import React from 'react';
import { EmptyState } from 'glints-aries/es/@next';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import { StyledModal } from './styles.sc';

const messages = defineMessages({
  title: {
    id: 'general_error-failed-to-load-title',
    defaultMessage: 'Failed to load',
  },
  description: {
    id: 'general-error-failed-to-load-body',
    defaultMessage: 'Check your internet connection and try to reload',
  },
  actionButtonText: {
    id: 'general-button-reload',
    defaultMessage: 'Reload',
  },
});

/* This is done to show the modal close button */
const EmptyComponent = () => <></>;

const Container = styled.div`
  & > div {
    padding: 0;
  }
`;

type ErrorStateModalProps = {
  onClose: () => void;
};

export default function ErrorStateModal({ onClose }: ErrorStateModalProps) {
  const { formatMessage } = useIntl();

  return (
    <StyledModal isOpen={true} header={<EmptyComponent />} onClose={onClose}>
      <Container>
        <EmptyState
          imageName="empty-mailbox"
          title={formatMessage(messages.title)}
          description={formatMessage(messages.description)}
          primaryButtonAction={{
            label: formatMessage(messages.actionButtonText),
            onClick: () => location.reload(),
          }}
        />
      </Container>
    </StyledModal>
  );
}
