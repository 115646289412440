import { CountrySelectionParams } from './components/LinksSection/types';

/**
 * Resolves the resultant URL from the given link definition and country code.
 *
 * If no default URL is provided in the definition, and a country-specific URL
 * cannot be found for the given country code, this function returns null.
 *
 * If a country is named in excludedCountries and also perCountryURL,
 * excludedCountries take precedence.
 */
export const resolveURL = function (
  def: CountrySelectionParams,
  countryCode: string
): string | null {
  if (def.excludedCountries?.includes(countryCode)) {
    return null;
  } else if (def.perCountryURL?.[countryCode]) {
    return def.perCountryURL[countryCode];
  } else if (def.defaultURL) {
    return def.defaultURL;
  } else {
    return null;
  }
};
