import React from 'react';
import { Spinner, Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { useIntl } from 'react-intl';

import { homePageSignUpFormMessages } from '../../homePageSignUpFormMessages';
import { StyledButton } from './SignUpButton.sc';

interface SignUpButtonProps {
  loading?: boolean;
}

const SignUpButton = ({ loading }: SignUpButtonProps) => {
  const { formatMessage } = useIntl();

  return (
    <StyledButton type="submit" disabled={loading}>
      <Typography variant="button" color={Neutral.B18}>
        {formatMessage(homePageSignUpFormMessages.signUpNow).toUpperCase()}
      </Typography>
      <If condition={loading}>
        <Spinner size="small" fill={Neutral.B18} />
      </If>
    </StyledButton>
  );
};

export default SignUpButton;
