import React, { Component } from 'react';
import { Row } from 'antd';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { updateMetadata } from '../../actions/meta';
import { BtnBlue } from '../../components/Buttons';
import styles from './styles.scss';

class Error403Page extends Component {
  static propTypes = {
    updateMetadata: PropTypes.func,
    intl: PropTypes.object,
  };

  componentDidMount() {
    this.props.updateMetadata(
      this.props.intl.formatMessage(messages.accessDenied),
      null,
      null,
      403
    );
  }

  render() {
    const { intl } = this.props;
    return (
      <Row
        className={styles.content}
        type="flex"
        justify="center"
        align="middle"
      >
        <div className={styles.imageWrapper}>
          <img
            src="/images/403.png"
            alt={intl.formatMessage(messages.forbidden)}
          />
        </div>
        <div className={styles.textWrapper}>
          <h4>
            <FormattedMessage
              tagName="strong"
              id="modules.ErrorPages.Error403.access.denied"
              defaultMessage="Access Denied"
            />
          </h4>
          <p>
            <FormattedMessage
              id="modules.ErrorPages.Error403.whoops.you.may"
              defaultMessage="Whoops, you may have wandered too far!"
            />
            <br />
            <FormattedMessage
              id="modules.ErrorPages.Error403.the.page.you"
              defaultMessage="(The page you have requested is forbidden.)"
            />
          </p>
        </div>
        <Link to="/">
          <BtnBlue>
            <FormattedMessage
              id="modules.ErrorPages.Error403.take.me.home"
              defaultMessage="Take me home!"
            />
          </BtnBlue>
        </Link>
      </Row>
    );
  }
}

Error403Page = connect(null, dispatch => ({
  updateMetadata: (title, description, image, prerenderStatusCode) =>
    dispatch(updateMetadata(title, description, image, prerenderStatusCode)),
}))(injectIntl(Error403Page));

const messages = defineMessages({
  accessDenied: {
    id: 'modules.ErrorPages.Error403.access.denied',
    defaultMessage: 'Access Denied',
  },
  forbidden: {
    id: 'modules.ErrorPages.Error403.403.forbidden',
    defaultMessage: '403 Forbidden',
  },
});

export default Error403Page;
