import { withChildren } from '@builder.io/react';
import { accordionConfig } from '@builder.io/widgets/dist/lib/components/Accordion.config';
import { carouselConfig } from '@builder.io/widgets/dist/lib/components/Carousel.config';
import { masonryConfig } from '@builder.io/widgets/dist/lib/components/Masonry.config';
import { tabsConfig } from '@builder.io/widgets/dist/lib/components/Tabs.config';

import { loadable } from './common/loadable';
import AriesButton from './components/BuilderIO/AriesButton';
import ButtonWithEventTracker from './components/BuilderIO/ButtonWithEventTracker/ButtonWithEventTracker';
import { carouselConfigExtended } from './components/BuilderIO/Carousel/Carousel.config';
import HomePageSignUpForm from './components/BuilderIO/HomePageSignUpForm/HomePageSignUpForm';
import Roles from './components/BuilderIO/HowItWorks/Roles';
import { getConfig } from './config';

type BuilderInstance = {
  builder: any;
};
const builderInstance: BuilderInstance = {
  builder: null,
};

const loadBuilder = () =>
  import(
    /* webpackExports: ["builder", "Builder"] */
    /* webpackChunkName: "builder-io-builder" */ '@builder.io/react'
  ).then(mod => ({
    builder: mod.builder,
    Builder: mod.Builder,
  }));

const setBuilderInstance = async () => {
  try {
    const { builder, Builder } = await loadBuilder();
    const config = getConfig();
    builder.init(config.BUILDER_PUBLIC_API_KEY);

    builder.setUserAttributes({
      country: config.COUNTRY,
      language: config.LANG,
    });

    Builder.registerComponent(
      loadable(() =>
        import('@builder.io/widgets/dist/lib/components/Accordion').then(
          mod => mod.AccordionComponent
        )
      ),
      accordionConfig
    );

    /*
     * Created new carousel component with extended carousel config
     * allowing to pass more properties and have more agile setup
     * without introducing new dependencies and potential regression.
     * Ideally old Carousel should be removed when nothing is
     * dependent on it.
     */
    const Carousel = loadable(() =>
      import('@builder.io/widgets/dist/lib/components/Carousel').then(
        mod => mod.CarouselComponent
      )
    );
    Builder.registerComponent(Carousel, carouselConfigExtended);
    Builder.registerComponent(Carousel, carouselConfig);

    Builder.registerComponent(
      loadable(() =>
        import('@builder.io/widgets/dist/lib/components/Masonry').then(
          mod => mod.MasonryComponent
        )
      ),
      masonryConfig
    );

    Builder.registerComponent(
      loadable(() =>
        import('@builder.io/widgets/dist/lib/components/Tabs').then(
          mod => mod.TabsComponent
        )
      ),
      tabsConfig
    );

    Builder.registerComponent(HomePageSignUpForm, {
      name: 'HomePageSignUpForm',
    });

    Builder.registerComponent(Roles, {
      name: 'How it works - Roles',
      inputs: [
        {
          name: 'roles',
          type: 'list',
          subFields: [
            {
              name: 'name',
              type: 'string',
              required: true,
            },
            {
              name: 'description',
              type: 'string',
              required: true,
            },
            {
              name: 'additional',
              type: 'string',
            },
          ],
        },
      ],
    });

    Builder.registerComponent(ButtonWithEventTracker, {
      name: 'ButtonWithEventTracker',
      inputs: [
        {
          name: 'text',
          type: 'string',
        },
        {
          name: 'sensorsDataEventName',
          type: 'string',
        },
      ],
    });

    Builder.registerComponent(withChildren(AriesButton), {
      name: 'AriesButton',
      description: 'Glints Aries - Button',
      defaultChildren: [
        {
          '@type': '@builder.io/sdk:Element',
          component: { name: 'Text', options: { text: 'Button' } },
        },
      ],
      inputs: [
        {
          name: 'variant',
          type: 'string',
          enum: [
            'link',
            'solid-white',
            'solid-blue',
            'yellow',
            'ghost',
            'white-grey',
          ],
          defaultValue: 'solid-blue',
        },
        {
          name: 'block',
          type: 'boolean',
          defaultValue: false,
        },
        {
          name: 'onClick',
          type: 'string',
          helperText: 'Should be pure function',
        },
      ],
    });

    builderInstance.builder = builder;
  } catch (e) {
    console.error(e);
  }
};

export const getBuilderInstance = async () => {
  if (!builderInstance.builder) {
    await setBuilderInstance();
  }

  return builderInstance.builder;
};
