import React from 'react';
import baseLoadable, {
  OptionsWithoutResolver,
  OptionsWithResolver,
} from '@loadable/component';

import LoadingFullscreen from '../components/LoadingFullscreen';

type loadFn = (props: any) => Promise<any>;
type options = OptionsWithoutResolver<any> | OptionsWithResolver<any>;

export function loadable<T = any>(loadFn: loadFn, options: options = {}) {
  const defaultOptions = {
    fallback: <LoadingFullscreen />,
  };
  const fullOptions = { ...defaultOptions, ...options };
  return baseLoadable<T>(loadFn, fullOptions);
}
