import React from 'react';

import useShowVIPProductPurchaseSuccessAlert from '../hooks/useShowVIPProductPurchaseSuccessAlert';
import GlintsVIPPurchaseModalForAfterPayment from '../PurchaseModals/GlintsVIPPurchaseModal/GlintsVIPPurchaseModalForAfterPayment';

export default function VIPAfterPaymentStateHandlers() {
  useShowVIPProductPurchaseSuccessAlert();
  return (
    <>
      <GlintsVIPPurchaseModalForAfterPayment />
    </>
  );
}
