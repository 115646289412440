import { defineMessages, MessageDescriptor } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'text-member',
    defaultMessage: 'Members',
  },
  addTeamMembers: {
    id: 'text-add-team-members',
    defaultMessage: 'Add team members',
  },
  searchByName: {
    id: 'interactive-search-by-name',
    defaultMessage: 'Search by name',
  },
  singleMemberJoinedChat: {
    id: 'text-single-member-join-chat',
    defaultMessage: '{memberName} has joined the chat.',
  },
  twoMemberJoinedChat: {
    id: 'text-two-members-join-chat',
    defaultMessage:
      '{firstMemberName}, {secondMemberName} have joined the chat.',
  },
  multiMemberJoinedChat: {
    id: 'text-multi-member-join-chat',
    defaultMessage:
      '{firstMemberName}, {secondMemberName}, and {otherCount} {otherCount, plural, one {other} other {others}} have joined the chat.',
  },
  multiMemberJoinedChatPlural: {
    id: 'text-multi-member-join-chat-plural',
    defaultMessage:
      '{firstMemberName}, {secondMemberName}, and {otherCount} others have joined the chat.',
  },
  singleMemberLeftChat: {
    id: 'text-single-member-left-chat',
    defaultMessage: '{memberName} has left the chat.',
  },
  twoMemberLeftChat: {
    id: 'text-two-members-left-chat',
    defaultMessage: '{firstMemberName}, {secondMemberName} have left the chat.',
  },
  multiMemberLeftChat: {
    id: 'text-multi-member-left-chat',
    defaultMessage:
      '{firstMemberName}, {secondMemberName} and {otherCount} {otherCount, plural, one {other} other {others}} have left the chat.',
  },
  multiMemberLeftChatPlural: {
    id: 'text-multi-member-left-chat-plural',
    defaultMessage:
      '{firstMemberName}, {secondMemberName} and {otherCount} others have left the chat.',
  },
  fromThisChatLabel: {
    id: 'text-from-this-chat-label',
    defaultMessage: 'From this chat',
  },
  fromThisChatDesc: {
    id: 'text-from-this-chat-desc',
    defaultMessage: 'No longer have access to this chat',
  },
  fromThisChatAndPTNLabel: {
    id: 'text-from-this-chat-ptn-label',
    defaultMessage: 'From this chat & People to Notify',
  },
  fromThisChatAndPTNDesc: {
    id: 'text-from-this-chat-ptn-desc',
    defaultMessage: 'No longer have access to this chat or receive new chat',
  },
});

export const memberJoinedChatMessage: Record<
  number,
  MessageDescriptor | undefined
> = {
  1: messages.singleMemberJoinedChat,
  2: messages.twoMemberJoinedChat,
  3: messages.multiMemberJoinedChat,
};

export const memberLeftChatMessage: Record<
  number,
  MessageDescriptor | undefined
> = {
  1: messages.singleMemberLeftChat,
  2: messages.twoMemberLeftChat,
  3: messages.multiMemberLeftChat,
};

export enum RemoveChannelMemberAction {
  FROM_CHAT = 'FROM_CHAT',
  FROM_CHAT_AND_PTN = 'FROM_CHAT_AND_PTN',
}
