import { GetAssetURLCallback } from '../../../../../common/asset-manager';
import { FooterLink, getLinks as getDefaultLinks } from './default';
import { links as sgLinks } from './sg';

export const getLinks = (
  getAssetURL: GetAssetURLCallback
): Record<string, FooterLink[]> => ({
  default: getDefaultLinks(getAssetURL),
  sg: sgLinks,
});
