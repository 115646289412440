import { useLocation } from 'react-router';

import { GlintsVIPPurchaseFailedState } from '../../../../../common/interfaces/routerState';
import { makeApolloErrorFromCode } from '../../../../../common/utility';

export default function useGetErrorFromVIPPurchaseFailedState() {
  const location = useLocation<GlintsVIPPurchaseFailedState | undefined>();

  const { glintsVIPPurchaseFailed } = location.state || {};

  if (!glintsVIPPurchaseFailed) return undefined;

  return makeApolloErrorFromCode(glintsVIPPurchaseFailed.failureType);
}
