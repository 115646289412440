import CALL_API from '../../middleware/api_key';
import { Schemas } from '../../middleware/schemas';
import { getUser } from '../../selectors/user';

export const Actions = {
  FETCH_ME_REQUEST: 'glints/user/me/FETCH_ME_REQUEST',
  FETCH_ME_SUCCESS: 'glints/user/me/FETCH_ME_SUCCESS',
  FETCH_ME_FAILURE: 'glints/user/me/FETCH_ME_FAILURE',

  PERSIST_ME_REQUEST: 'glints/user/me/PERSIST_ME_REQUEST',
  PERSIST_ME_SUCCESS: 'glints/user/me/PERSIST_ME_SUCCESS',
  PERSIST_ME_FAILURE: 'glints/user/me/PERSIST_ME_FAILURE',
};

export function fetchMe() {
  return async dispatch => {
    await dispatch({
      [CALL_API]: {
        types: [
          Actions.FETCH_ME_REQUEST,
          Actions.FETCH_ME_SUCCESS,
          Actions.FETCH_ME_FAILURE,
        ],
        endpoint: 'me',
        schema: Schemas.USER,
        options: {
          params: {
            include: 'Companies, Group, PendingUserCompanies, City, Country',
          },
        },
      },
    });
  };
}

export function saveUserFeatures(feature) {
  return async (dispatch, getState) => {
    const preferences = getUser(getState())?.preferences ?? {};
    preferences.features = preferences.features || {};
    preferences.features[feature] = true;
    await dispatch(persistMe({ preferences: preferences }));
  };
}

export function persistMe(values) {
  return async dispatch => {
    await dispatch({
      [CALL_API]: {
        types: [
          Actions.PERSIST_ME_REQUEST,
          Actions.PERSIST_ME_SUCCESS,
          Actions.PERSIST_ME_FAILURE,
        ],
        endpoint: 'me',
        schema: Schemas.USER,
        method: 'put',
        options: {
          data: values,
        },
      },
    });
  };
}
