import React, { ComponentType } from 'react';

import UseMobileAppPrompt from './UseMobileAppPrompt';
import useShowMobileAppPrompt from './useShowMobileAppPrompt';

export function withUseMobileAppPrompt<P = Record<string, unknown>>(
  WrappedComponent: ComponentType<P>
) {
  const Wrapper = (props: P & JSX.IntrinsicAttributes) => {
    const canShowPrompt = useShowMobileAppPrompt();

    if (canShowPrompt) {
      return <UseMobileAppPrompt />;
    }

    return <WrappedComponent {...props} />;
  };

  return Wrapper;
}
