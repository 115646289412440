import React from 'react';
import {
  Button,
  ButtonGroup,
  DestructiveButton,
  Modal,
  Typography,
} from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';

type DeleteMessageModalProps = {
  onSubmit: () => void;
  onClose: () => void;
};

const DeleteMessageModal = ({ onSubmit, onClose }: DeleteMessageModalProps) => (
  <Modal
    isOpen={true}
    header={
      <FormattedMessage
        id="text_delete-template-message-title"
        defaultMessage="Delete message?"
      />
    }
    customActions={
      <ButtonGroup>
        <Button onClick={onClose}>
          <Typography variant="button">
            <FormattedMessage id="button-text_cancel" defaultMessage="Cancel" />
          </Typography>
        </Button>
        <DestructiveButton onClick={onSubmit}>
          <Typography variant="button">
            <FormattedMessage id="interactive-delete" defaultMessage="Delete" />
          </Typography>
        </DestructiveButton>
      </ButtonGroup>
    }
    onClose={onClose}
  >
    <Typography variant="body1">
      <FormattedMessage
        id="text-delete-message-desc"
        defaultMessage="Are you sure you want to permanently delete this message?"
      />
    </Typography>
  </Modal>
);

export default DeleteMessageModal;
