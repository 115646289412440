import React, { PropsWithChildren } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import { CompanyStatus } from './constants';
import { useGetSessionCompany } from './hooks/useGetSessionCompany';

export const checkCompanyVerificationStatus = (
  WrappedComponent: React.FC<PropsWithChildren>
) => {
  const CheckIsCompanyVerified = (props: any) => {
    const dispatch = useDispatch();
    const { sessionCompany } = useGetSessionCompany();
    if (sessionCompany?.status === CompanyStatus.VERIFIED) {
      return <WrappedComponent {...props} />;
    }
    dispatch(push('/403'));
    return null;
  };

  return CheckIsCompanyVerified;
};
