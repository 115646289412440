import { handleActions } from 'redux-actions';

import { Actions } from '../actions/skills';

const initialState = {
  loading: false,
  error: null,
  value: [],
};

export default handleActions(
  {
    [Actions.SKILLS_REQUEST]: state => ({
      ...state,
      loading: true,
      value: state.value.length ? state.value : [],
      error: null,
    }),
    [Actions.SKILLS_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      value: [...new Set(action.response.result.concat(state.value))],
      error: null,
    }),
    [Actions.SKILLS_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      value: [],
      error: action.error,
    }),
    [Actions.SKILLS_RESET]: state => ({
      ...state,
      ...initialState,
    }),
  },
  initialState
);
