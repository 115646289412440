import React, { ReactNode } from 'react';

import { COUNTRY_DOMAINS } from '../common/constants';
import { useGetSessionCompany } from '../common/hooks/useGetSessionCompany';

type RenderOnlyForCountryProps = {
  children: ReactNode;
};

const RenderOnlyForIDCompany = ({ children }: RenderOnlyForCountryProps) => {
  const { sessionCompany } = useGetSessionCompany();
  if (
    !sessionCompany ||
    sessionCompany.CountryCode.toLowerCase() !== COUNTRY_DOMAINS.id
  ) {
    return null;
  }

  return <>{children}</>;
};

const RenderBasedOnCompanyCountry = Object.assign(RenderOnlyForIDCompany, {
  IDOnly: RenderOnlyForIDCompany,
});

export default RenderBasedOnCompanyCountry;
