import { useMemo, useState } from 'react';
import { useLocation } from 'react-router';

import { ROUTES } from '../../../common/routes';
import { AlterNativeNavbarContentBasedOnPathName } from './constants';

type AlternativeHeaderState =
  | {
      showAlertNativeHeader: true;
      alternativeHeaderContent: JSX.Element | null;
    }
  | {
      showAlertNativeHeader: false;
      alternativeHeaderContent: null;
    };

export default function useShowAlternativeHeader() {
  const { pathname } = useLocation();
  const [
    { showAlertNativeHeader, alternativeHeaderContent },
    setShowAlternativeHeader,
  ] = useState<AlternativeHeaderState>({
    showAlertNativeHeader: false,
    alternativeHeaderContent: null,
  });

  const pathNameWithoutTrailingSlash = useMemo(() => {
    if (pathname === '/') {
      return pathname;
    }
    return pathname.replace(/\/+$/, '');
  }, [pathname]);

  const contentComponent =
    AlterNativeNavbarContentBasedOnPathName[pathNameWithoutTrailingSlash];

  const showHeader = Object.keys(
    AlterNativeNavbarContentBasedOnPathName
  ).includes(pathNameWithoutTrailingSlash);

  const routesWithNoNavigation = [
    ROUTES.companyReportStatus,
    ROUTES.onboarding,
  ];

  return {
    showAlternativeHeader: showAlertNativeHeader || showHeader,
    alternativeHeaderContent: alternativeHeaderContent || contentComponent,
    preventNavigation: routesWithNoNavigation.some(route =>
      pathname.includes(route)
    ),
    setShowAlternativeHeader,
  } as const;
}
