import React, { useEffect, useState } from 'react';
import { Greyscale } from 'glints-aries';
import { Alert, Badge, Icon } from 'glints-aries/es/@next';
import { BadgeStatusVariant } from 'glints-aries/es/@next/Badge';
import { IconNames } from 'glints-aries/es/@next/Icon/icons/icons';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import type { Event } from 'stream-chat';
import {
  useChannelStateContext,
  useChatContext,
  useMessageContext,
  useRetryHandler,
} from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import styled from 'styled-components';

import { useMessagingChannelContext } from '../constants';

const Styled = {
  Badge: styled(Badge)`
    display: inline-block;
  `,
  BadgeText: styled.div`
    margin: -8px 0px 8px 20px;
  `,
};

const messages = defineMessages({
  sending: {
    id: 'text-message-delivery-sending',
    defaultMessage: 'Sending',
  },
  sent: {
    id: 'text-message-delivery-sent',
    defaultMessage: 'Sent',
  },
  read: {
    id: 'text-message-delivery-read',
    defaultMessage: 'Read',
  },
  readMultiple: {
    id: 'text-message-delivery-read-multiple',
    defaultMessage: '{count} Read',
  },
  failed: {
    id: 'text-message-delivery-failed',
    defaultMessage: 'Failed',
  },
});

type StatusBadgeProps = {
  status?: string;
  iconName: string;
  text: string;
};

const StatusBadge = ({
  status = 'neutral',
  iconName,
  text,
}: StatusBadgeProps) => (
  <Styled.Badge status={status as BadgeStatusVariant}>
    <Icon
      name={iconName as IconNames}
      height={16}
      width={16}
      fill={Greyscale.lightgrey}
    />
    <Styled.BadgeText>{text}</Styled.BadgeText>
  </Styled.Badge>
);

const MessageStatus = () => {
  const { formatMessage } = useIntl();
  const { message, readBy = [], isMyMessage } = useMessageContext();
  const { lastRegularMessageIdSentByCurrentUser } =
    useMessagingChannelContext();
  const { channel } = useChannelStateContext();
  const { client } = useChatContext();
  const retrySendMessage = useRetryHandler();
  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    const connectionChanged = ({
      online = false,
    }: Event<DefaultStreamChatGenerics>) => {
      if (isConnected !== online) {
        setIsConnected(online);
      }
    };

    client.on('connection.changed', connectionChanged);
    return () => client.off('connection.changed', connectionChanged);
  }, [client, isConnected]);

  if (!isMyMessage() || message.type !== 'regular') return null;

  const userId = client.user?.id;

  const [sending, delivered, failed] = [
    message.status === 'sending',
    message.status === 'received' &&
      message.id === lastRegularMessageIdSentByCurrentUser,
    message.status === 'failed',
  ];

  const [membersCount, readByOthersCount] = [
    channel.data?.member_count ?? 0,
    readBy.filter(user => user.id !== userId).length,
  ];

  return (
    <>
      <Choose>
        <When condition={sending}>
          <StatusBadge
            iconName="ri-timer3-line"
            text={formatMessage(messages.sending)}
          />
        </When>
        <When condition={failed}>
          <StatusBadge
            status="critical"
            iconName="ri-error-warning-line"
            text={formatMessage(messages.failed)}
          />
          <a
            onClick={() => retrySendMessage(message)}
            style={{ marginLeft: '10px' }}
          >
            <FormattedMessage
              id="button-text_try-again"
              defaultMessage="Try again"
            />
          </a>
        </When>
        <When condition={delivered}>
          <Choose>
            <When condition={readByOthersCount}>
              <StatusBadge
                iconName="ri-eye-line"
                text={formatMessage(
                  membersCount > 2 ? messages.readMultiple : messages.read,
                  {
                    count: readByOthersCount,
                  }
                )}
              />
            </When>
            <Otherwise>
              <StatusBadge
                iconName="ri-check"
                text={formatMessage(messages.sent)}
              />
            </Otherwise>
          </Choose>
        </When>
      </Choose>
      <Alert
        show={!isConnected}
        status="error"
        content={
          <FormattedMessage
            id="text-connection-failed"
            defaultMessage="Connection failure, reconnecting now..."
          />
        }
      />
    </>
  );
};

export default MessageStatus;
