import React, { Fragment, MutableRefObject, useState } from 'react';
import { Box, Flex, Greyscale, SecondaryColor } from 'glints-aries';
import { Button, ButtonGroup, Icon, Typography } from 'glints-aries/es/@next';
import { Neutral, Red } from 'glints-aries/es/@next/utilities/colors';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { TemplateMessageFragment } from '../../graphql/TemplateMessage';
import DeleteTemplateMessageConfirmationModal from './DeleteTemplateMessageConfirmationModal';

const Styled = {
  InteractiveText: styled.div`
    cursor: pointer;
    p {
      white-space: nowrap;
    }
  `,
  Container: styled.div`
    display: grid;
    grid-auto-rows: auto 1fr auto;
    height: 100%;
  `,
};

export type EditTemplateMessagesProps = {
  messages: TemplateMessageFragment[];
  listContainerRef: MutableRefObject<HTMLDivElement | null>;
  onNew: () => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onClose?: () => void;
};

const EditTemplateMessages = ({
  messages,
  listContainerRef,
  onNew,
  onEdit,
  onDelete,
  onClose,
}: EditTemplateMessagesProps) => {
  const [deleteMessageId, setDeleteMessageId] = useState<string>();
  const resetDeleteMessageId = () => setDeleteMessageId(undefined);
  const handleOnDeleteConfirm = () => {
    if (!deleteMessageId) return;
    onDelete(deleteMessageId);
    resetDeleteMessageId();
  };
  const makePromptDeleteHandler = (messageId: string) => () =>
    setDeleteMessageId(messageId);
  const makePromptEditHandler = (messageId: string) => () => onEdit(messageId);

  return (
    <Styled.Container>
      <Flex
        px={16}
        py={12}
        style={{
          color: SecondaryColor.actionblue,
          cursor: 'pointer',
          border: `1px solid ${Neutral.B85}`,
          borderLeft: 0,
          borderRight: 0,
        }}
        onClick={() => onNew()}
      >
        <Icon
          name="ri-add-circle-line"
          width={16}
          style={{ fill: SecondaryColor.actionblue, marginRight: 16 }}
        />
        <FormattedMessage
          id="button-text_add-new-message"
          defaultMessage="Add new message"
        />
      </Flex>
      <div
        ref={listContainerRef}
        style={{
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        {messages.map(message => (
          <Fragment key={message.id}>
            <Flex
              p={16}
              justifyContent="space-between"
              style={{
                gap: 16,
                borderBottom: `1px solid ${Neutral.B85}`,
                backgroundColor:
                  message.id === deleteMessageId
                    ? Greyscale.softgrey
                    : undefined,
              }}
            >
              <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                &ldquo;{message.message}&rdquo;
              </Typography>
              <ButtonGroup>
                {messages.length > 1 && (
                  <Styled.InteractiveText
                    onClick={makePromptDeleteHandler(message.id)}
                  >
                    <Typography variant="body2" color={Red.Brand}>
                      <FormattedMessage
                        id="interactive-delete"
                        defaultMessage="Delete"
                      />
                    </Typography>
                  </Styled.InteractiveText>
                )}
                <Styled.InteractiveText
                  onClick={makePromptEditHandler(message.id)}
                >
                  <Typography variant="body2" color={SecondaryColor.actionblue}>
                    <FormattedMessage
                      id="general_text-edit"
                      defaultMessage="Edit"
                    />
                  </Typography>
                </Styled.InteractiveText>
              </ButtonGroup>
            </Flex>
          </Fragment>
        ))}
      </div>
      <Box
        p={16}
        style={{ borderTop: `1px solid ${Neutral.B95}`, marginTop: -1 }}
      >
        <Button fullWidth={true} size="slim" onClick={onClose}>
          <Typography variant="button">
            <FormattedMessage id="interactive-done" defaultMessage="Done" />
          </Typography>
        </Button>
      </Box>
      {deleteMessageId && (
        <DeleteTemplateMessageConfirmationModal
          onDelete={handleOnDeleteConfirm}
          onClose={resetDeleteMessageId}
        />
      )}
    </Styled.Container>
  );
};

export default EditTemplateMessages;
