import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { WHATSAPP_CHAT_SUPPORT_ENABLED_COUNTRIES } from '../../common/constants';
import config from '../../config';
import { useZendeskVisibilityContext } from '../../modules/Zendesk/ZendeskVisibilityContext';
import { getFeatures } from '../../selectors/features';
import { messages } from './constants';
import { StyledWhatsappButtonWrapper } from './WhatsappButton.sc';

export const WhatsappButton = () => {
  const intl = useIntl();

  const prefilledMessage = encodeURIComponent(
    intl.formatMessage(messages.textHello)
  );

  const { isZendeskMessagingVisible } = useZendeskVisibilityContext();

  const { empShowWhatsappIcon } = useSelector(getFeatures);

  const isWhatsappChatSupportEnabled =
    WHATSAPP_CHAT_SUPPORT_ENABLED_COUNTRIES.includes(config.COUNTRY) &&
    empShowWhatsappIcon &&
    // Hide the whatsapp icon with the same criteria as the zendesk widget
    isZendeskMessagingVisible;

  return (
    <If condition={isWhatsappChatSupportEnabled}>
      <StyledWhatsappButtonWrapper
        href={`https://wa.me/${config.WHATSAPP_CHAT_SUPPORT_NUMBER}?text=${prefilledMessage}`}
        target="_blank"
      >
        <img alt="Chat on WhatsApp" src="images/whatsapp-icon.svg" />
      </StyledWhatsappButtonWrapper>
    </If>
  );
};
