import { Menu } from 'antd';
import { CloseIcon } from 'glints-aries';
import styled, { css } from 'styled-components';

import { NavbarItem } from './NavbarItem';

export const SidebarWrapper = styled.div`
  padding: 20px;
  background-color: white;
`;

export const SidebarCloseWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: flex-end;
`;

export const SidebarCloseIcon = styled(CloseIcon)`
  font-size: 30px;
  font-weight: bold;
`;

export const SidebarMenu = styled(Menu).attrs({
  mode: 'inline',
  inlineIndent: 12,
})`
  display: flex;
  flex-direction: column;
  border-right: none;

  .ant-menu-item {
    padding-right: 0;

    > * {
      width: 100%;
    }
  }

  .ant-menu-item-group-list {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const sidebarItemStyles = css`
  text-transform: uppercase;
  color: black;
  font-weight: bold;
  letter-spacing: 0.5px;
  padding: 12px 20px;
  margin-right: -12px;
  line-height: 1;
`;

export const SidebarItem = styled(NavbarItem)`
  ${sidebarItemStyles}
`;

const sidebarItemGroupStyle = css`
  .ant-menu-item-group-title {
    background-color: #f5f5f5;
    padding: 12px 8px;
    margin-bottom: 12px;
  }
`;

export const SidebarParentItem = styled(Menu.ItemGroup)`
  ${sidebarItemStyles}
  ${sidebarItemGroupStyle}

  padding: 0;
  margin: 16px 0;
`;

export const SidebarSection = styled(Menu.ItemGroup)`
  ${sidebarItemGroupStyle}

  .ant-menu-item-group-title {
    background-color: #f5f5f5;
    padding: 12px 8px;
    margin-bottom: 12px;
  }
`;

export const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

export const Name = styled.span`
  margin-right: 5px;
  display: flex;
  flex-grow: 1;
  text-transform: uppercase;
  color: black;
  font-weight: bold;
  letter-spacing: 0.5px;
`;
