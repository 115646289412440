import { Device, ScreenSize } from 'glints-aries';
import { Colors } from 'glints-aries/es/@next';
import { space24, space32 } from 'glints-aries/es/@next/utilities/spacing';
import styled from 'styled-components';

export const ResponsiveContainer = styled.div`
  @media ${Device.mobileS} {
    padding: 0 10px;
  }
  @media (min-width: ${ScreenSize.mobileS}px) {
    padding: 0 10px;
  }
  @media (min-width: ${ScreenSize.mobileM}px) {
    padding: 0 20px;
  }
  @media (min-width: ${ScreenSize.mobileL}px) {
    padding: 0 ${space24};
  }
  @media (min-width: ${ScreenSize.tablet}px) {
    padding: 0 ${space32};
  }
  @media (min-width: ${ScreenSize.desktopS}px) {
    padding: 0 ${space32};
  }
  @media (min-width: ${ScreenSize.desktopM}px) {
    padding: 0 30px;
  }
  @media (min-width: ${ScreenSize.desktopL}px) {
    width: 1400px;
    padding: 0 20px;
  }
`;

export const StyledContainerV2 = styled(ResponsiveContainer)`
  position: relative;
  margin: 0 auto;
  font-family: Poppins, sans-serif;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  &[data-loading='true'] {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 70px - 24px);
  }

  &[data-full-width='true'] {
    &&& {
      width: 100%;
    }
  }
`;

export const FullBackground = styled.div`
  background-color: ${Colors.Neutral.B99};
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100vw;
  padding-bottom: ${space24};
  min-height: calc(100vh - 70px);
`;
