import { Middleware } from '@reduxjs/toolkit';
import axios from 'axios';
import { identity } from 'lodash-es';
import { schema } from 'normalizr';

import { apiURL, getAuthHeader, normalizeResponse } from '../common/api';
import { handleFraudCompanyError } from '../common/handleFraudCompany';
import { createAcceptLanguageHeader } from '../common/helpers/createAcceptLanguageHeader';
import { getConfig } from '../config';
import CALL_API from './api_key';

axios.interceptors.response.use(
  response => response,
  err => {
    handleFraudCompanyError(
      err.response?.status,
      err.response?.data?.error?.companyStatus
    );
    return Promise.reject(err);
  }
);
/**
 * Fetches an API response and normalizes the result JSON according to schema.
 * This makes every API response have the same shape, regardless of how nested it was.
 */
async function callApi(
  endpoint: string,
  schema: schema.Entity<unknown> | schema.Entity<unknown>[],
  options: {
    headers?: Record<string, string>;
  } = {},
  method: 'get' | 'put' | 'post' = 'get',
  responseTransformer = identity
) {
  const fullUrl = apiURL(`/${endpoint}`);
  const headers = { headers: options.headers };

  let response = null;
  switch (method) {
    case 'get':
      response = await axios(fullUrl, options);
      break;
    case 'put':
      delete options.headers;
      response = await axios.put(fullUrl, options, headers);
      break;
    case 'post':
      delete options.headers;
      response = await axios.post(fullUrl, options, headers);
      break;
  }

  response = responseTransformer(response);
  if (!response.data || !response.data.data) {
    return;
  }

  // Collections include a count to indicate the total number of items.
  return normalizeResponse(response, schema);
}

/**
 * A Redux middleware that interprets actions with CALL_API info specified.
 * Performs the call and promises when such actions are dispatched.
 */
const ApiMiddleware: Middleware = store => next => async action => {
  /* eslint callback-return:0 */
  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  let { endpoint, options } = callAPI;
  const { schema, types, meta, method, responseTransformer } = callAPI;
  options = options || {};
  options.headers = getAuthHeader(store.dispatch, store.getState);
  const config = getConfig();
  if (options.headers) {
    options.headers['accept-language'] = createAcceptLanguageHeader(
      config.LANG
    );
  }

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState());
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of 3 action types.');
  }

  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  function actionWith(data: {
    meta: unknown;
    type: string;
    response?: unknown;
    error?: unknown;
  }) {
    const finalAction = { ...action, ...data };
    delete finalAction[CALL_API];
    return finalAction;
  }

  const [requestType, successType, failureType] = types;
  next(
    actionWith({
      meta,
      type: requestType,
    })
  );

  try {
    const response = await callApi(
      endpoint,
      schema,
      options,
      method,
      responseTransformer
    );

    next(
      actionWith({
        meta,
        response,
        type: successType,
      })
    );
  } catch (err) {
    next(
      actionWith({
        meta,
        error: err,
        type: failureType,
      })
    );
    console.error(err);
  }
};

export default ApiMiddleware;
