import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, LinkedinIcon, Loading } from 'glints-aries';
import PropTypes from 'prop-types';

import { LinkedInAuthDialog } from '../dialog/linkedin-auth-dialog';
import styles from './AriesButtons.scss';

const LoginWithLinkedinButton = ({
  className = '',
  buttonText,
  linkLinkedIn,
  loading,
  ...props
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClick = e => {
    if (e) {
      e.preventDefault();
    }
    setIsDialogOpen(true);
  };

  return (
    <Button
      onClick={handleClick}
      variant="solid-blue"
      style={{
        lineHeight: 1,
        padding: '15px 40px',
        backgroundColor: '#317ab0',
        display: 'flex',
        alignItems: 'baseline',
      }}
      className={classNames(styles.btn, className)}
      {...props}
    >
      <LinkedinIcon className={styles.icon} />
      &nbsp;&nbsp;{buttonText}
      <If condition={loading}>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Loading className={styles.loading} />
      </If>
      <LinkedInAuthDialog
        onClosing={() => setIsDialogOpen(false)}
        onReceiveAuthCode={linkLinkedIn}
        linkedInDialogOpen={isDialogOpen}
      />
    </Button>
  );
};

LoginWithLinkedinButton.propTypes = {
  className: PropTypes.string,
  linkLinkedIn: PropTypes.func.isRequired,
  buttonText: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};

export default LoginWithLinkedinButton;
