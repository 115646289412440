import { useSelector } from 'react-redux';

import { UnleashFeatureNames } from '../../modules/Unleash/featureNames';
import { getIsFeatureEnabled } from '../../selectors/features';
import { countryCodeIsIndonesia } from '../helpers/countryCodeHelper';
import { useGetSessionCompany } from './useGetSessionCompany';

type Params = {
  skipCompanyCountryCheck?: boolean;
};

export default function useIsScreeningQuestionEnabled(
  params?: Params
): boolean {
  const { sessionCompany } = useGetSessionCompany();
  const isEmpScreeningQuestionEnabled = useSelector(state =>
    getIsFeatureEnabled(state, UnleashFeatureNames.allScreeningQuestion)
  );

  if (params?.skipCompanyCountryCheck) {
    return Boolean(isEmpScreeningQuestionEnabled);
  }

  return Boolean(
    isEmpScreeningQuestionEnabled &&
      sessionCompany &&
      countryCodeIsIndonesia(sessionCompany.CountryCode)
  );
}
