import { handleActions } from 'redux-actions';

import { Actions } from './Actions';

const initialState = {
  isOpen: false,
  job: null,
};

export default handleActions(
  {
    [Actions.OPEN_JOB_SHARE_MODAL]: (state, action) => ({
      ...state,
      isOpen: true,
      job: action.payload,
    }),
    [Actions.CLOSE_JOB_SHARE_MODAL]: state => ({
      ...state,
      isOpen: false,
      job: null,
    }),
  },
  initialState
);
