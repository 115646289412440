import { Banner } from 'glints-aries/es/@next';
import styled from 'styled-components';

import { NAVBAR_Z_INDEX } from '../../modules/Navigation/Navbar.sc';

export const StyledBanner = styled(Banner)`
  position: static;
`;

export const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: ${NAVBAR_Z_INDEX + 1};
`;
