import React, { useCallback, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import {
  ArrowDownIcon,
  ArrowNextIcon,
  ArrowUpIcon,
  SecondaryColor,
} from 'glints-aries';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
  SupportedCountry,
  TALENT_HUNT_SIGNUP_LINK,
  URLS,
} from '../../common/constants';
import config from '../../config';
import type { MenuItem } from '../Platform/Constants';
import { Button, LabelWithIcon } from './CTAButton.sc';
import DropdownItem from './DropdownItem';
import { NavbarItem } from './NavbarItem';

const messages = defineMessages({
  getTalentHuntAccess: {
    id: 'modules.Navigation.CTAButton.get.talenthunt.access',
    defaultMessage: 'Get TalentHunt Access',
  },
  getTalentHubAccess: {
    id: 'modules.Navigation.CTAButton.get.talenthub.access',
    defaultMessage: 'Get TalentHub Access',
  },
  forTalentHunt: {
    id: 'modules.Navigation.CTAButton.for.talenthunt',
    defaultMessage: 'For TalentHunt',
  },
  postJob: {
    id: 'modules.Navigation.CTAButton.post.a.job.for.free',
    defaultMessage: 'Post A Job For Free',
  },
  candidates: {
    id: 'modules.Navigation.CTAButton.candidates',
    defaultMessage: 'Job Seekers',
  },
  scheduleConsultation: {
    id: 'modules.Navigation.CTAButton.schedule.consultation',
    defaultMessage: 'Schedule Consultation',
  },
  signup: {
    id: 'modules.Navigation.CTAButton.sign.up',
    defaultMessage: 'Sign up',
  },
});

const getTalentHuntAccessLabel = (
  <FormattedMessage {...messages.getTalentHuntAccess} />
);

const scheduleConsultationLabel = (
  <FormattedMessage {...messages.scheduleConsultation} />
);

const pathNames: PerPathNameConfig = {
  talenthunt: {
    id: {
      label: getTalentHuntAccessLabel,
      to: TALENT_HUNT_SIGNUP_LINK,
      value: 'getTalentHuntAccess',
    },
    default: {
      // SG has no access to TalentHunt
      // VN is currently using ID sign up
      label: getTalentHuntAccessLabel,
      to: TALENT_HUNT_SIGNUP_LINK,
      value: 'getTalentHuntAccess',
    },
  },
  talenthub: {
    default: {
      label: <FormattedMessage {...messages.getTalentHubAccess} />,
      to: URLS.signup.service.talentHub.default,
      value: 'getTalentHubAccess',
    },
  },
  default: {
    id: {
      label: <FormattedMessage {...messages.signup} />,
      value: 'signUp',
      children: [
        {
          label: <FormattedMessage {...messages.forTalentHunt} />,
          to: TALENT_HUNT_SIGNUP_LINK,
          value: 'forTalentHunt',
        },
        { label: 'For Job Portal', to: '/signup', value: 'forJobPortal' },
      ],
    },
    default: {
      label: (
        <LabelWithIcon>
          <FormattedMessage {...messages.candidates} /> <ArrowNextIcon />
        </LabelWithIcon>
      ),
      to: config.CANDIDATE_URL,
      value: 'candidatesSite',
    },
    sg: {
      label: scheduleConsultationLabel,
      to: URLS.signup.service.talentHub.default,
      value: 'scheduleConsultation',
    },
    tw: {
      label: scheduleConsultationLabel,
      to: URLS.signup.service.talentHub.tw,
      value: 'scheduleConsultation',
    },
  },
};

type PerPathNameConfig = {
  [pathname: string]: PerCountryConfig;
};

type PerCountryConfig = {
  [country in SupportedCountry]?: MenuItem;
} & { default: MenuItem };

const CTAButton: React.FC<CTAButtonProps> = ({ ...menuItemProps }) => {
  const pathNameObject =
    pathNames?.[location.pathname.split('/')[1]] ?? pathNames.default;
  let cta =
    pathNameObject?.[config.COUNTRY as SupportedCountry] ??
    pathNameObject.default;

  if (
    ['id', 'sg'].includes(config.COUNTRY) &&
    !location.pathname.split('/')[1]
  ) {
    cta = pathNames.default.default;
  }

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleDropdownVisibleChange = useCallback(
    (visible: boolean) => {
      setIsDropdownVisible(visible);
    },
    [setIsDropdownVisible]
  );

  if (cta.children) {
    return (
      <Menu.Item {...menuItemProps}>
        <Dropdown
          onVisibleChange={handleDropdownVisibleChange}
          overlay={
            <Menu>
              <DropdownItem items={cta.children} />
            </Menu>
          }
        >
          <Button
            style={{
              color: isDropdownVisible ? 'white' : SecondaryColor.actionblue,
              backgroundColor: isDropdownVisible
                ? SecondaryColor.actionblue
                : 'white',
            }}
          >
            <>
              {cta.label}
              &nbsp;&nbsp;&nbsp;
            </>
            <Choose>
              <When condition={isDropdownVisible}>
                <ArrowUpIcon />
              </When>
              <Otherwise>
                <ArrowDownIcon />
              </Otherwise>
            </Choose>
          </Button>
        </Dropdown>
      </Menu.Item>
    );
  } else {
    return (
      <NavbarItem to={cta.to} {...menuItemProps}>
        <Button variant="solid-blue">
          <>{cta.label}</>
        </Button>
      </NavbarItem>
    );
  }
};

type CTAButtonProps = MenuItemProps;

export default CTAButton;
