import { defineMessages } from 'react-intl';

export const selectFieldMessages = defineMessages({
  noOptions: {
    id: 'text-no-options',
    defaultMessage: 'No options',
  },
  typeToSearch: {
    id: 'text-please-type-to-search',
    defaultMessage: 'Please type to search',
  },
  loading: {
    id: 'text-loading',
    defaultMessage: 'Loading...',
  },
});
