import React from 'react';
import { Flex } from 'glints-aries/es';
import { RadioButton } from 'glints-aries/es/@next';
import { space12 } from 'glints-aries/es/@next/utilities/spacing';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import {
  ApplicationRejectionMessagePreference,
  RejectionReasonPreferenceFormState,
} from '../../types';
import {
  rejectionMethodExplanationMessages,
  rejectionMethodMessages,
} from '../consts';
import UnifiedMessageInput from './UnifiedMessageInput';

const RadioWrapper = styled.div`
  & label:first-child {
    padding-bottom: ${space12};
  }
`;

export default function UnifiedMessageOption() {
  const { formatMessage } = useIntl();
  const { control } = useFormContext<RejectionReasonPreferenceFormState>();
  return (
    <Controller
      name="preferredType"
      control={control}
      render={({ field }) => (
        <RadioWrapper>
          <RadioButton
            {...field}
            checked={
              field.value ===
              ApplicationRejectionMessagePreference.UNIFIED_MESSAGE
            }
            label={formatMessage(
              rejectionMethodMessages[
                ApplicationRejectionMessagePreference.UNIFIED_MESSAGE
              ]
            )}
            helperText={formatMessage(
              rejectionMethodExplanationMessages[
                ApplicationRejectionMessagePreference.UNIFIED_MESSAGE
              ]
            )}
            value={ApplicationRejectionMessagePreference.UNIFIED_MESSAGE}
          />
          <If
            condition={
              field.value ===
              ApplicationRejectionMessagePreference.UNIFIED_MESSAGE
            }
          >
            <Flex>
              {/* This hidden RadioButton is used to get the radio size as spacing */}
              <RadioButton
                style={{ opacity: 0, cursor: 'default' }}
                disabled={true}
              />
              <UnifiedMessageInput />
            </Flex>
          </If>
        </RadioWrapper>
      )}
    />
  );
}
