const paramsToObject = (entries: IterableIterator<[string, string]>) => {
  const result: { [key: string]: string } = {};
  for (const entry of entries) {
    const [key, value] = entry;
    result[key] = value;
  }
  return result;
};

export const searchStringToParamsObject = (search: string) =>
  paramsToObject(new URLSearchParams(search).entries());
