/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import React, { Component } from 'react';
import { Row } from 'antd';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { updateMetadata } from '../../actions/meta';
import { BtnBlue } from '../../components/Buttons';
import styles from './styles.scss';

class Error404Page extends Component {
  static propTypes = {
    updateMetadata: PropTypes.func,
    intl: PropTypes.object,
    uuid: PropTypes.object,
    location: PropTypes.object,
  };

  componentDidMount() {
    this.props.updateMetadata(
      this.props.intl.formatMessage(messages.pageNotFound),
      null,
      null,
      404
    );
  }

  render() {
    const { intl, uuid, location } = this.props;
    const uuidCheck =
      /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/;
    const isUUIDValid = uuidCheck.test(location?.state?.uuid);
    return (
      <Row
        className={styles.content}
        type="flex"
        justify="center"
        align="middle"
      >
        <div className={styles.imageWrapper}>
          <img
            src="/images/404.svg"
            alt={intl.formatMessage(messages.notFound)}
          />
        </div>
        {location?.state?.uuid && !isUUIDValid ? (
          <div className={styles.textWrapper}>
            <h4>
              <FormattedMessage
                tagName="strong"
                id="modules.ErrorPages.Error404.uuid.not.found"
                defaultMessage="UUID {uuid} not valid"
                values={{ uuid: uuid || location.state?.uuid }}
              />
            </h4>
            <p>
              <FormattedMessage
                id="modules.ErrorPages.Error404.please.contact"
                defaultMessage="Please contact an administrator for more information."
              />
            </p>
          </div>
        ) : (
          <div className={styles.textWrapper}>
            <h4>
              <FormattedMessage
                tagName="strong"
                id="modules.ErrorPages.Error404.page.not.found"
                defaultMessage="Page Not Found"
              />
            </h4>
            <p>
              <FormattedMessage
                id="modules.ErrorPages.Error404.whoops.you.may"
                defaultMessage="Whoops, you may have wandered too far!"
              />
              <br />
              <FormattedMessage
                id="modules.ErrorPages.Error404.the.page.you"
                defaultMessage="(The page you have requested was not found or might have been moved.)"
              />
            </p>
          </div>
        )}
        <Link to="/">
          <BtnBlue>
            <FormattedMessage
              id="modules.ErrorPages.Error404.take.me.home"
              defaultMessage="Take me home!"
            />
          </BtnBlue>
        </Link>
      </Row>
    );
  }
}

Error404Page = connect(null, dispatch => ({
  updateMetadata: (title, description, image, prerenderStatusCode) =>
    dispatch(updateMetadata(title, description, image, prerenderStatusCode)),
}))(injectIntl(Error404Page));

const messages = defineMessages({
  pageNotFound: {
    id: 'modules.ErrorPages.Error404.page.not.found',
    defaultMessage: 'Page Not Found',
  },
  notFound: {
    id: 'modules.ErrorPages.Error404.404.not.found',
    defaultMessage: '404 Not Found',
  },
});

export default Error404Page;
