import useSWR, { SWRConfiguration } from 'swr';

import { User } from '../../models/User';

export interface CompanyUser {
  id: string;
  role: string;
  UserId: string;
  CompanyId: string;
  createdAt: string;
  updatedAt: string;
  links: { user: User };
}

type Options = Partial<{
  limit: string;
  offset: string;
  where: string;
}>;

const useCompanyUsers = (
  companyId: string,
  options: Options = {},
  swrOptions?: SWRConfiguration
) => {
  const params = new URLSearchParams({ include: 'User', ...options });
  return useSWR<{
    data: CompanyUser[];
  }>(`/companies/${companyId}/userCompanies?${params}`, swrOptions);
};

export default useCompanyUsers;
