import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  GetAssetURLCallback,
  useGetAssetURL,
} from '../../../../common/asset-manager';
import { COUNTRY_DOMAINS } from '../../../../common/constants';
import { getConfig } from '../../../../config';
import {
  CompanyLinks,
  LinksSection,
  LinksSectionWrapper,
  Title,
} from '../../FooterGlints.sc';
import { getLinks } from './links';
import { renderLinks } from './utils';

const getLinkData = (getAssetUrl: GetAssetURLCallback) => {
  const country = getConfig().COUNTRY;
  const links = getLinks(getAssetUrl);
  switch (country) {
    case COUNTRY_DOMAINS.sg:
      return links.sg;
    default:
      return links.default;
  }
};

export default function DefaultLinksSection() {
  const { getAssetURL } = useGetAssetURL();

  return (
    <LinksSectionWrapper>
      {getLinkData(getAssetURL).map(({ title, links }) => (
        <LinksSection key={title.id}>
          <FormattedMessage tagName={Title} {...title} />
          <CompanyLinks>{renderLinks(links)}</CompanyLinks>
        </LinksSection>
      ))}
    </LinksSectionWrapper>
  );
}
