import React from 'react';
import { Button } from 'glints-aries';
import { Props } from 'glints-aries/lib/General/Button/Button';

const AriesButton: React.FC = (props: Props & { onClick?: string }) => {
  const { onClick, ...rest } = props;

  // eslint-disable-next-line no-eval
  return <Button {...rest} onClick={onClick ? eval(onClick) : undefined} />;
};

export default AriesButton;
