import {
  Greyscale,
  PrimaryColor,
  SecondaryColor,
  Shadow as AriesShadowStyles,
} from 'glints-aries';

export const Text = {
  color: {
    primary: Greyscale.black,
    secondary: Greyscale.devilsgrey,
    lightSecondary: Greyscale.grey,
    action: SecondaryColor.actionblue,
    danger: PrimaryColor.glintsred,
  },
};

export const Icon = {
  color: {
    secondary: Greyscale.devilsgrey,
    action: SecondaryColor.actionblue,
    darkAction: SecondaryColor.darkblue,
    lightSecondary: Greyscale.grey,
  },
};

export const Link = {
  color: { hover: '#132647' },
};

const BorderColors = {
  primary: Greyscale.softgrey,
  darkPrimary: Greyscale.grey,
  alt: Greyscale.lightgrey,
  secondary: SecondaryColor.actionblue,
  disabled: Greyscale.lightgrey,
  hover: '#d6f4ff',
};

const BorderWidths = { primary: '2px', alt: '1px' };

const BorderStyles = {
  primary: `${BorderWidths.primary} solid ${BorderColors.primary}`,
  alt: `${BorderWidths.alt} solid ${BorderColors.alt}`,
  secondary: `${BorderWidths.primary} solid ${BorderColors.secondary}`,
};

export const Border = {
  color: BorderColors,
  width: BorderWidths,
  style: BorderStyles,
};

export const Shadow = {
  color: {
    action: SecondaryColor.actionblue,
    actionTransparent: `${SecondaryColor.actionblue}80`,
  },
  style: {
    default: AriesShadowStyles.down1,
    hover: AriesShadowStyles.down2,
    sticky: AriesShadowStyles.down3,
    dropdown: AriesShadowStyles.down3,
    alert: AriesShadowStyles.down4,
    negative: AriesShadowStyles.up3,
  },
};

export const Background = {
  color: {
    action: SecondaryColor.actionblue,
    disabled: Greyscale.lightgrey,
    hover: '#d6f4ff',
  },
};

export const Divider = Border;

export const Page = {
  gap: {
    top: '48px',
    bottom: '64px',
  },
};
