import React, { Fragment } from 'react';

interface RegionalLink {
  href?: string;
  image: string;
  name: string;
  alt: string;
}

const BASE_URL =
  window.location.hostname === 'localhost'
    ? 'https://employers.staging.glints'
    : window.location.origin.split('.').slice(0, -1).join('.');

const IMAGE_PATH = '/images/flags';

const REGIONAL_LINKS: RegionalLink[] = [
  {
    href: `${BASE_URL}.id`,
    image: `${IMAGE_PATH}/indonesia.png`,
    name: 'Indonesia',
    alt: 'Glints for Employers Indonesia',
  },
  {
    href: `${BASE_URL}.my`,
    image: `${IMAGE_PATH}/malaysia.png`,
    name: 'Malaysia',
    alt: 'Glints for Employers Malaysia',
  },
  {
    href: `${BASE_URL}.sg`,
    image: `${IMAGE_PATH}/singapore.png`,
    name: 'Singapore',
    alt: 'Glints for Employers Singapore',
  },
  {
    href: `${BASE_URL}.vn`,
    image: `${IMAGE_PATH}/vietnam.png`,
    name: 'Vietnam',
    alt: 'Glints for Employers Vietnam',
  },
  {
    image: `${IMAGE_PATH}/hongkong.png`,
    name: 'Hongkong',
    alt: 'Glints for Employers Hongkong',
  },
  {
    image: `${IMAGE_PATH}/china.png`,
    name: 'China',
    alt: 'Glints for Employers China',
  },
  {
    image: `${IMAGE_PATH}/ph.svg`,
    name: 'Philippines',
    alt: 'Glints for Employers Philippines',
  },
];

const RegionalLinks = () => (
  <Fragment>
    {REGIONAL_LINKS.map(({ name, href, image, alt }: RegionalLink) => (
      <>
        <If condition={href}>
          <a key={name} href={href} target="_blank" rel="noreferrer">
            <img loading="lazy" src={image} alt={alt} />
          </a>
        </If>
        <If condition={!href}>
          <img loading="lazy" src={image} alt={alt} key={name} />
        </If>
      </>
    ))}
  </Fragment>
);

export default RegionalLinks;
