import { createAction } from 'redux-actions';

import { fetchJob } from '../../actions/job';
import axios from '../../common/axios-ts';
import { USER_ROLES } from '../../common/constants';
import CALL_API from '../../middleware/api_key';
import { Schemas } from '../../middleware/schemas';
import { getSessionToken } from '../../selectors/session';

export const Actions = {
  APPROVE_COMPANY_USER_REQUEST:
    'glints/companyDashboard/APPROVE_COMPANY_USER_REQUEST',
  APPROVE_COMPANY_USER_SUCCESS:
    'glints/companyDashboard/APPROVE_COMPANY_USER_SUCCESS',
  APPROVE_COMPANY_USER_FAILURE:
    'glints/companyDashboard/APPROVE_COMPANY_USER_FAILURE',

  REJECT_COMPANY_USER_REQUEST:
    'glints/companyDashboard/REJECT_COMPANY_USER_REQUEST',
  REJECT_COMPANY_USER_SUCCESS:
    'glints/companyDashboard/REJECT_COMPANY_USER_SUCCESS',
  REJECT_COMPANY_USER_FAILURE:
    'glints/companyDashboard/REJECT_COMPANY_USER_FAILURE',

  RESET_COMPANY_USER_STATE: 'glints/companyDashboard/RESET_COMPANY_USER_STATE',

  EXTEND_JOB_EXPIRY_REQUEST:
    'glints/companyDashboard/EXTEND_JOB_EXPIRY_REQUEST',
  EXTEND_JOB_EXPIRY_SUCCESS:
    'glints/companyDashboard/EXTEND_JOB_EXPIRY_SUCCESS',
  EXTEND_JOB_EXPIRY_FAILURE:
    'glints/companyDashboard/EXTEND_JOB_EXPIRY_FAILURE',
};

export const approveCompanyUserRequest = createAction(
  Actions.APPROVE_COMPANY_USER_REQUEST
);
export const approveCompanyUserSuccess = createAction(
  Actions.APPROVE_COMPANY_USER_SUCCESS
);
export const approveCompanyUserFailure = createAction(
  Actions.APPROVE_COMPANY_USER_FAILURE
);

export const rejectCompanyUserRequest = createAction(
  Actions.REJECT_COMPANY_USER_REQUEST
);
export const rejectCompanyUserSuccess = createAction(
  Actions.REJECT_COMPANY_USER_SUCCESS
);
export const rejectCompanyUserFailure = createAction(
  Actions.REJECT_COMPANY_USER_FAILURE
);

export const resetCompanyUserState = createAction(
  Actions.RESET_COMPANY_USER_STATE
);

export const extendJobExpiryRequest = createAction(
  Actions.EXTEND_JOB_EXPIRY_REQUEST
);
export const extendJobExpirySuccess = createAction(
  Actions.EXTEND_JOB_EXPIRY_SUCCESS
);
export const extendJobExpiryFailure = createAction(
  Actions.EXTEND_JOB_EXPIRY_FAILURE
);

export function approveCompanyUser(userId, email, token, companyId) {
  return async dispatch => {
    try {
      dispatch(approveCompanyUserRequest());
      await axios(token).post(`/companies/${companyId}/userCompanies`, {
        data: {
          UserId: userId,
          email: email,
          role: USER_ROLES.RECRUITER,
        },
      });
      dispatch(approveCompanyUserSuccess());
    } catch (err) {
      dispatch(approveCompanyUserFailure(err));
    }
  };
}

export function rejectCompanyUser(token, companyId, companyUserId, userId) {
  return async dispatch => {
    try {
      dispatch(rejectCompanyUserRequest());
      await axios(token).put(
        `/companies/${companyId}/pendingUserCompanies/${companyUserId}`,
        {
          data: {
            UserId: userId,
            status: 'REJECTED',
          },
        }
      );
      dispatch(rejectCompanyUserSuccess());
    } catch (err) {
      dispatch(rejectCompanyUserFailure(err));
    }
  };
}

export function extendJobExpiryWithAxios(jobId) {
  return async (dispatch, getState) => {
    try {
      dispatch(extendJobExpiryRequest());
      const response = await axios(getSessionToken(getState())).post(
        `jobs/${jobId}/expiryExtension`
      );
      dispatch(extendJobExpirySuccess(response.data));
      return response.data;
    } catch (err) {
      dispatch(extendJobExpiryFailure(err));
      throw err;
    }
  };
}

export function extendJobExpiry(jobId) {
  return async dispatch => {
    try {
      return await dispatch({
        [CALL_API]: {
          types: [
            Actions.EXTEND_JOB_EXPIRY_REQUEST,
            Actions.EXTEND_JOB_EXPIRY_SUCCESS,
            Actions.EXTEND_JOB_EXPIRY_FAILURE,
          ],
          method: 'post',
          endpoint: `jobs/${jobId}/expiryExtension`,
          schema: Schemas.JOB,
        },
      });
    } catch (error) {
      if (error.response.status === 403) {
        // This is actually how this endpoint responds to extension requests for
        // jobs with pending applications. This is handled properly later on, so
        // here we just need to catch the error and not re-throw it to suppress
        // the error message in the console.
      } else {
        throw error;
      }
    }
    dispatch(fetchJob(jobId));
  };
}
