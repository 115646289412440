import { createContext } from 'react';

export type NavigationContext = {
  setShowAlternativeHeader: (_: {
    showAlertNativeHeader: boolean;
    alternativeHeaderContent: JSX.Element | null;
  }) => void;
};

export const NavigationContext = createContext<NavigationContext | undefined>(
  undefined
);

export const NavigationProvider = NavigationContext.Provider;
