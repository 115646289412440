const unaccentVietnamese = (input: string) => {
  input = input.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  input = input.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  input = input.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  input = input.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  input = input.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  input = input.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  input = input.replace(/đ/g, 'd');
  input = input.replace(/ð/g, 'd'); // Lowercase eth. Uppercase looks like D with dash and is sometimes incorrectly used in our database
  input = input.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '');
  input = input.replace(/\u02C6|\u0306|\u031B/g, '');
  return input;
};

export const unaccent = (input: string) => {
  input = String.prototype.normalize()
    ? input.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    : input;
  input = unaccentVietnamese(input);
  return input;
};

export const sanitize = (input: string) => unaccent(input.toLowerCase().trim());

export const filterSanitizedOptions = (
  input: any,
  option: { [s: string]: unknown } | ArrayLike<unknown>
) => sanitize(Object.values(option).join('')).includes(sanitize(input));
