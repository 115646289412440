import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { WHATSAPP_CHAT_SUPPORT_ENABLED_COUNTRIES } from '../../common/constants';
import config from '../../config';
import { areFeaturesFetched, getFeatures } from '../../selectors/features';
import { WhatsappButton } from '../CompanyDashboardV2/JobShareModal/JobShareModal.sc';
import { ZendeskVisibilityContext } from './ZendeskVisibilityContext';

const ZENDESK_LAZY_LOAD_TIMEOUT = 10000;

type Props = {
  children: React.ReactNode;
};

export const ZendeskVisibilityProvider = ({ children }: Props) => {
  const { empShowWhatsappIcon } = useSelector(getFeatures);
  const featureFetched = useSelector(areFeaturesFetched);
  const isWhatsappChatSupportEnabled =
    WHATSAPP_CHAT_SUPPORT_ENABLED_COUNTRIES.includes(config.COUNTRY) &&
    empShowWhatsappIcon;

  const [isZendeskMessagingVisible, setIsZendeskMessagingVisible] =
    useState<boolean>(true);

  const hideZendeskMessaging = () => setIsZendeskMessagingVisible(false);
  const showZendeskMessaging = () => setIsZendeskMessagingVisible(true);

  useEffect(() => {
    if (featureFetched && !isWhatsappChatSupportEnabled) {
      const zenDeskScript = document.createElement('script');
      zenDeskScript.id = 'ze-snippet';
      zenDeskScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${config.ZENDESK_WIDGET_KEY}`;
      setTimeout(
        () => document.body.appendChild(zenDeskScript),
        ZENDESK_LAZY_LOAD_TIMEOUT
      );
    }
  }, [featureFetched, isWhatsappChatSupportEnabled]);

  useEffect(() => {
    try {
      if (window.zE) {
        window.zE(
          'messenger:set',
          'zIndex',
          isZendeskMessagingVisible ? 99999 : -99999
        );
      }
    } catch (error) {
      // Since Zendesk is 3rd party lib. any failure to it should not cause entire UI to fail
      // Catch and report error to avoid showing fallback UI due to unhandled error
      console.error(error);
    }
  }, [isZendeskMessagingVisible]);

  return (
    <ZendeskVisibilityContext.Provider
      value={{
        showZendeskMessaging,
        hideZendeskMessaging,
        isZendeskMessagingVisible,
      }}
    >
      {children}
      <WhatsappButton />
    </ZendeskVisibilityContext.Provider>
  );
};
