import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

export const getGraphqlErrorCode = (error: ApolloError | undefined) =>
  error?.graphQLErrors?.[0]?.extensions?.code as string | undefined;

export const makeApolloErrorFromCode = (
  code: string,
  message?: string
): ApolloError => {
  const graphQLError = new GraphQLError(
    message || 'A client side custom error',
    {
      extensions: {
        code,
      },
    }
  );

  return new ApolloError({
    graphQLErrors: [graphQLError],
  });
};
