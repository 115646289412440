import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import classNames from 'classnames';
import { isEmpty, isFunction } from 'lodash-es';
import PropTypes from 'prop-types';

import { SelectContainer } from './SelectField.sc';

const SelectField = ({
  className,
  children,
  label,
  value,
  error,
  onBlur,
  onSelect,
  onSelectCallback,
  onBlurCallback,
  onFocusCallBack,
  onSelectBlur,
  onSearch,
  disabled,
  ...props
}) => {
  const [isFocus, setIsFocus] = useState(Boolean(value || false));

  const container = useRef(null);

  const handleOnSelect = useCallback(
    (value, option) => {
      if (onSelect && isFunction(onSelect)) {
        onSelect(value, option);
      }

      if (onSelectCallback && isFunction(onSelectCallback)) {
        onSelectCallback();
      }
    },
    [onSelect, onSelectCallback]
  );

  const handleBlur = useCallback(() => {
    if (isFunction(onBlur)) {
      // https://github.com/ant-design/ant-design/issues/23372#issuecomment-622467235
      onBlur(value);
    }

    setIsFocus(false);
    if (isFunction(onBlurCallback)) {
      onBlurCallback();
    }
  }, [onBlur, onBlurCallback, value]);

  useEffect(() => {
    if (label && !isEmpty(value) && !isFocus) {
      setIsFocus(true);
    }
  }, [value, isFocus, label]);

  return (
    <SelectContainer
      className={className}
      error={error}
      disabled={disabled}
      ref={container}
      onClick={() => {
        if (!isFocus && !disabled) {
          setIsFocus(true);
        }
      }}
    >
      <If condition={label}>
        <div
          className={classNames(
            'label-wrapper',
            isFocus ? 'input-focus' : null
          )}
        >
          <label className={isFocus ? 'input-focus-label' : null}>
            {label}
          </label>
        </div>
      </If>
      <Select
        defaultValue={value}
        value={value}
        className={classNames(className)}
        onFocus={() => {
          setIsFocus(true);
          if (isFunction(onFocusCallBack)) {
            onFocusCallBack();
          }
        }}
        onBlur={handleBlur}
        onDropdownVisibleChange={open => setIsFocus(open)}
        onSelect={(value, option) => {
          if (onSelectBlur) {
            setIsFocus(false);
          }
          handleOnSelect(value, option);
        }}
        getPopupContainer={() => container.current}
        disabled={disabled}
        // This delays calling the onSearch callback to give us time eg to react
        // to onBlur before Select erases its input upon clicking outside the
        // component
        onSearch={
          onSearch && ((...args) => setImmediate(() => onSearch(...args)))
        }
        showSearch={isFunction(onSearch)}
        {...props}
      >
        {children}
      </Select>
    </SelectContainer>
  );
};

SelectField.Option = Select.Option;
SelectField.OptGroup = Select.OptGroup;

SelectField.propTypes = {
  children: PropTypes.node,

  className: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.node,

  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onSelectBlur: PropTypes.bool,

  onBlur: PropTypes.func,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  onSelectCallback: PropTypes.func,
  onBlurCallback: PropTypes.func,
  onFocusCallBack: PropTypes.func,
};

export default SelectField;
