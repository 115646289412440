import React, { useState } from 'react';

import {
  AdditionalText,
  DescriptionContainer,
  Icon,
  List,
  Position,
  Role,
  RolesContainer,
  RoleWithArrowContainer,
} from './Roles.sc';

type RolesType = {
  name: string;
  description: string;
  additional: string;
};

const Roles = (props: { roles: RolesType[] }) => {
  const { roles } = props;

  const [selectedRole, setSelectedRole] = useState(roles?.[0]);
  return (
    <>
      <RolesContainer>
        {roles.map(role => {
          const isActive = selectedRole.name === role.name;
          return (
            <RoleWithArrowContainer key={role.name}>
              <Role
                style={{
                  background: isActive ? '#fff' : 'transparent',
                  color: isActive ? '#2d2d2d' : '#666',
                  border: isActive ? '1px solid #1f2c3a' : '1px solid #a6cbdc',
                  boxShadow: isActive ? '0 0 0 2px #1f2c3a' : 'none',
                }}
                role="button"
                onClick={() => setSelectedRole(role)}
              >
                {role.name}
              </Role>
              {isActive && <Icon src="/images/how-it-works/arrow.svg" />}
            </RoleWithArrowContainer>
          );
        })}
      </RolesContainer>
      {selectedRole.description && (
        <DescriptionContainer>
          <List>
            {selectedRole.description.split(',').map(position => (
              <Position key={position}>{position},</Position>
            ))}
          </List>
          <AdditionalText>{selectedRole.additional}</AdditionalText>
        </DescriptionContainer>
      )}
    </>
  );
};

export default Roles;
