import { Greyscale, PrimaryColor } from 'glints-aries';
import styled, { css } from 'styled-components';

import { Shadow } from '../../styles/variables';

const overrideAntSelectCSS = ({ error, disabled }) => css`
  .label-wrapper {
    font-size: 16px;
    position: absolute;
    z-index: 100;
    left: 17px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s;
    color: ${disabled ? Greyscale.lightgrey : Greyscale.grey};
    pointer-events: none;
    background: ${disabled ? '#F5F5F5' : 'transparent'};
  }

  .input-focus {
    font-size: 12px;
    left: 10px;
    top: 0;
    color: black;
    background: ${disabled ? '#F5F5F5' : Greyscale.white};
  }

  .input-focus-label {
    padding: 0 5px;
  }

  .ant-select-selection--single {
    height: auto;
    padding: 7px 4px;
  }

  .ant-select {
    font-size: 16px;
    display: block;
    border-color: ${error ? PrimaryColor.glintsred : Greyscale.grey};
  }

  .ant-select-selection {
    border: 2px solid;
    border-color: ${error ? PrimaryColor.glintsred : Greyscale.grey};
  }

  .ant-select-selection__rendered {
    line-height: 2;
  }

  .ant-select-dropdown-menu-item-group {
    position: relative;
  }

  .ant-select-item-group {
    position: sticky;
    top: 0;
    background-color: ${Greyscale.white};
    z-index: 9999;
    font-weight: bold;
    font-size: 16px;
    color: ${PrimaryColor.glintsblue};
    margin-right: 1em;
  }

  .ant-select-single .ant-select-selector {
    border: none;
  }

  .ant-select-dropdown {
    box-shadow: ${Shadow.style.dropdown};
  }
`;

export const SelectContainer = styled.div`
  position: relative;
  ${() => overrideAntSelectCSS}
`;
