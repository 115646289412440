import { createAction } from 'redux-actions';

import { setSessionToken } from '../../../actions/session';
import axios from '../../../common/axios-ts';
import config from '../../../config';
import { getLinkedInCsrfToken } from './selectors';

export const Actions = {
  LOGIN_REQUEST: 'glints/login/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'glints/login/LOGIN_SUCCESS',
  LOGIN_FAILURE: 'glints/login/LOGIN_FAILURE',

  RESET_LOGIN_STATE: 'glints/login/RESET_LOGIN_STATE',

  LOGIN_WITH_FACEBOOK_REQUEST: 'glints/login/LOGIN_WITH_FACEBOOK_REQUEST',
  LOGIN_WITH_FACEBOOK_SUCCESS: 'glints/login/LOGIN_WITH_FACEBOOK_SUCCESS',
  LOGIN_WITH_FACEBOOK_FAILURE: 'glints/login/LOGIN_WITH_FACEBOOK_FAILURE',

  LOGIN_WITH_LINKEDIN_REQUEST: 'glints/login/LOGIN_WITH_LINKEDIN_REQUEST',
  LOGIN_WITH_LINKEDIN_SUCCESS: 'glints/login/LOGIN_WITH_LINKEDIN_SUCCESS',
  LOGIN_WITH_LINKEDIN_FAILURE: 'glints/login/LOGIN_WITH_LINKEDIN_FAILURE',
};

const loginRequest = createAction(Actions.LOGIN_REQUEST);
const loginSuccess = createAction(Actions.LOGIN_SUCCESS);
const loginFailure = createAction(Actions.LOGIN_FAILURE);

export const resetLoginState = createAction(Actions.RESET_LOGIN_STATE);

const loginWithFacebookRequest = createAction(
  Actions.LOGIN_WITH_FACEBOOK_REQUEST
);
const loginWithFacebookSuccess = createAction(
  Actions.LOGIN_WITH_FACEBOOK_SUCCESS
);
const loginWithFacebookFailure = createAction(
  Actions.LOGIN_WITH_FACEBOOK_FAILURE
);

const loginWithLinkedInRequest = createAction(
  Actions.LOGIN_WITH_LINKEDIN_REQUEST
);
const loginWithLinkedInSuccess = createAction(
  Actions.LOGIN_WITH_LINKEDIN_SUCCESS
);
const loginWithLinkedInFailure = createAction(
  Actions.LOGIN_WITH_LINKEDIN_FAILURE
);

export function login(email: string, password: string) {
  return async (dispatch: any) => {
    try {
      dispatch(loginRequest());
      /* eslint-disable camelcase */
      const response = await axios(null, config.OAUTH2_BASE).post('token', {
        grant_type: 'password',
        client_id: config.CLIENT_ID,
        username: email,
        password,
      });
      /* eslint-enable camelcase */
      await dispatch(setSessionToken(response.data['access_token']));
      dispatch(loginSuccess());
    } catch (err) {
      dispatch(loginFailure(err));
    }
  };
}

export function loginWithFacebook(code: string) {
  return async (dispatch: any) => {
    try {
      dispatch(loginWithFacebookRequest());
      const response = await axios(null, config.OAUTH2_BASE).post('/facebook', {
        code,
        clientId: config.FACEBOOK_APPID,
        apiClientId: config.CLIENT_ID,
        redirectUri: `${
          document.getElementsByTagName('base')[0].href
        }oauth2/facebook`,
        role: 'COMPANY',
      });
      await dispatch(setSessionToken(response.data.token));
      dispatch(loginWithFacebookSuccess());
    } catch (err) {
      dispatch(loginWithFacebookFailure(err));
    }
  };
}

export function loginWithLinkedIn(code: string) {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(loginWithLinkedInRequest());
      const response = await axios(null, config.OAUTH2_BASE).post('/linkedin', {
        code,
        clientId: config.LINKEDIN_APPID,
        apiClientId: config.CLIENT_ID,
        redirectUri: `${
          document.getElementsByTagName('base')[0].href
        }oauth2/linkedin`,
        csrfToken: getLinkedInCsrfToken(getState()),
        role: 'COMPANY',
      });
      await dispatch(setSessionToken(response.data.token));
      dispatch(loginWithLinkedInSuccess());
    } catch (err) {
      dispatch(loginWithLinkedInFailure(err));
    }
  };
}
