import React from 'react';
import { Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { useGetDivElementHeight } from '../../../common/hooks/useGetDivElementHeight';
import { CurrentCompanyJobSlotsSubscription } from '../../../common/interfaces/entities';
import { StickyContainer, StyledBanner } from '../styles.sc';
import {
  PremiumBannerImage,
  SubscriptionUpgradeToPremiumBannerContainer,
} from './styles.sc';

type Props = {
  setFixedBannerHeight: (height: number) => void;
  closeBanner: () => void;
  companyJobSlotSubscription: Pick<
    CurrentCompanyJobSlotsSubscription,
    'translatedPlanName' | 'totalConcurrentJobSlots'
  >;
};

export const SubscriptionUpgradeToPremiumBanner = ({
  closeBanner,
  setFixedBannerHeight,
  companyJobSlotSubscription,
}: Props) => {
  const { ref } = useGetDivElementHeight({
    setElementHeight: setFixedBannerHeight,
  });

  return (
    <StickyContainer ref={ref}>
      <StyledBanner
        type="fixed"
        status="info"
        showIcon={false}
        onDismiss={() => {
          closeBanner();
          setFixedBannerHeight(0);
        }}
      >
        <SubscriptionUpgradeToPremiumBannerContainer>
          <PremiumBannerImage src="/images/subscription-premium-upgrade-banner.svg" />
          <Typography variant="body2" color={Neutral.B18}>
            <FormattedMessage
              id="interactive-free-plan-where-started"
              defaultMessage="{count, plural, =1 {Hello! Your {packageName} Plan only allows you to hire 1 role at a time.{br}<link>Upgrade now to hire multiple roles simultaneously!</link>} other {Hello! Your {packageName} Plan only allows you to hire {count} roles at a time.{br}<link>Upgrade now to hire more than {count} roles simultaneously!</link>}}"
              values={{
                count: companyJobSlotSubscription.totalConcurrentJobSlots,
                packageName: companyJobSlotSubscription.translatedPlanName,
                link: (text: React.ReactNode) => (
                  <Link to="/upgrade-plan">{text}</Link>
                ),
                br: <br />,
              }}
            />
          </Typography>
        </SubscriptionUpgradeToPremiumBannerContainer>
      </StyledBanner>
    </StickyContainer>
  );
};
