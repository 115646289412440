import React, { Component } from 'react';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export default function authenticate(WrappedComponent, optional = false) {
  class AuthenticatedComponent extends Component {
    static propTypes = {
      authenticated: PropTypes.bool.isRequired,
      dispatch: PropTypes.func.isRequired,
      location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
      }),
    };

    componentDidMount() {
      this.checkAuthenticated();
    }

    componentDidUpdate() {
      this.checkAuthenticated();
    }

    checkAuthenticated() {
      if (!this.props.authenticated && !optional) {
        const locationSearch = this.props.location.search;
        const redirectAfterLogin =
          this.props.location.pathname + locationSearch;

        const goToSignup = locationSearch.includes('signupIfUnauth=true');

        if (goToSignup) {
          this.props.dispatch(push(`/signup`));
        } else {
          this.props.dispatch(push(`/login?next=${redirectAfterLogin}`));
        }
      }
    }

    render() {
      if (this.props.authenticated || optional) {
        return <WrappedComponent {...this.props} />;
      }
      return null;
    }
  }

  return connect(state => ({
    authenticated: Boolean(state.session.token),
  }))(AuthenticatedComponent);
}
