import React from 'react';
import { Flex } from 'glints-aries';
import { merge } from 'lodash-es';
import { useMessageContext } from 'stream-chat-react';

import {
  ApplicationChatContactsExchangeRequestStatus,
  ApplicationChatContactsRequestActionType,
} from '../../constants';
import {
  getMessageApplicationMetadata,
  isContactsRequestAction,
} from '../../helper';
import CddContactsRequestMessageContent from './CddContactsRequestMessageContent';
import EmpContactsRequestMessageContent from './EmpContactsRequestMessageContent';
import ContactsExchangeStatusSystemMessage from './StatusSystemMessage';

const ContactsRequestMessage = () => {
  const { message } = useMessageContext();
  const metadata = getMessageApplicationMetadata(message);

  if (!metadata || !isContactsRequestAction(metadata)) return null;

  return (
    <Flex flexDirection="column" alignItems="center" style={{ gap: 16 }}>
      <ContactsExchangeStatusSystemMessage
        metadata={merge({}, metadata, {
          action_payload: {
            status: ApplicationChatContactsExchangeRequestStatus.PENDING,
          },
        })}
      />

      <If
        condition={
          metadata.action_type ===
          ApplicationChatContactsRequestActionType.CONTACTS_REQUEST_EMP
        }
      >
        <EmpContactsRequestMessageContent metadata={metadata} />
      </If>
      <If
        condition={
          metadata.action_type ===
          ApplicationChatContactsRequestActionType.CONTACTS_REQUEST_CDD
        }
      >
        <CddContactsRequestMessageContent metadata={metadata} />
      </If>
    </Flex>
  );
};

export default ContactsRequestMessage;
