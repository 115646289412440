import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, FacebookIcon, Loading } from 'glints-aries';
import PropTypes from 'prop-types';

import { FacebookAuthDialog } from '../dialog/facebook-auth-dialog';
import styles from './AriesButtons.scss';

const LoginWithFacebookButton = ({
  className,
  buttonText,
  linkFacebook,
  loading,
  ...props
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClick = e => {
    if (e) {
      e.preventDefault();
    }
    setIsDialogOpen(true);
  };

  return (
    <Button
      onClick={handleClick}
      variant="solid-blue"
      style={{
        lineHeight: 1,
        padding: '15px 40px',
        backgroundColor: '#405a93',
        display: 'flex',
        alignItems: 'baseline',
      }}
      className={classNames(styles.btn, className)}
      {...props}
    >
      <FacebookIcon className={styles.icon} />
      &nbsp;&nbsp;{buttonText}
      <If condition={loading}>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Loading className={styles.loading} />
      </If>
      <FacebookAuthDialog
        onClosing={() => setIsDialogOpen(false)}
        onReceiveAuthCode={linkFacebook}
        facebookDialogOpen={isDialogOpen}
      />
    </Button>
  );
};

LoginWithFacebookButton.propTypes = {
  className: PropTypes.string,
  linkFacebook: PropTypes.func.isRequired,
  buttonText: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};

export default LoginWithFacebookButton;
