import React from 'react';
import dayjs from 'dayjs';
import { Flex, Greyscale } from 'glints-aries';
import { Badge, Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { defineMessages, FormattedMessage } from 'react-intl';

import { JOB_STATUS, JOB_TYPE_LABEL } from '../../../common/constants';
import NodesJoin from '../../../components/NodesJoin';
import { GetApplicationByIdData } from '../graphql';
import MiddleDot from './MiddleDot';

const jobStatusToBadgeStatusMap = {
  [JOB_STATUS.OPEN]: 'success',
  [JOB_STATUS.IN_REVIEW]: 'information',
  [JOB_STATUS.CLOSED]: 'critical',
} as const;

const jobStatusLabel = defineMessages({
  [JOB_STATUS.OPEN]: {
    id: 'text-active',
    defaultMessage: 'Active',
  },
  [JOB_STATUS.IN_REVIEW]: {
    id: 'text-in-review',
    defaultMessage: 'In Review',
  },
  [JOB_STATUS.CLOSED]: {
    id: 'interactive-inactive',
    defaultMessage: 'Inactive',
  },
});

type Props = {
  job: GetApplicationByIdData['getApplicationById']['job'];
};

const ApplicationJobInfo = ({ job }: Props) => (
  <Flex
    p={16}
    flexDirection="column"
    style={{
      gap: 8,
      border: `1px solid ${Neutral.B85}`,
      borderRadius: 8,
      backgroundColor: Greyscale.white,
    }}
  >
    <Flex
      alignItems="center"
      justifyContent="space-between"
      data-cy="application-job-title-message"
    >
      <Typography variant="body2" color={Neutral.B18}>
        {job.title}
      </Typography>
      <Badge status={jobStatusToBadgeStatusMap[job.status]}>
        <Typography variant="overline" as="b">
          <FormattedMessage {...jobStatusLabel[job.status]} />
        </Typography>
      </Badge>
    </Flex>
    <Typography variant="subtitle2" color={Greyscale.devilsgrey}>
      <NodesJoin separator={<MiddleDot />}>
        <FormattedMessage {...JOB_TYPE_LABEL[job.type]} />
        <FormattedMessage
          id="text_yoe-range"
          defaultMessage="{min}-{max} yrs"
          values={{
            min: job.minYearsOfExperience,
            max: job.maxYearsOfExperience,
          }}
        />
      </NodesJoin>
    </Typography>
    <Typography variant="subtitle2" color={Greyscale.devilsgrey}>
      <NodesJoin separator={<MiddleDot />}>
        <FormattedMessage
          id="text_job-created-datetime"
          defaultMessage="Created on {date}"
          values={{
            date: dayjs.utc(job.createdAt).format('MMM DD, YYYY'),
          }}
        />
        <FormattedMessage
          id="text_job-modified-datetime"
          defaultMessage="Modified on {date}"
          values={{
            date: dayjs.utc(job.updatedAt).format('MMM DD, YYYY'),
          }}
        />
      </NodesJoin>
    </Typography>
  </Flex>
);

export default ApplicationJobInfo;
