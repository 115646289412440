import React, { ComponentProps } from 'react';
import { Flex } from 'glints-aries/es';
import { EmptyState, PrimaryButton, Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space12 } from 'glints-aries/es/@next/utilities/spacing';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import {
  MoveToInCommunicationPopover,
  useBuildMoveToInCommunicationActionButtonProps,
} from './CommonComponents';
import { ApplicationDataForBulkUpdate } from './types';

const messages = defineMessages({
  moveCandidateToWhatsapp: {
    id: 'text-move-candidate-to-whatsapp',
    defaultMessage: 'Move Candidate to WhatsApp',
  },
  inOrderToWhatsapp: {
    id: 'text-in-order-to-whatsapp',
    defaultMessage:
      'In order to WhatsApp this candidate, please move this candidate to "In Communication".',
  },
  moveCandidateToView: {
    id: 'text-move-candidate-to-view',
    defaultMessage: 'Move Candidate to view',
  },
  inOrderToView: {
    id: 'text-in-order-to-view',
    defaultMessage:
      "In order to view this information, please move this candidate to 'In Communication'.",
  },
  moveCandidateToChat: {
    id: 'text-move-candidate-to-chat',
    defaultMessage: 'Move Candidate to Chat',
  },
  inOrderToChat: {
    id: 'text-in-order-to-chat',
    defaultMessage:
      'In order to chat with this candidate, please move this candidate to "In Communication".',
  },
});

type MoveToInCommunicationVariant = 'whatsapp' | 'view' | 'chat';

type MoveToInCommunicationProps = {
  variant: MoveToInCommunicationVariant;
  applicationDetails: ApplicationDataForBulkUpdate;
};

const MoveToInCommunicationButton = ({
  applicationDetails,
}: Pick<MoveToInCommunicationProps, 'applicationDetails'>) => {
  const { text, onClick, loading } =
    useBuildMoveToInCommunicationActionButtonProps({
      applicationDetails,
    });

  return (
    <PrimaryButton onClick={onClick} loading={loading}>
      <Typography variant="button">{text}</Typography>
    </PrimaryButton>
  );
};

function Title({ variant }: Pick<MoveToInCommunicationProps, 'variant'>) {
  switch (variant) {
    case 'whatsapp':
      return <FormattedMessage {...messages.moveCandidateToWhatsapp} />;
    case 'view':
      return <FormattedMessage {...messages.moveCandidateToView} />;
    case 'chat':
      return <FormattedMessage {...messages.moveCandidateToChat} />;
  }
}

function Subtitle({ variant }: Pick<MoveToInCommunicationProps, 'variant'>) {
  switch (variant) {
    case 'whatsapp':
      return <FormattedMessage {...messages.inOrderToWhatsapp} />;
    case 'view':
      return <FormattedMessage {...messages.inOrderToView} />;
    case 'chat':
      return <FormattedMessage {...messages.inOrderToChat} />;
  }
}

function RejectedMoveToInCommunicationComponent({
  variant,
  ...rest
}: MoveToInCommunicationProps) {
  return (
    <Flex flexDirection="column" gap={space12} alignItems="stretch">
      <div>
        <Typography variant="body2" color={Neutral.B18}>
          <Title variant={variant} />
        </Typography>
        <Typography variant="body1" color={Neutral.B18}>
          <Subtitle variant={variant} />
        </Typography>
      </div>
      <MoveToInCommunicationButton {...rest} />
    </Flex>
  );
}

type RejectedMoveToInCommunicationPopoverProps = Pick<
  ComponentProps<
    (typeof MoveToInCommunicationPopover)['RejectedMoveToIncommunication']
  >,
  'applicationDetails' | 'activator' | 'preferredAlignment'
> & {
  variant: MoveToInCommunicationVariant;
};

function RejectedMoveToInCommunicationPopover({
  variant,
  applicationDetails,
  ...rest
}: RejectedMoveToInCommunicationPopoverProps) {
  return (
    <MoveToInCommunicationPopover.RejectedMoveToIncommunication
      applicationDetails={applicationDetails}
      title={<Title variant={variant} />}
      description={<Subtitle variant={variant} />}
      {...rest}
    />
  );
}

function RejectedMoveToInCommunicationResume({
  applicationDetails,
}: Pick<MoveToInCommunicationProps, 'applicationDetails'>) {
  const { formatMessage } = useIntl();
  const { text, onClick, loading } =
    useBuildMoveToInCommunicationActionButtonProps({
      applicationDetails,
    });
  return (
    <EmptyState
      imageName="empty-mailbox"
      title={formatMessage(messages.moveCandidateToView)}
      description={<RejectedMoveToInCommunication.Subtitle variant="view" />}
      primaryButtonAction={{
        label: <Typography variant="button">{text}</Typography>,
        onClick,
        loading,
      }}
      fullWidth={false}
    />
  );
}

export const RejectedMoveToInCommunication = Object.assign(
  RejectedMoveToInCommunicationComponent,
  {
    Title,
    Subtitle,
    Popover: RejectedMoveToInCommunicationPopover,
    Resume: RejectedMoveToInCommunicationResume,
  }
);
