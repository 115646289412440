import React from 'react';

import useGetFullProfileAccessBalance from '../../common/hooks/useGetFullProfileAccessBalance';
import useIsProfileLimitAccessEnabled from '../../common/hooks/useIsProfileLimitAccessEnabled';
import {
  ApplicationAccessLevel,
  ApplicationDetails,
  Job,
} from '../../common/interfaces/entities';
import { VIPUpgradeToVIPCtaClickedEventProperties } from '../../common/tracking';
import { checkIfShouldShowFPAForcedPaywallForApplications } from './utils';
import VipAccessNeededToViewDetails from './VipAccessNeededToViewDetails';

type RenderBasedOnAccessLevelProps = {
  children: React.ReactNode;
  applicationDetails: Pick<
    ApplicationDetails,
    'employerMetadata' | 'id' | 'status'
  >;
  jobId: Job['id'];
  renderVipAccessContent?: (
    _: { defaultElement: JSX.Element } & ReturnType<
      typeof useGetFullProfileAccessBalance
    >
  ) => JSX.Element;
  vipCTAClickedTrackingLocation:
    | VIPUpgradeToVIPCtaClickedEventProperties['eventLocation']
    | 'skip'; // Used when the default VipAccessNeededToViewDetails is not used
};

const PartialAccessWrapper = ({
  jobId,
  renderVipAccessContent,
  applicationDetails,
  vipCTAClickedTrackingLocation,
}: Pick<
  RenderBasedOnAccessLevelProps,
  | 'jobId'
  | 'renderVipAccessContent'
  | 'applicationDetails'
  | 'vipCTAClickedTrackingLocation'
>) => {
  const { initialBalance, currentBalance } = useGetFullProfileAccessBalance({
    jobId,
  });

  const elementsCommonProps = {
    jobId,
    applicationId: applicationDetails.id,
  };
  const content = renderVipAccessContent?.({
    defaultElement: (
      <VipAccessNeededToViewDetails
        {...elementsCommonProps}
        initialBalance={initialBalance}
        vipCTAClickedTrackingLocation={vipCTAClickedTrackingLocation}
      />
    ),
    initialBalance,
    currentBalance,
  }) || (
    <VipAccessNeededToViewDetails
      {...elementsCommonProps}
      initialBalance={initialBalance}
      vipCTAClickedTrackingLocation={vipCTAClickedTrackingLocation}
    />
  );
  return <>{content}</>;
};

const ContentWrapper = ({
  children,
  applicationDetails,
  jobId,
  renderVipAccessContent,
  vipCTAClickedTrackingLocation,
}: RenderBasedOnAccessLevelProps) => {
  const { accessLevel } = applicationDetails.employerMetadata || {
    accessLevel: null,
  };
  const { status } = applicationDetails;
  const { currentBalance } = useGetFullProfileAccessBalance({
    jobId,
  });
  if (currentBalance > 0) {
    return <>{children}</>;
  }

  const isPartialAccess = accessLevel === ApplicationAccessLevel.PARTIAL;
  const shouldShowForcedPaywallForApplication =
    checkIfShouldShowFPAForcedPaywallForApplications({
      profileLimitBalance: currentBalance,
      status,
    });
  if (shouldShowForcedPaywallForApplication || isPartialAccess) {
    return (
      <PartialAccessWrapper
        jobId={jobId}
        renderVipAccessContent={renderVipAccessContent}
        applicationDetails={applicationDetails}
        vipCTAClickedTrackingLocation={vipCTAClickedTrackingLocation}
      />
    );
  }
  return <>{children}</>;
};

export default function RenderBasedOnApplicationAccessLevel({
  children,
  ...rest
}: RenderBasedOnAccessLevelProps) {
  const isFullProfileAccessEnabled = useIsProfileLimitAccessEnabled();

  if (!isFullProfileAccessEnabled) {
    return <>{children}</>;
  }

  return <ContentWrapper {...rest}> {children} </ContentWrapper>;
}
