import React from 'react';
import { Flex } from 'glints-aries';
import { merge } from 'lodash-es';
import { useMessageContext } from 'stream-chat-react';

import {
  ApplicationChatCVExchangeRequestStatus,
  ApplicationChatCVRequestActionType,
} from '../../constants';
import { getMessageApplicationMetadata, isCVRequestAction } from '../../helper';
import AutoShareCVMessageContent from './AutoShareCVMessageContent';
import AutoShareStatusSystemMessage from './AutoShareStatusSystemMessage';
import CddCVRequestMessageContent from './CddCVRequestMessageContent';
import CVExchangeStatusSystemMessage from './CVExchangeStatusSystemMessage';
import EmpCVRequestMessageContent from './EmpCVRequestMessageContent';

const CVRequestMessage = () => {
  const { message } = useMessageContext();
  const metadata = getMessageApplicationMetadata(message);

  if (!metadata || !isCVRequestAction(metadata)) return null;

  return (
    <Flex flexDirection="column" alignItems="center" style={{ gap: 16 }}>
      <Choose>
        <When
          condition={
            metadata.action_type ===
            ApplicationChatCVRequestActionType.CV_AUTO_SHARED
          }
        >
          <AutoShareStatusSystemMessage />
        </When>
        <Otherwise>
          <CVExchangeStatusSystemMessage
            metadata={merge({}, metadata, {
              action_payload: {
                status: ApplicationChatCVExchangeRequestStatus.PENDING,
              },
            })}
          />
        </Otherwise>
      </Choose>

      <If
        condition={
          metadata.action_type ===
          ApplicationChatCVRequestActionType.CV_REQUEST_EMP
        }
      >
        <EmpCVRequestMessageContent metadata={metadata} />
      </If>

      <If
        condition={
          metadata.action_type ===
            ApplicationChatCVRequestActionType.CV_REQUEST_CDD ||
          metadata.action_type ===
            ApplicationChatCVRequestActionType.CV_REQUEST_CDD_AUTO
        }
      >
        <CddCVRequestMessageContent metadata={metadata} />
      </If>

      <If
        condition={
          metadata.action_type ===
          ApplicationChatCVRequestActionType.CV_AUTO_SHARED
        }
      >
        <AutoShareCVMessageContent />
      </If>
    </Flex>
  );
};

export default CVRequestMessage;
