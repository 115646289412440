import { createAction } from 'redux-actions';

export const Actions = {
  OPEN_MODAL: 'glints/modal/OPEN_MODAL',
  CLOSE_MODAL: 'glints/modal/CLOSE_MODAL',
};

export const openModal = createAction(Actions.OPEN_MODAL, (name, data) => ({
  name,
  data,
}));
export const closeModal = createAction(Actions.CLOSE_MODAL);
