import React from 'react';
import {
  Button,
  DestructiveButton,
  Modal,
  Typography,
} from 'glints-aries/es/@next';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { useSetRefParentElementZIndex } from '../../hooks';
import ResponsiveButtonGroup from '../ResponsiveButtonGroup';

const messages = defineMessages({
  header: {
    id: 'text_delete-template-message-title',
    defaultMessage: 'Delete message?',
  },
  btnDelete: {
    id: 'interactive-delete',
    defaultMessage: 'Delete',
  },
  btnCancel: {
    id: 'button-text_cancel',
    defaultMessage: 'Cancel',
  },
});

type Props = {
  onClose: () => void;
  onDelete: () => void;
};

const DeleteTemplateMessageConfirmationModal = ({
  onClose,
  onDelete,
}: Props) => {
  const modalRef = useSetRefParentElementZIndex('1000001');
  const { formatMessage } = useIntl();

  return (
    <Modal
      ref={modalRef}
      isOpen={true}
      header={formatMessage(messages.header)}
      onClose={onClose}
      customActions={
        <ResponsiveButtonGroup>
          <Button onClick={onClose}>{formatMessage(messages.btnCancel)}</Button>
          <DestructiveButton onClick={onDelete}>
            {formatMessage(messages.btnDelete)}
          </DestructiveButton>
        </ResponsiveButtonGroup>
      }
    >
      <Typography variant="body1">
        <FormattedMessage
          id="text_delete-template-message-desc"
          defaultMessage="Are you sure you want to delete this template message?"
        />
      </Typography>
    </Modal>
  );
};

export default DeleteTemplateMessageConfirmationModal;
