import { useCallback } from 'react';
import { AnyAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { saveBasicInfo as saveBasicInfoAction } from '../../modules/Settings/Actions';

type BasicInfoValues = Partial<{
  phone: string;
}>;

const useSaveBasicInfo = () => {
  const dispatch = useDispatch();
  const saveBasicInfo = useCallback(
    async (values: BasicInfoValues) => {
      await dispatch(saveBasicInfoAction(values) as unknown as AnyAction);
    },
    [dispatch]
  );

  return saveBasicInfo;
};

export default useSaveBasicInfo;
