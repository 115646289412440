import { useSelector } from 'react-redux';

import { UnleashFeatureNames } from '../../modules/Unleash/featureNames';
import { getIsFeatureEnabled } from '../../selectors/features';
import { countryCodeIsIndonesia } from '../helpers/countryCodeHelper';
import { useGetSessionCompany } from './useGetSessionCompany';

export default function useIsPremiumJobEnabled(): boolean {
  const { sessionCompany } = useGetSessionCompany();
  const isPremiumJobEnabled = useSelector(state =>
    getIsFeatureEnabled(state, UnleashFeatureNames.empPremiumJob)
  );

  return Boolean(
    isPremiumJobEnabled &&
      sessionCompany &&
      countryCodeIsIndonesia(sessionCompany.CountryCode)
  );
}
