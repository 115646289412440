import React, { createContext, useContext } from 'react';
import { ModalProvider, ModalWithProvider } from 'glints-aries/es/@next';

import useClaimCompanyProductFreeTrialOffer from './hooks/useClaimCompanyProductFreeTrialOffer';

type Context = ReturnType<typeof useClaimCompanyProductFreeTrialOffer>;
const Context = createContext<Context | null>(null);
type Props = {
  children: React.ReactNode;
};

export const useActivateFreeTrialModalContext = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      'useActiveFreeTrialModalContext must be used within a ActiveFreeTrialModalProvider'
    );
  }
  return context;
};

export default function ActivateFreeTrialModalProvider({ children }: Props) {
  return (
    <Context.Provider value={useClaimCompanyProductFreeTrialOffer()}>
      <ModalProvider>
        <ModalWithProvider />
        {children}
      </ModalProvider>
    </Context.Provider>
  );
}
