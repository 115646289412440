import { createSelector } from 'reselect';

import { getSchools } from '../../../selectors/entities';

export const getSelectOptionIds = (state, props) =>
  state.formComponents.formSelectSchool[props.meta.form]?.options?.value ?? [];

export const makeGetSelectOptions = () =>
  createSelector([getSelectOptionIds, getSchools], (ids, schools) =>
    ids.map(id => ({
      label: schools[id].name,
      value: id,
    }))
  );

export const isSelectOptionsLoading = (state, props) =>
  state.formComponents.formSelectSchool[props.meta.form]?.options?.loading ??
  false;

export const getSelectedSchoolId = (state, props) =>
  state.formComponents.formSelectSchool[props.meta.form]?.selectedSchool
    ?.value ?? null;

export const makeGetSelectedSchool = () =>
  createSelector(
    [getSelectedSchoolId, getSchools],
    (id, schools) => schools[id]
  );
