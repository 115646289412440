import React from 'react';
import { Greyscale } from 'glints-aries';
import { Typography } from 'glints-aries/es/@next';
import { EventComponentProps, MessageTimestamp } from 'stream-chat-react';

import { useFormatStreamMessage } from '../hooks';

const MessageSystem = ({ message }: EventComponentProps) => {
  const formatStreamMessage = useFormatStreamMessage();
  if (message.type === 'system') {
    const formattedMessage = formatStreamMessage(message);

    return (
      <div className="messaging-chat-channel-message">
        <Typography
          variant="subtitle2"
          style={{ textAlign: 'center', color: Greyscale.devilsgrey }}
        >
          <MessageTimestamp message={message} format="HH:mm" />
          &nbsp;&#65073;&nbsp;
          <span>{formattedMessage}</span>
        </Typography>
      </div>
    );
  }

  return null;
};

export default MessageSystem;
