import { createAction } from 'redux-actions';

import axios from '../../common/axios-ts';
import { getSetPasswordParams } from './Selectors';

export const Actions = {
  PASSWORD_RESET_TOKEN_REQUEST:
    'glints/passwordReset/PASSWORD_RESET_TOKEN_REQUEST',
  PASSWORD_RESET_TOKEN_SUCCESS:
    'glints/passwordReset/PASSWORD_RESET_TOKEN_SUCCESS',
  PASSWORD_RESET_TOKEN_FAILURE:
    'glints/passwordReset/PASSWORD_RESET_TOKEN_FAILURE',

  SET_NEW_PASSWORD_REQUEST: 'glints/passwordReset/SET_NEW_PASSWORD_REQUEST',
  SET_NEW_PASSWORD_SUCCESS: 'glints/passwordReset/SET_NEW_PASSWORD_SUCCESS',
  SET_NEW_PASSWORD_FAILURE: 'glints/passwordReset/SET_NEW_PASSWORD_FAILURE',

  RESET_STATE: 'glints/passwordReset/RESET_STATE',

  UPDATE_SET_PASSWORD_PARAMS: 'glints/passwordReset/UPDATE_SET_PASSWORD_PARAMS',
};

export const passwordResetTokenRequest = createAction(
  Actions.PASSWORD_RESET_TOKEN_REQUEST
);
export const passwordResetTokenSuccess = createAction(
  Actions.PASSWORD_RESET_TOKEN_SUCCESS
);
export const passwordResetTokenFailure = createAction(
  Actions.PASSWORD_RESET_TOKEN_FAILURE
);

export const setNewPasswordRequest = createAction(
  Actions.SET_NEW_PASSWORD_REQUEST
);
export const setNewPasswordSuccess = createAction(
  Actions.SET_NEW_PASSWORD_SUCCESS
);
export const setNewPasswordFailure = createAction(
  Actions.SET_NEW_PASSWORD_FAILURE
);

export const resetState = createAction(Actions.RESET_STATE);

export const updateSetPasswordParams = createAction(
  Actions.UPDATE_SET_PASSWORD_PARAMS,
  (id, token) => ({ id, token })
);

export function sendPasswordResetToken(email) {
  return async dispatch => {
    try {
      dispatch(passwordResetTokenRequest());
      await axios().post('/users/sendPasswordResetToken', {
        email,
      });
      dispatch(passwordResetTokenSuccess(email));
    } catch (err) {
      dispatch(passwordResetTokenFailure(err));
    }
  };
}

export function setNewPassword(password) {
  return async (dispatch, getState) => {
    try {
      dispatch(setNewPasswordRequest());

      const params = getSetPasswordParams(getState());
      const body = {
        ...params,
        password,
      };

      if (!params.id) {
        delete body.id;
      }

      if (!params.email) {
        delete body.email;
      }

      await axios().post('/users/resetPassword', body);

      dispatch(setNewPasswordSuccess());
    } catch (err) {
      dispatch(setNewPasswordFailure(err));
    }
  };
}
