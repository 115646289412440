import { startCase } from 'lodash-es';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useGetSessionCompany } from '../../common/hooks/useGetSessionCompany';
import {
  Applicant,
  ApplicantWhatsAppDetails,
  ApplicationDetails,
  Job,
} from '../../common/interfaces/entities';
import config from '../../config';
import { User } from '../../models/User';
import { getUser } from '../../selectors/user';
import {
  trackWAChatIconClickedEvent,
  WhatsAppContactPopoverSources,
} from './tracking';

type Props = {
  applicant: Pick<Applicant, 'ugcFullName'>;
  whatsAppDetails: ApplicantWhatsAppDetails;
  job: Pick<Job, 'title' | 'id'>;
  applicationDetails: Pick<ApplicationDetails, 'id' | 'status'>;
  source: WhatsAppContactPopoverSources;
};
const messages = defineMessages({
  openingText: {
    id: 'text-hi-candidate-first-last',
    defaultMessage: 'Hi {fullName},',
  },
  employerInfo: {
    id: 'text-I-am-employer-name',
    defaultMessage:
      "I'm {fullName} from {companyName}. We have received your application for the vacancy {jobTitle} that we posted on Glints and would like to continue the recruitment process to the next stage.",
  },
  applicationDetailLink: {
    id: 'text-your-application-we-received',
    defaultMessage: 'Your application that we received: {applicationLink}',
  },
  replyToContinue: {
    id: 'text-please-reply-if-you-are',
    defaultMessage:
      'Please reply if you are willing to continue the recruitment process.',
  },
});

export const useBuildWhatsAppMessageAndRedirect = () => {
  const { formatMessage } = useIntl();
  const { sessionCompany } = useGetSessionCompany();
  const user: User = useSelector(getUser);

  const buildWhatsAppMessageAndRedirect = ({
    applicant,
    job,
    whatsAppDetails,
    applicationDetails,
    source,
  }: Props) => {
    const { CANDIDATE_URL } = config;
    const applicationLink = `${CANDIDATE_URL}/user/applications/${applicationDetails.id}`;

    const message = [
      formatMessage(messages.openingText, {
        fullName: startCase(applicant.ugcFullName),
      }),
      '',
      formatMessage(messages.employerInfo, {
        fullName: startCase(user.ugcFullName),
        companyName: sessionCompany?.name,
        jobTitle: job.title,
      }),
      '',
      formatMessage(messages.applicationDetailLink, {
        applicationLink: applicationLink,
      }),
      '',
      formatMessage(messages.replyToContinue),
    ].join('\n');

    const whatsAppNumber = whatsAppDetails.whatsAppNumber
      ? whatsAppDetails.whatsAppNumber.replace('+', '')
      : whatsAppDetails.whatsAppNumber;
    const whatsAppURL = new URL(`https://wa.me/${whatsAppNumber}`);
    whatsAppURL.searchParams.append('text', message);

    trackWAChatIconClickedEvent({
      applicationId: applicationDetails.id,
      jobId: job.id,
      atsStage: applicationDetails.status,
      source,
    });

    window.open(whatsAppURL.toString(), '_blank', 'noopener,noreferrer');
  };

  return { buildWhatsAppMessageAndRedirect };
};
