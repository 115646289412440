import React from 'react';
import {
  Icon,
  InlineError,
  TextArea,
  TextAreaProps,
  Tooltip,
  Typography,
} from 'glints-aries/es/@next';
import { Blue, Neutral, Red } from 'glints-aries/es/@next/utilities/colors';

import { StyledLabelWrapper, StyledTextField } from './TextField.sc';

type TextAreaFieldProps = TextAreaProps & {
  label?: string;
  tooltipText?: string;
  subLabel?: string;
  errorText?: string;
  maxLength?: number;
  value?: string;
  fullWidth?: boolean;
  hasAsterisk?: boolean;
};

const TextAreaField = React.forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
  (
    {
      label,
      subLabel,
      tooltipText,
      errorText,
      maxLength,
      value,
      fullWidth,
      hasAsterisk,
      ...props
    }: TextAreaFieldProps,
    ref
  ) => (
    <StyledTextField data-fullwidth={fullWidth}>
      <If condition={Boolean(label)}>
        <StyledLabelWrapper>
          <Typography variant="body2">{label}</Typography>
          <If condition={Boolean(tooltipText)}>
            <Tooltip content={tooltipText} preferredPosition="right-middle">
              <Icon
                name="ri-information-line"
                height={20}
                width={20}
                fill={Blue.S99}
                data-cy={'tooltip'}
              />
            </Tooltip>
          </If>
        </StyledLabelWrapper>
      </If>
      <Typography variant="subtitle2" color={Neutral.B18}>
        {subLabel}
        <Typography variant="subtitle2" color={Red.B93} as="span">
          {hasAsterisk ? '*' : ''}
        </Typography>
      </Typography>
      <TextArea
        {...props}
        ref={ref}
        maxLength={maxLength}
        value={value || ''}
        error={Boolean(errorText)}
      />
      <If condition={Boolean(errorText)}>
        <InlineError text={String(errorText)} data-cy={'error-message'} />
      </If>
    </StyledTextField>
  )
);

TextAreaField.displayName = 'TextAreaField';

export default TextAreaField;
