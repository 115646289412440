import { defineMessages } from 'react-intl';
import * as Yup from 'yup';

import {
  ApplicationRejectionMessage,
  ApplicationRejectionMessagePreference,
  ApplicationRejectionMessageType,
  RejectionReasonPreferenceFormState,
} from '../types';

export const rejectionReasonPreferenceFormValidationSchema =
  Yup.object<RejectionReasonPreferenceFormState>({
    preferredType: Yup.string()
      .oneOf(Object.values(ApplicationRejectionMessagePreference))
      .required(),
    rejectionMessages: Yup.array()
      .of(
        Yup.object<ApplicationRejectionMessage>()
          .shape({
            message: Yup.string().required(),
            messageType: Yup.string()
              .oneOf(Object.values(ApplicationRejectionMessageType))
              .required(),
          })
          .required()
      )
      .required(),
  });

export const applicationRejectionMessageTypeMessages =
  defineMessages<ApplicationRejectionMessageType>({
    [ApplicationRejectionMessageType.NO_SKILL_SET_FIT]: {
      id: 'interactive-skill-set-mismatch',
      defaultMessage: 'Skill Set Mismatch',
    },
    [ApplicationRejectionMessageType.NO_EXPERIENCE_FIT]: {
      id: 'interactive-lack-of-experience',
      defaultMessage: 'Lack of Experience',
    },
    [ApplicationRejectionMessageType.NO_INDUSTRY_FIT]: {
      id: 'interactive-industry-background-mismatch',
      defaultMessage: 'Industry Background Mismatch',
    },
    [ApplicationRejectionMessageType.NO_EDUCATION_FIT]: {
      id: 'interactive-education-background-mismatch',
      defaultMessage: 'Education Background Mismatch',
    },
    [ApplicationRejectionMessageType.NO_SALARY_FIT]: {
      id: 'interactive-salary-mismatch ',
      defaultMessage: 'Salary Mismatch',
    },
    [ApplicationRejectionMessageType.POSITION_FILLED]: {
      id: 'interactive-hired-someone-else',
      defaultMessage: 'Hired Someone Else for This Role',
    },
    [ApplicationRejectionMessageType.NOT_SPECIFIED]: {
      id: 'interactive-not-hiring-anymore',
      defaultMessage: 'Not hiring for this role anymore',
    },
    [ApplicationRejectionMessageType.CANDIDATE_NOT_ACTIVE]: {
      id: 'interactive-candidate-is-unresponsive',
      defaultMessage: 'Candidate is Unresponsive',
    },
    [ApplicationRejectionMessageType.UNIFIED_MESSAGE]: {
      id: 'text-unified-message',
      defaultMessage: 'Unified Message',
    },
    [ApplicationRejectionMessageType.OTHER]: {
      id: 'interactive-other',
      defaultMessage: 'Other',
    },
  });

export const rejectionMethodMessages =
  defineMessages<ApplicationRejectionMessagePreference>({
    [ApplicationRejectionMessagePreference.UNIFIED_MESSAGE]: {
      id: 'text-unified-message',
      defaultMessage: 'Unified Message',
    },
    [ApplicationRejectionMessagePreference.CUSTOM_MESSAGE]: {
      id: 'text-custom-messages-for-different',
      defaultMessage: 'Custom messages for different rejection reasons',
    },
    [ApplicationRejectionMessagePreference.NO_MESSAGE]: {
      id: 'text-dont-send-rejection-messages',
      defaultMessage: 'Don’t send rejection messages',
    },
  });

export const rejectionMethodExplanationMessages =
  defineMessages<ApplicationRejectionMessagePreference>({
    [ApplicationRejectionMessagePreference.UNIFIED_MESSAGE]: {
      id: 'text-send-the-same-rejection',
      defaultMessage: 'Send the same rejection message for all rejections.',
    },
    [ApplicationRejectionMessagePreference.CUSTOM_MESSAGE]: {
      id: 'text-choose-and-send-specific',
      defaultMessage:
        'Choose and send specific rejection messages based on your chosen reason.',
    },
    [ApplicationRejectionMessagePreference.NO_MESSAGE]: {
      id: 'text-dont-send-any-rejection',
      defaultMessage: 'Don’t send any rejection messages for all rejections.',
    },
  });

export const REJECTION_REASON_FORM_ID = 'rejectionReasonPreferenceForm';

export const MESSAGE_INPUT_MAX_LENGTH = 120;
