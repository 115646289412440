import {
  gql,
  MutationHookOptions,
  QueryHookOptions,
  useMutation,
  useQuery,
} from '@apollo/client';

export type TemplateMessageFragment = {
  id: string;
  message: string;
};

const messagingTemplateMessageFragment = gql`
  fragment MessagingTemplateMessageFragment on UserMessagingTemplateMessage {
    id
    message
  }
`;

// getMessagingTemplateMessages
export const getMessagingTemplateMessages = gql`
  query getMessagingTemplateMessages($input: GetMessagingTemplateMessageInput) {
    getMessagingTemplateMessages(input: $input) {
      data {
        ...MessagingTemplateMessageFragment
      }
    }
  }
  ${messagingTemplateMessageFragment}
`;

type GetMessagingTemplateMessagesData = {
  getMessagingTemplateMessages: {
    data: TemplateMessageFragment[];
  };
};

type GetMessagingTemplateMessagesVariables = {
  input?: {
    limit: number;
    offset: number;
  };
};

export const useGetMessagingTemplateMessages = (
  options?: QueryHookOptions<
    GetMessagingTemplateMessagesData,
    GetMessagingTemplateMessagesVariables
  >
) =>
  useQuery<
    GetMessagingTemplateMessagesData,
    GetMessagingTemplateMessagesVariables
  >(getMessagingTemplateMessages, options);

// batchCreateDefaultMessagingTemplateMessages
const batchCreateDefaultMessagingTemplateMessages = gql`
  mutation batchCreateDefaultMessagingTemplateMessages(
    $input1: CreateMessagingTemplateMessageInput!
    $input2: CreateMessagingTemplateMessageInput!
    $input3: CreateMessagingTemplateMessageInput!
  ) {
    result1: createMessagingTemplateMessage(input: $input1) {
      ...MessagingTemplateMessageFragment
    }
    result2: createMessagingTemplateMessage(input: $input2) {
      ...MessagingTemplateMessageFragment
    }
    result3: createMessagingTemplateMessage(input: $input3) {
      ...MessagingTemplateMessageFragment
    }
  }
  ${messagingTemplateMessageFragment}
`;

/**
 * @template message the message content to be created.
 */
type CreateMessagingTemplateMessageInput = {
  message: string;
};

/**
 * Returns 3 default template messages we batch creates for the user.
 */
type BatchCreateDefaultMessagingTemplateMessagesData = {
  result1: TemplateMessageFragment;
  result2: TemplateMessageFragment;
  result3: TemplateMessageFragment;
};

/**
 * The input variables to create 3 default template messages for the user.
 */
type BatchCreateDefaultMessagingTemplateMessagesVariables = {
  input1: CreateMessagingTemplateMessageInput;
  input2: CreateMessagingTemplateMessageInput;
  input3: CreateMessagingTemplateMessageInput;
};

export const useBatchCreateDefaultMessagingTemplateMessages = (
  options?: MutationHookOptions<
    BatchCreateDefaultMessagingTemplateMessagesData,
    BatchCreateDefaultMessagingTemplateMessagesVariables
  >
) =>
  useMutation<
    BatchCreateDefaultMessagingTemplateMessagesData,
    BatchCreateDefaultMessagingTemplateMessagesVariables
  >(batchCreateDefaultMessagingTemplateMessages, options);

// createMessagingTemplateMessage
const createMessagingTemplateMessage = gql`
  mutation createMessagingTemplateMessage(
    $input: CreateMessagingTemplateMessageInput!
  ) {
    createMessagingTemplateMessage(input: $input) {
      ...MessagingTemplateMessageFragment
    }
  }
  ${messagingTemplateMessageFragment}
`;

/**
 * The mutation's return type.
 * @template createMessagingTemplateMessage contains the created template message.
 */
type CreateMessagingTemplateMessageData = {
  createMessagingTemplateMessage: TemplateMessageFragment;
};

/**
 * The mutation's variables to create a template message.
 * @template input create template message input.
 */
type CreateMessagingTemplateMessageVariables = {
  input: CreateMessagingTemplateMessageInput;
};

export const useCreateMessagingTemplateMessage = (
  options?: MutationHookOptions<
    CreateMessagingTemplateMessageData,
    CreateMessagingTemplateMessageVariables
  >
) =>
  useMutation<
    CreateMessagingTemplateMessageData,
    CreateMessagingTemplateMessageVariables
  >(createMessagingTemplateMessage, options);

// deleteMessagingTemplateMessageById
const deleteMessagingTemplateMessageById = gql`
  mutation deleteMessagingTemplateMessageById($id: UUID!) {
    deleteMessagingTemplateMessageById(id: $id)
  }
`;

/**
 * The mutation's return type.
 * @template deleteMessagingTemplateMessageById returns true if delete is successful.
 */
type DeleteMessagingTemplateMessageData = {
  deleteMessagingTemplateMessageById: boolean;
};

/**
 * The mutation's variables to delete a template message.
 * @template id the template message to be deleted.
 */
type DeleteMessagingTemplateMessageVariables = {
  id: string;
};

export const useDeleteMessagingTemplateMessage = (
  options?: MutationHookOptions<
    DeleteMessagingTemplateMessageData,
    DeleteMessagingTemplateMessageVariables
  >
) =>
  useMutation<
    DeleteMessagingTemplateMessageData,
    DeleteMessagingTemplateMessageVariables
  >(deleteMessagingTemplateMessageById, options);

// updateMessagingTemplateMessageById
const updateMessagingTemplateMessageById = gql`
  mutation updateMessagingTemplateMessageById(
    $id: UUID!
    $input: UpdateMessagingTemplateMessageInput!
  ) {
    updateMessagingTemplateMessageById(id: $id, input: $input) {
      ...MessagingTemplateMessageFragment
    }
  }
  ${messagingTemplateMessageFragment}
`;

/**
 * @template message the message content to be updated.
 */
type UpdateMessagingTemplateMessageByIdInput = {
  message: string;
};

/**
 * The mutation's return type.
 * @template updateMessagingTemplateMessageById returns true if update is successful.
 */
type UpdateMessagingTemplateMessageData = {
  updateMessagingTemplateMessageById: boolean;
};

/**
 * The mutation's variables to update a template message.
 * @template id template message id to be updated.
 * @template input update template message input.
 */
type UpdateMessagingTemplateMessageVariables = {
  id: string;
  input: UpdateMessagingTemplateMessageByIdInput;
};

export const useUpdateMessagingTemplateMessage = (
  options?: MutationHookOptions<
    UpdateMessagingTemplateMessageData,
    UpdateMessagingTemplateMessageVariables
  >
) =>
  useMutation<
    UpdateMessagingTemplateMessageData,
    UpdateMessagingTemplateMessageVariables
  >(updateMessagingTemplateMessageById, options);
