import React, { useState } from 'react';
import { Flex } from 'glints-aries/es';
import {
  Divider,
  Icon,
  OutlineButton,
  Popover,
  Typography,
} from 'glints-aries/es/@next';
import { Blue, Green, Neutral } from 'glints-aries/es/@next/utilities/colors';
import {
  space4,
  space8,
  space12,
} from 'glints-aries/es/@next/utilities/spacing';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import {
  trackVIPUpgradeCtaClicked,
  VIPUpgradeToVIPCtaClickedEventProperties,
} from '../../common/tracking';
import { useGlintsVIPFreeTrialOrPurchaseModal } from '../../modules/CreditSystem/PurchaseModals/GlintsVIPPurchaseModal/hooks/useGlintsVIPFreeTrialOrPurchaseModal';
import useFullProfileAccessAwarenessModal from '../FullProfileAccessAwarenessModal/useFullProfileAccessAwarenessModal';
import RenderVIPCTALabelBasedOnVIPFreeTrial from '../VIPFreeTrial/RenderVIPCTALabelBasedOnVIPFreeTrial';
import StyledVIPGradientButton from '../VIPGradientButton';
import {
  ChatAccessLimitStyle,
  DefaultAccessLimitStyle,
  PopoverAccessLimitStyle,
} from './styles.sc';

type ActionButtonsProps = {
  vipCTAClickedTrackingLocation:
    | VIPUpgradeToVIPCtaClickedEventProperties['eventLocation']
    | 'skip'; // Used when the default VipAccessNeededToViewDetails is not used;
};

type VipAccessNeededToViewDetailsProps = ActionButtonsProps & {
  initialBalance: number;
};

type VIPAccessLimitPopoverProps = {
  activator: React.ReactNode;
} & Pick<VipAccessNeededToViewDetailsProps, 'initialBalance'> &
  UseVIPModalWithCacheConfigurations;

type UseVIPModalWithCacheConfigurations = VipAccessNeededToViewDetailsProps;

const useVIPPurchaseModalWithCacheConfigurations = () => {
  const { openGlintsVIPPurchaseModal, isModalChunkLoadPending } =
    useGlintsVIPFreeTrialOrPurchaseModal();
  const handleOpenGlintsVIPPurchaseModal = () => openGlintsVIPPurchaseModal();
  return { handleOpenGlintsVIPPurchaseModal, isModalChunkLoadPending } as const;
};

function Description({
  initialBalance,
}: Pick<VipAccessNeededToViewDetailsProps, 'initialBalance'>) {
  const { formatNumber } = useIntl();
  const { handleOpenGlintsVIPPurchaseModal } =
    useVIPPurchaseModalWithCacheConfigurations();

  return (
    <Typography variant="body1">
      <FormattedMessage
        id="text-limit-of-full-access"
        defaultMessage="Limit of {accessLimit} full applicant profiles reached for this job. <link1>Upgrade to VIP</link1> to access more."
        values={{
          accessLimit: formatNumber(initialBalance),
          link1: chunks => (
            <span onClick={handleOpenGlintsVIPPurchaseModal}>
              <Typography
                as="span"
                color={Blue.S99}
                style={{
                  cursor: 'pointer',
                }}
              >
                <RenderVIPCTALabelBasedOnVIPFreeTrial>
                  {chunks}
                </RenderVIPCTALabelBasedOnVIPFreeTrial>
              </Typography>
            </span>
          ),
        }}
      />
    </Typography>
  );
}

function ActionButtons({ vipCTAClickedTrackingLocation }: ActionButtonsProps) {
  const { isModalChunkLoadPending, handleOpenGlintsVIPPurchaseModal } =
    useVIPPurchaseModalWithCacheConfigurations();

  const { openFullProfileAccessAwarenessModal } =
    useFullProfileAccessAwarenessModal();

  const onUpgradeToVIPClick = () => {
    handleOpenGlintsVIPPurchaseModal();

    if (vipCTAClickedTrackingLocation !== 'skip') {
      trackVIPUpgradeCtaClicked({
        eventLocation: vipCTAClickedTrackingLocation,
      });
    }
  };

  return (
    <Flex gap={space8}>
      <OutlineButton onClick={openFullProfileAccessAwarenessModal}>
        <Typography variant="button">
          <FormattedMessage
            id="interactive-learn-more"
            defaultMessage="Learn More"
          />
        </Typography>
      </OutlineButton>
      <StyledVIPGradientButton
        loading={isModalChunkLoadPending}
        onClick={onUpgradeToVIPClick}
      >
        <Typography variant="button" color={Neutral.B18}>
          <RenderVIPCTALabelBasedOnVIPFreeTrial>
            <FormattedMessage
              id="interactive-upgrade-to-vip"
              defaultMessage="Upgrade to VIP"
            />
          </RenderVIPCTALabelBasedOnVIPFreeTrial>
        </Typography>
      </StyledVIPGradientButton>
    </Flex>
  );
}

const PopoverAccessLimit = ({
  activator,
  initialBalance,
  vipCTAClickedTrackingLocation,
}: VIPAccessLimitPopoverProps) => {
  const [active, setActive] = useState(false);
  const [openPopover, closePopover] = [
    () => setActive(true),
    () => setActive(false),
  ];

  return (
    <div onMouseLeave={closePopover}>
      <Popover
        active={active}
        onClose={closePopover}
        preferredAlignment="left"
        activator={<div onMouseEnter={openPopover}>{activator}</div>}
        fluidContent={true}
      >
        <Popover.Pane>
          <PopoverAccessLimitStyle.VipBackground
            onClick={e => e.stopPropagation()}
          >
            <PopoverAccessLimitStyle.Image />
            <div>
              <Typography variant="subtitle1">
                <FormattedMessage
                  id="text-full-profile-access-reached"
                  defaultMessage="Full Profile Access Reached"
                />
              </Typography>
              <Description initialBalance={initialBalance} />
            </div>
            <ActionButtons
              vipCTAClickedTrackingLocation={vipCTAClickedTrackingLocation}
            />
          </PopoverAccessLimitStyle.VipBackground>
        </Popover.Pane>
      </Popover>
    </div>
  );
};

function ChatAccessLimit({
  initialBalance,
  vipCTAClickedTrackingLocation,
}: VipAccessNeededToViewDetailsProps) {
  return (
    <ChatAccessLimitStyle.VipBackground onClick={e => e.stopPropagation()}>
      <ChatAccessLimitStyle.Image />
      <div>
        <Typography variant="subtitle1">
          <FormattedMessage
            id="text-full-profile-access-reached"
            defaultMessage="Full Profile Access Reached"
          />
        </Typography>
        <Description initialBalance={initialBalance} />
      </div>
      <ActionButtons
        vipCTAClickedTrackingLocation={vipCTAClickedTrackingLocation}
      />
    </ChatAccessLimitStyle.VipBackground>
  );
}

const lockedContentList: MessageDescriptor[] = [
  {
    id: 'text-contact-details',
    defaultMessage: 'Contact Details',
  },
  {
    id: 'text-portfolio-website-link',
    defaultMessage: 'Portfolio/Website Link',
  },
  {
    id: 'text-attachment',
    defaultMessage: 'Attachments',
  },
  {
    id: 'text-supporting-links',
    defaultMessage: 'Supporting Links',
  },
  {
    id: 'text-view-and-download-cv',
    defaultMessage: 'View and Download CV',
  },
  {
    id: 'text-process-candidate',
    defaultMessage: 'Process Candidate',
  },
  {
    id: 'text-chat-with-candidate',
    defaultMessage: 'Chat with Candidate',
  },
];

function VipAccessNeededToViewDetails({
  vipCTAClickedTrackingLocation,
}: VipAccessNeededToViewDetailsProps) {
  return (
    <DefaultAccessLimitStyle.VipBackground>
      <div>
        <div>
          <Typography variant="body2">
            <FormattedMessage
              id="text-full-profile-access-reached"
              defaultMessage="Full Profile Access Reached"
            />
          </Typography>
          <Typography variant="subtitle2">
            <FormattedMessage
              id="text-upgrade-to-vip-for-unlimited"
              defaultMessage="Upgrade to VIP for unlimited access to more candidate information."
            />
          </Typography>
        </div>
        <Divider
          style={{
            margin: `${space12} 0`,
          }}
        />
        <DefaultAccessLimitStyle.LockedContentList>
          {lockedContentList.map(message => (
            <Flex gap={space4} key={message.id}>
              <Icon
                name="ri-checkbox-circle-fill"
                fill={Green.B61}
                width={20}
              />
              <Typography variant="subtitle2">
                <FormattedMessage {...message} />
              </Typography>
            </Flex>
          ))}
        </DefaultAccessLimitStyle.LockedContentList>
      </div>

      <ActionButtons
        vipCTAClickedTrackingLocation={vipCTAClickedTrackingLocation}
      />
    </DefaultAccessLimitStyle.VipBackground>
  );
}

export default Object.assign(VipAccessNeededToViewDetails, {
  PopoverAccessLimit,
  ChatAccessLimit,
});
