import { EmployerPurchaseProductType } from '../../../../common/interfaces/entities';
import { GraphqlErrorCodes, TrackingProductNames } from '../../constants';

export const partialPurchaseErrorCodesToRefetchOrderSummary = [
  GraphqlErrorCodes.OPERATION_NOT_ALLOWED,
];
export const purchaseUsingCreditErrorCodesToRefetchOrderSummary = [
  GraphqlErrorCodes.INSUFFICIENT_BALANCE,
];

export const productTypeToTrackingNameMapping: Record<
  EmployerPurchaseProductType,
  TrackingProductNames
> = {
  [EmployerPurchaseProductType.HOT_JOB]: TrackingProductNames.hotJob,
  [EmployerPurchaseProductType.RECOMMENDED_TALENT_CHAT]:
    TrackingProductNames.recommendedTalent,
  [EmployerPurchaseProductType.VIP_MEMBERSHIP]:
    TrackingProductNames.vipMembership,
  [EmployerPurchaseProductType.TALENT_SEARCH]:
    TrackingProductNames.talentSearch,
};
