import { createSelector } from 'reselect';

import { getJobCategories } from '../../../selectors/entities';

export const getSelectOptionIds = (state, props) =>
  state.formComponents.formSelectJobCategory[props.meta.form]?.options?.value ??
  [];

export const makeGetSelectOptions = () =>
  createSelector([getSelectOptionIds, getJobCategories], (ids, jobCategories) =>
    ids.map(id => ({
      label: jobCategories[id].name,
      value: id,
    }))
  );

export const isSelectOptionsLoading = (state, props) =>
  state.formComponents.formSelectJobCategory[props.meta.form]?.options
    ?.loading ?? false;

export const getSelectedJobCategoryId = (state, props) =>
  state.formComponents.formSelectJobCategory[props.meta.form]
    ?.selectedJobCategory?.value ?? null;

export const makeGetSelectedJobCategory = () =>
  createSelector(
    [getSelectedJobCategoryId, getJobCategories],
    (id, jobCategories) => jobCategories[id]
  );
