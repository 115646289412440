/* eslint-disable camelcase */
import { ApplicationSource } from '../../common/constants';
import { TrackingService } from '../../common/tracking/trackingService';
import { MessagingUIMode } from './constants';

export const trackChatListingClickedEvent = (properties: {
  applicationId: string;
  applicationSource: ApplicationSource | '';
}) => {
  TrackingService.logEvent('Chat Listing Clicked', properties);
};

export const trackMessageSentEvent = (properties: {
  uiType: MessagingUIMode;
  messageId: string;
  messageType: 'FIRST_REPLY' | 'NORMAL_MESSAGE';
  messageLength: number | undefined;
  jobId: string | undefined;
  companyId: string | undefined;
  channelId: string | undefined;
  channelMembersCount: number | undefined;
  applicationStatus: string | undefined;
}) => {
  const transformedProperties = {
    ui_type: properties.uiType,
    message_id: properties.messageId,
    message_type: properties.messageType,
    message_length: properties.messageLength,
    job_id: properties.jobId,
    company_id: properties.companyId,
    channel_id: properties.channelId,
    channel_members_count: properties.channelMembersCount,
    application_status: properties.applicationStatus,
    triggered_by: 'Employer',
  };

  TrackingService.logEvent('Message Sent', transformedProperties);
};

export type ChatPageViewedEventSource =
  | 'employer-ats-v2'
  | 'employer-ats-v2-modal'
  | 'application-details-page'
  | 'chat-widget'
  | 'navbar';

export const trackChatPageViewedEvent = (properties: {
  source: ChatPageViewedEventSource;
  uiType: MessagingUIMode;
}) => {
  const transformedProperties = {
    source: properties.source,
    ui_type: properties.uiType,
  };

  TrackingService.logEvent('Chat Page Viewed', transformedProperties);
};

export const trackMessagingTemplateMessageSelectedEvent = () => {
  TrackingService.logEvent('Template Message Selected');
};

export const trackMessagingApplicationStatusChangeEvent = (properties: {
  messagingPayload: {
    jobId: string;
    companyId: string;
    channelId?: string;
    channelMembersCount?: number;
    fromApplicationStatus: string;
    toApplicationStatus: string;
  };
  waPresent: boolean;
}) => {
  const { messagingPayload, waPresent } = properties;
  const transformedProperties = {
    messagingPayload: {
      job_id: messagingPayload.jobId,
      company_id: messagingPayload.companyId,
      channel_id: messagingPayload.channelId,
      channel_members_count: messagingPayload.channelMembersCount,
      from_application_status: messagingPayload.fromApplicationStatus,
      to_application_status: messagingPayload.toApplicationStatus,
    },
    waPresent: waPresent,
  };

  TrackingService.logEvent('Application Status Change', transformedProperties);
};

export const trackCVViewedEvent = (properties: {
  jobId: string;
  companyId: string;
  messageId?: string;
  channelId?: string;
  channelMembersCount?: number;
  applicationStatus?: string;
  messageSentDatetime?: string;
  actionType?: string;
}) => {
  const transformedProperties = {
    job_id: properties.jobId,
    message_id: properties.messageId,
    company_id: properties.companyId,
    channel_id: properties.channelId,
    channel_members_count: properties.channelMembersCount,
    application_status: properties.applicationStatus,
    message_sent_datetime: properties.messageSentDatetime,
    action_type: properties.actionType,
  };

  TrackingService.logEvent('CV Viewed', transformedProperties);
};

export const trackAddTemplateMessageClickedEvent = () => {
  TrackingService.logEvent('Add Template Message Clicked');
};

export const trackPreferredNotificationChannelDismissedEvent = () => {
  TrackingService.logEvent('Preferred Notification Channel Dismissed');
};

export const trackPreferredNotificationChannelSubmittedEvent = (properties: {
  preferredNotificationChannelWhatsapp: boolean;
  preferredNotificationChannelTelegram: boolean;
  preferredNotificationChannelSms: boolean;
  preferredNotificationChannelBrowser: boolean;
  preferredNotificationChannelMobile: boolean;
}) => {
  const transformedProperties = {
    preferred_noti_channel_whatsapp:
      properties.preferredNotificationChannelWhatsapp,
    preferred_noti_channel_telegram:
      properties.preferredNotificationChannelTelegram,
    preferred_noti_channel_sms: properties.preferredNotificationChannelSms,
    preferred_noti_channel_browser:
      properties.preferredNotificationChannelBrowser,
    preferred_noti_channel_mobile:
      properties.preferredNotificationChannelMobile,
  };

  TrackingService.logEvent(
    'Preferred Notification Channel Submitted',
    transformedProperties
  );
};

export const trackPhoneNumberCanceledEvent = (properties: {
  jobId?: string;
  companyId?: string;
  channelId?: string;
  channelMembersCount?: number;
  applicationStatus?: string;
}) => {
  const transformedProperties = {
    job_id: properties.jobId,
    company_id: properties.companyId,
    channel_id: properties.channelId,
    channel_members_count: properties.channelMembersCount,
    application_status: properties.applicationStatus,
  };

  TrackingService.logEvent('Phone Number Canceled', transformedProperties);
};

export const trackPhoneNumberSubmittedEvent = (properties: {
  jobId?: string;
  companyId?: string;
  channelId?: string;
  channelMembersCount?: number;
  applicationStatus?: string;
}) => {
  const transformedProperties = {
    job_id: properties.jobId,
    company_id: properties.companyId,
    channel_id: properties.channelId,
    channel_members_count: properties.channelMembersCount,
    application_status: properties.applicationStatus,
  };

  TrackingService.logEvent('Phone Number Submitted', transformedProperties);
};

export const trackTemplateButtonClickedEvent = () => {
  TrackingService.logEvent('Template Button Clicked');
};

export const trackTemplateMessageListingViewedEvent = () => {
  TrackingService.logEvent('Template Message Listing Viewed');
};
