import React, { useState } from 'react';
import { Flex } from 'glints-aries';
import {
  Banner,
  Button,
  PrimaryButton,
  Typography,
} from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useChannelStateContext, useMessageContext } from 'stream-chat-react';

import { getUserPhoneNumber } from '../../../../selectors/user';
import {
  applicationChatContactsExchangeCddRequestMessages,
  ApplicationChatContactsExchangeRequestStatus,
  useMessagingChannelContext,
} from '../../constants';
import { useUpdateShareContactsRequest } from '../../graphql';
import { getChannelApplicationMetadata } from '../../helper';
import { useRebuildComponentOnChannelEvent } from '../../hooks';
import { ContactsRequestAction } from '../../types';
import ResponsiveButtonGroup from '../ResponsiveButtonGroup';
import { CardContainer } from '../Styled';
import CandidatePhoneNumber from './CandidatePhoneNumber';
import ContactsRequestInputPhoneNumber from './ContactsRequestInputPhoneNumber';
import ContactsExchangeStatusSystemMessage from './StatusSystemMessage';

type Props = {
  metadata: ContactsRequestAction;
};

const CddContactsRequestMessageContent = ({ metadata }: Props) => {
  useRebuildComponentOnChannelEvent('channel.updated');
  const [showInputPhoneNumber, setShowInputPhoneNumber] = useState(false);
  const phoneNumber = useSelector(getUserPhoneNumber) as string | undefined;
  const { channel } = useChannelStateContext();
  const { message } = useMessageContext();
  const { uiMode } = useMessagingChannelContext();
  const [updateShareContactsRequest, updateShareContactsRequestResult] =
    useUpdateShareContactsRequest();

  const channelMetadata = getChannelApplicationMetadata(channel);
  const isLatestRequest =
    message.id === channelMetadata?.contacts_request_msg_id;
  const showBanner =
    isLatestRequest &&
    !metadata.action_payload.hidden &&
    [
      ApplicationChatContactsExchangeRequestStatus.PENDING,
      ApplicationChatContactsExchangeRequestStatus.ACCEPTED,
    ].includes(metadata.action_payload.status);

  const acceptContactsExchangeRequest = async () => {
    await updateShareContactsRequest({
      variables: {
        messageId: message.id,
        input: {
          status: ApplicationChatContactsExchangeRequestStatus.ACCEPTED,
          telemetryEventProps: {
            ui_type: uiMode,
          },
        },
      },
    });
  };

  return (
    <>
      <If
        condition={
          metadata.action_payload.status !==
          ApplicationChatContactsExchangeRequestStatus.PENDING
        }
      >
        <ContactsExchangeStatusSystemMessage metadata={metadata} />
      </If>
      <If condition={showBanner}>
        <Choose>
          <When condition={showInputPhoneNumber}>
            <ContactsRequestInputPhoneNumber
              onClose={() => setShowInputPhoneNumber(false)}
              afterSubmit={acceptContactsExchangeRequest}
            />
          </When>
          <Otherwise>
            <CardContainer>
              <Banner dismissable={false} iconName="ri-information-line">
                <Flex flexDirection="column" style={{ gap: 8 }}>
                  <Choose>
                    <When
                      condition={
                        metadata.action_payload.status ===
                        ApplicationChatContactsExchangeRequestStatus.PENDING
                      }
                    >
                      <Typography variant="body1">
                        <FormattedMessage
                          {...applicationChatContactsExchangeCddRequestMessages[
                            ApplicationChatContactsExchangeRequestStatus.PENDING
                          ]}
                        />
                      </Typography>
                      <ResponsiveButtonGroup>
                        <PrimaryButton
                          disabled={updateShareContactsRequestResult.loading}
                          onClick={() => {
                            if (!phoneNumber) {
                              return setShowInputPhoneNumber(true);
                            }

                            acceptContactsExchangeRequest();
                          }}
                        >
                          <Typography variant="button">
                            <FormattedMessage
                              id="button-text_accept"
                              defaultMessage="Accept"
                            />
                          </Typography>
                        </PrimaryButton>
                        <Button
                          disabled={updateShareContactsRequestResult.loading}
                          onClick={() =>
                            updateShareContactsRequest({
                              variables: {
                                messageId: message.id,
                                input: {
                                  status:
                                    ApplicationChatContactsExchangeRequestStatus.REJECTED,
                                  telemetryEventProps: {
                                    ui_type: uiMode,
                                  },
                                },
                              },
                            })
                          }
                        >
                          <Typography variant="button">
                            <FormattedMessage
                              id="interactive-reject"
                              defaultMessage="Reject"
                            />
                          </Typography>
                        </Button>
                      </ResponsiveButtonGroup>
                    </When>
                    <When
                      condition={
                        metadata.action_payload.status ===
                        ApplicationChatContactsExchangeRequestStatus.ACCEPTED
                      }
                    >
                      <CandidatePhoneNumber metadata={metadata} />
                    </When>
                  </Choose>
                </Flex>
              </Banner>
            </CardContainer>
          </Otherwise>
        </Choose>
      </If>
    </>
  );
};

export default CddContactsRequestMessageContent;
