import { Device } from 'glints-aries';
import { space4, space8 } from 'glints-aries/es/@next/utilities/spacing';
import styled from 'styled-components';

export type StyledTextFieldProps = {
  width?: number;
};

export const StyledLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${space8};
  margin-bottom: ${space8};
`;

export const StyledTextField = styled.div<StyledTextFieldProps>`
  ${({ width }) => ({
    ...(width ? { width: `${width}px` } : {}),
  })}

  display: flex;
  flex-direction: column;
  gap: ${space4};
  max-width: 50%;
  min-width: 0;

  &[data-fullwidth='true'] {
    width: 100%;
    max-width: 100%;
  }

  @media ${Device.tablet} {
    max-width: 100%;
  }
`;
