import loadable from '@loadable/component';

import { retry } from '../retry';

const EmailVerificationPage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-email-verification" */ '../modules/CompanyOnboarding/EmailVerificationPage/EmailVerificationPage'
      )
  )
);

const PasswordResetPage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-password-reset" */ '../modules/PasswordReset/PasswordResetPage'
      )
  )
);

const LoginPage = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "page-login" */ '../modules/CompanyOnboarding/SignInPage/SignInPage'
      )
  )
);

const Logout = loadable(() =>
  retry(
    () => import(/* webpackChunkName: "page-logout" */ '../components/Logout')
  )
);

export const authRoutes = [
  {
    path: '/email-verification',
    component: EmailVerificationPage,
    allowUnverified: true,
  },
  {
    path: '/forgot-password',
    component: PasswordResetPage,
    allowUnverified: true,
  },
  {
    path: '/login',
    component: LoginPage,
    allowUnverified: true,
  },
  {
    path: '/logout',
    component: Logout,
    allowUnverified: true,
  },
];
