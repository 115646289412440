import { Alert, CheckmarkSolidIcon } from 'glints-aries';
import {
  space8,
  space16,
  space24,
  space32,
} from 'glints-aries/es/@next/utilities/spacing';
import styled from 'styled-components';

export const ShareButton = styled('div')`
  height: 48px;
  width: 48px;
  margin: 0 10px;
  cursor: pointer;
  @media (max-width: 380px) {
    margin: 0px 5px;
  }
  @media (max-width: 340px) {
    margin: 0px;
  }
`;

export const SocialMediaWrapper = styled('div')`
  display: flex;
  margin-bottom: ${space24};
`;
export const ZaloButton = styled('div')`
  background-image: url('../../../images/zalo.svg');
  margin-right: 10px;
  margin-left: 0px;
  width: 49px;
  @media (max-width: 380px) {
    margin-right: 5px;
  }
  @media (max-width: 340px) {
    margin-right: 0px;
  }
`;

export const WhatsappButton = styled('div')`
  background-image: url('../../../images/whatsapp.svg');
  margin-right: 10px;
  margin-left: 0px;
  width: 49px;
  @media (max-width: 380px) {
    margin-right: 5px;
  }
  @media (max-width: 340px) {
    margin-right: 0px;
  }
`;

export const FacebookButton = styled('div')`
  background-image: url('../../../images/facebook.svg');
`;

export const TwitterButton = styled('div')`
  background-image: url('../../../images/twitter.svg');
`;

export const LinkedInButton = styled('div')`
  background-image: url('../../../images/linkedin.svg');
`;

export const TelegramButton = styled('div')`
  background-image: url('../../../images/telegram.svg');
  width: 49px;
`;

export const SuccessAlert = styled(Alert)`
  margin-bottom: ${space16};
  line-height: 1.6;
`;

export const SuccessIcon = styled(CheckmarkSolidIcon)`
  margin-right: ${space8};
`;

export const GlintsCopyToClipboardWrapper = styled.div`
  margin-top: ${space32};
`;
