import { handleActions } from 'redux-actions';

import { Actions } from './Actions';

const initialState = {
  glints: {
    isPostJobModalOpen: false,
  },
  stats: {
    loading: false,
    value: {
      companies: {
        total: 0,
        previousMonth: 0,
      },
      jobs: {
        total: 0,
        previousMonth: 0,
      },
      users: {
        total: 0,
        previousMonth: 0,
      },
    },
  },
};

export default handleActions(
  {
    [Actions.glints.TOGGLE_CREATE_COMPANY_MODAL]: state => ({
      ...state,
      glints: {
        ...state.glints,
        isPostJobModalOpen: !state.glints.isPostJobModalOpen,
      },
    }),
    [Actions.FETCH_STATS_REQUEST]: state => ({
      ...state,
      stats: {
        ...state.stats,
        loading: true,
      },
    }),
    [Actions.FETCH_STATS_SUCCESS]: (state, action) => ({
      ...state,
      stats: {
        ...state.stats,
        loading: false,
        value: action.response,
      },
    }),
    [Actions.FETCH_STATS_FAILED]: state => ({
      ...state,
      stats: initialState.stats,
    }),
  },
  initialState
);
