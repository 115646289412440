import { Menu } from 'antd';
import { Greyscale } from 'glints-aries';
import { space12 } from 'glints-aries/es/@next/utilities/spacing';
import { Link as BaseLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const itemMixin = css<{ disabled?: boolean }>`
  text-transform: uppercase;
  color: ${({ disabled }) => (disabled ? '#b6b6b6 !important' : 'black')};
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 12px;

  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
        `
      : null}
`;

const dropdownItemStyles = css`
  background-color: ${Greyscale.white} !important;

  &:hover {
    background-color: #f5f5f5 !important;
  }
`;

export const Item = styled(Menu.Item)`
  ${dropdownItemStyles}
  ${itemMixin}
  padding: ${space12};
`;

export const SubMenuTitle = styled.span`
  ${itemMixin}
`;

export const Link = styled(BaseLink)`
  ${itemMixin}
  font-size: 13px;
`;

export const Anchor = styled.a`
  ${itemMixin}
`;

export const DropdownLink = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  border-bottom: 5px solid transparent;
  text-transform: uppercase;
  color: black;
  font-weight: bold;
  letter-spacing: 0.5px;
  margin: 5px 15px 0;
  line-height: 1;
`;
