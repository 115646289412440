import React from 'react';
import { Flex } from 'glints-aries';
import { Banner, Typography } from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';
import { useChannelStateContext } from 'stream-chat-react';

import { getChannelApplicationMetadata } from '../../helper';
import {
  trackPhoneNumberCanceledEvent,
  trackPhoneNumberSubmittedEvent,
} from '../../tracking';
import PhoneNumberInput from '../PhoneNumberInput';
import { CardContainer } from '../Styled';

type Props = {
  onClose?: () => void;
  afterSubmit?: () => void;
};
const ContactsRequestInputPhoneNumber = ({ afterSubmit, onClose }: Props) => {
  const { channel } = useChannelStateContext();

  const channelMetadata = getChannelApplicationMetadata(channel);
  const gtmEventPayload = {
    jobId: channelMetadata?.application.JobId,
    companyId: channelMetadata?.application.Job?.CompanyId,
    channelId: channel.id,
    channelMembersCount: channel.data?.member_count,
    applicationStatus: channelMetadata?.application.status,
  };

  const handleSubmit = async () => {
    trackPhoneNumberSubmittedEvent(gtmEventPayload);

    afterSubmit?.();
    onClose?.();
  };

  const handleCancel = async () => {
    trackPhoneNumberCanceledEvent(gtmEventPayload);
    onClose?.();
  };

  return (
    <CardContainer>
      <Banner iconName="ri-information-line" dismissable={false}>
        <Flex flexDirection="column" style={{ gap: 8 }}>
          <Typography variant="body1">
            <FormattedMessage
              id="text_update-phone-no-label"
              defaultMessage="To exchange your contact with the candidate, you need to provide your phone number"
            />
          </Typography>
          <PhoneNumberInput onClose={handleCancel} afterSubmit={handleSubmit} />
        </Flex>
      </Banner>
    </CardContainer>
  );
};

export default ContactsRequestInputPhoneNumber;
