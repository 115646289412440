import { includes } from 'lodash-es';

import {
  Job,
  JobScreeningQuestionSelectedQuestions,
} from '../../../common/interfaces/entities';
import {
  CustomQuestionPlainTexts,
  customQuestionPlainTextToFieldName,
  JobFormState,
  JobPostingPayload,
  SelectedQuestionsFormState,
} from '../common/constants';

const isScreeningQuestionSelected = (
  screeningQuestions: JobFormState['screeningQuestions'],
  type: SelectedQuestionsFormState
) => screeningQuestions?.selectedQuestions?.includes(type);

const convertSelectedQuestionsFormStateToPayLoad = (
  selectedQuestions?: SelectedQuestionsFormState[]
): JobScreeningQuestionSelectedQuestions[] | undefined =>
  selectedQuestions?.map(selectedQuestion =>
    includes(CustomQuestionPlainTexts, selectedQuestion)
      ? JobScreeningQuestionSelectedQuestions.CUSTOM_PLAIN_TEXT
      : (selectedQuestion as JobScreeningQuestionSelectedQuestions)
  );

const convertCustomPlainTextScreeningQuestionsToPayload = (
  screeningQuestions: JobFormState['screeningQuestions']
) => {
  const customQuestionPlainTexts =
    screeningQuestions?.selectedQuestions?.filter(
      (selectedQuestion): selectedQuestion is CustomQuestionPlainTexts =>
        includes(CustomQuestionPlainTexts, selectedQuestion)
    );
  if (!customQuestionPlainTexts?.length) {
    return null;
  }

  return {
    customQuestionPlainTexts: customQuestionPlainTexts.map(
      question =>
        screeningQuestions?.[customQuestionPlainTextToFieldName[question]] || ''
    ),
  };
};

export const convertScreeningQuestionFormStateToPayload = ({
  screeningQuestions,
  isEdit,
  isVIPActive,
}: {
  screeningQuestions: JobFormState['screeningQuestions'];
  isEdit: boolean;
  isVIPActive: boolean;
}): Pick<JobPostingPayload, 'screeningQuestions'> | null => {
  const includeScreeningQuestion =
    !isEdit &&
    isVIPActive &&
    screeningQuestions?.selectedQuestions?.length &&
    screeningQuestions.selectedQuestions.length;

  if (!includeScreeningQuestion) {
    return null;
  }

  return {
    screeningQuestions: {
      selectedQuestions:
        convertSelectedQuestionsFormStateToPayLoad(
          screeningQuestions?.selectedQuestions
        ) || [],
      ...(isScreeningQuestionSelected(
        screeningQuestions,
        SelectedQuestionsFormState.INTRO_VIDEO
      ) && {
        introVideoPreferredLanguage:
          screeningQuestions?.introVideoPreferredLanguage,
      }),
      ...(isScreeningQuestionSelected(
        screeningQuestions,
        SelectedQuestionsFormState.INDUSTRY_EXPERIENCE
      ) && {
        industryIds: screeningQuestions?.industries?.map(
          industry => industry.id
        ),
      }),
      ...(isScreeningQuestionSelected(
        screeningQuestions,
        SelectedQuestionsFormState.DOCUMENT_CERTIFICATE_AVAILABILITY
      ) && {
        documentAndCertificateIds:
          screeningQuestions?.documentAndCertificates?.map(
            document => document.id
          ),
      }),
      ...convertCustomPlainTextScreeningQuestionsToPayload(screeningQuestions),
    },
  };
};

const convertSelectedQuestionsResponseToFormState = (
  selectedQuestions?: JobScreeningQuestionSelectedQuestions[]
): SelectedQuestionsFormState[] | undefined => {
  if (!selectedQuestions) return undefined;

  let customPlainTextCounter = 0;

  return selectedQuestions.map(selectedQuestion => {
    if (
      selectedQuestion ===
      JobScreeningQuestionSelectedQuestions.CUSTOM_PLAIN_TEXT
    ) {
      return Object.values(CustomQuestionPlainTexts)[customPlainTextCounter++];
    }
    return selectedQuestion;
  });
};

export const convertScreeningQuestionResponseToFormState = ({
  screeningQuestions,
  isVIPActive,
  isEdit,
}: {
  screeningQuestions: Job['screeningQuestions'];
  isVIPActive: boolean;
  isEdit: boolean;
}): JobFormState['screeningQuestions'] => {
  const noScreeningQuestion =
    !screeningQuestions || screeningQuestions?.selectedQuestions.length < 1;

  const shouldResetScreeningQuestionField = !isEdit && !isVIPActive;

  if (shouldResetScreeningQuestionField || noScreeningQuestion) {
    return undefined;
  }

  return {
    selectedQuestions: convertSelectedQuestionsResponseToFormState(
      screeningQuestions.selectedQuestions
    ),
    introVideoPreferredLanguage:
      screeningQuestions.introVideoPreferredLanguageDetail?.code || undefined,
    industries:
      screeningQuestions.industries.length > 0
        ? screeningQuestions.industries
        : undefined,
    documentAndCertificates:
      screeningQuestions.documentAndCertificates.length > 0
        ? screeningQuestions.documentAndCertificates
        : undefined,
    customQuestionPlainTextA:
      screeningQuestions.customQuestionPlainTexts?.[0] || '',
    customQuestionPlainTextB:
      screeningQuestions.customQuestionPlainTexts?.[1] || '',
    customQuestionPlainTextC:
      screeningQuestions.customQuestionPlainTexts?.[2] || '',
  };
};
