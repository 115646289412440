import React from 'react';
import { Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { getExpiryInDays } from '../../../common/helpers/monetisationUtils';
import { useGetDivElementHeight } from '../../../common/hooks/useGetDivElementHeight';
import { showSubscriptionRenewalContactUsQueryParam } from '../constants';
import { StickyContainer, StyledBanner } from '../styles.sc';
type Props = {
  setFixedBannerHeight: (height: number) => void;
  closeBanner: () => void;
  expiryDate: string;
  planName: string;
};

export const SubscriptionPlanExpiringBanner = ({
  setFixedBannerHeight,
  closeBanner,
  expiryDate,
  planName,
}: Props) => {
  const { ref } = useGetDivElementHeight({
    setElementHeight: setFixedBannerHeight,
  });
  const expiryInDays = getExpiryInDays(expiryDate);
  return (
    <StickyContainer ref={ref}>
      <StyledBanner
        type="fixed"
        status="warning"
        showIcon={false}
        onDismiss={() => {
          closeBanner();
          setFixedBannerHeight(0);
        }}
      >
        <Typography variant="body2" color={Neutral.B18} as="div">
          <FormattedMessage
            id="interactive-subscription-expire-in-days"
            defaultMessage="{count, plural, =0 {Your <bold>{packageName} Plan</bold> will expire today. <link>Contact us</link> to renew your plan.} =1 {Your <bold>{packageName} Plan</bold> will expire tomorrow. <link>Contact us</link> to renew your plan.} other {Your <bold>{packageName} Plan</bold> will expire in {count} days. <link>Contact us</link> to renew your plan.}}"
            values={{
              link: (text: React.ReactNode) => (
                <Link
                  to={`/upgrade-plan?${showSubscriptionRenewalContactUsQueryParam}=1`}
                >
                  {text}
                </Link>
              ),
              packageName: planName,
              bold: (...chunks: React.ReactNode[]) => (
                <Typography variant="body2" as="span">
                  {chunks}
                </Typography>
              ),
              count: expiryInDays,
            }}
          />
        </Typography>
      </StyledBanner>
    </StickyContainer>
  );
};
