import FORBIDDEN_KEYWORDS, {
  DEFAULT_FORBIDDEN_KEYWORDS,
  ForbiddenKeywordsCountries,
} from './forbiddenKeywords';
import { unaccent } from './sanitizeString';

export const isValidPhoneNumber = (value: string) =>
  value.match(/^[\d|+|-]{8,}$/);

export const getCountryCodeFromLocation = (location: string) => {
  if (location) {
    const locationArray = location.split(',');
    const countryCode = locationArray[locationArray.length - 1].trim();
    if (countryCode.length === 2) return countryCode;
  }
  return null;
};

export const getFirstForbiddenKeywordByCountry = (
  input: string,
  country = 'ID',
  isForJob = true
) => {
  if (!input) return null;

  const key = isForJob ? 'job' : 'company';

  let forbiddenKeywords = DEFAULT_FORBIDDEN_KEYWORDS[key];

  if (
    country &&
    Object.values(ForbiddenKeywordsCountries).indexOf(
      country as ForbiddenKeywordsCountries
    ) !== -1
  ) {
    forbiddenKeywords = forbiddenKeywords.concat(
      FORBIDDEN_KEYWORDS[`${country}` as ForbiddenKeywordsCountries][key]
    );
  }

  const normalizedInput = unaccent(input);
  const normalizedForbiddenKeywords = forbiddenKeywords.map(keyword =>
    unaccent(keyword)
  );

  const allMatches = [];

  for (let i = 0; i < normalizedForbiddenKeywords.length; i++) {
    const regex = new RegExp(
      `${normalizedForbiddenKeywords[i]
        .normalize('NFKD')
        .replace(/[\u0300-\u036f]/g, '')}`,
      'i'
    );
    const match = normalizedInput.match(regex);

    if (typeof match?.index !== 'undefined') {
      const forbiddenKeyword = forbiddenKeywords[i];
      const wordFound = input.substring(
        match.index,
        match.index + forbiddenKeyword.length
      );
      if (wordFound.toLowerCase() === forbiddenKeyword.toLowerCase())
        allMatches.push(forbiddenKeyword);
    }
  }

  // return the longest match or null
  return allMatches.length
    ? allMatches.reduce(
        (savedText, text) =>
          text.length > savedText.length ? text : savedText,
        ''
      )
    : null;
};
