import { ScreenSize } from 'glints-aries';
import { space8, space32 } from 'glints-aries/es/@next/utilities/spacing';
import styled from 'styled-components';

export const SubscriptionUpgradeToPremiumBannerContainer = styled.div`
  text-align: center;
  display: flex;
  gap: ${space32};
  align-items: center;
  justify-content: center;
  padding: ${space8} 0;

  @media (max-width: ${ScreenSize.tablet}px) {
    flex-direction: column;
    gap: ${space8};
    padding: 0;
  }
`;

export const PremiumBannerImage = styled.img`
  width: 72px;
  height: 72px;
  @media (max-width: ${ScreenSize.tablet}px) {
    display: none;
  }
`;
