import React from 'react';
import { Greyscale } from 'glints-aries';
import { Typography } from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';
import { MessageTimestamp, useChatContext } from 'stream-chat-react';

import {
  getCVExchangeActingUserByStatus,
  getCVExchangeRequestMessages,
  getGroupCVExchangeRequestMessages,
} from '../../helper';
import { CVRequestAction } from '../../types';

type Props = {
  metadata: CVRequestAction;
};

const CVExchangeStatusSystemMessage = ({ metadata }: Props) => {
  const { client } = useChatContext();
  const actingUser = getCVExchangeActingUserByStatus(metadata);
  const isActingUser = client.userID === actingUser?.id;
  const messages =
    actingUser && !isActingUser
      ? getGroupCVExchangeRequestMessages(metadata)
      : getCVExchangeRequestMessages(metadata);

  if (messages !== null) {
    return (
      <Typography
        variant="subtitle2"
        color={Greyscale.devilsgrey}
        style={{ textAlign: 'center' }}
      >
        <MessageTimestamp format="HH:mm" />
        &#65073;
        <FormattedMessage
          {...messages[metadata.action_payload.status]}
          values={{ memberName: actingUser?.name }}
        />
      </Typography>
    );
  }

  return <></>;
};

export default CVExchangeStatusSystemMessage;
