import { Badge } from 'glints-aries/es/@next';
import { borderRadius8 } from 'glints-aries/es/@next/utilities/borderRadius';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space4, space8 } from 'glints-aries/es/@next/utilities/spacing';
import styled from 'styled-components';

export const CutoutBadgeStyle = styled(Badge)`
  padding: ${space4} ${space8};
  border-radius: ${borderRadius8} 0;
`;

type DotBadgeProps = {
  backgroundColor: string;
  size: number;
};
export const DotBadgeStyle = styled.span<DotBadgeProps>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background-color: ${props => props.backgroundColor};
  border: 2px solid ${Neutral.B100};
  border-radius: 100%;
`;
