import React, { Suspense } from 'react';
import dayjs from 'dayjs';
import { Flex } from 'glints-aries/es';
import { Icon, Typography } from 'glints-aries/es/@next';
import { Blue, Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space4, space8 } from 'glints-aries/es/@next/utilities/spacing';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { GlintsLogoRed } from '../../../common/color';
import { ROUTES } from '../../../common/routes';
import ErrorBoundaryWithCrashReporting from '../../../components/Error/ErrorBoundaryWithCrashReporting';
import { useGetCompanyCreditInventorySuspenseQuery } from '../hooks/useGetCompanyCreditInventory';
import useTopUpGlintsCreditModal from '../TopUpGlintsCreditModal/hooks/useTopUpGlintsCreditModal';

type CreditBalancePopoverMenuItemProps = {
  closePopover: () => void;
};

const CREDIT_BALANCE_EXPIRING_THRESHOLD_DAYS = 7;

function CreditBalancePopoverMenuItemComponent({
  closePopover,
}: CreditBalancePopoverMenuItemProps) {
  const { data } = useGetCompanyCreditInventorySuspenseQuery('cache-first');
  const { formatDate, formatNumber } = useIntl();
  const hasCreditBalance = data.amount > 0;
  const showTopUpButton = !hasCreditBalance;
  const { pathname } = useLocation();
  const isActivePage = new RegExp(`^(/${ROUTES.features}).*`).test(pathname);
  const { TopUpGlintsCreditModal, openModal } = useTopUpGlintsCreditModal();

  const handleClosePopover = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    closePopover();
  };

  const isExpiring = Boolean(
    hasCreditBalance &&
      data.expiryDateTime &&
      dayjs(data.expiryDateTime).diff(dayjs(), 'days') <=
        CREDIT_BALANCE_EXPIRING_THRESHOLD_DAYS
  );

  const topUpButton = (
    <div
      onClick={e => {
        e.preventDefault();
        handleClosePopover(e);
        openModal();
      }}
    >
      <Typography
        variant="button"
        color={Blue.S99}
        style={{
          cursor: 'pointer',
        }}
      >
        <FormattedMessage id="interactive-top-up" defaultMessage="Top Up" />
      </Typography>
    </div>
  );

  const content = (
    <Flex
      style={{
        padding: space8,
        gap: space8,
        cursor: 'pointer',
      }}
    >
      <Icon
        name="ri-glints-logo-fill"
        fill={hasCreditBalance ? GlintsLogoRed : Neutral.B40}
        height={24}
      />
      <div>
        <Typography variant="body1" color={Neutral.B18}>
          <FormattedMessage
            id="text-credit-balance-credit-count"
            defaultMessage="Credit Balance: {creditCount}"
            values={{
              creditCount: formatNumber(data.amount),
            }}
          />
        </Typography>
        <If condition={showTopUpButton}>{topUpButton}</If>

        {data.expiryDateTime && (
          <Flex
            alignItems="center"
            style={{
              gap: space4,
            }}
          >
            <Typography variant="subtitle2" color={Neutral.B40}>
              <FormattedMessage
                id="text-expires-on"
                defaultMessage="Expires on: {expiryDate}"
                values={{
                  expiryDate: formatDate(data.expiryDateTime, {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  }),
                }}
              />
              <If condition={isExpiring}>
                <span>{` - `}</span>
              </If>
            </Typography>
            <If condition={isExpiring}>{topUpButton}</If>
          </Flex>
        )}
      </div>
    </Flex>
  );

  if (isActivePage) {
    return (
      <>
        {TopUpGlintsCreditModal}

        <div
          style={{
            cursor: 'pointer',
          }}
          onClick={handleClosePopover}
        >
          {content}
        </div>
      </>
    );
  }

  return (
    <>
      {TopUpGlintsCreditModal}
      <Link to={`/${ROUTES.features}`} onClick={handleClosePopover}>
        {content}
      </Link>
    </>
  );
}

export default function CreditBalancePopoverMenuItem(
  props: CreditBalancePopoverMenuItemProps
) {
  return (
    <ErrorBoundaryWithCrashReporting fallback={null}>
      <Suspense fallback={null}>
        <CreditBalancePopoverMenuItemComponent {...props} />
      </Suspense>
    </ErrorBoundaryWithCrashReporting>
  );
}
