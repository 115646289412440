import React, { useCallback, useState } from 'react';

import { loadable } from '../../../../common/loadable';
import { TopUpGlintsCreditModalProps } from '../TopUpGlintsCreditModal';

const TopUpGlintsCreditModal = loadable<TopUpGlintsCreditModalProps>(
  () => import('../TopUpGlintsCreditModal').then(module => module.default),
  { fallback: undefined }
);

export default function useTopUpGlintsCreditModal() {
  const [shouldRenderModal, setShouldRenderModal] = useState(false);

  const closeModal = useCallback(() => {
    setShouldRenderModal(false);
  }, []);

  const openModal = useCallback(() => {
    if (!shouldRenderModal) {
      setShouldRenderModal(true);
    }
  }, [shouldRenderModal]);

  return {
    // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
    TopUpGlintsCreditModal: shouldRenderModal ? (
      <TopUpGlintsCreditModal onClose={closeModal} />
    ) : null,
    openModal,
  };
}
