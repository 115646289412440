import { handleActions } from 'redux-actions';

import { Actions } from '../actions/job-titles';

const initialState = {
  loading: false,
  error: null,
  value: [],
};

export default handleActions(
  {
    [Actions.JOB_TITLES_REQUEST]: state => ({
      ...state,
      loading: true,
      error: null,
      value: state.value,
    }),
    [Actions.JOB_TITLES_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      value: [...new Set(action.response.result.concat(state.value))],
    }),
    [Actions.JOB_TITLES_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
      value: state.value,
    }),
  },
  initialState
);
