import React, { Component } from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import FormSelect from '../Select';
import {
  destroyState,
  fetchSchools,
  fetchSelectedSchool,
  initState,
  updateSelectedSchool,
} from './Actions';
import {
  isSelectOptionsLoading,
  makeGetSelectedSchool,
  makeGetSelectOptions,
} from './Selectors';

class FormSelectSchool extends Component {
  static propTypes = {
    fetchSchools: PropTypes.func.isRequired,
    schools: PropTypes.array,
    fetching: PropTypes.bool,
    input: PropTypes.object,

    initState: PropTypes.func.isRequired,
    destroyState: PropTypes.func.isRequired,

    fetchSelectedSchool: PropTypes.func.isRequired,
    updateSelectedSchool: PropTypes.func.isRequired,
    selectedSchool: PropTypes.object,
  };

  static defaultProps = {
    schools: [],
    fetching: false,
  };

  componentDidMount() {
    this.props.initState();

    // If a school was selected, fetch it so we know its name.
    if (this.props.input.value) {
      this.props.fetchSelectedSchool(this.props.input.value);
    }
  }

  componentWillUnmount() {
    this.props.destroyState();
  }

  handleOnChange = value => {
    const { input, updateSelectedSchool } = this.props;
    input.onChange(value.key);
    updateSelectedSchool(value.key);
  };

  render() {
    const { schools, fetchSchools, fetching, selectedSchool, input, ...props } =
      this.props;

    const value = input.value
      ? {
          key: input.value,
          label: selectedSchool ? selectedSchool.name : 'Loading...',
        }
      : undefined;

    return (
      <FormSelect
        options={schools}
        showSearch={true}
        onSearch={fetchSchools}
        optionLabelProp="children"
        filterOption={false}
        labelInValue={true}
        notFoundContent={
          <React.Fragment>
            <If condition={fetching}>
              <Spin size="small" />
            </If>
            <If condition={!fetching}>
              <FormattedMessage
                id="components.Form.SelectSchool.SelectSchool.not.found"
                defaultMessage="Not Found"
              />
            </If>
          </React.Fragment>
        }
        {...props}
        value={value}
        onChange={this.handleOnChange}
      />
    );
  }
}

export default connect(
  () => {
    const getSelectOptions = makeGetSelectOptions();
    const getSelectedSchool = makeGetSelectedSchool();
    return (state, props) => ({
      schools: getSelectOptions(state, props),
      fetching: isSelectOptionsLoading(state, props),
      selectedSchool: getSelectedSchool(state, props),
    });
  },
  (dispatch, ownProps) => ({
    initState: () => dispatch(initState(ownProps.meta.form)),
    destroyState: () => dispatch(destroyState(ownProps.meta.form)),
    fetchSchools: searchTerm =>
      dispatch(fetchSchools(ownProps.meta.form, searchTerm)),
    fetchSelectedSchool: id =>
      dispatch(fetchSelectedSchool(ownProps.meta.form, id)),
    updateSelectedSchool: id =>
      dispatch(updateSelectedSchool(ownProps.meta.form, id)),
  })
)(FormSelectSchool);
