window.lazySizesConfig = {
  // Add classes like 'lazyloaded' to lazyload elements. That allows us to toggle
  // background-images in styled components when lazysizes determines that it
  // should load.
  addClasses: true,
};

// lazysizes is not exactly react friendly. It relies on global side effects to
// do it's thing. Importing it here will run those side effects (i.e. registering
// its event listeners and whatnot).
import 'lazysizes';
