import React from 'react';
import {
  HashLink as BaseHashLink,
  HashLinkProps,
} from 'react-router-hash-link';

export const HashLink: React.FC<HashLinkProps> = props => (
  <BaseHashLink
    scroll={element => {
      const y = element.getBoundingClientRect().top + window.pageYOffset - 70;
      window.scrollTo({ top: y, behavior: 'smooth' });
      const content = document.querySelector('.am-drawer-content');
      if (content) {
        content.scrollTo(0, element.offsetTop);
      }
    }}
    {...props}
  />
);
