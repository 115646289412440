import loadable from '@loadable/component';

import { EModalName } from './ModalConstants';

export const modalRegistry = {
  [EModalName.APPLICATION_REJECTION_REASONS]: loadable(
    () =>
      import(
        /* webpackChunkName: "modal-application-rejection" */ './modals/ApplicationRejectionModal'
      )
  ),
  [EModalName.HIRE_CONFIRMATION]: loadable(
    () =>
      import(
        /* webpackChunkName: "modal-candidate-hire-confirmation" */ './modals/HireCandidateConfirmationModal'
      )
  ),
};
