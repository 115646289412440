import { useCallback, useEffect } from 'react';
import { apm } from '@elastic/apm-rum';
import { isArray } from 'lodash-es';
import moment from 'moment';
import { useSWRConfig } from 'swr';

import {
  ApplicationDetails,
  Job,
} from '../../../../common/interfaces/entities';
import {
  ApplicationDetailsResponse,
  ApplicationListWithBreakdownResponse,
} from '../../../../common/interfaces/responses';
import { SwrCacheKeys } from '../../../../common/swr-cache-keys';
import { READ_TIMEOUT } from '../../constants';
import { useApplicationReadStatus } from '../../hooks/useApplicationReadStatus';

type Props = {
  applicationId: ApplicationDetails['id'];
  jobId: Job['id'];
};

export const RenderlessSetApplicationFirstReadAt = ({
  applicationId,
  jobId,
}: Props) => {
  const { setApplicationRead } = useApplicationReadStatus();

  const { mutate } = useSWRConfig();

  const handleAfterApplicationReadAtSet = useCallback(async () => {
    await mutate<ApplicationListWithBreakdownResponse>(
      key => isArray(key) && key[0] === SwrCacheKeys.jobApplications(jobId),
      data => {
        if (
          !data?.data?.applications?.some(
            application => application.id === applicationId
          )
        ) {
          return data;
        }
        return {
          ...data,
          data: {
            ...data.data,
            applications: data.data.applications.map(application => {
              if (application.id !== applicationId) return application;
              return {
                ...application,
                firstReadAt: moment().toISOString(),
              };
            }),
          },
        };
      },
      { revalidate: false }
    );
    mutate<ApplicationDetailsResponse>(
      SwrCacheKeys.applicationDetails(jobId, applicationId),
      data => {
        if (!data) return data;
        return {
          ...data,
          data: {
            ...data.data,
            firstReadAt: moment().toISOString(),
          },
        };
      },
      { revalidate: false }
    );
  }, [applicationId, jobId, mutate]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      try {
        void setApplicationRead(applicationId);
        void handleAfterApplicationReadAtSet();
      } catch (error) {
        apm.captureError(error as Error);
      }
    }, READ_TIMEOUT);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [applicationId, handleAfterApplicationReadAtSet, setApplicationRead]);

  return null;
};
