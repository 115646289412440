import { Drawer } from 'antd-mobile';
import { DrawerWebProps } from 'antd-mobile/lib/drawer/PropsType';
import { ScreenSize } from 'glints-aries';
import styled from 'styled-components';

interface ResetDrawerProps extends DrawerWebProps {
  headerHeight: number;
}

const ResetDrawer = styled(Drawer)<ResetDrawerProps>`
  position: relative !important;

  .am-drawer-sidebar {
    position: fixed;
    min-width: 300px;
    z-index: 11;
    background-color: white;
  }

  .am-drawer-content {
    position: relative;
    overflow: visible;

    .content,
    .ats-job-detail-bar-container {
      min-height: calc(100vh - ${props => props.headerHeight}px);
    }
  }

  @media (min-width: ${ScreenSize.tablet}px) {
    .am-drawer-sidebar {
      display: none;
    }
  }
`;

export default ResetDrawer;
