import React from 'react';
import { Flex, PrimaryColor, SecondaryColor } from 'glints-aries';
import { Banner, Button, Icon, Typography } from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';
import { useChannelStateContext, useMessageContext } from 'stream-chat-react';

import {
  ApplicationChatContactsExchangeRequestStatus,
  useMessagingChannelContext,
} from '../../constants';
import {
  useCreateShareContactsRequest,
  useUpdateShareContactsRequest,
} from '../../graphql';
import { getChannelApplicationMetadata } from '../../helper';
import { useRebuildComponentOnChannelEvent } from '../../hooks';
import { ContactsRequestAction } from '../../types';
import ResponsiveButtonGroup from '../ResponsiveButtonGroup';
import { CardContainer } from '../Styled';
import CandidatePhoneNumber from './CandidatePhoneNumber';
import ContactsExchangeStatusSystemMessage from './StatusSystemMessage';

type Props = {
  metadata: ContactsRequestAction;
};

const EmpContactsRequestMessageContent = ({ metadata }: Props) => {
  useRebuildComponentOnChannelEvent('channel.updated');
  const { message } = useMessageContext();
  const { uiMode } = useMessagingChannelContext();
  const { channel } = useChannelStateContext();
  const [createShareContactsRequest, createShareContactsRequestResult] =
    useCreateShareContactsRequest();
  const [updateShareContactsRequest, updateShareContactsRequestResult] =
    useUpdateShareContactsRequest();

  const channelMetadata = getChannelApplicationMetadata(channel);
  const isLatestRequest =
    message.id === channelMetadata?.contacts_request_msg_id;
  const showBanner =
    isLatestRequest &&
    !metadata.action_payload.hidden &&
    [
      ApplicationChatContactsExchangeRequestStatus.PENDING,
      ApplicationChatContactsExchangeRequestStatus.ACCEPTED,
      ApplicationChatContactsExchangeRequestStatus.REJECTED,
    ].includes(metadata.action_payload.status);

  return (
    <>
      <If
        condition={
          metadata.action_payload.status !==
          ApplicationChatContactsExchangeRequestStatus.PENDING
        }
      >
        <ContactsExchangeStatusSystemMessage metadata={metadata} />
      </If>
      <If condition={showBanner}>
        <CardContainer>
          <Banner dismissable={false} iconName="ri-information-line">
            <Flex flexDirection="column" style={{ gap: 8 }}>
              <Choose>
                <When
                  condition={
                    metadata.action_payload.status ===
                    ApplicationChatContactsExchangeRequestStatus.PENDING
                  }
                >
                  <Typography variant="body1">
                    <FormattedMessage
                      id="text_action-card_phone-request-sent"
                      defaultMessage="Exchange phone request sent"
                    />
                  </Typography>
                  <Flex alignItems="center" style={{ gap: 8 }}>
                    <Icon
                      name="ri-information-fill"
                      style={{
                        position: 'relative',
                        width: '16px',
                        height: '16px',
                        fill: SecondaryColor.actionblue,
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      color={SecondaryColor.actionblue}
                    >
                      <FormattedMessage
                        id="text_action-card_waiting-cdd-accept-phone"
                        defaultMessage="Please wait for candidate's confirmation."
                      />
                    </Typography>
                  </Flex>
                  <ResponsiveButtonGroup>
                    <Button
                      disabled={updateShareContactsRequestResult.loading}
                      onClick={() =>
                        updateShareContactsRequest({
                          variables: {
                            messageId: message.id,
                            input: {
                              status:
                                ApplicationChatContactsExchangeRequestStatus.CANCELED,
                              telemetryEventProps: {
                                ui_type: uiMode,
                              },
                            },
                          },
                        })
                      }
                    >
                      <FormattedMessage
                        id="button-text_cancel"
                        defaultMessage="Cancel"
                      />
                    </Button>
                  </ResponsiveButtonGroup>
                </When>
                <When
                  condition={
                    metadata.action_payload.status ===
                    ApplicationChatContactsExchangeRequestStatus.ACCEPTED
                  }
                >
                  <CandidatePhoneNumber metadata={metadata} />
                </When>
                <When
                  condition={
                    metadata.action_payload.status ===
                    ApplicationChatContactsExchangeRequestStatus.REJECTED
                  }
                >
                  <Typography variant="body1">
                    <FormattedMessage
                      id="text_action-card_phone-request-sent"
                      defaultMessage="Exchange phone request sent"
                    />
                  </Typography>
                  <Flex alignItems="center" style={{ gap: 8 }}>
                    <Icon
                      name="ri-information-fill"
                      style={{
                        position: 'relative',
                        width: '16px',
                        height: '16px',
                        fill: PrimaryColor.glintsred,
                        flexShrink: 0,
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      color={PrimaryColor.glintsred}
                    >
                      <FormattedMessage
                        id="text_action-card_cdd-reject-phone"
                        defaultMessage="Uh oh, the candidate has rejected your request. Don't worry, continue chatting or try again."
                      />
                    </Typography>
                  </Flex>
                  <ResponsiveButtonGroup>
                    <Button
                      disabled={createShareContactsRequestResult.loading}
                      onClick={async () => {
                        if (channel.id) {
                          await createShareContactsRequest({
                            variables: {
                              channelId: channel.id,
                            },
                          });
                        }
                      }}
                    >
                      <Typography variant="button">
                        <FormattedMessage
                          id="button-text_try-again"
                          defaultMessage="Try again"
                        />
                      </Typography>
                    </Button>
                  </ResponsiveButtonGroup>
                </When>
              </Choose>
            </Flex>
          </Banner>
        </CardContainer>
      </If>
    </>
  );
};

export default EmpContactsRequestMessageContent;
