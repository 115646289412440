import { ApplicationStatus } from '../../common/constants';

export interface ApplicationDetailURLParams {
  applicationId: string;
  jobId: string;
}

export interface EmptyApplicationDetailURLParams {
  jobId: string;
  status: ApplicationStatus;
}

export const READ_TIMEOUT = 1000; // ms
