import { getFeature } from '../../../selectors/features';
import { UnleashFeatureNames } from '../../Unleash/featureNames';

export const getLoginLoading = (state: any) => state.login.loading;
export const getLoginSuccess = (state: any) => state.login.success;
export const getLoginError = (state: any) => state.login.error;

export const getFacebookIsOpen = (state: any) => state.login.facebook.isOpen;
export const getFacebookLoading = (state: any) => state.login.facebook.loading;
export const getFacebookSuccess = (state: any) => state.login.facebook.success;
export const getFacebookError = (state: any) => state.login.facebook.error;

export const getLinkedInCsrfToken = (state: any) =>
  state.login.linkedIn.csrfToken;
export const getLinkedInIsOpen = (state: any) => state.login.linkedIn.isOpen;
export const getLinkedInLoading = (state: any) => state.login.linkedIn.loading;
export const getLinkedInSuccess = (state: any) => state.login.linkedIn.success;
export const getLinkedInError = (state: any) => state.login.linkedIn.error;

export const getMfaTokenExpired = (state: any) =>
  state.login.multiFactorAuthentication.isMfaTokenExpired;
export const getMfaRequired = (state: any) =>
  state.login.multiFactorAuthentication.isRequired;
export const getMfaToken = (state: any) =>
  state.login.multiFactorAuthentication.mfaToken;
export const getMfaDetails = (state: any) =>
  state.login.multiFactorAuthentication.mfaDetails;

export const getIsNewLoginVerificationEnabled = (state: any) =>
  getFeature(state, UnleashFeatureNames.empIsNewLoginVerificationEnabled);
