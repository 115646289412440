import React from 'react';

export default function EmptyErrorBoundaryFallbackComponent() {
  return (
    <div
      style={{
        display: 'none',
      }}
    />
  );
}
