import CALL_API from '../../middleware/api_key';
import { Schemas } from '../../middleware/schemas';
import { getIsFeatureEnabled } from '../../selectors/features';
import { openModal } from '../Modal/Actions';
import { EModalName } from '../Modal/ModalConstants';
import { UnleashFeatureNames } from '../Unleash/featureNames';
import { JOB_APPLICATION_STATUS_ENUM } from './Constants';

export const Actions = {
  UPDATE_APPLICATION_STATUS_REQUEST:
    'glints/application/UPDATE_APPLICATION_STATUS_REQUEST',
  UPDATE_APPLICATION_STATUS_SUCCESS:
    'glints/application/UPDATE_APPLICATION_STATUS_SUCCESS',
  UPDATE_APPLICATION_STATUS_FAILURE:
    'glints/application/UPDATE_APPLICATION_STATUS_FAILURE',
};

const bulkChangeApplicationStatusUrl = (
  jobId,
  isApplicationsBulkAPIV2Enabled
) =>
  isApplicationsBulkAPIV2Enabled
    ? `v2/v2/jobs/${jobId}/applications`
    : `jobs/${jobId}/applicationsBulk`;

export function updateApplicationStatus(
  application,
  status,
  options = {},
  skipConfirmation = false
) {
  if (application.status === status) {
    return {
      type: 'NOOP',
      comment:
        "This action is supposed to do nothing. It allows an action-creator to 'abort' an action by dispatching an action with no effect. Do not use this action type in a reducer!",
    };
  }

  if (!skipConfirmation) {
    switch (status) {
      case JOB_APPLICATION_STATUS_ENUM.HIRED:
        return openModal(EModalName.HIRE_CONFIRMATION, application);
      case JOB_APPLICATION_STATUS_ENUM.REJECTED:
        return openModal(EModalName.APPLICATION_REJECTION_REASONS, application);
      default:
        break;
    }
  }

  return async (dispatch, getState) => {
    const isApplicationsBulkAPIV2Enabled = getIsFeatureEnabled(
      getState(),
      UnleashFeatureNames.empApplicationsBulkAPIV2
    );
    await dispatch({
      [CALL_API]: {
        types: [
          Actions.UPDATE_APPLICATION_STATUS_REQUEST,
          Actions.UPDATE_APPLICATION_STATUS_SUCCESS,
          Actions.UPDATE_APPLICATION_STATUS_FAILURE,
        ],
        endpoint: bulkChangeApplicationStatusUrl(
          application.JobId,
          isApplicationsBulkAPIV2Enabled
        ),
        schema: Schemas.APPLICATION_UPDATE,
        method: 'put',
        options: {
          data: {
            updates: [
              {
                ...options.data,
                applicationId: application.id,
                status,
              },
            ],
          },
        },
        responseTransformer: response => ({
          data: response,
        }),
      },
    });
  };
}
