import React, { FC, useEffect } from 'react';

import { setNoIndex, unsetNoIndex } from '../actions/meta';
import { useAppDispatch } from '../store';

const NoIndexWrapper = (WrappedComponent: FC<any>) => {
  const NoIndex = (props: JSX.IntrinsicAttributes) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
      dispatch(setNoIndex());
      return () => {
        dispatch(unsetNoIndex());
      };
    }, [dispatch]);

    return <WrappedComponent {...props} />;
  };

  return NoIndex;
};

export default NoIndexWrapper;
