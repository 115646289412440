import React from 'react';
import { Spinner } from 'glints-aries/es/@next';
import { Blue } from 'glints-aries/es/@next/utilities/colors';
import styled from 'styled-components';

import { StyledModal } from './styles.sc';

const LoadingStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 320px;
`;

type LoadingStateModalProps = {
  onClose: () => void;
};

export const LoadingStateModal = ({ onClose }: LoadingStateModalProps) => (
  <StyledModal isOpen={true} onClose={onClose}>
    <LoadingStateContainer>
      <Spinner fill={Blue.S99} size="large" />
    </LoadingStateContainer>
  </StyledModal>
);
