import React, { useEffect, useRef, useState } from 'react';
import { ScreenSize, Textarea } from 'glints-aries/es';
import {
  Modal,
  OutlineButton,
  PrimaryButton,
  Typography,
} from 'glints-aries/es/@next';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { useSetRefParentElementZIndex } from '../../hooks';
import ResponsiveButtonGroup from '../ResponsiveButtonGroup';

const messageMaxLength = 200;
const messages = defineMessages({
  headerNew: {
    id: 'text_add_new-message-title',
    defaultMessage: 'Add new template message',
  },
  headerEdit: {
    id: 'text_edit-message-title',
    defaultMessage: 'Edit message',
  },
  placeholder: {
    id: 'placeholder-text_new-template-message',
    defaultMessage: 'Write your message...',
  },
});

const Styled = {
  Modal: styled(Modal)`
    width: 580px;

    .modal-content {
      width: 100%;
    }

    @media (max-width: ${ScreenSize.desktopM - 1}px) {
      width: 100%;
    }
  `,
};

type Props = {
  defaultMessage?: string;
  onSave: (message: string) => void;
  onClose: () => void;
};

const EditTemplateMessageModal = ({
  defaultMessage = '',
  onSave,
  onClose,
}: Props) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [message, setMessage] = useState(defaultMessage);
  const { formatMessage } = useIntl();
  const modalRef = useSetRefParentElementZIndex('1000001');

  const messageLength = message.trim().length;
  const hasError = messageLength > messageMaxLength;
  const handleSave = () => {
    if (!hasError) {
      onSave(message);
      onClose();
    }
  };

  useEffect(() => {
    textareaRef.current?.setSelectionRange(-1, -1);
  }, []);

  return (
    <Styled.Modal
      ref={modalRef}
      isOpen={true}
      header={formatMessage(
        defaultMessage ? messages.headerEdit : messages.headerNew
      )}
      customActions={
        <ResponsiveButtonGroup>
          <OutlineButton onClick={onClose}>
            <Typography variant="button">
              <FormattedMessage
                id="button-text_cancel"
                defaultMessage="Cancel"
              />
            </Typography>
          </OutlineButton>
          <PrimaryButton
            disabled={!messageLength || hasError}
            onClick={handleSave}
          >
            <Typography variant="button">
              <FormattedMessage id="interactive-save" defaultMessage="Save" />
            </Typography>
          </PrimaryButton>
        </ResponsiveButtonGroup>
      }
      onClose={onClose}
    >
      <Textarea
        ref={textareaRef}
        value={message}
        placeholder={formatMessage(messages.placeholder)}
        onChange={event => setMessage(event.currentTarget.value)}
        inputMode="text"
        status={hasError ? 'error' : 'default'}
        // @ts-ignore
        autoFocus={true}
        minLength={1}
        maxLength={messageMaxLength}
      />
      <Typography variant="subtitle2">
        {messageLength}/{messageMaxLength}
      </Typography>
    </Styled.Modal>
  );
};

export default EditTemplateMessageModal;
