import { handleActions } from 'redux-actions';

import {
  Actions,
  REMOVE_COMPANY_JOB_ID_FROM_STATE,
  UPDATE_COMPANY_JOBS_VALUE,
} from '../actions/jobs';

const initialState = {
  companyJobs: {
    loading: false,
    error: null,
    value: [],
    total: 0,
    views: [],
  },
};

export default handleActions(
  {
    [Actions.COMPANY_JOBS_REQUEST]: state => ({
      ...state,
      companyJobs: {
        ...state.companyJobs,
        loading: true,
        error: null,
        value: [],
        views: [],
        total: 0,
      },
    }),
    [Actions.COMPANY_JOBS_SUCCESS]: (state, action) => ({
      ...state,
      companyJobs: {
        ...state.companyJobs,
        loading: false,
        error: null,
        value: action.response.result,
        views: action.response.result.map(r => ({ id: r })),
        total: action.response.count,
      },
    }),
    [Actions.COMPANY_JOBS_FAILURE]: (state, action) => ({
      ...state,
      companyJobs: {
        ...state.companyJobs,
        loading: false,
        error: action.error,
        value: [],
        views: [],
        total: 0,
      },
    }),
    [REMOVE_COMPANY_JOB_ID_FROM_STATE]: (state, action) => ({
      ...state,
      companyJobs: {
        ...state.companyJobs,
        value: state.companyJobs.value.filter(jId => jId !== action.payload),
        total: state.companyJobs.total - 1,
      },
    }),
    [UPDATE_COMPANY_JOBS_VALUE]: (state, action) => ({
      ...state,
      companyJobs: {
        value: action.payload,
      },
    }),
  },
  initialState
);
