import { ScreenSize } from 'glints-aries/es';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { COUNTRY_DOMAINS } from '../../common/constants';
import { getConfig } from '../../config';
import { getIsFeatureEnabled } from '../../selectors/features';
import { getIsLoggedIn } from '../../selectors/session';
import { UnleashFeatureNames } from '../Unleash/featureNames';

export default function useShowMobileAppPrompt(): boolean {
  const isMobileView = useMediaQuery({ maxWidth: ScreenSize.tablet - 1 });
  const useMobileAppPromptEnabled = useSelector(state =>
    getIsFeatureEnabled(state, UnleashFeatureNames.empUseMobileAppPrompt)
  );
  const { COUNTRY } = getConfig();

  const isEnabledForCountry = [COUNTRY_DOMAINS.id, COUNTRY_DOMAINS.vn].includes(
    COUNTRY
  );

  const isLoggedIn = useSelector(getIsLoggedIn);
  return (
    isLoggedIn &&
    isEnabledForCountry &&
    useMobileAppPromptEnabled &&
    isMobileView
  );
}
