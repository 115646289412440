import React, { useEffect } from 'react';
import autosize from 'autosize';
import { Greyscale, ScreenSize } from 'glints-aries';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import {
  ChatAutoComplete,
  MessageInput,
  useChannelStateContext,
  useMessageInputContext,
} from 'stream-chat-react';
import styled from 'styled-components';

import { ApplicationStatus } from '../../../common/constants';
import { getFeatures } from '../../../selectors/features';
import { getChannelApplicationMetadataV2 } from '../helper';
import { useEmpRespondSideEffect, useIsPopupMode } from '../hooks';
import TemplateMessagesPopoverToggle from './TemplateMessages/TemplateMessagesPopoverToggle';
import UploadFileButton from './UploadFileButton';

const noop = () => undefined;

const BaseContainer = styled.div`
  display: flex;
  padding: 16px;
  border-top: 1px solid ${Neutral.B85};
  background-color: ${Greyscale.white};
  gap: 8px;
  z-index: 99;

  .str-chat__textarea textarea {
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
`;

const ResponsiveContainer = styled(BaseContainer)`
  @media (max-width: ${ScreenSize.desktopM - 1}px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
`;

const Input = () => {
  const { formatMessage } = useIntl();
  const { channel } = useChannelStateContext();
  const isPopupMode = useIsPopupMode();
  const { handleSubmit, textareaRef } = useMessageInputContext();
  const metadata = getChannelApplicationMetadataV2(channel);
  const { empHideChatAttachment } = useSelector(getFeatures);
  const empRespondSideEffect = useEmpRespondSideEffect();
  const isSmallDeviceScreen = useMediaQuery({
    maxWidth: ScreenSize.mobileM - 1,
  });

  useEffect(() => {
    if (textareaRef.current) {
      autosize(textareaRef.current);
    }
  }, [textareaRef]);

  const Container = isPopupMode ? BaseContainer : ResponsiveContainer;

  return (
    <Container>
      <TemplateMessagesPopoverToggle
        shrink={isSmallDeviceScreen || isPopupMode}
      />
      <ChatAutoComplete
        placeholder={formatMessage({
          id: 'placholder-text_chat-input',
          defaultMessage: 'Write something...',
        })}
        handleSubmit={event => {
          const isFirstReply =
            metadata.glints_application_status === ApplicationStatus.NEW;

          handleSubmit(event, {
            isFirstReply,
          });

          const { value } = event.target;
          if (typeof value === 'string' && value.trim()) {
            empRespondSideEffect();
          }

          // autoresize textarea
          if (textareaRef.current) {
            textareaRef.current.value = '';
            textareaRef.current.dispatchEvent(new Event('input'));
          }
        }}
        onPaste={noop}
      />
      <If condition={!empHideChatAttachment}>
        <UploadFileButton channel={channel} />
      </If>
    </Container>
  );
};

const MessagingInput = () => (
  <MessageInput Input={Input} disableMentions={true} />
);

export default MessagingInput;
