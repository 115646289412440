import { startCase } from 'lodash-es';
import { schema } from 'normalizr';

/**
 * We use this Normalizr schemas to transform API responses from a nested form
 * to a flat form where repos and users are placed in `entities`, and nested
 * JSON objects are replaced with their IDs. This is very convenient for
 * consumption by reducers, because we can easily build a normalized tree
 * and keep it updated as we fetch more data.
 *
 * Read more about Normalizr: https://github.com/paularmstrong/normalizr
 */

const ActivityLogSchema = new schema.Entity('activityLog');
const ApplicationSchema = new schema.Entity('application');
const ApplicationUpdateSchema = new schema.Object({
  updates: [ApplicationSchema],
});
const BookmarkSchema = new schema.Entity('bookmark');
const CitySchema = new schema.Entity('city');
const CompanySchema = new schema.Entity('company');
const CompanyUserSchema = new schema.Entity('companyUser');
const CountrySchema = new schema.Entity('country', {}, { idAttribute: 'code' });
const CurrencySchema = new schema.Entity(
  'currency',
  {},
  { idAttribute: 'code' }
);
const GroupMemberSchema = new schema.Entity('groupMember');
const GroupSchema = new schema.Entity('group');
const IdentitySchema = new schema.Entity('identity');
const IndustrySchema = new schema.Entity('industry');
const JobCategorySchema = new schema.Entity('jobCategory');
const JobDraftSchema = new schema.Entity('jobDraft');
const JobSchema = new schema.Entity('job');
const JobTitleSchema = new schema.Entity('jobTitle');
const JobTypeSchema = new schema.Entity(
  'jobType',
  {},
  { idAttribute: 'value' }
);
const RecommendationSchema = new schema.Entity('recommendation');
const SchoolSchema = new schema.Entity('school');
const SkillSchema = new schema.Entity('skill');
const StudySchema = new schema.Entity('study');
const TagSchema = new schema.Entity('tag');
const TalentHuntApplicationSchema = new schema.Entity('talentHuntApplication');
const TalentSchema = new schema.Entity(
  'talent',
  {},
  {
    processStrategy: value => {
      if (value.links) {
        const keys = Object.keys(value.links);
        keys.forEach(key => {
          value[startCase(key).split(' ').join('')] = value.links[key];
        });
        delete value.links;
      }
      return value;
    },
  }
);
const UserSchema = new schema.Entity('user');
const RecruiterSchema = new schema.Entity('recruiter');
const FeatureSchema = new schema.Entity(
  'features',
  {},
  { idAttribute: 'name' }
);

ApplicationSchema.define({
  Applicant: TalentSchema,
});

CitySchema.define({
  links: {
    country: CountrySchema,
  },
});

CompanySchema.define({
  links: {
    jobs: [JobSchema],
  },
});

JobDraftSchema.define({
  links: {
    job: JobSchema,
  },
});

RecommendationSchema.define({
  links: {
    talent: TalentSchema,
  },
});

export const Schemas = {
  ACTIVITY_LOG: ActivityLogSchema,
  ACTIVITY_LOG_ARRAY: [ActivityLogSchema],
  APPLICATION: ApplicationSchema,
  APPLICATION_UPDATE: ApplicationUpdateSchema,
  APPLICATION_ARRAY: [ApplicationSchema],
  BOOKMARK: BookmarkSchema,
  BOOKMARK_ARRAY: [BookmarkSchema],
  CITY: CitySchema,
  CITY_ARRAY: [CitySchema],
  COMPANY: CompanySchema,
  COMPANY_ARRAY: [CompanySchema],
  COMPANY_USER: CompanyUserSchema,
  COMPANY_USER_ARRAY: [CompanyUserSchema],
  COUNTRY: CountrySchema,
  COUNTRY_ARRAY: [CountrySchema],
  CURRENCY: CurrencySchema,
  CURRENCY_ARRAY: [CurrencySchema],
  FEATURE_ARRAY: [FeatureSchema],
  GROUP: GroupSchema,
  GROUP_ARRAY: [GroupSchema],
  GROUP_MEMBER: GroupMemberSchema,
  GROUP_MEMBER_ARRAY: [GroupMemberSchema],
  IDENTITY: IdentitySchema,
  IDENTITY_ARRAY: [IdentitySchema],
  INDUSTRY: IndustrySchema,
  INDUSTRY_ARRAY: [IndustrySchema],
  JOB: JobSchema,
  JOB_ARRAY: [JobSchema],
  JOB_DRAFT: JobDraftSchema,
  JOB_DRAFT_ARRAY: [JobDraftSchema],
  JOB_CATEGORY: JobCategorySchema,
  JOB_CATEGORY_ARRAY: [JobCategorySchema],
  JOB_TITLE: JobTitleSchema,
  JOB_TITLE_ARRAY: [JobTitleSchema],
  JOB_TYPE_ARRAY: [JobTypeSchema],
  RECOMMENDATION: RecommendationSchema,
  RECOMMENDATION_ARRAY: [RecommendationSchema],
  SCHOOL: SchoolSchema,
  SCHOOL_ARRAY: [SchoolSchema],
  SKILL: SkillSchema,
  SKILL_ARRAY: [SkillSchema],
  STUDY: StudySchema,
  STUDY_ARRAY: [StudySchema],
  TAG: TagSchema,
  TAG_ARRAY: [TagSchema],
  TALENT: TalentSchema,
  TALENT_ARRAY: [TalentSchema],
  TALENTHUNT_APPLICATION: TalentHuntApplicationSchema,
  TALENTHUNT_APPLICATION_ARRAY: [TalentHuntApplicationSchema],
  USER: UserSchema,
  RECRUITER: [RecruiterSchema],
};
