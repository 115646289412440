import React from 'react';
import { RadioButton } from 'glints-aries/es/@next';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
  ApplicationRejectionMessagePreference,
  RejectionReasonPreferenceFormState,
} from '../../types';
import {
  rejectionMethodExplanationMessages,
  rejectionMethodMessages,
} from '../consts';

export default function NoMessageOption() {
  const { formatMessage } = useIntl();
  const { control } = useFormContext<RejectionReasonPreferenceFormState>();
  return (
    <Controller
      name="preferredType"
      control={control}
      render={({ field }) => (
        <RadioButton
          {...field}
          checked={
            field.value === ApplicationRejectionMessagePreference.NO_MESSAGE
          }
          label={formatMessage(
            rejectionMethodMessages[
              ApplicationRejectionMessagePreference.NO_MESSAGE
            ]
          )}
          helperText={formatMessage(
            rejectionMethodExplanationMessages[
              ApplicationRejectionMessagePreference.NO_MESSAGE
            ]
          )}
          value={ApplicationRejectionMessagePreference.NO_MESSAGE}
        />
      )}
    />
  );
}
