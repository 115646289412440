import { useSelector } from 'react-redux';

import { UnleashFeatureNames } from '../../modules/Unleash/featureNames';
import { getIsFeatureEnabled } from '../../selectors/features';
import { countryCodeIsIndonesia } from '../helpers/countryCodeHelper';
import { useGetSessionCompany } from './useGetSessionCompany';

export default function useIsPremiumFiltersEnabled(): boolean {
  const { sessionCompany } = useGetSessionCompany();
  const isPremiumFiltersEnabled = useSelector(state =>
    getIsFeatureEnabled(state, UnleashFeatureNames.empPremiumFilters)
  );

  return Boolean(
    isPremiumFiltersEnabled &&
      sessionCompany &&
      countryCodeIsIndonesia(sessionCompany.CountryCode)
  );
}
