import React from 'react';
import { Modal, Typography } from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  .modal-content {
    width: 100%;
    height: fit-content;
    display: flex;
    place-content: center;
  }
`;

export default function LoadingModal() {
  return (
    <StyledModal isOpen={true}>
      <Typography variant="body1">
        <FormattedMessage defaultMessage="Loading..." id="text-loading" />
      </Typography>
    </StyledModal>
  );
}
