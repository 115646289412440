import { defineMessage } from 'react-intl';

import config from '../../../../../config';
import { LinkDefinition } from '../types';

const companySectionLinks: LinkDefinition[] = [
  {
    defaultURL: `${config.CANDIDATE_URL}/sg/about/`,
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'About Us',
      id: 'text-footer-about-us',
    }),
    className: 'about',
  },
  {
    defaultURL: `${config.CANDIDATE_URL}/sg/about/team`,
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Our Team',
      id: 'text-footer-our-team',
    }),
    className: 'team',
  },
  {
    defaultURL: `${config.CANDIDATE_URL}/sg/careers`,
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Work at Glints',
      id: 'text-footer-work-at-glints',
    }),
    className: 'careers',
  },
  {
    defaultURL: 'https://security.glints.com/',
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Report Vulnerability',
      id: 'text-footer-report-vulnerability',
    }),
    className: 'security',
  },
  {
    defaultURL: `${config.CANDIDATE_URL}/sg/about/terms`,
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'User Agreement',
      id: 'text-footer-user-agreement',
    }),
    className: 'terms',
  },
  {
    defaultURL: `${config.CANDIDATE_URL}/sg/about/privacy-policy`,
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Privacy Policy',
      id: 'text-footer-privacy-policy',
    }),
    className: 'privacy-policy',
  },
  {
    defaultURL: `${config.CANDIDATE_URL}/sg/about/standard-terms-for-services`,
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Standard Terms of Service',
      id: 'text-footer-standard-terms',
    }),
    className: 'standard-terms',
  },
];

const forJobSeekers: LinkDefinition[] = [
  {
    defaultURL: 'https://help.glints.com/hc/en-us',
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Help Center',
      id: 'text-footer-help-center',
    }),
    className: 'help-center',
  },
  {
    defaultURL: `${config.CANDIDATE_URL}/sg/browse/company`,
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Jobs by Company Name',
      id: 'text-footer-jobs-by-company-name',
    }),
    className: 'jobs-by-company',
  },
  {
    defaultURL: `${config.CANDIDATE_URL}/sg/browse/job-category`,
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Jobs by Category',
      id: 'text-footer-jobs-by-category',
    }),
    className: 'jobs-by-category',
  },
];

const forEmployers: LinkDefinition[] = [
  {
    defaultURL: 'https://employers.glints.sg',
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Job Marketplace',
      id: 'text-footer-job-marketplace',
    }),
    className: 'job-marketplace',
  },
  {
    defaultURL: 'https://talenthub.glints.com',
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Talenthub',
      id: 'text-footer-talenthub',
    }),
    className: 'talenthub',
  },
  {
    defaultURL: 'https://employers.glints.com/en-sg/blog/',
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Blog',
      id: 'text-footer-blog',
    }),
    className: 'blog',
  },
  {
    defaultURL: 'https://talenthub.glints.com/ebooks',
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Ebooks',
      id: 'text-footer-ebooks',
    }),
    className: 'ebooks',
  },
];

export const links = [
  {
    title: defineMessage({
      defaultMessage: 'Company',
      id: 'text-company-v2',
    }),
    links: companySectionLinks,
  },
  {
    title: defineMessage({
      defaultMessage: 'For Job Seekers',
      id: 'text-footer-job-seekers',
    }),
    links: forJobSeekers,
  },
  {
    title: defineMessage({
      defaultMessage: 'For Employers',
      id: 'text-footer-for-employers',
    }),
    links: forEmployers,
  },
];
