import { ScreenSize } from 'glints-aries';
import { space4, space8 } from 'glints-aries/es/@next/utilities/spacing';
import styled from 'styled-components';

export const StyledTextInputContainer = styled.div`
  width: calc(50% - ${space8});
  display: flex;
  flex-direction: column;
  gap: ${space4};

  @media (max-width: ${ScreenSize.mobileL}px) {
    width: 100%;
  }
`;
