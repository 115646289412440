import React, { Suspense, useState } from 'react';
import {
  ButtonProps,
  Icon,
  PrimaryButton,
  Typography,
} from 'glints-aries/es/@next';
import { Popover } from 'glints-aries/es/@next/Popover';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space8, space16 } from 'glints-aries/es/@next/utilities/spacing';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { WhatsAppGreen } from '../../../../../common/color';
import {
  trackWAPopoverInCommunicationClickedEvent,
  WhatsAppContactPopoverSources,
} from '../../../../../components/ApplicantWhatsAppContactPopover/tracking';
import { useBuildWhatsAppMessageAndRedirect } from '../../../../../components/ApplicantWhatsAppContactPopover/useBuildWhatsAppMessageAndRedirect';
import RenderBasedOnApplicationAccessLevel from '../../../../../components/FullProfileAccess/RenderBasedOnApplicationAccessLevel';
import { RejectedMoveToInCommunication } from '../../../../../components/MoveToInCommunication/RejectedMoveToInCommunication';
import RenderBasedOnApplicationStatusRejected from '../../../../../components/MoveToInCommunication/RenderBasedOnApplicationStatusRejected';
import { useGetApplicationWithChannelAndMetadata } from '../../../graphql';
import { useEmpRespondSideEffect } from '../../../hooks';
import ChannelHeaderMenuButton from '../ChannelHeaderMenuButton';
import { messages } from './messages';

const Content = styled.div`
  background-color: ${Neutral.B18};
  width: 318px;

  .description {
    margin-top: ${space8};
  }
  .cta-button {
    margin-top: ${space16};
  }

  &[data-has-cta='true'] {
    width: unset;
  }
`;

const Button = styled(ChannelHeaderMenuButton)`
  svg {
    fill: ${WhatsAppGreen};
  }
`;

const DisabledWhatsAppButton = (props: ButtonProps) => (
  <Button
    {...props}
    icon={<Icon name="ri-whatsapp-fill" className="whatsapp-icon" />}
    iconPosition="left"
    disabled={true}
    data-cy="exchange-whatsapp-number-btn"
  >
    <FormattedMessage {...messages.whatsAppCandidate} />
  </Button>
);
const WhatsAppChannelHeaderMenuButtonComponent = () => {
  const { data, loading } = useGetApplicationWithChannelAndMetadata();
  const application = data?.getApplicationById;

  const { whatsAppNumber, isAvailable: whatsappAvailable } =
    application?.whatsAppDetails || {
      whatsAppNumber: null,
      isAvailable: false,
    };

  const { formatMessage } = useIntl();

  const [popoverActive, setPopoverActive] = useState(false);
  const empRespondSideEffect = useEmpRespondSideEffect();
  const { buildWhatsAppMessageAndRedirect } =
    useBuildWhatsAppMessageAndRedirect();

  const openChatWindow = () => {
    if (!application) {
      console.error(`No application found!`);
      return;
    }
    buildWhatsAppMessageAndRedirect({
      applicant: { ugcFullName: application.applicant.ugcFullName || '' },
      whatsAppDetails: application.whatsAppDetails,
      applicationDetails: application,
      job: application.job,
      source: WhatsAppContactPopoverSources.CHAT,
    });
  };

  const moveToInCommunication = async () => {
    await empRespondSideEffect();
    if (application) {
      trackWAPopoverInCommunicationClickedEvent({
        applicationId: application.id,
        jobId: application.job.id,
        source: WhatsAppContactPopoverSources.CHAT,
      });
    }
    setPopoverActive(false);
  };

  const getPopoverTitleAndDescription = () => {
    if (whatsappAvailable && !whatsAppNumber) {
      return {
        title: formatMessage(messages.whatsApp),
        description: formatMessage(messages.toContactMoveOrChat),
      };
    }

    return {
      title: formatMessage(messages.noWhatsAppNumber),
      description: formatMessage(messages.whatsAppNotIncluded),
    };
  };

  const handleMouseEnter = () => {
    if (whatsAppNumber) {
      return;
    }
    setPopoverActive(true);
  };

  const disableButton = !whatsAppNumber;

  const activator = (
    <div onMouseEnter={handleMouseEnter}>
      <Button
        icon={<Icon name="ri-whatsapp-fill" className="whatsapp-icon" />}
        iconPosition="left"
        disabled={disableButton}
        onClick={openChatWindow}
        loading={loading}
        data-cy="exchange-whatsapp-number-btn"
      >
        {formatMessage(messages.whatsAppCandidate)}
      </Button>
    </div>
  );

  const { title, description } = getPopoverTitleAndDescription();

  if (!application) return null;

  return (
    <RenderBasedOnApplicationAccessLevel
      applicationDetails={{
        id: application.id,
        status: application.status,
        employerMetadata: application.accessLevel
          ? {
              accessLevel: application.accessLevel,
            }
          : undefined,
      }}
      vipCTAClickedTrackingLocation="skip"
      jobId={application.job.id}
      renderVipAccessContent={() => <DisabledWhatsAppButton />}
    >
      <div onMouseLeave={() => setPopoverActive(false)}>
        <Popover
          active={popoverActive}
          activator={activator}
          onClose={() => setPopoverActive(false)}
          preferredAlignment="left"
          zIndexOverride={1000}
        >
          <Popover.Pane fixed={true}>
            <Content data-has-cta={whatsappAvailable}>
              <Popover.Section>
                <RenderBasedOnApplicationStatusRejected
                  applicationStatus={application.status}
                  renderRestrictedComponent={() => (
                    <Choose>
                      <When condition={whatsappAvailable}>
                        <Typography variant={'caption'} color={Neutral.B100}>
                          <RejectedMoveToInCommunication.Title variant="whatsapp" />
                        </Typography>
                        <Typography
                          className="description"
                          variant={'subtitle2'}
                          color={Neutral.B100}
                        >
                          <RejectedMoveToInCommunication.Subtitle variant="whatsapp" />
                        </Typography>
                      </When>
                      <Otherwise>
                        <Typography variant={'caption'} color={Neutral.B100}>
                          <FormattedMessage {...messages.noWhatsAppNumber} />
                        </Typography>
                      </Otherwise>
                    </Choose>
                  )}
                >
                  <Typography variant={'caption'} color={Neutral.B100}>
                    {title}
                  </Typography>
                  <Typography
                    className="description"
                    variant={'subtitle2'}
                    color={Neutral.B100}
                  >
                    {description}
                  </Typography>
                </RenderBasedOnApplicationStatusRejected>
                <If condition={whatsappAvailable}>
                  <PrimaryButton
                    fullWidth={true}
                    className="cta-button"
                    onClick={moveToInCommunication}
                    data-cy="move-to=communication-btn"
                  >
                    {formatMessage(messages.moveToInCommunication)}
                  </PrimaryButton>
                </If>
              </Popover.Section>
            </Content>
          </Popover.Pane>
        </Popover>
      </div>
    </RenderBasedOnApplicationAccessLevel>
  );
};

export const WhatsAppChannelHeaderMenuButton = () => (
  <Suspense fallback={<DisabledWhatsAppButton loading={true} />}>
    <WhatsAppChannelHeaderMenuButtonComponent />
  </Suspense>
);
