import React, { ReactNode, useState } from 'react';
import { Popover, Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space16 } from 'glints-aries/es/@next/utilities/spacing';
import { get } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import { getConfig } from '../../../../config';
import {
  ContentContainer,
  CustomPopoverStyles,
  HeaderContainer,
  StyledImage,
  StyledVIPBadge,
} from './VIPBenefitPopover.sc';

type CallbackWithPopoverActions<T> = (_: {
  openPopover: () => void;
  closePopover: () => void;
}) => T;
type VIPBenefitPopoverProps = {
  title: ReactNode;
  description: ReactNode;
  images: {
    id: string;
    en: string;
  };
  activator: CallbackWithPopoverActions<JSX.Element>;
  onMouseEnter?: CallbackWithPopoverActions<void>;
  onMouseLeave?: CallbackWithPopoverActions<void>;
};

const VIPBadge = () => (
  <StyledVIPBadge>
    <img
      width={space16}
      height={space16}
      src="/images/credit-system/vip-icon-sm.svg"
      alt="VIP Icon"
    />
    <Typography variant="overline" color={Neutral.B18}>
      <FormattedMessage id="text-glints-vip" defaultMessage="GLINTS VIP" />
    </Typography>
  </StyledVIPBadge>
);

export default function VIPBenefitPopover({
  description,
  title,
  activator,
  onMouseEnter,
  onMouseLeave,
  images,
}: VIPBenefitPopoverProps) {
  const [isPopoverActive, setIsPopoverActive] = useState(false);
  const [openPopover, closePopover] = [
    () => setIsPopoverActive(true),
    () => setIsPopoverActive(false),
  ];
  const [makeMouseEnterCallback, makeMouseLeaveCallback] = [
    () => onMouseEnter && onMouseEnter({ openPopover, closePopover }),
    () => onMouseLeave && onMouseLeave({ openPopover, closePopover }),
  ];
  const lang: string = getConfig().LANG || 'en';
  const image = get(images, lang.toLowerCase(), images.en);
  return (
    <div
      onMouseEnter={makeMouseEnterCallback}
      onMouseLeave={makeMouseLeaveCallback}
      style={{
        width: 'max-content',
      }}
    >
      <CustomPopoverStyles />
      <Popover
        active={isPopoverActive}
        activator={activator({ openPopover, closePopover })}
        autofocusTarget="first-node"
        onClose={closePopover}
        preferredAlignment={'left'}
        zIndexOverride={1001}
        preferredPosition="mostSpace"
      >
        <Popover.Pane>
          <ContentContainer>
            <VIPBadge />
            <HeaderContainer>
              <Typography variant="subtitle1">{title}</Typography>
              <Typography variant="body1">{description}</Typography>
            </HeaderContainer>
            <a
              href={image}
              target="_blank"
              style={{
                display: 'contents',
              }}
              rel="noreferrer"
            >
              <StyledImage src={image} />
            </a>
          </ContentContainer>
        </Popover.Pane>
      </Popover>
    </div>
  );
}
