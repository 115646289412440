import React from 'react';
import { Flex } from 'glints-aries';
import {
  Banner,
  Button,
  PrimaryButton,
  Typography,
} from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';
import { useChannelStateContext, useMessageContext } from 'stream-chat-react';

import {
  ApplicationChatCVExchangeRequestStatus,
  useMessagingChannelContext,
} from '../../constants';
import { useUpdateShareResumeRequest } from '../../graphql';
import { getChannelApplicationMetadata } from '../../helper';
import { useRebuildComponentOnChannelEvent } from '../../hooks';
import { CVRequestAction } from '../../types';
import ResponsiveButtonGroup from '../ResponsiveButtonGroup';
import { CardContainer } from '../Styled';
import CandidateCV from './CandidateCV';
import CVExchangeStatusSystemMessage from './CVExchangeStatusSystemMessage';

type Props = {
  metadata: CVRequestAction;
};

const CddCVRequestMessageContent = ({ metadata }: Props) => {
  useRebuildComponentOnChannelEvent('channel.updated');
  const { channel } = useChannelStateContext();
  const { message } = useMessageContext();
  const { uiMode } = useMessagingChannelContext();
  const [updateShareResumeRequest, updateShareResumeRequestResult] =
    useUpdateShareResumeRequest();
  const resume = message.attachments?.find(item => item.type === 'file');
  const resumeFileName = resume?.file_name as string;
  const channelMetadata = getChannelApplicationMetadata(channel);
  const isLatestRequest = message.id === channelMetadata?.cv_request_msg_id;

  const showBanner =
    isLatestRequest &&
    !metadata.action_payload.hidden &&
    [
      ApplicationChatCVExchangeRequestStatus.PENDING,
      ApplicationChatCVExchangeRequestStatus.ACCEPTED,
    ].includes(metadata.action_payload.status);

  return (
    <>
      <If
        condition={
          metadata.action_payload.status !==
          ApplicationChatCVExchangeRequestStatus.PENDING
        }
      >
        <CVExchangeStatusSystemMessage metadata={metadata} />
      </If>
      <If condition={showBanner}>
        <CardContainer>
          <Banner
            dismissable={false}
            status="info"
            iconName="ri-information-line"
          >
            <Flex flexDirection="column" style={{ gap: 8 }}>
              <Choose>
                <When
                  condition={
                    metadata.action_payload.status ===
                    ApplicationChatCVExchangeRequestStatus.PENDING
                  }
                >
                  <Typography variant="body1">{resumeFileName}</Typography>
                  <ResponsiveButtonGroup>
                    <PrimaryButton
                      disabled={updateShareResumeRequestResult.loading}
                      onClick={() =>
                        updateShareResumeRequest({
                          variables: {
                            messageId: message.id,
                            input: {
                              status:
                                ApplicationChatCVExchangeRequestStatus.ACCEPTED,
                              telemetryEventProps: {
                                ui_type: uiMode,
                              },
                            },
                          },
                        })
                      }
                    >
                      <Typography variant="button">
                        <FormattedMessage
                          id="button-text_accept"
                          defaultMessage="Accept"
                        />
                      </Typography>
                    </PrimaryButton>
                    <Button
                      disabled={updateShareResumeRequestResult.loading}
                      onClick={() =>
                        updateShareResumeRequest({
                          variables: {
                            messageId: message.id,
                            input: {
                              status:
                                ApplicationChatCVExchangeRequestStatus.REJECTED,
                              telemetryEventProps: {
                                ui_type: uiMode,
                              },
                            },
                          },
                        })
                      }
                    >
                      <Typography variant="button">
                        <FormattedMessage
                          id="interactive-reject"
                          defaultMessage="Reject"
                        />
                      </Typography>
                    </Button>
                  </ResponsiveButtonGroup>
                </When>
                <When
                  condition={
                    metadata.action_payload.status ===
                    ApplicationChatCVExchangeRequestStatus.ACCEPTED
                  }
                >
                  <CandidateCV />
                </When>
              </Choose>
            </Flex>
          </Banner>
        </CardContainer>
      </If>
    </>
  );
};

export default CddCVRequestMessageContent;
