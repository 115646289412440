import React from 'react';
import PropTypes from 'prop-types';

import { getRandomFallbackJobBannerPic } from '../../common/asset-manager';
import GlintsPicture from './GlintsPicture';

const OpportunityBannerPicture = ({
  opportunityID,
  bannerPic,
  ...otherProps
}) => {
  if (bannerPic) {
    return (
      <GlintsPicture
        name={bannerPic}
        assetType="job-banner-pic"
        fallback={getRandomFallbackJobBannerPic(opportunityID)}
        fallbackAssetType="job-default-banner-pic"
        {...otherProps}
      />
    );
  } else {
    return (
      <GlintsPicture
        name={getRandomFallbackJobBannerPic(opportunityID)}
        assetType="job-default-banner-pic"
        {...otherProps}
      />
    );
  }
};

OpportunityBannerPicture.propTypes = {
  opportunityID: PropTypes.any.isRequired,
  bannerPic: PropTypes.string,
};

export default OpportunityBannerPicture;
