import React from 'react';
import { Box, Flex, Greyscale, SecondaryColor } from 'glints-aries';
import { Typography } from 'glints-aries/es/@next';
import { Blue, Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space8 } from 'glints-aries/es/@next/utilities/spacing';
import { first } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { Attachment as AttachmentType } from 'stream-chat';
import {
  Attachment,
  MessageTimestamp,
  useChannelStateContext,
  useMessageContext,
} from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import styled from 'styled-components';

import useIsOSSEnabled from '../../../common/hooks/useIsOSSEnabled';
import NodesJoin from '../../../components/NodesJoin';
import { convertS3ToOSSURL, getChannelApplicationMetadataV2 } from '../helper';
import {
  useFormatStreamMessage,
  useIsMobileVersion,
  useIsPopupMode,
} from '../hooks';
import AvatarWithOSS from './AvatarWithOSS';
import FileAttachment from './FileAttachment';
import ImageAttachment from './ImageAttachment';
import MessageActions from './MessageActions/MessageActions';
import MessageStatus from './MessageStatus';
import MiddleDot from './MiddleDot';

const processAttachment = (
  attachment: AttachmentType<DefaultStreamChatGenerics>
) => ({
  ...attachment,
  // eslint-disable-next-line camelcase
  image_url: convertS3ToOSSURL(attachment.image_url),
  asset_url: convertS3ToOSSURL(attachment.asset_url),
  thumb_url: convertS3ToOSSURL(attachment.thumb_url),
  attachmentAssetUrl: convertS3ToOSSURL(attachment.attachmentAssetUrl),
});

const TextMessage = () => {
  const { channel } = useChannelStateContext();
  const { groupStyles, message, isMyMessage } = useMessageContext();
  const isPopupMode = useIsPopupMode();
  const isMobileVersion = useIsMobileVersion();
  const formatStreamMessage = useFormatStreamMessage();
  const channelMetadata = getChannelApplicationMetadataV2(channel);
  const isOSSEnabled = useIsOSSEnabled();
  const isMessageDeleted = Boolean(message.deleted_at);
  const isEmpMessage =
    message.user?.id !== channelMetadata.glints_application_applicant_id;
  const showMetadata = groupStyles?.find(style =>
    ['single', 'bottom'].includes(style)
  );
  const messageText = formatStreamMessage(message);

  const attachments =
    message.attachments?.filter(
      attachment => !attachment.title_link && !attachment.og_scrape_url
    ) || [];

  const processedAttachments = isOSSEnabled
    ? attachments.map(processAttachment)
    : attachments;

  return (
    <>
      <MessageContainer
        isEmpMessage={isEmpMessage}
        className={`messaging-chat-channel-text-message-${first(groupStyles)}`}
      >
        <MessageWrapper
          isEmpMessage={isEmpMessage}
          isMobileVersion={isMobileVersion}
          isPopupMode={isPopupMode}
        >
          <AvatarWithOSS
            image={message.user?.image}
            name={message.user?.name}
            shape="circle"
          />
          <Choose>
            <When condition={isMessageDeleted}>
              <DeletedMessageBubble isEmpMessage={isEmpMessage}>
                <MessageTypography isEmpMessage={isEmpMessage} variant="body1">
                  <FormattedMessage
                    id="text-delete-message"
                    defaultMessage="Deleted message"
                  />
                </MessageTypography>
              </DeletedMessageBubble>
            </When>
            <Otherwise>
              <Flex
                flexDirection={isEmpMessage ? 'row-reverse' : 'row'}
                style={{ gap: space8 }}
              >
                {messageText && (
                  <MessageBubble isEmpMessage={isEmpMessage}>
                    <MessageTypography
                      isEmpMessage={isEmpMessage}
                      variant="body1"
                    >
                      {messageText}
                    </MessageTypography>
                  </MessageBubble>
                )}
                {Boolean(processedAttachments.length) && (
                  <Attachment
                    attachments={processedAttachments}
                    File={FileAttachment}
                    Image={ImageAttachment}
                  />
                )}
                <MessageActionsContainer>
                  <MessageActions />
                </MessageActionsContainer>
              </Flex>
            </Otherwise>
          </Choose>
        </MessageWrapper>

        {showMetadata && (
          <Box px={48}>
            <Typography variant="overline" color={Greyscale.devilsgrey}>
              <NodesJoin separator={<MiddleDot />}>
                <If condition={isEmpMessage}>{message.user?.name}</If>
                <span>
                  <MessageTimestamp format="HH:mm" />
                  <Box ml={4} style={{ display: 'inline' }}>
                    <MessageStatus />
                  </Box>
                </span>
              </NodesJoin>
            </Typography>
          </Box>
        )}
      </MessageContainer>
      <If condition={message.is_auto_reply && isMyMessage()}>
        <Flex p={16} justifyContent="center">
          <Typography
            variant="subtitle2"
            color={Greyscale.devilsgrey}
            style={{ textAlign: 'center' }}
          >
            <FormattedMessage
              id="text-chat-log-auto-reply-no-supported"
              defaultMessage="This is an auto-reply message, which is no longer supported.{br}Please manually respond to the candidate."
              values={{
                br: <br />,
              }}
            />
          </Typography>
        </Flex>
      </If>
    </>
  );
};

type MessageProps = {
  isEmpMessage: boolean;
  isMobileVersion?: boolean;
  isPopupMode?: boolean;
};

const MessageContainer = styled.div<MessageProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: ${space8};

  ${({ isEmpMessage }) => ({
    alignItems: isEmpMessage ? 'flex-end' : 'flex-start',
  })}
`;

const MessageActionsContainer = styled.div`
  visibility: hidden;
`;

const MessageWrapper = styled.div<MessageProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: ${space8};
  word-break: break-word;

  ${({ isEmpMessage, isMobileVersion, isPopupMode }) => ({
    flexDirection: isEmpMessage ? 'row-reverse' : 'row',
    maxWidth: isPopupMode ? '100%' : isMobileVersion ? '70%' : '50%',
  })}

  &:hover {
    ${MessageActionsContainer} {
      visibility: visible;
    }
  }
`;

const MessageBubble = styled.div<MessageProps>`
  padding: 16px;
  border-radius: 8px;

  ${({ isEmpMessage }) => ({
    color: isEmpMessage ? Greyscale.white : Greyscale.black,
    backgroundColor: isEmpMessage ? SecondaryColor.actionblue : Greyscale.white,
    border: isEmpMessage ? undefined : `1px solid #e9e9e9`,
  })}
`;

const DeletedMessageBubble = styled(MessageBubble)<MessageProps>`
  padding: 16px;
  border-radius: 8px;
  border: none;

  ${({ isEmpMessage }) => ({
    color: isEmpMessage ? Neutral.B85 : Greyscale.devilsgrey,
    backgroundColor: isEmpMessage ? SecondaryColor.actionblue : Neutral.B95,
  })}
`;

const MessageTypography = styled(Typography)<MessageProps>`
  white-space: pre-wrap;

  ${({ isEmpMessage }) => ({
    '::selection': {
      backgroundColor: isEmpMessage ? Blue.S100 : SecondaryColor.actionblue,
    },
  })}
`;

export default TextMessage;
