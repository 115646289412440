import * as Sentry from '@sentry/react';

import { Actions as LoginActions } from '../modules/Login/Actions';
import { serverErrorMessages } from '../modules/Login/Constants';
import { Actions as SignUpActions } from '../modules/SignUp/Actions';

const ignoredActions = [
  '@@redux-form/SET_SUBMIT_FAILED', // validation failed and shown to users
];

const metadataSection = {
  user: 'user',
  custom: 'custom',
};

const isErrorEventToBeReported = action => {
  if (!action.error) {
    return false;
  }

  if (ignoredActions.includes(action.type)) {
    return false;
  }

  if (
    action.type === LoginActions.LOGIN_FAILURE ||
    action.type === SignUpActions.CREATE_USER_FAILURE
  ) {
    const response = action.payload?.response;
    if (
      response?.data?.error === serverErrorMessages.MFA_REQUIRED ||
      response?.status === 401 ||
      response?.status === 409
    ) {
      // Ignore errors from password login failure
      // and prompt for MFA
      // and SignUp Email Conflict.
      return false;
    }
  }

  return true;
};

export default store => next => async action => {
  const isValidErrorEvent = isErrorEventToBeReported(action);
  const userId = store.getState().user.me.value;
  const me = store.getState().entities.user[userId];

  if (me) {
    Sentry.setUser({
      id: me.id,
      email: me.email,
      name: `${me.firstName} ${me.lastName}`.trim(),
    });
    Sentry.setContext(metadataSection.user, {
      firstName: me.firstName,
      lastName: me.lastName,
      isVerified: me.isVerified,
      lastSeen: me.lastSeen,
      role: me.role,
    });
  } else {
    Sentry.setUser(null);
  }

  if (isValidErrorEvent) {
    const error =
      typeof action.error === 'boolean' ? action.payload : action.error;

    const metadata = {
      reduxActionType: action.type,
    };

    if (error.isAxiosError) {
      if (error.response) {
        metadata.axiosResponseData = error.response.data;
        metadata.axiosResponseStatus = error.response.status;
      }
      if (error.config) {
        metadata.axiosRequestBaseURL = error.config.baseURL;
        metadata.axiosRequestURL = error.config.url;
        metadata.axiosRequestMethod = error.config.method;
      }
    }

    Sentry.captureException(error, scope => {
      scope.setTag('groupingHash', action.type + error?.message);
      scope.setExtra(metadataSection.custom, metadata);
    });
  }

  next(action);
};
