import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import baseStyles from '../../styles/base.scss';

const ExperienceRange = ({ experience, isInline = true }) => (
  <span className={baseStyles.colorGray}>
    <If condition={(experience.startDate || experience.endDate) && isInline}>
      (
    </If>
    <If condition={experience.startDate && experience.type !== 'AWARD'}>
      {dayjs
        .utc(experience.startDate)
        .format(isInline ? "MMM'YY" : 'MMMM YYYY')}
      &nbsp;-&nbsp;
    </If>
    <If condition={experience.endDate || experience.isPresent}>
      <Choose>
        <When condition={experience.type === 'AWARD'}>
          {dayjs.utc(experience.endDate).format(isInline ? "'YY" : 'YYYY')}
        </When>
        <When condition={experience.isPresent}>
          <FormattedMessage
            id="components.ExperienceRange.ExperienceRange.present"
            defaultMessage="Present"
          />
          <If
            condition={
              !isInline &&
              dayjs.utc(experience.startDate).diff(dayjs().utc(), 'month')
            }
          >
            &nbsp;({dayjs.utc(experience.startDate).fromNow(true)})
          </If>
        </When>
        <Otherwise>
          {dayjs
            .utc(experience.endDate)
            .format(isInline ? "MMM'YY" : 'MMMM YYYY')}
          <If
            condition={
              !isInline &&
              dayjs
                .utc(experience.startDate)
                .diff(dayjs.utc(experience.endDate), 'month')
            }
          >
            &nbsp;(
            {dayjs
              .utc(experience.startDate)
              .from(dayjs.utc(experience.endDate), true)}
            )
          </If>
        </Otherwise>
      </Choose>
    </If>
    <If condition={!experience.endDate && !experience.isPresent}>
      <FormattedMessage
        id="components.ExperienceRange.ExperienceRange.unknown"
        defaultMessage="Unknown"
      />
    </If>
    <If condition={(experience.startDate || experience.endDate) && isInline}>
      )
    </If>
  </span>
);

ExperienceRange.propTypes = {
  experience: PropTypes.object,
  isInline: PropTypes.bool,
};

export default ExperienceRange;
