import React from 'react';
import { ImageComponent, ImageProps } from 'stream-chat-react';
import styled from 'styled-components';

import FileUploadSpinner from './FileUploadSpinner';

const Styled = {
  Container: styled.div`
    position: relative;
    max-height: inherit;
    height: inherit;
  `,
  LoadingOverlay: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.5);
  `,
  SpinnerContainer: styled.div`
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 8px);
  `,
};

type ImageAttachmentProps = ImageProps & {
  asset_url?: string;
  loading?: boolean;
};

const ImageAttachment = (props: ImageAttachmentProps) => (
  <Styled.Container>
    <ImageComponent {...props} image_url={props.image_url ?? props.asset_url} />
    <If condition={props.loading}>
      <Styled.LoadingOverlay>
        <Styled.SpinnerContainer>
          <FileUploadSpinner />
        </Styled.SpinnerContainer>
      </Styled.LoadingOverlay>
    </If>
  </Styled.Container>
);

export default ImageAttachment;
