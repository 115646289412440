import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useLocalStorage, useMount } from 'react-use';

import { useGetSessionCompany } from '../../common/hooks/useGetSessionCompany';
import { TrackingService } from '../../common/tracking/trackingService';
import { User } from '../../models/User';
import { getUser } from '../../selectors/user';

type TrackMobileAppPromptViewedProps = {
  sessionCompany: Pick<User, 'id'> | undefined;
  user: Pick<User, 'id' | 'role'> | undefined;
  pathname: string;
  firstViewedDate: string | undefined;
  setFirstViewedDate: (value: string) => void;
};

const TrackMobileAppPromptViewed = ({
  firstViewedDate,
  pathname,
  sessionCompany,
  user,
  setFirstViewedDate,
}: TrackMobileAppPromptViewedProps) => {
  useMount(() => {
    TrackingService.logEvent('emp_app_sheet_viewed', {
      page_path: pathname,
      company_id: sessionCompany?.id || 'no_company',
      role: user?.role,
      user_id_value: user?.id,
      is_first_viewed: firstViewedDate === undefined,
    });
    setFirstViewedDate(dayjs().toISOString());
  });

  return null;
};

export default function useMobileAppPromptTracking() {
  const { sessionCompany } = useGetSessionCompany();
  const user: Pick<User, 'id' | 'role'> | undefined = useSelector(getUser);
  const { pathname } = useLocation();
  const [firstViewedDate, setFirstViewedDate] = useLocalStorage<string>(
    'mobileAppPromptViewedDate',
    undefined
  );

  const trackOpenAppClicked = () => {
    TrackingService.logEvent('emp_app_sheet_clicked', {
      page_path: pathname,
      company_id: sessionCompany?.id || 'no_company',
      role: user?.role,
      user_id_value: user?.id,
    });
  };

  const trackMobileAppPromptViewedElement = useMemo(
    () => (
      <TrackMobileAppPromptViewed
        firstViewedDate={firstViewedDate}
        pathname={pathname}
        sessionCompany={sessionCompany}
        user={user}
        setFirstViewedDate={setFirstViewedDate}
      />
    ),
    [firstViewedDate, pathname, sessionCompany, setFirstViewedDate, user]
  );

  return {
    trackOpenAppClicked,
    trackMobileAppPromptViewedElement,
  } as const;
}
