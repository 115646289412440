import { includes, values } from 'lodash-es';
import { QueryParamConfig, StringParam } from 'use-query-params';

import { FilterApplicationSource } from './constants';

export const buildFilterApplicationSourceQueryParam = ({
  isRecommendedTalentEnabled,
}: {
  isRecommendedTalentEnabled: boolean;
}): QueryParamConfig<
  FilterApplicationSource | null | undefined,
  FilterApplicationSource
> => ({
  encode: StringParam.encode,
  decode: value => {
    const appSource = StringParam.decode(value);
    const isRecommendedTalentDisabledButSelected =
      appSource === FilterApplicationSource.recommendedTalent &&
      !isRecommendedTalentEnabled;

    if (
      !appSource ||
      !includes(values(FilterApplicationSource), appSource) ||
      isRecommendedTalentDisabledButSelected
    ) {
      return FilterApplicationSource.allCandidates;
    }

    return appSource as FilterApplicationSource;
  },
});
