import { useSelector } from 'react-redux';

import config from '../../config';
import { INDONESIA_COUNTRY } from '../../modules/JobV2/common/constants';
import { UnleashFeatureNames } from '../../modules/Unleash/featureNames';
import { getIsFeatureEnabled } from '../../selectors/features';
import { useGetSessionCompany } from './useGetSessionCompany';

const useIsWhatsAppIntegrationEnabled = () => {
  const isWhatsIntegrationEnabled = useSelector(state =>
    getIsFeatureEnabled(state, UnleashFeatureNames.empWhatsappIntegration)
  );

  const { sessionCompany } = useGetSessionCompany();
  const { COUNTRY } = config;

  return (
    isWhatsIntegrationEnabled &&
    sessionCompany?.CountryCode === INDONESIA_COUNTRY.code &&
    // used for consistency with the existing implementations as they use the
    // country code from the app config rather than the company country code
    COUNTRY === INDONESIA_COUNTRY.code.toLowerCase()
  );
};

export default useIsWhatsAppIntegrationEnabled;
