import { handleActions } from 'redux-actions';

import { Actions } from '../../actions/user/me';

/**
 * Reducer for the me endpoint.
 */

const initialState = {
  loading: false,
  error: false,
  success: false,
  value: null,
};

export default handleActions(
  {
    [Actions.FETCH_ME_REQUEST]: state => ({
      ...state,
      loading: true,
    }),
    [Actions.FETCH_ME_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      value: action.response.result,
    }),
    [Actions.FETCH_ME_FAILURE]: (state, action) => ({
      ...state,
      error: action.error.response,
    }),
    [Actions.PERSIST_ME_REQUEST]: state => ({
      ...state,
      loading: true,
      error: null,
      success: false,
    }),
    [Actions.PERSIST_ME_SUCCESS]: state => ({
      ...state,
      loading: false,
      error: null,
      success: true,
    }),
    [Actions.PERSIST_ME_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      error: action.error.response,
    }),
  },
  initialState
);
