import { createAction } from 'redux-actions';

export const UPDATE_METADATA = 'glints/meta/UPDATE_METADATA';
export const SET_NO_INDEX = 'glints/meta/SET_NO_INDEX';
export const UNSET_NO_INDEX = 'glints/meta/UNSET_NO_INDEX';

export function updateMetadata(
  title?: string,
  description?: string,
  image?: string,
  prerenderStatusCode?: number,
  canonical?: string,
  siteName?: string,
  skipTwitterSite?: boolean
) {
  return {
    type: UPDATE_METADATA,
    payload: {
      title,
      description,
      image,
      prerenderStatusCode,
      canonical,
      siteName,
      skipTwitterSite,
    },
  };
}

export const setNoIndex = createAction(SET_NO_INDEX);
export const unsetNoIndex = createAction(UNSET_NO_INDEX);
