import React from 'react';
import { Modal, Typography } from 'glints-aries/es/@next';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  browseFiles: {
    id: 'interactive-browse-file',
    defaultMessage: 'Browse files',
  },
  cancel: {
    id: 'interactive-cancel',
    defaultMessage: 'Cancel',
  },
});

type FileUploadErrorModalProps = {
  title: string;
  description: string;
  onBrowseFiles: () => void;
  onClose: () => void;
};

const FileUploadErrorModal = ({
  title,
  description,
  onBrowseFiles,
  onClose,
}: FileUploadErrorModalProps) => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      isOpen={true}
      header={title}
      showCloseButton={true}
      primaryAction={{
        label: formatMessage(messages.browseFiles),
        action: onBrowseFiles,
      }}
      secondaryAction={{
        label: formatMessage(messages.cancel),
        action: onClose,
      }}
      onClose={onClose}
    >
      <Typography variant="body1">{description}</Typography>
    </Modal>
  );
};
export default FileUploadErrorModal;
