import { createSelector } from 'reselect';

import { getIdentities } from '../../selectors/entities';
import { getUserNotifications } from '../../selectors/user';
import { NOTIFICATION_CATEGORIES } from './Constants';

export const getActiveTab = state => state?.settings?.activeTab || {};

export const getProfilePicS3Data = state =>
  state?.settings?.basicInfo.profilePic.s3Data;
export const getProfilePicValue = state =>
  state?.settings?.basicInfo.profilePic.value;
export const getProfilePicLoading = state =>
  state?.settings?.basicInfo.profilePic.loading;
export const getProfilePicError = state =>
  state?.settings?.basicInfo.profilePic.error;
export const getProfilePicSuccess = state =>
  state?.settings?.basicInfo.profilePic.success;

export const getBasicInfoUpdateStates = state =>
  state?.settings?.basicInfo.update;

export const getUpdateEmailLoading = state =>
  state?.settings?.updateEmail.loading;
export const getUpdateEmailSuccess = state =>
  state?.settings?.updateEmail.success;
export const getUpdateEmailError = state => state?.settings?.updateEmail.error;

export const getUpdatePasswordLoading = state =>
  state?.settings?.updatePassword.loading;
export const getUpdatePasswordSuccess = state =>
  state?.settings?.updatePassword.success;
export const getUpdatePasswordError = state =>
  state?.settings?.updatePassword.error;

export const getIdentityIds = state => state.settings.identities.value;
export const getCurrentIdentities = createSelector(
  [getIdentityIds, getIdentities],
  (ids, identities) => ids.map(id => identities[id])
);

export const getIdentitiesLoading = state =>
  state?.settings?.identities.loading;
export const getIdentitiesSuccess = state =>
  state?.settings?.identities.success;
export const getIdentitiesError = state => state?.settings?.identities.error;

export const getFacebookIsOpen = state =>
  state?.settings?.identities.facebook.isOpen;
export const getFacebookLoading = state =>
  state?.settings?.identities.facebook.loading;
export const getFacebookSuccess = state =>
  state?.settings?.identities.facebook.succcess;
export const getFacebookError = state =>
  state?.settings?.identities.facebook.error;

export const getLinkedInCsrfToken = state =>
  state?.settings?.identities.linkedIn.csrfToken;
export const getLinkedInIsOpen = state =>
  state?.settings?.identities.linkedIn.isOpen;
export const getLinkedInLoading = state =>
  state?.settings?.identities.linkedIn.loading;
export const getLinkedInSuccess = state =>
  state?.settings?.identities.linkedIn.succcess;
export const getLinkedInError = state =>
  state?.settings?.identities.linkedIn.error;

export const getUnlinkError = state => state?.settings?.identities.unlink.error;
export const getUnlinkId = state => state?.settings?.identities.unlink.id;

export const getInitialValuesForNotifications = state => {
  const userNotifications = getUserNotifications(state);
  const categoryKeys = Object.keys(NOTIFICATION_CATEGORIES);
  const initialValues = {};

  categoryKeys.forEach(categoryKey => {
    const channelKeys = Object.keys(
      NOTIFICATION_CATEGORIES[categoryKey]?.channels
    );
    if (channelKeys.length) {
      channelKeys.forEach(channelKey => {
        const fieldKey = `${channelKey}-${categoryKey}`;
        initialValues[fieldKey] =
          userNotifications?.[channelKey]?.[categoryKey] ?? true;
      });
    }
  });

  return initialValues;
};

export const getNotificationStates = state => state.settings.notifications;
