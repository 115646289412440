import styled from 'styled-components';

export const Role = styled.div`
  justify-content: center;
  align-items: stretch;
  border-radius: var(--space-small-8, 8px);
  border: 1px solid #a6cbdc;
  background-color: #fff;
  display: flex;
  padding: 16px;
  flex-direction: column;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;

  color: var(--Text-text_supplementary, #666);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family:
    Noto Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;

  :hover {
    background: #fff;
    border: 1px solid #1f2c3a !important;
    box-shadow: 0 0 0 2px #1f2c3a !important;
    color: var(--Text-text_normal, #2d2d2d) !important;
  }
  @media (min-width: 640px) {
    font-size: 16px;
    white-space: unset;
    min-height: 86px;
  }
  @media (min-width: 992px) {
    padding: 16px 40px;
  }
`;

export const RolesContainer = styled.div`
  justify-content: space-between;
  align-items: stretch;
  align-self: stretch;
  border-radius: var(--space-small-0, 0px);
  display: flex;
  gap: 20px;
  overflow: scroll;
  padding: 0 2px;
  @media (min-width: 640px) {
    overflow: unset;
    padding: 0;
  }
`;

export const RoleWithArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  @media (min-width: 640px) {
    flex-grow: 1;
    flex-basis: 0;
  }
`;

export const Icon = styled.img`
  margin-top: 4px;
  width: 24px;
  height: 16px;
  align-self: center;
`;

export const AdditionalText = styled.span`
  color: var(--Text-text_normal_onDark, #fff);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family:
    Noto Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 220%;
  white-space: nowrap;
  @media (min-width: 992px) {
    font-size: 24px;
  }
`;

export const DescriptionContainer = styled.span`
  border-radius: 20px;
  margin-top: 16px;
  padding: 32px;
  background: var(
    --talenthub-dark-gradient-card,
    radial-gradient(
      149.36% 112% at 92.48% 6.67%,
      #182644 6.41%,
      #4f4f4f 26.15%,
      #011744 85.94%
    )
  );
  flex-grow: 1;
  @media (min-width: 640px) {
    padding: 32px 60px;
  }
`;

export const List = styled.span`
  color: var(--Text-text_normal_onDark, #fff);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family:
    Noto Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 220%;
  @media (min-width: 992px) {
    font-size: 24px;
    font-weight: 600;
  }
`;

export const Position = styled.span`
  display: inline-block;
  padding-right: 16px;
`;
