import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { User } from '../../models/User';
import { getUser } from '../../selectors/user';
import ProfilePicture from './ProfilePicture';

const StarVariant = {
  foreground: 'foreground',
  background: 'background',
} as const;

type StarVariant = (typeof StarVariant)[keyof typeof StarVariant];

type SizeProps = {
  profilePictureSize: number;
  vipLogoWidth: number;
  vipLogoOffset: number;
  hasStarsBackground?: boolean;
  starVariant?: StarVariant;
  foregroundStarStyle?: React.CSSProperties;
};

const ProfileContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Border = styled.div`
  padding: 2px;
  background-image: linear-gradient(180deg, #ffcf2c, #ffa81c);
  border-radius: 50%;
`;

const StyledVIPProfilePicture = styled(ProfilePicture)<
  Pick<SizeProps, 'profilePictureSize'>
>`
  width: ${props => props.profilePictureSize}px;
  height: ${props => props.profilePictureSize}px;
  border-radius: 50%;
`;

const VIPLogo = styled.img<Pick<SizeProps, 'vipLogoOffset' | 'vipLogoWidth'>>`
  margin-top: -${props => props.vipLogoOffset}px;
  width: ${props => props.vipLogoWidth}px;
`;

const StarsBackground = styled.div<Pick<SizeProps, 'profilePictureSize'>>`
  background-image: url('/images/credit-system/vip-profile-picture/stars-background.svg');
  padding: 0px calc(0.28 * ${props => props.profilePictureSize}px);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
`;

const ConditionalStarsBackground = ({
  children,
  condition,
  profilePictureSize,
}: {
  children: ReactNode;
  condition: boolean;
  profilePictureSize: SizeProps['profilePictureSize'];
}) => {
  if (!condition) {
    return <>{children}</>;
  }

  return (
    <StarsBackground profilePictureSize={profilePictureSize}>
      {children}
    </StarsBackground>
  );
};

export default function VIPProfilePicture({
  profilePictureSize,
  vipLogoWidth,
  vipLogoOffset,
  hasStarsBackground = false,
  starVariant = 'background',
  foregroundStarStyle,
}: SizeProps) {
  const me: User | undefined = useSelector(getUser);

  if (!me) {
    throw new Error('User need to be defined in VIPProfilePicture component');
  }

  return (
    <ProfileContainer>
      <ConditionalStarsBackground
        condition={hasStarsBackground && starVariant === 'background'}
        profilePictureSize={profilePictureSize}
      >
        {hasStarsBackground && starVariant === 'foreground' && (
          <img
            src="/images/vip-avatar-stars.svg"
            style={{
              position: 'absolute',
              zIndex: 1,
              ...foregroundStarStyle,
            }}
          />
        )}
        <Border>
          <StyledVIPProfilePicture
            userId={me.id}
            profilePic={me.profilePic}
            alt="Profile Picture"
            profilePictureSize={profilePictureSize}
          />
        </Border>
      </ConditionalStarsBackground>
      <VIPLogo
        src="/images/credit-system/glints-vip-icon.svg"
        alt="Glints VIP"
        vipLogoOffset={vipLogoOffset}
        vipLogoWidth={vipLogoWidth}
      />
    </ProfileContainer>
  );
}
