import React from 'react';
import { Dropdown, Menu } from 'antd';
import { ArrowDownIcon, Button, Flex } from 'glints-aries';
import { ButtonGroup } from 'glints-aries/es/@next';
import { space8 } from 'glints-aries/es/@next/utilities/spacing';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { COUNTRY_DOMAINS } from '../../common/constants';
import { countryCodeIsVietnam } from '../../common/helpers/countryCodeHelper';
import useGetRefCodeParam from '../../common/hooks/useGetRefCodeParam';
import { Company } from '../../common/interfaces/entities';
import config from '../../config';
import {
  getAreFeaturesFetched,
  getFeatures,
  getIsFeatureEnabled,
} from '../../selectors/features';
import { getIsLoggedIn } from '../../selectors/session';
import AppLanguageSwitcher from '../AppLanguage/AppLanguageSwitcher';
import { canAccessMessaging } from '../MessagingV2/selectors';
import { MenuItem, MenuType } from '../Platform/Constants';
import { UnleashFeatureNames } from '../Unleash/featureNames';
import CTAButton from './CTAButton';
import CTAButtonLoggedIn from './CTAButtonLoggedIn';
import CTAChat from './CTAChat';
import DropdownItem from './DropdownItem';
import {
  LeftMenu,
  NavbarContainer,
  NavbarLogo,
  RightMenu,
} from './NavbarCommon.sc';
import {
  DropdownLink,
  DropdownLinkLabel,
  DropdownMenu,
  StyledBadge,
} from './NavbarDesktop.sc';
import { NavbarItem } from './NavbarItem';
import {
  disableLink,
  filteredMenuItems,
  renderItemLabel,
} from './navigationUtils';
import { Spring2021LandingMenuItems } from './Spring2021LandingMenuItems';
import { useGetMenuItemsBuilder } from './useGetMenuItemsBuilder';
import UserMenu from './UserMenu/UserMenu';

const { COUNTRY } = config;
const isCountryID = COUNTRY === 'id';
const isCountrySG = COUNTRY === 'sg';

const NavbarDesktop: React.FC<NavbarDesktopProps> = ({
  authenticated,
  company,
  fromLandingPage,
  fromJobEditPage,
  fromOnboardingPage,
  hideSignUp,
  menuItems,
  permissions,
  role,
}) => {
  const intl = useIntl();
  const { empBuilderIntegration } = useSelector(getFeatures);

  const notifyNewVNMonetizedFeatures = useSelector(state =>
    getIsFeatureEnabled(
      state,
      UnleashFeatureNames.empNotifyNewVNMonetizedFeatures
    )
  );
  const isVNCompany = countryCodeIsVietnam((company as Company)?.CountryCode);

  const showNewLandingNavigation =
    fromLandingPage && (isCountryID || isCountrySG);
  const areFeaturesFetched = useSelector(getAreFeaturesFetched);
  const empCanAccessMessaging = useSelector(canAccessMessaging);
  const landingNavItems = useGetMenuItemsBuilder();
  const domain = config?.COUNTRY ?? COUNTRY_DOMAINS.default;
  const candidateUrl =
    domain && domain !== COUNTRY_DOMAINS.default
      ? `${config.CANDIDATE_URL}/${domain}`
      : config.CANDIDATE_URL;

  const isLoggedIn = useSelector(getIsLoggedIn);
  const isCurrentLandingMenu =
    areFeaturesFetched &&
    !authenticated &&
    !empBuilderIntegration &&
    showNewLandingNavigation;

  const referralCode = useGetRefCodeParam();

  return (
    <NavbarContainer>
      <LeftMenu mode="horizontal" selectedKeys={[]}>
        <NavbarLogo to={isLoggedIn ? '/dashboard' : '/'} />
        <If condition={areFeaturesFetched}>
          {filteredMenuItems(
            menuItems,
            Boolean(authenticated),
            empBuilderIntegration
          ).map(item => {
            if (item.children) {
              return (
                <Menu.Item key={item.value}>
                  <Dropdown
                    overlay={
                      <DropdownMenu>
                        <DropdownItem items={item.children} />
                      </DropdownMenu>
                    }
                  >
                    <DropdownLink>
                      <DropdownLinkLabel>
                        {renderItemLabel(intl, item.label)}
                      </DropdownLinkLabel>
                      <ArrowDownIcon />
                    </DropdownLink>
                  </Dropdown>
                </Menu.Item>
              );
            } else {
              return (
                <NavbarItem
                  activeClassName="ant-menu-item-selected"
                  key={item.value}
                  to={item.to || ''}
                  disabled={
                    item.canBeDisabled &&
                    disableLink(role, company, permissions)
                  }
                  badge={item.badge}
                  onClick={item.onClick}
                >
                  <Flex alignItems="center" style={{ gap: space8 }}>
                    {renderItemLabel(intl, item.label)}
                    <If
                      condition={
                        isVNCompany &&
                        notifyNewVNMonetizedFeatures &&
                        ['features', 'recommendedTalent'].includes(item.value)
                      }
                    >
                      <StyledBadge>
                        {intl.formatMessage({
                          id: 'text-new',
                          defaultMessage: 'New',
                        })}
                      </StyledBadge>
                    </If>
                  </Flex>
                </NavbarItem>
              );
            }
          })}
        </If>

        <If condition={isCurrentLandingMenu}>
          <Spring2021LandingMenuItems />
        </If>

        <If condition={empBuilderIntegration}>
          {!isLoggedIn &&
            landingNavItems.items.map(item => {
              if (item.subNav?.length) {
                return (
                  <Menu.Item key={item.label}>
                    <Dropdown
                      overlay={
                        <DropdownMenu>
                          <DropdownItem
                            key={item.label}
                            items={item.subNav.map(i => ({
                              value: i.label,
                              label: i.label,
                              to: referralCode
                                ? `${i.url}?refCode=${referralCode}`
                                : i.url,
                            }))}
                          />
                        </DropdownMenu>
                      }
                    >
                      <DropdownLink>
                        <DropdownLinkLabel>{item.label}</DropdownLinkLabel>
                        <ArrowDownIcon />
                      </DropdownLink>
                    </Dropdown>
                  </Menu.Item>
                );
              } else {
                return (
                  <NavbarItem key={item.label} to={item.url}>
                    {item.label}
                  </NavbarItem>
                );
              }
            })}
        </If>
      </LeftMenu>

      <RightMenu mode="horizontal" selectedKeys={[]} disabledOverflow={true}>
        <If condition={Boolean(authenticated)}>
          <ButtonGroup style={{ gap: 16, padding: 16 }}>
            <If condition={empCanAccessMessaging}>
              <CTAChat />
            </If>
            <CTAButtonLoggedIn
              fromJobEditPage={fromJobEditPage}
              fromOnboardingPage={fromOnboardingPage}
            />
          </ButtonGroup>
          <Menu.Divider />
          <UserMenu
            menuItems={menuItems.filter(link => link.type === MenuType.user)}
          />
        </If>
        <If condition={!authenticated}>
          <NavbarItem to="/login">
            <FormattedMessage
              id="modules.Navigation.NavbarDesktop.login"
              defaultMessage="Login"
            />
          </NavbarItem>

          <If condition={!hideSignUp && !['id', 'sg'].includes(config.COUNTRY)}>
            <NavbarItem to="/signup">
              <FormattedMessage
                id="modules.Navigation.NavbarDesktop.sign.up"
                defaultMessage="Sign Up"
              />
            </NavbarItem>
          </If>
          <If condition={!empBuilderIntegration}>
            <CTAButton />
          </If>
          <If condition={empBuilderIntegration && !authenticated}>
            <NavbarItem to={candidateUrl}>
              <FormattedMessage
                id="modules.Navigation.CTAButton.candidates"
                defaultMessage="Job Seekers"
              />
            </NavbarItem>
            <NavbarItem to={landingNavItems?.button?.url || ''}>
              <Button variant="solid-blue">
                {landingNavItems?.button?.label}
              </Button>
            </NavbarItem>
          </If>
        </If>
        <AppLanguageSwitcher />
      </RightMenu>
    </NavbarContainer>
  );
};

type NavbarDesktopProps = {
  authenticated?: boolean;
  fromJobEditPage: boolean;
  fromLandingPage: boolean;
  fromOnboardingPage: boolean;
  hideSignUp: boolean;
  menuItems: MenuItem[];
  company: unknown;
  permissions: unknown;
  role: string;
  hasNewUnreadMessages: boolean;
};

export default NavbarDesktop;
