import React, { ComponentType } from 'react';
import { Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { useMount } from 'react-use';

import { EmployerPurchaseProductType } from '../../../../common/interfaces/entities';
import { trackPaidFeatureSuccessfullyAdded } from '../PurchaseProductOrderSummarySection/tracking';
import { CreditProductPurchaseOrderForAfterPayment } from '../types';
import { GlintsVIPSuccessModalContent } from './GlintsVIPSuccessModalContent';
import { JobBoostSuccessModalContent } from './JobBoostSuccessModalContent';
import { RecommendedTalentChatSuccessModalContent } from './RecommendedTalentChatSuccessModalContent';
import { StyledSuccessModal } from './styles.sc';
import { TalentSearchSuccessModalContent } from './TalentSearchSuccessModalContent';

type CreditProductPurchaseSuccessModalProps = {
  creditProductPurchaseOrder: CreditProductPurchaseOrderForAfterPayment;
  onClose: () => void;
};

type ComponentTypeProps = {
  onClose: () => void;
  creditProductPurchaseOrder: CreditProductPurchaseOrderForAfterPayment;
};

type ModalContent = ComponentType<ComponentTypeProps> & {
  ModalTitle: ComponentType<ComponentTypeProps>;
  ModalFooter: ComponentType<ComponentTypeProps>;
};

const MappedModalContentBasedOnType: Record<
  EmployerPurchaseProductType,
  ModalContent
> = {
  [EmployerPurchaseProductType.HOT_JOB]: JobBoostSuccessModalContent,
  [EmployerPurchaseProductType.RECOMMENDED_TALENT_CHAT]:
    RecommendedTalentChatSuccessModalContent,
  [EmployerPurchaseProductType.VIP_MEMBERSHIP]: GlintsVIPSuccessModalContent,
  [EmployerPurchaseProductType.TALENT_SEARCH]: TalentSearchSuccessModalContent,
};

export default function CreditProductPurchaseSuccessModal({
  creditProductPurchaseOrder,
  onClose,
}: CreditProductPurchaseSuccessModalProps) {
  const productType = creditProductPurchaseOrder.product.type;
  const isUsingDefaultHeader =
    productType !== EmployerPurchaseProductType.VIP_MEMBERSHIP;

  useMount(() => {
    trackPaidFeatureSuccessfullyAdded(
      productType,
      creditProductPurchaseOrder.companyProductInventory?.amount ?? 0
    );
  });

  const Title = () => {
    const Component = MappedModalContentBasedOnType[productType].ModalTitle;

    return <Component {...{ creditProductPurchaseOrder, onClose }} />;
  };

  const Footer = () => {
    const Component = MappedModalContentBasedOnType[productType].ModalFooter;

    return <Component {...{ creditProductPurchaseOrder, onClose }} />;
  };

  const Content = () => {
    const Component = MappedModalContentBasedOnType[productType];

    return <Component {...{ creditProductPurchaseOrder, onClose }} />;
  };

  return (
    <StyledSuccessModal
      header={
        <Typography variant="subtitle1" color={Neutral.B18}>
          <Title />
        </Typography>
      }
      customActions={<Footer />}
      onClose={onClose}
      isOpen={true}
      closeOnClickOutside={true}
      headerHasDefaultPadding={isUsingDefaultHeader}
      showCloseButton={isUsingDefaultHeader}
    >
      <Content />
    </StyledSuccessModal>
  );
}
