import React, { useState } from 'react';

import { CreditProductPurchaseOrderForAfterPayment } from '../types';
import CreditProductPurchaseSuccessModal from './CreditProductPurchaseSuccessModal';

type ModalState =
  | {
      isOpen: true;
      creditProductPurchaseOrder: CreditProductPurchaseOrderForAfterPayment;
    }
  | {
      isOpen: false;
    };

type UseShowCreditProductPurchaseSuccessModal = {
  onClose?: () => void;
};
export default function useShowCreditProductPurchaseSuccessModal({
  onClose,
}: UseShowCreditProductPurchaseSuccessModal = {}) {
  const [modalState, setModalState] = useState<ModalState>({
    isOpen: false,
  });

  const handleClose = () => {
    setModalState({ isOpen: false });
    onClose?.();
  };

  const openCreditProductPurchaseSuccessModal = (
    creditProductPurchaseOrder: CreditProductPurchaseOrderForAfterPayment
  ) => {
    setModalState({ isOpen: true, creditProductPurchaseOrder });
  };

  return {
    // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
    creditProductPurchaseSuccessModal: modalState.isOpen ? (
      <CreditProductPurchaseSuccessModal
        creditProductPurchaseOrder={modalState.creditProductPurchaseOrder}
        onClose={handleClose}
      />
    ) : null,
    openCreditProductPurchaseSuccessModal,
  } as const;
}
