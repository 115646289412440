import { Actions as EntityActions } from './actions/entities';
import { Actions as ApplicationActions } from './modules/Application/Actions';

const GTM_JOB_APPLICATION_TYPES = {
  RECOMMENDATION: 'recommendation',
  APPLICATION: 'application',
};

const handleAction = {
  [ApplicationActions.UPDATE_APPLICATION_STATUS_SUCCESS]: (_state, action) => {
    const datalayerVars = {
      applicationStatus:
        action.response.entities.application[action.response.result.updates[0]]
          .status,
      fromTalentHuntJobApplication: false,
    };
    if (action.payload) {
      datalayerVars.changeCandidatePipelineStatusProps = action.payload.data;
    }
    return datalayerVars;
  },
  [EntityActions.UPDATE_TALENTHUNT_APPLICATION_ENTITY]: (_state, action) => {
    const talentHuntApplication = action.payload.data;
    if (talentHuntApplication.RecommendationId) {
      return {
        isMadeByAI: talentHuntApplication.isMadeByAI,
        fromTalentHuntJobApplication: true,
        talentHuntJobApplicationStatus: talentHuntApplication.status,
        gtmJobApplicationType: GTM_JOB_APPLICATION_TYPES.RECOMMENDATION,
        changeCandidatePipelineStatusProps: {
          topTenCandidateIds: talentHuntApplication.topTenCandidateIds,
          filter: talentHuntApplication.filter,
          sort: talentHuntApplication.sort,
          // These ids from EmployerATSPage is in camelCase while others are in PascalCase.
          jobId: talentHuntApplication.jobId || talentHuntApplication.JobId,
          candidateId:
            talentHuntApplication.candidateId ||
            talentHuntApplication.CandidateId,
        },
      };
    } else if (talentHuntApplication.ApplicationId) {
      return {
        fromTalentHuntJobApplication: true,
        talentHuntJobApplicationId: talentHuntApplication.ApplicationId,
        talentHuntJobApplicationJobId: talentHuntApplication.jobId,
        talentHuntJobApplicationStatus: talentHuntApplication.status,
        gtmJobApplicationType: GTM_JOB_APPLICATION_TYPES.APPLICATION,
      };
    } else {
      return {};
    }
  },
  [EntityActions.UPDATE_JOB_ENTITY]: (_state, action) => {
    const jobStatus = action.payload.data.status;
    return { jobStatus };
  },
};

export default (state, action) => {
  const actionHandler = handleAction[action.type];
  if (actionHandler) {
    return actionHandler(state, action);
  } else {
    return {};
  }
};
