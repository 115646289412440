// Abstraction around window.scrollTo. For mobile viewpoert widths, we are using
// a drawer component from antd which makes the drawer's content the main
// scrolling element. But for larger sceen sizes the window is the main scrolling
// element. In any case only one of these two will react to calling scrollTo on
// them. Thus this function here attempts to scroll both to make sure that we
// scroll to the target position whether we're on mobile or desktop.
export const scrollTo = (x: number, y: number) => {
  window.scrollTo(x, y);
  const content = document.querySelector('.am-drawer-content');
  if (content) {
    content.scrollTo(0, y);
  }
};
