import React from 'react';
import { FormattedMessage } from 'react-intl';

import config from '../../../../config';
import { resolveURL } from '../../utils';
import { Link } from './../../FooterGlints.sc';
import { LinkDefinition } from './types';

export const renderLinks = (defs: LinkDefinition[]) =>
  defs.map(def => {
    const url = resolveURL(def, config.COUNTRY);
    if (!url) {
      return;
    }

    return (
      <Link
        className={def.className}
        href={url}
        key={def.formattedMessageDescriptor.id}
        isExternal={def.isExternal}
        openInNewTab={def.openInNewTab}
      >
        <FormattedMessage {...def.formattedMessageDescriptor} />
      </Link>
    );
  });
