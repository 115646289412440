import { handleActions } from 'redux-actions';

import { Actions } from '../actions/groups';

const initialState = {
  all: {
    loading: false,
    error: null,
    value: [],
  },
};

export default handleActions(
  {
    [Actions.GROUPS_REQUEST]: state => ({
      ...state,
      all: {
        ...state.all,
        loading: true,
        value: [],
        error: null,
      },
    }),
    [Actions.GROUPS_SUCCESS]: (state, action) => ({
      ...state,
      all: {
        ...state.all,
        loading: false,
        value: action.response.result,
        error: null,
      },
    }),
    [Actions.GROUPS_FAILURE]: (state, action) => ({
      ...state,
      all: {
        ...state.all,
        loading: false,
        value: [],
        error: action.error,
      },
    }),
    [Actions.NON_GROUPS_REQUEST]: (state, action) => ({
      ...state,
      all: {
        ...state.all,
        loading: false,
        value: [],
        error: action.error,
      },
    }),
    [Actions.NON_GROUPS_SUCCESS]: (state, action) => ({
      ...state,
      all: {
        ...state.all,
        loading: false,
        value: [],
        error: action.error,
      },
    }),
    [Actions.NON_GROUPS_FAILURE]: (state, action) => ({
      ...state,
      all: {
        ...state.all,
        loading: false,
        value: [],
        error: action.error,
      },
    }),
  },
  initialState
);
