import { push } from 'connected-react-router';
import { createAction } from 'redux-actions';

import axios from '../common/axios-ts';
import CALL_API from '../middleware/api_key';
import { Schemas } from '../middleware/schemas';
import { trackCompanyJoinRequested } from '../modules/CompanyOnboarding/CompanyCreationPage/components/JoinCompanyModal/GTMActions';
import { fetchMe } from './user/me';

export const Actions = {
  COMPANY_REQUEST: 'glints/company/COMPANY_REQUEST',
  COMPANY_SUCCESS: 'glints/company/COMPANY_SUCCESS',
  COMPANY_FAILURE: 'glints/company/COMPANY_FAILURE',

  COMPANIES_REQUEST: 'glints/onboarding/COMPANIES_REQUEST',
  COMPANIES_SUCCESS: 'glints/onboarding/COMPANIES_SUCCESS',
  COMPANIES_FAILURE: 'glints/onboarding/COMPANIES_FAILURE',
};

export const REQUEST_COMPANY_USERS = 'glints/company/REQUEST_COMPANY_USERS';
export const RECEIVE_COMPANY_USERS = 'glints/company/RECEIVE_COMPANY_USERS';

export const UPDATE_COMPANY_TAB = 'glints/company/UPDATE_COMPANY_TAB';

export const TOGGLE_COMPANY_ADMIN_REQUEST_MODAL =
  'glints/company/TOGGLE_COMPANY_ADMIN_REQUEST_MODAL';

export const JOIN_COMPANY_REQUEST = 'glints/company/JOIN_COMPANY_REQUEST';
export const JOIN_COMPANY_SUCCESS = 'glints/company/JOIN_COMPANY_SUCCESS';
export const JOIN_COMPANY_FAILURE = 'glints/company/JOIN_COMPANY_FAILURE';

export const requestCompanyUsers = createAction(REQUEST_COMPANY_USERS);
export const receiveCompanyUsers = createAction(RECEIVE_COMPANY_USERS);

export const toggleCompanyAdminRequestModal = createAction(
  TOGGLE_COMPANY_ADMIN_REQUEST_MODAL
);

export const joinCompanyRequest = createAction(JOIN_COMPANY_REQUEST);
export const joinCompanySuccess = createAction(JOIN_COMPANY_SUCCESS);
export const joinCompanyFailure = createAction(JOIN_COMPANY_FAILURE);

export function fetchCompany(id, options = {}) {
  return async dispatch => {
    await dispatch({
      [CALL_API]: {
        types: [
          Actions.COMPANY_REQUEST,
          Actions.COMPANY_SUCCESS,
          Actions.COMPANY_FAILURE,
        ],
        endpoint: `companies/${id}`,
        schema: Schemas.COMPANY,
        options: {
          ...options,
          params: {
            include: 'GroupMembers',
            ...(options ? options.params : {}),
          },
        },
      },
    });
  };
}

export function fetchCompanyUsers(companyId, options) {
  return async (dispatch, getState) => {
    try {
      dispatch(requestCompanyUsers());
      const response = await axios(getState().session.token).get(
        `/companies/${companyId}/userCompanies`,
        {
          params: {
            include: 'User',
            ...(options ? options.params : {}),
          },
        }
      );
      dispatch(receiveCompanyUsers(response.data));
    } catch (err) {
      dispatch(receiveCompanyUsers(err));
    }
  };
}

export function requestJoinCompany(companyId) {
  return async (dispatch, getState) => {
    try {
      dispatch(joinCompanyRequest());
      await axios(getState().session.token).post(
        `companies/${companyId}/pendingUserCompanies`
      );
      trackCompanyJoinRequested(companyId);
      dispatch(joinCompanySuccess());
      await dispatch(fetchMe());
      dispatch(toggleCompanyAdminRequestModal(false));
      dispatch(push('/dashboard'));
    } catch (err) {
      dispatch(joinCompanyFailure(err));
    }
  };
}

export function fetchCompanies(options) {
  return dispatch => {
    dispatch({
      [CALL_API]: {
        types: [
          Actions.COMPANIES_REQUEST,
          Actions.COMPANIES_SUCCESS,
          Actions.COMPANIES_FAILURE,
        ],
        endpoint: 'companies',
        schema: Schemas.COMPANY_ARRAY,
        options: {
          ...options,
          params: {
            ...(options ? options.params : {}),
          },
        },
      },
    });
  };
}
