import { createSelector } from 'reselect';

import { getUserCompanies } from './user';

export const getSessionToken = state => {
  if (state.session.oneTimeToken) {
    return state.session.oneTimeToken;
  } else {
    return state.session.token;
  }
};

export const getChatToken = state => state.session.chatToken;

export const getSessionCompany = state => state.session.data.company;

export const getSessionCompanyId = state => state.session.data.company?.id;

export const getSessionCompanyName = state => state.session.data.company.name;

export const getSessionCompanyCountryCode = state =>
  state.session.data.company?.CountryCode;

export const getSessionUserCompanyId = createSelector(
  [getSessionCompanyId, getUserCompanies],
  (companyId, userCompanies) =>
    userCompanies.find(company => company.id === companyId).UserCompany.id
);

export const isSessionInitialized = state =>
  Boolean(state.session.lastInitialized);

export const isSessionError = state => Boolean(state.session.initializationErr);

export const getSessionInitializationErr = state =>
  state.session.initializationErr;

export const getScopedTokenActionCompleted = state =>
  state.session.scopedTokenActionCompleted;

export const getIsLoggedIn = state => Boolean(state?.session?.token);
