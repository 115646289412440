import React from 'react';

import { ROUTES } from '../../../common/routes';
import ContactSupportButton from '../../../components/ContactSupportButton/ContactSupportButton';
import { MenuItemsWithLogoutAndContactSupport } from './MenuItemsWithLogoutAndContactSupport/MenuItemsWithLogoutAndContactSupport';

export const AlterNativeNavbarContentBasedOnPathName: Record<
  string,
  JSX.Element | undefined
> = {
  [`/${ROUTES.onboarding}`]: <ContactSupportButton />,
  [`/${ROUTES.emailVerification}`]: <ContactSupportButton />,
  [`/${ROUTES.companyReportStatus}`]: <MenuItemsWithLogoutAndContactSupport />,
};
