import React from 'react';
import { Icon } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space16, space24 } from 'glints-aries/es/@next/utilities/spacing';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styled from 'styled-components';

const StyledCustomHeader = styled.div`
  background-image: linear-gradient(90deg, #ffedb1, #fff8e0);
  padding: ${space16} ${space24};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GlintsVIPCustomModalHeader = ({
  title,
  onClose,
}: {
  title: MessageDescriptor;
  onClose: () => void;
}) => (
  <StyledCustomHeader>
    <FormattedMessage {...title} />
    <Icon
      name="ri-close"
      height={24}
      fill={Neutral.B40}
      onClick={() => onClose()}
      style={{ cursor: 'pointer' }}
    />
  </StyledCustomHeader>
);
