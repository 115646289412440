import { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { ScreenSize } from 'glints-aries';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useEffectOnce } from 'react-use';
import { ChannelFilters, ChannelSort, EventTypes } from 'stream-chat';
import {
  Streami18n,
  StreamMessage,
  useChannelStateContext,
  useChatContext,
} from 'stream-chat-react';
import {
  BooleanParam,
  StringParam,
  useQueryParam,
  useQueryParams,
} from 'use-query-params';

import { ApplicationStatus } from '../../common/constants';
import useBulkChangeApplicationStatus from '../../common/hooks/requests/useBulkChangeApplicationStatus';
import { DefaultStreamChannelTypes } from '../../common/stream';
import { getConfig } from '../../config';
import { getFeatures, getIsFeatureEnabled } from '../../selectors/features';
import { getSessionCompanyId } from '../../selectors/session';
import { getUser } from '../../selectors/user';
import { UnleashFeatureNames } from '../Unleash/featureNames';
import {
  ChannelQueryParam,
  dayjsCalendarMessages,
  FilterApplicationSourceToApplicationSourceMap,
  getStreamSystemMessages,
  MessagingUIMode,
  useMessagingChannelContext,
} from './constants';
import {
  getChannelApplicationMetadata,
  getChannelApplicationMetadataV2,
  getMessageApplicationMetadata,
  isCustomMessage,
} from './helper';
import { buildFilterApplicationSourceQueryParam } from './queryParams';
import {
  ChatPageViewedEventSource,
  trackChatPageViewedEvent,
  trackMessageSentEvent,
} from './tracking';
import { MessagingMessageData } from './types';

export const useRebuildComponentOnChannelEvent = (event: EventTypes) => {
  const { channel } = useChannelStateContext();
  const forceRefresh = useReducer(() => ({}), {})[1];

  useEffect(() => {
    channel.on(event, forceRefresh);

    return () => channel.off(event, forceRefresh);
  }, [channel, event, forceRefresh]);
};

export const useTrackMessageSentEvent = () => {
  const { client } = useChatContext();
  const { channel } = useChannelStateContext();
  const { uiMode } = useMessagingChannelContext();

  const currentUserId = client.userID;

  useEffect(
    () =>
      channel.on('message.new', ({ message }) => {
        if (
          message &&
          message.user?.id === currentUserId &&
          message.type === 'regular' &&
          message.text
        ) {
          const channelMetadata = getChannelApplicationMetadata(channel);
          trackMessageSentEvent({
            uiType: uiMode,
            messageId: message.id,
            messageType: message.isFirstReply
              ? 'FIRST_REPLY'
              : 'NORMAL_MESSAGE',
            messageLength: message.text?.length,
            jobId: channelMetadata?.application.JobId,
            companyId: channelMetadata?.application.Job?.CompanyId,
            channelId: channel.id,
            channelMembersCount: channel.data?.memberCount as number,
            applicationStatus: channelMetadata?.application.status,
          });
        }
      }).unsubscribe,
    [currentUserId, channel, uiMode]
  );
};

export const useSetRefParentElementZIndex = (zIndex: string) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const parentEl = ref.current?.parentElement;
    if (parentEl) {
      parentEl.style.zIndex = zIndex;
    }
  }, [zIndex, ref]);

  return ref;
};

export const useIsGroupChatEnabled = () => {
  const { empApplicationGroupChatEnabled } = useSelector(getFeatures);

  return Boolean(empApplicationGroupChatEnabled);
};

export const useFormatStreamMessage = () => {
  const { formatMessage } = useIntl();

  return (message: MessagingMessageData) => {
    if (!message.glints_i18n_message_props) return message.text;

    return formatMessage(
      {
        id: message.glints_i18n_message_props.id,
        defaultMessage: message.glints_i18n_message_props.default_message,
      },
      message.glints_i18n_message_props.values
    );
  };
};

/**
 * Returns a callback to be invoked when the employer responded to the cdd
 * (eg: send a text message, initiate contacts exchange request or accepting/rejecting them).
 *
 * This side effect includes updating the application status from NEW to IN_REVIEW if it's the first time the employer has responded to the cdd, noop otherwise.
 */

export const useEmpRespondSideEffect = () => {
  const { channel } = useChannelStateContext();
  const {
    isBackgroundUpdateApplicationStatusInProgress,
    setIsBackgroundUpdateApplicationStatusInProgress,
  } = useMessagingChannelContext();
  const metadata = getChannelApplicationMetadataV2(channel);
  const updateApplicationStatus = useBulkChangeApplicationStatus({
    jobId: metadata.glints_job_id,
    disableAlert: true,
  });
  const applicant =
    channel.state.members[metadata.glints_application_applicant_id]?.user;
  const isApplicationNewOrRejected = [
    ApplicationStatus.NEW,
    ApplicationStatus.REJECTED,
  ].includes(metadata.glints_application_status);

  const updateApplicationStatusToInReview = async () => {
    try {
      if (
        isApplicationNewOrRejected &&
        !isBackgroundUpdateApplicationStatusInProgress
      ) {
        setIsBackgroundUpdateApplicationStatusInProgress(true);
        const status = ApplicationStatus.IN_REVIEW;
        await updateApplicationStatus(
          [
            {
              status,
              name: applicant?.name ?? '',
              applicationId: metadata.glints_application_id,
            },
          ],
          {
            eagerUpdateChannel: true,
          }
        );
      }
    } finally {
      setIsBackgroundUpdateApplicationStatusInProgress(false);
    }
  };

  return updateApplicationStatusToInReview;
};

export const useChannelFiltersState = (): ChannelFilters => {
  const user = useSelector(getUser);
  const companyId = useSelector(getSessionCompanyId);
  const isRecommendedTalentEnabled = useSelector(state =>
    getIsFeatureEnabled(state, UnleashFeatureNames.empRecommendedTalents)
  );

  const [{ app_source, app_status, cid, keyword, job_id }] = useQueryParams({
    [ChannelQueryParam.APP_SOURCE]: buildFilterApplicationSourceQueryParam({
      isRecommendedTalentEnabled,
    }),
    [ChannelQueryParam.APP_STATUS]: StringParam,
    [ChannelQueryParam.CHANNEL_ID]: StringParam,
    [ChannelQueryParam.KEYWORD]: StringParam,
    [ChannelQueryParam.JOB_ID]: StringParam,
  });

  const appSources = FilterApplicationSourceToApplicationSourceMap[app_source];

  const filters: ChannelFilters = {
    type: DefaultStreamChannelTypes.MESSAGING,
    members: {
      $in: [user.id],
    },
    glints_application_id: {
      $exists: true,
    },
    glints_company_id: companyId,
    glints_application_status:
      app_status ?? (cid ? undefined : ApplicationStatus.NEW),
    glints_job_id: job_id,
  };

  if (appSources) {
    filters.glints_application_source = {
      $in: appSources,
    };
  }

  if (keyword) {
    filters['member.user.name'] = {
      $autocomplete: keyword ?? '',
    };
  }

  return filters;
};

export const useChannelSortState = (): ChannelSort => {
  const [query] = useQueryParams({
    [ChannelQueryParam.SORT_BY_UNREAD]: BooleanParam,
  });
  const sort: ChannelSort = {};

  if (query[ChannelQueryParam.SORT_BY_UNREAD]) {
    sort.unread_count = -1;
  }

  return sort;
};

export const useIsMobileVersion = (componentName = '') => {
  const componentBreakpointMapping: Record<string, number> = {
    ChannelHeaderMenus: 576,
    TopNavCTA: ScreenSize.tablet,
    ResponseRate: ScreenSize.tablet,
  };

  const maxWidth =
    componentBreakpointMapping[componentName] ?? ScreenSize.desktopM;

  const isTablet = useMediaQuery({
    maxWidth: maxWidth - 1,
  });

  return isTablet;
};

export const useTrackPageRedirectedFromZalo = () => {
  const [source, setSource] = useQueryParam(
    ChannelQueryParam.SOURCE,
    StringParam
  );

  useEffectOnce(() => {
    if (source?.toLowerCase() === 'zalo') {
      trackChatPageViewedEvent({
        source: source as ChatPageViewedEventSource,
        uiType: MessagingUIMode.FULL,
      });

      setSource(undefined, 'replaceIn');
    }
  });
};

export const useTemplateDrawerContext = () => {
  const [isTemplateDrawerOpened, setIsTemplateDrawerOpened] = useQueryParam(
    ChannelQueryParam.OPEN_TEMPLATE_DRAWER,
    BooleanParam
  );

  return {
    isOpen: Boolean(isTemplateDrawerOpened),
    open: () => setIsTemplateDrawerOpened(true, 'replaceIn'),
    close: () => setIsTemplateDrawerOpened(undefined, 'replaceIn'),
  };
};

export const useChannelLatestRegularMessageSentByCurrentUser = () => {
  const { client } = useChatContext();
  const { channel } = useChannelStateContext();

  const message = useMemo(
    () =>
      channel.state.latestMessages
        .filter(
          msg =>
            msg.type === 'regular' &&
            msg.user?.id === client.userID &&
            !isCustomMessage(msg)
        )
        .pop(),
    [client.userID, channel.state.latestMessages]
  );

  return message;
};

export const useStreamI18nInstance = () => {
  const { formatMessage } = useIntl();
  const config = getConfig();

  const [i18nInstance] = useState(
    new Streami18n({
      language: config.LANG ?? 'en',
      translationsForLanguage: {
        'New Messages!': formatMessage(getStreamSystemMessages.newMessages),
        Close: formatMessage(getStreamSystemMessages.close),
      },
      dayjsLocaleConfigForLanguage: {
        // @ts-ignore
        calendar: {
          lastDay: formatMessage(dayjsCalendarMessages.lastDay),
          sameDay: formatMessage(dayjsCalendarMessages.sameDay),
          nextDay: formatMessage(dayjsCalendarMessages.nextDay),
          lastWeek: formatMessage(dayjsCalendarMessages.lastWeek),
          nextWeek: formatMessage(dayjsCalendarMessages.nextWeek),
          sameElse: 'DD/MM/YY',
        },
      },
    })
  );

  return i18nInstance;
};

export const useIsPopupMode = () => {
  const { uiMode } = useMessagingChannelContext();

  return uiMode === MessagingUIMode.POPUP;
};

export const useIsChatWidgetEnabled = () => !useIsMobileVersion();

export const useChannelCVRequestMessage = () => {
  const [fetchedMessage, setFetchedMessage] = useState<StreamMessage>();
  const { channel, messages } = useChannelStateContext();
  const { glints_cv_request_msg_id } = getChannelApplicationMetadataV2(channel);

  const cvRequestMessage = glints_cv_request_msg_id
    ? messages?.find(message => message.id === glints_cv_request_msg_id)
    : undefined;
  const message = cvRequestMessage ?? fetchedMessage;
  const metadata = message ? getMessageApplicationMetadata(message) : undefined;

  useEffect(() => {
    setFetchedMessage(undefined);
    if (!glints_cv_request_msg_id || cvRequestMessage) return;

    const fetchMessage = async () => {
      const response = await channel.getMessagesById([
        glints_cv_request_msg_id,
      ]);

      setFetchedMessage(response.messages[0]);
    };

    fetchMessage();
  }, [glints_cv_request_msg_id, cvRequestMessage, channel]);

  if (!message || !metadata) return null;

  return {
    message,
    metadata,
  };
};
