import CALL_API from '../middleware/api_key';
import { Schemas } from '../middleware/schemas';

export const Actions = {
  STUDIES_REQUEST: 'glints/studies/STUDIES_REQUEST',
  STUDIES_SUCCESS: 'glints/studies/STUDIES_SUCCESS',
  STUDIES_FAILURE: 'glints/studies/STUDIES_FAILURE',
};

export function fetchStudies(options) {
  return {
    type: 'fetchStudies',
    [CALL_API]: {
      types: [
        Actions.STUDIES_REQUEST,
        Actions.STUDIES_SUCCESS,
        Actions.STUDIES_FAILURE,
      ],
      endpoint: 'studies',
      schema: Schemas.STUDY_ARRAY,
      options: {
        ...options,
        params: {
          order: 'name ASC',
          ...(options ? options.params : {}),
        },
      },
    },
  };
}
