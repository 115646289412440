import { createAction } from 'redux-actions';

import CALL_API from '../../../middleware/api_key';
import { Schemas } from '../../../middleware/schemas';

export const Actions = {
  INIT_STATE: 'glints/form/formSelectJobCategory/INIT_STATE',
  DESTROY_STATE: 'glints/form/formSelectJobCategory/DESTROY_STATE',

  JOB_CATEGORIES_REQUEST:
    'glints/form/formSelectJobCategory/JOB_CATEGORIES_REQUEST',
  JOB_CATEGORIES_SUCCESS:
    'glints/form/formSelectJobCategory/JOB_CATEGORIES_SUCCESS',
  JOB_CATEGORIES_FAILURE:
    'glints/form/formSelectJobCategory/JOB_CATEGORIES_FAILURE',

  UPDATE_SELECTED_JOB_CATEGORY:
    'glints/form/formSelectJobCategory/UPDATE_SELECTED_JOB_CATEGORY',
  SELECTED_JOB_CATEGORY_REQUEST:
    'glints/form/formSelectJobCategory/SELECTED_JOB_CATEGORY_REQUEST',
  SELECTED_JOB_CATEGORY_SUCCESS:
    'glints/form/formSelectJobCategory/SELECTED_JOB_CATEGORY_SUCCESS',
  SELECTED_JOB_CATEGORY_FAILURE:
    'glints/form/formSelectJobCategory/SELECTED_JOB_CATEGORY_FAILURE',
};

export const initState = createAction(Actions.INIT_STATE, null, form => ({
  form,
}));
export const destroyState = createAction(Actions.DESTROY_STATE, null, form => ({
  form,
}));

export const updateSelectedJobCategory = createAction(
  Actions.UPDATE_SELECTED_JOB_CATEGORY,
  (form, jobCategory) => jobCategory,
  form => ({ form })
);

export function fetchSelectedJobCategory(form, id) {
  return {
    [CALL_API]: {
      types: [
        Actions.SELECTED_JOB_CATEGORY_REQUEST,
        Actions.SELECTED_JOB_CATEGORY_SUCCESS,
        Actions.SELECTED_JOB_CATEGORY_FAILURE,
      ],
      meta: { form },
      endpoint: `jobCategories/${id}`,
      schema: Schemas.JOB_CATEGORY,
    },
  };
}

export function fetchJobCategories(
  form,
  searchTerm,
  excludeDescription = true
) {
  const options = {
    params: {
      order: 'name ASC',
      where: searchTerm
        ? {
            name: {
              ilike: `%${searchTerm}%`,
            },
          }
        : undefined,
    },
  };

  if (excludeDescription) {
    const excludeDescriptionAttributes = JSON.stringify([
      'id',
      'links',
      'name',
      'sections',
      'createdAt',
      'udpatedAt',
      'bannerPic',
    ]);
    options.attributes = excludeDescriptionAttributes;
  }
  return {
    [CALL_API]: {
      types: [
        Actions.JOB_CATEGORIES_REQUEST,
        Actions.JOB_CATEGORIES_SUCCESS,
        Actions.JOB_CATEGORIES_FAILURE,
      ],
      meta: { form },
      endpoint: 'jobCategories',
      schema: Schemas.JOB_CATEGORY_ARRAY,
      options,
    },
  };
}
