import React, { Suspense } from 'react';
import { Flex } from 'glints-aries/es';
import { Badge, Icon, Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space4 } from 'glints-aries/es/@next/utilities/spacing';
import { FormattedNumber } from 'react-intl';
import styled from 'styled-components';

import { GlintsLogoRed } from '../../../common/color';
import ErrorBoundaryWithCrashReporting from '../../../components/Error/ErrorBoundaryWithCrashReporting';
import { useGetCompanyCreditInventorySuspenseQuery } from '../hooks/useGetCompanyCreditInventory';

const StyledBadge = styled(Badge)`
  background-color: ${Neutral.B99};
`;
function CreditBalanceActivatorBadgeComponent() {
  const { data } = useGetCompanyCreditInventorySuspenseQuery('cache-first');
  return (
    <StyledBadge>
      <Flex
        style={{
          gap: space4,
        }}
        alignItems="center"
      >
        <Icon name="ri-glints-logo-fill" fill={GlintsLogoRed} width={12} />
        <Typography variant="overline">
          <FormattedNumber value={data.amount} />
        </Typography>
      </Flex>
    </StyledBadge>
  );
}

export default function CreditBalanceActivatorBadge() {
  return (
    <ErrorBoundaryWithCrashReporting fallback={null}>
      <Suspense fallback={null}>
        <CreditBalanceActivatorBadgeComponent />
      </Suspense>
    </ErrorBoundaryWithCrashReporting>
  );
}
