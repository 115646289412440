import { handleActions } from 'redux-actions';

import { Actions, UPDATE_SEARCH_TERM } from '../actions/countries';

const initialState = {
  loading: false,
  error: null,
  value: [],
  lastRequest: null,
  searchTerm: '',
};

export default handleActions(
  {
    [Actions.COUNTRIES_REQUEST]: state => ({
      ...state,
      loading: true,
      error: null,
      value: state.value.length ? state.value : [],
      lastRequest: new Date().toISOString().split('.')[0],
    }),
    [Actions.COUNTRIES_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      value: action.response.result,
    }),
    [Actions.COUNTRIES_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
      value: [],
      lastRequest: null,
    }),
    [UPDATE_SEARCH_TERM]: (state, action) => ({
      ...state,
      searchTerm: action.payload,
    }),
  },
  initialState
);
