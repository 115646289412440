import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { head, isObject } from 'lodash-es';

import { useAxiosWithAPI } from '../../../common/hooks/useAxiosWithAPI';

type SuccessResponse = {
  success: boolean;
};

type ErrorResponse = {
  error: {
    title: string;
    details: Array<{ id: string } | string>;
  };
};

export const useApplicationReadStatus = () => {
  const axios = useAxiosWithAPI();

  const setApplicationRead = useCallback(
    async (applicationId: string) => {
      const url = `/applications/${applicationId}/read`;
      try {
        const { data } = await axios.post<SuccessResponse | ErrorResponse>(
          url,
          {
            data: {},
          }
        );
        return { ...data };
      } catch (err) {
        const error = err as AxiosError<ErrorResponse>;
        const apiError = error.response?.data.error;
        if (apiError) {
          const apiErrorMessages = isObject(head(apiError.details))
            ? apiError.details.map(_err => Object.values(_err).join(', '))
            : apiError.details;
          throw new Error(`${apiError.title} ${apiErrorMessages.join(', ')}`);
        }
        throw error;
      }
    },
    [axios]
  );

  return { setApplicationRead };
};
