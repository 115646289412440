import { useSelector } from 'react-redux';

import { UnleashFeatureNames } from '../../modules/Unleash/featureNames';
import { getIsFeatureEnabled } from '../../selectors/features';

export default function useIsOSSEnabled(): boolean {
  const allUseOSS = useSelector(state =>
    getIsFeatureEnabled(state, UnleashFeatureNames.allUseOSS)
  );

  return allUseOSS;
}
