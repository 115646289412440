import { defineMessage, defineMessages } from 'react-intl';

export const loadingMessage = defineMessage({
  id: 'text-loading',
  defaultMessage: 'Loading...',
});

export const headerMessages = defineMessages({
  nameAndLocation: {
    id: 'text-name-and-location',
    defaultMessage: 'Name & Location',
  },
  contact: {
    id: 'text-contact',
    defaultMessage: 'Contact',
  },
  lastActive: {
    id: 'text-last-active',
    defaultMessage: 'Last Active',
  },
  gender: {
    id: 'text-gender',
    defaultMessage: 'Gender',
  },
  expectedSalary: {
    id: 'text-expected-salary',
    defaultMessage: 'Expected Salary',
  },
  yearsOfExperience: {
    id: 'text-yoe',
    defaultMessage: 'YoE',
  },
  latestWorkExperience: {
    id: 'text-latest-work-experience',
    defaultMessage: 'Latest Position',
  },
  education: {
    id: 'text-education',
    defaultMessage: 'Education',
  },
  action: {
    id: 'text-action',
    defaultMessage: 'Actions',
  },
  highestEducationLevel: {
    id: 'text-education-level',
    defaultMessage: 'Education Level',
  },
  applicationDate: {
    id: 'text-application-date',
    defaultMessage: 'Application Date',
  },
  whatsApp: {
    id: 'text-channel-whatsapp',
    defaultMessage: 'WhatsApp',
  },
});

export const actionMessages = defineMessages({
  moveTo: {
    id: 'interactive-move-to',
    defaultMessage: 'Move To',
  },
  process: {
    id: 'interactive-process',
    defaultMessage: 'Process',
  },
  reject: {
    id: 'interactive-reject',
    defaultMessage: 'Reject',
  },
});

export const restrictUnverifiedCompanyMessage = defineMessage({
  id: 'text-company-needs-verifications-candidate',
  defaultMessage:
    'Your company needs to be verified before you can view the resume and profile details of the candidate. After verification, you can also chat with the candidate directly.',
});

export const RESOURCE_NAME = {
  singular: 'candidate',
  plural: 'candidates',
};
