import { useCallback } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import useEvictApplicationCache from '../../../../common/hooks/useEvictApplicationCache';
import { ChannelQueryParam } from '../../constants';

export default function useCloseChannelPopupWidget() {
  const [popupChannelId, setPopupChannelId] = useQueryParam(
    ChannelQueryParam.POPUP_CHANNEL_ID,
    StringParam
  );

  const evictApplicationCache = useEvictApplicationCache();
  const closeChannelPopupWidget = useCallback(() => {
    if (popupChannelId) {
      // since we have cache-first policy for application query, we need to evict the cache
      // after popup is closed to make sure the application is refetched
      // when the popup is opened again
      // this mimics network only behavior while keeping the cache-first policy
      evictApplicationCache(popupChannelId);
    }
    setPopupChannelId(undefined, 'replaceIn');
  }, [evictApplicationCache, popupChannelId, setPopupChannelId]);
  return closeChannelPopupWidget;
}
