import { handleActions } from 'redux-actions';

import { Actions } from '../actions/session';

/**
 * Reducer for login.
 */
const initialState = {
  token: null,
  chatToken: null,
  scopedTokenActionCompleted: false,

  data: {
    company: undefined,
    group: undefined,
    customFeatures: [],
  },

  lastInitialized: false,
  initializationErr: null,

  marketingMetadata: null,
};

export default handleActions(
  {
    [Actions.SET_SESSION_TOKEN]: (state, action) => ({
      ...state,
      token: action.payload,
    }),
    [Actions.SET_CHAT_TOKEN]: (state, action) => ({
      ...state,
      chatToken: action.payload,
    }),
    [Actions.SET_ONE_TIME_TOKEN]: (state, action) => ({
      ...state,
      oneTimeToken: action.payload,
    }),
    [Actions.CLEAR_ONE_TIME_TOKEN]: state => ({
      ...state,
      oneTimeToken: null,
    }),
    [Actions.RESET_LOGIN_STATE]: state => ({
      ...state,
      login: initialState.login,
    }),
    [Actions.SET_SESSION_COMPANY]: (state, action) => ({
      ...state,
      data: {
        ...state.data,
        company: action.payload,
        group: null,
      },
    }),
    [Actions.SET_SESSION_GROUP]: (state, action) => ({
      ...state,
      data: {
        ...state.data,
        company: null,
        group: action.payload,
      },
    }),
    [Actions.REQUEST_INITIALIZE_SESSION]: state => ({
      ...state,
      lastInitialized: null,
      initializationErr: null,
    }),
    [Actions.RECEIVE_INITIALIZE_SESSION]: (state, action) => ({
      ...state,
      lastInitialized: new Date(),
      initializationErr: action.error ? action.payload : null,
    }),
    [Actions.UPDATE_MARKETING_METADATA]: (state, action) => ({
      ...state,
      marketingMetadata: action.payload,
    }),
    [Actions.SET_SCOPED_TOKEN_ACTION_COMPLETED]: state => ({
      ...state,
      scopedTokenActionCompleted: true,
    }),
  },
  initialState
);
