import { createAction } from 'redux-actions';

import axios from '../../common/axios-ts';
import CALL_API from '../../middleware/api_key';
import { Schemas } from '../../middleware/schemas';
import { getSessionToken } from '../../selectors/session';

export const Actions = {
  SEND_TOKEN_REQUEST: 'glints/emailVerification/SEND_TOKEN_REQUEST',
  SEND_TOKEN_SUCCESS: 'glints/emailVerification/SEND_TOKEN_SUCCESS',
  SEND_TOKEN_FAILURE: 'glints/emailVerification/SEND_TOKEN_FAILURE',

  VERIFY_EMAIL_REQUEST: 'glints/emailVerification/VERIFY_EMAIL_REQUEST',
  VERIFY_EMAIL_SUCCESS: 'glints/emailVerification/VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_FAILURE: 'glints/emailVerification/VERIFY_EMAIL_FAILURE',
};

export const sendTokenRequest = createAction(Actions.SEND_TOKEN_REQUEST);
export const sendTokenSuccess = createAction(Actions.SEND_TOKEN_SUCCESS);
export const sendTokenFailure = createAction(Actions.SEND_TOKEN_FAILURE);

export function sendEmailVerificationToken(referralCode) {
  return async (dispatch, getState) => {
    try {
      dispatch(sendTokenRequest());
      await axios(getSessionToken(getState())).post(
        '/users/sendEmailVerificationToken',
        referralCode ? { acquisitionReferralCode: referralCode } : {}
      );
      dispatch(sendTokenSuccess());
    } catch (err) {
      dispatch(sendTokenFailure(err));
    }
  };
}

export function verifyEmail({ token, id, email }) {
  return async dispatch => {
    await dispatch({
      [CALL_API]: {
        types: [
          Actions.VERIFY_EMAIL_REQUEST,
          Actions.VERIFY_EMAIL_SUCCESS,
          Actions.VERIFY_EMAIL_FAILURE,
        ],
        endpoint: 'users/verifyEmail',
        schema: Schemas.USER,
        method: 'post',
        options: {
          token,
          id,
          email,
        },
      },
    });
  };
}
