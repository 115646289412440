import React from 'react';
import { OutlineButton, Typography } from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../../../common/routes';

export const LogoutButton = () => (
  <Link to={`/${ROUTES.logout}`}>
    <OutlineButton>
      <Typography variant="button">
        <FormattedMessage id="interactive-log-out" defaultMessage="Logout" />
      </Typography>
    </OutlineButton>
  </Link>
);
