import React, { useState } from 'react';
import { Modal, TextArea, Typography } from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';

type EditMessageModalProps = {
  text?: string;
  onSubmit: (text: string) => void;
  onClose: () => void;
};

const EditMessageModal = ({
  text,
  onSubmit,
  onClose,
}: EditMessageModalProps) => {
  const [value, setValue] = useState<string>(text ?? '');
  const trimmedValue = value.trim();
  const handleSubmit = () => {
    if (trimmedValue.length) {
      onSubmit(trimmedValue);
    }
  };

  return (
    <Modal
      isOpen={true}
      header={
        <FormattedMessage
          id="text_edit-message-title"
          defaultMessage="Edit message"
        />
      }
      primaryAction={{
        label: (
          <Typography variant="button">
            <FormattedMessage id="interactive-save" defaultMessage="Save" />
          </Typography>
        ),
        onClick: handleSubmit,
        disabled: !trimmedValue.length,
      }}
      secondaryAction={{
        label: (
          <Typography variant="button">
            <FormattedMessage id="button-text_cancel" defaultMessage="Cancel" />
          </Typography>
        ),
        onClick: onClose,
      }}
      onClose={onClose}
      closeOnClickOutside={true}
    >
      <TextArea value={value} canExceedMaxLength={true} onChange={setValue} />
    </Modal>
  );
};

export default EditMessageModal;
