import { merge, uniq } from 'lodash-es';
import { createAction } from 'redux-actions';

import axios from '../common/axios-ts';
import CALL_API from '../middleware/api_key';
import { Schemas } from '../middleware/schemas';
import { updateJobEntity } from './entities';

export const Actions = {
  JOB_REQUEST: 'glints/job/JOB_REQUEST',
  JOB_SUCCESS: 'glints/job/JOB_SUCCESS',
  JOB_FAILURE: 'glints/job/JOB_FAILURE',
};

export const REQUEST_UPDATE_JOB = 'glints/job/REQUEST_UPDATE_JOB';
export const RECEIVE_UPDATE_JOB = 'glints/job/RECEIVE_UPDATE_JOB';

export const requestUpdateJob = createAction(REQUEST_UPDATE_JOB);
export const receiveUpdateJob = createAction(RECEIVE_UPDATE_JOB);

export function fetchJob(id, options) {
  return {
    [CALL_API]: {
      types: [Actions.JOB_REQUEST, Actions.JOB_SUCCESS, Actions.JOB_FAILURE],
      endpoint: `jobs/${id}`,
      schema: Schemas.JOB,
      options: merge(options, {
        params: {
          include: uniq(
            ['Skills', 'JobTitles', 'JobSalaries', 'Groups', 'Tags'].concat(
              options && options.params && options.params.include
                ? options.params.include.replace(/ /, '').split(',')
                : []
            )
          ).join(','),
        },
      }),
    },
  };
}

export function updateJob(jobId, values) {
  return async (dispatch, getState) => {
    dispatch(requestUpdateJob());
    try {
      await axios(getState().session.token).put(`/jobs/${jobId}`, {
        data: {
          ...values,
        },
      });
      dispatch(receiveUpdateJob());
      dispatch(updateJobEntity(jobId, values));
    } catch (err) {
      dispatch(receiveUpdateJob(err));
    }
  };
}
