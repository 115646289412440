import { orderBy } from 'lodash-es';
import { handleActions } from 'redux-actions';

import { Actions, RESET_APPLICATIONS } from '../actions/applications';
import { APPLICATIONS_PER_PAGE_LIMIT } from '../common/constants';

const initialState = {
  loading: false,
  error: null,
  value: [],
  lessThanLimit: false,
  selectedStatuses: [],
};

export default handleActions(
  {
    [Actions.JOB_APPLICATIONS_REQUEST]: state => ({
      ...state,
      loading: true,
      error: null,
      lessThanLimit: false,
    }),
    [Actions.JOB_APPLICATIONS_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      value: orderBy(
        [...new Set(state.value.concat(action.response.result))],
        ['createdAt'],
        ['desc']
      ),
      total: action.response.count,
      lessThanLimit:
        action.response.result.length < APPLICATIONS_PER_PAGE_LIMIT,
    }),
    [Actions.JOB_APPLICATIONS_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
      value: [],
      lessThanLimit: false,
    }),
    [RESET_APPLICATIONS]: () => initialState,
  },
  initialState
);
