import { handleActions } from 'redux-actions';

import { Actions } from './Constants';

const initialState = {
  loading: false,
  error: null,
  company: null,
  logo: {
    loading: false,
    s3Data: null,
    value: null,
    error: null,
  },
  banner: {
    s3Data: null,
    loading: false,
    value: null,
    error: null,
  },
  photos: {
    s3Data: null,
    list: [],
    error: null,
    loading: false,
  },
  document: {
    s3Data: null,
    error: null,
    value: null,
    loading: false,
  },
  descriptionEditorState: null,
  cultureEditorState: null,
  step: 'INFO',
  update: {
    loading: false,
    success: false,
    error: null,
  },
};

export default handleActions(
  {
    [Actions.COMPANY_REQUEST]: state => ({
      ...state,
      loading: true,
      error: null,
      company: null,
    }),
    [Actions.COMPANY_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      company: action.response.result,
    }),
    [Actions.COMPANY_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload || action.error,
      company: null,
    }),
    [Actions.UPDATE_COMPANY_BANNER_S3_DATA]: (state, action) => ({
      ...state,
      banner: {
        ...state.banner,
        s3Data: action.payload,
        loading: true,
        error: null,
      },
    }),
    [Actions.UPDATE_COMPANY_BANNER]: (state, action) => ({
      ...state,
      banner: {
        ...state.banner,
        value: action.payload,
        loading: false,
      },
    }),
    [Actions.ON_UPLOADING_COMPANY_BANNER]: state => ({
      ...state,
      banner: {
        ...state.banner,
        s3Data: null,
        value: null,
      },
    }),
    [Actions.ON_UPLOAD_COMPANY_BANNER_SUCCESS]: (state, action) => ({
      ...state,
      banner: {
        ...state.banner,
        value: action.payload,
        error: null,
        loading: false,
      },
    }),
    [Actions.ON_UPLOAD_COMPANY_BANNER_ERROR]: (state, action) => ({
      ...state,
      banner: {
        ...state.banner,
        error: action.payload,
        s3Data: null,
        value: null,
        loading: false,
      },
    }),
    [Actions.ON_REMOVE_COMPANY_BANNER]: state => ({
      ...state,
      banner: {
        ...state.banner,
        s3Data: null,
        value: null,
        error: null,
        loading: false,
      },
    }),
    [Actions.UPDATE_COMPANY_LOGO_S3_DATA]: (state, action) => ({
      ...state,
      logo: {
        ...state.logo,
        s3Data: action.payload,
        loading: true,
        error: null,
      },
    }),
    [Actions.UPDATE_COMPANY_LOGO]: (state, action) => ({
      ...state,
      logo: {
        ...state.logo,
        value: action.payload,
        loading: false,
      },
    }),
    [Actions.ON_UPLOADING_COMPANY_LOGO]: state => ({
      ...state,
      logo: {
        ...state.logo,
        s3Data: null,
        value: null,
      },
    }),
    [Actions.ON_UPLOAD_COMPANY_LOGO_SUCCESS]: (state, action) => ({
      ...state,
      logo: {
        ...state.logo,
        value: action.payload,
        error: null,
        loading: false,
      },
    }),
    [Actions.ON_UPLOAD_COMPANY_LOGO_ERROR]: (state, action) => ({
      ...state,
      logo: {
        ...state.logo,
        error: action.payload,
        s3Data: null,
        value: null,
        loading: false,
      },
    }),
    [Actions.ON_REMOVE_COMPANY_LOGO]: state => ({
      ...state,
      logo: {
        ...state.logo,
        s3Data: null,
        value: null,
        error: null,
        loading: false,
      },
    }),
    [Actions.UPDATE_COMPANY_PHOTO_S3_DATA]: (state, action) => ({
      ...state,
      photos: {
        ...state.photos,
        s3Data: action.payload,
        loading: true,
        error: null,
      },
    }),
    [Actions.ON_UPLOAD_COMPANY_PHOTO_ERROR]: (state, action) => ({
      ...state,
      photos: {
        ...state.photos,
        error: action.payload,
        s3Data: null,
        loading: false,
      },
    }),
    [Actions.ADD_TO_COMPANY_PHOTOS_LIST]: (state, action) => ({
      ...state,
      photos: {
        ...state.photos,
        list: [...state.photos.list, action.payload],
        s3Data: null,
        error: null,
        loading: false,
      },
    }),
    [Actions.ON_REMOVE_COMPANY_PHOTO]: (state, action) => ({
      ...state,
      photos: {
        ...state.photos,
        list: state.photos.list.filter(a => a.uid !== action.payload),
        s3Data: null,
        loading: false,
        error: null,
      },
    }),
    [Actions.ON_UPLOAD_COMPANY_PHOTO_SUCCESS]: state => ({
      ...state,
      photos: {
        ...state.photos,
        s3Data: null,
        loading: false,
        error: null,
      },
    }),
    [Actions.ON_UPLOADING_COMPANY_PHOTO]: state => ({
      ...state,
      photos: {
        ...state.photos,
        s3Data: null,
        error: null,
      },
    }),
    [Actions.SET_DESCRIPTION_EDITOR_STATE]: (state, action) => ({
      ...state,
      descriptionEditorState: action.payload,
    }),
    [Actions.SET_CULTURE_EDITOR_STATE]: (state, action) => ({
      ...state,
      cultureEditorState: action.payload,
    }),
    [Actions.UPDATE_COMPANY_EDIT_STEP]: (state, action) => ({
      ...state,
      step: action.payload,
    }),
    [Actions.RESET_COMPANY_EDIT_STATE]: () => initialState,
    [Actions.UPDATE_COMPANY_REQUEST]: state => ({
      ...state,
      update: {
        ...state.update,
        loading: true,
        success: false,
        error: null,
      },
    }),
    [Actions.UPDATE_COMPANY_SUCCESS]: state => ({
      ...state,
      update: {
        ...state.update,
        loading: false,
        success: true,
        error: null,
      },
    }),
    [Actions.UPDATE_COMPANY_FAILURE]: (state, action) => ({
      ...state,
      update: {
        ...state.update,
        loading: false,
        success: false,
        error: action.payload || action.error,
      },
    }),
    [Actions.UPLOAD_COMPANY_DOCUMENT_SUCCESS]: state => ({
      ...state,
      document: {
        ...state.document,
        loading: false,
        error: null,
        success: true,
      },
    }),
    [Actions.UPLOAD_COMPANY_DOCUMENT_FAILURE]: state => ({
      ...state,
      document: {
        ...state.document,
        loading: false,
        error: true,
      },
    }),
    [Actions.REMOVE_COMPANY_DOCUMENT]: state => ({
      ...state,
      document: {
        ...state.document,
        loading: false,
        error: null,
      },
    }),
  },
  initialState
);
