import React, { useState } from 'react';

import RejectionReasonPreferenceModal from '../RejectionReasonPreferenceModal';

export default function useRejectionReasonPreferenceModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openRejectionReasonPreferenceModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return {
    rejectionReasonPreferenceModal: isModalOpen && (
      <RejectionReasonPreferenceModal onClose={handleCloseModal} />
    ),
    openRejectionReasonPreferenceModal,
  } as const;
}
