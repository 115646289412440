import React from 'react';
import { Menu } from 'antd';
import type { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getSessionCompany } from '../../selectors/session';
import { getUserRole } from '../../selectors/user';
import { getPermissions } from '../Platform';
import type { MenuItem } from '../Platform/Constants';
import { Anchor, Item, Link, SubMenuTitle } from './DropdownItem.sc';
import { disableLink, isAbsoluteURL, renderItemLabel } from './navigationUtils';

const DropdownItem: React.FC<DropdownItemProps> = ({
  items,
  ...menuItemProps
}) => {
  const intl = useIntl();
  const role = useSelector(getUserRole);
  const company = useSelector(getSessionCompany);
  const permissions = useSelector(getPermissions);

  return (
    <>
      {items.map(item => {
        if (item.to && isAbsoluteURL(item.to)) {
          return (
            <Item
              key={item.value}
              disabled={
                item.disabled ||
                (item.canBeDisabled && disableLink(role, company, permissions))
              }
              {...menuItemProps}
            >
              <Anchor
                key={item.value}
                href={item.to}
                target="_blank"
                rel="noreferrer"
              >
                {renderItemLabel(intl, item.label)}
              </Anchor>
            </Item>
          );
        } else if (item.children) {
          return (
            <Menu.SubMenu
              key={item.value}
              title={
                <SubMenuTitle>{renderItemLabel(intl, item.label)}</SubMenuTitle>
              }
              {...menuItemProps}
            >
              {item.children.map(
                child =>
                  child.to && (
                    <Item key={child.value} {...menuItemProps}>
                      <Link
                        to={child.to}
                        disabled={
                          child.disabled ||
                          (child.optional &&
                            disableLink(role, company, permissions))
                        }
                      >
                        {renderItemLabel(intl, child.label)}
                      </Link>
                    </Item>
                  )
              )}
            </Menu.SubMenu>
          );
        } else {
          return (
            <Item
              key={item.value}
              disabled={
                item.disabled ||
                (item.canBeDisabled && disableLink(role, company, permissions))
              }
              {...menuItemProps}
            >
              <Link
                to={item.to}
                disabled={
                  item.disabled ||
                  (item.canBeDisabled &&
                    disableLink(role, company, permissions))
                }
              >
                {renderItemLabel(intl, item.label)}
              </Link>
            </Item>
          );
        }
      })}
    </>
  );
};

type DropdownItemProps = {
  items: MenuItem[];
} & MenuItemProps;

export default DropdownItem;
