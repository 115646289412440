import React, { Suspense } from 'react';
import { Badge, Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { defineMessages, FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import ErrorBoundaryWithCrashReporting from '../../../components/Error/ErrorBoundaryWithCrashReporting';
import GlintsVIPComponentWrapper from '../../../components/GlintsVIPComponentWrapper';
import { useGlintsVIPMembershipInfo } from '../../../components/GlintsVIPMembershipInfo/hooks/useGlintsVIPMembershipInfo';

type VIPMembershipStatusBadgeProps = {
  isVipUser: boolean;
};
const vipUserStyles = css`
  background: linear-gradient(0deg, #ffa81c 0%, #ffcf2c 100%);
`;
const freeUserStyles = css`
  background: linear-gradient(0deg, #2d2d2d 0%, #666 100%);
`;
const StyledBadge = styled(Badge)<VIPMembershipStatusBadgeProps>`
  ${props => (props.isVipUser ? vipUserStyles : freeUserStyles)};
  flex-shrink: 0;
`;

const messages = defineMessages({
  vipUser: {
    id: 'text-vip-user',
    defaultMessage: 'VIP Member',
  },
  freeUser: {
    id: 'text-free-user',
    defaultMessage: 'Free User',
  },
});

function VIPMembershipStatusBadgeComponent() {
  const {
    vipMembershipInfo: { isActive },
  } = useGlintsVIPMembershipInfo('cache-first');
  return (
    <StyledBadge isVipUser={isActive}>
      <Typography variant="overline" color={Neutral.B100}>
        <FormattedMessage
          {...(isActive ? messages.vipUser : messages.freeUser)}
        />
      </Typography>
    </StyledBadge>
  );
}

export default function VIPMembershipStatusBadge() {
  return (
    <GlintsVIPComponentWrapper>
      <ErrorBoundaryWithCrashReporting fallback={null}>
        <Suspense fallback={null}>
          <VIPMembershipStatusBadgeComponent />
        </Suspense>
      </ErrorBoundaryWithCrashReporting>
    </GlintsVIPComponentWrapper>
  );
}
