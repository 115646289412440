import { getFeature } from '../../selectors/features';
import { UnleashFeatureNames } from '../Unleash/featureNames';

export const getLoginLoading = state => state.login.loading;
export const getLoginSuccess = state => state.login.success;
export const getLoginError = state => state.login.error;

export const getFacebookIsOpen = state => state.login.facebook.isOpen;
export const getFacebookLoading = state => state.login.facebook.loading;
export const getFacebookSuccess = state => state.login.facebook.success;
export const getFacebookError = state => state.login.facebook.error;

export const getLinkedInCsrfToken = state => state.login.linkedIn.csrfToken;
export const getLinkedInIsOpen = state => state.login.linkedIn.isOpen;
export const getLinkedInLoading = state => state.login.linkedIn.loading;
export const getLinkedInSuccess = state => state.login.linkedIn.success;
export const getLinkedInError = state => state.login.linkedIn.error;

export const getMfaTokenExpired = state =>
  state.login.multiFactorAuthentication.isMfaTokenExpired;
export const getMfaRequired = state =>
  state.login.multiFactorAuthentication.isRequired;
export const getMfaToken = state =>
  state.login.multiFactorAuthentication.mfaToken;
export const getMfaDetails = state =>
  state.login.multiFactorAuthentication.mfaDetails;

export const getIsNewLoginVerificationEnabled = state =>
  getFeature(state, UnleashFeatureNames.empIsNewLoginVerificationEnabled);
