import React from 'react';
import { InlineError } from 'glints-aries/es/@next';
import { Input, InputProps } from 'glints-aries/es/@next/Input/Input';

import { StyledTextInputContainer } from './SignUpTextInput.sc';

type SignupTextInputProps = {
  errorText?: string;
} & InputProps;

const SignupTextInput = (props: SignupTextInputProps) => (
  <StyledTextInputContainer>
    <Input {...props} />
    <If condition={Boolean(props.errorText)}>
      <InlineError text={String(props.errorText)} />
    </If>
  </StyledTextInputContainer>
);

export default SignupTextInput;
