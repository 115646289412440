import {
  gql,
  SuspenseQueryHookFetchPolicy,
  useSuspenseQuery,
} from '@apollo/client';
import { useSelector } from 'react-redux';

import { getSessionCompanyId } from '../../selectors/session';

const query = gql`
  query getFullProfileAccessBalance($input: GetJobProductInventoryInput!) {
    getFullProfileAccessBalance: getJobProductInventory(input: $input) {
      productId
      jobId
      amount
      initialJobProductBalance
    }
  }
`;

export const JobProductType = {
  FULL_PROFILE_ACCESS: 'FULL_PROFILE_ACCESS',
} as const;

export type JobProductType =
  (typeof JobProductType)[keyof typeof JobProductType];

type QueryVariables = {
  input: {
    companyId: string;
    jobId: string;
    productType: JobProductType;
  };
};

type QueryResult = {
  getFullProfileAccessBalance: {
    productId: string;
    jobId: string;
    amount: number;
    initialJobProductBalance: number;
  };
};

export default function useGetFullProfileAccessBalance(
  params: Pick<QueryVariables['input'], 'jobId'> & {
    queryKey?: string;
    fetchPolicy?: SuspenseQueryHookFetchPolicy;
  }
) {
  const {
    jobId,
    fetchPolicy = 'cache-and-network',
    queryKey = 'getFullProfileAccessBalance',
  } = params;
  const companyId = useSelector(getSessionCompanyId);
  const { data } = useSuspenseQuery<QueryResult, QueryVariables>(query, {
    variables: {
      input: {
        companyId,
        jobId,
        productType: JobProductType.FULL_PROFILE_ACCESS,
      },
    },
    queryKey: queryKey,
    fetchPolicy: fetchPolicy,
  });

  return {
    currentBalance: data.getFullProfileAccessBalance.amount ?? 0,
    initialBalance:
      data.getFullProfileAccessBalance.initialJobProductBalance ?? 0,
  };
}
