import {
  companyStatusQueryParam,
  reportRedirectStatuses,
} from '../modules/CompanyReportStatus/constants';
import { CompanyStatus } from './constants';
import { ROUTES } from './routes';

export const httpForbiddenErrorCode = 403;

export const handleFraudCompanyError = (
  errorCode: number,
  companyStatus: CompanyStatus.BLOCKED | CompanyStatus.UNDER_INVESTIGATION
) => {
  if (
    errorCode === httpForbiddenErrorCode &&
    reportRedirectStatuses.includes(companyStatus)
  ) {
    const urlParams = new URLSearchParams(window.location.search);
    if (
      window.location.pathname.startsWith(`/${ROUTES.companyReportStatus}`) &&
      urlParams.get(companyStatusQueryParam) === companyStatus
    ) {
      return;
    }
    window.location.href = `/${ROUTES.companyReportStatus}?${companyStatusQueryParam}=${companyStatus}`;
  }
};
