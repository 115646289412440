import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import joinToArray from '../common/filters/joinToArray';

/**
 * Like Array.prototype.join for React nodes! Falsy children are removed
 * before the join.
 */
export const NodesJoin = ({ separator, children }) => (
  <For
    each="child"
    of={joinToArray(
      children.filter(x => x),
      separator
    )}
    index="index"
  >
    <Fragment key={index}>{child}</Fragment>
  </For>
);
NodesJoin.propTypes = {
  separator: PropTypes.node.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default NodesJoin;
