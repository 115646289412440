/* eslint-disable max-lines, jsx-control-statements/jsx-use-if-tag */
import React, { ReactNode } from 'react';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  MailIcon,
  TwitterIcon,
} from 'glints-aries';
import { FormattedMessage } from 'react-intl';

import { EA_LICENCE_NUMBER } from '../../common/constants';
import config from '../../config';
import DefaultLinksSection from './components/LinksSection/LinksSection';
import { CountrySelectionParams } from './components/LinksSection/types';
import {
  Caption,
  ConnectedSection,
  Description,
  DescriptionSection,
  DisclaimerPart,
  Footer,
  FooterWrapper,
  LicenseWrapper,
  Link,
  Logo,
  RegionalHeading,
  RegionalSection,
  SocialMediaWrapper,
} from './FooterGlints.sc';
import RegionalLinks from './RegionalLinks';
import useShowFooter from './useShowFooter';
import { resolveURL } from './utils';

const renderSocialMediaLinks = (defs: SocialMediaLinkDefinition[]) =>
  defs.map(def => {
    const url = resolveURL(def, config.COUNTRY);
    if (!url) {
      return;
    }

    return (
      <Link href={url} isExternal={true} key={def.key}>
        {def.icon}
      </Link>
    );
  });

type SocialMediaLinkDefinition = CountrySelectionParams & {
  key: string;
  icon: React.ReactNode;
};

const socialMediaLinks: SocialMediaLinkDefinition[] = [
  {
    key: 'instagram',
    defaultURL: 'https://www.instagram.com/glints.talenthub',
    perCountryURL: {
      id: 'https://www.instagram.com/glintsid',
      vn: 'https://www.instagram.com/glints.vn',
    },
    excludedCountries: ['tw'],
    icon: <InstagramIcon />,
  },
  {
    key: 'twitter',
    defaultURL: 'https://twitter.com/glintssingapore',
    perCountryURL: {
      id: 'https://twitter.com/glintsid',
    },
    excludedCountries: ['tw', 'vn'],
    icon: <TwitterIcon />,
  },
  {
    key: 'facebook',
    defaultURL: 'https://www.facebook.com/glintssingapore',
    perCountryURL: {
      id: 'https://www.facebook.com/glintsindonesia',
      vn: 'https://facebook.com/glintsvietnam',
      tw: 'https://www.facebook.com/glintstaiwan',
    },
    icon: <FacebookIcon />,
  },
  {
    key: 'linkedIn',
    defaultURL: 'https://www.linkedin.com/showcase/glints-for-employers',
    icon: <LinkedinIcon />,
  },
  {
    key: 'mail',
    defaultURL: 'mailto:employers@glints.com',
    perCountryURL: {
      tw: 'mailto:team-marketing@glints.com',
    },
    icon: <MailIcon />,
  },
];

const year = new Date().getFullYear();

const { COUNTRY } = config;
const isCountryVN = COUNTRY === 'vn';
const isCountryID = COUNTRY === 'id';

const FooterGlints: React.FC<Record<string, never>> = () => {
  const showFooter = useShowFooter();

  return showFooter ? (
    <FooterWrapper>
      <Footer>
        <Logo src="/images/logo-dark.png" />
        <DescriptionSection>
          <FormattedMessage
            id="footer.regional.heading"
            defaultMessage="Glints Asia Pacific"
          >
            {(message: ReactNode[]) => (
              <RegionalHeading>{message}</RegionalHeading>
            )}
          </FormattedMessage>
          <RegionalSection>
            <RegionalLinks />
          </RegionalSection>
          <Description>
            <>
              <FormattedMessage
                id="text-footer-description"
                defaultMessage="Glints is the leading talent ecosystem in Southeast Asia. Our mission is to enable the 120 million professionals in the region to grow their careers and empower organizations to hire the right talent from anywhere in Southeast Asia. Officially launched in 2015 in Singapore, Glints has empowered more than 5 million talent and 60,000 organizations to realize their human potential. Today, we stand at the forefront of human capital empowerment as the fastest-growing startup in the career development and talent recruitment space. Glints currently operates in Indonesia, Malaysia, Singapore, Vietnam, Philippines, China, Hong Kong and Taiwan, China."
              />
              <If condition={isCountryID}>
                <p>
                  <br />
                  <span style={{ fontWeight: 'bold' }}>
                    <FormattedMessage
                      id="text-consumer-complaints"
                      defaultMessage="Consumer Complaints"
                    />
                  </span>
                  <br />
                  <br />
                  <FormattedMessage
                    id="text-glints-id-company-address"
                    defaultMessage="Company Address: Standard Chartered Tower, 9th Floor, Prof. DR. Satrio Street number 164, Karet Semanggi Subdistrict, Setiabudi District, South Jakarta City, DKI Jakarta Province, 12930"
                  />
                  <br />
                  <br />
                  <FormattedMessage
                    id="text-glints-email-address"
                    defaultMessage="E-mail Address: <link>hi@glints.com</link>"
                    values={{
                      link: (text: ReactNode) => (
                        <a
                          href="mailto:hi@glints.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {text}
                        </a>
                      ),
                    }}
                  />
                  <br />
                  <br />
                  <FormattedMessage
                    id="text-ditjen-pktn"
                    defaultMessage="Directorate General of Consumer Protection and Orderly Commerce, Whatsapp Number: 0853-1111-1010"
                  />
                </p>
              </If>
            </>
          </Description>
        </DescriptionSection>
        <ConnectedSection>
          <SocialMediaWrapper>
            {renderSocialMediaLinks(socialMediaLinks)}
          </SocialMediaWrapper>
        </ConnectedSection>
        <DefaultLinksSection />
        <LicenseWrapper>
          <FormattedMessage
            tagName={Caption}
            id="components.Footer.FooterGlints.year.glints"
            defaultMessage="&copy; {year} Glints"
            values={{
              year,
            }}
          />
          <Choose>
            <When condition={isCountryVN}>
              <FormattedMessage
                tagName={DisclaimerPart}
                id="components.Footer.FooterGlints.disclaimer.vietnam.title"
                defaultMessage="CÔNG TY TNHH GLINTS VIỆT NAM"
              />
              <FormattedMessage
                tagName={DisclaimerPart}
                id="components.Footer.FooterGlints.disclaimer.vietnam.license"
                defaultMessage="Mã số Doanh nghiệp: 0316168834 theo Giấy chứng nhận đăng ký doanh nghiệp do Sở Kế hoạch và Đầu tư Thành phố Hồ Chí Minh cấp lần đầu ngày 27/02/2020."
              />
              <FormattedMessage
                tagName={DisclaimerPart}
                id="components.Footer.FooterGlints.disclaimer.vietnam.address"
                defaultMessage="Địa chỉ: Tầng 6, Số 100, Đường Nguyễn Thị Minh Khai, Phường 06, Quận 3, Thành phố Hồ Chí Minh, Việt Nam"
              />
            </When>
            <Otherwise>
              <FormattedMessage
                tagName={Caption}
                id="components.Footer.FooterGlints.ea.license.no"
                defaultMessage="EA License No: {eaLicenseNo}"
                values={{
                  eaLicenseNo: EA_LICENCE_NUMBER,
                }}
              />
            </Otherwise>
          </Choose>
        </LicenseWrapper>
      </Footer>
    </FooterWrapper>
  ) : (
    <></>
  );
};

export default FooterGlints;
