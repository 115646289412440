import { cloneDeep } from 'lodash-es';
import { createAction } from 'redux-actions';

import axios from '../../common/axios-ts';
import config from '../../config';
import CALL_API from '../../middleware/api_key';
import { Schemas } from '../../middleware/schemas';
import { getSessionToken } from '../../selectors/session';
import { getUserPreferences } from '../../selectors/user';
import { getLinkedInCsrfToken, getProfilePicValue } from './Selectors';

export const Actions = {
  SET_ACTIVE_TAB: 'glints/settings/SET_ACTIVE_TAB',

  SET_PROFILE_PIC_VALUE: 'glints/settings/SET_PROFILE_PIC_VALUE',
  UPDATE_PROFILE_PIC_S3_DATA: 'glints/settings/UPDATE_PROFILE_PIC_S3_DATA',
  HANDLE_UPLOAD_PROFILE_PIC_LOADING:
    'glints/settings/HANDLE_UPLOAD_PROFILE_PIC_LOADING',
  HANDLE_UPLOAD_PROFILE_PIC_SUCCESS:
    'glints/settings/HANDLE_UPLOAD_PROFILE_PIC_SUCCESS',
  HANDLE_UPLOAD_PROFILE_PIC_ERROR:
    'glints/settings/HANDLE_UPLOAD_PROFILE_PIC_ERROR',

  SAVE_BASIC_INFO_REQUEST: 'glints/settings/SAVE_BASIC_INFO_REQUEST',
  SAVE_BASIC_INFO_SUCCESS: 'glints/settings/SAVE_BASIC_INFO_SUCCESS',
  SAVE_BASIC_INFO_FAILURE: 'glints/settings/SAVE_BASIC_INFO_FAILURE',

  UPDATE_EMAIL_REQUEST: 'glints/settings/UPDATE_EMAIL_REQUEST',
  UPDATE_EMAIL_SUCCESS: 'glints/settings/UPDATE_EMAIL_SUCCESS',
  UPDATE_EMAIL_FAILURE: 'glints/settings/UPDATE_EMAIL_FAILURE',
  RESET_UPDATE_EMAIL_STATE: 'glints/settings/RESET_UPDATE_EMAIL_STATE',

  UPDATE_PASSWORD_REQUEST: 'glints/settings/UPDATE_PASSWORD_REQUEST',
  UPDATE_PASSWORD_SUCCESS: 'glints/settings/UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE: 'glints/settings/UPDATE_PASSWORD_FAILURE',
  RESET_UPDATE_PASSWORD_STATE: 'glints/settings/RESET_UPDATE_PASSWORD_STATE',

  IDENTITIES_REQUEST: 'glints/settings/IDENTITIES_REQUEST',
  IDENTITIES_SUCCESS: 'glints/settings/IDENTITIES_SUCCESS',
  IDENTITIES_FAILURE: 'glints/settings/IDENTITIES_FAILURE',

  OPEN_FACEBOOK_DIALOG: 'glints/settings/OPEN_FACEBOOK_DIALOG',
  CLOSE_FACEBOOK_DIALOG: 'glints/settings/CLOSE_FACEBOOK_DIALOG',
  RESET_LINK_FACEBOOK_STATE: 'glints/settings/RESET_LINK_FACEBOOK_STATE',

  LINK_FACEBOOK_REQUEST: 'glints/settings/LINK_FACEBOOK_REQUEST',
  LINK_FACEBOOK_SUCCESS: 'glints/settings/LINK_FACEBOOK_SUCCESS',
  LINK_FACEBOOK_FAILURE: 'glints/settings/LINK_FACEBOOK_FAILURE',

  OPEN_LINKEDIN_DIALOG: 'glints/settings/OPEN_LINKEDIN_DIALOG',
  CLOSE_LINKEDIN_DIALOG: 'glints/settings/CLOSE_LINKEDIN_DIALOG',
  RESET_LINK_LINKEDIN_STATE: 'glints/settings/RESET_LINK_LINKEDIN_STATE',

  LINK_LINKEDIN_REQUEST: 'glints/settings/LINK_LINKEDIN_REQUEST',
  LINK_LINKEDIN_SUCCESS: 'glints/settings/LINK_LINKEDIN_SUCCESS',
  LINK_LINKEDIN_FAILURE: 'glints/settings/LINK_LINKEDIN_FAILURE',

  UNLINK_IDENTITY_REQUEST: 'glints/settings/UNLINK_IDENTITY_REQUEST',
  UNLINK_IDENTITY_SUCCESS: 'glints/settings/UNLINK_IDENTITY_SUCCESS',
  UNLINK_IDENTITY_FAILURE: 'glints/settings/UNLINK_IDENTITY_FAILURE',

  SAVE_NOTIFICATIONS_REQUEST: 'glints/settings/SAVE_NOTIFICATIONS_REQUEST',
  SAVE_NOTIFICATIONS_SUCCESS: 'glints/settings/SAVE_NOTIFICATIONS_SUCCESS',
  SAVE_NOTIFICATIONS_FAILURE: 'glints/settings/SAVE_NOTIFICATIONS_FAILURE',

  RESET_NOTIFICATIONS_STATE: 'glints/settings/RESET_NOTIFICATIONS_STATE',
};

export const setActiveTab = createAction(Actions.SET_ACTIVE_TAB);

export const setProfilePicValue = createAction(Actions.SET_PROFILE_PIC_VALUE);
export const updateProfilePicS3Data = createAction(
  Actions.UPDATE_PROFILE_PIC_S3_DATA
);
export const handleUploadProfilePicLoading = createAction(
  Actions.HANDLE_UPLOAD_PROFILE_PIC_LOADING
);
export const handleUploadProfilePicSuccess = createAction(
  Actions.HANDLE_UPLOAD_PROFILE_PIC_SUCCESS
);
export const handleUploadProfilePicError = createAction(
  Actions.HANDLE_UPLOAD_PROFILE_PIC_ERROR
);

export const resetUpdateEmailState = createAction(
  Actions.RESET_UPDATE_EMAIL_STATE
);

export const resetUpdatePasswordState = createAction(
  Actions.RESET_UPDATE_PASSWORD_STATE
);

export const openFacebookDialog = createAction(Actions.OPEN_FACEBOOK_DIALOG);
export const closeFacebookDialog = createAction(Actions.CLOSE_FACEBOOK_DIALOG);
export const resetLinkFacebookState = createAction(
  Actions.RESET_LINK_FACEBOOK_STATE
);

export const openLinkedInDialog = createAction(
  Actions.OPEN_LINKEDIN_DIALOG,
  csrfToken => ({ csrfToken })
);
export const closeLinkedInDialog = createAction(Actions.CLOSE_LINKEDIN_DIALOG);
export const resetLinkLinkedInState = createAction(
  Actions.RESET_LINK_LINKEDIN_STATE
);

export const unlinkIdentityRequest = createAction(
  Actions.UNLINK_IDENTITY_REQUEST
);
export const unlinkIdentitySuccess = createAction(
  Actions.UNLINK_IDENTITY_SUCCESS
);
export const unlinkIdentityFailure = createAction(
  Actions.UNLINK_IDENTITY_FAILURE
);

export const resetNotificationsState = createAction(
  Actions.RESET_NOTIFICATIONS_STATE
);

export function saveBasicInfo(values) {
  return async (dispatch, getState) => {
    const profilePic = getProfilePicValue(getState());
    if (profilePic) {
      values.profilePic = profilePic;
    }

    await dispatch({
      [CALL_API]: {
        types: [
          Actions.SAVE_BASIC_INFO_REQUEST,
          Actions.SAVE_BASIC_INFO_SUCCESS,
          Actions.SAVE_BASIC_INFO_FAILURE,
        ],
        endpoint: 'me',
        schema: Schemas.USER,
        method: 'put',
        options: {
          data: values,
        },
      },
    });
  };
}

export function updateEmail(newEmail) {
  return async dispatch => {
    await dispatch({
      [CALL_API]: {
        types: [
          Actions.UPDATE_EMAIL_REQUEST,
          Actions.UPDATE_EMAIL_SUCCESS,
          Actions.UPDATE_EMAIL_FAILURE,
        ],
        endpoint: 'me',
        schema: Schemas.USER,
        method: 'put',
        options: {
          data: {
            email: newEmail,
          },
        },
      },
    });
  };
}

export function updatePassword(newPassword) {
  return async dispatch => {
    await dispatch({
      [CALL_API]: {
        types: [
          Actions.UPDATE_PASSWORD_REQUEST,
          Actions.UPDATE_PASSWORD_SUCCESS,
          Actions.UPDATE_PASSWORD_FAILURE,
        ],
        endpoint: 'me',
        schema: Schemas.USER,
        method: 'put',
        options: {
          data: {
            password: newPassword,
          },
        },
      },
    });
  };
}

export function fetchIdentities() {
  return async dispatch => {
    await dispatch({
      [CALL_API]: {
        types: [
          Actions.IDENTITIES_REQUEST,
          Actions.IDENTITIES_SUCCESS,
          Actions.IDENTITIES_FAILURE,
        ],
        endpoint: 'identities',
        schema: Schemas.IDENTITY_ARRAY,
      },
    });
  };
}

export function linkFacebook(code) {
  return async dispatch => {
    await dispatch({
      [CALL_API]: {
        types: [
          Actions.LINK_FACEBOOK_REQUEST,
          Actions.LINK_FACEBOOK_SUCCESS,
          Actions.LINK_FACEBOOK_FAILURE,
        ],
        endpoint: 'oauth2/facebook',
        schema: Schemas.IDENTITY,
        method: 'post',
        options: {
          code,
          clientId: config.FACEBOOK_APPID,
          apiClientId: config.CLIENT_ID,
          redirectUri: `${
            document.getElementsByTagName('base')[0].href
          }oauth2/facebook`,
        },
      },
    });
  };
}

export function linkLinkedIn(code) {
  return async (dispatch, getState) => {
    await dispatch({
      [CALL_API]: {
        types: [
          Actions.LINK_LINKEDIN_REQUEST,
          Actions.LINK_LINKEDIN_SUCCESS,
          Actions.LINK_LINKEDIN_FAILURE,
        ],
        endpoint: 'oauth2/linkedin',
        schema: Schemas.IDENTITY,
        method: 'post',
        options: {
          code,
          clientId: config.LINKEDIN_APPID,
          apiClientId: config.CLIENT_ID,
          redirectUri: `${
            document.getElementsByTagName('base')[0].href
          }oauth2/linkedin`,
          csrfToken: getLinkedInCsrfToken(getState()),
          role: 'COMPANY',
        },
      },
    });
  };
}

export function unlinkIdentity(identityId) {
  return async (dispatch, getState) => {
    try {
      dispatch(unlinkIdentityRequest(identityId));
      await axios(getSessionToken(getState())).delete(
        `/identities/${identityId}`
      );
      dispatch(unlinkIdentitySuccess(identityId));
    } catch (err) {
      dispatch(unlinkIdentityFailure(err));
    }
  };
}

export function saveUserNotifications(values) {
  return async (dispatch, getState) => {
    const preferences = cloneDeep(getUserPreferences(getState())) || {};
    preferences.notifications = preferences.notifications || {};

    const keys = Object.keys(values);
    keys.forEach(key => {
      const [channel, category] = key.split('-');
      if (!preferences.notifications[channel]) {
        preferences.notifications[channel] = {};
      }
      preferences.notifications[channel][category] = values[key];
    });

    await dispatch({
      [CALL_API]: {
        types: [
          Actions.SAVE_NOTIFICATIONS_REQUEST,
          Actions.SAVE_NOTIFICATIONS_SUCCESS,
          Actions.SAVE_NOTIFICATIONS_FAILURE,
        ],
        endpoint: 'me',
        schema: Schemas.USER,
        method: 'put',
        options: {
          data: {
            preferences: preferences,
          },
        },
      },
    });
  };
}
