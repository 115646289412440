import { ApolloError, gql, useMutation } from '@apollo/client';
import { noop } from 'lodash-es';
import { useSelector } from 'react-redux';

import { getSessionCompanyId } from '../../../selectors/session';
import {
  GetVIPMembershipInfoResponse,
  useWriteVIPMembershipInfoToCache,
} from '../../GlintsVIPMembershipInfo/hooks/useGetVIPMembershipInfo';

const query = gql`
  mutation claimCompanyProductFreeTrialOffer(
    $input: ClaimCompanyProductFreeTrialOfferInput!
  ) {
    claimCompanyProductFreeTrialOffer(input: $input) {
      ...vipMembershipInfoFragment
    }
  }
`;

type QueryInput = {
  input: {
    companyId: string;
    companyProductFreeTrialOfferId: string;
  };
};

type QueryOutput = {
  claimCompanyProductFreeTrialOffer: NonNullable<
    GetVIPMembershipInfoResponse['getVIPMembershipInfo']
  >;
};

export default function useClaimCompanyProductFreeTrialOffer() {
  const [
    mutation,
    { loading: isClaimingOffer, error: claimOfferError, client, reset },
  ] = useMutation<QueryOutput, QueryInput>(query);
  const companyId = useSelector(getSessionCompanyId);
  const { writeVIPMembershipInfoToCache } = useWriteVIPMembershipInfoToCache();

  const claimCompanyProductFreeTrialOffer = (props: {
    companyProductFreeTrialOfferId: string;
    onSuccess?: (
      data: QueryOutput['claimCompanyProductFreeTrialOffer']
    ) => void;
    onError?: (error: ApolloError) => void;
  }) => {
    const { companyProductFreeTrialOfferId } = props;
    mutation({
      variables: {
        input: {
          companyId,
          companyProductFreeTrialOfferId,
        },
      },
      onCompleted: data => {
        client.refetchQueries({
          include: [
            'getCompanyProductFreeTrialOffer',
            'getProductUsageRecords',
          ],
        });
        writeVIPMembershipInfoToCache(data.claimCompanyProductFreeTrialOffer);
        props.onSuccess?.(data.claimCompanyProductFreeTrialOffer);
      },
      onError: props.onError || noop,
    });
  };

  return {
    isClaimingOffer,
    claimOfferError,
    claimCompanyProductFreeTrialOffer,
    reset,
  } as const;
}
