import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { FormattedMessage } from 'react-intl';
import Popout from 'react-popout';

import config from '../../config';

const styles = {
  body: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Poppins, sans-serif',
  },
};

let popout;

export const LinkedInAuthDialog = props => {
  const Component = props => {
    const redirectUri = `${
      document.getElementsByTagName('base')[0].href
    }oauth2/linkedin`;
    const { csrfToken } = props;
    const dialogUrl =
      'https://www.linkedin.com/oauth/v2/authorization?' +
      `client_id=${config.LINKEDIN_APPID}&` +
      `redirect_uri=${encodeURIComponent(redirectUri)}&` +
      `scope=${encodeURIComponent('r_emailaddress r_liteprofile')}&` +
      `response_type=code&state=${csrfToken}`;
    const options = {
      menubar: 'off',
      toolbar: 'off',
      width: '527',
      height: '582',
    };

    const dialogWindow = {
      addEventListener: window.addEventListener.bind(window),
      removeEventListener: window.removeEventListener.bind(window),
      open: (...args) => {
        const openedWindow = window.open(...args);
        if (!openedWindow) {
          return;
        }
        const interval = setInterval(() => {
          // When the window is closed, trigger the close event.
          if (openedWindow.closed) {
            clearInterval(interval);
            props.onClosing();
          }

          try {
            const query = parse(openedWindow.location.search);

            // If authorization was successful, code is populated.
            // Otherwise, error is populated.
            if (query.code || query.error) {
              clearInterval(interval);
              popout.closeWindow();
              props.onClosing();

              // Trigger the handler if authorization was successful.
              if (query.code) {
                props.onReceiveAuthCode(query.code);
              }
            }
          } catch (e) {
            if (e.name === 'SecurityError') {
              // Ignore attempted cross-domain access.
            } else {
              throw e;
            }
          }
        }, 100);
        return openedWindow;
      },
    };

    return (
      <If condition={props.linkedInDialogOpen}>
        <Popout
          ref={ref => (popout = ref)}
          title="Login with LinkedIn"
          url={dialogUrl}
          options={options}
          window={dialogWindow}
        >
          <div style={styles.body}>
            {/* Hack: The body is created by react-popout, so we need to style it this way */}
            <style>{'body { margin: 0; padding: 0; }'}</style>
            <FormattedMessage
              id="components.dialog.LinkedinAuthDialog.redirecting.to.linkedin"
              defaultMessage="Redirecting to LinkedIn&hellip;"
            />
          </div>
        </Popout>
      </If>
    );
  };

  return <Component {...props} />;
};
LinkedInAuthDialog.propTypes = {
  onClosing: PropTypes.func.isRequired,
  onReceiveAuthCode: PropTypes.func.isRequired,
  linkedInDialogOpen: PropTypes.bool.isRequired,
  csrfToken: PropTypes.string,
};
