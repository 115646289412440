import React from 'react';

import { TrackingService } from '../../../common/tracking/trackingService';
import { StyledButton } from './ButtonWithEventTracker.sc';

interface ButtonWithEventTrackerProps {
  text: string;
  sensorsDataEventName?: string;
}

const ButtonWithEventTracker = ({
  text,
  sensorsDataEventName,
}: ButtonWithEventTrackerProps) => {
  const handleClick = () => {
    if (sensorsDataEventName) {
      TrackingService.logEvent(sensorsDataEventName);
    }
  };
  return <StyledButton onClick={handleClick}>{text}</StyledButton>;
};

export default ButtonWithEventTracker;
