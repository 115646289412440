import CALL_API from '../middleware/api_key';
import { Schemas } from '../middleware/schemas';

export const Actions = {
  COMPANY_JOBS_REQUEST: 'glints/jobs/COMPANY_JOBS_REQUEST',
  COMPANY_JOBS_SUCCESS: 'glints/jobs/COMPANY_JOBS_SUCCESS',
  COMPANY_JOBS_FAILURE: 'glints/jobs/COMPANY_JOBS_FAILURE',
};

export const REMOVE_COMPANY_JOB_ID_FROM_STATE =
  'glints/jobs/REMOVE_COMPANY_JOB_ID_FROM_STATE';

export const UPDATE_COMPANY_JOBS_VALUE =
  'glints/jobs/UPDATE_COMPANY_JOBS_VALUE';

export function fetchCompanyJobs(companyId, options, limit = 0) {
  return async dispatch => {
    await dispatch({
      [CALL_API]: {
        types: [
          Actions.COMPANY_JOBS_REQUEST,
          Actions.COMPANY_JOBS_SUCCESS,
          Actions.COMPANY_JOBS_FAILURE,
        ],
        endpoint: `companies/${companyId}/jobs`,
        schema: Schemas.JOB_ARRAY,
        options: {
          ...options,
          params: {
            ...(options ? options.params : {}),
            order: 'updatedAt DESC',
            include: 'Sites, jobSalaries, Company, Groups',
            isPublic: true,
            limit,
          },
        },
      },
    });
  };
}
