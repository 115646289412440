import { createAction } from 'redux-actions';

import CALL_API from '../../middleware/api_key';

export const Actions = {
  SET_FOOTER_HEIGHT: 'glints/application/SET_FOOTER_HEIGHT',
  GET_RESUME_DETAIL_REQUEST: 'glints/application/GET_RESUME_DETAIL_REQUEST',
  GET_RESUME_DETAIL_SUCCESS: 'glints/application/GET_RESUME_DETAIL_SUCCESS',
  GET_RESUME_DETAIL_FAILURE: 'glints/application/GET_RESUME_DETAIL_FAILURE',
  HIDE_RESUME_PAGE_FOOTER: 'glints/application/HIDE_RESUME_PAGE_FOOTER',
};

export function getResumeDetail(jobId, applicationId) {
  return async dispatch => {
    await dispatch({
      [CALL_API]: {
        types: [
          Actions.GET_RESUME_DETAIL_REQUEST,
          Actions.GET_RESUME_DETAIL_SUCCESS,
          Actions.GET_RESUME_DETAIL_FAILURE,
        ],
        endpoint: `jobs/${jobId}/applications/${applicationId}`,
        method: 'get',
      },
    });
  };
}

export const setFooterHeight = createAction(Actions.SET_FOOTER_HEIGHT);
export const hideResumePageFooter = createAction(
  Actions.HIDE_RESUME_PAGE_FOOTER
);
