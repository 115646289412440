import React from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';

import { SelectField } from '../GlintsAntdSelect';

const FormSelect = ({
  input,
  options,
  hasFormError,
  hasFeedback,
  meta,
  className,
  required,
  selectClassName,
  ...props
}) => {
  let inputErrorStatus = null;
  if (
    (required && meta.touched && !input.value) ||
    (hasFormError && meta.error)
  ) {
    inputErrorStatus = 'error';
  }
  return (
    <Form.Item
      hasFeedback={hasFeedback}
      validateStatus={inputErrorStatus}
      help={inputErrorStatus ? meta.error : null}
      className={className}
    >
      <SelectField
        size="large"
        {...props}
        {...input}
        className={selectClassName}
      >
        <For each="option" of={options}>
          <SelectField.Option
            value={option.value.toString()}
            key={option.value}
          >
            {option.label}
          </SelectField.Option>
        </For>
      </SelectField>
    </Form.Item>
  );
};

FormSelect.propTypes = {
  options: PropTypes.array,

  hasFeedback: PropTypes.bool,
  required: PropTypes.bool,

  addonBefore: PropTypes.node,

  className: PropTypes.string,
  hasFormError: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  selectClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  input: PropTypes.object,
  meta: PropTypes.object,
};

export default FormSelect;
