import { defineMessages } from 'react-intl';

export const homePageSignUpFormMessages = defineMessages({
  firstName: {
    id: 'text-first-name',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'text-last-name',
    defaultMessage: 'Last Name',
  },
  workEmailAddress: {
    id: 'interactive-work-email-address',
    defaultMessage: 'Work Email Address',
  },
  phoneNumber: {
    id: 'text-phone-number',
    defaultMessage: 'Phone Number',
  },
  password: {
    id: 'interactive-password',
    defaultMessage: 'Password',
  },
  confirmPassword: {
    id: 'text-confirm-password',
    defaultMessage: 'Confirm Password',
  },
  signUpAgreement: {
    id: 'interactive-signing-up-terms-policy',
    defaultMessage:
      "By signing up here, I agree to <link>Glints' Terms of Service and Privacy Policy</link>",
  },
  signUpNow: {
    id: 'interactive-sign-up-now',
    defaultMessage: 'Sign Up Now',
  },
});
