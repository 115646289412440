import React, { useState } from 'react';
import PhoneNumber from 'awesome-phonenumber';
import { Flex, PrimaryColor } from 'glints-aries';
import {
  Breakpoints,
  Button,
  PrimaryButton,
  Typography,
} from 'glints-aries/es/@next';
import { TextInput } from 'glints-aries/es/@next/TextInput';
import { flatMap, identity, sortBy } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import useSWR from 'swr';

import { COUNTRY_CALLING_CODES } from '../../../common/constants';
import useSaveBasicInfo from '../../../common/hooks/useSaveBasicInfo';
import SelectField from '../../../components/Fields/SelectField';
import config from '../../../config';
import { Country } from '../../../models/Country';
import { getUserPhoneNumber } from '../../../selectors/user';
import ResponsiveButtonGroup from './ResponsiveButtonGroup';

type Props = {
  onClose?: () => void;
  afterSubmit?: (submittedPhoneNumber?: string) => void;
  showInput?: boolean;
};

const Styled = {
  SelectField: styled(SelectField)`
    svg {
      height: 20px !important;
      width: 20px !important;
      position: static;
      fill: #666666 !important;
    }
  `,
  NumberInput: styled(TextInput)`
    height: 38px;
    @media (min-width: ${Breakpoints.large}) {
      width: 360px;
    }
    @media (max-width: ${Breakpoints.large}) {
      width: 100%;
    }
  `,
};

const PhoneNumberInput = ({
  afterSubmit,
  onClose,
  showInput = true,
}: Props) => {
  const saveBasicInfo = useSaveBasicInfo();
  const rawUserPhoneNumber = useSelector(getUserPhoneNumber) ?? '';
  const userPhoneNumber = PhoneNumber(rawUserPhoneNumber).isValid()
    ? PhoneNumber(rawUserPhoneNumber)
    : undefined;
  const initialCallingCode = userPhoneNumber
    ? `+${userPhoneNumber.getCountryCode()}`
    : COUNTRY_CALLING_CODES[config.COUNTRY ?? 'sg'];
  const initialPhoneNumber = {
    callingCode: initialCallingCode,
    number: userPhoneNumber?.getNumber('significant'),
    error: false,
  };
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);

  const { data, isLoading } = useSWR<{ data: Country[] }>('/countries');
  const countries = data?.data;
  const options = countries
    ? flatMap(
        sortBy(countries, country => country.name),
        country =>
          country.callingCode?.map(callingCode => ({
            value: callingCode,
            label: `${callingCode} ${country.name}`,
          }))
      ).filter(identity)
    : [];

  const handleSubmit = async () => {
    const fullPhoneNumber = phoneNumber.callingCode + phoneNumber.number;
    const isValid = PhoneNumber(fullPhoneNumber).isValid();

    if (!isValid) {
      setPhoneNumber(state => ({ ...state, error: true }));
      return;
    }

    await saveBasicInfo({
      phone: fullPhoneNumber,
    });

    afterSubmit?.(fullPhoneNumber);
    onClose?.();
  };

  const handleCancel = async () => {
    setPhoneNumber(initialPhoneNumber);
    onClose?.();
  };

  return (
    <>
      <If condition={showInput}>
        <Flex alignItems="center" mt={8} style={{ gap: 8 }}>
          <Styled.SelectField
            id={'country-code-dropdown'}
            value={{
              label: phoneNumber.callingCode,
              value: phoneNumber.callingCode,
            }}
            placeholder={initialCallingCode}
            onChange={newValue =>
              setPhoneNumber(state => ({
                ...state,
                callingCode: (newValue as any).value,
              }))
            }
            width={104}
            styles={{
              menu: baseStyles => ({
                ...baseStyles,
                width: 260,
              }),
            }}
            options={options}
            isLoading={isLoading}
            hasPlaceholderAsOption={false}
          />
          <Styled.NumberInput
            autoFocus={true}
            value={phoneNumber.number}
            error={phoneNumber.error}
            onChange={newInput =>
              setPhoneNumber(state => ({
                ...state,
                number: newInput.replace(/[^0-9]/g, ''),
              }))
            }
            onKeyDown={e => {
              if (e.key === 'Enter') handleSubmit();
            }}
            placeholder={PhoneNumber.getExample(
              PhoneNumber(phoneNumber.callingCode).getRegionCode(),
              'mobile'
            ).getNumber('significant')}
          />
        </Flex>
        <If condition={phoneNumber.error}>
          <Typography variant="overline" color={PrimaryColor.glintsred}>
            <FormattedMessage
              id="text-enter-valid-phone-number"
              defaultMessage="Please enter a valid phone number"
            />
          </Typography>
        </If>
      </If>
      <ResponsiveButtonGroup>
        <PrimaryButton disabled={!phoneNumber.number} onClick={handleSubmit}>
          <Typography variant="button">
            <FormattedMessage id="button-text_submit" defaultMessage="Submit" />
          </Typography>
        </PrimaryButton>
        <If condition={showInput}>
          <Button onClick={handleCancel}>
            <Typography variant="button">
              <FormattedMessage
                id="button-text_cancel"
                defaultMessage="Cancel"
              />
            </Typography>
          </Button>
        </If>
      </ResponsiveButtonGroup>
    </>
  );
};

export default PhoneNumberInput;
