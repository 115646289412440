import CALL_API from '../middleware/api_key';
import { Schemas } from '../middleware/schemas';

export const Actions = {
  JOB_CATEGORIES_REQUEST: 'glints/jobCategories/JOB_CATEGORIES_REQUEST',
  JOB_CATEGORIES_SUCCESS: 'glints/jobCategories/JOB_CATEGORIES_SUCCESS',
  JOB_CATEGORIES_FAILURE: 'glints/jobCategories/JOB_CATEGORIES_FAILURE',
};

export function fetchJobCategories(options, excludeDescription = true) {
  if (excludeDescription) {
    const excludeDescriptionAttributes = JSON.stringify([
      'id',
      'links',
      'name',
      'sections',
      'createdAt',
      'udpatedAt',
      'bannerPic',
    ]);
    options = options ? options : {};
    options.attributes = excludeDescriptionAttributes;
  }
  return {
    [CALL_API]: {
      types: [
        Actions.JOB_CATEGORIES_REQUEST,
        Actions.JOB_CATEGORIES_SUCCESS,
        Actions.JOB_CATEGORIES_FAILURE,
      ],
      endpoint: 'jobCategories',
      schema: Schemas.JOB_CATEGORY_ARRAY,
      options: {
        ...options,
        params: {
          limit: 0,
          order: 'name ASC',
          ...(options ? options.params : {}),
        },
      },
    },
  };
}
