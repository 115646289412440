import { gql } from '@apollo/client';

import { fragmentRegistry } from '../../../graphql/fragmentRegistry';

const skillsFragment = gql`
  fragment skillsFragment on JobSkillsType {
    skill {
      id
      name
    }
  }
`;

const hierarchicalJobCategoryFragment = gql`
  fragment hierarchicalJobCategoryFragment on HierarchicalJobCategory {
    id
    name
    leaves {
      name
      id
    }
    parents {
      name
      id
    }
  }
`;

const locationFragment = gql`
  fragment locationFragment on HierarchicalLocation {
    id
    name
    formattedName
    parents {
      id
      name
      formattedName
    }
  }
`;

fragmentRegistry.register(gql`
  fragment jobScreeningQuestionFragment on JobScreeningQuestion {
    selectedQuestions
    documentAndCertificates {
      id
      name
    }
    introVideoPreferredLanguageDetail {
      code
      name
      translationKey
    }
    industries {
      id
      name
    }
    customQuestionPlainTexts @include(if: $isCustomPlainSQEnabled)
  }
`);

fragmentRegistry.register(gql`
  fragment jobDraftScreeningQuestionFragment on JobDraftScreeningQuestion {
    selectedQuestions
    introVideoPreferredLanguageDetail {
      code
      name
      translationKey
    }
    documentAndCertificates {
      id
      name
    }
    industries {
      id
      name
    }
    customQuestionPlainTexts @include(if: $isCustomPlainSQEnabled)
  }
`);

export const getJobByIdQuery = gql`
  query getJobById(
    $jobId: String!
    $empBoostJobEnabled: Boolean!
    $isMustHaveProfilePictureEnabled: Boolean!
    $isCustomPlainSQEnabled: Boolean!
  ) {
    getJobById(id: $jobId) {
      salaries {
        CurrencyCode
        id
        maxAmount
        minAmount
        salaryMode
        salaryType
      }
      CityId
      company {
        id
      }
      CountryCode
      creatorId
      jobExtensionDetails {
        extendExpiryInDays
      }
      HierarchicalJobCategoryId
      hierarchicalJobCategory {
        ...hierarchicalJobCategoryFragment
      }
      DistrictId
      location {
        ...locationFragment
      }
      closedAt
      createdAt
      updatedAt
      expiryDate
      JobCategoryId
      acceptsForeignApplications
      descriptionJsonString
      id
      workArrangementOption
      numberOfHires
      shouldShowSalary
      status
      title
      type
      skills {
        ...skillsFragment
      }
      minYearsOfExperience
      maxYearsOfExperience
      shouldPublishOnLinkedin
      benefits {
        benefit
        description
        logo
        title
      }
      interviewProcessJsonString
      receiverIds
      areMandatoryDetailsComplete
      country {
        name
        code
        isWhitelisted
      }
      city {
        id
        name
        CountryCode
      }
      educationLevel
      isHot @include(if: $empBoostJobEnabled)
      isJobBoostEligible @include(if: $empBoostJobEnabled)
      minAge
      maxAge
      gender
      unfilteredSkills {
        ...skillsFragment
      }
      isLocationRequiredForCandidate
      isResumeRequiredForCandidate
      isEducationRequiredForCandidate
      jobDeprioritizeInfo {
        isDeprioritized
      }
      applicationCountBreakdown {
        unread
      }
      maybeHaveArchivedApplications
      screeningQuestions {
        ...jobScreeningQuestionFragment
      }
      screeningQuestionsEnabled
      isJobPremium
      vipCandidateInformation @include(if: $isMustHaveProfilePictureEnabled) {
        required {
          profilePic
        }
      }
    }
  }
  ${hierarchicalJobCategoryFragment}
  ${skillsFragment}
  ${locationFragment}
`;

export const getJobDraftByIdQuery = gql`
  query getJobDraftById(
    $jobDraftId: String!
    $isMustHaveProfilePictureEnabled: Boolean!
    $isCustomPlainSQEnabled: Boolean!
  ) {
    getJobDraftById(id: $jobDraftId) {
      id
      CityId
      title
      type
      JobCategoryId
      workArrangementOption
      CountryCode
      CreatorId
      acceptsForeignApplications
      descriptionJsonString
      minYearsOfExperience
      maxYearsOfExperience
      skills {
        ...skillsFragment
      }
      salaries {
        CurrencyCode
        id
        maxAmount
        minAmount
        salaryMode
        salaryType
      }
      HierarchicalJobCategoryId
      hierarchicalJobCategory {
        ...hierarchicalJobCategoryFragment
      }
      country {
        name
        code
        isWhitelisted
      }
      DistrictId
      location {
        ...locationFragment
      }
      educationLevel
      minAge
      maxAge
      gender
      isLocationRequiredForCandidate
      isResumeRequiredForCandidate
      isEducationRequiredForCandidate
      shouldShowSalary
      screeningQuestions {
        ...jobDraftScreeningQuestionFragment
      }
      company {
        id
      }
      vipCandidateInformation @include(if: $isMustHaveProfilePictureEnabled) {
        required {
          profilePic
        }
      }
    }
  }
  ${hierarchicalJobCategoryFragment}
  ${skillsFragment}
  ${locationFragment}
`;
