import React, { useState } from 'react';
import { Box } from 'glints-aries';
import {
  Button,
  ButtonGroup,
  DestructiveButton,
  Icon,
  Popover,
  RadioButton,
  Typography,
} from 'glints-aries/es/@next';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { messages, RemoveChannelMemberAction } from './constants';

const Styled = {
  RemoveButton: styled(Button)`
    padding: 8px 12px;
  `,
};

type RemoveMemberDropdownProps = {
  onRemove?: (value: RemoveChannelMemberAction) => void;
};

const RemoveMemberDropdown = ({ onRemove }: RemoveMemberDropdownProps) => {
  const { formatMessage } = useIntl();
  const [isActive, setActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    RemoveChannelMemberAction.FROM_CHAT
  );

  const togglePopover = () => setActive(!isActive);
  const closePopover = () => setActive(false);
  const handleRemove = () => onRemove?.(selectedValue);
  const makeOnChangeHandler = (value: RemoveChannelMemberAction) => () =>
    setSelectedValue(value);

  const popoverActivator = (
    <Styled.RemoveButton
      icon={<Icon name="ri-delete-bin-line" />}
      onClick={togglePopover}
    >
      <Typography variant="button">
        <FormattedMessage id="interactive-remove" defaultMessage="Remove" />
      </Typography>
      <Icon name="ri-arrow-m-down-fill" />
    </Styled.RemoveButton>
  );

  return (
    <Popover
      active={isActive}
      activator={popoverActivator}
      onClose={closePopover}
      preferredAlignment="right"
    >
      <Popover.Pane>
        <Box p={12} style={{ maxWidth: '312px' }}>
          <RadioButton
            name="remove-channel-member"
            label={formatMessage(messages.fromThisChatLabel)}
            helperText={formatMessage(messages.fromThisChatDesc)}
            value={RemoveChannelMemberAction.FROM_CHAT}
            checked={selectedValue === RemoveChannelMemberAction.FROM_CHAT}
            onChange={makeOnChangeHandler(RemoveChannelMemberAction.FROM_CHAT)}
          />
          <RadioButton
            name="remove-channel-member"
            label={formatMessage(messages.fromThisChatAndPTNLabel)}
            helperText={formatMessage(messages.fromThisChatAndPTNDesc)}
            value={RemoveChannelMemberAction.FROM_CHAT_AND_PTN}
            checked={
              selectedValue === RemoveChannelMemberAction.FROM_CHAT_AND_PTN
            }
            onChange={makeOnChangeHandler(
              RemoveChannelMemberAction.FROM_CHAT_AND_PTN
            )}
          />
          <Box mt={16}>
            <ButtonGroup fullWidth={true}>
              <Button onClick={closePopover}>
                <FormattedMessage
                  id="interactive-cancel"
                  defaultMessage="Cancel"
                />
              </Button>
              <DestructiveButton onClick={handleRemove}>
                <FormattedMessage
                  id="interactive-remove"
                  defaultMessage="Remove"
                />
              </DestructiveButton>
            </ButtonGroup>
          </Box>
        </Box>
      </Popover.Pane>
    </Popover>
  );
};

export default RemoveMemberDropdown;
