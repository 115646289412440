import { gql, useMutation } from '@apollo/client';
import axios from 'axios';

export interface StreamUserInput {
  id: string;
}

const syncUserMutation = `
mutation syncStreamUser($data: StreamUserInput!) {
    syncStreamUser(data: $data) {
      id
      name
      image
      glintsRole
      created_at
      updated_at
      banned
      online
    }
  }`;

export const syncStreamUserWithAxios = async (
  streamUserInput: StreamUserInput,
  token: string
) => {
  const response = await axios.post(
    '/api/graphql',
    {
      query: syncUserMutation,
      variables: { data: streamUserInput },
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response?.data?.data?.syncStreamUser;
};

export const useSyncStreamUser = () => {
  const [syncStreamUser] = useMutation(gql`
    ${syncUserMutation}
  `);
  return syncStreamUser;
};
