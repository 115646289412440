import { Button as BaseButton, ScreenSize } from 'glints-aries';
import styled from 'styled-components';

export const Button = styled(BaseButton)`
  height: 45px;
  width: 260px;

  @media (min-width: ${ScreenSize.desktopM}px) {
    margin-top: 0;
    width: auto;
  }
`;

export const LabelWithIcon = styled.span`
  display: inline-flex;
  align-items: center;

  svg {
    margin-left: 8px;
    font-size: 15px;
  }
`;
