import { apm } from '@elastic/apm-rum';
import { isS3Url, parseS3Url } from 'amazon-s3-url';
import { Channel } from 'stream-chat';
import { getGroupStyles, StreamMessage } from 'stream-chat-react';

import { getOSSAssetURL } from '../../common/asset-manager';
import pushGTMEvent from '../../common/google-tag-manager';
import assertNever from '../../common/helpers/assertNever';
import { ApplicationDataForBulkUpdate } from '../../components/MoveToInCommunication/types';
import { getConfig } from '../../config';
import {
  applicationChatContactsExchangeCddRequestMessages,
  applicationChatContactsExchangeEmpRequestMessages,
  ApplicationChatContactsExchangeRequestStatus,
  ApplicationChatContactsRequestActionType,
  applicationChatCVExchangeCddRequestMessages,
  applicationChatCVExchangeEmpRequestMessages,
  ApplicationChatCVExchangeRequestStatus,
  ApplicationChatCVRequestActionType,
  applicationGroupChatContactsExchangeCddRequestMessages,
  applicationGroupChatContactsExchangeEmpRequestMessages,
  applicationGroupChatCVExchangeCddRequestMessages,
  applicationGroupChatCVExchangeEmpRequestMessages,
  AttachmentType,
  MessagingGtmEvent,
} from './constants';
import { ApplicationData } from './graphql';
import {
  ApplicationMessageMetadata,
  ContactsRequestAction,
  CVRequestAction,
  MessagingChannelData,
  MessagingMessageData,
} from './types';

export const getChannelApplicationMetadata = (channel: Channel) =>
  (channel.data as MessagingChannelData).glints_application_chat;

export const getChannelApplicationMetadataV2 = (channel: Channel) =>
  channel.data as MessagingChannelData;

export const getMessageApplicationMetadata = (message: StreamMessage) =>
  (message as MessagingMessageData).glints_application_chat;

export const isCVRequestAction = (
  metadata: ApplicationMessageMetadata
): metadata is CVRequestAction =>
  Object.values<unknown>(ApplicationChatCVRequestActionType).includes(
    metadata.action_type
  );

export const isContactsRequestAction = (
  metadata: ApplicationMessageMetadata
): metadata is ContactsRequestAction =>
  Object.values<unknown>(ApplicationChatContactsRequestActionType).includes(
    metadata.action_type
  );

export const isCustomMessage = (message: StreamMessage): boolean =>
  Boolean(getMessageApplicationMetadata(message));

export const customGroupStyles: typeof getGroupStyles = (
  current,
  previous,
  next,
  noGroupByUser
) => {
  const [currentIsCustomMsg, nextIsCustomMsg, prevIsCustomMsg] = [
    current && Boolean(isCustomMessage(current)),
    next && Boolean(isCustomMessage(next)),
    previous && Boolean(isCustomMessage(previous)),
  ];

  if (currentIsCustomMsg || (nextIsCustomMsg && prevIsCustomMsg))
    return 'single';
  if (nextIsCustomMsg) return 'bottom';
  if (prevIsCustomMsg && ['regular', 'deleted'].includes(next?.type ?? ''))
    return 'top';

  return getGroupStyles(current, previous, next, noGroupByUser);
};

export const pushMessagingGTMEvent = (
  event: MessagingGtmEvent,
  dataLayer?: Record<string, unknown>
) =>
  pushGTMEvent(event, {
    messagingPayload: dataLayer,
  });

type PushMessagingGTMEventWithWhatsappInfo = {
  event: MessagingGtmEvent;
  messagingPayload: {
    job_id: string;
    company_id: string;
    channel_id?: string;
    channel_members_count?: number;
    from_application_status: string;
    to_application_status: string;
  };
  waPresent: boolean;
};

export const pushMessagingGTMEventWithWhatsappInfo = ({
  event,
  messagingPayload,
  waPresent,
}: PushMessagingGTMEventWithWhatsappInfo) => {
  pushGTMEvent(event, {
    messagingPayload,
    waPresent,
  });
};

export const getFileAttachmentType = (file: File) => {
  const mimeType = file.type.split('/')[0];

  switch (mimeType) {
    case 'image':
      return AttachmentType.IMAGE;
    default:
      return AttachmentType.FILE;
  }
};

export const getContactsExchangeRequestMessages = (
  metadata: ContactsRequestAction
) => {
  switch (metadata.action_type) {
    case ApplicationChatContactsRequestActionType.CONTACTS_REQUEST_EMP:
      return applicationChatContactsExchangeEmpRequestMessages;
    case ApplicationChatContactsRequestActionType.CONTACTS_REQUEST_CDD:
      return applicationChatContactsExchangeCddRequestMessages;
    default:
      return assertNever(metadata.action_type);
  }
};

export const getGroupContactsExchangeRequestMessages = (
  metadata: ContactsRequestAction
) => {
  switch (metadata.action_type) {
    case ApplicationChatContactsRequestActionType.CONTACTS_REQUEST_EMP:
      return applicationGroupChatContactsExchangeEmpRequestMessages;
    case ApplicationChatContactsRequestActionType.CONTACTS_REQUEST_CDD:
      return applicationGroupChatContactsExchangeCddRequestMessages;
    default:
      return assertNever(metadata.action_type);
  }
};

export const getContactsExchangeActingUserByStatus = (
  metadata: ContactsRequestAction
) => {
  switch (metadata.action_payload.status) {
    case ApplicationChatContactsExchangeRequestStatus.PENDING:
      return metadata.action_payload.requestor_user;
    case ApplicationChatContactsExchangeRequestStatus.ACCEPTED:
    case ApplicationChatContactsExchangeRequestStatus.REJECTED:
    case ApplicationChatContactsExchangeRequestStatus.CANCELED:
      return metadata.action_payload.respondent_user;
    default:
      assertNever(metadata.action_payload.status);
  }
};

export const getCVExchangeRequestMessages = (metadata: CVRequestAction) => {
  switch (metadata.action_type) {
    case ApplicationChatCVRequestActionType.CV_REQUEST_EMP:
      return applicationChatCVExchangeEmpRequestMessages;
    case ApplicationChatCVRequestActionType.CV_REQUEST_CDD:
    case ApplicationChatCVRequestActionType.CV_REQUEST_CDD_AUTO:
      return applicationChatCVExchangeCddRequestMessages;
    case ApplicationChatCVRequestActionType.CV_AUTO_SHARED:
      return null;
    default:
      return assertNever(metadata.action_type);
  }
};

export const getGroupCVExchangeRequestMessages = (
  metadata: CVRequestAction
) => {
  switch (metadata.action_type) {
    case ApplicationChatCVRequestActionType.CV_REQUEST_EMP:
      return applicationGroupChatCVExchangeEmpRequestMessages;
    case ApplicationChatCVRequestActionType.CV_REQUEST_CDD:
    case ApplicationChatCVRequestActionType.CV_REQUEST_CDD_AUTO:
      return applicationGroupChatCVExchangeCddRequestMessages;
    case ApplicationChatCVRequestActionType.CV_AUTO_SHARED:
      return null;
    default:
      return assertNever(metadata.action_type);
  }
};

export const getCVExchangeActingUserByStatus = (metadata: CVRequestAction) => {
  switch (metadata.action_payload.status) {
    case ApplicationChatCVExchangeRequestStatus.PENDING:
      return metadata.action_payload.requestor_user;
    case ApplicationChatCVExchangeRequestStatus.ACCEPTED:
    case ApplicationChatCVExchangeRequestStatus.REJECTED:
    case ApplicationChatCVExchangeRequestStatus.CANCELED:
      return metadata.action_payload.respondent_user;
    default:
      assertNever(metadata.action_payload.status);
  }
};

/**
 * Extracts the S3 URL from a Thumbor URL.
 * @param url The Thumbor URL.
 *
 * The Thumbor URL has a structure like `/<unsafe>/<dimensions>/<s3-url-path>`.
 * This function removes the first two segments (`unsafe` and dimensions) to reconstruct the original S3 URL.
 * For example, a Thumbor URL `images-dev.glints.com/unsafe/40x40/glints-dashboard-dev.s3.amazonaws.com/profile-picture-default/14.jpg`
 * would be converted to `https://glints-dashboard-dev.s3.amazonaws.com/profile-picture-default/14.jpg`.
 */
const extractS3UrlFromThumborUrl = (url: string) => {
  try {
    const parsedUrl = new URL(url);
    const pathSegments = parsedUrl.pathname
      .split('/')
      .filter(segment => segment);

    if (pathSegments.length < 3) {
      throw new Error('URL does not have enough path segments');
    }

    return `https://${pathSegments.slice(2).join('/')}`;
  } catch (error) {
    apm.captureError(error as Error);
    console.error('Invalid URL:', error);
    return '';
  }
};

const isOSSURL = (url: string) => {
  const { OSS_BUCKET_NAME, OSS_BUCKET_LOCATION } = getConfig();

  return url.includes(`${OSS_BUCKET_NAME}.${OSS_BUCKET_LOCATION}.aliyuncs.com`);
};

const getKeyFromOSSURL = (url: string) => {
  try {
    const parsedUrl = new URL(url);
    const path = parsedUrl.pathname.substring(1); // Remove the leading '/'
    const query = parsedUrl.search; // Get the query string

    return `${path}${query}`;
  } catch (error) {
    apm.captureError(error as Error);
    console.error('Invalid URL:', error);
    return '';
  }
};

export const convertS3ToOSSURL = (url: unknown) => {
  if (!url || typeof url !== 'string') {
    return undefined;
  }

  const thumborDomains = ['images.glints.com', 'images-dev.glints.com'];

  const isThumborUrl = thumborDomains.some(domain => url.includes(domain));

  const assetUrl = isThumborUrl ? extractS3UrlFromThumborUrl(url) : url;

  if (isOSSURL(assetUrl)) {
    // Replace direct OSS URL with the custom domain OSS URL
    return getOSSAssetURL(getKeyFromOSSURL(assetUrl));
  }

  if (!isS3Url(assetUrl)) {
    return assetUrl;
  }

  const { key } = parseS3Url(assetUrl);

  return getOSSAssetURL(key);
};

export const buildApplicationDataForBulkUpdate = (
  application: ApplicationData
): ApplicationDataForBulkUpdate => ({
  ...application,
  Applicant: {
    firstName: application.applicant.firstName || '',
    lastName: application.applicant.lastName || '',
    email: application.applicant.email,
  },
  JobId: application.job.id,
});
