import { configureStore, Reducer } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import * as Sentry from '@sentry/react';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { compact, merge } from 'lodash-es';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import persistState, { mergePersistedState } from 'redux-localstorage';
import adapter from 'redux-localstorage/lib/adapters/localStorage';
import filter from 'redux-localstorage-filter';
import { createLogger } from 'redux-logger';

import trackingMiddleware from './common/tracking/trackingReduxMiddleware';
import config from './config';
import api from './middleware/api';
import apm from './middleware/apm';
import googleTagManager from './middleware/google-tag-manager';
import sentry from './middleware/sentry';
import rootReducer from './reducers';

const appName = 'glintsEmployersApp';

export const browserHistory = createBrowserHistory();

// Create a reducer that rehydrates from persisted state.
export const createReducer = (reducer: Reducer, asyncReducers?: any) =>
  mergePersistedState((initialState, persistedState) =>
    merge({}, initialState, persistedState)
  )(reducer(browserHistory, asyncReducers));

const storage = filter(['session.token', 'session.data', 'user.me.value'])(
  adapter(window.localStorage)
);

// // Redux integration
// // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const store: ToolkitStore & { asyncReducers?: any } = configureStore({
  reducer: createReducer(rootReducer),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      ...compact([
        api,
        sentry,
        apm,
        trackingMiddleware,
        googleTagManager,
        routerMiddleware(browserHistory),
        config.LOG_REDUX_ACTIONS_TO_CONSOLE &&
          createLogger({ collapsed: true }),
      ])
    ),
  enhancers: [sentryReduxEnhancer, persistState(storage, appName)],
  devTools: config.DEBUG,
});

store.asyncReducers = {};

export function injectAsyncReducer(name: string, asyncReducer: Reducer) {
  store.asyncReducers[name] = asyncReducer;
  store.replaceReducer(createReducer(rootReducer, store.asyncReducers));
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Type-safe version of useDispatch and useSelector, see https://redux-toolkit.js.org/tutorials/typescript
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
