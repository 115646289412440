import React from 'react';
import classNames from 'classnames';
import { Button, Loading } from 'glints-aries';
import PropTypes from 'prop-types';

import styles from './AriesButtons.scss';

const BtnWhite = ({ className, loading, small, ...props }) => (
  <Button
    variant="ghost"
    style={
      small
        ? { lineHeight: 1, padding: '12px 40px', color: '#3FAFE0' }
        : { lineHeight: 1, padding: '15px 40px', color: '#3FAFE0' }
    }
    className={classNames(small ? styles.btnSmall : styles.btn, className)}
    {...props}
  >
    {props.children}
    <If condition={loading}>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Loading className={styles.loading} />
    </If>
  </Button>
);

BtnWhite.propTypes = {
  loading: PropTypes.bool,
  small: PropTypes.bool,

  children: PropTypes.node,

  props: PropTypes.object,

  className: PropTypes.string,
};

export default BtnWhite;
