import { useSelector } from 'react-redux';

import { UnleashFeatureNames } from '../../../modules/Unleash/featureNames';
import { getIsFeatureEnabled } from '../../../selectors/features';

export default function useIsApplicationsBulkAPIV2Enabled() {
  const isRecommendedTalentV2APIEnabled = useSelector(state =>
    getIsFeatureEnabled(state, UnleashFeatureNames.empApplicationsBulkAPIV2)
  );

  return isRecommendedTalentV2APIEnabled;
}
