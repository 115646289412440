import CALL_API from '../middleware/api_key';
import { Schemas } from '../middleware/schemas';

export const Actions = {
  JOB_TYPES_REQUEST: 'glints/statuses/JOB_TYPES_REQUEST',
  JOB_TYPES_SUCCESS: 'glints/statuses/JOB_TYPES_SUCCESS',
  JOB_TYPES_FAILURE: 'glints/statuses/JOB_TYPES_FAILURE',
};

export function fetchJobTypes() {
  return {
    [CALL_API]: {
      types: [
        Actions.JOB_TYPES_REQUEST,
        Actions.JOB_TYPES_SUCCESS,
        Actions.JOB_TYPES_FAILURE,
      ],
      endpoint: 'preferences/jobTypes',
      schema: Schemas.JOB_TYPE_ARRAY,
    },
  };
}
