import React, { Suspense } from 'react';
import { useMount } from 'react-use';

import { ApplicationDetails, Job } from '../../common/interfaces/entities';
import { TrackingService } from '../../common/tracking/trackingService';
import RenderBasedOnApplicationAccessLevel from './RenderBasedOnApplicationAccessLevel';

export const TrackFPAPaywallViewedBasedOnApplicationAccessLevel = (props: {
  applicationDetails: Pick<
    ApplicationDetails,
    'employerMetadata' | 'id' | 'status'
  >;
  jobId: Job['id'];
  onSuccess?: () => void;
}) => (
  <Suspense fallback={null}>
    <RenderBasedOnApplicationAccessLevel
      {...props}
      vipCTAClickedTrackingLocation="skip"
      renderVipAccessContent={() => (
        <TrackFPAPaywallViewed
          applicationId={props.applicationDetails.id}
          onSuccess={props.onSuccess}
        />
      )}
    >
      <div />
    </RenderBasedOnApplicationAccessLevel>
  </Suspense>
);

export const TrackFPAPaywallViewed = ({
  applicationId,
  onSuccess,
}: {
  applicationId: string;
  onSuccess?: () => void;
}) => {
  useMount(() => {
    trackFPAPaywallViewed({
      applicationId,
    });
    onSuccess?.();
  });

  return null;
};

export const trackFPAPaywallViewed = (props: { applicationId: string }) => {
  const transformedProperties = {
    // eslint-disable-next-line camelcase
    application_id: props.applicationId,
  };

  TrackingService.logEvent('fpa_paywall_viewed', transformedProperties);
};
