import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router';

import { getIsLoggedIn } from '../selectors/session';

export function withDashboardRedirect<P = Record<string, unknown>>(
  WrappedComponent: ComponentType<P>
) {
  const Wrapper = (props: P & JSX.IntrinsicAttributes) => {
    const isLoggedIn = useSelector(getIsLoggedIn);
    const { pathname } = useLocation();

    if (pathname === '/' && isLoggedIn) {
      return <Redirect to="/dashboard" />;
    }

    return <WrappedComponent {...props} />;
  };

  return Wrapper;
}
