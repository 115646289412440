import { Modal } from 'glints-aries/es/@next';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  width: 100%;
  max-width: 620px;

  .modal-content {
    width: 100%;
  }
`;
