import { handleActions } from 'redux-actions';

import { SET_NO_INDEX, UNSET_NO_INDEX, UPDATE_METADATA } from '../actions/meta';
import config from '../config';

let initialState;

if (config.COUNTRY === 'sg') {
  initialState = {
    title: 'Glints - Career Discovery & Development Platform',
    description: `Explore internships, graduate jobs & career development resources in Singapore on Glints.
    Do what you love on our career discovery & development platform.`,
    image: '/images/logo.png',
    prerenderStatusCode: null,
    noIndex: false,
    location: null,
    canonical: null,
  };
} else {
  initialState = {
    title: 'Glints - Career Discovery & Development Platform',
    description: `Explore internships, graduate jobs & career development resources in Indonesia on Glints.
    Do what you love on our career discovery & development platform.`,
    image: '/images/logo.png',
    prerenderStatusCode: null,
    noIndex: false,
    location: null,
    canonical: null,
  };
}

export default handleActions(
  {
    [UPDATE_METADATA]: (state, action) => ({
      ...state,
      title: action.payload.title ? action.payload.title : initialState.title,
      description:
        action.payload.description === ''
          ? ''
          : action.payload.description ?? initialState.description,
      siteName: action.payload.siteName,
      skipTwitterSite: action.payload.skipTwitterSite,
      image: action.payload.image
        ? action.payload.image.startsWith('https:')
          ? action.payload.image
          : `https:${action.payload.image}`
        : null,
      prerenderStatusCode: action.payload.prerenderStatusCode
        ? action.payload.prerenderStatusCode
        : null,
      location: window.location.href,
      canonical: action.payload.canonical,
    }),
    [SET_NO_INDEX]: state => ({
      ...state,
      noIndex: true,
    }),
    [UNSET_NO_INDEX]: state => ({
      ...state,
      noIndex: false,
    }),
  },
  initialState
);
