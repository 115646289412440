import { useSelector } from 'react-redux';

import { UnleashFeatureNames } from '../../modules/Unleash/featureNames';
import { getIsFeatureEnabled } from '../../selectors/features';
import { countryCodeIsIndonesia } from '../helpers/countryCodeHelper';
import { useGetSessionCompany } from './useGetSessionCompany';

export default function useIsCustomPlainSQEnabled(): boolean {
  const { sessionCompany } = useGetSessionCompany();
  const isCustomPlainTextEnabled = useSelector(state =>
    getIsFeatureEnabled(state, UnleashFeatureNames.allCustomSQPlainText)
  );

  return Boolean(
    isCustomPlainTextEnabled &&
      sessionCompany &&
      countryCodeIsIndonesia(sessionCompany.CountryCode)
  );
}
