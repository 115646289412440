import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, Greyscale, Loading } from 'glints-aries';
import {
  Button,
  ButtonGroup,
  Card,
  Divider,
  Icon,
  Popover,
  PrimaryButton,
  Typography,
} from 'glints-aries/es/@next';
import { PlainButton } from 'glints-aries/es/@next/Button/PlainButtonStyle';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useMessageInputContext } from 'stream-chat-react';
import styled from 'styled-components';

import { defaultTemplateMessages } from '../../constants';
import {
  useBatchCreateDefaultMessagingTemplateMessages,
  useCreateMessagingTemplateMessage,
  useDeleteMessagingTemplateMessage,
  useGetMessagingTemplateMessages,
  useUpdateMessagingTemplateMessage,
} from '../../graphql/TemplateMessage';
import {
  useIsMobileVersion,
  useIsPopupMode,
  useTemplateDrawerContext,
} from '../../hooks';
import {
  trackAddTemplateMessageClickedEvent,
  trackMessagingTemplateMessageSelectedEvent,
  trackTemplateButtonClickedEvent,
  trackTemplateMessageListingViewedEvent,
} from '../../tracking';
import EditTemplateMessageModal from './EditTemplateMessageModal';
import EditTemplateMessagesDrawer from './EditTemplateMessagesDrawer';

const formattedMessages = defineMessages({
  btnAddNew: {
    id: 'button-text_add-new',
    defaultMessage: 'Add New',
  },
  btnEdit: {
    id: 'interactive-edit',
    defaultMessage: 'Edit',
  },
});

const Styled = {
  CardAction: styled.div`
    padding: 10px 16px;
    border-top: 1px solid #e9e9e9;
  `,
  CardBody: styled.div`
    display: grid;
    flex-direction: column;
    align-items: center;
    max-height: 450px;
    width: 420px;
    max-width: calc(100vw - 30px);
    overflow: auto;
  `,
  CardSection: styled.div`
    padding: 20px 24px;
    cursor: pointer;
    &:hover {
      background-color: ${Greyscale.softgrey};
    }
  `,
  TemplateMessageParagraph: styled(Typography)`
    margin-bottom: 0 !important;
  `,
  MobileButton: styled(PlainButton)`
    padding: 0;
    min-width: auto;
  `,
};

type TemplateMessagesPopoverToggleProps = {
  shrink?: boolean;
};

const TemplateMessagesPopoverToggle = ({
  shrink,
}: TemplateMessagesPopoverToggleProps) => {
  const { formatMessage } = useIntl();
  const editTemplateMessagesListContainerRef = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState(false);
  const [editMessageModal, setEditMessageModal] = useState<{
    isOpen: boolean;
    id?: string;
  }>({
    isOpen: false,
    id: undefined,
  });
  const templateDrawer = useTemplateDrawerContext();
  const isMobileVersion = useIsMobileVersion();
  const isPopupMode = useIsPopupMode();
  const { setText, textareaRef } = useMessageInputContext();
  const { data, loading, called } = useGetMessagingTemplateMessages();
  const [createTemplateMessage] = useCreateMessagingTemplateMessage({
    refetchQueries: ['getMessagingTemplateMessages'],
    onCompleted: () => {
      editTemplateMessagesListContainerRef.current?.scrollTo({ top: 0 });
    },
  });
  const [editTemplateMessage] = useUpdateMessagingTemplateMessage({
    refetchQueries: ['getMessagingTemplateMessages'],
  });
  const [deleteMessage] = useDeleteMessagingTemplateMessage({
    refetchQueries: ['getMessagingTemplateMessages'],
  });
  const makeInput = useCallback(
    (message: (typeof defaultTemplateMessages)[number]) => ({
      message: formatMessage(message),
    }),
    [formatMessage]
  );
  const [createDefaultTemplateMessages, createDefaultTemplateMessagesResult] =
    useBatchCreateDefaultMessagingTemplateMessages({
      variables: {
        input1: makeInput(defaultTemplateMessages[0]),
        input2: makeInput(defaultTemplateMessages[1]),
        input3: makeInput(defaultTemplateMessages[2]),
      },
      refetchQueries: ['getMessagingTemplateMessages'],
    });

  const messages = data?.getMessagingTemplateMessages.data ?? [];
  const noResults = called && !loading && !messages.length;

  useEffect(() => {
    if (noResults && !createDefaultTemplateMessagesResult.called) {
      createDefaultTemplateMessages();
    }
  }, [
    noResults,
    createDefaultTemplateMessagesResult.called,
    createDefaultTemplateMessages,
  ]);

  const editMessage = (id?: string) => {
    setActive(false);
    setEditMessageModal({
      isOpen: true,
      id,
    });
  };

  const addMessage = () => {
    editMessage();
    trackAddTemplateMessageClickedEvent();
  };

  const editMessages = () => {
    setActive(false);
    templateDrawer.open();
    trackTemplateMessageListingViewedEvent();
  };

  const handleEditMessageOnSave = (message: string) => {
    if (editMessageModal.id) {
      editTemplateMessage({
        variables: {
          id: editMessageModal.id,
          input: {
            message,
          },
        },
      });
    } else {
      createTemplateMessage({
        variables: { input: { message } },
      });
    }
  };

  const handleEditMessageOnClose = () => {
    setEditMessageModal({
      isOpen: false,
      id: undefined,
    });
    if (!templateDrawer.isOpen) {
      setActive(true);
    }
  };

  const handleDeleteMessageOnSave = (id: string) =>
    deleteMessage({ variables: { id } });

  const makeMessageOnClickHandler = (message: string) => () => {
    setActive(false);
    setText(message);
    trackMessagingTemplateMessageSelectedEvent();

    // autoresize textarea
    if (textareaRef.current) {
      textareaRef.current.value = message;
      textareaRef.current.focus();
      textareaRef.current.dispatchEvent(new Event('input'));
    }
  };

  const ResponsiveButton =
    isMobileVersion || isPopupMode ? Styled.MobileButton : Button;

  return (
    <div>
      <Popover
        active={active}
        activator={
          <ResponsiveButton
            size="large"
            type="button"
            icon={<Icon name="ri-chat-quote-line" />}
            onClick={() => {
              setActive(!active);
              trackTemplateButtonClickedEvent();
            }}
            style={{
              minWidth: 'unset',
            }}
            data-cy="choose-template-button"
          >
            <If condition={!shrink}>
              <Typography variant="button">
                <FormattedMessage
                  id="button-text_template-message"
                  defaultMessage="Template"
                />
              </Typography>
            </If>
          </ResponsiveButton>
        }
        fluidContent={true}
        preferredAlignment="left"
        preferredPosition="above"
        onClose={() => setActive(false)}
      >
        <Popover.Pane>
          <Card
            heading={
              <Typography variant="body2">
                <FormattedMessage
                  id="text_template-message-title"
                  defaultMessage="Template messages"
                />
              </Typography>
            }
          >
            <Styled.CardBody>
              <Choose>
                <When condition={loading}>
                  <Box p={64} style={{ textAlign: 'center' }}>
                    <Loading />
                    <Typography variant="body1">
                      <FormattedMessage
                        id="text-loading"
                        defaultMessage="Loading..."
                      />
                    </Typography>
                  </Box>
                </When>
                <Otherwise>
                  {messages.map((message, index) => (
                    <Fragment key={message.id}>
                      <Styled.CardSection
                        data-cy={`template-message-${index}`}
                        onClick={makeMessageOnClickHandler(message.message)}
                      >
                        <Styled.TemplateMessageParagraph variant="body1">
                          &ldquo;{message.message}&rdquo;
                        </Styled.TemplateMessageParagraph>
                      </Styled.CardSection>
                      <If condition={index !== messages.length - 1}>
                        <Divider />
                      </If>
                    </Fragment>
                  ))}
                </Otherwise>
              </Choose>
            </Styled.CardBody>
            <Styled.CardAction>
              <ButtonGroup style={{ justifyContent: 'flex-end' }}>
                <Button onClick={editMessages}>
                  {formatMessage(formattedMessages.btnEdit)}
                </Button>
                <PrimaryButton onClick={addMessage}>
                  {formatMessage(formattedMessages.btnAddNew)}
                </PrimaryButton>
              </ButtonGroup>
            </Styled.CardAction>
          </Card>
        </Popover.Pane>
      </Popover>
      <If condition={editMessageModal.isOpen}>
        <EditTemplateMessageModal
          defaultMessage={
            messages.find(message => message.id === editMessageModal.id)
              ?.message
          }
          onSave={handleEditMessageOnSave}
          onClose={handleEditMessageOnClose}
        />
      </If>
      <EditTemplateMessagesDrawer
        editTemplateMessagesProps={{
          messages,
          listContainerRef: editTemplateMessagesListContainerRef,
          onNew: addMessage,
          onEdit: editMessage,
          onDelete: handleDeleteMessageOnSave,
        }}
      />
    </div>
  );
};

export default TemplateMessagesPopoverToggle;
