import CALL_API from '../middleware/api_key';
import { Schemas } from '../middleware/schemas';

export const Actions = {
  CURRENCIES_REQUEST: 'glints/currencies/CURRENCIES_REQUEST',
  CURRENCIES_SUCCESS: 'glints/currencies/CURRENCIES_SUCCESS',
  CURRENCIES_FAILURE: 'glints/currencies/CURRENCIES_FAILURE',
};

export function fetchCurrencies(options) {
  return {
    [CALL_API]: {
      types: [
        Actions.CURRENCIES_REQUEST,
        Actions.CURRENCIES_SUCCESS,
        Actions.CURRENCIES_FAILURE,
      ],
      endpoint: 'currencies',
      schema: Schemas.CURRENCY_ARRAY,
      options: {
        ...options,
        params: {
          ...(options ? options.params : {}),
          order: 'name ASC',
          limit: 0,
        },
      },
    },
  };
}
