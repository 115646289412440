import {
  gql,
  SuspenseQueryHookOptions,
  useSuspenseQuery,
} from '@apollo/client';
import Big from 'big.js';
import { useSelector } from 'react-redux';

import { getSessionCompanyId } from '../../selectors/session';
import {
  BaseEmployerInput,
  EmployerPurchaseProductType,
} from '../interfaces/entities';
import { CompanyProductFreeTrialOffer } from '../interfaces/product-free-trial-offer';

const query = gql`
  query getCompanyProductFreeTrialOffer(
    $input: GetCompanyProductFreeTrialOfferInput!
  ) {
    getCompanyProductFreeTrialOffer(input: $input) {
      id
      trialInvitationValidUntil
      productQuantity
      productTimePeriod
      productTimePeriodUnit
      product {
        type
      }
      currencyPrice {
        currency
        total
      }
    }
  }
`;

type GetCompanyProductFreeTrialOfferInput = {
  input: BaseEmployerInput & {
    productType: EmployerPurchaseProductType;
  };
};

type GetCompanyProductFreeTrialOfferResponse = {
  getCompanyProductFreeTrialOffer: CompanyProductFreeTrialOffer | null;
};

type UseGetCompanyFreeTrialOffersParams = {
  productType: EmployerPurchaseProductType;
  options?: SuspenseQueryHookOptions<
    GetCompanyProductFreeTrialOfferResponse,
    GetCompanyProductFreeTrialOfferInput
  >;
};

const transformCompanyProductFreeTrialOffer = (
  companyProductFreeTrialOffer: CompanyProductFreeTrialOffer
): CompanyProductFreeTrialOffer => ({
  ...companyProductFreeTrialOffer,
  currencyPrice: companyProductFreeTrialOffer.currencyPrice
    ? {
        ...companyProductFreeTrialOffer.currencyPrice,
        total: Big(companyProductFreeTrialOffer.currencyPrice.total),
      }
    : null,
});

export const useGetCompanyFreeTrialOffer = ({
  productType,
  options = {
    fetchPolicy: 'cache-and-network',
    queryKey: 'getCompanyProductFreeTrialOffer',
  },
}: UseGetCompanyFreeTrialOffersParams): CompanyProductFreeTrialOffer | null => {
  const companyId: string | undefined = useSelector(getSessionCompanyId);

  const { data } = useSuspenseQuery<
    GetCompanyProductFreeTrialOfferResponse,
    GetCompanyProductFreeTrialOfferInput
  >(
    query,

    {
      variables: {
        input: {
          companyId: companyId || '',
          productType,
        },
      },
      ...options,
    }
  );

  return data.getCompanyProductFreeTrialOffer
    ? transformCompanyProductFreeTrialOffer(
        data.getCompanyProductFreeTrialOffer
      )
    : null;
};
