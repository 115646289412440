import React, { ReactElement, useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { IntlProvider as BaseIntlProvider } from 'react-intl';

import { getConfig } from './config';

const defaultLanguage = 'en';

const languageToLocaleMap: { [key: string]: string } = {
  zh: 'zh-cn',
  en: 'en-sg',
  id: 'id',
  vi: 'vi',
  zh_MY: 'zh-cn',
  zh_TW: 'zh-tw',
};

export const mapLanguageToDayjsLocale = (language: string) =>
  languageToLocaleMap[language] || language;

type IntlProviderProps = {
  children: ReactElement;
};

const IntlProvider = ({ children }: IntlProviderProps) => {
  const [messages, setMessages] = useState();
  const [locale, setLocale] = useState(defaultLanguage);

  useEffect(() => {
    async function getMessages() {
      const config = getConfig();
      const language = config.LANG || defaultLanguage;
      const country = config.COUNTRY;

      // We configure zh rather than zh_{country}, because only zh is valid to React-Intl and LanguageSwitcher.
      // Therefore, we need to compose the locale name here to load the correct file
      const localeJsonName =
        language === 'zh' ? `zh_${country.toUpperCase()}` : language;

      const messagesFromS3 = localeJsonName
        ? await axios
            // ?cache=false is to force browser to clear cache after we add Cache-control: 'no-cache'
            // to S3. We can remove the querystring after one month
            .get(`https://${config.S3_I18N}/${localeJsonName}.json?cache=false`)
            .then(response => response.data)
            .catch(error =>
              console.error(`[i18n] Invalid locale '${language}'`, error)
            )
        : null;
      setMessages(messages => messagesFromS3 || messages);
      setLocale(messagesFromS3 ? language : defaultLanguage);

      const dateLocale = mapLanguageToDayjsLocale(localeJsonName);
      if (dateLocale) {
        await import(`dayjs/locale/${dateLocale}.js`);
        dayjs.locale(dateLocale);
      }
    }
    getMessages();
  }, []);

  return (
    <BaseIntlProvider
      locale={locale}
      textComponent={React.Fragment}
      messages={messages}
    >
      {children}
    </BaseIntlProvider>
  );
};

export default IntlProvider;
