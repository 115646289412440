export const getActivityLog = state => state.entities.activityLog;
export const getApplications = state => state.entities.application;
export const getBookmarks = state => state.entities.bookmark;
export const getCities = state => state.entities.city;
export const getCompanies = state => state.entities.company;
export const getCompanyUsers = state => state.entities.companyUser;
export const getCountries = state => state.entities.country;
export const getCurrencies = state => state.entities.currency;
export const getGroup = state => state.entities.group;
export const getGroupMembers = state => state.entities.groupMember;
export const getIdentities = state => state.entities.identity;
export const getJobs = state => state.entities.job;
export const getJobCategories = state => state.entities.jobCategory;
export const getJobTitles = state => state.entities.jobTitle;
export const getJobTypes = state => state.entities.jobType;
export const getRecommendations = state => state.entities.recommendation;
export const getSchools = state => state.entities.school;
export const getSkills = state => state.entities.skill;
export const getStudies = state => state.entities.study;
export const getTalentHuntApplications = state =>
  state.entities.talentHuntApplication;
export const getTalents = state => state.entities.talent;
export const getTalentsProfile = state => state.entities.talentProfile;
