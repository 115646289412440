import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { defineMessage, defineMessages } from 'react-intl';
import { CustomClasses, StreamMessage } from 'stream-chat-react';

import { ApplicationSource } from '../../common/constants';

export enum MessagingUIMode {
  POPUP = 'Chat Widget',
  FULL = 'Chat Full Page',
}

export type MessagingChannelContextValue = {
  uiMode: MessagingUIMode;
  lastRegularMessageIdSentByCurrentUser?: string;
  isBackgroundUpdateApplicationStatusInProgress: boolean;
  setIsBackgroundUpdateApplicationStatusInProgress: Dispatch<
    SetStateAction<boolean>
  >;
  showInputPhoneNumber: boolean;
  setShowInputPhoneNumber: Dispatch<SetStateAction<boolean>>;
  ephemeralMessages: StreamMessage[];
  sendEphemeralMessage: (message: StreamMessage) => {
    destroy: () => void;
  };
  destroyEphemeralMessage: (id: string) => void;
};

export const MessagingChannelContext = createContext<
  MessagingChannelContextValue | undefined
>(undefined);

export const useMessagingChannelContext = () => {
  const context = useContext(MessagingChannelContext);
  if (!context) {
    throw new Error(
      'No MessagingChannelContext.Provider found when calling useMessagingChannelContext.'
    );
  }
  return context;
};

export enum ApplicationCVExchangeStatus {
  NONE = 'NONE',
  PENDING = 'PENDING',
  DONE = 'DONE',
  AUTO_SHARED = 'AUTO_SHARED',
}

export enum ApplicationContactsExchangeStatus {
  NONE = 'NONE',
  PENDING = 'PENDING',
  DONE = 'DONE',
}

export enum ApplicationChatCVExchangeRequestStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
}

export enum ApplicationChatContactsExchangeRequestStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
}

export enum ApplicationChatCVRequestActionType {
  CV_REQUEST_CDD = 'CV_REQUEST_CDD',
  CV_REQUEST_CDD_AUTO = 'CV_REQUEST_CDD_AUTO',
  CV_REQUEST_EMP = 'CV_REQUEST_EMP',
  CV_AUTO_SHARED = 'CV_AUTO_SHARED',
}

export enum ApplicationChatContactsRequestActionType {
  CONTACTS_REQUEST_CDD = 'CONTACTS_REQUEST_CDD',
  CONTACTS_REQUEST_EMP = 'CONTACTS_REQUEST_EMP',
}

export enum ApplicationChatEphemeralActionType {
  INPUT_PHONE = 'INPUT_PHONE',
}

export const applicationChatContactsExchangeEmpRequestMessages = defineMessages(
  {
    [ApplicationChatContactsExchangeRequestStatus.PENDING]: {
      id: 'text_chat-log_emp-ask-phone',
      defaultMessage:
        "You have sent the Exchange Phone request. Please wait for the candidate's confirmation.",
    },
    [ApplicationChatContactsExchangeRequestStatus.ACCEPTED]: {
      id: 'text_chat-log_cdd-accept-phone',
      defaultMessage: 'The candidate has accepted the phone exchange request.',
    },
    [ApplicationChatContactsExchangeRequestStatus.REJECTED]: {
      id: 'text_chat-log_cdd-reject-phone',
      defaultMessage: 'The candidate has rejected the phone exchange request.',
    },
    [ApplicationChatContactsExchangeRequestStatus.CANCELED]: {
      id: 'text_chat-log_emp-cancel-ask-phone',
      defaultMessage: 'You have canceled the phone exchange request.',
    },
  }
);

export const applicationGroupChatContactsExchangeEmpRequestMessages = {
  ...applicationChatContactsExchangeEmpRequestMessages,
  ...defineMessages({
    [ApplicationChatContactsExchangeRequestStatus.PENDING]: {
      id: 'text_chat-log_emp-ask-phone_group-convo',
      defaultMessage:
        "{memberName} has sent the Exchange Phone request. Please wait for the candidate's confirmation.",
    },
    [ApplicationChatContactsExchangeRequestStatus.CANCELED]: {
      id: 'text_chat-log_emp-cancel-ask-phone_group-convo',
      defaultMessage: '{memberName} has canceled the phone exchange request.',
    },
  }),
};

export const applicationChatContactsExchangeCddRequestMessages = defineMessages(
  {
    [ApplicationChatContactsExchangeRequestStatus.PENDING]: {
      id: 'text_action-card_cdd-ask-phone',
      defaultMessage:
        'The candidate has asked you to exchange the phone number.',
    },
    [ApplicationChatContactsExchangeRequestStatus.ACCEPTED]: {
      id: 'text_chat-log_emp-accept-phone',
      defaultMessage: 'You have accepted the phone exchange request.',
    },
    [ApplicationChatContactsExchangeRequestStatus.REJECTED]: {
      id: 'text_chat-log_emp-reject-phone',
      defaultMessage: 'You have rejected the phone exchange request.',
    },
    [ApplicationChatContactsExchangeRequestStatus.CANCELED]: {
      id: 'text_chat-log_cdd-cancel-ask-phone',
      defaultMessage: 'The candidate has canceled the phone exchange request.',
    },
  }
);

export const applicationGroupChatContactsExchangeCddRequestMessages = {
  ...applicationChatContactsExchangeCddRequestMessages,
  ...defineMessages({
    [ApplicationChatContactsExchangeRequestStatus.ACCEPTED]: {
      id: 'text_chat-log_emp-accept-phone_group-convo',
      defaultMessage: '{memberName} has accepted the phone exchange request.',
    },
    [ApplicationChatContactsExchangeRequestStatus.REJECTED]: {
      id: 'text_chat-log_emp-reject-phone_group-convo',
      defaultMessage: '{memberName} has rejected the phone exchange request.',
    },
  }),
};

export const applicationChatCVExchangeEmpRequestMessages = defineMessages({
  [ApplicationChatCVExchangeRequestStatus.PENDING]: {
    id: 'text_chat-log_emp-ask-cv',
    defaultMessage:
      "You have sent the CV request. Please wait for the candidate's confirmation.",
  },
  [ApplicationChatCVExchangeRequestStatus.ACCEPTED]: {
    id: 'text_chat-log_cdd-share-cv',
    defaultMessage: 'The candidate has shared the CV.',
  },
  [ApplicationChatCVExchangeRequestStatus.REJECTED]: {
    id: 'text_action-cdd-reject-cv',
    defaultMessage: 'Uh oh, the candidate has rejected the CV request.',
  },
  [ApplicationChatCVExchangeRequestStatus.CANCELED]: {
    id: 'text_chat-log_emp-cancel-ask-cv',
    defaultMessage: 'You have canceled the CV exchange request.',
  },
});

export const applicationGroupChatCVExchangeEmpRequestMessages = {
  ...applicationChatCVExchangeEmpRequestMessages,
  ...defineMessages({
    [ApplicationChatCVExchangeRequestStatus.PENDING]: {
      id: 'text_chat-log_emp-ask-cv_group-convo',
      defaultMessage:
        "{memberName} has sent the CV request. Please wait for the candidate's confirmation.",
    },
    [ApplicationChatCVExchangeRequestStatus.CANCELED]: {
      id: 'text_chat-log_emp-cancel-ask-cv_group-convo',
      defaultMessage: '{memberName} has canceled the CV exchange request.',
    },
  }),
};

export const applicationAutoShareCVRequestMessage = defineMessage({
  id: 'text_chat-log_cdd-share-cv',
  defaultMessage: 'The candidate has shared the CV.',
});

export const applicationChatCVExchangeCddRequestMessages = defineMessages({
  [ApplicationChatCVExchangeRequestStatus.PENDING]: {
    id: 'text_chat-log_cdd-share-cv',
    defaultMessage: 'The candidate has shared the CV.',
  },
  [ApplicationChatCVExchangeRequestStatus.ACCEPTED]: {
    id: 'text_chat-log_emp-accept-cv',
    defaultMessage: "You have accepted the candidate's CV.",
  },
  [ApplicationChatCVExchangeRequestStatus.REJECTED]: {
    id: 'text_chat-log_emp-reject-cv',
    defaultMessage: "You have rejected the candidate's CV.",
  },
  [ApplicationChatCVExchangeRequestStatus.CANCELED]: {
    id: 'text_chat-log_cdd-cancel-share-cv',
    defaultMessage: 'The candidate has canceled the CV exchange request.',
  },
});

export const applicationGroupChatCVExchangeCddRequestMessages = {
  ...applicationChatCVExchangeCddRequestMessages,
  ...defineMessages({
    [ApplicationChatCVExchangeRequestStatus.ACCEPTED]: {
      id: 'text_chat-log_emp-accept-cv_group-convo',
      defaultMessage: "{memberName} has accepted the candidate's CV.",
    },
    [ApplicationChatCVExchangeRequestStatus.REJECTED]: {
      id: 'text_chat-log_emp-reject-cv_group-convo',
      defaultMessage: "{memberName} has rejected the candidate's CV.",
    },
  }),
};

export const applicationChatContactsExchangeStatusMessages = defineMessages({
  [ApplicationContactsExchangeStatus.NONE]: {
    id: 'chat-action-text_exchange-phone',
    defaultMessage: 'Exchange phone',
  },
  [ApplicationContactsExchangeStatus.PENDING]: {
    id: 'chat-action-text_exchanging-phone',
    defaultMessage: 'Exchanging phone',
  },
  [ApplicationContactsExchangeStatus.DONE]: {
    id: 'chat-action-text_exchanged-phone',
    defaultMessage: 'Exchanged phone',
  },
});

export const applicationChatCVExchangeStatusMessages = defineMessages({
  [ApplicationCVExchangeStatus.NONE]: {
    id: 'chat-action-text_request-cv',
    defaultMessage: 'Request CV',
  },
  [ApplicationCVExchangeStatus.PENDING]: {
    id: 'chat-action-text_requesting-cv',
    defaultMessage: 'Requesting CV',
  },
  [ApplicationCVExchangeStatus.DONE]: {
    id: 'button-text_view-cv',
    defaultMessage: 'View CV',
  },
  [ApplicationCVExchangeStatus.AUTO_SHARED]: {
    id: 'button-text_view-cv',
    defaultMessage: 'View CV',
  },
});

export const getStreamSystemMessages = defineMessages({
  newMessages: {
    id: 'text_new-message',
    defaultMessage: 'New Messages!',
  },
  close: {
    id: 'interactive-close',
    defaultMessage: 'Close',
  },
});

export const defaultTemplateMessages = [
  defineMessage({
    id: 'text_chat-emp-default-mesage_reject',
    defaultMessage:
      'Thank you for your interest in the position. After careful consideration, I regret to inform you that you have not been selected for the role. I wish you all the best in your future endeavors.',
  }),
  defineMessage({
    id: 'text_chat-emp-default-mesage_considering',
    defaultMessage:
      'Thank you for your interest. I will review your application carefully and consider your qualifications and experience for the role. I will get back to you soon with an update on the next steps.',
  }),
  defineMessage({
    id: 'text_chat-emp-default-mesage_asking-cv',
    defaultMessage:
      'Hi, I hope this message finds you well. Could you please provide me with an updated copy of your CV for my review?',
  }),
] as const;

export const channelPreviewMessages = defineMessages({
  nameSentImage: {
    id: 'text-cdd-sent-photo',
    defaultMessage: '{name} sent a photo.',
  },
  nameSentVideo: {
    id: 'text-candidate-sent-a-video',
    defaultMessage: '{name} sent a video.',
  },
  youSentImage: {
    id: 'text-emp-sent-photo',
    defaultMessage: 'You sent a photo.',
  },
  nameSentDocument: {
    id: 'text-cdd-sent-doc',
    defaultMessage: '{name} sent {fileName}.',
  },
  youSentDocument: {
    id: 'text-emp-sent-doc',
    defaultMessage: 'You sent {fileName}.',
  },
});

export enum MessagingGtmEvent {
  MESSAGE_SENT = 'messaging-message-sent',
  ZALO_NOTIFICATION_PHONE_SUBMITTED = 'zalo-notification-phone-submitted',
}

export enum AttachmentType {
  FILE = 'file',
  IMAGE = 'image',
  VIDEO = 'video',
}

export const dayjsCalendarMessages = defineMessages({
  lastDay: {
    id: 'text-chat-last-day',
    defaultMessage: '[Yesterday at] LT',
  },
  sameDay: {
    id: 'text-chat-same-day',
    defaultMessage: '[Today at] LT',
  },
  nextDay: {
    id: 'text-chat-next-day',
    defaultMessage: '[Tomorrow at] LT',
  },
  lastWeek: {
    id: 'text-chat-last-week',
    defaultMessage: '[Last] dddd [at] LT',
  },
  nextWeek: {
    id: 'text-chat-next-week',
    defaultMessage: 'dddd [at] LT',
  },
});

export enum ChannelQueryParam {
  APP_STATUS = 'app_status',
  CHANNEL_ID = 'cid',
  KEYWORD = 'keyword',
  SORT_BY_UNREAD = 'sort_by_unread',
  JOB_ID = 'job_id',
  APP_SOURCE = 'app_source',
  SOURCE = 'source',
  OPEN_TEMPLATE_DRAWER = 'op_tpl_drawer',
  POPUP_CHANNEL_ID = 'pop_cid',
}

export const customClasses: CustomClasses = {
  chat: 'messaging-chat',
  chatContainer: 'message-chat-container',
  channel: 'messaging-chat-channel',
  channelList: 'messaging-chat-channel-list',
  message: 'messaging-chat-channel-message',
  messageList: 'messaging-chat-message-list',
};

export const FilterApplicationSource = {
  allCandidates: 'allCandidates',
  applications: 'applications',
  talentSearch: 'talentSearch',
  recommendedTalent: 'recommendedTalent',
} as const;

export type FilterApplicationSource =
  (typeof FilterApplicationSource)[keyof typeof FilterApplicationSource];

export const FilterApplicationSourceToApplicationSourceMap: Record<
  FilterApplicationSource,
  ApplicationSource[] | undefined
> = {
  [FilterApplicationSource.allCandidates]: undefined,
  [FilterApplicationSource.applications]: [
    ApplicationSource.IOS,
    ApplicationSource.ANDROID,
    ApplicationSource.WEB,
    // We include this for now as those applications were present earlier too.
    ApplicationSource.UNKNOWN,
  ],
  [FilterApplicationSource.talentSearch]: [ApplicationSource.TALENT_SEARCH_WEB],
  [FilterApplicationSource.recommendedTalent]: [
    ApplicationSource.RECOMMENDED_TALENT,
  ],
};
