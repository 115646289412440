import React from 'react';
import styled from 'styled-components';

import useIsPremiumFiltersEnabled from '../../../common/hooks/useIsPremiumFiltersEnabled';
import useIsPremiumJobEnabled from '../../../common/hooks/useIsPremiumJobEnabled';
import useIsProfileLimitAccessEnabled from '../../../common/hooks/useIsProfileLimitAccessEnabled';
import useIsScreeningQuestionEnabled from '../../../common/hooks/useIsScreeningQuestionEnabled';
import { getVIPMembershipBenefits } from './constants';
import GlintsVIPBenefit from './GlintsVIPBenefit';
import GlintsVIPFreeCreditBenefit from './GlintsVIPFreeCreditBenefit';

type BaseProps = {
  hideDescription?: boolean;
  includeComingSoon?: boolean;
  showLearnMore?: boolean;
};

type FreeCreditProps =
  | {
      includeFreeCreditBenefit?: false;
      freeCreditAmount?: never;
    }
  | {
      includeFreeCreditBenefit: true;
      freeCreditAmount: number;
    };

type Props = BaseProps & FreeCreditProps;

export default function GlintsVIPBenefits({
  hideDescription,
  includeComingSoon = false,
  includeFreeCreditBenefit = false,
  showLearnMore,
  freeCreditAmount: amount,
}: Props) {
  const isScreeningQuestionEnabled = useIsScreeningQuestionEnabled({
    skipCompanyCountryCheck: true,
  });
  const isPremiumFilterEnabled = useIsPremiumFiltersEnabled();
  const isFullProfileAccessEnabled = useIsProfileLimitAccessEnabled({
    skipVIPMembershipCheck: true,
  });
  const isPremiumJobEnabled = useIsPremiumJobEnabled();
  const benefits = getVIPMembershipBenefits({
    isScreeningQuestionEnabled,
    isPremiumFilterEnabled,
    isFullProfileAccessEnabled,
    isPremiumJobEnabled,
  }).filter(props => {
    const isComingSoon = 'isComingSoon' in props;
    return includeComingSoon || !isComingSoon;
  });

  return (
    <BenefitsGrid>
      {benefits.map(benefit => (
        <GlintsVIPBenefit
          key={benefit.heading.id}
          {...benefit}
          showLearnMore={showLearnMore}
          hideDescription={hideDescription}
        />
      ))}
      {includeFreeCreditBenefit && amount && (
        <GlintsVIPFreeCreditBenefit
          hideDescription={hideDescription}
          amount={amount}
        />
      )}
    </BenefitsGrid>
  );
}

const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-gap: 20px;
`;
