import CALL_API from '../middleware/api_key';
import { Schemas } from '../middleware/schemas';

export const Actions = {
  INDUSTRIES_REQUEST: 'glints/industries/INDUSTRIES_REQUEST',
  INDUSTRIES_SUCCESS: 'glints/industries/INDUSTRIES_SUCCESS',
  INDUSTRIES_FAILURE: 'glints/industries/INDUSTRIES_FAILURE',
};

export function fetchIndustries(options) {
  return {
    [CALL_API]: {
      types: [
        Actions.INDUSTRIES_REQUEST,
        Actions.INDUSTRIES_SUCCESS,
        Actions.INDUSTRIES_FAILURE,
      ],
      endpoint: 'industries',
      schema: Schemas.INDUSTRY_ARRAY,
      options: {
        ...options,
        params: {
          ...(options ? options.params : {}),
          order: 'name ASC',
          limit: 0,
        },
      },
    },
  };
}
