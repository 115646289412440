import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { closeModal } from './Actions';
import { modalRegistry } from './Constants';
import { getModalData, getModalName } from './Selectors';

const Modal = ({ name, data, closeModal }) => {
  const ModalComponent = modalRegistry[name];

  if (!ModalComponent) {
    return null;
  }

  return <ModalComponent data={data} onClose={closeModal} />;
};

Modal.propTypes = {
  name: PropTypes.string,
  data: PropTypes.any,
  closeModal: PropTypes.func,
};

const mapStateToProps = state => ({
  name: getModalName(state),
  data: getModalData(state),
});

const mapDispatchToProps = {
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
