import React, { ComponentProps } from 'react';
import { apm } from '@elastic/apm-rum';
import { ErrorBoundary } from 'react-error-boundary';

import EmptyErrorBoundaryFallbackComponent from '../EmptyErrorBoundaryFallbackComponent';
type ErrorBoundaryWithCrashReportingProps = {
  children: React.ReactNode;
  /**
   Override the default onError behavior
   **/
  onError?: ComponentProps<typeof ErrorBoundary>['onError'];
} & (
  | { fallback: ComponentProps<typeof ErrorBoundary>['fallback'] }
  | { fallbackRender: ComponentProps<typeof ErrorBoundary>['fallbackRender'] }
);

export default function ErrorBoundaryWithCrashReporting({
  children,
  onError,
  ...props
}: ErrorBoundaryWithCrashReportingProps) {
  const getFallbackProps = () => {
    if ('fallback' in props) {
      return {
        fallback: props.fallback || <EmptyErrorBoundaryFallbackComponent />,
      };
    }
    return {
      fallbackRender:
        props.fallbackRender || EmptyErrorBoundaryFallbackComponent,
    };
  };

  return (
    <ErrorBoundary
      {...getFallbackProps()}
      onError={(error, info) => {
        if (onError) {
          onError(error, info);
          return;
        }
        apm.captureError(error);
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
