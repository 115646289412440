import CALL_API from '../middleware/api_key';
import { Schemas } from '../middleware/schemas';

export const Actions = {
  SCHOOLS_REQUEST: 'glints/schools/SCHOOLS_REQUEST',
  SCHOOLS_SUCCESS: 'glints/schools/SCHOOLS_SUCCESS',
  SCHOOLS_FAILURE: 'glints/schools/SCHOOLS_FAILURE',
};

export function fetchSchools(options) {
  return {
    type: 'fetchSchools',
    [CALL_API]: {
      types: [
        Actions.SCHOOLS_REQUEST,
        Actions.SCHOOLS_SUCCESS,
        Actions.SCHOOLS_FAILURE,
      ],
      endpoint: 'schools',
      schema: Schemas.SCHOOL_ARRAY,
      options: {
        ...options,
        params: {
          includeUnNormalizedSchools: true,
          order: 'name ASC',
          ...(options ? options.params : {}),
        },
      },
    },
  };
}
