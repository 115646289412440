import { Greyscale, ScreenSize, SecondaryColor } from 'glints-aries/es';
import { DropShadow, Fonts, Typography } from 'glints-aries/es/@next';
import { borderRadius4 } from 'glints-aries/es/@next/utilities/borderRadius';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import styled, { createGlobalStyle } from 'styled-components';

export const CardContainer = styled.div`
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
`;

export const ClippedTypography = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 500px;
`;

export const JobTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0;
`;

export const StreamUIThemeOverride = createGlobalStyle`
  .messaging-chat {
    .messaging-chat-channel-message {
      padding: 8px 0;

      &:has(> .messaging-chat-channel-text-message-top) {
        padding-bottom: 4px;
      }

      &:has(> .messaging-chat-channel-text-message-middle) {
        padding: 4px 0;
      }

      &:has(> .messaging-chat-channel-text-message-bottom) {
        padding-top: 4px;
      }
    }

    .messaging-chat-message-list {
      flex: 1;
      padding: 16px;
      padding-right: 8px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .str-chat__channel-list-messenger {
      padding: 0;
      background: none;

      .str-chat__channel-list-messenger__main {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      .str-chat__avatar {
        margin: 0;
      }
    }

    .str-chat__main-panel {
      height: 100%;
      display: flex;
      flex-direction: column;
      box-shadow: ${DropShadow.base};

      .str-chat__list-notifications {
        .notification-error {
          display: none;
        }
      }
    }

    .image-gallery-index,
    .image-gallery-fullscreen-button {
      display: none;
    }

    .str-chat__modal {
      background-color: rgba(45, 45, 45, 0.5);

      .str-chat__modal__close-button {
        font-family: ${Fonts.NotoSans};
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.25px;
        background-color: ${Neutral.B18};
        padding: 16px;
        &:hover {
          opacity: 1;
        }
      }

      .str-chat__modal__inner {
        padding: 0;
      }
    }

    .str-chat__custom-notification,
    .str-chat__message-notification {
      background-color: ${SecondaryColor.actionblue};
    }

    .str-chat__textarea__textarea {
      padding: 12px 16px;
      max-height: 273px;
      border-radius: ${borderRadius4};
      border-color: ${Neutral.B85};

      @media (max-height: ${ScreenSize.tablet}px) {
        max-height: 200px;
      }
    }

    time,
    .str-chat__date-separator-date {
      font: inherit;
      text-transform: capitalize;
    }

    .str-chat__loading-channels {
      padding: 16px;
      width: 100%;
      height: auto;
      background-color: ${Greyscale.white};

      .str-chat__loading-channels-avatar,
      .str-chat__loading-channels-username,
      .str-chat__loading-channels-status {
        background: linear-gradient(
          110.76deg,
          #eef0f3 36.01%,
          rgba(217, 217, 217, 0.24) 47.36%,
          #eef0f3 57.51%
        );
      }

      .str-chat__loading-channels-status {
        width: 100%;
      }
    }

    .str-chat__message-attachment {
      border-radius: 8px;
    }

    .str-chat__message-attachment--file {
      border: 1px solid #e9e9e9;
    }
  }
`;
