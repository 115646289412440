import React, { FC, ReactElement, useEffect, useMemo } from 'react';
import { apm } from '@elastic/apm-rum';
import { LoadableComponent } from '@loadable/component';
import { Reducer } from '@reduxjs/toolkit';
import { ConnectedComponent } from 'react-redux';
import {
  matchPath,
  Route as BaseRoute,
  RouterProps,
  useLocation,
} from 'react-router';

import authenticate from '../common/authenticate';
import { checkCompanyVerificationStatus } from '../common/checkCompanyVerificationStatus';
import NoIndexWrapper from '../common/NoIndexWrapper';
import { searchStringToParamsObject } from '../common/queryParamHelpers';
import { scrollTo } from '../common/scrollTo';
import { verify } from '../common/verify';
import { withUseMobileAppPrompt } from '../modules/UseMobileAppPrompt/withUseMobileAppPrompt';
import { injectAsyncReducer } from '../store';
import { withDashboardRedirect } from './withDashboardRedirect';

type RouteComponentProps = {
  component:
    | FC<RouterProps>
    | FC<RouteComponentProps>
    | LoadableComponent<any>
    | ConnectedComponent<any, any>;
  mustBeAuthenticated?: boolean;
  allowUnverifiedUserEmail?: boolean;
  allowUnverifiedCompany?: boolean;
  index?: boolean;
  path?: string | readonly string[];
  exact?: boolean;
  desktopOnly?: boolean;
};

// Patches the route so that it will pass the match params as an ownProp and
// include a query hash inside the location ownProp. These functionalities were
// removed after react - router 3 but many of our page components still depend
// on these props. Also replicates the functionality of the now incompatible
// react-router-scroll router middleware to scroll to the top of the page on
// route change.
const Route: FC<RouteComponentProps> = ({
  component: Component,
  mustBeAuthenticated,
  allowUnverifiedUserEmail = false,
  index = true,
  allowUnverifiedCompany = true,
  desktopOnly = false,
  ...props
}) => {
  const { pathname } = useLocation();
  const userEmailMustBeVerified = !allowUnverifiedUserEmail;

  useEffect(() => {
    scrollTo(0, 0);

    if (pathname === '/job/create') {
      const transaction = apm.startTransaction(pathname, 'custom-route-change');
      window.apmCustomRouteChangeTransaction = transaction;
    }

    if (pathname === '/manage-candidates') {
      const transaction = apm.startTransaction(pathname, 'page-load', {
        managed: true,
      });
      window.apmManageCandidatesPageTransaction = transaction;
    }

    if (pathname === '/dashboard') {
      const transaction = apm.startTransaction(pathname, 'page-load', {
        managed: true,
      });
      window.apmCompanyDashboardPageTransaction = transaction;
    }

    const isApplicationDetailsPage = matchPath(pathname, {
      path: '/jobs/:jobId/applications/:applicationId',
      exact: true,
    });

    if (isApplicationDetailsPage) {
      const transaction = apm.startTransaction(pathname, 'page-load', {
        managed: true,
      });
      window.apmApplicationDetailsPageTransaction = transaction;
    }

    if (pathname.startsWith('/company/team')) {
      const getCompanyTeamReducer = async () => {
        const companyTeamReducer = await import(
          '../modules/CompanyTeam/Reducer'
        ).then(module => module.default);
        injectAsyncReducer(
          'companyTeam',
          companyTeamReducer as unknown as Reducer
        );
      };

      getCompanyTeamReducer();
    }

    if (pathname.startsWith('/settings')) {
      const getSettingsReducer = async () => {
        const settingsReducer = await import(
          '../modules/Settings/Reducer'
        ).then(module => module.default);
        injectAsyncReducer('settings', settingsReducer as unknown as Reducer);
      };

      getSettingsReducer();
    }
  }, [pathname]);

  // Apply HOCs based on custom route parameters. We have to use useMemo here to
  // ensure that the HOCs won't re-run when the route's props change (e.g. when
  // the search string changes). This would lead to a complete recreation of the
  // entire page component.
  const AugmentedComponent = useMemo(
    () =>
      [
        userEmailMustBeVerified && verify,
        !allowUnverifiedCompany && checkCompanyVerificationStatus,
        mustBeAuthenticated && authenticate,
        !index && NoIndexWrapper,
        desktopOnly && withUseMobileAppPrompt,
        withDashboardRedirect,
      ]
        .filter(f => f)
        .reduce(
          (result, hoc) =>
            (
              hoc as (
                WrappedComponent: any
              ) => (props: any) => ReactElement | null
            )(result),
          Component
        ),
    [
      userEmailMustBeVerified,
      allowUnverifiedCompany,
      mustBeAuthenticated,
      index,
      desktopOnly,
      Component,
    ]
  );

  return (
    <BaseRoute
      {...props}
      render={routeProps => (
        <AugmentedComponent
          {...routeProps}
          location={{
            ...routeProps.location,
            query: searchStringToParamsObject(routeProps.location.search),
          }}
          params={routeProps.match.params}
        />
      )}
    />
  );
};

export default Route;
