import React, { ComponentType, useEffect, useRef } from 'react';
import { useModal } from 'glints-aries/es/@next';
import { omit } from 'lodash-es';
import { useHistory, useLocation } from 'react-router';

import { EmployerPurchaseProductType } from '../../../common/interfaces/entities';
import { CreditProductPurchaseSuccessState } from '../../../common/interfaces/routerState';
import { GlintsVIPSuccessModalContent } from '../PurchaseModals/CreditProductPurchaseSuccessModal/GlintsVIPSuccessModalContent';
import { JobBoostSuccessModalContent } from '../PurchaseModals/CreditProductPurchaseSuccessModal/JobBoostSuccessModalContent';
import { RecommendedTalentChatSuccessModalContent } from '../PurchaseModals/CreditProductPurchaseSuccessModal/RecommendedTalentChatSuccessModalContent';
import { TalentSearchSuccessModalContent } from '../PurchaseModals/CreditProductPurchaseSuccessModal/TalentSearchSuccessModalContent';
import { trackPaidFeatureSuccessfullyAdded } from '../PurchaseModals/PurchaseProductOrderSummarySection/tracking';
import { CreditProductPurchaseOrderForAfterPayment } from '../PurchaseModals/types';

type ComponentTypeProps = {
  onClose: () => void;
  creditProductPurchaseOrder: CreditProductPurchaseOrderForAfterPayment;
};

type ModalContent = ComponentType<ComponentTypeProps> & {
  ModalTitle: ComponentType<ComponentTypeProps>;
  ModalFooter: ComponentType<ComponentTypeProps>;
};

const MappedModalContentBasedOnType: Record<
  EmployerPurchaseProductType,
  ModalContent
> = {
  [EmployerPurchaseProductType.HOT_JOB]: JobBoostSuccessModalContent,
  [EmployerPurchaseProductType.RECOMMENDED_TALENT_CHAT]:
    RecommendedTalentChatSuccessModalContent,
  [EmployerPurchaseProductType.VIP_MEMBERSHIP]: GlintsVIPSuccessModalContent,
  [EmployerPurchaseProductType.TALENT_SEARCH]: TalentSearchSuccessModalContent,
};

type Params = {
  onClose?: (
    creditProductPurchaseSuccess?: CreditProductPurchaseSuccessState['creditProductPurchaseSuccess']
  ) => void;
  productTypesToHandle?: EmployerPurchaseProductType[];
};

// VIP_MEMBERSHIP is handled globally, so we don't need to handle it here
const defaultProductTypesToHandle: Exclude<
  EmployerPurchaseProductType,
  typeof EmployerPurchaseProductType.VIP_MEMBERSHIP
>[] = Object.values(
  omit(EmployerPurchaseProductType, EmployerPurchaseProductType.VIP_MEMBERSHIP)
);

export default function useShowCreditProductPurchaseSuccessModalAsAlert(
  params?: Params
) {
  const { onClose } = params || {};

  const location = useLocation<CreditProductPurchaseSuccessState | undefined>();
  const history = useHistory();

  const { open: showModal, close: closeModal } = useModal();
  const showAlertCreditProductPurchaseSuccessWithLocationState = () => {
    if (!location.state?.creditProductPurchaseSuccess) {
      return;
    }
    const typesToHandle =
      params?.productTypesToHandle || defaultProductTypesToHandle;

    const { creditProductPurchaseSuccess } = location.state;

    const productType = creditProductPurchaseSuccess.product.type;

    if (!typesToHandle.includes(productType)) {
      return;
    }

    const isUsingDefaultHeader =
      productType !== EmployerPurchaseProductType.VIP_MEMBERSHIP;

    trackPaidFeatureSuccessfullyAdded(
      productType,
      creditProductPurchaseSuccess.companyProductInventory?.amount ?? 0
    );

    const handleOnClose = () => {
      closeModal();
      const nextState = omit(location.state, 'creditProductPurchaseSuccess');

      history.replace({
        ...location,
        state: nextState,
      });

      onClose?.(creditProductPurchaseSuccess);
    };

    const Title = () => {
      const Component = MappedModalContentBasedOnType[productType].ModalTitle;

      return (
        <Component
          {...{
            creditProductPurchaseOrder: creditProductPurchaseSuccess,
            onClose: handleOnClose,
          }}
        />
      );
    };

    const Footer = () => {
      const Component = MappedModalContentBasedOnType[productType].ModalFooter;

      return (
        <Component
          {...{
            creditProductPurchaseOrder: creditProductPurchaseSuccess,
            onClose: handleOnClose,
          }}
        />
      );
    };

    const Content = () => {
      const Component = MappedModalContentBasedOnType[productType];

      return (
        <Component
          {...{
            creditProductPurchaseOrder: creditProductPurchaseSuccess,
            onClose: handleOnClose,
          }}
        />
      );
    };

    showModal({
      header: <Title />,
      customActions: <Footer />,
      onClose: handleOnClose,
      closeOnClickOutside: true,
      children: <Content />,
      headerHasDefaultPadding: isUsingDefaultHeader,
      showCloseButton: isUsingDefaultHeader,
      style: {
        minWidth: 620,
        maxWidth: 980,
        alignItems:
          productType === EmployerPurchaseProductType.VIP_MEMBERSHIP
            ? 'center'
            : undefined,
      },
      zIndexOverride: 1000,
    });
  };

  // using useRef to bypass having infinite rerenders due to useEffect deps as here deps are nested objects which changes reference every time
  const showAlertCreditProductPurchaseSuccessWithLocationStateRef = useRef(
    showAlertCreditProductPurchaseSuccessWithLocationState
  );
  // every time this hook is called, we want to update the ref to the latest function
  showAlertCreditProductPurchaseSuccessWithLocationStateRef.current =
    showAlertCreditProductPurchaseSuccessWithLocationState;

  useEffect(() => {
    showAlertCreditProductPurchaseSuccessWithLocationStateRef.current();
  }, [location.state?.creditProductPurchaseSuccess]);

  return null;
}
