import { Device } from 'glints-aries';
import { space4, space8 } from 'glints-aries/es/@next/utilities/spacing';
import styled from 'styled-components';

export const StyledLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${space8};
  margin-bottom: ${space8};
`;

export const StyledSelectField = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space4};
  max-width: 40%;

  &[data-fullwidth='true'] {
    max-width: 100%;
  }

  @media ${Device.tablet} {
    max-width: 100%;
  }
`;
