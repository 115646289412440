import { space8 } from 'glints-aries/es/@next/utilities/spacing';
import styled from 'styled-components';

export const StyledButton = styled.button`
  width: 258px;
  display: flex;
  gap: ${space8};
  padding: 17px 24px 12px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #fff240;
  box-shadow:
    0px -1px 0px 0px rgba(0, 0, 0, 0.2) inset,
    6px 6px 0px 0px #ec2728;
  border: none;
  position: absolute;
  bottom: -28px;
  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
  }
`;
