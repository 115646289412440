import { handleActions } from 'redux-actions';

import { trackEmployerLoggedInEvent } from '../CompanyOnboarding/SignInPage/tracking';
import { Actions } from './Actions';
import { errorMessages, serverErrorMessages } from './Constants';

export const initialState = {
  loading: false,
  success: false,
  error: null,

  facebook: {
    loading: false,
    success: false,
    error: null,
  },

  linkedIn: {
    loading: false,
    success: false,
    error: null,
  },

  multiFactorAuthentication: {
    isRequired: false,
    mfaToken: null,
    isMfaTokenExpired: false,
    mfaDetails: {
      email: null,
      sms: null,
    },
  },
};

const getGenericLogInFailureMessageAndDescription = payload => {
  if (payload.response) {
    return [errorMessages.errorOccurred, errorMessages.engineersNotified];
  }

  if (payload.message === 'Network Error') {
    return [errorMessages.networkError, errorMessages.checkIfConnected];
  }

  return [payload.message, errorMessages.engineersNotified];
};

export default handleActions(
  {
    [Actions.LOGIN_REQUEST]: state => ({
      ...state,
      loading: true,
      success: false,
      error: null,
    }),
    [Actions.LOGIN_SUCCESS]: state => {
      trackEmployerLoggedInEvent();
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
      };
    },
    [Actions.LOGIN_FAILURE]: (state, action) => {
      const err = action.payload.response;
      let message = null;
      let description = null;

      if (err?.data?.error === serverErrorMessages.MFA_REQUIRED) {
        return {
          ...state,
          loading: false,
          success: false,
          error: {
            message: errorMessages.verificationCodeRequired,
            description: errorMessages.verificationCodeRequired,
          },

          multiFactorAuthentication: {
            isRequired: true,

            mfaToken: err.data?.mfa_token,
            isMfaTokenExpired: false,
            mfaDetails: {
              email: err.data?.mfa_details?.email,
              sms: err.data?.mfa_details?.sms,
              whatsapp: err.data?.mfa_details?.whatsapp,
            },
          },
        };
      } else if (err?.status === 401 || err?.status === 400) {
        message = errorMessages.invalidEmailPassword;
        description = errorMessages.enterCorrectEmailPassword;
      } else if (err?.status === 429) {
        message = errorMessages.loginRateLimitExceeded;
        description = errorMessages.loginRateLimitExceededRetry;
      } else {
        [message, description] = getGenericLogInFailureMessageAndDescription(
          action.payload
        );
      }

      return {
        ...state,
        error: { message, description },
        loading: false,
        success: false,
      };
    },
    [Actions.RESET_LOGIN_STATE]: () => initialState,
    [Actions.LOGIN_WITH_FACEBOOK_REQUEST]: state => ({
      ...state,
      facebook: {
        ...state.facebook,
        loading: true,
        success: false,
        error: null,
      },
    }),
    [Actions.LOGIN_WITH_FACEBOOK_SUCCESS]: state => ({
      ...state,
      facebook: {
        ...state.facebook,
        loading: false,
        success: true,
        error: null,
      },
    }),
    [Actions.LOGIN_WITH_FACEBOOK_FAILURE]: (state, action) => ({
      ...state,
      facebook: {
        ...state.facebook,
        loading: false,
        success: false,
        error: action.payload,
      },
    }),
    [Actions.LOGIN_WITH_LINKEDIN_REQUEST]: state => ({
      ...state,
      linkedIn: {
        ...state.linkedIn,
        loading: true,
        success: false,
        error: null,
      },
    }),
    [Actions.LOGIN_WITH_LINKEDIN_SUCCESS]: state => ({
      ...state,
      linkedIn: {
        ...state.linkedIn,
        loading: false,
        success: true,
        error: null,
      },
    }),
    [Actions.LOGIN_WITH_LINKEDIN_FAILURE]: (state, action) => ({
      ...state,
      linkedIn: {
        ...state.linkedIn,
        loading: false,
        success: false,
        error: action.payload,
      },
    }),
    [Actions.MFA_TOKEN_EXPIRE]: state => ({
      ...state,

      multiFactorAuthentication: {
        isRequired: false,
        mfaToken: null,
        isMfaTokenExpired: true,
      },
    }),
  },
  initialState
);
