import { createAction } from 'redux-actions';
import { setSubmitFailed } from 'redux-form';

import { initializeSession, setSessionToken } from '../../actions/session';
import axios from '../../common/axios-ts';
import { stringifyPhoneNumber } from '../../components/Form/PhoneNumberInput';
import config from '../../config';
import { login } from '../Login';
import { getLinkedInCsrfToken } from './Selectors';

export const Actions = {
  CREATE_USER_REQUEST: 'glints/signUp/CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'glints/signUp/CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'glints/signUp/CREATE_USER_FAILURE',

  RESET_SIGN_UP_STATE: 'glints/signUp/RESET_SIGN_UP_STATE',

  SIGN_UP_WITH_FACEBOOK_REQUEST: 'glints/signUp/SIGN_UP_WITH_FACEBOOK_REQUEST',
  SIGN_UP_WITH_FACEBOOK_SUCCESS: 'glints/signUp/SIGN_UP_WITH_FACEBOOK_SUCCESS',
  SIGN_UP_WITH_FACEBOOK_FAILURE: 'glints/signUp/SIGN_UP_WITH_FACEBOOK_FAILURE',

  SIGN_UP_WITH_LINKEDIN_REQUEST: 'glints/signUp/SIGN_UP_WITH_LINKEDIN_REQUEST',
  SIGN_UP_WITH_LINKEDIN_SUCCESS: 'glints/signUp/SIGN_UP_WITH_LINKEDIN_SUCCESS',
  SIGN_UP_WITH_LINKEDIN_FAILURE: 'glints/signUp/SIGN_UP_WITH_LINKEDIN_FAILURE',

  OPEN_FACEBOOK_DIALOG: 'glints/signUp/OPEN_FACEBOOK_DIALOG',
  CLOSE_FACEBOOK_DIALOG: 'glints/signUp/CLOSE_FACEBOOK_DIALOG',

  OPEN_LINKEDIN_DIALOG: 'glints/signUp/OPEN_LINKEDIN_DIALOG',
  CLOSE_LINKEDIN_DIALOG: 'glints/signUp/CLOSE_LINKEDIN_DIALOG',
};

export const createUserRequest = createAction(Actions.CREATE_USER_REQUEST);
export const createUserSuccess = createAction(Actions.CREATE_USER_SUCCESS);
export const createUserFailure = createAction(Actions.CREATE_USER_FAILURE);

export const resetSignUpState = createAction(Actions.RESET_SIGN_UP_STATE);

export const signUpWithFacebookRequest = createAction(
  Actions.SIGN_UP_WITH_FACEBOOK_REQUEST
);
export const signUpWithFacebookSuccess = createAction(
  Actions.SIGN_UP_WITH_FACEBOOK_SUCCESS
);
export const signUpWithFacebookFailure = createAction(
  Actions.SIGN_UP_WITH_FACEBOOK_FAILURE
);

export const signUpWithLinkedInRequest = createAction(
  Actions.SIGN_UP_WITH_LINKEDIN_REQUEST
);
export const signUpWithLinkedInSuccess = createAction(
  Actions.SIGN_UP_WITH_LINKEDIN_SUCCESS
);
export const signUpWithLinkedInFailure = createAction(
  Actions.SIGN_UP_WITH_LINKEDIN_FAILURE
);

export const openFacebookDialog = createAction(Actions.OPEN_FACEBOOK_DIALOG);
export const closeFacebookDialog = createAction(Actions.CLOSE_FACEBOOK_DIALOG);

export const openLinkedInDialog = createAction(
  Actions.OPEN_LINKEDIN_DIALOG,
  csrfToken => ({ csrfToken })
);
export const closeLinkedInDialog = createAction(Actions.CLOSE_LINKEDIN_DIALOG);

export function signUp(values) {
  return async dispatch => {
    try {
      dispatch(createUserRequest());
      const response = await axios(null).post('/users', {
        data: {
          ...values,
          phone: stringifyPhoneNumber(JSON.parse(values.phone)),
          role: 'COMPANY',
        },
      });
      dispatch(createUserSuccess(response.data.data.id));
      await dispatch(login(values.email, values.password));
      dispatch(initializeSession());
    } catch (err) {
      dispatch(createUserFailure(err));
      dispatch(setSubmitFailed('signUp'));
    }
  };
}

export function signUpWithFacebook(code) {
  return async dispatch => {
    try {
      dispatch(signUpWithFacebookRequest());
      const response = await axios(null, config.OAUTH2_BASE).post('/facebook', {
        code,
        clientId: config.FACEBOOK_APPID,
        apiClientId: config.CLIENT_ID,
        redirectUri: `${
          document.getElementsByTagName('base')[0].href
        }oauth2/facebook`,
        role: 'COMPANY',
      });
      await dispatch(setSessionToken(response.data.token));
      dispatch(signUpWithFacebookSuccess(response?.data?.data?.id));
    } catch (err) {
      dispatch(signUpWithFacebookFailure(err));
    }
  };
}

export function signUpWithLinkedIn(code) {
  return async (dispatch, getState) => {
    try {
      dispatch(signUpWithLinkedInRequest());
      const response = await axios(null, config.OAUTH2_BASE).post('/linkedin', {
        code,
        clientId: config.LINKEDIN_APPID,
        apiClientId: config.CLIENT_ID,
        redirectUri: `${
          document.getElementsByTagName('base')[0].href
        }oauth2/linkedin`,
        csrfToken: getLinkedInCsrfToken(getState()),
        role: 'COMPANY',
      });
      await dispatch(setSessionToken(response.data.token));
      dispatch(signUpWithLinkedInSuccess(response?.data?.data?.id));
    } catch (err) {
      dispatch(signUpWithLinkedInFailure(err));
    }
  };
}
