import { BorderRadius } from 'glints-aries/es/@next';
import { space8, space64 } from 'glints-aries/es/@next/utilities/spacing';
import styled from 'styled-components';

export const StyledWhatsappButtonWrapper = styled.a`
  position: fixed;
  right: ${space8};
  bottom: 100px;
  border-radius: ${BorderRadius.borderRadiusHalf};
  z-index: 19999;

  img {
    width: ${space64}};
    height: ${space64}};
  }
`;
