import React from 'react';
import { ButtonGroup } from 'glints-aries/es/@next';

import ContactSupportButton from '../../../../components/ContactSupportButton/ContactSupportButton';
import { LogoutButton } from '../LogoutButton/LogoutButton';

export const MenuItemsWithLogoutAndContactSupport = () => (
  <ButtonGroup>
    <ContactSupportButton />
    <LogoutButton />
  </ButtonGroup>
);
