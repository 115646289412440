import React, { ReactNode } from 'react';

import { ApplicationStatus } from '../../common/constants';
import useIsProfileLimitAccessEnabled from '../../common/hooks/useIsProfileLimitAccessEnabled';

type Props = {
  children: ReactNode;
  applicationStatus: ApplicationStatus;
  renderRestrictedComponent: () => JSX.Element;
};

export default function RenderBasedOnApplicationStatusRejected({
  children,
  applicationStatus,
  renderRestrictedComponent,
}: Props) {
  const isProfileLimitAccessEnabled = useIsProfileLimitAccessEnabled({
    skipVIPMembershipCheck: true,
  });

  const shouldRestrictAccess =
    isProfileLimitAccessEnabled &&
    applicationStatus === ApplicationStatus.REJECTED;

  if (!shouldRestrictAccess) {
    return <>{children}</>;
  }

  return renderRestrictedComponent();
}
