import React, { ReactNode } from 'react';

import { useGetAssetURL } from '../common/asset-manager';

type Props = {
  children: ReactNode;
  profilePic?: string | null;
  profilePicUrl?: string | null;
};

const OptionalProfilePictureLink = ({
  profilePic,
  profilePicUrl,
  children,
}: Props) => {
  const { getAssetURL } = useGetAssetURL();

  const url = profilePic
    ? getAssetURL(profilePic, 'profile-picture')
    : profilePicUrl || '';

  return (
    <Choose>
      <When condition={url}>
        {url && (
          <a target="_blank" href={url} rel="noreferrer">
            {children}
          </a>
        )}
      </When>
      <Otherwise>{children}</Otherwise>
    </Choose>
  );
};

export default OptionalProfilePictureLink;
