import React from 'react';
import PropTypes from 'prop-types';

import { getRandomFallbackCompanyBannerPic } from '../../common/asset-manager';
import GlintsPicture from './GlintsPicture';

const CompanyBannerPicture = ({ companyID, bannerPic, ...otherProps }) => {
  if (bannerPic) {
    return (
      <GlintsPicture
        name={bannerPic}
        assetType="company-banner-pic"
        fallback={getRandomFallbackCompanyBannerPic(companyID)}
        fallbackAssetType="default-banner-pic-large"
        {...otherProps}
      />
    );
  } else {
    return (
      <GlintsPicture
        name={getRandomFallbackCompanyBannerPic(companyID)}
        assetType="default-banner-pic-large"
        {...otherProps}
      />
    );
  }
};

CompanyBannerPicture.propTypes = {
  companyID: PropTypes.any.isRequired,
  bannerPic: PropTypes.string,
};

export default CompanyBannerPicture;
