import { useEffect } from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { getIsLoggedIn } from '../../selectors/session';
import { useAppDispatch } from '../../store';
import { LOGOUT_BROADCAST_CHANNEL_NAME } from './constants';
export const LogoutBroadcastListener = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const history = useHistory();

  useEffect(() => {
    if (!isLoggedIn) return;

    const channel = new BroadcastChannel(LOGOUT_BROADCAST_CHANNEL_NAME);
    channel.onmessage = () => history.push('/logout');
    return () => void channel.close();
  }, [isLoggedIn, dispatch, history]);

  return null;
};
