import moment from 'moment';

export const getResumeKeyAndLabel = (resumeFileName: string) => {
  /**
   * Resume in the chat could have been glints profile resume generated,
   * or the resume uploaded by user. Resume that's generated from glints profile is
   * stored in 'temp', meanwhile the user uploaded resume is stored in 'resume' folder.
   */
  let label = 'resume';
  const isTimeStampAtEnd = (resumeFileName: string) => {
    const splittedFileName = resumeFileName.split('-');
    const possibleTimeStamp = splittedFileName.pop();
    return moment(possibleTimeStamp, 'X', true).isValid();
  };

  const fileNameWithoutExtension = resumeFileName.split('.')[0];

  if (isTimeStampAtEnd(fileNameWithoutExtension)) {
    label = 'temp';
  }

  return {
    key: resumeFileName,
    label,
  };
};
