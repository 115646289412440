import { useCallback } from 'react';
import { omit } from 'lodash-es';
import { useHistory, useLocation } from 'react-router';

export default function useRemoveStateFromLocation() {
  const location = useLocation<Record<string, unknown> | undefined>();
  const history = useHistory();

  const removeStateFromLocation = useCallback(
    (key: string) => {
      if (!(key in (location.state ?? {}))) return;
      const nextState = omit(location.state, key);

      history.replace({
        ...location,
        state: nextState,
      });
    },
    [history, location]
  );

  return {
    removeStateFromLocation,
  } as const;
}
