import { TrackingService } from '../../../../../common/tracking/trackingService';

export const trackCompanyJoinRequested = (properties: {
  companyId: string;
}) => {
  const transformedProperties = {
    company_id: properties.companyId,
  };

  TrackingService.logEvent('Company Join Requested', transformedProperties);
};
