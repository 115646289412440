import { CompanyUserEntities, State, UserEntities } from './reduxEntitiesTypes';
import { TrackingService } from './trackingService';

const TrackingActionTypes = {
  FETCH_ME_SUCCESS: 'glints/user/me/FETCH_ME_SUCCESS',
  LOGOUT: 'glints/session/LOGOUT',
  ADD_COMPANY_USER_SUCCESS: 'glints/companyTeam/ADD_COMPANY_USER_SUCCESS',
  DELETE_COMPANY_USER_SUCCESS: 'glints/companyTeam/DELETE_COMPANY_USER_SUCCESS',
} as const;

type LogoutAction = {
  type: 'glints/session/LOGOUT';
};

type FetchMeSuccessAction = {
  type: 'glints/user/me/FETCH_ME_SUCCESS';
  response: {
    result: string;
    entities: {
      user: UserEntities;
    };
  };
};

type AddCompanyUserAction = {
  type: 'glints/companyTeam/ADD_COMPANY_USER_SUCCESS';
  response: {
    entities: {
      companyUser: CompanyUserEntities;
    };
  };
};

type DeleteCompanyUserAction = {
  type: 'glints/companyTeam/DELETE_COMPANY_USER_SUCCESS';
  payload: string;
};

export type TrackingAction =
  | FetchMeSuccessAction
  | LogoutAction
  | AddCompanyUserAction
  | DeleteCompanyUserAction;

const handleActions = {
  [TrackingActionTypes.FETCH_ME_SUCCESS]: (
    _: State,
    action: FetchMeSuccessAction
  ) => {
    const { response } = action as unknown as FetchMeSuccessAction;
    const userId = response.result;
    const user = response.entities.user[userId];

    TrackingService.afterLogin(userId, user.email);

    TrackingService.setUserProperties({
      role: user.role,
      isVerified: user.isVerified,
      email: user.email,
      countryCode: user.CountryCode,
      phone: user.phone,
      firstName: user.firstName,
      lastName: user.lastName,
      createdAt: user.createdAt,
    });

    return;
  },
  [TrackingActionTypes.LOGOUT]: (state: State) => {
    const userId = state.user.me.value;
    const userEmail = state.entities.user[userId]?.email;
    TrackingService.afterLogout(userId, userEmail);

    return;
  },
  [TrackingActionTypes.ADD_COMPANY_USER_SUCCESS]: (
    _: State,
    action: AddCompanyUserAction
  ) => {
    const addedMember = action.response.entities.companyUser;
    const memberId = addedMember[Object.keys(addedMember)[0]].id;

    TrackingService.logEvent('Team Member Added', { member: memberId });
  },
  [TrackingActionTypes.DELETE_COMPANY_USER_SUCCESS]: (
    _: State,
    action: DeleteCompanyUserAction
  ) => {
    const memberId = action.payload;

    TrackingService.logEvent('Team Member Removed', { member: memberId });
  },
} as const;

export default (state: State, action: TrackingAction) => {
  switch (action.type) {
    case TrackingActionTypes.FETCH_ME_SUCCESS:
      return handleActions[TrackingActionTypes.FETCH_ME_SUCCESS](state, action);
    case TrackingActionTypes.LOGOUT:
      return handleActions[TrackingActionTypes.LOGOUT](state);
    case TrackingActionTypes.ADD_COMPANY_USER_SUCCESS:
      return handleActions[TrackingActionTypes.ADD_COMPANY_USER_SUCCESS](
        state,
        action
      );
    case TrackingActionTypes.DELETE_COMPANY_USER_SUCCESS:
      return handleActions[TrackingActionTypes.DELETE_COMPANY_USER_SUCCESS](
        state,
        action
      );
    default:
      return;
  }
};
