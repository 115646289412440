import { Device, ScreenSize } from 'glints-aries';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  font-family: Poppins, sans-serif;

  @media ${Device.mobileS} {
    padding: 0 10px;
  }
  @media (min-width: ${ScreenSize.mobileS}px) {
    padding: 0 10px;
  }
  @media (min-width: ${ScreenSize.mobileM}px) {
    padding: 0 20px;
  }
  @media (min-width: ${ScreenSize.mobileL}px) {
    padding: 0 24px;
  }
  @media (min-width: ${ScreenSize.tablet}px) {
    padding: 0 32px;
  }
  @media (min-width: ${ScreenSize.desktopS}px) {
    padding: 0 32px;
  }
  @media (min-width: ${ScreenSize.desktopM}px) {
    padding: 0 30px;
  }
  @media (min-width: ${ScreenSize.desktopL}px) {
    width: 1400px;
    padding: 0 20px;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
`;

export default Container;
