import { omit } from 'lodash-es';
import { handleActions } from 'redux-actions';

import { Actions } from './Actions';

const initialState = {};

export default handleActions(
  {
    [Actions.INIT_STATE]: (state, action) => ({
      ...state,
      [action.payload]: {
        selectedSchool: {
          loading: false,
          value: null,
          error: null,
        },
        options: {
          loading: false,
          value: [],
          error: null,
        },
      },
    }),
    [Actions.DESTROY_STATE]: (state, action) => ({
      ...omit(state, action.payload),
    }),
    [Actions.SELECTED_SCHOOL_REQUEST]: (state, action) => ({
      ...state,
      [action.meta.form]: {
        ...state[action.meta.form],
        selectedSchool: {
          ...state.selectedSchool,
          loading: true,
          value: null,
          error: null,
        },
      },
    }),
    [Actions.SELECTED_SCHOOL_SUCCESS]: (state, action) => ({
      ...state,
      [action.meta.form]: {
        ...state[action.meta.form],
        selectedSchool: {
          ...state.selectedSchool,
          loading: false,
          value: action.response.result,
          error: null,
        },
      },
    }),
    [Actions.SELECTED_SCHOOL_FAILURE]: (state, action) => ({
      ...state,
      [action.meta.form]: {
        ...state[action.meta.form],
        selectedSchool: {
          ...state.selectedSchool,
          loading: false,
          value: null,
          error: action.payload,
        },
      },
    }),
    [Actions.UPDATE_SELECTED_SCHOOL]: (state, action) => ({
      ...state,
      [action.meta.form]: {
        ...state[action.meta.form],
        selectedSchool: {
          ...state.selectedSchool,
          loading: false,
          value: action.payload,
          error: null,
        },
      },
    }),
    [Actions.SCHOOLS_REQUEST]: (state, action) => ({
      ...state,
      [action.meta.form]: {
        ...state[action.meta.form],
        options: {
          ...state.options,
          loading: true,
          value: [],
          error: null,
        },
      },
    }),
    [Actions.SCHOOLS_SUCCESS]: (state, action) => ({
      ...state,
      [action.meta.form]: {
        ...state[action.meta.form],
        options: {
          ...state.options,
          loading: false,
          value: action.response.result,
          error: null,
        },
      },
    }),
    [Actions.SCHOOLS_FAILURE]: (state, action) => ({
      ...state,
      [action.meta.form]: {
        ...state[action.meta.form],
        options: {
          ...state.options,
          loading: false,
          value: [],
          error: action.payload,
        },
      },
    }),
  },
  initialState
);
