import React, { Suspense } from 'react';
import { Flex } from 'glints-aries/es';
import { PlainButton, Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space4, space8 } from 'glints-aries/es/@next/utilities/spacing';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { EmployerPurchaseProductType } from '../../../common/interfaces/entities';
import { ROUTES } from '../../../common/routes';
import ErrorBoundaryWithCrashReporting from '../../../components/Error/ErrorBoundaryWithCrashReporting';
import GlintsVIPComponentWrapper from '../../../components/GlintsVIPComponentWrapper';
import { useGlintsVIPMembershipInfo } from '../../../components/GlintsVIPMembershipInfo/hooks/useGlintsVIPMembershipInfo';
import NodesJoin from '../../../components/NodesJoin';
import RenderVIPCTALabelBasedOnVIPFreeTrial from '../../../components/VIPFreeTrial/RenderVIPCTALabelBasedOnVIPFreeTrial';
import { useGlintsVIPFreeTrialOrPurchaseModal } from '../PurchaseModals/GlintsVIPPurchaseModal/hooks/useGlintsVIPFreeTrialOrPurchaseModal';
import VIPLimitedTimeOfferBanner from '../VIPLimitedTImeOfferBanner/VIPLimitedTimeOfferBanner';

const messages = defineMessages({
  membershipActive: {
    id: 'text-vip-membership-active',
    defaultMessage: 'VIP Membership: Active',
  },
  membershipInactive: {
    id: 'text-vip-membership-inactive',
    defaultMessage: 'VIP Membership: Inactive',
  },
});

const StyledIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const StyledButton = styled(PlainButton)`
  min-width: 50px;
  height: auto;
  display: inline;
  padding: 0;
`;

type VIPMembershipInfoMenuItemProps = {
  closePopover: () => void;
};
function VIPMembershipInfoMenuItemComponent({
  closePopover,
}: VIPMembershipInfoMenuItemProps) {
  const {
    vipMembershipInfo: {
      isActive,
      expiryDateTime,
      isExpiring,
      creditBenefit,
      trialInformationDetail,
    },
  } = useGlintsVIPMembershipInfo('cache-first');
  const { formatDate } = useIntl();
  const { openGlintsVIPPurchaseModal, isModalChunkLoadPending } =
    useGlintsVIPFreeTrialOrPurchaseModal();
  const { pathname, search } = useLocation();

  const isActivePage = (() => {
    const isCorrectPath = new RegExp(`^(/${ROUTES.features}).*`).test(pathname);
    const params = new URLSearchParams(search);
    const purchasedTab =
      (params.get('purchasedTab') || '') ===
      EmployerPurchaseProductType.VIP_MEMBERSHIP;
    return isCorrectPath && purchasedTab;
  })();

  const handleClosePopover = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    closePopover();
  };
  const handleOpenGlintsVIPPurchaseModal = (e: {
    stopPropagation: () => void;
    preventDefault: () => void;
  }) => {
    handleClosePopover(e);
    e.preventDefault();
    openGlintsVIPPurchaseModal();
  };

  const content = (
    <Flex
      style={{
        padding: space8,
        gap: space8,
        cursor: 'pointer',
      }}
    >
      <StyledIcon
        src={
          isActive
            ? '/images/credit-system/glints-vip-icon.svg'
            : '/images/credit-system/glints-vip-inactive-icon.svg'
        }
        alt="Glints VIP"
      />
      <div>
        <Typography variant="body1" color={Neutral.B18}>
          <NodesJoin separator={' '}>
            <FormattedMessage
              {...(isActive
                ? messages.membershipActive
                : messages.membershipInactive)}
            />
            {isActive && trialInformationDetail?.isActive && (
              <FormattedMessage id="text-trial" defaultMessage=" (Trial)" />
            )}
          </NodesJoin>
        </Typography>
        <If condition={!isActive}>
          <StyledButton
            onClick={handleOpenGlintsVIPPurchaseModal}
            disabled={isModalChunkLoadPending}
          >
            <Typography variant="button">
              <RenderVIPCTALabelBasedOnVIPFreeTrial>
                <FormattedMessage
                  id="interactive-upgrade-to-vip"
                  defaultMessage="Upgrade to VIP"
                />
              </RenderVIPCTALabelBasedOnVIPFreeTrial>
            </Typography>
          </StyledButton>
        </If>
        {expiryDateTime && (
          <Flex
            alignItems="center"
            style={{
              gap: space4,
            }}
          >
            <Typography variant="subtitle2" color={Neutral.B40}>
              <FormattedMessage
                id="text-expires-on"
                defaultMessage="Expires on: {expiryDate}"
                values={{
                  expiryDate: formatDate(
                    trialInformationDetail.isActive &&
                      trialInformationDetail.endDate
                      ? trialInformationDetail.endDate
                      : expiryDateTime,
                    {
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit',
                    }
                  ),
                }}
              />
              <If condition={isExpiring}>
                <span>{` - `}</span>
              </If>
            </Typography>
            <If condition={isExpiring}>
              <StyledButton
                onClick={handleOpenGlintsVIPPurchaseModal}
                disabled={isModalChunkLoadPending}
              >
                <Typography variant="button">
                  <FormattedMessage
                    id="interactive-extend"
                    defaultMessage="Extend"
                  />
                </Typography>
              </StyledButton>
            </If>
          </Flex>
        )}
        {creditBenefit && (
          <div
            style={{
              marginTop: space4,
            }}
          >
            <VIPLimitedTimeOfferBanner
              freeCreditsCount={creditBenefit.amount}
              variant="navbarBanner"
              purchaseType={isActive ? 'extend' : 'upgrade'}
            />
          </div>
        )}
      </div>
    </Flex>
  );

  if (isActivePage) {
    return (
      <div style={{ cursor: 'pointer' }} onClick={handleClosePopover}>
        {content}
      </div>
    );
  }

  return (
    <Link
      to={`/${ROUTES.features}?purchasedTab=${EmployerPurchaseProductType.VIP_MEMBERSHIP}&tab=PURCHASES`}
      onClick={handleClosePopover}
    >
      {content}
    </Link>
  );
}

export default function VIPMembershipInfoMenuItem(
  props: VIPMembershipInfoMenuItemProps
) {
  return (
    <GlintsVIPComponentWrapper>
      <ErrorBoundaryWithCrashReporting fallback={null}>
        <Suspense fallback={null}>
          <VIPMembershipInfoMenuItemComponent {...props} />
        </Suspense>
      </ErrorBoundaryWithCrashReporting>
    </GlintsVIPComponentWrapper>
  );
}
