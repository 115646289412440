import { useCallback } from 'react';
import Axios from 'axios';

import { useAxiosWithAPI } from './useAxiosWithAPI';

type AwsS3PresignedPostUrlResponseData = {
  request: string;
  fields: [string, string][];
  name: string;
};

type AwsS3PresignedPostUrlRequestParams = {
  name: string;
  type: string;
  label: string;
};

const useUploadFileToS3 = () => {
  const axios = useAxiosWithAPI();
  const uploadFileToS3 = useCallback(
    async (file: File, params: AwsS3PresignedPostUrlRequestParams) => {
      const formData = new FormData();
      const signUrlResponse = await axios.get('/s3/uploadPostUrl', {
        params,
      });
      const signed = signUrlResponse.data as AwsS3PresignedPostUrlResponseData;
      signed.fields.forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.append('file', file);

      return {
        signed,
        response: await Axios.post(signed.request, formData),
      };
    },
    [axios]
  );

  return uploadFileToS3;
};

export default useUploadFileToS3;
