import { createAction } from 'redux-actions';

import CALL_API from '../../../middleware/api_key';
import { Schemas } from '../../../middleware/schemas';

export const Actions = {
  INIT_STATE: 'glints/form/formSelectSchool/INIT_STATE',
  DESTROY_STATE: 'glints/form/formSelectSchool/DESTROY_STATE',

  SCHOOLS_REQUEST: 'glints/form/formSelectSchool/SCHOOLS_REQUEST',
  SCHOOLS_SUCCESS: 'glints/form/formSelectSchool/SCHOOLS_SUCCESS',
  SCHOOLS_FAILURE: 'glints/form/formSelectSchool/SCHOOLS_FAILURE',

  UPDATE_SELECTED_SCHOOL: 'glints/form/formSelectSchool/UPDATE_SELECTED_SCHOOL',
  SELECTED_SCHOOL_REQUEST:
    'glints/form/formSelectSchool/SELECTED_SCHOOL_REQUEST',
  SELECTED_SCHOOL_SUCCESS:
    'glints/form/formSelectSchool/SELECTED_SCHOOL_SUCCESS',
  SELECTED_SCHOOL_FAILURE:
    'glints/form/formSelectSchool/SELECTED_SCHOOL_FAILURE',
};

export const initState = createAction(Actions.INIT_STATE, null, form => ({
  form,
}));
export const destroyState = createAction(Actions.DESTROY_STATE, null, form => ({
  form,
}));

export const updateSelectedSchool = createAction(
  Actions.UPDATE_SELECTED_SCHOOL,
  (form, school) => school,
  form => ({ form })
);

export function fetchSelectedSchool(form, id) {
  return {
    [CALL_API]: {
      types: [
        Actions.SELECTED_SCHOOL_REQUEST,
        Actions.SELECTED_SCHOOL_SUCCESS,
        Actions.SELECTED_SCHOOL_FAILURE,
      ],
      meta: { form },
      endpoint: `schools/${id}`,
      schema: Schemas.SCHOOL,
    },
  };
}

export function fetchSchools(form, searchTerm) {
  return {
    [CALL_API]: {
      types: [
        Actions.SCHOOLS_REQUEST,
        Actions.SCHOOLS_SUCCESS,
        Actions.SCHOOLS_FAILURE,
      ],
      meta: { form },
      endpoint: 'schools',
      schema: Schemas.SCHOOL_ARRAY,
      options: {
        params: {
          where: {
            name: {
              ilike: `%${searchTerm}%`,
            },
          },
        },
      },
    },
  };
}
