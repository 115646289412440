import { ApolloError, gql, useMutation } from '@apollo/client';

import {
  ApplicationRejectionMessage,
  ApplicationRejectionMessagePreference,
} from '../types';

const addApplicationRejectionMessagePreferencesMutation = gql`
  mutation addApplicationRejectionMessagePreferences(
    $input: AddApplicationRejectionMessagePreferencesInput!
  ) {
    addApplicationRejectionMessagePreferences(input: $input) {
      preferredType
      rejectionMessages {
        message
        messageType
      }
    }
  }
`;

type QueryInput = {
  input: {
    preferredType: ApplicationRejectionMessagePreference;
    rejectionMessages: ApplicationRejectionMessage[];
  };
};

type QueryOutput = {
  addApplicationRejectionMessagePreferences: QueryInput['input'];
};

export const useAddApplicationRejectionMessagePreferences = () => {
  const [mutation, { loading }] = useMutation<QueryOutput, QueryInput>(
    addApplicationRejectionMessagePreferencesMutation
  );

  const addApplicationRejectionMessagePreferences = async (
    data: QueryInput['input'],
    handleOnCompleted: (
      data: QueryOutput['addApplicationRejectionMessagePreferences']
    ) => void,
    onError: (error: ApolloError) => void
  ) => {
    const onCompleted = (data: QueryOutput) => {
      handleOnCompleted(data.addApplicationRejectionMessagePreferences);
    };

    await mutation({
      variables: {
        input: data,
      },
      onCompleted,
      onError,
    });
  };

  return {
    addApplicationRejectionMessagePreferences,
    isSavingRejectionMessagePreferences: loading,
  } as const;
};
