import React, { useState } from 'react';
import { Greyscale, MoreHorizontalIcon, PrimaryColor } from 'glints-aries/es';
import {
  ActionList,
  ActionListItem,
  Icon,
  Popover,
  Typography,
  useAlert,
} from 'glints-aries/es/@next';
import { borderRadiusHalf } from 'glints-aries/es/@next/utilities/borderRadius';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space4, space18 } from 'glints-aries/es/@next/utilities/spacing';
import { FormattedMessage } from 'react-intl';
import { useCopyToClipboard } from 'react-use';
import {
  useChannelActionContext,
  useChatContext,
  useMessageContext,
} from 'stream-chat-react';
import styled from 'styled-components';

import DeleteMessageModal from './DeleteMessageModal';
import EditMessageModal from './EditMessageModal';

const Styled = {
  IconButton: styled.div`
    cursor: pointer;
    padding: ${space4};
    border: 1px solid ${Neutral.B85};
    border-radius: ${borderRadiusHalf};
    height: fit-content;
    line-height: 0;
  `,
  ActionIcon: styled(Icon)`
    width: ${space18};
  `,
};

const MessageActions = () => {
  const [active, setActive] = useState(false);
  const [showEditMessageModal, setShowOpenEditMessageModal] = useState(false);
  const [showDeleteMessageModal, setShowDeleteMessageModal] = useState(false);
  const { message, actionsEnabled, getMessageActions } = useMessageContext();
  const { editMessage } = useChannelActionContext();
  const { client } = useChatContext();
  const [, copyToClipboard] = useCopyToClipboard();
  const alert = useAlert();

  if (!actionsEnabled) return null;

  const messageActions = getMessageActions();

  const copyMessageText = () => {
    if (message.text) {
      copyToClipboard(message.text);
      alert.open({
        status: 'success',
        content: (
          <FormattedMessage
            id="text-message-copied"
            defaultMessage="Message copied"
          />
        ),
      });
    }
  };

  const [openEditMessageModal, closeEditMessageModal] = [
    () => setShowOpenEditMessageModal(true),
    () => setShowOpenEditMessageModal(false),
  ];

  const [openDeleteMessageModal, closeDeleteMessageModal] = [
    () => setShowDeleteMessageModal(true),
    () => setShowDeleteMessageModal(false),
  ];

  const handleEditMessage = async (text: string) => {
    closeEditMessageModal();
    await editMessage({
      id: message.id,
      text,
    });
  };

  const handleDeleteMessage = async () => {
    closeDeleteMessageModal();
    await client.deleteMessage(message.id, false);
  };

  const makeActionHandler = (action?: () => void) => () => {
    setActive(false);
    return action?.();
  };

  const actions: ActionListItem[] = [];
  const [canEdit, canCopy, canDelete] = [
    Boolean(message.text) && messageActions.includes('edit'),
    Boolean(message.text),
    messageActions.includes('delete'),
  ];

  if (canEdit) {
    actions.push({
      icon: <Styled.ActionIcon name="ri-pencil-line" />,
      content: (
        <Typography variant="body1">
          <FormattedMessage id="interactive-edit" defaultMessage="Edit" />
        </Typography>
      ),
      action: makeActionHandler(openEditMessageModal),
    });
  }

  if (canCopy) {
    actions.push({
      icon: <Styled.ActionIcon name="ri-file-copy2-line" />,
      content: (
        <Typography variant="body1">
          <FormattedMessage id="interactive-copy" defaultMessage="Copy" />
        </Typography>
      ),
      action: makeActionHandler(copyMessageText),
    });
  }

  if (canDelete) {
    actions.push({
      icon: (
        <Styled.ActionIcon
          name="ri-delete-bin-line"
          style={{
            fill: PrimaryColor.glintsred,
          }}
        />
      ),
      content: (
        <Typography variant="body1" color={PrimaryColor.glintsred}>
          <FormattedMessage id="interactive-delete" defaultMessage="Delete" />
        </Typography>
      ),
      action: makeActionHandler(openDeleteMessageModal),
    });
  }

  if (!actions.length) return null;

  return (
    <>
      <Popover
        active={active}
        onClose={() => setActive(false)}
        activator={
          <Styled.IconButton onClick={() => setActive(true)}>
            <MoreHorizontalIcon
              width={16}
              height={16}
              color={Greyscale.devilsgrey}
            />
          </Styled.IconButton>
        }
      >
        <ActionList items={actions} />
      </Popover>
      <If condition={showEditMessageModal}>
        <EditMessageModal
          text={message.text}
          onClose={closeEditMessageModal}
          onSubmit={handleEditMessage}
        />
      </If>
      <If condition={showDeleteMessageModal}>
        <DeleteMessageModal
          onClose={closeDeleteMessageModal}
          onSubmit={handleDeleteMessage}
        />
      </If>
    </>
  );
};

export default MessageActions;
