import React from 'react';
import classNames from 'classnames';
import { Button, Loading } from 'glints-aries';
import PropTypes from 'prop-types';

import styles from './AriesButtons.scss';

const BtnRed = ({ className, loading, small, jobEditCTABtn, ...props }) => (
  <Button
    variant="solid-blue"
    style={
      (small
        ? { lineHeight: 1, padding: '12px 40px', backgroundColor: '#d26f6d' }
        : { lineHeight: 1, padding: '15px 40px', backgroundColor: '#d26f6d' },
      jobEditCTABtn
        ? {
            lineHeight: 1,
            padding: '15px 40px',
            backgroundColor: '#d26f6d',
            fontSize: '15px',
          }
        : { fontSize: '16px' })
    }
    className={classNames(small ? styles.btnSmall : styles.btn, className)}
    {...props}
  >
    {props.children}
    <If condition={loading}>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Loading className={styles.loading} />
    </If>
  </Button>
);

BtnRed.propTypes = {
  loading: PropTypes.bool,
  small: PropTypes.bool,
  jobEditCTABtn: PropTypes.bool,

  children: PropTypes.node,

  props: PropTypes.object,

  className: PropTypes.string,
};

export default BtnRed;
