import React from 'react';
import { FormattedMessage } from 'react-intl';

export const JOB_APPLICATION_STATUS_ENUM = {
  NEW: 'NEW',
  IN_REVIEW: 'IN_REVIEW',
  INTERVIEWING: 'INTERVIEWING',
  OFFERED: 'OFFERED',
  HIRED: 'HIRED',
  REJECTED: 'REJECTED',
};

export const JOB_APPLICATION_STATUSES = [
  {
    label: (
      <FormattedMessage
        id="modules.Application.Constants.good.fit"
        defaultMessage="Good Fit"
      />
    ),
    value: 'IN_REVIEW',
  },
  {
    label: (
      <FormattedMessage
        id="interactive-interviewing"
        defaultMessage="Interviewing"
      />
    ),
    value: 'INTERVIEWING',
  },
  {
    label: (
      <FormattedMessage
        id="modules.Application.Constants.offering"
        defaultMessage="Offering"
      />
    ),
    value: 'OFFERED',
  },
  {
    label: <FormattedMessage id="interactive-hired" defaultMessage="Hired" />,
    value: 'HIRED',
  },
  {
    label: (
      <FormattedMessage
        id="modules.Application.Constants.unsuitable"
        defaultMessage="Unsuitable"
      />
    ),
    value: 'REJECTED',
  },
];

export const JOB_APPLICATION_STATUSES_TO_LABEL = {
  NEW: 'Pending',
  IN_REVIEW: 'Good Fit',
  INTERVIEWING: 'Interviewing',
  OFFERED: 'Offering',
  HIRED: 'Hired',
  REJECTED: 'Rejected',
};
