import React, { useCallback } from 'react';
import {
  ButtonProps,
  OutlineButton,
  PrimaryButton,
  Typography,
} from 'glints-aries/es/@next';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { WHATSAPP_CHAT_SUPPORT_ENABLED_COUNTRIES } from '../../common/constants';
import config from '../../config';
import { UnleashFeatureNames } from '../../modules/Unleash/featureNames';
import { getIsFeatureEnabled } from '../../selectors/features';
import { messages } from '../WhatsappButton/constants';

type ButtonType = 'primary' | 'outline';

type Props = ButtonProps & {
  buttonType?: ButtonType;
};

const ContactSupportButton = (props?: Props) => {
  const empShowWhatsappIcon = useSelector(state =>
    getIsFeatureEnabled(state, UnleashFeatureNames.empShowWhatsappIcon)
  );

  const { formatMessage } = useIntl();

  const handleContactSupport = useCallback(() => {
    const prefilledMessage = encodeURIComponent(
      formatMessage(messages.textHello)
    );
    const isWhatsappChatSupportEnabled =
      WHATSAPP_CHAT_SUPPORT_ENABLED_COUNTRIES.includes(config.COUNTRY) &&
      empShowWhatsappIcon;

    if (isWhatsappChatSupportEnabled) {
      window.open(
        `https://wa.me/${config.WHATSAPP_CHAT_SUPPORT_NUMBER}?text=${prefilledMessage}`,
        '_blank',
        'noopener,noreferrer'
      );
      return;
    }

    if (window.zE) {
      window.zE('messenger', 'open');
      return;
    }

    window.open(
      `mailto:hi@glints.com?subject=${prefilledMessage}`,
      '_blank',
      'noopener,noreferrer'
    );
  }, [empShowWhatsappIcon, formatMessage]);

  const buttonText = (
    <Typography variant="button">
      <FormattedMessage
        id="interactive-contact-support"
        defaultMessage="Contact Support"
      />
    </Typography>
  );

  return (
    <Choose>
      <When condition={props?.buttonType === 'primary'}>
        <PrimaryButton {...props} onClick={handleContactSupport}>
          {buttonText}
        </PrimaryButton>
      </When>
      <Otherwise>
        <OutlineButton {...props} onClick={handleContactSupport}>
          {buttonText}
        </OutlineButton>
      </Otherwise>
    </Choose>
  );
};

export default ContactSupportButton;
