import React from 'react';
import { Icon, Typography } from 'glints-aries/es/@next';
import { Blue, Neutral, Red } from 'glints-aries/es/@next/utilities/colors';
import { MessageDescriptor } from 'react-intl';
import {
  components,
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  GroupBase,
  InputActionMeta,
  NoticeProps,
} from 'react-select';

import { selectFieldMessages } from './selectFieldMessages';
import { SelectFieldProps } from './SelectFieldProps';

export const getNoOptionMessage = (
  formatMessage: (message: MessageDescriptor) => string,
  props: SelectFieldProps
) => {
  const defaultTypeToSearchComponent = (
    noOptionsMessageProps: NoticeProps<unknown, boolean, GroupBase<unknown>>
  ) => (
    <components.NoOptionsMessage {...noOptionsMessageProps}>
      <Typography variant="body1" color={Neutral.B40} as="span">
        {formatMessage(selectFieldMessages.typeToSearch)}
      </Typography>
    </components.NoOptionsMessage>
  );

  const defaultNoOptionsComponent = (
    noOptionsMessageProps: NoticeProps<unknown, boolean, GroupBase<unknown>>
  ) => (
    <components.NoOptionsMessage {...noOptionsMessageProps}>
      <Typography variant="body1" color={Neutral.B40} as="span">
        {formatMessage(selectFieldMessages.noOptions)}
      </Typography>
    </components.NoOptionsMessage>
  );

  if (props?.isSearchable && props.inputValue === '') {
    return defaultTypeToSearchComponent;
  } else if (props?.components?.NoOptionsMessage) {
    return props?.components?.NoOptionsMessage;
  }

  return defaultNoOptionsComponent;
};

export const DropdownIndicator = (
  props: DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>
) => (
  <components.DropdownIndicator {...props}>
    <Icon
      name={'ri-search'}
      height={20}
      fill={props.isDisabled ? Neutral.B85 : Neutral.B40}
    />
  </components.DropdownIndicator>
);

type DropdownStyleParams = {
  error: boolean;
  isSearchable?: boolean;
  width?: number;
  removeBorder?: boolean;
  removeFocusBoxShadow?: boolean;
};

export const dropdownStyle = ({
  error,
  isSearchable,
  width,
  removeBorder,
  removeFocusBoxShadow,
}: DropdownStyleParams) => ({
  control: (
    baseStyles: CSSObjectWithLabel,
    state: { isFocused: boolean; isDisabled: boolean }
  ) => ({
    ...baseStyles,
    cursor: 'pointer',
    fontFamily: 'Noto Sans',
    fontWeight: 400,
    fontSize: 16,
    backgroundColor: state.isDisabled ? Neutral.B95 : Neutral.B100,
    ...(width ? { width } : {}),
    ...(isSearchable ? { flexDirection: 'row-reverse' as const } : {}),
    ...(error
      ? {
          borderColor: `${Red.B93} !important`,
          boxShadow: 'none',
        }
      : {
          borderColor: `${Neutral.B68} !important`,
          boxShadow: state.isFocused
            ? `0px 0px 0px 1px ${Neutral.B100}, 0px 0px 0px 3px ${Blue.S54}`
            : 'none',
        }),
    ...(removeBorder
      ? {
          border: 'none',
        }
      : {}),
    ...(removeFocusBoxShadow
      ? {
          boxShadow: 'none',
        }
      : {}),
  }),
  menu: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    maxHeight: 200,
  }),
  menuList: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    maxHeight: 200,
    fontFamily: 'Noto Sans',
    fontWeight: 400,
    fontSize: 16,
  }),
  menuPortal: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    zIndex: 999,
  }),
  placeholder: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    color: Neutral.B40,
  }),
  singleValue: (
    baseStyles: CSSObjectWithLabel,
    state: { isDisabled: boolean }
  ) => ({
    ...baseStyles,
    color: state.isDisabled ? Neutral.B85 : Neutral.B18,
  }),
  dropdownIndicator: (
    baseStyles: CSSObjectWithLabel,
    state: { isDisabled: boolean }
  ) => ({
    ...baseStyles,
    color: `${state.isDisabled ? Neutral.B85 : Neutral.B40} !important`,
  }),
});

export const checkKeepSearchValueAction = (meta: InputActionMeta) => {
  if (
    meta.action === 'menu-close' ||
    meta.action === 'input-blur' ||
    meta.action === 'set-value'
  ) {
    return true;
  }
  return false;
};
