import { useCallback, useLayoutEffect, useRef } from 'react';
import { debounce } from 'lodash-es';
import { useUnmount } from 'react-use';

type Props = {
  setElementHeight: (height: number) => void;
  removeListeners?: boolean;
};

export const useGetDivElementHeight = ({
  setElementHeight,
  removeListeners,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const handleUpdateClientHeight = useCallback(() => {
    if (ref.current) {
      setElementHeight(ref.current.clientHeight);
    }
  }, [setElementHeight]);

  useLayoutEffect(() => {
    const debouncedHandleResize = debounce(handleUpdateClientHeight, 500);
    if (removeListeners) {
      return () => window.removeEventListener('resize', debouncedHandleResize);
    }
    debouncedHandleResize();
    window.addEventListener('resize', debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, [handleUpdateClientHeight, removeListeners, setElementHeight]);

  useUnmount(() => {
    setElementHeight(0);
  });

  return { ref, handleUpdateClientHeight } as const;
};
