import React from 'react';
import { Box, Drawer } from 'glints-aries';
import { Typography } from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { useTemplateDrawerContext } from '../../hooks';
import EditTemplateMessages, {
  EditTemplateMessagesProps,
} from './EditTemplateMessages';

export type EditTemplateMessagesDrawerProps = {
  editTemplateMessagesProps: EditTemplateMessagesProps;
};

const Styled = {
  Container: styled.div`
    .aries-drawer {
      min-height: 100%;
      max-height: 100%;
      z-index: 1000000;
    }
    .drawer-contentwrapper {
      max-width: 480px;
      height: 100%;
    }
  `,
  Content: styled.div`
    &,
    & > div:nth-child(2) {
      display: grid;
      grid-template-rows: auto 1fr;
      height: 100%;
    }
  `,
};

const EditTemplateMessagesDrawer = ({
  editTemplateMessagesProps,
}: EditTemplateMessagesDrawerProps) => {
  const templateDrawer = useTemplateDrawerContext();

  return (
    <Styled.Container>
      <Drawer
        position="right"
        isOpen={templateDrawer.isOpen}
        onClose={templateDrawer.close}
      >
        <Styled.Content>
          <Box p={16}>
            <Typography variant="subtitle1">
              <FormattedMessage
                id="text_edit-template-message-title"
                defaultMessage="Edit template message"
              />
            </Typography>
          </Box>
          <EditTemplateMessages
            onClose={templateDrawer.close}
            {...editTemplateMessagesProps}
          />
        </Styled.Content>
      </Drawer>
    </Styled.Container>
  );
};

export default EditTemplateMessagesDrawer;
