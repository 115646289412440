import { AxiosError } from 'axios';
import dayjs from 'dayjs';

import { CurrentCompanyJobSlotsSubscription } from '../interfaces/entities';

type ErrorResponse = {
  error: {
    code: string;
  };
};

export const isSubscriptionJobPostUsageSoftLimitReached = ({
  activeConcurrentJobs,
  totalConcurrentJobSlots,
}: Pick<
  CurrentCompanyJobSlotsSubscription,
  'totalConcurrentJobSlots' | 'activeConcurrentJobs'
>) => activeConcurrentJobs > totalConcurrentJobSlots;

export const getExpiryInDays = (expiryDate: string) => {
  const daysToExpiry = dayjs(expiryDate).diff(
    dayjs().format('YYYY-MM-DD'),
    'day'
  );
  return daysToExpiry;
};

const SUBSCRIPTION_EXPIRING_BANNER_THRESHOLD_DAYS = 10;
export const checkIsSubscriptionPlanExpiring = (expiryDate: string) => {
  const expiryInDays = getExpiryInDays(expiryDate);
  return (
    expiryInDays >= 0 &&
    expiryInDays <= SUBSCRIPTION_EXPIRING_BANNER_THRESHOLD_DAYS
  );
};

const TALENT_SEARCH_CHATS_EXPIRING_THRESHOLD_DAYS = 30;
export const checkIsTalentSearchChatsExpiring = (expiryDate: string) => {
  const expiryInDays = getExpiryInDays(expiryDate);
  return (
    expiryInDays >= 0 &&
    expiryInDays <= TALENT_SEARCH_CHATS_EXPIRING_THRESHOLD_DAYS
  );
};

export const checkIsSubscriptionHardLimitReachedError = (
  error: Error | undefined
): boolean => {
  if (!error) return false;

  return (
    (error as AxiosError<ErrorResponse>)?.response?.data.error?.code ===
    'ConcurrentJobsHardLimitReachedError'
  );
};
