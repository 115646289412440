import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

export default function useEvictApplicationCache() {
  const apolloClient = useApolloClient();
  return useCallback(
    (applicationId: string) => {
      apolloClient.cache.evict({
        id: apolloClient.cache.identify({
          __typename: 'Application',
          id: applicationId,
        }),
      });
      apolloClient.cache.gc();
    },
    [apolloClient.cache]
  );
}
