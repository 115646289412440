import React from 'react';
import { Typography } from 'glints-aries/es/@next';
import { Blue, Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space4, space12 } from 'glints-aries/es/@next/utilities/spacing';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { VIPMembershipBenefit } from './types';
import VIPBenefitPopover from './VIPBenefitPopover/VIPBenefitPopover';

type Props = VIPMembershipBenefit & {
  hideDescription?: boolean;
  showLearnMore?: boolean;
};

export default function GlintsVIPBenefit({
  imageSrc,
  heading,
  description,
  hideDescription,
  showLearnMore,
  ...otherProps
}: Props) {
  const isComingSoon = 'isComingSoon' in otherProps;
  return (
    <BenefitContainer data-align-center={hideDescription}>
      <IconContainer src={imageSrc} />
      <div>
        <Typography variant="body2">
          <FormattedMessage {...heading} />
        </Typography>
        <If condition={!hideDescription}>
          <Typography variant="body1" color={Neutral.B40}>
            <FormattedMessage {...description} />
          </Typography>
        </If>
        {!isComingSoon && showLearnMore && (
          <VIPBenefitPopover
            title={<FormattedMessage {...heading} />}
            onMouseEnter={({ openPopover }) => {
              openPopover();
            }}
            onMouseLeave={({ closePopover }) => {
              closePopover();
            }}
            activator={() => (
              <Typography
                variant="button"
                color={Blue.S99}
                style={{
                  cursor: 'pointer',
                  marginTop: space4,
                  width: 'max-content',
                }}
              >
                <FormattedMessage
                  id="interactive-learn-more"
                  defaultMessage="Learn More"
                />
              </Typography>
            )}
            images={otherProps.promotionImages}
            description={<FormattedMessage {...description} />}
          />
        )}
      </div>
    </BenefitContainer>
  );
}

export const BenefitContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${space12};
  &[data-align-center='true'] {
    align-items: center;
  }
`;

export const IconContainer = styled.img`
  width: 40px;
`;
