import { createAction } from 'redux-actions';

export const Actions = {
  UPDATE_JOB_ENTITY: 'glints/entities/UPDATE_JOB_ENTITY',
  REMOVE_JOB_ENTITY: 'glints/entities/REMOVE_JOB_ENTITY',

  ADD_JOB_DRAFT_ENTITY: 'glints/entities/ADD_JOB_DRAFT_ENTITY',
  UPDATE_JOB_DRAFT_ENTITY: 'glints/entities/UPDATE_JOB_DRAFT_ENTITY',
  REMOVE_JOB_DRAFT_ENTITY: 'glints/entities/REMOVE_JOB_DRAFT_ENTITY',
  UPDATE_JOB_DRAFT_ENTITY_SKILLS:
    'glints/entities/UPDATE_JOB_DRAFT_ENTITY_SKILLS',
  UPDATE_JOB_DRAFT_ENTITY_JOB_TITLES:
    'glints/entities/UPDATE_JOB_DRAFT_ENTITY_JOB_TITLES',
  UPDATE_JOB_DRAFT_ENTITY_SALARIES:
    'glints/entities/UPDATE_JOB_DRAFT_ENTITY_SALARIES',
  UPDATE_JOB_DRAFT_ENTITY_GROUPS:
    'glints/entities/UPDATE_JOB_DRAFT_ENTITY_GROUPS',

  UPDATE_GROUP_ENTITY: 'glints/entities/UPDATE_GROUP_ENTITY',

  UPDATE_TALENT_ENTITY: 'glints/entities/UPDATE_TALENT_ENTITY',
  UPDATE_TALENT_ENTITY_PREFERENCES:
    'glints/entities/UPDATE_TALENT_ENTITY_PREFERENCES',
  REMOVE_TALENT_JOB_ROLE_PREFERENCE:
    'glints/entities/REMOVE_TALENT_JOB_ROLE_PREFERENCE',

  ADD_TALENT_EXPERIENCE_ITEM: 'glints/entities/ADD_TALENT_EXPERIENCE_ITEM',
  EDIT_TALENT_EXPERIENCE_ITEM: 'glints/entities/EDIT_TALENT_EXPERIENCE_ITEM',
  REMOVE_TALENT_EXPERIENCE_ITEM:
    'glints/entities/REMOVE_TALENT_EXPERIENCE_ITEM',

  ADD_TALENT_SKILL: 'glints/entities/ADD_TALENT_SKILL',
  REMOVE_TALENT_SKILL: 'glints/entities/REMOVE_TALENT_SKILL',

  UPDATE_ENTITIES: 'glints/entities/UPDATE_ENTITIES',

  UPDATE_COMPANY_USER_ENTITY: 'glints/entities/UPDATE_COMPANY_USER_ENTITY',
  REMOVE_COMPANY_USER_ENTITY: 'glints/entities/REMOVE_COMPANY_USER_ENTITY',

  UPDATE_ACTIVITY_LOG_ENTITY: 'glints/entities/UPDATE_ACTIVITY_LOG_ENTITY',
  REMOVE_ACTIVITY_LOG_ENTITY: 'glints/entities/REMOVE_ACTIVITY_LOG_ENTITY',

  UPDATE_TALENTHUNT_APPLICATION_ENTITY:
    'glints/entities/UPDATE_TALENTHUNT_APPLICATION_ENTITY',

  UPDATE_TALENT_ATTACHMENT: 'glints/entities/UPDATE_TALENT_ATTACHMENT',

  UPDATE_TALENT: 'glints/entities/UPDATE_TALENT',
};

export const updateJobEntity = createAction(
  Actions.UPDATE_JOB_ENTITY,
  (id, data) => ({ id, data })
);
export const removeJobEntity = createAction(Actions.REMOVE_JOB_ENTITY);

export const addJobDraftEntity = createAction(Actions.ADD_JOB_DRAFT_ENTITY);
export const updateJobDraftEntity = createAction(
  Actions.UPDATE_JOB_DRAFT_ENTITY,
  (id, data) => ({ id, data })
);
export const removeJobDraftEntity = createAction(
  Actions.REMOVE_JOB_DRAFT_ENTITY
);
export const updateJobDraftEntitySkills = createAction(
  Actions.UPDATE_JOB_DRAFT_ENTITY_SKILLS,
  (id, data) => ({ id, data })
);
export const updateJobDraftEntityJobTitles = createAction(
  Actions.UPDATE_JOB_DRAFT_ENTITY_JOB_TITLES,
  (id, data) => ({ id, data })
);
export const updateJobDraftEntitySalaries = createAction(
  Actions.UPDATE_JOB_DRAFT_ENTITY_SALARIES,
  (id, data) => ({ id, data })
);
export const updateJobDraftEntityGroups = createAction(
  Actions.UPDATE_JOB_DRAFT_ENTITY_GROUPS,
  (id, data) => ({ id, data })
);

export const updateTalentEntity = createAction(
  Actions.UPDATE_TALENT_ENTITY,
  (id, data) => ({ id, data })
);
export const removeTalentJobRolePreference = createAction(
  Actions.REMOVE_TALENT_JOB_ROLE_PREFERENCE,
  (talentId, preferenceId) => ({ talentId, preferenceId })
);
export const addTalentExperienceItem = createAction(
  Actions.ADD_TALENT_EXPERIENCE_ITEM,
  (talentId, data) => ({ talentId, data })
);
export const editTalentExperienceItem = createAction(
  Actions.EDIT_TALENT_EXPERIENCE_ITEM,
  (talentId, editedData) => ({ talentId, editedData })
);
export const removeTalentExperienceItem = createAction(
  Actions.REMOVE_TALENT_EXPERIENCE_ITEM,
  (talentId, experienceId) => ({ talentId, experienceId })
);

export const addTalentSkill = createAction(
  Actions.ADD_TALENT_SKILL,
  (talentId, skill) => ({ talentId, skill })
);
export const removeTalentSkill = createAction(
  Actions.REMOVE_TALENT_SKILL,
  (talentId, skillId) => ({ talentId, skillId })
);

export const updateEntities = createAction(
  Actions.UPDATE_ENTITIES,
  (entity, value) => ({ entity, value })
);

export const updateCompanyUserEntity = createAction(
  Actions.UPDATE_COMPANY_USER_ENTITY,
  (id, data) => ({ id, data })
);
export const removeCompanyUserEntity = createAction(
  Actions.REMOVE_COMPANY_USER_ENTITY
);

export const updateActivityLogEntity = createAction(
  Actions.UPDATE_ACTIVITY_LOG_ENTITY,
  (id, data) => ({ id, data })
);
export const removeActivityLogEntity = createAction(
  Actions.REMOVE_ACTIVITY_LOG_ENTITY
);

export const updateTalentAttachment = createAction(
  Actions.UPDATE_TALENT_ATTACHMENT,
  (id, data) => ({ id, data })
);
export const updateTalent = createAction(Actions.UPDATE_TALENT, (id, data) => ({
  id,
  data,
}));
