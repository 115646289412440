import { handleActions } from 'redux-actions';

import { Actions } from './Actions';
import { StatusEnumForFooterVisible } from './constants';

export const initialState = {
  loading: false,
  error: null,
  success: false,
  email: null,
  applicationId: null,
  applicantId: null,
  jobId: null,
  resumeFileName: null,
  isFooterVisible: false,
  footerHeight: 0,
  loaded: false,
};

export default handleActions(
  {
    [Actions.GET_RESUME_DETAIL_REQUEST]: state => ({
      ...state,
      loading: true,
    }),
    [Actions.GET_RESUME_DETAIL_SUCCESS]: (state, actions) => {
      const ResumeDetail = {
        loading: false,
        success: true,
        applicationId: actions.response.id,
        applicantId: actions.response.ApplicantId,
        jobId: actions.response.JobId,
        resumeFileName: actions.response.resume,
        applicantStatus: actions.response.status,
        loaded: true,
      };

      if (StatusEnumForFooterVisible.includes(actions.response.status)) {
        ResumeDetail.isFooterVisible = true;
      }

      return {
        ...state,
        ...ResumeDetail,
      };
    },
    [Actions.GET_RESUME_DETAIL_FAILURE]: state => ({
      ...state,
      loading: false,
      success: false,
      applicationId: null,
      applicantId: null,
      jobId: null,
      resumeFileName: null,
      loaded: true,
    }),
    [Actions.HIDE_RESUME_PAGE_FOOTER]: state => ({
      ...state,
      isFooterVisible: false,
    }),
    [Actions.SET_FOOTER_HEIGHT]: (state, actions) => ({
      ...state,
      footerHeight: actions.payload,
    }),
  },
  initialState
);
