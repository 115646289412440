import { Colors } from 'glints-aries/es/@next';
import styled from 'styled-components';

const Card = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${Colors.Neutral.B100};
  box-shadow: 0px 1px 3px 0px #3f3f4426;
  box-shadow: 0px 0px 0px 1px #3f3f440d;
  width: 100%;
`;

export default Card;
