import React, { useState } from 'react';
import { Button, Typography } from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';

import { ContactsRequestAction } from '../../types';
import ResponsiveButtonGroup from '../ResponsiveButtonGroup';

type Props = {
  metadata: ContactsRequestAction;
};

const CandidatePhoneNumber = ({ metadata }: Props) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const phone = metadata.action_payload.contents?.cdd?.phone;
  const copyPhoneNumber = () => {
    if (phone) {
      navigator.clipboard.writeText(phone);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 3000);
    }
  };

  return (
    <>
      <Typography variant="body1">
        <FormattedMessage
          id="text_action-card_cdd-phone-label"
          defaultMessage="Candidate's phone number:"
        />
        <br />
        {phone}
      </Typography>
      <ResponsiveButtonGroup>
        <Button onClick={copyPhoneNumber} disabled={!phone || copySuccess}>
          <Typography variant="button">
            <Choose>
              <When condition={copySuccess}>
                <FormattedMessage
                  id="button-text_copied"
                  defaultMessage="Copied"
                />
              </When>
              <Otherwise>
                <FormattedMessage id="button-text_copy" defaultMessage="Copy" />
              </Otherwise>
            </Choose>
          </Typography>
        </Button>
      </ResponsiveButtonGroup>
    </>
  );
};
export default CandidatePhoneNumber;
