import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import {
  space4,
  space8,
  space16,
} from 'glints-aries/es/@next/utilities/spacing';
import styled from 'styled-components';

export const StyledButton = styled.button`
  background: transparent;
  outline: none;
  box-shadow: none;
  width: fit-content;
  display: flex;
  padding: ${space8} ${space16};
  border: 1px solid ${Neutral.B100};
  border-radius: ${space4};
  color: ${Neutral.B100};
  cursor: pointer;
  letter-spacing: 1.2px;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
`;
