import { StringParam, useQueryParam } from 'use-query-params';

import { referralCodeRegex } from '../../modules/CompanyOnboarding/CompanyCreationPage/consts';

export default function useGetRefCodeParam(): string | null {
  const [referralCode] = useQueryParam('refCode', StringParam);
  const isReferralCodeValid =
    referralCode && referralCodeRegex.test(referralCode);
  return isReferralCodeValid ? referralCode : null;
}
