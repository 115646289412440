import CALL_API from '../middleware/api_key';
import { Schemas } from '../middleware/schemas';

export const Actions = {
  GROUPS_REQUEST: 'glints/groups/GROUPS_REQUEST',
  GROUPS_SUCCESS: 'glints/groups/GROUPS_SUCCESS',
  GROUPS_FAILURE: 'glints/groups/GROUPS_FAILURE',
};

export function fetchGroups(options) {
  return {
    [CALL_API]: {
      types: [
        Actions.GROUPS_REQUEST,
        Actions.GROUPS_SUCCESS,
        Actions.GROUPS_FAILURE,
      ],
      endpoint: 'groups',
      schema: Schemas.GROUP_ARRAY,
      options: {
        params: {
          ...(options ? options.params : {}),
          order: 'name ASC',
          limit: 0,
        },
      },
    },
  };
}
