import { useMemo } from 'react';

import { getConfig } from '../config';
import { UnleashFeatureNames } from '../modules/Unleash/featureNames';
import { getIsFeatureEnabled } from '../selectors/features';
import { hash } from './filters';
import useIsOSSEnabled from './hooks/useIsOSSEnabled';

/**
 * Assets used on Glints are stored on Amazon S3. Due to the differing storage
 * locations depending on environment (i.e. production vs development) and
 * asset type, this helper function allows callers to quickly determine the
 * full URL.
 *
 * The currently defined asset types are:
 *
 *   - application-attachment
 *   - career-banner-pic
 *   - career-banner-pic-small
 *   - company-banner-pic
 *   - company-logo
 *   - company-photos
 *   - company-resources
 *   - content
 *   - default-banner-pic-large
 *   - default-banner-pic-small
 *   - group-logo
 *   - group-banner-pic
 *   - job-attachment
 *   - job-banner-pic
 *   - job-default-banner-pic
 *   - perk-pic
 *   - profession-banner-pic
 *   - profession-banner-pic-small
 *   - profile-picture
 *   - profile-picture-default
 *   - resource-banner-pic
 *   - resource-cards
 *   - resume
 *
 *  Some defaults for images:
 *
 *  - default-company-logo.jpg
 *  - default-group-logo.jpg
 *  - default-job-edit-banner-pic.jpg
 *  - profile-picture-default-(id % 15 + 1).jpg
 *  - career-banner-pic-(career.id).jpg
 *  - job-default-banner-pic-(id % 16 + 1).jpg
 *  - profession-banner-pic-(profession.id).jpg
 *  - glints_logo_360.jpg
 *
 */

type GetAssetURLOptions = {
  useOSSDirectURL?: boolean;
};

export type GetAssetURLCallback = (
  name: string,
  assetType?: string,
  options?: GetAssetURLOptions
) => string;

export function getS3AssetURL(name: string, assetType?: string) {
  const { S3_BUCKET_NAME, S3_BUCKET_LOCATION } = getConfig();

  if (assetType) {
    return `https://${S3_BUCKET_LOCATION}.amazonaws.com/${S3_BUCKET_NAME}/${assetType}/${name}`;
  } else {
    return `https://${S3_BUCKET_LOCATION}.amazonaws.com/${S3_BUCKET_NAME}/${name}`;
  }
}

/**
 * @params options.useOSSDirectURL - If true, use the direct URL to the OSS bucket instead of the custom domain. Use case: thumbor doesn't support custom domains.
 */
export function getOSSAssetURL(
  name: string,
  assetType?: string,
  options?: GetAssetURLOptions
) {
  const { OSS_BUCKET_NAME, OSS_BUCKET_LOCATION, CUSTOM_OSS_DOMAIN } =
    getConfig();

  const baseOSSURL = options?.useOSSDirectURL
    ? `https://${OSS_BUCKET_NAME}.${OSS_BUCKET_LOCATION}.aliyuncs.com`
    : CUSTOM_OSS_DOMAIN;

  if (assetType) {
    return `${baseOSSURL}/${assetType}/${name}`;
  } else {
    return `${baseOSSURL}/${name}`;
  }
}

export function useGetAssetURL(): {
  getAssetURL: GetAssetURLCallback;
} {
  const isOSSEnabled = useIsOSSEnabled();

  const getAssetURL = useMemo(
    () => (isOSSEnabled ? getOSSAssetURL : getS3AssetURL),
    [isOSSEnabled]
  );

  return {
    getAssetURL,
  };
}

export function getAssetURLFromState(state: unknown) {
  return getIsFeatureEnabled(state, UnleashFeatureNames.allUseOSS)
    ? getOSSAssetURL
    : getS3AssetURL;
}

export function getRandomFallbackProfilePicture(id: string) {
  return `${(Math.abs(hash(id)) % 15) + 1}.jpg`;
}

export function getRandomFallbackJobBannerPic(id: string) {
  return `${(Math.abs(hash(id)) % 16) + 1}.jpg`;
}

export function getRandomFallbackCompanyBannerPic(id: string) {
  return `${(Math.abs(hash(id)) % 16) + 1}.jpg`;
}
