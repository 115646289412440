import { useApolloClient } from '@apollo/client';
import { StringParam, useQueryParam } from 'use-query-params';

import { BulkChangeApplicationStatusResponse } from '../../../common/hooks/requests/useBulkChangeApplicationStatus';
import { ChannelQueryParam } from '../constants';

export default function useHandleApplicationStatusChangeForChat() {
  const apolloClient = useApolloClient();

  const [, setAppStatus] = useQueryParam(
    ChannelQueryParam.APP_STATUS,
    StringParam
  );

  const handleApplicationStatusChangeForChat = (
    result: BulkChangeApplicationStatusResponse
  ) => {
    if (result.updates.length && !result.failed.length) {
      for (const updatedApplication of result.updates) {
        apolloClient.cache.modify({
          id: apolloClient.cache.identify({
            __typename: 'Application',
            id: updatedApplication.id,
          }),
          fields: {
            status() {
              return updatedApplication.status;
            },
            whatsAppDetails() {
              return updatedApplication.whatsAppDetails;
            },
          },
        });
      }

      setAppStatus(result.updates[0].status, 'replaceIn');
    }
  };

  return {
    handleApplicationStatusChangeForChat,
  };
}
