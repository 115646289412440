import React from 'react';
import classNames from 'classnames';
import { Loading } from 'glints-aries';
import PropTypes from 'prop-types';

import styles from './BtnWhiteBorderless.scss';

const BtnWhiteBorderless = ({ className, loading, small, ...props }) => (
  <div
    role="button"
    className={classNames(small ? styles.btnSmall : styles.btn, className)}
    {...props}
  >
    {props.children}
    <If condition={loading}>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Loading />
    </If>
  </div>
);

BtnWhiteBorderless.propTypes = {
  loading: PropTypes.bool,
  small: PropTypes.bool,

  children: PropTypes.node,

  props: PropTypes.object,

  className: PropTypes.string,
};

export default BtnWhiteBorderless;
