import styled from 'styled-components';

export const navbarMinHeight = '70px';
export const NAVBAR_Z_INDEX = 10;
export const NavbarWrapper = styled.div<{ top?: number }>`
  position: sticky;
  top: ${({ top }) => top || 0}px;
  left: 0;
  min-height: ${navbarMinHeight};

  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: ${NAVBAR_Z_INDEX};
  width: 100%;
  background-color: white;
  border-bottom: 2px solid #f3f3f3;

  box-sizing: border-box;
`;
