import React from 'react';

import useShowMobileAppPrompt from './useShowMobileAppPrompt';

type RenderDesktopOnlyProps = {
  children: React.ReactNode;
};

export default function RenderDesktopOnly({
  children,
}: RenderDesktopOnlyProps) {
  const isPromptVisible = useShowMobileAppPrompt();
  if (isPromptVisible) {
    return null;
  }
  return <>{children}</>;
}
