import { getLocation as getConnectedLocation } from 'connected-react-router';
import { createSelector } from 'reselect';

import { searchStringToParamsObject } from '../common/queryParamHelpers';

export const getLocation = createSelector([getConnectedLocation], location => {
  const query = searchStringToParamsObject(location.search);
  return { ...location, query };
});

export const getLocationQueryParam = (state, key) => {
  if (typeof state !== 'object' || typeof key !== 'string') {
    throw TypeError(
      `Invalid arguments supplied to getLocationQueryParam: Expected (object, string) but got (${typeof state}, ${typeof key})`
    );
  }
  const location = getConnectedLocation(state);
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get(key);
};
