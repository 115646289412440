import { ButtonGroup, Modal } from 'glints-aries/es/@next';
import styled from 'styled-components';

export const StyledSuccessModal = styled(Modal)`
  .modal-content {
    min-width: 620px;
    max-width: 980px;
  }
`;

export const ProductIcon = styled.img`
  width: 72px;
  height: 72px;
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  justify-content: flex-end;
`;
