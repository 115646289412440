import { apm } from '@elastic/apm-rum';
import { includes } from 'lodash-es';

import { getConfig } from '../../config';
import {
  appLanguagesBasedOnCountry,
  AppLanguageStorageKey,
  defaultAppLanguages,
} from './constants';

type GlintsEmployersAppConfig = typeof window.GlintsEmployersAppConfig;
/** Returns a read-only copy of the config */
function getClientConfig() {
  return getConfig() as GlintsEmployersAppConfig;
}

/** Overwrites the config */
function setConfig(config: typeof window.GlintsEmployersAppConfig) {
  window.GlintsEmployersAppConfig = config;
}

function isValidLanguage(language: string) {
  const country = getClientConfig().COUNTRY as string;
  const languagesForCountry =
    appLanguagesBasedOnCountry[country] || defaultAppLanguages;
  return includes(languagesForCountry, language);
}

function initializeConfigLanguage() {
  try {
    const storedLanguage = localStorage.getItem(AppLanguageStorageKey);
    if (!storedLanguage) return;
    if (!isValidLanguage(storedLanguage)) {
      localStorage.removeItem(AppLanguageStorageKey);
      return;
    }
    const currentConfig = getClientConfig();
    setConfig({
      ...currentConfig,
      LANG: storedLanguage,
    });
  } catch (e) {
    apm.captureError(e as Error);
  }
}

initializeConfigLanguage();
