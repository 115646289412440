const clampedNum = ({
  max,
  count,
  suffix = '+',
}: {
  max: number;
  count?: number;
  suffix?: string;
}) => {
  if (!count) return count;

  return count > max ? `${max}${suffix}` : count;
};

export default clampedNum;
