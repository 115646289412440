import {
  hierarchicalLocationSupportedCountryCodes,
  ID_COUNTRY_LOCATION,
  VN_COUNTRY_LOCATION,
} from '../constants';

export const countryCodeIsVietnam = (countryCode?: string) =>
  countryCode?.toUpperCase() === VN_COUNTRY_LOCATION.code;

export const countryCodeIsIndonesia = (countryCode?: string) =>
  countryCode?.toUpperCase() === ID_COUNTRY_LOCATION.code;

export const isHierarchicalLocationSupportedForCountryCode = (
  countryCode: string
) => hierarchicalLocationSupportedCountryCodes.includes(countryCode);
