import React from 'react';
import { Greyscale } from 'glints-aries';
import { Icon } from 'glints-aries/es/@next';
import styled from 'styled-components';

type FileUploadSpinnerProps = {
  fill?: string;
};

const SpinnerIcon = styled(Icon)<FileUploadSpinnerProps>`
  width: 16px;
  fill: ${props => props.fill ?? Greyscale.white};
  animation: spinner 1s linear infinite;
`;

const FileUploadSpinner = (props: FileUploadSpinnerProps) => (
  <SpinnerIcon name="ri-loader" {...props} />
);

export default FileUploadSpinner;
