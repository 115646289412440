export const Actions = {
  UPDATE_COMPANY_LOGO_S3_DATA: 'glints/companyEdit/UPDATE_COMPANY_LOGO_S3_DATA',
  UPDATE_COMPANY_LOGO: 'glints/companyEdit/UPDATE_COMPANY_LOGO',
  ON_UPLOAD_COMPANY_LOGO_ERROR:
    'glints/companyEdit/ON_UPLOAD_COMPANY_LOGO_ERROR',
  ON_UPLOAD_COMPANY_LOGO_SUCCESS:
    'glints/companyEdit/ON_UPLOAD_COMPANY_LOGO_SUCCESS',
  ON_UPLOADING_COMPANY_LOGO: 'glints/companyEdit/ON_UPLOADING_COMPANY_LOGO',
  ON_REMOVE_COMPANY_LOGO: 'glints/companyEdit/ON_REMOVE_COMPANY_LOGO',

  UPDATE_COMPANY_BANNER_S3_DATA:
    'glints/companyEdit/UPDATE_COMPANY_BANNER_S3_DATA',
  UPDATE_COMPANY_BANNER: 'glints/companyEdit/UPDATE_COMPANY_BANNER',
  ON_UPLOAD_COMPANY_BANNER_ERROR:
    'glints/companyEdit/ON_UPLOAD_COMPANY_BANNER_ERROR',
  ON_UPLOAD_COMPANY_BANNER_SUCCESS:
    'glints/companyEdit/ON_UPLOAD_COMPANY_BANNER_SUCCESS',
  ON_UPLOADING_COMPANY_BANNER: 'glints/companyEdit/ON_UPLOADING_COMPANY_BANNER',
  ON_REMOVE_COMPANY_BANNER: 'glints/companyEdit/ON_REMOVE_COMPANY_BANNER',

  UPDATE_COMPANY_PHOTO_S3_DATA: 'glints/company/UPDATE_COMPANY_PHOTO_S3_DATA',
  ADD_TO_COMPANY_PHOTOS_LIST: 'glints/company/ADD_TO_COMPANY_PHOTOS_LIST',
  ON_UPLOAD_COMPANY_PHOTO_ERROR: 'glints/company/ON_UPLOAD_COMPANY_PHOTO_ERROR',
  ON_UPLOAD_COMPANY_PHOTO_SUCCESS:
    'glints/company/ON_UPLOAD_COMPANY_PHOTO_SUCCESS',
  ON_UPLOADING_COMPANY_PHOTO: 'glints/company/ON_UPLOADING_COMPANY_PHOTO',
  ON_REMOVE_COMPANY_PHOTO: 'glints/company/ON_REMOVE_COMPANY_PHOTO',

  SET_DESCRIPTION_EDITOR_STATE:
    'glints/companyEdit/SET_DESCRIPTION_EDITOR_STATE',
  SET_CULTURE_EDITOR_STATE: 'glints/companyEdit/SET_CULTURE_EDITOR_STATE',

  UPDATE_COMPANY_EDIT_STEP: 'glints/companyEdit/UPDATE_COMPANY_EDIT_STEP',

  RESET_COMPANY_EDIT_STATE: 'glints/companyEdit/RESET_COMPANY_EDIT_STATE',

  UPDATE_COMPANY_REQUEST: 'glints/companyEdit/UPDATE_COMPANY_REQUEST',
  UPDATE_COMPANY_SUCCESS: 'glints/companyEdit/UPDATE_COMPANY_SUCCESS',
  UPDATE_COMPANY_FAILURE: 'glints/companyEdit/UPDATE_COMPANY_FAILURE',

  COMPANY_REQUEST: 'glints/companyEdit/COMPANY_REQUEST',
  COMPANY_SUCCESS: 'glints/companyEdit/COMPANY_SUCCESS',
  COMPANY_FAILURE: 'glints/companyEdit/COMPANY_FAILURE',

  UPLOAD_COMPANY_DOCUMENT_SUCCESS:
    'glints/companyEdit/UPLOAD_COMPANY_DOCUMENT_SUCCESS',
  UPLOAD_COMPANY_DOCUMENT_FAILURE:
    'glints/companyEdit/UPLOAD_COMPANY_DOCUMENT_FAILURE',
  REMOVE_COMPANY_DOCUMENT: 'glints/companyEdit/REMOVE_COMPANY_DOCUMENT',
};
