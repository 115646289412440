import { ScreenSize } from 'glints-aries';
import {
  space16,
  space32,
  space40,
} from 'glints-aries/es/@next/utilities/spacing';
import styled from 'styled-components';

export const StyledFormContainer = styled.form`
  width: 100%;
  padding: ${space32} ${space32} ${space40} ${space32};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${space16};

  border-radius: 20px;
  background: rgba(248, 250, 252, 0.7);
  box-shadow:
    0px 2px 20px 0px rgba(71, 71, 71, 0.15),
    0px 0px 8px 0px rgba(71, 71, 71, 0.05);
  backdrop-filter: blur(10px);

  a,
  p {
    display: inline-block;
  }
`;

export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: ${space16};
  flex-wrap: wrap;

  @media (max-width: ${ScreenSize.mobileL}) {
    &&& {
      flex-direction: column;
    }
  }
`;
