import { handleActions } from 'redux-actions';

import { UPDATE_USER_COMPANIES } from '../../actions/user/companies';

const initialState = {
  value: [],
};

export default handleActions(
  {
    [UPDATE_USER_COMPANIES]: (state, action) => ({
      ...state,
      value: action.payload,
    }),
  },
  initialState
);
