import createAction from 'redux-actions/lib/createAction';

import CALL_API from '../middleware/api_key';
import { Schemas } from '../middleware/schemas';

export const Actions = {
  SKILLS_REQUEST: 'glints/skills/SKILLS_REQUEST',
  SKILLS_SUCCESS: 'glints/skills/SKILLS_SUCCESS',
  SKILLS_FAILURE: 'glints/skills/SKILLS_FAILURE',
  SKILLS_RESET: 'glints/skills/SKILLS_RESET',
};

export function fetchSkills(options) {
  return {
    [CALL_API]: {
      types: [
        Actions.SKILLS_REQUEST,
        Actions.SKILLS_SUCCESS,
        Actions.SKILLS_FAILURE,
      ],
      endpoint: 'skills',
      schema: Schemas.SKILL_ARRAY,
      options: {
        ...options,
        params: {
          limit: 10,
          order: 'popularity DESC',
          ...(options ? options.params : {}),
        },
      },
    },
  };
}

export const resetSkills = createAction(Actions.SKILLS_RESET);
