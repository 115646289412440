import React, { ReactNode } from 'react';

import useIsVIPMembershipEnabled from '../common/hooks/useIsVIPMembershipEnabled';

type Props = {
  children: ReactNode;
};

export default function GlintsVIPComponentWrapper({ children }: Props) {
  const isVIPMembershipEnabled = useIsVIPMembershipEnabled();

  return isVIPMembershipEnabled ? <>{children}</> : null;
}
