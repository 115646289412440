import React, { ReactNode } from 'react';
import { Flex } from 'glints-aries/es';
import {
  OutlineButton,
  PrimaryButton,
  Typography,
} from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space4 } from 'glints-aries/es/@next/utilities/spacing';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import { EmployerPurchaseProductType } from '../../../../common/interfaces/entities';
import { ROUTES } from '../../../../common/routes';
import { CreditProductPurchaseOrder } from '../../types';
import { verticalSpacing } from '../spacing';
import { ProductIcon, StyledButtonGroup } from './styles.sc';

const messages = defineMessages({
  chatsAdded: {
    id: 'text-chats-added',
    defaultMessage: 'Chats Added',
  },
  purchaseSuccessfully: {
    id: 'text-purchased-successfully',
    defaultMessage: 'Purchased successfully',
  },
  yourRTChat: {
    id: 'text-your-recommended-talent-chat',
    defaultMessage:
      'Your Recommended Talent chat balance will be valid till <bold>{date}.</bold>',
  },
  nowYouHaveChats: {
    id: 'text-now-you-have-chats',
    defaultMessage:
      'Now you have {count, plural, =1 {<bold>{count}</bold> chat} other {<bold>{count}</bold> chats}}. Check your <link>chat balance</link> for more details.',
  },
});

type Props = {
  creditProductPurchaseOrder: Pick<
    CreditProductPurchaseOrder,
    'companyProductInventory'
  >;
  onClose: () => void;
};
const ModalTitle = () => (
  <FormattedMessage {...messages.purchaseSuccessfully} />
);

const ModalFooter = ({ onClose }: { onClose: () => void }) => {
  const { pathname } = useLocation();
  const recommendedTalentRegExp = RegExp(`^/recommended-talents$`);
  const isRecommendedTalentPath = recommendedTalentRegExp.test(pathname);
  return (
    <StyledButtonGroup>
      <OutlineButton onClick={onClose}>
        <Typography variant="button">
          <FormattedMessage id="interactive-close" defaultMessage="Close" />
        </Typography>
      </OutlineButton>
      <If condition={!isRecommendedTalentPath}>
        <Link to={`/${ROUTES.recommendedTalents}`}>
          <PrimaryButton onClick={onClose}>
            <Typography variant="button">
              <FormattedMessage
                id="interactive-find-recommended-talent"
                defaultMessage="Find Recommended Talent"
              />
            </Typography>
          </PrimaryButton>
        </Link>
      </If>
    </StyledButtonGroup>
  );
};

const Bold = (...chunks: ReactNode[]) => (
  <Typography variant="body2" as="span">
    {chunks}
  </Typography>
);

function RecommendedTalentChatSuccessModalContentComponent({
  creditProductPurchaseOrder: { companyProductInventory },
  onClose,
}: Props) {
  const { formatDate, formatNumber } = useIntl();
  return (
    <Flex
      flexDirection="column"
      style={{
        gap: 20,
      }}
    >
      <ProductIcon
        src="/images/credit-system/recommended-talent-icon.svg"
        alt="Recommended talent"
      />
      <div>
        <Typography variant="body2">
          <FormattedMessage {...messages.chatsAdded} />
        </Typography>
        <Typography variant="body1" as="div" color={Neutral.B40}>
          <FormattedMessage
            {...messages.yourRTChat}
            values={{
              date: companyProductInventory?.expiryDateTime
                ? formatDate(companyProductInventory.expiryDateTime, {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  })
                : '-',
              bold: Bold,
            }}
            tagName="div"
          />
          {verticalSpacing(space4)}
          <FormattedMessage
            {...messages.nowYouHaveChats}
            values={{
              count: formatNumber(companyProductInventory?.amount || 0),
              bold: Bold,
              link: (chunks: React.ReactNode) => (
                <Link
                  to={`/${ROUTES.features}?tab=PURCHASES&purchasedTab=${EmployerPurchaseProductType.RECOMMENDED_TALENT_CHAT}`}
                  onClick={onClose}
                >
                  {chunks}
                </Link>
              ),
            }}
            tagName="div"
          />
        </Typography>
      </div>
    </Flex>
  );
}

export const RecommendedTalentChatSuccessModalContent = Object.assign(
  RecommendedTalentChatSuccessModalContentComponent,
  {
    ModalTitle,
    ModalFooter,
  }
);
