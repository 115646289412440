import { push } from 'connected-react-router';
import jwtDecode from 'jwt-decode';
import { normalize } from 'normalizr';

import { logout } from '../actions/session';
import config from '../config';
import { getSessionToken } from '../selectors/session';

export const getAuthHeader = (dispatch, getState) => {
  const token = getSessionToken(getState());
  if (token) {
    const jwt = jwtDecode(token);
    if (jwt.exp < Date.now() / 1000) {
      dispatch(logout());
      dispatch(push('/login'));
    }
    return {
      Authorization: `Bearer ${token}`,
    };
  } else return {};
};

export const normalizeResponse = (response, schema) => {
  if (schema) {
    if (response.data.count) {
      return {
        ...normalize(response.data.data, schema),
        count: response.data.count,
      };
    } else {
      return { ...normalize(response.data.data, schema) };
    }
  } else {
    return response.data.data;
  }
};

export const apiURL = path => `${config.API_BASE}${path}`;

export const fullApiUrl = path =>
  `${config.EMPLOYER_URL}${config.API_BASE}${path}`;
