import React, { Suspense } from 'react';
import { ApolloError, useApolloClient } from '@apollo/client';
import { apm } from '@elastic/apm-rum';
import { Typography, useAlert } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { ComponentAction } from 'glints-aries/es/types/componentAction';
import { FormattedMessage, useIntl } from 'react-intl';

import ErrorBoundaryWithCrashReporting from '../../Error/ErrorBoundaryWithCrashReporting';
import ErrorStateModal from './ErrorStateModal';
import { useAddApplicationRejectionMessagePreferences } from './hooks/useAddApplicationRejectionMessagePreferences';
import { LoadingStateModal } from './LoadingStateModal';
import { REJECTION_REASON_FORM_ID } from './RejectionReasonPreferenceForm/consts';
import RejectionReasonPreferenceForm from './RejectionReasonPreferenceForm/RejectionReasonPreferenceForm';
import { StyledModal } from './styles.sc';
import { RejectionReasonPreferenceFormState } from './types';

const Header = () => (
  <Typography variant="subtitle1" color={Neutral.B18}>
    <FormattedMessage
      id="text-select-rejection-method"
      defaultMessage="Select Rejection Method"
    />
  </Typography>
);

const HeaderDescription = (
  <Typography variant="body1" color={Neutral.B18}>
    <FormattedMessage
      id="text-we-recommend-sending-personalized"
      defaultMessage="We recommend sending personalized rejection messages to keep rejected applicants informed with a positive touch."
    />
  </Typography>
);

type RejectionReasonPreferenceModalProps = {
  onClose: () => void;
};

export default function RejectionReasonPreferenceModal({
  onClose,
}: RejectionReasonPreferenceModalProps) {
  const client = useApolloClient();
  const { open } = useAlert();
  const { formatMessage } = useIntl();

  const {
    addApplicationRejectionMessagePreferences,
    isSavingRejectionMessagePreferences,
  } = useAddApplicationRejectionMessagePreferences();

  const onCompleted = () => {
    onClose();
    open({
      status: 'success',
      title: formatMessage({
        id: 'text-success',
        defaultMessage: 'Success',
      }),
      content: formatMessage({
        id: 'text-rejection-method-saved',
        defaultMessage: 'Rejection Method Saved',
      }),
    });
    client.refetchQueries({
      include: ['getApplicationRejectionMessagePreferences'],
    });
  };

  const onError = (error: ApolloError) => {
    onClose();
    apm.captureError(error);
    open({
      status: 'error',
      title: formatMessage({
        id: 'text-error',
        defaultMessage: 'Error',
      }),
      content: formatMessage({
        id: 'text-something-went-wrong',
        defaultMessage: 'Something went wrong. Please try again later.',
      }),
    });
  };

  const onSubmit = async (data: RejectionReasonPreferenceFormState) => {
    await addApplicationRejectionMessagePreferences(data, onCompleted, onError);
  };

  const primaryAction: ComponentAction = {
    label: formatMessage({ id: 'interactive-save', defaultMessage: 'Save' }),
    form: REJECTION_REASON_FORM_ID,
    loading: isSavingRejectionMessagePreferences,
  };
  const secondaryAction: ComponentAction = {
    type: 'button',
    label: formatMessage({ id: 'interactive-close', defaultMessage: 'Close' }),
    onClick: onClose,
  };

  return (
    <ErrorBoundaryWithCrashReporting
      fallback={<ErrorStateModal onClose={onClose} />}
    >
      <Suspense fallback={<LoadingStateModal onClose={onClose} />}>
        <StyledModal
          isOpen={true}
          header={<Header />}
          headerDescription={HeaderDescription}
          primaryAction={primaryAction}
          secondaryAction={secondaryAction}
          onClose={onClose}
        >
          <RejectionReasonPreferenceForm onSubmit={onSubmit} />
        </StyledModal>
      </Suspense>
    </ErrorBoundaryWithCrashReporting>
  );
}
