import { isEmpty } from 'lodash-es';
import useSWR, { Fetcher, useSWRConfig } from 'swr';

import { ApplicationDetails } from '../../../../../../common/interfaces/entities';
import { ApplicationDetailsResponse } from '../../../../../../common/interfaces/responses';
import { SwrCacheKeys } from '../../../../../../common/swr-cache-keys';

type GetApplicationDetailsProps = {
  jobId: string;
  applicationId: string;
  screeningQuestions?: {
    screeningQuestionsFilter?: Record<string, unknown>;
  };
};

export default function useGetApplicationDetails({
  jobId,
  applicationId,
  screeningQuestions,
}: GetApplicationDetailsProps) {
  const queryParams = new URLSearchParams({
    ...(screeningQuestions?.screeningQuestionsFilter && {
      screeningQuestionFilter: JSON.stringify(
        screeningQuestions.screeningQuestionsFilter
      ),
    }),
  });

  const key = `/jobs/${jobId}/applications/${applicationId}?${queryParams.toString()}`;

  const { fetcher } = useSWRConfig();

  const cacheKey = SwrCacheKeys.applicationDetails(jobId, applicationId);

  const { data, mutate } = useSWR<ApplicationDetailsResponse>(
    cacheKey,
    () => (fetcher as Fetcher<ApplicationDetailsResponse, string>)(key),
    {
      suspense: true,
    }
  );

  return {
    // Need to cast data to ApplicationDetails because swr hasn't handle suspense typing properly
    // https://github.com/vercel/swr/issues/1412
    application: data?.data as ApplicationDetails,
    hasScreeningQuestionsFilterApplied: !isEmpty(
      screeningQuestions?.screeningQuestionsFilter
    ),
    mutate,
  } as const;
}
