import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { CompanyProductFreeTrialOffer } from '../../../common/interfaces/product-free-trial-offer';
import { timePeriodUnitToText } from '../../../modules/CreditSystem/constants';

type Props = {
  vipOffer: CompanyProductFreeTrialOffer;
};

export default function VIPFreeTrialCTAText({ vipOffer }: Props) {
  const { formatMessage } = useIntl();

  if (!vipOffer.productTimePeriod || !vipOffer.productTimePeriodUnit) {
    return null;
  }

  return (
    <FormattedMessage
      id="interactive-try-count-days-for"
      defaultMessage="Try {count} {timeUnit} for Rp 0"
      values={{
        count: vipOffer.productTimePeriod,
        timeUnit: formatMessage(
          timePeriodUnitToText[vipOffer.productTimePeriodUnit],
          {
            count: vipOffer.productTimePeriod,
          }
        ),
      }}
    />
  );
}
