import { apm as elasticApm } from '@elastic/apm-rum';
import { Middleware } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';

import { Company } from '../common/interfaces/entities';
import { getConfig } from '../config';
import { getSessionCompany } from '../selectors/session';
import { getUser } from '../selectors/user';

const metadataSection = {
  user: 'user',
};

const apm: Middleware<Record<string, unknown>, ToolkitStore> =
  store => next => async action => {
    const me = getUser(store.getState());
    const config = getConfig();

    if (me) {
      const sessionCompany: Company | undefined = getSessionCompany(
        store.getState()
      );
      elasticApm.setUserContext({
        id: me.id,
        email: me.email,
        username: `${me.firstName} ${me.lastName}`.trim(),
      });

      elasticApm.setCustomContext({
        [metadataSection.user]: {
          firstName: me.firstName,
          lastName: me.lastName,
          isVerified: me.isVerified,
          lastSeen: me.lastSeen,
          role: me.role,
          sessionCompanyId: sessionCompany?.id,
          sessionCompanyStatus: sessionCompany?.status,
          siteLanguage: config.LANG,
        },
      });
    } else {
      // work around to reset the context as per https://github.com/elastic/apm-agent-rum-js/issues/1085#issuecomment-933945338
      elasticApm.setUserContext({
        email: '',
        id: '',
        username: '',
      });
      elasticApm.setCustomContext({
        [metadataSection.user]: {},
      });
    }

    next(action);
  };

export default apm;
