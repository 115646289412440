import React from 'react';
import { Dropdown, Menu } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { ArrowDownIcon } from 'glints-aries';
import { defineMessage, FormattedMessage, useIntl } from 'react-intl';

import { CONTACT_LINK } from '../../common/constants';
import config from '../../config';
import { MenuItem, MenuType } from '../Platform/Constants';
import DropdownItem from './DropdownItem';
import { DropdownLink, DropdownLinkLabel } from './NavbarDesktop.sc';
import { NavbarItem } from './NavbarItem';
import { renderItemLabel } from './navigationUtils';
import { SidebarItem } from './Sidebar.sc';

const { COUNTRY } = config;

const services: MenuItem[] = [
  {
    value: 'services_jobsearch',
    isExternal: true,
    label: defineMessage({
      id: 'modules.Navigation.Spring2021LandingMenuItems.JobSearch',
      defaultMessage: 'JobSearch',
    }),
    canBeDisabled: false,
    type: MenuType.general,
    to:
      COUNTRY === 'id'
        ? `${config.CANDIDATE_URL}/id`
        : `${config.CANDIDATE_URL}/opportunities/jobs/explore?CountryCode=SG&locationName=Singapore`,
  },
  {
    value: 'services_talenthunt',
    isExternal: true,
    label: defineMessage({
      id: 'modules.Navigation.Spring2021LandingMenuItems.TalentHunt',
      defaultMessage: 'TalentHunt',
    }),
    canBeDisabled: false,
    type: MenuType.general,
    to: COUNTRY === 'id' ? 'https://talenthunt.glints.id' : '/talenthunt',
  },
  {
    value: 'services_talenthub',
    isExternal: true,
    label: defineMessage({
      id: 'modules.Navigation.Spring2021LandingMenuItems.TalentHub',
      defaultMessage: 'TalentHub',
    }),
    canBeDisabled: false,
    type: MenuType.general,
    to: 'https://global.glints.com',
  },
  ...(COUNTRY === 'id'
    ? [
        {
          value: 'services_expertclass',
          isExternal: true,
          label: defineMessage({
            id: 'modules.Navigation.Spring2021LandingMenuItems.ExpertClass',
            defaultMessage: 'ExpertClass',
          }),
          canBeDisabled: false,
          type: MenuType.general,
          to: 'https://expertclass.glints.com/subscription/',
        },
      ]
    : []),
];

export const Spring2021LandingMenuItems: React.FC<
  Spring2021LandingMenuItemsProps
> = ({ sidebar = false, ...menuItemProps }) => {
  const intl = useIntl();
  const MenuItem = sidebar ? SidebarItem : NavbarItem;

  return (
    <>
      <Choose>
        <When condition={sidebar}>
          {services.map(
            service =>
              service.to && (
                <MenuItem
                  key={service.value}
                  to={service.to}
                  {...menuItemProps}
                >
                  {renderItemLabel(intl, service.label)}
                </MenuItem>
              )
          )}
        </When>
        <Otherwise>
          <Menu.Item {...menuItemProps}>
            <Dropdown
              overlay={
                <Menu>
                  <DropdownItem items={services} />
                </Menu>
              }
            >
              <DropdownLink>
                <DropdownLinkLabel>
                  <FormattedMessage
                    id="modules.Navigation.Spring2021LandingMenuItems.ourServices"
                    defaultMessage="OUR SERVICES"
                  />
                </DropdownLinkLabel>
                <ArrowDownIcon />
              </DropdownLink>
            </Dropdown>
          </Menu.Item>
        </Otherwise>
      </Choose>
      {CONTACT_LINK && (
        <MenuItem to={CONTACT_LINK} {...menuItemProps}>
          <FormattedMessage
            id="modules.Navigation.Spring2021LandingMenuItems.contact"
            defaultMessage="CONTACT US"
          />
        </MenuItem>
      )}
    </>
  );
};

type Spring2021LandingMenuItemsProps = {
  sidebar?: boolean;
} & MenuItemProps;
