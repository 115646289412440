import React from 'react';
import { space12 } from 'glints-aries/es/@next/utilities/spacing';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

import TextAreaField from '../../../../Fields/TextAreaField';
import {
  ApplicationRejectionMessageType,
  RejectionReasonPreferenceFormState,
} from '../../types';
import { MESSAGE_INPUT_MAX_LENGTH } from '../consts';

export default function UnifiedMessageInput() {
  const { formatMessage } = useIntl();
  const { control } = useFormContext<RejectionReasonPreferenceFormState>();

  const rejectionMessages = useWatch({
    control,
    name: 'rejectionMessages',
  });

  const unifiedMessageTypeIndex = rejectionMessages.findIndex(
    message =>
      message.messageType === ApplicationRejectionMessageType.UNIFIED_MESSAGE
  );

  const messageInputName =
    `rejectionMessages.${unifiedMessageTypeIndex}.message` as const;

  return (
    <div style={{ paddingBottom: space12 }}>
      <Controller
        name={messageInputName}
        control={control}
        render={({ field }) => (
          <TextAreaField
            maxLength={MESSAGE_INPUT_MAX_LENGTH}
            canExceedMaxLength={false}
            {...field}
            subLabel={formatMessage({
              id: 'text-message',
              defaultMessage: 'Message',
            })}
          />
        )}
      />
    </div>
  );
}
