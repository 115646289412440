import React from 'react';
import { ButtonGroup, ButtonGroupProps } from 'glints-aries/es/@next';

import { useIsMobileVersion } from '../hooks';

const ResponsiveButtonGroup = (props: ButtonGroupProps) => {
  const isMobileVersion = useIsMobileVersion();

  return (
    <ButtonGroup
      {...props}
      fullWidth={isMobileVersion}
      style={{ width: isMobileVersion ? '100%' : undefined, ...props.style }}
    />
  );
};

export default ResponsiveButtonGroup;
