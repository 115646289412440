export const ACTIVITIES = {
  ACCESS_COMPANY_DASHBOARD: 'ACCESS_COMPANY_DASHBOARD',

  ACCESS_COMPANY_ADMIN_SECTION: 'ACCESS_COMPANY_ADMIN_SECTION',

  ACCESS_COMPANY_MENU_ITEMS: 'ACCESS_COMPANY_MENU_ITEMS',

  ACCESS_TALENT_SEARCH: 'ACCESS_TALENT_SEARCH',

  EDIT_COMPANY: 'EDIT_COMPANY',

  MANAGE_GROUP_MEMBERS: 'MANAGE_GROUP_MEMBERS',

  ACCESS_CURATION_STATUS_FILTER: 'ACCESS_CURATION_STATUS_FILTER',
  EDIT_TALENT: 'EDIT_TALENT',

  ACCESS_GROUP_APPLICATIONS: 'ACCESS_GROUP_APPLICATIONS',

  ACCESS_JOB_CHANNELS: 'ACCESS_JOB_CHANNELS',

  ACCESS_NON_GROUP_JOBS: 'ACCESS_NON_GROUP_JOBS',

  ACCESS_APPLY_REDIRECT_JOB_URL: 'ACCESS_APPLY_REDIRECT_JOB_URL',

  ACCESS_RECOMMENDATIONS: 'ACCESS_RECOMMENDATIONS',
  ACCESS_RECRUITER_ATS: 'ACCESS_RECRUITER_ATS',

  ACCESS_ACTIONS_DROPDOWN: 'ACCESS_ACTIONS_DROPDOWN',

  ACCESS_FIND_TALENT: 'ACCESS_FIND_TALENT',
};
