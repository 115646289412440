import { createContext, useContext } from 'react';

export type ZendeskVisibilityContextType = {
  showZendeskMessaging: () => void;
  hideZendeskMessaging: () => void;
  isZendeskMessagingVisible: boolean;
};

export const ZendeskVisibilityContext = createContext<
  ZendeskVisibilityContextType | undefined
>(undefined);

export const useZendeskVisibilityContext = () => {
  const context = useContext(ZendeskVisibilityContext);
  if (!context) {
    throw new Error(
      'useZendeskVisibilityContext must be used within a ZendeskVisibilityProvider'
    );
  }

  return context;
};
