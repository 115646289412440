import { borderRadius8 } from 'glints-aries/es/@next/utilities/borderRadius';
import { Yellow } from 'glints-aries/es/@next/utilities/colors';
import {
  space4,
  space8,
  space12,
} from 'glints-aries/es/@next/utilities/spacing';
import styled from 'styled-components';

export const StyledBanner = styled.div`
  display: flex;
  gap: ${space8};
  padding: ${space8} 20px;
  width: 100%;
  background-color: ${Yellow.S75};
  border-radius: ${borderRadius8};
  align-items: flex-start;
`;

export const NavbarBannerStyle = styled(StyledBanner)`
  padding: ${space4} ${space12};
`;

export const TopCutoutBannerStyle = styled(StyledBanner)`
  width: fit-content;
  padding: ${space4} ${space12};
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
`;
