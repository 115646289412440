export const camelCaseToSnakeCase = (str: string) =>
  str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();

export const titleCaseToSnakeCase = (str: string) =>
  str.replace(/\s/g, '_').toLowerCase();

export const flattenObject = (
  unprocessedObject: Record<string, unknown>,
  prefix = ''
): Record<string, unknown> =>
  Object.keys(unprocessedObject).reduce(
    (resultObject, key) => {
      const updatedPrefix = prefix.length ? `${prefix}_` : '';
      if (
        typeof unprocessedObject[key] === 'object' &&
        unprocessedObject[key] !== null &&
        !Array.isArray(unprocessedObject[key])
      ) {
        Object.assign(
          resultObject,
          flattenObject(
            unprocessedObject[key] as Record<string, unknown>,
            `${updatedPrefix}${key}`
          )
        );
      } else {
        resultObject[`${updatedPrefix}${key}`] = unprocessedObject[key];
      }
      return resultObject;
    },
    {} as Record<string, unknown>
  );
