import React from 'react';
import { Greyscale } from 'glints-aries';
import { Typography } from 'glints-aries/es/@next';
import { useIntl } from 'react-intl';
import { MessageTimestamp } from 'stream-chat-react';

import { applicationAutoShareCVRequestMessage } from '../../constants';

const AutoShareStatusSystemMessage = () => {
  const { formatMessage } = useIntl();

  return (
    <Typography
      variant="subtitle2"
      color={Greyscale.devilsgrey}
      style={{ textAlign: 'center' }}
    >
      <MessageTimestamp format="HH:mm" />
      &#65073;
      {formatMessage(applicationAutoShareCVRequestMessage)}
    </Typography>
  );
};

export default AutoShareStatusSystemMessage;
