/* eslint-disable camelcase */
import { EDUCATION_LEVEL, GenderKeys } from '../../common/constants';
import { TrackingService } from '../../common/tracking/trackingService';

export const ChatClickedEventLocation = {
  MANAGE_CANDIDATES_TABLE: 'Manage Candidates Table',
  MANAGE_CANDIDATES_POPUP: 'Manage Candidates Popup',
  HEADER: 'Header',
  APPLICATION_DETAILPAGE: 'Application Detail Page',
} as const;

type ChatClickedEventLocation =
  (typeof ChatClickedEventLocation)[keyof typeof ChatClickedEventLocation];

type ChatClickedEventDataLayer = {
  clickLocation: ChatClickedEventLocation;
  applicationId: string;
  waPresent: boolean;
};

export const ApplicationShortlistedPages = {
  APPLICATION_DETAILS_PAGE: 'Application Details Page',
  MANAGE_CANDIDATES: 'Manage Cadidates',
} as const;

type ApplicationShortlistedPages =
  (typeof ApplicationShortlistedPages)[keyof typeof ApplicationShortlistedPages];

export const ShortlistMethod = {
  SINGLE_SHORTLIST: 'Single Shortlist',
  BULK_SHORTLIST: 'Bulk Shortlist',
  SHORTLISTED_FOR_INFO: 'Shortlisted for Info',
} as const;

type ShortlistMethod = (typeof ShortlistMethod)[keyof typeof ShortlistMethod];

type ApplicationsShortlistedEventDataLayer =
  | {
      page: ApplicationShortlistedPages;
      shortlistMethod: ShortlistMethod;
      isMultipleApplications: false;
      waPresent: boolean;
    }
  | {
      page: ApplicationShortlistedPages;
      shortlistMethod: ShortlistMethod;
      isMultipleApplications: true;
      numberOfApplicationShortlisted: number;
    };

export const trackGlobalChatClickedEvent = (
  clickLocation: ChatClickedEventDataLayer['clickLocation']
) => {
  TrackingService.logEvent('Chat Clicked', { clickLocation });
};

export const trackChatClickedEvent = (data: ChatClickedEventDataLayer) => {
  TrackingService.logEvent('Chat Clicked', data);
};

export const trackApplicationsShortlistedEvent = (
  data: ApplicationsShortlistedEventDataLayer
) => {
  const { isMultipleApplications, ...rest } = data;
  if (isMultipleApplications) {
    TrackingService.logEvent('application_shortlisted', {
      ...rest,
    });
  } else {
    TrackingService.logEvent('application_shortlisted', {
      ...rest,
      numberOfApplicationShortlisted: 1,
    });
  }
};

export const trackManageCandidateViewedEvent = (properties: {
  jobId: string;
  noPendingCdd: number;
  noShortlistedCdd: number;
  noRejectedCdd: number;
}) => {
  const transformedProperties = {
    job_id: properties.jobId,
    no_pending_cdd: properties.noPendingCdd,
    no_shortlisted_cdd: properties.noShortlistedCdd,
    no_rejected_cdd: properties.noRejectedCdd,
  };

  TrackingService.logEvent('Manage Candidate Viewed', transformedProperties);
};

export const trackChatBannerButtonClickedEvent = (properties: {
  companyId: string;
}) => {
  const transformedProperties = {
    company_id: properties.companyId,
  };
  TrackingService.logEvent('Chat Banner Clicked', transformedProperties);
};

export const trackApplicationDetailsTabSelectedEvent = (properties: {
  tabClicked: string;
  page: string;
}) => {
  const transformedProperties = {
    tab_clicked: properties.tabClicked,
    page: properties.page,
  };

  TrackingService.logEvent(
    'Application Detail Tab Selected',
    transformedProperties
  );
};

export const trackApplicationSelectedEvent = (properties: {
  selectMethod: string;
  applicationId: string;
  stageWhenApplicationSelected: string;
}) => {
  const transformedProperties = {
    select_method: properties.selectMethod,
    application_id: properties.applicationId,
    stage_when_application_selected: properties.stageWhenApplicationSelected,
  };

  TrackingService.logEvent('Application Selected', transformedProperties);
};

export const trackApplicationStageTabSelectedEvent = (properties: {
  tabClicked: string;
}) => {
  const transformedProperties = {
    tab_clicked: properties.tabClicked,
  };

  TrackingService.logEvent(
    'Application Stage Tab Selected',
    transformedProperties
  );
};

export const trackApplicationRejectedEvent = (properties: {
  page: string;
  rejectMethod: string;
  numberOfApplicationRejected: number;
}) => {
  const transformedProperties = {
    page: properties.page,
    reject_method: properties.rejectMethod,
    number_of_application_rejected: properties.numberOfApplicationRejected,
  };

  TrackingService.logEvent('application_rejected', transformedProperties);
};

export type EventProperties = {
  locations: string[] | null;
  cities: string[] | null;
  country: string | null;
  minAge: number | null;
  maxAge: number | null;
  gender: GenderKeys | null;
  minSalary: number | null;
  maxSalary: number | null;
  workDurationInMonthsMin: number | null;
  workDurationInMonthsMax: number | null;
  educationLevel: EDUCATION_LEVEL | null;
  sources: string[] | null;
};

export const trackFiltersAppliedEvent = (properties: EventProperties) => {
  const transformedProperties = {
    locations: properties.locations,
    country: properties.country,
    cities: properties.cities,
    min_age: properties.minAge,
    max_age: properties.maxAge,
    gender: properties.gender,
    min_salary: properties.minSalary,
    max_salary: properties.maxSalary,
    work_duration_in_months_min: properties.workDurationInMonthsMin,
    work_duration_in_months_max: properties.workDurationInMonthsMax,
    education_level: properties.educationLevel,
    sources: properties.sources,
  };

  TrackingService.logEvent('Filters Applied Clicked', transformedProperties);
};

export const trackPageBackButtonClickedEvent = () => {
  TrackingService.logEvent('Page Back Button Clicked');
};

export const trackPageSelectedEvent = (properties: { pageClicked: number }) => {
  const transformedProperties = {
    page_clicked: properties.pageClicked,
  };

  TrackingService.logEvent('Page Selected', transformedProperties);
};

export const trackPopUpDisplayedEvent = (properties: {
  page: string;
  status: string;
}) => {
  const transformedProperties = {
    page: properties.page,
    status: properties.status,
  };

  TrackingService.logEvent('pop_up_displayed', transformedProperties);
};

export const trackResumeDownloadedEvent = (properties: {
  stageOfApplication: string;
}) => {
  const transformedProperties = {
    stage_of_application: properties.stageOfApplication,
  };

  TrackingService.logEvent('Resume Downloaded', transformedProperties);
};
