import React, { ReactNode, useState } from 'react';
import { motion } from 'framer-motion';
import { Flex } from 'glints-aries/es';
import { Button, ButtonGroup, Icon, Typography } from 'glints-aries/es/@next';
import { borderRadius8 } from 'glints-aries/es/@next/utilities/borderRadius';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import {
  space4,
  space8,
  space16,
  space24,
} from 'glints-aries/es/@next/utilities/spacing';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { getConfig } from '../../config';
import RenderVIPCTALabelBasedOnVIPFreeTrial from '../VIPFreeTrial/RenderVIPCTALabelBasedOnVIPFreeTrial';
import StyledVIPGradientButton from '../VIPGradientButton';

const AccordionButton = styled.div`
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${space4};
`;

const ListItem = styled.div<{ indentation?: boolean }>`
  display: flex;
  column-gap: ${space8};
  align-items: baseline;
  ::before {
    content: '\u2022';
    padding-inline-start: ${props => (props.indentation ? '32px' : '10px')};
  }
`;

const profileLimitAccessImageLang: Record<string, string> = {
  en: 'images/full-profile-access/awareness-paywall-en.jpeg',
  id: 'images/full-profile-access/awareness-paywall-id.jpeg',
};

const FullProfileAccessInformation = () => {
  const { DEFAULT_FULL_PROFILE_ACCESS_BALANCE, LANG } = getConfig();

  const profileLimitAccessImage =
    profileLimitAccessImageLang[LANG] ?? profileLimitAccessImageLang.en;

  return (
    <>
      <Typography
        variant="body2"
        color={Neutral.B18}
        style={{ marginBottom: space4 }}
      >
        <FormattedMessage
          id="text-what-is-full-profile"
          defaultMessage="What is full profile access?"
        />
      </Typography>
      <ListItem>
        <Typography variant="body1" color={Neutral.B18}>
          <FormattedMessage
            id="text-full-profile-access-limit"
            defaultMessage="Full profile access limit means access to applicant's resume, contact details, social media links and other documents."
          />
        </Typography>
      </ListItem>
      <ListItem style={{ marginBottom: space16 }}>
        <Typography variant="body1" color={Neutral.B18}>
          <FormattedMessage
            id="text-you-can-get-full"
            defaultMessage="You can get full profile access after processing a candidate and you only receive {count} full profile access per job."
            values={{
              count: DEFAULT_FULL_PROFILE_ACCESS_BALANCE,
            }}
          />
        </Typography>
      </ListItem>
      <img
        style={{
          width: '100%',
          height: 'auto',
          borderRadius: borderRadius8,
          marginBottom: space4,
        }}
        src={profileLimitAccessImage}
        alt="Full Profile Access"
      />
      <Typography variant="subtitle2" color={Neutral.B40}>
        <FormattedMessage
          id="text-this-shown-when-you"
          defaultMessage="This is shown when you have reached the full profile access limit for the job."
        />
      </Typography>
      <Typography
        variant="body2"
        color={Neutral.B18}
        style={{ marginTop: space16 }}
      >
        <FormattedMessage
          id="text-what-reduces-my-full"
          defaultMessage="What reduces my full profile access?"
        />
      </Typography>
      <ListItem>
        <Typography variant="body1" color={Neutral.B18}>
          <FormattedMessage
            id="text-reply-to-a-candidate"
            defaultMessage="Reply to a candidate via chat."
          />
        </Typography>
      </ListItem>
      <ListItem>
        <Typography variant="body1" color={Neutral.B18}>
          <FormattedMessage
            id="text-accepting-their-cv"
            defaultMessage="Accepting their CV"
          />
        </Typography>
      </ListItem>
      <ListItem>
        <Typography variant="body1" color={Neutral.B18}>
          <FormattedMessage
            id="text-moving-them-to-any"
            defaultMessage="Moving them to any of the following stages:"
          />
        </Typography>
      </ListItem>
      <ListItem indentation={true}>
        <Typography variant="body1" color={Neutral.B18}>
          <FormattedMessage
            id="text-in-communication"
            defaultMessage="In Communication"
          />
        </Typography>
      </ListItem>
      <ListItem indentation={true}>
        <Typography variant="body1" color={Neutral.B18}>
          <FormattedMessage
            id="text_application-status_skill-test"
            defaultMessage="Skill Test"
          />
        </Typography>
      </ListItem>
      <ListItem indentation={true}>
        <Typography variant="body1" color={Neutral.B18}>
          <FormattedMessage
            id="interactive-interviewing"
            defaultMessage="Interviewing"
          />
        </Typography>
      </ListItem>
      <ListItem indentation={true}>
        <Typography variant="body1" color={Neutral.B18}>
          <FormattedMessage
            id="text_application-status_offer-sent"
            defaultMessage="Offer Sent"
          />
        </Typography>
      </ListItem>
      <ListItem indentation={true}>
        <Typography variant="body1" color={Neutral.B18}>
          <FormattedMessage id="interactive-hired" defaultMessage="Hired" />
        </Typography>
      </ListItem>
      <Typography
        variant="body2"
        color={Neutral.B18}
        style={{ marginTop: space16 }}
      >
        <FormattedMessage
          id="text-what-happens-if-you-used"
          defaultMessage="What happens if you’ve used all your unlocks?"
        />
      </Typography>
      <ListItem>
        <Typography
          variant="body1"
          color={Neutral.B18}
          style={{ marginTop: space4 }}
        >
          <FormattedMessage
            id="text-you-can-still-process"
            defaultMessage="You can still process candidates, but their contact details and other information will remain hidden"
          />
        </Typography>
      </ListItem>
      <Typography
        variant="body2"
        color={Neutral.B18}
        style={{ marginTop: space16 }}
      >
        <FormattedMessage
          id="text-how-do-I-increase"
          defaultMessage="How do I increase my limit?"
        />
      </Typography>
      <ListItem>
        <Typography
          variant="body1"
          color={Neutral.B18}
          style={{ marginTop: space4 }}
        >
          <FormattedMessage
            id="text-upgrade-to-vip-for"
            defaultMessage="Upgrade to VIP for unlimited Full Profile Limit Access."
          />
        </Typography>
      </ListItem>
    </>
  );
};

export const FullProfileAccessAwarenessModalContent = () => {
  const { DEFAULT_FULL_PROFILE_ACCESS_BALANCE } = getConfig();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <div style={{ width: 580 }}>
      <Flex
        flexDirection="column"
        alignItems="center"
        style={{ width: '100%' }}
      >
        <img
          src="images/full-profile-access/full-profile-access-awareness-asset.svg"
          style={{ marginBottom: space16 }}
        />
        <Typography
          variant="subtitle1"
          color={Neutral.B18}
          style={{
            textAlign: 'center',
          }}
        >
          <FormattedMessage
            id="text-unlock-count-contacts-resumes"
            defaultMessage="Upgrade to VIP for unlimited access to applicants' contacts and resumes! {br}<sub>Glints offers free {count} applicant contacts & resume access per job{br}for non-VIP users.</sub>"
            values={{
              count: DEFAULT_FULL_PROFILE_ACCESS_BALANCE,
              br: <br />,
              sub: (text: ReactNode[]) => (
                <Typography variant="body2" color={Neutral.B40}>
                  {text}
                </Typography>
              ),
            }}
          />
        </Typography>
        <AccordionButton onClick={toggleAccordion}>
          <Typography variant="body2" color={Neutral.B40}>
            <FormattedMessage
              id="text-frequently-asked-questions"
              defaultMessage="Frequently asked questions"
            />
          </Typography>
          <motion.div
            animate={{
              rotate: isAccordionOpen ? 180 : 0,
              transformOrigin: 'center center',
            }}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Icon
              name={'ri-arrow-m-down-line'}
              width={space24}
              height={space24}
              fill={Neutral.B40}
            />
          </motion.div>
        </AccordionButton>
      </Flex>
      <motion.div
        animate={{
          height: isAccordionOpen ? 'auto' : 0,
          opacity: isAccordionOpen ? 1 : 0,
          marginTop: isAccordionOpen ? space16 : 0,
        }}
        style={{ height: 0, overflow: 'hidden' }}
      >
        <FullProfileAccessInformation />
      </motion.div>
    </div>
  );
};

type FooterProps = {
  onClose: () => void;
  openGlintsVIPPurchaseModal: () => void;
  isGlintsVIPPurchaseModalChunkLoading: boolean;
  isVIPMembershipActive: boolean;
};

export const FullProfileAccessAwarenessModalFooter = ({
  onClose,
  openGlintsVIPPurchaseModal,
  isGlintsVIPPurchaseModalChunkLoading,
  isVIPMembershipActive,
}: FooterProps) => (
  <ButtonGroup>
    <Button onClick={onClose}>
      <Typography variant="button" color={Neutral.B18}>
        <FormattedMessage id="interactive-close" defaultMessage="Close" />
      </Typography>
    </Button>
    {!isVIPMembershipActive && (
      <StyledVIPGradientButton
        onClick={openGlintsVIPPurchaseModal}
        loading={isGlintsVIPPurchaseModalChunkLoading}
      >
        <Typography variant="button" color={Neutral.B18}>
          <RenderVIPCTALabelBasedOnVIPFreeTrial>
            <FormattedMessage
              id="interactive-upgrade-to-vip"
              defaultMessage="Upgrade to VIP"
            />
          </RenderVIPCTALabelBasedOnVIPFreeTrial>
        </Typography>
      </StyledVIPGradientButton>
    )}
  </ButtonGroup>
);
