import { ApplicationStatus } from '../../common/constants';

const PaywallRequiredApplicationStatus = [
  ApplicationStatus.NEW,
  ApplicationStatus.REJECTED,
];
export function checkIfShouldShowFPAForcedPaywallForApplications({
  profileLimitBalance,
  status,
}: {
  profileLimitBalance: number;
  status: ApplicationStatus;
}) {
  return (
    profileLimitBalance <= 0 &&
    PaywallRequiredApplicationStatus.includes(status)
  );
}
