import { User } from '../../models/User';
import { Company, SubscriptionPlanType } from '../interfaces/entities';
import { SensorsData, SensorsDataAllowedTypes } from './sensorsData';

export class TrackingService {
  static logEvent(
    eventName: string,
    eventProperties?: Record<string, unknown>
  ) {
    SensorsData.track(eventName, eventProperties);
  }

  static afterLogin(userId: string, email?: string) {
    SensorsData.loginWithKey('$identity_login_id', userId);

    if (!email) return;
    SensorsData.bind('$identity_email', email);
  }

  static afterLogout(userId: string, email?: string) {
    SensorsData.unbind('$identity_login_id', userId);

    if (email) {
      SensorsData.unbind('$identity_email', email);
    }

    SensorsData.logout();
  }

  static setUserProperties(
    userProperties: Record<string, SensorsDataAllowedTypes>
  ) {
    SensorsData.setProfile(userProperties);
  }

  static updateCompanySubscriptionAndProductsProperties(properties: {
    company: Pick<
      Company,
      | 'id'
      | 'currentCompanyJobSlotsSubscription'
      | 'creditBalance'
      | 'hotJobBalances'
      | 'recommendedTalentBalance'
      | 'talentSearchBalance'
    >;
    user: Pick<User, 'id' | 'role'>;
    empExpFpaLimitValue: string;
  }) {
    const { company, user } = properties;

    const hjBalanceEventProps = (company.hotJobBalances || []).reduce(
      (acc, balanceInventory) => ({
        ...acc,
        [`hj_${balanceInventory.variant.toLowerCase()}_credits`]:
          balanceInventory.balance,
      }),
      {}
    );

    /* eslint-disable camelcase */
    const transformedProperties = {
      company_id: company.id,
      user_id_value: user.id,
      subscription_plan:
        company.currentCompanyJobSlotsSubscription?.planType ??
        SubscriptionPlanType.FREE,
      company_total_active_jobs:
        company.currentCompanyJobSlotsSubscription?.activeConcurrentJobs ??
        null,
      role: user.role,
      rt_credits: company.recommendedTalentBalance ?? 0,
      tsc_credits: company.talentSearchBalance ?? 0,
      glints_credits: company.creditBalance ?? 0,
      emp_exp_fpa_limit_value: properties.empExpFpaLimitValue,
      ...hjBalanceEventProps,
    };
    /* eslint-enable camelcase */

    SensorsData.registerPage(transformedProperties);
  }

  static setExpInfoToUserProfile(expInfo: string) {
    if (!expInfo) return;

    const pairs = expInfo.split(',');

    const userPropertiesToSet: Record<string, SensorsDataAllowedTypes> =
      pairs.reduce((obj, pair) => {
        const [key, value] = pair.split(':');
        return {
          ...obj,
          [key]: value,
        };
      }, {});

    this.setUserProperties(userPropertiesToSet);
  }
}
