import { createAction } from 'redux-actions';

export const REGISTER_COMPONENT = 'glints/activities/REGISTER_COMPONENT';
export const DEREGISTER_COMPONENT = 'glints/activities/DEREGISTER_COMPONENT';
export const UPDATE_COMPONENT_STATE =
  'glints/activities/UPDATE_COMPONENT_STATE';

/**
 * name:- name will be used as key to store component's state.
 * initState:- component's initial state.
 */
export const registerComponent = createAction(
  REGISTER_COMPONENT,
  (name, initState) => ({ name, initState })
);

/**
 * name:- name of the component whose state is to be removed.
 */
export const deregisterComponent = createAction(DEREGISTER_COMPONENT, name => ({
  name,
}));

/**
 * name:- name of the component.
 * udpates:- an object with updates key-value pair, to be merged with previous state.
 */
export const updateComponentState = createAction(
  UPDATE_COMPONENT_STATE,
  (name, updates, replace) => ({ name, updates, replace })
);
