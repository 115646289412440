import { AnyAction, Dispatch } from '@reduxjs/toolkit';

import CALL_API from '../middleware/api_key';
import { Schemas } from '../middleware/schemas';
import { getSessionCompanyId } from '../selectors/session';
import { RootState } from '../store';

export const Actions = {
  FETCH_FEATURES_REQUEST: 'glints/features/FETCH_FEATURES_REQUEST',
  FETCH_FEATURES_REQUEST_WITH_LOADING_MODAL:
    'glints/features/FETCH_FEATURES_REQUEST_WITH_LOADING_MODAL',
  FETCH_FEATURES_SUCCESS: 'glints/features/FETCH_FEATURES_SUCCESS',
  FETCH_FEATURES_FAILURE: 'glints/features/FETCH_FEATURES_FAILURE',
};

export const fetchFeatures =
  (options?: { shouldShowLoadingModal?: boolean; companyId?: string }) =>
  async (dispatch: Dispatch, getState: RootState) => {
    const companyId = options?.companyId ?? getSessionCompanyId(getState());

    const params = companyId ? { companyId } : {};

    return await dispatch({
      type: 'fetchFeatures',
      [CALL_API]: {
        types: [
          options?.shouldShowLoadingModal
            ? Actions.FETCH_FEATURES_REQUEST_WITH_LOADING_MODAL
            : Actions.FETCH_FEATURES_REQUEST,
          Actions.FETCH_FEATURES_SUCCESS,
          Actions.FETCH_FEATURES_FAILURE,
        ],
        endpoint: 'enabled-feature-flags',
        options: {
          params,
        },
        schema: Schemas.FEATURE_ARRAY,
      },
    } as AnyAction);
  };
