import { isUndefined, pick, remove } from 'lodash-es';
import { createSelector } from 'reselect';

import { countryCodeIsIndonesia } from '../../common/helpers/countryCodeHelper';
import { getIsFeatureEnabled } from '../../selectors/features';
import { getSessionCompanyId } from '../../selectors/session';
import { getUserCompanies } from '../../selectors/user';
import { UnleashFeatureNames } from '../Unleash/featureNames';
import { MENU_ITEMS, TALENT_HUNT } from './Constants';
import { FEATURES } from './Features';
import { CUSTOM_PERMISSIONS } from './Permissions';

export const getPlatformFeatures = state => state.platform.features.value;
function getSessionUserCompany(state) {
  const userCompanies = getUserCompanies(state);
  const sessionCompanyId = getSessionCompanyId(state);
  return userCompanies.find(company => company.id === sessionCompanyId);
}
export const getMenuItems = state => {
  const menuItems = state?.platform?.features?.value?.menuItems ?? [];

  const sessionUserCompany = getSessionUserCompany(state);

  const employerATSEnabled = !isUndefined(
    state?.entities?.features?.empEmployerATS
  );

  const empRecommendedTalent = getIsFeatureEnabled(
    state,
    UnleashFeatureNames.empRecommendedTalents
  );

  const result = menuItems.filter(
    item =>
      !(
        employerATSEnabled ? ['applications', 'recommendations'] : ['eats']
      ).includes(item.value)
  );

  if (
    !sessionUserCompany ||
    !countryCodeIsIndonesia(sessionUserCompany.CountryCode)
  ) {
    remove(result, item => item.value === MENU_ITEMS.cvFinder.value);
  }

  const isCountrySupported = countryCodeIsIndonesia(
    sessionUserCompany?.CountryCode
  );
  if (!empRecommendedTalent || !sessionUserCompany || !isCountrySupported) {
    remove(result, item => item.value === MENU_ITEMS.recommendedTalent.value);
  }

  if (!sessionUserCompany || !isCountrySupported) {
    remove(result, item => item.value === MENU_ITEMS.features.value);
  }

  return result;
};

export const getPlatformFeatureNames = state => state.platform.features.names;
export const hasPlatformFeatureName = (state, name) =>
  state.platform.features.names.includes(name);
export const getFeatureSet = name => FEATURES[name] || null;

export const getMergePermissionsStates = state =>
  pick(state.platform.permissions, ['loading', 'success', 'error']);
export const getPermissions = state => state.platform.permissions.value;
export const getCustomPermissionsSet = name => CUSTOM_PERMISSIONS[name] || null;
export function authorizeCheck(activity, role, permissions) {
  if (permissions[activity]) {
    if (role && activity) {
      role = role.toUpperCase();
      return permissions[activity][role] || false;
    }
  }
  return false;
}
export const getIsTalentHuntApproved = createSelector(
  [getPlatformFeatureNames],
  platformFeatureName => platformFeatureName.includes(TALENT_HUNT)
);
