import jwtDecode from 'jwt-decode';
import { createAction } from 'redux-actions';

import { setSessionToken } from '../../../actions/session';
import axios from '../../../common/axios-ts';
import { TrackingService } from '../../../common/tracking/trackingService';
import config from '../../../config';
import { getLinkedInCsrfToken } from './selectors';

export const SignUpSource = {
  HOMEPAGE: 'homepage',
  SIGNUP_FORM: 'signup-form',
  LINKEDIN: 'linkedin',
  FACEBOOK: 'facebook',
} as const;

type SignUpSource = (typeof SignUpSource)[keyof typeof SignUpSource];

export const Actions = {
  SIGN_UP_SUCCESS: 'glints/signUp/SIGN_UP_SUCCESS',

  SIGN_UP_WITH_FACEBOOK_REQUEST: 'glints/signUp/SIGN_UP_WITH_FACEBOOK_REQUEST',
  SIGN_UP_WITH_FACEBOOK_FAILURE: 'glints/signUp/SIGN_UP_WITH_FACEBOOK_FAILURE',

  SIGN_UP_WITH_LINKEDIN_REQUEST: 'glints/signUp/SIGN_UP_WITH_LINKEDIN_REQUEST',
  SIGN_UP_WITH_LINKEDIN_FAILURE: 'glints/signUp/SIGN_UP_WITH_LINKEDIN_FAILURE',

  OPEN_FACEBOOK_DIALOG: 'glints/signUp/OPEN_FACEBOOK_DIALOG',
  CLOSE_FACEBOOK_DIALOG: 'glints/signUp/CLOSE_FACEBOOK_DIALOG',

  OPEN_LINKEDIN_DIALOG: 'glints/signUp/OPEN_LINKEDIN_DIALOG',
  CLOSE_LINKEDIN_DIALOG: 'glints/signUp/CLOSE_LINKEDIN_DIALOG',
} as const;

const signUpWithFacebookRequest = createAction(
  Actions.SIGN_UP_WITH_FACEBOOK_REQUEST
);

const signUpWithFacebookFailure = createAction(
  Actions.SIGN_UP_WITH_FACEBOOK_FAILURE
);

const signUpWithLinkedInRequest = createAction(
  Actions.SIGN_UP_WITH_LINKEDIN_REQUEST
);

const signUpWithLinkedInFailure = createAction(
  Actions.SIGN_UP_WITH_LINKEDIN_FAILURE
);

export const trackSignUpSuccess = (
  source: SignUpSource,
  userId: string,
  email?: string
) => {
  TrackingService.afterLogin(userId, email);
  TrackingService.logEvent('Employer Account Creation', {
    source,
  });
};

export function signUpWithFacebook(code: string) {
  return async (dispatch: any) => {
    try {
      dispatch(signUpWithFacebookRequest());
      const response = await axios(null, config.OAUTH2_BASE).post('/facebook', {
        code,
        clientId: config.FACEBOOK_APPID,
        apiClientId: config.CLIENT_ID,
        redirectUri: `${
          document.getElementsByTagName('base')[0].href
        }oauth2/facebook`,
        role: 'COMPANY',
      });
      const token = response?.data?.token;
      const userId = jwtDecode(token)?.sub;
      await dispatch(setSessionToken(token));
      trackSignUpSuccess(SignUpSource.FACEBOOK, userId);
    } catch (err) {
      console.error(err);
      dispatch(signUpWithFacebookFailure(err));
    }
  };
}

export function signUpWithLinkedIn(code: string) {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(signUpWithLinkedInRequest());
      const response = await axios(null, config.OAUTH2_BASE).post('/linkedin', {
        code,
        clientId: config.LINKEDIN_APPID,
        apiClientId: config.CLIENT_ID,
        redirectUri: `${
          document.getElementsByTagName('base')[0].href
        }oauth2/linkedin`,
        csrfToken: getLinkedInCsrfToken(getState()),
        role: 'COMPANY',
      });
      const token = response?.data?.token;
      const userId = jwtDecode(token)?.sub;
      await dispatch(setSessionToken(token));
      trackSignUpSuccess(SignUpSource.LINKEDIN, userId);
    } catch (err) {
      console.error(err);
      dispatch(signUpWithLinkedInFailure(err));
    }
  };
}
