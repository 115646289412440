import { createAction } from 'redux-actions';

import CALL_API from '../middleware/api_key';
import { Schemas } from '../middleware/schemas';

export const Actions = {
  COUNTRIES_REQUEST: 'glints/countries/COUNTRIES_REQUEST',
  COUNTRIES_SUCCESS: 'glints/countries/COUNTRIES_SUCCESS',
  COUNTRIES_FAILURE: 'glints/countries/COUNTRIES_FAILURE',
};

export const UPDATE_SEARCH_TERM = 'glints/countries/UPDATE_SEARCH_TERM';

export const updateCountriesSearchTerm = createAction(UPDATE_SEARCH_TERM);

export const fetchCountriesWithDispatch = options => ({
  type: 'fetchCountries',
  [CALL_API]: {
    types: [
      Actions.COUNTRIES_REQUEST,
      Actions.COUNTRIES_SUCCESS,
      Actions.COUNTRIES_FAILURE,
    ],
    endpoint: 'countries',
    schema: Schemas.COUNTRY_ARRAY,
    options: {
      ...options,
      params: {
        ...(options ? options.params : {}),
        order: 'name ASC',
      },
    },
  },
});

export function fetchCountries(options) {
  return async dispatch => {
    await dispatch({
      [CALL_API]: {
        types: [
          Actions.COUNTRIES_REQUEST,
          Actions.COUNTRIES_SUCCESS,
          Actions.COUNTRIES_FAILURE,
        ],
        endpoint: 'countries',
        schema: Schemas.COUNTRY_ARRAY,
        options: {
          ...options,
          params: {
            ...(options ? options.params : {}),
            order: 'name ASC',
          },
        },
      },
    });
  };
}
