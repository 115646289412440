import React, { useRef, useState } from 'react';
import { Button, Icon, useAlert } from 'glints-aries/es/@next';
import { PlainButton } from 'glints-aries/es/@next/Button/PlainButtonStyle';
import { uniqueId } from 'lodash-es';
import { defineMessage, defineMessages, useIntl } from 'react-intl';
import { Channel } from 'stream-chat';
import styled from 'styled-components';

import useUploadFileToS3 from '../../../common/hooks/useUploadfileToS3';
import { useStreamClient } from '../../../common/stream';
import { useMessagingChannelContext } from '../constants';
import { getFileAttachmentType } from '../helper';
import { useIsMobileVersion, useIsPopupMode } from '../hooks';
import FileUploadErrorModal from './FileUploadErrorModal';

enum FileUploadErrorCode {
  FileSizeError = 'FileSizeError',
  FileTypeError = 'FileTypeError',
  GenericNetworkError = 'GenericNetworkError',
}

const label = 'chat-attachments';
const acceptedMimeTypes = ['application/pdf', 'image/png', 'image/jpeg'];
const maxFileSizeInMB = 5;
const messages = {
  [FileUploadErrorCode.FileSizeError]: defineMessages({
    title: {
      id: 'text-file-too-big-title',
      defaultMessage: 'Your file is too big',
    },
    description: {
      id: 'text-file-too-big-desc',
      defaultMessage:
        'The file you uploaded is {fileSize} MB. The maximum file size is 5MB.',
    },
  }),
  [FileUploadErrorCode.FileTypeError]: defineMessages({
    title: {
      id: 'text-unsupport-file-type-title',
      defaultMessage: 'File type not supported',
    },
    description: {
      id: 'text-unsupport-file-type-desc',
      defaultMessage:
        'The file you uploaded is not supported. Please upload a PDF, PNG, or JPEG file.',
    },
  }),
  [FileUploadErrorCode.GenericNetworkError]: defineMessage({
    id: 'text-media-upload-failed',
    defaultMessage: 'Upload failed, please try again.',
  }),
};

const validateFile = (file: File) => {
  if (!acceptedMimeTypes.includes(file.type))
    return FileUploadErrorCode.FileTypeError;

  if (file.size > maxFileSizeInMB * 1024 * 1024)
    return FileUploadErrorCode.FileSizeError;

  return null;
};

const Styled = {
  AttachmentBtn: styled(Button)`
    padding: 14px;
    min-width: fit-content;
  `,
  AttachmentMobileBtn: styled(PlainButton)`
    padding: 0;
    min-width: fit-content;
  `,
};

type UploadFileBtnProps = {
  channel: Channel;
};

const UploadFileButton = ({ channel }: UploadFileBtnProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const streamClient = useStreamClient();
  const isMobileVersion = useIsMobileVersion();
  const isPopupMode = useIsPopupMode();
  const uploadFileToS3 = useUploadFileToS3();
  const { formatMessage } = useIntl();
  const { open: showAlert } = useAlert();
  const { sendEphemeralMessage } = useMessagingChannelContext();
  const [fileUploadErrorCode, setFileUploadErrorCode] = useState<{
    errorCode: Exclude<
      FileUploadErrorCode,
      FileUploadErrorCode.GenericNetworkError
    >;
    file: File;
  }>();

  const clearFileUploadErrorCode = () => setFileUploadErrorCode(undefined);

  const openFileBrowser = () => {
    clearFileUploadErrorCode();

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload: React.ChangeEventHandler<
    HTMLInputElement
  > = async event => {
    const file = event.target.files?.[0];
    if (!file) return;

    const errorCode = validateFile(file);
    if (errorCode)
      return setFileUploadErrorCode({
        errorCode,
        file,
      });

    const uploadFile = async () => {
      const { signed } = await uploadFileToS3(file, {
        name: `${channel.id}/${file.name}`,
        type: file.type,
        label,
      });

      const assetUrl = `${signed.request}/${label}/${channel.id}/${signed.name}`;
      await channel.sendMessage({
        attachments: [
          {
            title: file.name,
            type: getFileAttachmentType(file),
            mime_type: file.type,
            file_size: file.size,
            thumb_url: assetUrl,
            asset_url: assetUrl,
          },
        ],
        // custom field, used by mobile for push notification preview
        attachment_asset_url: assetUrl,
      });
    };

    const assetPreviewUrl = URL.createObjectURL(file);
    const ephemeralMessage = sendEphemeralMessage({
      id: uniqueId(file.name),
      type: 'regular',
      attachments: [
        {
          title: file.name,
          type: getFileAttachmentType(file),
          mime_type: file.type,
          file_size: file.size,
          thumb_url: assetPreviewUrl,
          asset_url: assetPreviewUrl,
          loading: true,
        },
      ],
      user: streamClient?.user,
      created_at: new Date().toISOString(),
    });

    try {
      await uploadFile();
    } catch (err) {
      const errorMessage = formatMessage(
        messages[FileUploadErrorCode.GenericNetworkError]
      );

      showAlert({
        status: 'error',
        children: errorMessage,
      });

      throw err;
    } finally {
      ephemeralMessage.destroy();
    }
  };

  const errorMessage =
    fileUploadErrorCode && messages[fileUploadErrorCode.errorCode];

  const ResponsiveAttachmentBtn =
    isMobileVersion || isPopupMode
      ? Styled.AttachmentMobileBtn
      : Styled.AttachmentBtn;

  return (
    <div>
      <ResponsiveAttachmentBtn
        size="large"
        icon={<Icon name="ri-attachment-line" />}
        iconPosition="left"
        onClick={openFileBrowser}
      />
      <input
        value=""
        type="file"
        ref={fileInputRef}
        accept={acceptedMimeTypes.join(',')}
        style={{ display: 'none' }}
        onChange={handleFileUpload}
      />
      {errorMessage && (
        <FileUploadErrorModal
          title={formatMessage(errorMessage.title)}
          description={formatMessage(errorMessage.description, {
            fileSize: Math.round(fileUploadErrorCode.file.size / 1024 / 1024),
          })}
          onBrowseFiles={openFileBrowser}
          onClose={clearFileUploadErrorCode}
        />
      )}
    </div>
  );
};

export default UploadFileButton;
