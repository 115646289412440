export const getUserId = state => state.user.me.value;

export const getUser = state => state.entities.user[getUserId(state)];

export const getUserRole = state => getUser(state)?.role;

export const getUserCompanies = state =>
  state?.user?.userCompanies?.value ?? [];

export const getUserPendingUserCompanies = state =>
  getUser(state)?.links?.pendingUserCompanies ?? [];

export const getUserPreferences = state => getUser(state)?.preferences ?? null;

export const getUserAppFeatures = state =>
  getUser(state)?.preferences?.features ?? null;

export const getUserNotifications = state =>
  getUser(state)?.preferences?.notifications ?? null;

export const getEmailVerificationStatus = state => getUser(state)?.isVerified;

export const getUserMetadata = state =>
  getUser(state)?.metadata?.employerMarketingParams ?? null;

export const getUserPhoneNumber = state => getUser(state)?.phone;

export const getIsPhoneNumberVerified = state =>
  getUser(state)?.isPhoneNumberVerified;
