import { createAction } from 'redux-actions';

import { APPLICATIONS_PER_PAGE_LIMIT } from '../common/constants';
import CALL_API from '../middleware/api_key';
import { Schemas } from '../middleware/schemas';

export const Actions = {
  JOB_APPLICATIONS_REQUEST: 'glints/applications/JOB_APPLICATIONS_REQUEST',
  JOB_APPLICATIONS_SUCCESS: 'glints/applications/JOB_APPLICATIONS_SUCCESS',
  JOB_APPLICATIONS_FAILURE: 'glints/applications/JOB_APPLICATIONS_FAILURE',
};

export const RESET_APPLICATIONS = 'glints/applications/RESET_APPLICATIONS';

export const resetApplications = createAction(RESET_APPLICATIONS);

export function fetchJobApplications(jobId, page = 1, options) {
  const limit = APPLICATIONS_PER_PAGE_LIMIT;
  const params = {
    ...(options ? options.params : {}),
    limit,
    order: 'createdAt DESC',
    offset: (page - 1) * limit,
  };

  return {
    [CALL_API]: {
      types: [
        Actions.JOB_APPLICATIONS_REQUEST,
        Actions.JOB_APPLICATIONS_SUCCESS,
        Actions.JOB_APPLICATIONS_FAILURE,
      ],
      endpoint: `jobs/${jobId}/applications`,
      schema: Schemas.APPLICATION_ARRAY,
      options: {
        ...options,
        params,
      },
    },
  };
}
