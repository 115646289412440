import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import useGetApplicationRejectionMessagePreferences from '../hooks/useGetApplicationRejectionMessagePreferences';
import { RejectionReasonPreferenceFormState } from '../types';
import CustomMessageOption from './components/CustomMessageOption';
import NoMessageOption from './components/NoMessageOption';
import UnifiedMessageOption from './components/UnifiedMessageOption';
import {
  REJECTION_REASON_FORM_ID,
  rejectionReasonPreferenceFormValidationSchema,
} from './consts';

type RejectionReasonPreferenceFormProps = {
  onSubmit: (data: RejectionReasonPreferenceFormState) => void;
};

export default function RejectionReasonPreferenceForm({
  onSubmit,
}: RejectionReasonPreferenceFormProps) {
  const { rejectionMessagePreferences } =
    useGetApplicationRejectionMessagePreferences({
      fetchPolicy: 'cache-first',
    });

  const forms = useForm<RejectionReasonPreferenceFormState>({
    resolver: yupResolver(rejectionReasonPreferenceFormValidationSchema),
    defaultValues: {
      preferredType: rejectionMessagePreferences.preferredType,
      rejectionMessages: rejectionMessagePreferences.rejectionMessages.map(
        message => ({
          message: message.message,
          messageType: message.messageType,
        })
      ),
    },
  });

  return (
    <FormProvider {...forms}>
      <form
        onSubmit={forms.handleSubmit(onSubmit)}
        id={REJECTION_REASON_FORM_ID}
      >
        <UnifiedMessageOption />
        <CustomMessageOption />
        <NoMessageOption />
      </form>
    </FormProvider>
  );
}
