import React from 'react';
import classNames from 'classnames';
import { Button, Greyscale, Loading } from 'glints-aries';
import PropTypes from 'prop-types';

import variables from '../../styles/variables.scss';
import styles from './AriesButtons.scss';

const BtnBlue = ({
  block = false,
  className = '',
  Icon = null,
  loading = false,
  small = false,
  inactive = false,
  style = {},
  ...props
}) => {
  const sizeStyle = { lineHeight: 1, padding: '15px 40px' };
  if (small) {
    sizeStyle.padding = '5px 12px';
  }
  const activeStyle = {
    paddingTop: '0',
    paddingBottom: '0',
  };
  if (inactive) {
    activeStyle.backgroundColor = variables.brandPrimary;
  }
  return (
    <Button
      variant="solid-blue"
      block={block}
      inactive={inactive}
      style={{ ...activeStyle, ...sizeStyle, ...style }}
      className={classNames(small ? styles.btnSmall : styles.btn, className)}
      {...props}
    >
      <If condition={Icon}>
        <div className={styles.iconWrapper}>
          <Icon color={Greyscale.white} />
        </div>
      </If>
      {props.children}
      <If condition={loading}>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Loading className={styles.loading} />
      </If>
    </Button>
  );
};

BtnBlue.propTypes = {
  block: PropTypes.bool,
  Icon: PropTypes.elementType,
  loading: PropTypes.bool,
  small: PropTypes.bool,
  children: PropTypes.node,
  props: PropTypes.object,
  className: PropTypes.string,
  inactive: PropTypes.bool,
  style: PropTypes.object,
};

export default BtnBlue;
