import React from 'react';
import { Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { FormattedMessage } from 'react-intl';

import { BenefitContainer, IconContainer } from './GlintsVIPBenefit';

const VIPFreeCreditBenefit = {
  imageSrc: '/images/credit-system/vip-benefits/free-credits.svg',
  heading: {
    id: 'text-free-glints-credits',
    defaultMessage: 'Free Glints Credits',
  },
  description: {
    id: 'text-receive-count-free-credits',
    defaultMessage:
      'Receive {count} free credits monthly until your VIP membership expires. If you have an active subscription plan, this will start when it ends.',
  },
};

type Props = {
  hideDescription?: boolean;
  amount: number;
  style?: React.CSSProperties;
};

const GlintsVIPFreeCreditBenefitContent = ({
  hideDescription,
  amount,
  style,
}: Props) => {
  const { imageSrc, heading, description } = VIPFreeCreditBenefit;

  return (
    <BenefitContainer data-align-center={hideDescription} style={style}>
      <IconContainer src={imageSrc} />
      <div>
        <Typography variant="body2">
          <FormattedMessage {...heading} />
        </Typography>
        <If condition={!hideDescription}>
          <Typography variant="body1" color={Neutral.B40}>
            <FormattedMessage {...description} values={{ count: amount }} />
          </Typography>
        </If>
      </div>
    </BenefitContainer>
  );
};

export default GlintsVIPFreeCreditBenefitContent;
