import { createAction } from 'redux-actions';

import CALL_API from '../../middleware/api_key';

export const Actions = {
  glints: {
    TOGGLE_CREATE_COMPANY_MODAL:
      'glints/landing/glints/TOGGLE_CREATE_COMPANY_MODAL',
  },
  FETCH_STATS_REQUEST: 'glints/landing/stats/FETCH_STATS_REQUEST',
  FETCH_STATS_SUCCESS: 'glints/landing/stats/FETCH_STATS_SUCCESS',
  FETCH_STATS_FAILED: 'glints/landing/stats/FETCH_STATS_FAILED',
};

export const toggleCreateCompanyModal = createAction(
  Actions.glints.TOGGLE_CREATE_COMPANY_MODAL
);

export function fetchStats() {
  return {
    [CALL_API]: {
      types: [
        Actions.FETCH_STATS_REQUEST,
        Actions.FETCH_STATS_SUCCESS,
        Actions.FETCH_STATS_FAILED,
      ],
      endpoint: 'stats/landing',
      responseTransformer: response => ({
        data: response,
      }),
    },
  };
}
