/**
 * The client configuration has been moved to the server side.
 * Check server/src/clientConfig.ts.
 */

/**
 * @deprecated use getConfig instead
 */
const config =
  typeof window !== 'undefined' ? window.GlintsEmployersAppConfig : {};

export function getConfig() {
  return typeof window !== 'undefined' ? window.GlintsEmployersAppConfig : {};
}

export default config;
