import { handleActions } from 'redux-actions';

import { Actions } from './Actions';

export const initialState = {
  requestToken: {
    loading: false,
    error: null,
    success: false,
    email: null,
  },
  setPassword: {
    id: null,
    token: null,
    loading: false,
    error: null,
    success: false,
  },
};

export default handleActions(
  {
    [Actions.PASSWORD_RESET_TOKEN_REQUEST]: state => ({
      ...state,
      requestToken: {
        ...initialState.requestToken,
        loading: true,
      },
    }),
    [Actions.PASSWORD_RESET_TOKEN_SUCCESS]: (state, action) => ({
      ...state,
      requestToken: {
        ...initialState.requestToken,
        success: true,
        email: action.payload,
      },
    }),
    [Actions.PASSWORD_RESET_TOKEN_FAILURE]: (state, action) => ({
      ...state,
      requestToken: {
        ...initialState.requestToken,
        error: action.payload.response,
      },
    }),
    [Actions.SET_NEW_PASSWORD_REQUEST]: state => ({
      ...state,
      setPassword: {
        ...state.setPassword,
        loading: true,
        error: null,
        success: false,
      },
    }),
    [Actions.SET_NEW_PASSWORD_SUCCESS]: state => ({
      ...state,
      setPassword: {
        ...state.setPassword,
        loading: false,
        success: true,
        error: null,
      },
    }),
    [Actions.SET_NEW_PASSWORD_FAILURE]: (state, action) => ({
      ...state,
      setPassword: {
        ...state.setPassword,
        loading: false,
        success: false,
        error: action.payload.response,
      },
    }),
    [Actions.RESET_STATE]: () => initialState,
    [Actions.UPDATE_SET_PASSWORD_PARAMS]: (state, action) => ({
      ...state,
      setPassword: {
        id: action.payload.id,
        token: action.payload.token,
      },
    }),
  },
  initialState
);
