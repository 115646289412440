import { useCallback } from 'react';
import dayjs from 'dayjs';
import { useLocalStorage } from 'react-use';

import { useGetSessionCompany } from '../../../common/hooks/useGetSessionCompany';

type MonetisationBannerClosedCache = {
  subscriptionPlanExceedingBanner?: string;
  subscriptionPlanExpiringBanner?: string;
  subscriptionUpgradeToPremiumBanner?: string;
  vipMembershipExpiringBanner?: string;
  vipMembershipExpiredBanner?: string;
};

const DURATION_TO_SHOW_BANNER_AGAIN_IN_HOURS = 24;

function canShowBanner(closedAtDate?: string) {
  if (!closedAtDate) return true;
  const now = dayjs();
  const closedAt = dayjs(closedAtDate).add(
    DURATION_TO_SHOW_BANNER_AGAIN_IN_HOURS,
    'h'
  );
  return now.isAfter(closedAt);
}

function getBannerClosedDate() {
  return dayjs().toJSON();
}

export const useMonetisationBannerClosedLocalStorage = () => {
  const { sessionCompany } = useGetSessionCompany();
  const [
    cachedMonetisationBannersClosedAt,
    setMonetisationBannerClosedAtCache,
  ] = useLocalStorage<MonetisationBannerClosedCache>(
    `monetisationBannersClosed:${sessionCompany?.id ?? ''}`,
    {
      subscriptionPlanExceedingBanner: undefined,
      subscriptionPlanExpiringBanner: undefined,
      subscriptionUpgradeToPremiumBanner: undefined,
      vipMembershipExpiringBanner: undefined,
      vipMembershipExpiredBanner: undefined,
    }
  );

  const setSubscriptionPlanExceedingBannerClosed = useCallback(() => {
    setMonetisationBannerClosedAtCache(state => ({
      ...state,
      subscriptionPlanExceedingBanner: getBannerClosedDate(),
    }));
  }, [setMonetisationBannerClosedAtCache]);

  const setSubscriptionPlanExpiringBannerClosed = useCallback(() => {
    setMonetisationBannerClosedAtCache(state => ({
      ...state,
      subscriptionPlanExpiringBanner: getBannerClosedDate(),
    }));
  }, [setMonetisationBannerClosedAtCache]);
  const setSubscriptionUpgradeToPremiumBannerClosed = useCallback(() => {
    setMonetisationBannerClosedAtCache(state => ({
      ...state,
      subscriptionUpgradeToPremiumBanner: getBannerClosedDate(),
    }));
  }, [setMonetisationBannerClosedAtCache]);

  const setVIPMembershipExpiringBannerClosed = useCallback(() => {
    setMonetisationBannerClosedAtCache(state => ({
      ...state,
      vipMembershipExpiringBanner: getBannerClosedDate(),
    }));
  }, [setMonetisationBannerClosedAtCache]);

  const setVIPMembershipExpiredBannerClosed = useCallback(() => {
    setMonetisationBannerClosedAtCache(state => ({
      ...state,
      vipMembershipExpiredBanner: getBannerClosedDate(),
    }));
  }, [setMonetisationBannerClosedAtCache]);

  return [
    {
      canShowSubscriptionPlanExceedingBanner: canShowBanner(
        cachedMonetisationBannersClosedAt?.subscriptionPlanExceedingBanner
      ),
      canShowSubscriptionPlanExpiringBanner: canShowBanner(
        cachedMonetisationBannersClosedAt?.subscriptionPlanExpiringBanner
      ),
      canShowSubscriptionUpgradeToPremiumBanner: canShowBanner(
        cachedMonetisationBannersClosedAt?.subscriptionUpgradeToPremiumBanner
      ),
      canShowVIPMembershipExpiringBanner: canShowBanner(
        cachedMonetisationBannersClosedAt?.vipMembershipExpiringBanner
      ),
      canShowVIPMembershipExpiredBanner: canShowBanner(
        cachedMonetisationBannersClosedAt?.vipMembershipExpiredBanner
      ),
    },
    {
      setSubscriptionPlanExceedingBannerClosed,
      setSubscriptionPlanExpiringBannerClosed,
      setSubscriptionUpgradeToPremiumBannerClosed,
      setVIPMembershipExpiringBannerClosed,
      setVIPMembershipExpiredBannerClosed,
    },
  ] as const;
};
