/* eslint-disable camelcase */
import { TrackingService } from '../../../../../common/tracking/trackingService';

export const TrackESCtoCloseSource = {
  APPLICATION_POPUP: 'Application Popup',
  CHAT_WIDGET: 'Chat Widget',
} as const;

type TrackESCtoCloseSource =
  (typeof TrackESCtoCloseSource)[keyof typeof TrackESCtoCloseSource];

export const trackESCtoClose = (payload: {
  applicationId: string;
  source: TrackESCtoCloseSource;
}) => {
  TrackingService.logEvent('Escape key pressed', payload);
};

export const trackApplicationViewedEvent = (
  properties: {
    jobId: string;
    applicationId: string;
    page: 'Application Detail Page' | 'Manage Candidates Popup';
    utmMedium?: string;
    utmSource?: string;
    utmCampaign?: string;
  },
  onSuccess?: () => void
) => {
  const transformedProperties = {
    job_id: properties.jobId,
    application_id: properties.applicationId,
    page: properties.page,
    utm_medium: properties.utmMedium,
    utm_source: properties.utmSource,
    utm_campaign: properties.utmCampaign,
  };
  TrackingService.logEvent('Application Viewed', transformedProperties);
  onSuccess?.();
};
