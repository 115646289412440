import { handleActions } from 'redux-actions';

import {
  Actions,
  RECEIVE_UPDATE_JOB,
  REQUEST_UPDATE_JOB,
} from '../actions/job';

const initialState = {
  value: null,
  error: null,
  loading: false,

  updating: false,
  updateError: null,
  updateSuccess: false,
};

export default handleActions(
  {
    [Actions.JOB_REQUEST]: state => ({
      ...state,
      loading: true,
      error: null,
      value: null,
    }),
    [Actions.JOB_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      value: action.response.result,
    }),
    [Actions.JOB_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error
        ? action.response
          ? action.response
          : action.error
        : null,
      value: null,
    }),
    [REQUEST_UPDATE_JOB]: state => ({
      ...state,
      updateError: null,
      updating: true,
      updateSuccess: false,
    }),
    [RECEIVE_UPDATE_JOB]: (state, action) => ({
      ...state,
      updateError: action.error ? action.payload : null,
      updating: false,
      updateSuccess: !action.error,
    }),
  },
  initialState
);
