import React from 'react';
import { Spinner } from 'glints-aries/es/@next';
import { Blue } from 'glints-aries/es/@next/utilities/colors';
import { defineMessage, useIntl } from 'react-intl';

import { FullBackground, StyledContainerV2 } from './ContainerV2.sc';

type ContainerV2Props = {
  fullWidth?: boolean;
} & (
  | {
      children: React.ReactNode;
      isLoading?: boolean;
    }
  | {
      isLoading: true;
      children?: never;
    }
);

const loadingMessage = defineMessage({
  id: 'text-loading',
  defaultMessage: 'Loading...',
});

const ContainerV2 = ({ children, isLoading, fullWidth }: ContainerV2Props) => {
  const { formatMessage } = useIntl();

  return (
    <FullBackground className="full-background">
      <StyledContainerV2 data-loading={isLoading} data-full-width={fullWidth}>
        <Choose>
          <When condition={isLoading}>
            <Spinner
              size="large"
              fill={Blue.S99}
              label={`${formatMessage(loadingMessage)}...`}
            />
          </When>
          <Otherwise>{children}</Otherwise>
        </Choose>
      </StyledContainerV2>
    </FullBackground>
  );
};

export default ContainerV2;
