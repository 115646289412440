import React, { useCallback } from 'react';
import { Button, PowerIcon } from 'glints-aries';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import authenticate from '../../common/authenticate';
import { COUNTRY_DOMAINS } from '../../common/constants';
import config from '../../config';
import { getFeatures } from '../../selectors/features';
import { getIsLoggedIn, getSessionCompany } from '../../selectors/session';
import { getUser, getUserRole } from '../../selectors/user';
import { getMenuItems, getPermissions, getPlatformFeatures } from '../Platform';
import { MenuItem, MenuType } from '../Platform/Constants';
import { closeSidebar } from './Actions';
import CTAButton from './CTAButton';
import CTAButtonLoggedIn from './CTAButtonLoggedIn';
import { Avatar } from './NavbarCommon.sc';
import {
  disableLink,
  filteredMenuItems,
  renderItemLabel,
} from './navigationUtils';
import {
  Name,
  ProfileSection,
  SidebarCloseIcon,
  SidebarCloseWrapper,
  SidebarItem,
  SidebarMenu,
  SidebarParentItem,
  SidebarSection,
  SidebarWrapper,
} from './Sidebar.sc';
import { Spring2021LandingMenuItems } from './Spring2021LandingMenuItems';
import { useGetMenuItemsBuilder } from './useGetMenuItemsBuilder';

const { COUNTRY } = config;

const Sidebar: React.FC<SidebarProps> = ({ authenticated }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const platformFeatures = useSelector(getPlatformFeatures);
  const company = useSelector(getSessionCompany);
  const me = useSelector(getUser);
  const menuItems = useSelector<unknown, MenuItem[]>(getMenuItems);
  const permissions = useSelector(getPermissions);
  const role = useSelector(getUserRole);
  const { empBuilderIntegration } = useSelector(getFeatures);

  const hideSignUp = platformFeatures?.menu?.hideSignUp ?? false;

  const landingNavItems = useGetMenuItemsBuilder();

  const isLoggedIn = useSelector(getIsLoggedIn);

  const handleSidebarDismissed = useCallback(() => {
    dispatch(closeSidebar());
  }, [dispatch]);

  const fromLandingPage = location.pathname === '/';
  const showNewLandingNavigation =
    fromLandingPage && (COUNTRY === 'id' || COUNTRY === 'sg');
  const domain = config?.COUNTRY ?? COUNTRY_DOMAINS.default;
  const candidateUrl =
    domain && domain !== COUNTRY_DOMAINS.default
      ? `${config.CANDIDATE_URL}/${domain}`
      : config.CANDIDATE_URL;

  return (
    <SidebarWrapper>
      <SidebarCloseWrapper>
        <SidebarCloseIcon onClick={handleSidebarDismissed} />
      </SidebarCloseWrapper>
      <SidebarMenu onClick={handleSidebarDismissed}>
        <If
          condition={
            !empBuilderIntegration && showNewLandingNavigation && !authenticated
          }
        >
          <Spring2021LandingMenuItems sidebar={true} />
        </If>
        <If condition={!authenticated}>
          <If condition={!hideSignUp && !(config.COUNTRY === 'id')}>
            <SidebarItem to="/signup">
              <FormattedMessage
                id="modules.Navigation.Sidebar.sign.up"
                defaultMessage="Sign Up"
              />
            </SidebarItem>
          </If>
          <SidebarItem to="/login">
            <FormattedMessage
              id="modules.Navigation.Sidebar.login"
              defaultMessage="Login"
            />
          </SidebarItem>
          <If condition={!empBuilderIntegration}>
            <CTAButton />
          </If>
        </If>
        <If condition={authenticated}>
          <CTAButtonLoggedIn closeSidebar={handleSidebarDismissed} />
        </If>
        {filteredMenuItems(menuItems, authenticated, empBuilderIntegration).map(
          item => {
            if (item.children) {
              return (
                // eslint-disable-next-line react/jsx-key
                <SidebarParentItem title={renderItemLabel(intl, item.label)}>
                  {item.children.map(
                    child =>
                      child.to && (
                        <SidebarItem
                          key={child.value}
                          to={child.to}
                          disabled={
                            child.optional &&
                            disableLink(role, company, permissions)
                          }
                        >
                          {renderItemLabel(intl, child.label)}
                        </SidebarItem>
                      )
                  )}
                </SidebarParentItem>
              );
            } else {
              return (
                <SidebarItem
                  activeClassName="ant-menu-item-selected"
                  key={item.value}
                  to={item.to}
                  disabled={
                    item.canBeDisabled &&
                    disableLink(role, company, permissions)
                  }
                  badge={item.badge}
                >
                  {renderItemLabel(intl, item.label)}
                </SidebarItem>
              );
            }
          }
        )}
        <If condition={empBuilderIntegration && !isLoggedIn}>
          {landingNavItems.items.map(item => {
            if (item.subNav?.length) {
              return (
                <SidebarParentItem key={item.label} title={item.label}>
                  {item.subNav.map(item => (
                    <SidebarItem key={item.label} to={item.url}>
                      {item.label}
                    </SidebarItem>
                  ))}
                </SidebarParentItem>
              );
            } else {
              return (
                <SidebarItem key={item.label} to={item.url}>
                  {item.label}
                </SidebarItem>
              );
            }
          })}
        </If>
        <If condition={empBuilderIntegration && !authenticated}>
          <SidebarItem to={candidateUrl}>
            <FormattedMessage
              id="modules.Navigation.CTAButton.candidates"
              defaultMessage="Job Seekers"
            />
          </SidebarItem>
          <SidebarItem to={landingNavItems?.button?.url || ''}>
            <Button variant="solid-blue" block={true}>
              {landingNavItems?.button?.label}
            </Button>
          </SidebarItem>
        </If>
        <If condition={authenticated}>
          <SidebarSection
            title={
              <ProfileSection>
                <If condition={me}>
                  <Avatar
                    profilePic={me.profilePic}
                    userId={me.id}
                    alt="Profile Picture"
                  />
                </If>
                <Name>{me && me.firstName}</Name>
              </ProfileSection>
            }
          >
            {menuItems
              .filter(link => link.type === MenuType.user)
              .map(item => {
                if (item.children) {
                  return item.children.map(
                    child =>
                      child.to && (
                        <SidebarItem
                          key={child.value}
                          to={child.to}
                          disabled={
                            child.optional &&
                            disableLink(role, company, permissions)
                          }
                        >
                          {renderItemLabel(intl, child.label)}
                        </SidebarItem>
                      )
                  );
                } else {
                  return (
                    <SidebarItem
                      activeClassName="ant-menu-item-selected"
                      key={item.value}
                      to={item.to}
                      disabled={
                        item.canBeDisabled &&
                        disableLink(role, company, permissions)
                      }
                      badge={item.badge}
                    >
                      {renderItemLabel(intl, item.label)}
                    </SidebarItem>
                  );
                }
              })}
            <SidebarItem to="/logout">
              <PowerIcon />
              &nbsp;
              <FormattedMessage
                id="interactive-log-out"
                defaultMessage="Logout"
              />
            </SidebarItem>
          </SidebarSection>
        </If>
      </SidebarMenu>
    </SidebarWrapper>
  );
};

type SidebarProps = {
  authenticated: boolean;
};

export default authenticate(Sidebar, true);
