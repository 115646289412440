import React from 'react';
import PropTypes from 'prop-types';

import { DEFAULT_COMPANY_LOGO } from '../../common/constants';
import GlintsPicture from './GlintsPicture';

const CompanyLogo = ({ logo, ...otherProps }) => (
  <GlintsPicture
    name={logo ? logo : DEFAULT_COMPANY_LOGO}
    assetType="company-logo"
    fallback={DEFAULT_COMPANY_LOGO}
    fallbackAssetType="company-logo"
    {...otherProps}
  />
);

CompanyLogo.propTypes = {
  logo: PropTypes.string,
};

export default CompanyLogo;
