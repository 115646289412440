import React, { Component } from 'react';
import { Row } from 'antd';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { updateMetadata } from '../../actions/meta';
import { BtnBlue } from '../../components/Buttons';
import styles from './styles.scss';

const supportAddress = 'hi@glints.com';
const mailLink = (...chunks) => (
  <a href={`mailto:${supportAddress}`}>{chunks}</a>
);

class Error500Page extends Component {
  static propTypes = {
    updateMetadata: PropTypes.func,
    error: PropTypes.any,
    info: PropTypes.any,
    intl: PropTypes.object,
  };

  componentDidMount() {
    this.props.updateMetadata(
      this.props.intl.formatMessage(messages.internalServerError),
      null,
      null,
      500
    );
  }

  render() {
    const { intl } = this.props;
    return (
      <Row
        className={styles.content}
        type="flex"
        justify="center"
        align="middle"
      >
        <div className={styles.imageWrapper}>
          <img
            src="/images/500.png"
            alt={intl.formatMessage(messages.numberInternalServerError)}
          />
        </div>
        <div className={styles.textWrapper}>
          <h4>
            <FormattedMessage
              tagName="strong"
              id="modules.ErrorPages.Error500.internal.server.error"
              defaultMessage="Internal Server Error"
            />
          </h4>
          <p>
            <FormattedMessage
              id="modules.ErrorPages.Error500.description"
              defaultMessage="The Application Encountered an Error"
            />
            <br />
            <FormattedMessage
              id="modules.ErrorPages.Error500.nextSteps"
              defaultMessage="Please try again or contact our support at <a>{supportAddress}</a>"
              values={{
                a: mailLink,
                supportAddress,
              }}
            />
          </p>
        </div>
        <a href="/">
          <BtnBlue>
            <FormattedMessage
              id="modules.ErrorPages.Error500.take.me.home"
              defaultMessage="Take me home!"
            />
          </BtnBlue>
        </a>
      </Row>
    );
  }
}

Error500Page = connect(null, dispatch => ({
  updateMetadata: (title, description, image, prerenderStatusCode) =>
    dispatch(updateMetadata(title, description, image, prerenderStatusCode)),
}))(injectIntl(Error500Page));

const messages = defineMessages({
  internalServerError: {
    id: 'modules.ErrorPages.Error500.internal.server.error',
    defaultMessage: 'Internal Server Error',
  },
  numberInternalServerError: {
    id: 'modules.ErrorPages.Error500.500.internal.server',
    defaultMessage: '500 Internal Server Error',
  },
});

export default Error500Page;
