import { merge } from 'lodash-es';

import { Actions } from '../actions/entities';
import { Actions as FeatureActions } from '../actions/features';

const initialState = {
  application: {},
  bookmark: {},
  company: {},
  companyUser: {},
  city: {},
  country: {},
  currency: {},
  group: {},
  industry: {},
  job: {},
  jobDraft: {},
  jobCategory: {},
  jobTitle: {},
  jobType: {},
  recommendation: {},
  school: {},
  skill: {},
  study: {},
  tag: {},
  talent: {},
  talentProfile: {},
  talentHuntApplication: {},
  user: {},
  recruiter: {},
  features: {},
};

/**
 * The default case updates an entity cache in response to any action with response.entities.
 */
export default function entities(state = initialState, action) {
  switch (action.type) {
    case Actions.UPDATE_JOB_ENTITY:
      return {
        ...state,
        job: {
          ...state.job,
          [action.payload.id]: {
            ...state.job[action.payload.id],
            ...action.payload.data,
          },
        },
      };
    case Actions.REMOVE_JOB_ENTITY:
      delete state.job[action.payload];
      return state;
    case Actions.ADD_JOB_DRAFT_ENTITY:
      return {
        ...state,
        jobDraft: {
          ...state.jobDraft,
          [action.payload.id]: action.payload,
        },
      };
    case Actions.UPDATE_JOB_DRAFT_ENTITY:
      return {
        ...state,
        jobDraft: {
          ...state.jobDraft,
          [action.payload.id]: {
            ...state.jobDraft[action.payload.id],
            ...action.payload.data,
          },
        },
      };
    case Actions.UPDATE_JOB_DRAFT_ENTITY_SKILLS:
      return {
        ...state,
        jobDraft: {
          ...state.jobDraft,
          [action.payload.id]: {
            ...state.jobDraft[action.payload.id],
            links: {
              ...state.jobDraft[action.payload.id].links,
              skills: action.payload.data,
            },
          },
        },
      };
    case Actions.UPDATE_JOB_DRAFT_ENTITY_JOB_TITLES:
      return {
        ...state,
        jobDraft: {
          ...state.jobDraft,
          [action.payload.id]: {
            ...state.jobDraft[action.payload.id],
            links: {
              ...state.jobDraft[action.payload.id].links,
              jobTitles: action.payload.data,
            },
          },
        },
      };
    case Actions.UPDATE_JOB_DRAFT_ENTITY_SALARIES:
      return {
        ...state,
        jobDraft: {
          ...state.jobDraft,
          [action.payload.id]: {
            ...state.jobDraft[action.payload.id],
            links: {
              ...state.jobDraft[action.payload.id].links,
              jobSalaryDrafts: action.payload.data,
            },
          },
        },
      };
    case Actions.UPDATE_JOB_DRAFT_ENTITY_GROUPS:
      return {
        ...state,
        jobDraft: {
          ...state.jobDraft,
          [action.payload.id]: {
            ...state.jobDraft[action.payload.id],
            links: {
              ...state.jobDraft[action.payload.id].links,
              groups: action.payload.data,
            },
          },
        },
      };
    case Actions.REMOVE_JOB_DRAFT_ENTITY:
      delete state.jobDraft[action.payload];
      return state;
    case Actions.UPDATE_GROUP_ENTITY:
      return {
        ...state,
        group: {
          ...state.group,
          [action.payload.id]: merge(
            {},
            state.group[action.payload.id],
            action.payload.data
          ),
        },
      };
    case Actions.UPDATE_TALENT_ENTITY:
      return {
        ...state,
        talent: {
          ...state.talent,
          [action.payload.id]: merge(
            {},
            state.talent[action.payload.id],
            action.payload.data
          ),
        },
      };
    case Actions.REMOVE_TALENT_JOB_ROLE_PREFERENCE:
      return {
        ...state,
        talent: {
          ...state.talent,
          [action.payload.talentId]: {
            ...state.talent[action.payload.talentId],
            JobRolePreferences: state.talent[
              action.payload.talentId
            ].JobRolePreferences.filter(
              x => x.id !== action.payload.preferenceId
            ),
          },
        },
      };
    case Actions.ADD_TALENT_EXPERIENCE_ITEM:
      return {
        ...state,
        talent: {
          ...state.talent,
          [action.payload.talentId]: {
            ...state.talent[action.payload.talentId],
            Experiences: state.talent[
              action.payload.talentId
            ].Experiences.concat(action.payload.data),
          },
        },
      };
    case Actions.EDIT_TALENT_EXPERIENCE_ITEM:
      return {
        ...state,
        talent: {
          ...state.talent,
          [action.payload.talentId]: {
            ...state.talent[action.payload.talentId],
            Experiences: state.talent[action.payload.talentId].Experiences.map(
              experience => {
                if (experience.id === action.payload.editedData.id) {
                  return action.payload.editedData;
                }
                return experience;
              }
            ),
          },
        },
      };
    case Actions.REMOVE_TALENT_EXPERIENCE_ITEM:
      return {
        ...state,
        talent: {
          ...state.talent,
          [action.payload.talentId]: {
            ...state.talent[action.payload.talentId],
            Experiences: state.talent[
              action.payload.talentId
            ].Experiences.filter(x => x.id !== action.payload.experienceId),
          },
        },
      };
    case Actions.ADD_TALENT_SKILL:
      return {
        ...state,
        talent: {
          ...state.talent,
          [action.payload.talentId]: {
            ...state.talent[action.payload.talentId],
            Skills: state.talent[action.payload.talentId].Skills.concat(
              action.payload.skill
            ),
          },
        },
      };
    case Actions.REMOVE_TALENT_SKILL:
      return {
        ...state,
        talent: {
          ...state.talent,
          [action.payload.talentId]: {
            ...state.talent[action.payload.talentId],
            Skills: state.talent[action.payload.talentId].Skills.filter(
              x => x.id !== action.payload.skillId
            ),
          },
        },
      };
    case Actions.UPDATE_ENTITIES:
      return {
        ...state,
        [action.payload.entity]: {
          ...state[action.payload.entity],
          ...action.payload.value,
        },
      };
    case Actions.UPDATE_COMPANY_USER_ENTITY:
      return {
        ...state,
        companyUser: {
          ...state.companyUser,
          [action.payload.id]: {
            ...state.companyUser[action.payload.id],
            ...action.payload.data,
          },
        },
      };
    case Actions.REMOVE_COMPANY_USER_ENTITY:
      return {
        ...state,
        companyUser: {
          [action.payload.id]: undefined,
          ...state.companyUser,
        },
      };
    case Actions.UPDATE_ACTIVITY_LOG_ENTITY:
      return {
        ...state,
        activityLog: {
          ...state.activityLog,
          [action.payload.id]: {
            ...state.activityLog[action.payload.id],
            ...action.payload.data,
          },
        },
      };
    case Actions.REMOVE_ACTIVITY_LOG_ENTITY:
      delete state.activityLog[action.payload];
      return state;
    case Actions.UPDATE_TALENTHUNT_APPLICATION_ENTITY:
      return {
        ...state,
        talentHuntApplication: {
          ...state.talentHuntApplication,
          [action.payload.id]: merge(
            {},
            state.talentHuntApplication[action.payload.id],
            action.payload.data
          ),
        },
      };
    case Actions.UPDATE_TALENT_ATTACHMENT:
      return {
        ...state,
        talent: {
          ...state.talent,
          [action.payload.id]: {
            ...state.talent[action.payload.id],
            portfolio: {
              ...state.talent[action.payload.id].portfolio,
              attachment: action.payload.data,
            },
          },
        },
      };
    case Actions.UPDATE_TALENT:
      return {
        ...state,
        talent: {
          ...state.talent,
          [action.payload.id]: {
            ...state.talent[action.payload.id],
            ...action.payload.data,
          },
        },
      };
    case FeatureActions.FETCH_FEATURES_SUCCESS:
      // Replace the entities with the new ones (not merge)
      return {
        ...state,
        features: action.response?.entities?.features ?? {},
      };
    default:
      if (action.response && action.response.entities) {
        return merge({}, state, action.response.entities);
      }
      return state;
  }
}
