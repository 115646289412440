import { COUNTRY_DOMAINS } from '../../common/constants';

export const AppLanguageStorageKey = 'appLanguage';

export type SupportedLanguages = 'en' | 'id' | 'vi' | 'zh';

export const defaultAppLanguages: SupportedLanguages[] = ['en'];

export const appLanguagesBasedOnCountry: Record<string, SupportedLanguages[]> =
  {
    [COUNTRY_DOMAINS.sg]: defaultAppLanguages,
    [COUNTRY_DOMAINS.id]: ['id', ...defaultAppLanguages],
    [COUNTRY_DOMAINS.vn]: ['vi', ...defaultAppLanguages],
    [COUNTRY_DOMAINS.my]: defaultAppLanguages,
    [COUNTRY_DOMAINS.hk]: defaultAppLanguages,
    [COUNTRY_DOMAINS.tw]: ['zh', ...defaultAppLanguages],
  };

export const appLanguageToContentMap: Record<
  SupportedLanguages,
  {
    shortForm: string;
    longForm: string;
  }
> = {
  en: {
    shortForm: 'EN',
    longForm: 'English',
  },
  id: {
    shortForm: 'ID',
    longForm: 'Bahasa Indonesia',
  },
  vi: {
    shortForm: 'VI',
    longForm: 'Việt Nam',
  },
  zh: {
    shortForm: 'CN',
    longForm: '繁体中文',
  },
};
