import { useCallback } from 'react';
import {
  gql,
  skipToken,
  SuspenseQueryHookFetchPolicy,
  useQuery,
  useSuspenseQuery,
} from '@apollo/client';

import { useGetSessionCompany } from '../../../common/hooks/useGetSessionCompany';
import { BaseEmployerInput } from '../../../common/interfaces/entities';
import { CompanyCreditInventory } from '../types';

const query = gql`
  query getCompanyCreditInventory($input: GetCompanyCreditInventoryInput!) {
    getCompanyCreditInventory(input: $input) {
      amount
      expiryDateTime
    }
  }
`;

type GetCompanyCreditInventoryVariables = {
  input: BaseEmployerInput;
};

type GetCompanyCreditInventoryResponse = {
  getCompanyCreditInventory?: CompanyCreditInventory;
};

export const useGetCompanyCreditInventorySuspenseQuery = (
  fetchPolicy: SuspenseQueryHookFetchPolicy = 'cache-and-network'
) => {
  const { sessionCompany } = useGetSessionCompany();

  const companyId = sessionCompany?.id;

  const { data, refetch } = useSuspenseQuery<
    GetCompanyCreditInventoryResponse,
    GetCompanyCreditInventoryVariables
  >(
    query,
    companyId
      ? {
          variables: {
            input: {
              companyId: companyId,
            },
          },
          fetchPolicy,
        }
      : skipToken
  );

  return {
    data: data?.getCompanyCreditInventory || {
      amount: 0,
      expiryDateTime: null,
    },
    refetchGetCompanyCreditInventory: refetch,
  } as const;
};

export const useGetCompanyCreditInventory = () => {
  const { sessionCompany } = useGetSessionCompany();

  const companyId = sessionCompany?.id;

  const { data, loading, error, refetch } = useQuery<
    GetCompanyCreditInventoryResponse,
    GetCompanyCreditInventoryVariables
  >(query, {
    variables: {
      input: {
        companyId: companyId || '',
      },
    },
    skip: !companyId,
    fetchPolicy: 'cache-and-network',
  });

  const refetchGetCompanyCreditInventory = useCallback(() => {
    refetch();
  }, [refetch]);

  return {
    data: data?.getCompanyCreditInventory,
    loading,
    error,
    refetchGetCompanyCreditInventory,
  } as const;
};
