import {
  camelCaseToSnakeCase,
  flattenObject,
  titleCaseToSnakeCase,
} from './helpers';

const getSensorsDataInstance = () => window.sensors;

export type SensorsDataAllowedTypes =
  | null
  | string
  | number
  | boolean
  | Array<string | number | boolean>;

export const processEventPropertiesForSensorsData = (
  properties: Record<string, unknown>
): Record<string, SensorsDataAllowedTypes> => {
  const flattenProperties = flattenObject(properties);
  const processedProperties: Record<string, SensorsDataAllowedTypes> = {};

  Object.keys(flattenProperties).forEach(key => {
    const value = flattenProperties[key];

    // SensorsData doesn't send events with null or undefined values
    if (value === null || value === undefined) return;

    if (
      Array.isArray(value) &&
      value.every(
        item =>
          typeof item === 'string' ||
          typeof item === 'number' ||
          typeof item === 'boolean'
      )
    ) {
      processedProperties[camelCaseToSnakeCase(key)] = value;
      return;
    }

    if (Array.isArray(value)) {
      processedProperties[camelCaseToSnakeCase(key)] = value.map(item =>
        JSON.stringify(item)
      );
      return;
    }

    processedProperties[camelCaseToSnakeCase(key)] =
      value as SensorsDataAllowedTypes;
  });

  return processedProperties;
};

export class SensorsData {
  static track(eventName: string, eventProperties?: Record<string, unknown>) {
    const sensors = getSensorsDataInstance();
    if (!sensors) return;

    const formattedEventName = titleCaseToSnakeCase(eventName);

    const formattedEventProperties = processEventPropertiesForSensorsData(
      eventProperties || {}
    );

    sensors.track(formattedEventName, formattedEventProperties);
  }

  static loginWithKey(key: string, value: string) {
    const sensors = getSensorsDataInstance();
    if (!sensors) return;

    sensors.loginWithKey(key, value);
  }

  static bind(key: string, value: string) {
    const sensors = getSensorsDataInstance();
    if (!sensors) return;

    sensors.bind(key, value);
  }

  static unbind(key: string, value: string) {
    const sensors = getSensorsDataInstance();
    if (!sensors) return;

    sensors.unbind(key, value);
  }

  static setProfile(profile: Record<string, SensorsDataAllowedTypes>) {
    const sensors = getSensorsDataInstance();
    if (!sensors) return;

    const formattedUserProperties: Record<string, SensorsDataAllowedTypes> = {};

    Object.keys(profile).forEach(key => {
      formattedUserProperties[camelCaseToSnakeCase(key)] = profile[key];
    });

    sensors.setProfile(formattedUserProperties);
  }

  static logout() {
    const sensors = getSensorsDataInstance();
    if (!sensors) return;

    sensors.logout();
  }

  static registerPage(properties: Record<string, SensorsDataAllowedTypes>) {
    const sensors = getSensorsDataInstance();
    if (!sensors) return;

    sensors.registerPage(properties);
  }
}
