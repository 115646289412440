import dayjs from 'dayjs';
import { filter, flow, map, partialRight, reverse, sortBy } from 'lodash-es';

import { EXPERIENCE_TYPE } from '../constants';

// This function produces the value that should be used when sorting experiences by `endDate`
// Normally we can just compare the given date but if the experience is marked as 'present'
// it should be displayed on above all others, thus the function returns the current day.
// Conversely if `endDate` is not given the function should sort the experience to the end
// of the list and returns the Epoch time in that case
const getEndDateComparee = (experience, now) => {
  if (experience.isPresent) {
    return now;
  }
  if (!experience.endDateFixed) {
    return dayjs.utc(0);
  }
  return dayjs.utc(experience.endDateFixed);
};

// The datetime objects from the API here can have their components smaller than month set arbitrarily
// Therefore, since only the year and the month should matter for the comparison, we fix them here to
// the start or end of the month, respectively
const fixDates = experience => ({
  ...experience,
  startDateFixed: dayjs.utc(experience.startDate).startOf('month'),
  endDateFixed: experience.endDate
    ? dayjs.utc(experience.endDate).endOf('month')
    : null,
});

const removeFixedDates = experience => {
  delete experience.startDateFixed;
  delete experience.endDateFixed;
  return experience;
};

export function sortExperiences(experiences) {
  const now = dayjs().utc();
  return flow(
    partialRight(map, fixDates),
    partialRight(sortBy, 'startDateFixed'),
    partialRight(sortBy, experience => getEndDateComparee(experience, now)),
    partialRight(map, removeFixedDates),
    partialRight(reverse)
  )(experiences);
}

export const getMostRecentWork = experiences =>
  experiences && experiences.length
    ? sortExperiences(filter(experiences, 'title'))[0]
    : null;

export const getMostRecentEducation = experiences =>
  experiences && experiences.length ? sortExperiences(experiences)[0] : null;

export const getWorkExperience = experiences =>
  experiences
    ? experiences.filter(exp => exp.type === EXPERIENCE_TYPE.WORK)
    : [];

export const getEducation = experiences =>
  experiences
    ? experiences.filter(exp => exp.type === EXPERIENCE_TYPE.EDUCATION)
    : [];
