import { uniq } from 'lodash-es';
import PreludeExtension from 'prelude-extension';

export const GROUPS = {
  talentSearchCompany: {
    id: '552fce49-8b19-4934-b01b-fbc7c3ad44eb',
  },
  talentSearchCandidate: {
    id: '552fce49-8b19-4934-b01b-fbc7c3ad44ea',
  },
};

export function applicationStatus(input) {
  const statusTypes = {
    NEW: 'PENDING REVIEW',
    IN_REVIEW: 'SHORTLISTED',
    INTERVIEWING: 'INTERVIEWING',
    OFFERED: 'OFFERING',
    HIRED: 'HIRED',
    REJECTED: 'UNSUITABLE',
  };
  return statusTypes[input];
}

const randomizeString = (string, id) => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const reverseAlphabet = alphabet.split('').reverse().join('');
  const position = reverseAlphabet.indexOf(string.toUpperCase());
  const newPosition = (Math.abs(hash(id)) * position) % reverseAlphabet.length;
  return reverseAlphabet[newPosition];
};

export function userName(input, firstNameOnly = false) {
  if (!input) {
    return '(Unnamed User)';
  }

  if (input.lastName && input.firstName && !firstNameOnly) {
    return [input.firstName.trim(), input.lastName.trim()].join(' ');
  } else if (input.firstName) {
    return input.firstName.trim();
  } else if (input.lastName) {
    return input.lastName.trim().toUpperCase();
  } else {
    return input.email;
  }
}

export function talentName(talent, firstNameOnly = false) {
  if (!talent) {
    return '(Unnamed User)';
  }
  if (talent.lastName && talent.firstName && !firstNameOnly) {
    return [
      randomizeString(talent.firstName.trim().charAt(0), talent.id),
      randomizeString(talent.lastName.trim().charAt(0), talent.id),
    ].join(' ');
  } else if (talent.firstName) {
    return randomizeString(talent.firstName.trim().charAt(0), talent.id);
  } else if (talent.lastName) {
    return randomizeString(talent.lastName.trim().charAt(0), talent.id);
  } else if (talent.email) {
    return randomizeString(talent.email.charAt(0), talent.id);
  } else {
    return randomizeString('A', talent.id);
  }
}

export function recruiterName(recruiter) {
  const name = userName(recruiter, true);
  return name ? name : 'Recruiter';
}

export function jobType(input, reverse) {
  const jobTypes = {
    INTERNSHIP: 'Internship',
    GLOBAL: 'Overseas Internship',
    FULL_TIME: 'Full-Time',
    PART_TIME: 'Part-Time',
    PROJECT_BASED: 'Freelance',
  };

  const jobTypesValues = {
    'Full-Time': 'FULL_TIME',
    'Part-Time': 'PART_TIME',
    Freelance: 'PROJECT_BASED',
    Internship: 'INTERNSHIP',
  };

  return reverse ? jobTypesValues[input] || '' : jobTypes[input] || '';
}

export function capitalizeFirstLetter(input) {
  let strings = null;

  if (input) {
    if (input.indexOf(' ') > 0) {
      strings = input.split(' ');
    } else {
      strings = [input];
    }

    const parsedStrings = strings.map(string => {
      const firstLetter = string.substring(0, 1).toUpperCase();
      const theRest = string.substring(1, string.length).toLowerCase();
      return firstLetter + theRest;
    });

    return parsedStrings.join(' ');
  }
  return input;
}

export function truncateString(input, length, withEllipses = true) {
  if (input) {
    if (withEllipses) {
      return input.length > length
        ? `${truncateString(input, length - 3, false)}...`
        : input;
    } else {
      return input.substring(0, length);
    }
  }
}

// Adds additional label and value fields for use with react-selectize
export function remapValuesForSelectize(
  input,
  searchTerm = '',
  labelPartitionsRequired = true
) {
  if (Array.isArray(input)) {
    return input.map(item => {
      if (item) {
        const temp = {
          ...item,
          label: item.name || '-',
          value: item.id || item.code,
        };
        if (labelPartitionsRequired) {
          temp.labelPartitions = PreludeExtension.partitionString(
            item.name.toLowerCase(),
            searchTerm.toLowerCase()
          );
        }
        return temp;
      }
    });
  } else if (input) {
    const temp = {
      ...input,
      label: input.name,
      value: input.id || input.code,
    };
    if (labelPartitionsRequired) {
      temp.labelPartitions = PreludeExtension.partitionString(
        input.name.toLowerCase(),
        searchTerm.toLowerCase()
      );
    }
    return temp;
  }
}

export function getCountryAttribute(code, type, countries) {
  if (code && countries && countries.length) {
    const country = countries.filter(country => country.code === code);
    if (type === 'DEMONYM') {
      return country[0].demonym;
    } else if (type === 'NAME') {
      return country[0].name;
    }
  }
  return null;
}

export function removeTrailingSlash(value) {
  return value ? value.replace(/\/+$/, '') : null;
}

export function incompleteRequiredFieldsCheck(values) {
  const checkValues = values.map(e => {
    if (Array.isArray(e)) {
      return Boolean(e === undefined || !e);
    } else {
      return Boolean(!e || e === undefined || e === '');
    }
  });
  return Boolean(checkValues.find(v => v));
}

export function parseSocialMediaUrl(value = '') {
  try {
    if (
      (!value.startsWith('http://') || !value.startsWith('https://')) &&
      value.startsWith('www.')
    ) {
      value = `http://${value}`;
    }
    const url = new URL(value);
    return url.pathname.substring(1);
  } catch (err) {
    if (value.startsWith('@')) {
      value = value.substring(1);
    }
    return value;
  }
}

export function constructProperUrl(value) {
  if (value) {
    if (value.startsWith('http://') || value.startsWith('https://')) {
      return value;
    } else {
      return `http://${value}`;
    }
  }
}

export function extractResourceIdsFromTalents(talents) {
  talents = talents.filter(talent => talent);

  const cityIds = [];
  const schoolIds = [];
  const studyIds = [];

  talents.forEach(talent => {
    if (talent.CityId) {
      cityIds.push(talent.CityId);
    }

    let education = [];
    education = talent.Experiences.filter(e => e.type === 'EDUCATION');
    education.forEach(e => {
      if (e.SchoolId) {
        schoolIds.push(e.SchoolId);
      }
      if (e.StudyId) {
        studyIds.push(e.StudyId);
      }
    });
  });

  const dedupedCityIds = uniq(cityIds);
  const dedupedSchoolIds = uniq(schoolIds);
  const dedupedStudyIds = uniq(studyIds);

  return [dedupedCityIds, dedupedSchoolIds, dedupedStudyIds];
}

// https://stackoverflow.com/a/34842797/825981
export function hash(str) {
  if (typeof str !== 'string') {
    return 0;
  }
  return str
    .split('')
    .reduce(
      (prevHash, currVal) =>
        ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
      0
    );
}

export const transformArrayToObject = (array, indexKey = 'id', valueKey) => {
  const object = {};

  array.forEach(
    item => (object[item[indexKey]] = valueKey ? item[valueKey] : item)
  );
  return object;
};

export function isTalentHuntApprovedJob(job) {
  const groups = job?.links?.groups ?? [];
  const found = groups.filter(
    group => group.id === GROUPS.talentSearchCompany.id
  );
  return Boolean(found.length);
}

export const formatNumber = num =>
  num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : num;
