import { handleActions } from 'redux-actions';

import { Actions } from '../actions/features';

const initialState = {
  loading: false,
  showLoadingModal: false,
  error: null,
  value: [],
  isFetched: false,
};

export default handleActions(
  {
    [Actions.FETCH_FEATURES_REQUEST]: state => ({
      ...state,
      loading: true,
      error: null,
      value: [],
      isFetched: false,
    }),
    [Actions.FETCH_FEATURES_REQUEST_WITH_LOADING_MODAL]: state => ({
      ...state,
      loading: true,
      showLoadingModal: true,
      error: null,
      value: [],
      isFetched: false,
    }),
    [Actions.FETCH_FEATURES_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      showLoadingModal: false,
      error: null,
      value: action.response?.entities?.features ?? {},
      isFetched: true,
    }),
    [Actions.FETCH_FEATURES_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      showLoadingModal: false,
      error: action.error,
      value: [],
      isFetched: true,
    }),
  },
  initialState
);
