import { useMemo } from 'react';
import {
  QueryHookOptions,
  skipToken,
  SuspenseQueryHookOptions,
  useQuery,
  useSuspenseQuery,
} from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';

import { Job } from '../../../common/interfaces/entities';
import { UnleashFeatureNames } from '../../../modules/Unleash/featureNames';
import { getIsFeatureEnabled } from '../../../selectors/features';
import useIsCustomPlainSQEnabled from '../useIsCustomPlainSQEnabled';
import useIsMustHaveProfilePictureEnabled from '../useIsMustHaveProfilePictureEnabled';
import { getJobByIdQuery } from './jobQueries';

interface UseJobParams
  extends Omit<QueryHookOptions<JobData, JobVariables>, 'variables'> {
  jobId: string;
}

interface UseJobSuspenseParams
  extends Omit<SuspenseQueryHookOptions<JobData, JobVariables>, 'variables'> {
  jobId: string;
  skip?: boolean;
}

interface JobData {
  getJobById: Job;
}

interface JobVariables {
  jobId: string;
  empBoostJobEnabled: boolean;
  isMustHaveProfilePictureEnabled: boolean;
  isCustomPlainSQEnabled: boolean;
}

const transformJobData = (data?: JobData['getJobById']) =>
  data && {
    ...data,
    CreatorId: data.creatorId,
  };

export const useJobSuspense = ({
  jobId,
  skip,
  ...options
}: UseJobSuspenseParams) => {
  const empBoostJobEnabled = useSelector(state =>
    getIsFeatureEnabled(state, UnleashFeatureNames.empBoostJob)
  );
  const isMustHaveProfilePictureEnabled = useIsMustHaveProfilePictureEnabled();
  const isCustomPlainSQEnabled = useIsCustomPlainSQEnabled();

  const { data, error } = useSuspenseQuery<JobData, JobVariables>(
    getJobByIdQuery,
    jobId && !skip
      ? {
          variables: {
            jobId,
            empBoostJobEnabled,
            isMustHaveProfilePictureEnabled,
            isCustomPlainSQEnabled,
          },
          fetchPolicy: options.fetchPolicy || 'network-only',
          ...options,
        }
      : skipToken
  );

  const transformedJobData: Job | undefined = useMemo(
    () => transformJobData(data?.getJobById),
    [data?.getJobById]
  );

  return {
    job: transformedJobData,
    error,
  };
};

const useJob = ({ jobId, ...options }: UseJobParams) => {
  const empBoostJobEnabled = useSelector(state =>
    getIsFeatureEnabled(state, UnleashFeatureNames.empBoostJob)
  );
  const isMustHaveProfilePictureEnabled = useIsMustHaveProfilePictureEnabled();
  const isCustomPlainSQEnabled = useIsCustomPlainSQEnabled();

  const { data, loading, error } = useQuery<JobData, JobVariables>(
    getJobByIdQuery,
    {
      variables: {
        jobId,
        empBoostJobEnabled,
        isMustHaveProfilePictureEnabled,
        isCustomPlainSQEnabled,
      },
      skip: !jobId || options.skip,
      fetchPolicy: options.fetchPolicy || 'network-only',
      ...options,
    }
  );

  const transformedJobData: Job | undefined = useMemo(
    () => transformJobData(data?.getJobById),
    [data?.getJobById]
  );

  if (error) {
    Sentry.captureException(error);
  }

  return {
    job: transformedJobData,
    loading: loading,
    error,
  };
};

export default useJob;
