import { handleActions } from 'redux-actions';

import { Actions } from '../actions/cities';

const initialState = {
  loading: false,
  error: null,
  value: [],
};

export default handleActions(
  {
    [Actions.CITIES_REQUEST]: state => ({
      ...state,
      loading: true,
      error: null,
      value: state.value.length ? state.value : [],
    }),
    [Actions.CITIES_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      value: action.response.result,
      error: null,
    }),
    [Actions.CITIES_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
      value: [],
    }),
    [Actions.CITIES_CLEAR]: state => ({
      ...state,
      ...initialState,
    }),
  },
  initialState
);
