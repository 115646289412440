export const ApplicationRejectionMessagePreference = {
  UNIFIED_MESSAGE: 'UNIFIED_MESSAGE',
  CUSTOM_MESSAGE: 'CUSTOM_MESSAGE',
  NO_MESSAGE: 'NO_MESSAGE',
} as const;

export type ApplicationRejectionMessagePreference =
  (typeof ApplicationRejectionMessagePreference)[keyof typeof ApplicationRejectionMessagePreference];

export const ApplicationRejectionMessageType = {
  NO_SKILL_SET_FIT: 'NO_SKILL_SET_FIT',
  NO_EXPERIENCE_FIT: 'NO_EXPERIENCE_FIT',
  NO_INDUSTRY_FIT: 'NO_INDUSTRY_FIT',
  NO_EDUCATION_FIT: 'NO_EDUCATION_FIT',
  NO_SALARY_FIT: 'NO_SALARY_FIT',
  POSITION_FILLED: 'POSITION_FILLED',
  NOT_SPECIFIED: 'NOT_SPECIFIED',
  CANDIDATE_NOT_ACTIVE: 'CANDIDATE_NOT_ACTIVE',
  OTHER: 'OTHER',
  UNIFIED_MESSAGE: ApplicationRejectionMessagePreference.UNIFIED_MESSAGE,
} as const;

export const selectableApplicationRejectionMessageTypes = Object.values(
  ApplicationRejectionMessageType
).filter(type => type !== ApplicationRejectionMessageType.UNIFIED_MESSAGE);

export type ApplicationRejectionMessageType =
  (typeof ApplicationRejectionMessageType)[keyof typeof ApplicationRejectionMessageType];

export type ApplicationRejectionMessage = {
  message: string;
  messageType: ApplicationRejectionMessageType;
};

export type RejectionReasonPreferenceFormState = {
  preferredType: ApplicationRejectionMessagePreference;
  rejectionMessages: ApplicationRejectionMessage[];
};
