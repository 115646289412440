import { createAction } from 'redux-actions';

export const Actions = {
  TOGGLE_SIDEBAR: 'glints/navigation/TOGGLE_SIDEBAR',
  CLOSE_SIDEBAR: 'glints/navigation/CLOSE_SIDEBAR',
  UPDATE_NAVBAR_LOGO: 'glints/navigation/UPDATE_NAVBAR_LOGO',
};

export const toggleSidebar = createAction(Actions.TOGGLE_SIDEBAR);
export const closeSidebar = createAction(Actions.CLOSE_SIDEBAR);
export const updateNavbarLogo = createAction(Actions.UPDATE_NAVBAR_LOGO);
