import { handleActions } from 'redux-actions';

import {
  DEREGISTER_COMPONENT,
  REGISTER_COMPONENT,
  UPDATE_COMPONENT_STATE,
} from '../actions/components';

const initialState = {
  registeredComponents: [],
};

export default handleActions(
  {
    [REGISTER_COMPONENT]: (state, action) => ({
      ...state,
      registeredComponents: state.registeredComponents.includes(
        action.payload.name
      )
        ? state.registeredComponents
        : [...state.registeredComponents, action.payload.name],
      [action.payload.name]: action.payload.initState || {},
    }),
    [DEREGISTER_COMPONENT]: (state, action) => {
      const newState = {};
      const newComponents = state.registeredComponents.filter(
        key => key !== action.payload.name
      );

      // preparing new state object
      newComponents.forEach(
        componentStateKey => (newState[newState] = state[componentStateKey])
      );
      newState.registeredComponents = newComponents;

      return newState;
    },
    [UPDATE_COMPONENT_STATE]: (state, action) => ({
      ...state,
      [action.payload.name]: action.payload.replace
        ? action.payload.updates
        : {
            ...state[action.payload.name],
            ...action.payload.updates,
          },
    }),
  },
  initialState
);
