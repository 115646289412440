import { useSelector } from 'react-redux';

import { UnleashFeatureNames } from '../../modules/Unleash/featureNames';
import { getIsFeatureEnabled } from '../../selectors/features';

export default function useIsChatV2Enabled() {
  const isChatV2Enabled = useSelector(state =>
    getIsFeatureEnabled(state, UnleashFeatureNames.allUseChatV2)
  );

  const isGlintsChatDeployed = useSelector(state =>
    getIsFeatureEnabled(state, UnleashFeatureNames.glintsChatDeployed)
  );

  return isChatV2Enabled && isGlintsChatDeployed;
}
