import React from 'react';
import { Loading } from 'glints-aries';
import { FormattedMessage } from 'react-intl';

import baseStyles from '../styles/base.scss';
import styles from './SessionInitializer.scss';

const LoadingFullscreen = () => (
  <div className={styles.spinWrapper}>
    <Loading />
    <p className={baseStyles.textPrimary}>
      <FormattedMessage
        id="components.LoadingFullscreen.loading"
        defaultMessage="Loading&hellip;"
      />
    </p>
  </div>
);

export default LoadingFullscreen;
