import { useEffect, useState } from 'react';

export const useDebounce = <T>(value: T, timeoutMs: number) => {
  const [state, setState] = useState(value);
  const [isArmed, setIsArmed] = useState(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      setState(value);
      setIsArmed(false);
    }, timeoutMs);
    setIsArmed(true);

    return () => clearTimeout(handler);
  }, [value, timeoutMs]);

  return { value: state, isArmed };
};

export default useDebounce;
