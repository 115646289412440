import { pick } from 'lodash-es';

export const getRequestTokenStates = state =>
  pick(state.passwordReset.requestToken, ['loading', 'success', 'error']);
export const getRequestTokenEmail = state =>
  state.passwordReset.requestToken.email;

export const getSetPasswordStates = state =>
  pick(state.passwordReset.setPassword, ['loading', 'success', 'error']);
export const getSetPasswordParams = state =>
  pick(state.passwordReset.setPassword, ['id', 'email', 'token']);
