import React, { useCallback, useState } from 'react';
import { StreamMessage } from 'stream-chat-react';

import {
  MessagingChannelContext,
  MessagingChannelContextValue,
  MessagingUIMode,
} from '../constants';
import { useChannelLatestRegularMessageSentByCurrentUser } from '../hooks';

type MessagingChannelContainerProps = {
  uiMode: MessagingUIMode;
  children: React.ReactNode;
};

const MessagingChannelContainer = ({
  uiMode,
  children,
}: MessagingChannelContainerProps) => {
  const lastRegularMessageSentByCurrentUser =
    useChannelLatestRegularMessageSentByCurrentUser();
  const [showInputPhoneNumber, setShowInputPhoneNumber] = useState(false);
  const [
    isBackgroundUpdateApplicationStatusInProgress,
    setIsBackgroundUpdateApplicationStatusInProgress,
  ] = useState(false);
  const [ephemeralMessages, setEphemeralMessages] = useState<StreamMessage[]>(
    []
  );

  const destroyEphemeralMessage: MessagingChannelContextValue['destroyEphemeralMessage'] =
    useCallback(
      id =>
        setEphemeralMessages(ephemeralMessages =>
          ephemeralMessages.filter(message => message.id !== id)
        ),
      []
    );

  const sendEphemeralMessage: MessagingChannelContextValue['sendEphemeralMessage'] =
    useCallback(
      message => {
        setEphemeralMessages(ephemeralMessages => {
          const messageExist = ephemeralMessages.find(
            message_ => message_.id === message.id
          );
          if (messageExist) return ephemeralMessages;
          return [...ephemeralMessages, message];
        });

        return {
          destroy: () => destroyEphemeralMessage(message.id),
        };
      },
      [destroyEphemeralMessage]
    );

  return (
    <MessagingChannelContext.Provider
      value={{
        uiMode,
        lastRegularMessageIdSentByCurrentUser:
          lastRegularMessageSentByCurrentUser?.id,
        isBackgroundUpdateApplicationStatusInProgress,
        setIsBackgroundUpdateApplicationStatusInProgress,
        showInputPhoneNumber,
        setShowInputPhoneNumber,
        ephemeralMessages,
        sendEphemeralMessage,
        destroyEphemeralMessage,
      }}
    >
      {children}
    </MessagingChannelContext.Provider>
  );
};

export default MessagingChannelContainer;
