import React, { ComponentProps, ReactNode, useState } from 'react';
import { Flex } from 'glints-aries/es';
import {
  ButtonProps,
  Icon,
  IconProps,
  Popover,
  PrimaryButton,
  Typography,
} from 'glints-aries/es/@next';
import { Blue, Green, Neutral } from 'glints-aries/es/@next/utilities/colors';
import {
  space4,
  space12,
  space16,
} from 'glints-aries/es/@next/utilities/spacing';
import { omit } from 'lodash-es';
import { defineMessage, FormattedMessage } from 'react-intl';
import { useCopyToClipboard } from 'react-use';
import styled from 'styled-components';

import { ApplicationStatus, IS_CHAT_ENABLED } from '../../common/constants';
import { assertIsDefined } from '../../common/helpers/assertIsDefined';
import useBulkChangeApplicationStatus from '../../common/hooks/requests/useBulkChangeApplicationStatus';
import { useUnverifiedCompanyRestrictionModal } from '../../common/hooks/useUnverifiedCompanyRestrictionModal/useUnverifiedCompanyRestrictionModal';
import {
  ApplicationShortlistedPages,
  ShortlistMethod,
  trackApplicationsShortlistedEvent,
} from '../../modules/EmployerATSV2/tracking';
import { buildBulkChangeApplicationStatusPayload } from '../Modal/ApplicationStatusTrackingModal/helper';
import { ApplicationDataForBulkUpdate } from './types';

type ContactCardProps = {
  sectionTitle: ReactNode;
  action: ReactNode;
  iconProps: IconProps;
};

export function ContactCard({
  iconProps,
  action,
  sectionTitle,
}: ContactCardProps) {
  return (
    <Flex gap={space12} alignItems="flex-start">
      <Icon {...iconProps} width={24} />
      <Flex flexDirection="column" alignItems="flex-start">
        <Typography variant="caption">{sectionTitle}</Typography>
        {action}
      </Flex>
    </Flex>
  );
}

export function ViewActionButton({ styles }: { styles?: React.CSSProperties }) {
  return (
    <Typography
      variant="subtitle2"
      color={Blue.S99}
      style={{
        cursor: 'pointer',
        ...styles,
      }}
    >
      <FormattedMessage id="interactive-view" defaultMessage="View" />
    </Typography>
  );
}

export function NoneActionButton() {
  return (
    <Typography variant="subtitle2" color={Neutral.B85}>
      <FormattedMessage id="text-none" defaultMessage="None" />
    </Typography>
  );
}

type TextWithCopyActionProps = {
  textToCopy: string;
  textToDisplay: ReactNode;
};

export function TextWithCopyAction({
  textToCopy,
  textToDisplay,
}: TextWithCopyActionProps) {
  const [, copyToClipboard] = useCopyToClipboard();
  const [copied, setCopied] = useState(false);
  const handleCopyClick = () => {
    if (copied) return;
    copyToClipboard(textToCopy);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };
  return (
    <Flex flexDirection="column" gap={space4}>
      <Typography variant="body1" color={Blue.S99}>
        {textToDisplay}
      </Typography>
      <Flex
        gap={space4}
        alignItems="center"
        style={{
          cursor: 'pointer',
        }}
        onClick={handleCopyClick}
      >
        <Choose>
          <When condition={copied}>
            <Icon name="ri-check" fill={Green.B61} height={20} width={24} />
            <Typography variant="subtitle2" color={Green.B61}>
              <FormattedMessage
                id="button-text_copied"
                defaultMessage="Copied"
              />
            </Typography>
          </When>
          <Otherwise>
            <Icon name="ri-file-copy-fill" width={20} fill={Neutral.B40} />
            <Typography variant="subtitle2" color={Neutral.B40}>
              <FormattedMessage id="button-text_copy" defaultMessage="Copy" />
            </Typography>
          </Otherwise>
        </Choose>
      </Flex>
    </Flex>
  );
}

type CustomPopoverProps = {
  activator: ReactNode;
  title: ReactNode;
  description: ReactNode;
  hideActionButton?: boolean;
  actionButtonProps: {
    text: ReactNode;
  } & ButtonProps;
  preferredAlignment?: ComponentProps<typeof Popover>['preferredAlignment'];
};

type MoveToInCommunicationPopoverProps = Omit<
  CustomPopoverProps,
  'actionButtonProps'
> & {
  applicationDetails: ApplicationDataForBulkUpdate;
};

const PopoverContentContainer = styled.div`
  display: flex;
  padding: ${space16};
  flex-direction: column;
  color: ${Neutral.B100};
  background-color: ${Neutral.B18};
  gap: ${space16};
`;

export const CustomPopover = ({
  activator,
  description,
  title,
  actionButtonProps,
  hideActionButton,
  preferredAlignment = 'left',
}: CustomPopoverProps) => {
  const [active, setActive] = useState(false);
  const [openPopover, closePopover] = [
    () => setActive(true),
    () => setActive(false),
  ];
  return (
    <div onMouseLeave={closePopover}>
      <Popover
        active={active}
        onClose={closePopover}
        preferredAlignment={preferredAlignment}
        activator={<div onMouseEnter={openPopover}>{activator}</div>}
      >
        <Popover.Pane>
          <PopoverContentContainer onClick={e => e.stopPropagation()}>
            <Flex
              flexDirection="column"
              style={{
                gap: space4,
                minWidth: 200,
              }}
            >
              <Typography variant="caption">{title}</Typography>
              <Typography variant="subtitle2">{description}</Typography>
            </Flex>
            <If condition={!hideActionButton}>
              <PrimaryButton
                {...omit(actionButtonProps, 'text')}
                fullWidth={true}
              >
                <Typography variant="button">
                  {actionButtonProps.text}
                </Typography>
              </PrimaryButton>
            </If>
          </PopoverContentContainer>
        </Popover.Pane>
      </Popover>
    </div>
  );
};

export function useBuildMoveToInCommunicationActionButtonProps({
  applicationDetails,
}: Pick<MoveToInCommunicationPopoverProps, 'applicationDetails'>): Pick<
  ComponentProps<typeof CustomPopover>['actionButtonProps'],
  'text' | 'onClick' | 'loading'
> {
  const [isChangingApplicationState, setIsChangingApplicationState] =
    useState(false);
  const { getOpenRestrictionModalFunc } = useUnverifiedCompanyRestrictionModal(
    restrictUnverifiedCompanyMessage
  );

  assertIsDefined(applicationDetails.JobId);

  const bulkChangeApplicationStatus = useBulkChangeApplicationStatus({
    jobId: applicationDetails.JobId,
  });

  const openRestrictionModalFunc = getOpenRestrictionModalFunc();

  const handleMoveToInCommunication = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (openRestrictionModalFunc) {
      openRestrictionModalFunc();
      return;
    }
    setIsChangingApplicationState(true);

    trackApplicationsShortlistedEvent({
      page: ApplicationShortlistedPages.MANAGE_CANDIDATES,
      shortlistMethod: ShortlistMethod.SINGLE_SHORTLIST,
      isMultipleApplications: false,
      waPresent: applicationDetails.whatsAppDetails.isAvailable,
    });

    await bulkChangeApplicationStatus(
      buildBulkChangeApplicationStatusPayload(
        [applicationDetails],
        ApplicationStatus.IN_REVIEW
      )
    );
    setIsChangingApplicationState(false);
  };

  return {
    text: (
      <Choose>
        <When condition={IS_CHAT_ENABLED}>
          <FormattedMessage
            id="interactive-move-to-in-communication"
            defaultMessage="Move to “In Communication”"
          />
        </When>
        <Otherwise>
          <FormattedMessage id="interactive-process" defaultMessage="Process" />
        </Otherwise>
      </Choose>
    ),
    onClick: handleMoveToInCommunication,
    loading: isChangingApplicationState,
  };
}

function MoveToInCommunicationPopoverComponent({
  applicationDetails,
  ...rest
}: MoveToInCommunicationPopoverProps) {
  const actionButtonProps = useBuildMoveToInCommunicationActionButtonProps({
    applicationDetails,
    ...rest,
  });
  return <CustomPopover {...rest} actionButtonProps={actionButtonProps} />;
}

function GenericMoveToInCommunication(
  props: Pick<MoveToInCommunicationPopoverProps, 'applicationDetails'>
) {
  return (
    <MoveToInCommunicationPopoverComponent
      {...props}
      title={
        <FormattedMessage
          id="text-process-to-view"
          defaultMessage="Process to view"
        />
      }
      description={
        <Choose>
          <When condition={IS_CHAT_ENABLED}>
            <FormattedMessage
              id="text-info-available-in-processed"
              defaultMessage="Move this candidate or Chat to view this info"
            />
          </When>
          <Otherwise>
            <FormattedMessage
              id="process-move-this-candidate-to"
              defaultMessage="Process this candidate to view"
            />
          </Otherwise>
        </Choose>
      }
      activator={<ViewActionButton />}
    />
  );
}

function RejectedMoveToIncommunication({
  title,
  description,
  ...props
}: Pick<
  MoveToInCommunicationPopoverProps,
  'applicationDetails' | 'preferredAlignment' | 'title' | 'description'
> & {
  activator?: ReactNode;
}) {
  return (
    <MoveToInCommunicationPopoverComponent
      {...props}
      title={title}
      description={description}
      activator={props.activator || <ViewActionButton />}
    />
  );
}

export const MoveToInCommunicationPopover = Object.assign(
  MoveToInCommunicationPopoverComponent,
  {
    Generic: GenericMoveToInCommunication,
    RejectedMoveToIncommunication,
  }
);

const restrictUnverifiedCompanyMessage = defineMessage({
  id: 'text-company-needs-verifications-candidate',
  defaultMessage:
    'Your company needs to be verified before you can view the resume and profile details of the candidate. After verification, you can also chat with the candidate directly.',
});
