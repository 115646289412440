import React from 'react';
import { Greyscale, PrimaryColor, ScreenSize } from 'glints-aries';
import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  display: flex;
  justify-content: center;
  background-image: url('/images/footer.webp');
  width: 100%;
`;

export const Footer = styled.div`
  color: white;
  display: grid;
  padding: 16px 16px;
  text-align: left;
  grid-template-areas: 'logo' 'description' 'links' 'social' 'license';
  grid-template-columns: 1fr;

  @media (min-width: 640px) {
    grid-template-areas: 'logo description' 'links links' 'social social' 'license license';
    grid-template-columns: 112px 1fr;
    margin: 0 32px;
    padding: 60px 48px 40px 32px;
  }
  @media (min-width: 992px) {
    grid-template-areas: 'logo description links' 'logo social links' 'license license links';
    grid-template-columns: 142px 0.8fr 1fr;
  }
`;

export const DescriptionSection = styled.div`
  grid-area: description;
  margin-right: 0;
  font-family:
    Noto Sans,
    sans-serif;
  @media (min-width: 640px) {
    margin-bottom: 60px;
  }
  @media (min-width: 992px) {
    margin-bottom: 0;
    margin-right: 48px;
  }
`;

export const Logo = styled.img`
  grid-area: logo;
  width: 80px;
  display: flex;
  justify-self: center;
  padding: 8px;
  @media (min-width: 640px) {
    justify-self: unset;
  }
  @media (min-width: 992px) {
    width: 126px;
    margin: 0 16px 0 0;
  }
`;

export const Description = styled.div`
  line-height: 140%;
  margin-bottom: 32px;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.2px;
  @media (min-width: 640px) {
    margin-bottom: 0;
    justify-self: unset;
  }
  @media (min-width: 992px) {
    line-height: 150%;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1.5px;
  }
`;

export const LicenseWrapper = styled.div`
  grid-area: license;
  margin: 8px auto 0 auto;
  text-align: center;

  @media (min-width: 992px) {
    text-align: left;
    margin: 48px auto 0 0;
  }
`;

export const ConnectedSection = styled.div`
  grid-area: social;
  min-width: 200px;
  margin: 0 auto;

  @media (min-width: 992px) {
    margin: 0 0;
  }
`;

export const Title = styled.p`
  opacity: 0.5;
  margin-bottom: 8px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.25px;
  font-family:
    Noto Sans,
    sans-serif;
  @media (min-width: 992px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.25px;
  }
`;

export const SocialMediaWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  svg {
    color: white;
    margin: 0 15px;
    font-size: 24px;

    @media (min-width: ${ScreenSize.tablet}px) {
      margin: 0 24px 0 0;
    }
  }

  svg:hover,
  .link:hover {
    color: ${PrimaryColor.glintsblue};
  }
`;

export const LinksSectionWrapper = styled.div`
  grid-area: links;
  display: flex;
  flex-direction: column;

  @media (min-width: 640px) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 60px;
  }
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`;

export const LinksSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CompanyLinks = styled.div`
  display: grid;
  grid-template-areas: 'about inside' 'tech careers' 'terms blog' 'report report';
  grid-template-columns: 1fr 1fr;
  margin-bottom: 32px;
  @media (min-width: 640px) {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 0;
  }
`;

export const SolutionsLinks = styled.div`
  display: grid;
  grid-template-areas: 'recruitment talent' 'job job';
  grid-template-columns: 1fr 1fr;
  margin-bottom: 32px;
  @media (min-width: 640px) {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 0;
  }
`;

export const ResourcesLinks = styled.div`
  display: grid;
  grid-template-areas: 'blog fee' 'startup hiring' 'talent events' 'ebooks faq';
  grid-template-columns: 1fr 1fr;
  margin-bottom: 32px;
  @media (min-width: 640px) {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 0;
  }
`;

type LinkProps = React.HTMLProps<HTMLAnchorElement> & {
  isExternal?: boolean;
  openInNewTab?: boolean;
  children: React.ReactNode;
};

export const Link = styled.a.attrs<LinkProps>(props => ({
  target: props.isExternal || props.openInNewTab ? '_blank' : undefined,
  rel:
    props.isExternal || props.openInNewTab ? 'noopener noreferrer' : undefined,
}))<LinkProps>`
  cursor: pointer;
  margin-bottom: 8px;
  font-size: 14px;
  color: white;
`;

export const Caption = styled.p`
  font-family:
    Noto Sans,
    sans-serif;
  font-size: 10px;
  line-height: 140%;
  font-weight: 500;
  letter-spacing: 0.2px;
  @media (min-width: 992px) {
    font-size: 12px;
    letter-spacing: 1.5px;
  }
`;

export const DisclaimerPart = styled.p`
  font-size: 14px;
  line-height: 1.2;
  margin-top: 8px;
`;

export const RegionalHeading = styled.h4`
  color: ${Greyscale.white};
  font-size: 12px;
  font-weight: 500;
  font-family:
    Noto Sans,
    sans-serif;
  line-height: 140%;
  letter-spacing: 0.2px;
  text-align: center;
  @media (min-width: 640px) {
    text-align: unset;
  }
  @media (min-width: 992px) {
    line-height: 150%;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1.5px;
  }
`;

export const RegionalSection = styled.nav`
  margin-top: 4px;
  margin-bottom: 10px;
  text-align: center;
  @media (min-width: 640px) {
    text-align: unset;
  }
  a {
    align-items: center;
    justify-content: flex-start;
    width: 32px;
    height: 32px;
  }
  img {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    object-fit: cover;
    margin-right: 16px;
  }
`;
