import React from 'react';
import { FormattedMessage } from 'react-intl';

import { EDUCATION_LEVEL_MESSAGES } from '../../../common/constants';
import { ExperienceRange } from '../../../components/ExperienceRange';
import NodesJoin from '../../../components/NodesJoin';
import { ApplicationData } from '../graphql';
import MiddleDot from './MiddleDot';

type Props = {
  education: ApplicationData['applicant']['experiences'][number];
};

const ApplicantLatestEducation = ({ education }: Props) => {
  const { study, educationLevel } = education;

  return (
    <>
      <NodesJoin separator={<MiddleDot />}>
        {educationLevel && (
          <FormattedMessage {...EDUCATION_LEVEL_MESSAGES[educationLevel]} />
        )}
        {study}
      </NodesJoin>
      &nbsp;
      <ExperienceRange experience={education} isInline={true} />
    </>
  );
};

export default ApplicantLatestEducation;
