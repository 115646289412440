import React, { useState } from 'react';
import {
  CallingCodeOption,
  PhoneNumber,
  PhoneNumberInput as BasePhoneNumberInput,
} from 'glints-aries';
import { flatMap, identity } from 'lodash-es';
import { defineMessages, useIntl } from 'react-intl';
import useSWR from 'swr';

import useDebounce from '../../common/hooks/useDebounce';
import { Country } from '../../models/Country';

const featuredCountryCodes = ['MY', 'ID', 'SG', 'TW', 'VN'];

export const PhoneNumberInput = ({
  onChange,
  value,
  className,
  error,
  onFocus,
  onBlur,
  addon,
}: Props) => {
  const { formatMessage } = useIntl();

  const [filterInput, setFilterInput] = useState<string>('');

  const { callingCodeOptions, countryCodeOptionsAreLoading } =
    useCountryCallingCodeOptions(filterInput.trim().toLowerCase());

  const options = addFallbackOptionIfGivenCallingCodeIsntKnown(
    value,
    callingCodeOptions,
    formatMessage(messages.currentCountry)
  );

  return (
    <BasePhoneNumberInput
      addon={addon}
      value={value}
      callingCodeOptions={options}
      filterValue={filterInput}
      isLoadingCallingCodeOptions={countryCodeOptionsAreLoading}
      label={formatMessage(messages.label)}
      featuredOptionsLabel={formatMessage(messages.featuredOptionsLabel)}
      otherOptionsLabel={formatMessage(messages.otherOptionsLabel)}
      callingCodeFilterInputPlaceholder={formatMessage(
        messages.callingCodeFilterInputPlaceholder
      )}
      callingCodeNoOptionsLabel={formatMessage(
        messages.callingCodeNoOptionsLabel,
        { searchTerm: filterInput }
      )}
      error={error}
      onChange={value =>
        onChange({
          callingCode: value.callingCode,
          significantNumber:
            value.significantNumber?.replace(/[^0-9]/g, '') ?? '',
        })
      }
      onInputChange={filterInput => setFilterInput(filterInput || '')}
      onFocus={onFocus}
      onBlur={onBlur}
      {...{ className }}
    />
  );
};

interface Props {
  className: string;
  onChange: (value: PhoneNumber) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  value: PhoneNumber;
  error?: string;
  addon?: React.ReactElement;
}

const addFallbackOptionIfGivenCallingCodeIsntKnown = (
  value: PhoneNumber,
  callingCodeOptions: CallingCodeOption[],
  fallbackOptionLabel: string
) => {
  const isCurrentCountryCodeAnOption = callingCodeOptions.find(
    callingCodeOption => value.callingCode === callingCodeOption.callingCode
  );
  const unknownCallingCodeFallback = {
    callingCode: value.callingCode,
    label: offlineCallingCodeLabels[value.callingCode] || fallbackOptionLabel,
    isFeatured: true,
  };

  return [
    ...callingCodeOptions,
    ...(isCurrentCountryCodeAnOption ? [] : [unknownCallingCodeFallback]),
  ];
};

export const PHONE_NUMBER_SEPARATOR = ' ';

export const stringifyPhoneNumber = ({
  callingCode,
  significantNumber,
}: PhoneNumber) =>
  ['+', callingCode, PHONE_NUMBER_SEPARATOR, significantNumber].join('');

const getKey = (filterInput = '') => {
  const params = new URLSearchParams({
    where: JSON.stringify(
      filterInput
        ? {
            name: { ilike: `%${filterInput}%` },
          }
        : { code: { in: featuredCountryCodes } }
    ),
  });
  return `/countries?${params.toString()}`;
};

const useCountryCallingCodeOptions = (filterInput: string) => {
  const filterInputDebounced = useDebounce(filterInput, 200);
  const { data, isLoading, isValidating } = useSWR<{ data: Country[] }>(
    getKey(filterInputDebounced)
  );
  const countryCodeOptionsAreLoading = isLoading || isValidating;
  const countries = data?.data;
  const callingCodes = countries
    ? flatMap(
        countries,
        country =>
          country.callingCode?.map(callingCode => ({
            countryName: country.name,
            countryCode: country.code,
            code: callingCode.replace(/\+/g, ''),
          }))
      ).filter(identity)
    : [];
  const callingCodeOptions: CallingCodeOption[] = callingCodes.map(
    callingCode => ({
      label: callingCode.countryName,
      callingCode: Number(callingCode.code),
      isFeatured: featuredCountryCodes.includes(callingCode.countryCode),
    })
  );

  return { callingCodeOptions, countryCodeOptionsAreLoading } as const;
};

const messages = defineMessages({
  label: { id: 'phoneNumberInput.label', defaultMessage: 'Mobile Number' },
  featuredOptionsLabel: {
    id: 'phoneNumberInput.featuredOptionsLabel',
    defaultMessage: 'Frequently Used',
  },
  otherOptionsLabel: {
    id: 'phoneNumberInput.otherOptionsLabel',
    defaultMessage: 'The Rest Of The World',
  },
  callingCodeFilterInputPlaceholder: {
    id: 'phoneNumberInput.callingCodeFilterInputPlaceholder',
    defaultMessage: 'Type country code or country name',
  },
  callingCodeNoOptionsLabel: {
    id: 'phoneNumberInput.callingCodeNoOptionsLabel',
    defaultMessage:
      'Sorry, there are no results for country {searchTerm}. Please try again.',
  },
  currentCountry: {
    id: 'phoneNumberInput.currentCountry',
    defaultMessage: 'Current Country',
  },
});

export const offlineCallingCodeLabels: Record<number, string> = {
  60: 'Malaysia',
  62: 'Indonesia',
  63: 'Philippines',
  65: 'Singapore',
  66: 'Thailand',
  84: 'Vietnam',
  852: 'Hong Kong',
  886: 'Taiwan',
};
