import CALL_API from '../middleware/api_key';
import { Schemas } from '../middleware/schemas';

export const Actions = {
  JOB_TITLES_REQUEST: 'glints/jobTitles/JOB_TITLES_REQUEST',
  JOB_TITLES_SUCCESS: 'glints/jobTitles/JOB_TITLES_SUCCESS',
  JOB_TITLES_FAILURE: 'glints/jobTitles/JOB_TITLES_FAILURE',
};

export function fetchJobTitles(options) {
  return async dispatch => {
    await dispatch({
      [CALL_API]: {
        types: [
          Actions.JOB_TITLES_REQUEST,
          Actions.JOB_TITLES_SUCCESS,
          Actions.JOB_TITLES_FAILURE,
        ],
        endpoint: 'jobTitles',
        schema: Schemas.JOB_TITLE_ARRAY,
        options: {
          ...options,
          params: {
            limit: 0,
            order: 'id ASC',
            ...(options ? options.params : {}),
          },
        },
      },
    });
  };
}
