import { init as initApm } from '@elastic/apm-rum';

import config from '../config';

function initializeApm() {
  const proxyUrl = location.origin;
  initApm({
    environment: config.ENVIRONMENT,
    serviceName: config.APM_SERVICE_NAME,
    serverUrl: proxyUrl,
    serverUrlPrefix: '/es-apm',
    serviceVersion: process.env.BUILD_ID || 'development',
  });
}

initializeApm();
