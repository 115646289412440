import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getSessionToken } from '../../selectors/session';
import makeAxios from '../axios-ts';

export const useAxiosWithAPI = () => {
  const token = useSelector(getSessionToken);
  const axios = useMemo(() => makeAxios(token), [token]);
  return axios;
};
