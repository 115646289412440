import { borderRadius8 } from 'glints-aries/es/@next/utilities/borderRadius';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import {
  space4,
  space8,
  space12,
  space16,
} from 'glints-aries/es/@next/utilities/spacing';
import styled, { createGlobalStyle } from 'styled-components';

export const CustomPopoverStyles = createGlobalStyle`
  .Polaris-Popover__Content {
    height: max-content !important;
  }
`;
export const ContentContainer = styled.div`
  background: linear-gradient(276.22deg, #ffedb1 3.98%, #fff8e0 99.99%);
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space4};
  padding: 29px ${space16};
  padding-bottom: ${space8};
  background-color: ${Neutral.B100};
`;

export const StyledVIPBadge = styled.div`
  background: linear-gradient(90deg, #ffedb1 0%, #fff8e0 100%);
  padding: ${space4} ${space12};
  display: flex;
  align-items: center;
  gap: ${space4};
  width: fit-content;
  border-bottom-right-radius: ${borderRadius8};
  position: absolute;
`;

export const StyledImage = styled.img`
  border-radius: ${borderRadius8};
  max-width: 400px;
  margin: ${space12} 16px;
  object-fit: contain;
  box-shadow: 0px 0px 5px 0px #4747471a;
`;
