import React from 'react';
import { Box } from 'glints-aries';

const MiddleDot = () => (
  <Box px={2} style={{ display: 'inline-block' }}>
    &#x30FB;
  </Box>
);

export default MiddleDot;
