import { createContext } from 'react';
import { ApolloError } from '@apollo/client';

import { CreditProductPurchaseOrderForAfterPayment } from '../../types';

export type OpenModalOptions = {
  callback?: () => void;
  errorBannerProviderInitialValue?: ApolloError;
  onClose?: (
    creditProductPurchaseOrder?: CreditProductPurchaseOrderForAfterPayment
  ) => void;
};

type GlintsVIPPurchaseModalContext = {
  openGlintsVIPPurchaseModal: (options?: OpenModalOptions) => void;
  isModalChunkLoadPending: boolean;
};

export const GlintsVIPPurchaseModalContext = createContext<
  GlintsVIPPurchaseModalContext | undefined
>(undefined);
