import React, { ReactNode, Suspense } from 'react';

import { useGetCompanyFreeTrialOffer } from '../../common/hooks/useGetCompanyFreeTrialOffer';
import { EmployerPurchaseProductType } from '../../common/interfaces/entities';
import { CompanyProductFreeTrialOffer } from '../../common/interfaces/product-free-trial-offer';
import ErrorBoundaryWithCrashReporting from '../Error/ErrorBoundaryWithCrashReporting';
import VIPFreeTrialCTAText from './components/VIPFreeTrialCTAText';

type Props = {
  children: ReactNode;
  renderFreeTrialCTA?: (vipOffer: CompanyProductFreeTrialOffer) => ReactNode;
};

function RenderVIPCTALabelBasedOnVIPFreeTrialContent({
  children,
  renderFreeTrialCTA,
}: Props) {
  const vipOffer = useGetCompanyFreeTrialOffer({
    productType: EmployerPurchaseProductType.VIP_MEMBERSHIP,
  });

  if (!vipOffer) {
    return <>{children}</>;
  }
  const content = renderFreeTrialCTA?.(vipOffer) ?? (
    <VIPFreeTrialCTAText vipOffer={vipOffer} />
  );
  return <>{content}</>;
}

export default function RenderVIPCTALabelBasedOnVIPFreeTrial({
  children,
  renderFreeTrialCTA,
}: Props) {
  return (
    <ErrorBoundaryWithCrashReporting fallback={null}>
      <Suspense fallback={null}>
        <RenderVIPCTALabelBasedOnVIPFreeTrialContent
          renderFreeTrialCTA={renderFreeTrialCTA}
        >
          {children}
        </RenderVIPCTALabelBasedOnVIPFreeTrialContent>
      </Suspense>
    </ErrorBoundaryWithCrashReporting>
  );
}
