import { USER_ROLES } from '../../common/constants';
import { ACTIVITIES } from './Activities';

export const DEFAULT_PERMISSIONS = {
  ACCESS_COMPANY_DASHBOARD: {
    [USER_ROLES.SUPER_ADMIN]: true,
    [USER_ROLES.COMPANY]: true,
    [USER_ROLES.EMPLOYER_SERVICE_REP]: true,
    [USER_ROLES.RECRUITER]: true,
  },
  ACCESS_COMPANY_ADMIN_SECTION: {
    [USER_ROLES.SUPER_ADMIN]: true,
    [USER_ROLES.COMPANY]: true,
    [USER_ROLES.RECRUITER]: true,
    [USER_ROLES.EMPLOYER_SERVICE_REP]: true,
  },
  ACCESS_COMPANY_MENU_ITEMS: {
    [USER_ROLES.SUPER_ADMIN]: true,
    [USER_ROLES.COMPANY]: true,
    [USER_ROLES.RECRUITER]: true,
    [USER_ROLES.EMPLOYER_SERVICE_REP]: true,
  },
  ACCESS_TALENT_SEARCH: {
    [USER_ROLES.RECRUITER]: true,
    [USER_ROLES.SUPER_ADMIN]: true,
    [USER_ROLES.EMPLOYER_SERVICE_REP]: true,
  },
  EDIT_COMPANY: {
    [USER_ROLES.SUPER_ADMIN]: true,
    [USER_ROLES.COMPANY]: true,
    [USER_ROLES.GROUP_ADMIN]: true,
    [USER_ROLES.RECRUITER]: true,
    [USER_ROLES.EMPLOYER_SERVICE_REP]: true,
  },
  MANAGE_GROUP_MEMBERS: {
    [USER_ROLES.SUPER_ADMIN]: true,
    [USER_ROLES.GROUP_ADMIN]: true,
    [USER_ROLES.RECRUITER]: true,
    [USER_ROLES.EMPLOYER_SERVICE_REP]: true,
  },
  ACCESS_CURATION_STATUS_FILTER: {
    [USER_ROLES.SUPER_ADMIN]: true,
    [USER_ROLES.RECRUITER]: true,
    [USER_ROLES.EMPLOYER_SERVICE_REP]: true,
  },
  EDIT_TALENT: {
    [USER_ROLES.SUPER_ADMIN]: true,
    [USER_ROLES.RECRUITER]: true,
    [USER_ROLES.EMPLOYER_SERVICE_REP]: true,
  },
  ACCESS_JOB_CHANNELS: {
    [USER_ROLES.SUPER_ADMIN]: true,
  },
  ACCESS_NON_GROUP_JOBS: {
    [USER_ROLES.SUPER_ADMIN]: true,
    [USER_ROLES.RECRUITER]: true,
    [USER_ROLES.EMPLOYER_SERVICE_REP]: true,
  },
  ACCESS_APPLY_REDIRECT_JOB_URL: {
    [USER_ROLES.SUPER_ADMIN]: true,
  },
  ACCESS_RECOMMENDATIONS: {
    [USER_ROLES.SUPER_ADMIN]: true,
    [USER_ROLES.RECRUITER]: true,
    [USER_ROLES.EMPLOYER_SERVICE_REP]: true,
  },
  ACCESS_RECRUITER_ATS: {
    [USER_ROLES.SUPER_ADMIN]: true,
    [USER_ROLES.RECRUITER]: true,
    [USER_ROLES.EMPLOYER_SERVICE_REP]: true,
  },
  ACCESS_ACTIONS_DROPDOWN: {
    [USER_ROLES.SUPER_ADMIN]: true,
    [USER_ROLES.RECRUITER]: true,
    [USER_ROLES.EMPLOYER_SERVICE_REP]: true,
  },
  // has access to the modules/FindTalent
  [ACTIVITIES.ACCESS_FIND_TALENT]: {
    [USER_ROLES.SUPER_ADMIN]: true,
    [USER_ROLES.RECRUITER]: true,
    [USER_ROLES.EMPLOYER_SERVICE_REP]: true,
  },
};

export const CUSTOM_PERMISSIONS = {
  talenthunt: {
    ACCESS_TALENT_SEARCH: {
      [USER_ROLES.COMPANY]: true,
    },
    ACCESS_RECOMMENDATIONS: {
      [USER_ROLES.COMPANY]: true,
    },
  },
};
