/**
 * This function is like Array.prototype.join but it returns an array with the
 * seperator inserted in between the elements of the input array.
 * e.g. joinToArray([1, 2, 3], 0) returns [1, 0, 2, 0, 3]
 */
export const joinToArray = (array, separator) =>
  array.reduce(
    (joined, currentElement, index) =>
      index === array.length - 1
        ? [...joined, currentElement]
        : [...joined, currentElement, separator],
    []
  );

export default joinToArray;
