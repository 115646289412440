import { handleActions } from 'redux-actions';

import { Actions } from './Actions';

const initialState = {
  sidebarOpen: false,
  logo: {
    style: 'navbar-logo',
  },
};

export default handleActions(
  {
    [Actions.TOGGLE_SIDEBAR]: state => ({
      ...state,
      sidebarOpen: !state.sidebarOpen,
    }),
    [Actions.CLOSE_SIDEBAR]: state => ({
      ...state,
      sidebarOpen: false,
    }),
    [Actions.UPDATE_NAVBAR_LOGO]: (state, action) => ({
      ...state,
      logo: action.payload,
    }),
  },
  initialState
);
