import React, { ComponentType, ReactNode } from 'react';
import { Icon, Typography } from 'glints-aries/es/@next';
import { Neutral, Orange } from 'glints-aries/es/@next/utilities/colors';
import { space2 } from 'glints-aries/es/@next/utilities/spacing';
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
} from 'react-intl';

import { GlintsLogoRed } from '../../../common/color';
import {
  NavbarBannerStyle,
  StyledBanner,
  TopCutoutBannerStyle,
} from './VIPLimitedTImeOfferBanner.sc';

type LimitedTimeOfferVariants =
  | 'topCutOut'
  | 'banner'
  | 'navbarBanner'
  | 'plainText';

type VIPPurchaseType = 'extend' | 'upgrade';

type VIPLimitedTimeOfferBannerProps = {
  variant: LimitedTimeOfferVariants;
  purchaseType: 'extend' | 'upgrade';
  freeCreditsCount: number;
};

type ComponentVariantProps = Pick<
  VIPLimitedTimeOfferBannerProps,
  'freeCreditsCount' | 'purchaseType'
>;

const messages: Record<VIPPurchaseType, MessageDescriptor> = defineMessages({
  extend: {
    id: 'text-limited-time-offer-extend',
    defaultMessage:
      '<b>Limited-Time Offer!</b> Extend VIP membership and get {count} free credits monthly!',
  },
  upgrade: {
    id: 'text-limited-time-offer-upgrade',
    defaultMessage:
      '<b>Limited-Time Offer!</b> Upgrade to VIP and get {count} free credits monthly!',
  },
});

const componentsBasedOnVariants: Record<
  LimitedTimeOfferVariants,
  ComponentType<ComponentVariantProps>
> = {
  banner: Banner,
  navbarBanner: NavbarBanner,
  topCutOut: TopCutoutBanner,
  plainText: PlainTextVariant,
};

export default function VIPLimitedTimeOfferBanner({
  variant,
  ...rest
}: VIPLimitedTimeOfferBannerProps) {
  const Component = componentsBasedOnVariants[variant];
  return <Component {...rest} />;
}

const Bold = (...chunks: ReactNode[]) => (
  <Typography variant="body2" as="span">
    {chunks}
  </Typography>
);

const Caption = (...chunks: ReactNode[]) => (
  <Typography variant="caption" as="span">
    {chunks}
  </Typography>
);

function Banner({ freeCreditsCount, purchaseType }: ComponentVariantProps) {
  return (
    <StyledBanner>
      <Icon
        name="ri-glints-logo-fill"
        fill={GlintsLogoRed}
        width={24}
        style={{
          flexShrink: 0,
          position: 'relative',
          top: space2,
        }}
      />

      <Typography variant="body1" color={Neutral.B18}>
        <FormattedMessage
          {...messages[purchaseType]}
          values={{
            count: freeCreditsCount,
            b: Bold,
          }}
        />
      </Typography>
    </StyledBanner>
  );
}

function NavbarBanner(props: ComponentVariantProps) {
  return (
    <NavbarBannerStyle>
      <Typography variant="subtitle2" color={Neutral.B18}>
        <FormattedMessage
          {...messages[props.purchaseType]}
          values={{
            count: props.freeCreditsCount,
            b: Caption,
          }}
        />
      </Typography>
    </NavbarBannerStyle>
  );
}

function TopCutoutBanner(props: Omit<ComponentVariantProps, 'showIcon'>) {
  return (
    <TopCutoutBannerStyle>
      <Typography variant="subtitle2" color={Neutral.B18}>
        <FormattedMessage
          {...messages[props.purchaseType]}
          values={{
            count: props.freeCreditsCount,
            b: Caption,
          }}
        />
      </Typography>
    </TopCutoutBannerStyle>
  );
}

function PlainTextVariant(props: Omit<ComponentVariantProps, 'showIcon'>) {
  return (
    <Typography variant="subtitle2" color={Orange.S87}>
      <FormattedMessage
        {...messages[props.purchaseType]}
        values={{
          count: props.freeCreditsCount,
          b: Caption,
        }}
      />
    </Typography>
  );
}
