import { carouselConfig } from '@builder.io/widgets/dist/lib/components/Carousel.config';

const carouselConfigExtended = { ...carouselConfig, name: 'CarouselExtended' };

const responsive = carouselConfig.inputs.find(
  (input: any) => input.name === 'responsive'
);

/*
 * helper text is incorrect, builder carousel uses react-slick under the hood,
 * new properties can be added via responsive.subFields, they are copied as-is in
 * node_modules/@builder.io/widgets/dist/lib/components/Carousel.js
 */
responsive.helperText =
  'Responsive settings - e.g. see https://react-slick.neostack.com/docs/get-started';

responsive.subFields = [
  {
    name: 'breakpoint',
    type: 'number',
    defaultValue: 400,
    required: true,
  },
  {
    name: 'settings',
    type: 'object',
    defaultValue: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      dots: false,
      variableWidth: true,
      draggable: true,
      pauseOnHover: false,
      autoplay: true,
      autoplaySpeed: 0,
      speed: 6000,
      cssEase: 'linear',
      fade: false,
      centerMode: false,
    },
    subFields: [
      {
        name: 'slidesToShow',
        type: 'number',
        defaultValue: 2,
      },
      {
        name: 'slidesToScroll',
        type: 'number',
        defaultValue: 2,
      },
      {
        name: 'infinite',
        type: 'boolean',
        defaultValue: true,
      },
      {
        name: 'dots',
        type: 'boolean',
        defaultValue: false,
      },
      {
        name: 'variableWidth',
        type: 'boolean',
        defaultValue: true,
      },
      {
        name: 'draggable',
        type: 'boolean',
        defaultValue: true,
      },
      {
        name: 'pauseOnHover',
        type: 'boolean',
        defaultValue: false,
      },
      {
        name: 'autoplay',
        type: 'boolean',
        defaultValue: true,
      },
      {
        name: 'autoplaySpeed',
        type: 'number',
        defaultValue: 0,
      },
      {
        name: 'speed',
        type: 'number',
        defaultValue: 6000,
      },
      {
        name: 'cssEase',
        type: 'string',
        defaultValue: 'linear',
      },
      {
        name: 'fade',
        type: 'boolean',
        defaultValue: false,
      },
      {
        name: 'centerMode',
        type: 'boolean',
        defaultValue: false,
      },
    ],
  },
];

export { carouselConfigExtended };
