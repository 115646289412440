import React, { useState } from 'react';
import { Flex } from 'glints-aries/es';
import {
  Icon,
  PlainButton,
  Popover,
  PopoverProps,
  Tag,
  Tooltip,
  Typography,
} from 'glints-aries/es/@next';
import { Blue, Neutral } from 'glints-aries/es/@next/utilities/colors';
import {
  space8,
  space12,
  space16,
} from 'glints-aries/es/@next/utilities/spacing';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import useRejectionReasonPreferenceModal from '../Modal/RejectionReasonPreferenceModal/hooks/useRejectionReasonPreferenceModal';
import { applicationRejectionMessageTypeMessages } from '../Modal/RejectionReasonPreferenceModal/RejectionReasonPreferenceForm/consts';
import {
  ApplicationRejectionMessage,
  ApplicationRejectionMessagePreference,
  ApplicationRejectionMessageType,
  selectableApplicationRejectionMessageTypes,
} from '../Modal/RejectionReasonPreferenceModal/types';

type RejectApplicationButtonWrapperProps = {
  children: React.ReactNode;
  rejectionPreference: ApplicationRejectionMessagePreference;
  rejectionMessages: ApplicationRejectionMessage[];
  onRejectApplications: (
    rejectionType: ApplicationRejectionMessageType
  ) => void;
  showTooltip?: boolean;
  preferredPosition?: PopoverProps['preferredPosition'];
  preferredAlignment?: PopoverProps['preferredAlignment'];
};

function RejectApplicationButtonWrapperTooltip({
  children,
}: Pick<RejectApplicationButtonWrapperProps, 'children'>) {
  const { formatMessage } = useIntl();

  return (
    <Tooltip
      content={formatMessage({
        id: 'interactive-reject',
        defaultMessage: 'Reject',
      })}
    >
      {children}
    </Tooltip>
  );
}

const PopoverContainer = styled.div`
  padding: ${space8} ${space16};
  width: 376px;
`;

const TagsWrapper = styled.div`
  display: flex;
  gap: ${space12};
  flex-wrap: wrap;
`;

type RejectionTypeTagProps = Pick<
  RejectApplicationButtonWrapperProps,
  'rejectionMessages' | 'onRejectApplications'
> & {
  type: ApplicationRejectionMessageType;
};

function RejectionTypeTag({
  rejectionMessages,
  type,
  onRejectApplications,
}: RejectionTypeTagProps) {
  const handleRejectApplications = (e: React.MouseEvent) => {
    e.stopPropagation();
    onRejectApplications(type);
  };

  const getTooltipContent = () => {
    const tooltipContent = rejectionMessages.find(
      message => message.messageType === type
    )?.message;

    if (!tooltipContent)
      throw new Error('Unable to get the message for unsupport rejection type');

    return tooltipContent;
  };

  return (
    <Tooltip
      content={getTooltipContent()}
      key={type}
      zIndex={999}
      preferredPosition="left-bottom"
    >
      <Tag onClick={handleRejectApplications} key={type}>
        <Typography variant="subtitle2" color={Blue.S99}>
          <FormattedMessage
            {...applicationRejectionMessageTypeMessages[type]}
          />
        </Typography>
      </Tag>
    </Tooltip>
  );
}

function RejectApplicationButtonWrapperPopover({
  children,
  rejectionMessages,
  onRejectApplications,
  preferredPosition,
  preferredAlignment,
}: Pick<
  RejectApplicationButtonWrapperProps,
  | 'children'
  | 'rejectionMessages'
  | 'onRejectApplications'
  | 'preferredPosition'
  | 'preferredAlignment'
>) {
  const [popoverActive, setPopoverActive] = useState(false);
  const { openRejectionReasonPreferenceModal, rejectionReasonPreferenceModal } =
    useRejectionReasonPreferenceModal();

  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleOpenRejectionReasonPreferenceModal = (e: React.MouseEvent) => {
    stopPropagation(e);
    openRejectionReasonPreferenceModal();
    setPopoverActive(false);
  };

  const activator = (
    <div onClick={stopPropagation} onMouseEnter={() => setPopoverActive(true)}>
      {children}
    </div>
  );

  return (
    <>
      <div onMouseLeave={() => setPopoverActive(false)}>
        <Popover
          active={popoverActive}
          activator={activator}
          onClose={() => setPopoverActive(false)}
          preferredPosition={preferredPosition}
          preferredAlignment={preferredAlignment}
        >
          <Popover.Pane>
            <PopoverContainer>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                style={{ marginBottom: space8 }}
              >
                <Typography variant="body2" color={Neutral.B18}>
                  <FormattedMessage
                    id="text-select-rejection-reason"
                    defaultMessage="Select Rejection Reason"
                  />
                </Typography>
                <PlainButton
                  icon={<Icon name="ri-pencil-line" />}
                  onClick={handleOpenRejectionReasonPreferenceModal}
                >
                  <FormattedMessage
                    id="interactive-edit"
                    defaultMessage="Edit"
                  />
                </PlainButton>
              </Flex>
              <TagsWrapper>
                {selectableApplicationRejectionMessageTypes.map(type => (
                  <RejectionTypeTag
                    rejectionMessages={rejectionMessages}
                    type={type}
                    onRejectApplications={onRejectApplications}
                    key={type}
                  />
                ))}
              </TagsWrapper>
            </PopoverContainer>
          </Popover.Pane>
        </Popover>
      </div>
      {rejectionReasonPreferenceModal}
    </>
  );
}

export default function RejectApplicationButtonWrapper({
  children,
  onRejectApplications,
  rejectionPreference,
  rejectionMessages,
  preferredAlignment,
  preferredPosition,
  showTooltip = false,
}: RejectApplicationButtonWrapperProps) {
  if (
    rejectionPreference === ApplicationRejectionMessagePreference.CUSTOM_MESSAGE
  ) {
    return (
      <RejectApplicationButtonWrapperPopover
        onRejectApplications={onRejectApplications}
        rejectionMessages={rejectionMessages}
        preferredAlignment={preferredAlignment}
        preferredPosition={preferredPosition}
      >
        {children}
      </RejectApplicationButtonWrapperPopover>
    );
  }

  if (showTooltip) {
    return (
      <RejectApplicationButtonWrapperTooltip>
        {children}
      </RejectApplicationButtonWrapperTooltip>
    );
  }

  return <>{children}</>;
}
