import { useSelector } from 'react-redux';

import { UnleashFeatureNames } from '../../modules/Unleash/featureNames';
import { getIsFeatureEnabled } from '../../selectors/features';
import { countryCodeIsIndonesia } from '../helpers/countryCodeHelper';
import { useGetSessionCompany } from './useGetSessionCompany';

type Params = {
  skipCompanyCountryCheck?: boolean;
};

export default function useIsVIPMembershipEnabled(params?: Params): boolean {
  const { sessionCompany } = useGetSessionCompany();
  const isEmpVIPMembershipEnabled = useSelector(state =>
    getIsFeatureEnabled(state, UnleashFeatureNames.empVIPMembership)
  );

  if (params?.skipCompanyCountryCheck) {
    return isEmpVIPMembershipEnabled;
  }

  return Boolean(
    isEmpVIPMembershipEnabled &&
      sessionCompany &&
      countryCodeIsIndonesia(sessionCompany.CountryCode)
  );
}
