import React from 'react';
import { Flex } from 'glints-aries';
import { Icon, Typography } from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  ApplicationStatus,
  applicationStatusMessages,
} from '../../../common/constants';
import { useIsMobileVersion } from '../hooks';
import ChannelHeaderMenuButton from './ChannelHeader/ChannelHeaderMenuButton';

type ApplicationStatusDropdownButtonProps = {
  status: ApplicationStatus;
  isLoading?: boolean;
  onClick?: () => void;
  isDisabled?: boolean;
};

const Styled = {
  SpinnerIcon: styled(Icon)`
    width: 18px !important;
    height: 18px !important;
    animation: spinner 1s linear infinite;
  `,
  ArrowDown: styled(Icon)`
    width: 18px !important;
    height: 18px !important;
  `,
};

const ApplicationStatusDropdownButton = ({
  status,
  isLoading,
  onClick,
  isDisabled,
}: ApplicationStatusDropdownButtonProps) => {
  const isMobileVersion = useIsMobileVersion('ChannelHeaderMenus');

  return (
    <ChannelHeaderMenuButton
      icon={
        <If condition={isMobileVersion}>
          <Icon name="ri-contacts-line" />
        </If>
      }
      iconPosition="left"
      disabled={isLoading || isDisabled}
      disableTypography={true}
      onClick={onClick}
    >
      <If condition={!isMobileVersion}>
        <Typography variant="body1">
          <FormattedMessage
            id="text_application-status-label"
            defaultMessage="Status:"
          />
        </Typography>
      </If>
      <Flex alignItems="center" style={{ gap: '4px' }}>
        <Typography
          variant={isMobileVersion ? 'subtitle2' : 'body2'}
          style={{
            whiteSpace: 'nowrap',
          }}
          data-cy="application-status-btn-text"
        >
          <Choose>
            <When condition={isMobileVersion}>
              <FormattedMessage
                id="interactive-move-to"
                defaultMessage="Move To"
              />
            </When>
            <Otherwise>
              <FormattedMessage {...applicationStatusMessages[status]} />
            </Otherwise>
          </Choose>
        </Typography>
        <Choose>
          <When condition={isLoading}>
            <Styled.SpinnerIcon name="ri-loader" />
          </When>
          <Otherwise>
            <Styled.ArrowDown name="ri-arrow-m-down-line" />
          </Otherwise>
        </Choose>
      </Flex>
    </ChannelHeaderMenuButton>
  );
};

export default ApplicationStatusDropdownButton;
