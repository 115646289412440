import React, { ReactNode } from 'react';
import styled from 'styled-components';

import UserLastSeenActivityStatus from './UserLastSeenActivityStatus';

const Container = styled.span`
  position: relative;
  display: flex;
  flex: unset;
  height: fit-content;
  .user-activity-dot {
    position: absolute;
    bottom: -4px;
    right: 0;
  }
`;

type UserLastSeenBadgeAvatarProps = {
  children: ReactNode;
  lastSeenAt: string | undefined | null;
  indicatorSize?: number;
  hideCondition?: (hours: number) => boolean;
};

export default function UserLastSeenBadgeAvatar({
  children,
  lastSeenAt,
  indicatorSize,
  ...rest
}: UserLastSeenBadgeAvatarProps) {
  return (
    <Container>
      {children}
      {lastSeenAt && (
        <UserLastSeenActivityStatus
          {...rest}
          lastSeen={lastSeenAt}
          size={indicatorSize}
          variant="dot"
        />
      )}
    </Container>
  );
}
