import React, { ComponentType } from 'react';
import dayjs from 'dayjs';
import { Badge } from 'glints-aries/es/@next';
import { BadgeStatusVariant } from 'glints-aries/es/@next/Badge';
import { Green, Neutral } from 'glints-aries/es/@next/utilities/colors';
import { get, omit } from 'lodash-es';
import {
  defineMessages,
  IntlShape,
  MessageDescriptor,
  useIntl,
} from 'react-intl';

import {
  CutoutBadgeStyle,
  DotBadgeStyle,
} from './UserLastSeenActivityStatus.sc';

const messages = defineMessages({
  online: {
    id: 'text-online',
    defaultMessage: 'Online',
  },
  justActive: {
    id: 'text-just-active',
    defaultMessage: 'Just Active',
  },
  activeToday: {
    id: 'text-active-today',
    defaultMessage: 'Active Today',
  },
  lastSeen: {
    id: 'text-last-seen-count-days',
    defaultMessage: 'Last seen {dateTime} ago',
  },
});

const hoursToText: Record<
  number,
  {
    status: BadgeStatusVariant;
    text: MessageDescriptor;
  }
> = {
  3: {
    status: 'successBright',
    text: messages.online,
  },
  8: {
    status: 'neutral',
    text: messages.justActive,
  },
  24: {
    status: 'neutral',
    text: messages.activeToday,
  },
};

type CommonProps = {
  lastSeen: string;
  hideCondition?: (hours: number) => boolean;
  style?: React.CSSProperties;
};
type UserLastSeenActivityStatusProps = CommonProps &
  (
    | {
        variant?: Exclude<LastSeenBadgeVariants, 'dot'>;
      }
    | {
        variant: 'dot';
        size?: number;
      }
  );

type BadgeProps = {
  children: React.ReactNode;
  status?: BadgeStatusVariant;
};

type LastSeenBadgeVariants = 'cutOut' | 'default' | 'dot';

const CutoutBadge = ({ children, ...rest }: BadgeProps) => (
  <CutoutBadgeStyle {...rest}>{children}</CutoutBadgeStyle>
);

const DefaultBadge = ({ children, ...rest }: BadgeProps) => (
  <Badge {...rest}>{children}</Badge>
);

const DotBadge = ({
  status,
  size,
  ...rest
}: Omit<BadgeProps, 'children'> & {
  size?: number;
}) => {
  const badgeBackgroundColor = {
    ['successBright']: Green.B61,
  };

  const backgroundColor = status
    ? get(badgeBackgroundColor, status, Neutral.B95)
    : Neutral.B95;

  return (
    <DotBadgeStyle
      {...omit(rest, 'children')}
      backgroundColor={backgroundColor}
      size={size || 15}
      className="user-activity-dot"
    />
  );
};

const variantsMap: Record<LastSeenBadgeVariants, ComponentType<BadgeProps>> = {
  cutOut: CutoutBadge,
  default: DefaultBadge,
  dot: DotBadge,
};

export default function UserLastSeenActivityStatus({
  lastSeen,
  variant = 'default',
  hideCondition,
  ...rest
}: UserLastSeenActivityStatusProps) {
  const intl = useIntl();
  const activityStatusText = getActivityStatusTextBasedOnLastSeen({
    intl,
    lastSeen,
    hideCondition,
  });
  if (!activityStatusText) return null;
  const BadgeComponent = variantsMap[variant];

  return (
    <BadgeComponent {...rest} status={activityStatusText.status}>
      {activityStatusText.text}
    </BadgeComponent>
  );
}

function getActivityStatusTextBasedOnLastSeen({
  intl,
  lastSeen,
  hideCondition,
}: {
  intl: Pick<IntlShape, 'formatMessage'>;
  lastSeen: string;
} & Pick<CommonProps, 'hideCondition'>): {
  text: string;
  status: BadgeStatusVariant;
} | null {
  const lastSeenDate = dayjs(lastSeen);
  const lastSeenInHours = dayjs().diff(lastSeenDate, 'hour');
  const textBasedOnHours = Object.keys(hoursToText).find(
    hours => lastSeenInHours <= parseInt(hours)
  );

  if (hideCondition && hideCondition(lastSeenInHours)) {
    return null;
  }

  if (!textBasedOnHours) {
    return {
      status: 'neutral',
      text: intl.formatMessage(messages.lastSeen, {
        dateTime: lastSeenDate.fromNow(true),
      }),
    };
  }
  const { status, text } = hoursToText[parseInt(textBasedOnHours)];
  return {
    status,
    text: intl.formatMessage(text),
  };
}
