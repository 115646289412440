import config from '../../config';
import { getFeature } from '../../selectors/features';
import { getUser } from '../../selectors/user';
import { UnleashFeatureNames } from '../Unleash/featureNames';

export const chatSupportedCountries = ['id', 'vn'];

export const canAccessMessaging = state => {
  const { COUNTRY } = config;
  const domainCountrySupported = chatSupportedCountries.includes(COUNTRY);
  return limitUserAccess(state) && domainCountrySupported;
};

const limitUserAccess = state => {
  const user = getUser(state);
  const limitGlintsUsers = getFeature(
    state,
    UnleashFeatureNames.empChatLimitGlintsUsers
  );

  if (!limitGlintsUsers) return true;

  if (!user) return true;

  const { email } = user;

  return limitGlintsUsers && email.split('@')[1] === 'glints.com';
};
