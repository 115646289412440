type errorProps = {
  status: number;
  data: {
    error: {
      code: number;
      details: Array<string>;
    };
  };
};

export const errorStatus = (error: errorProps) => error?.status ?? null;

export const isError400 = (error: errorProps) => error?.status === 400;
export const isError403 = (error: errorProps) => error?.status === 403;
export const isError404 = (error: errorProps) => error?.status === 404;
export const isError409 = (error: errorProps) => error?.status === 409;
export const isError500 = (error: errorProps) => error?.status === 500;

export const errorDetails = (error: errorProps) =>
  error?.data?.error?.details?.length ? error?.data?.error?.details[0] : null;

export const errorCode = (error: errorProps) =>
  error?.data?.error?.code ?? null;
