import React, { Suspense } from 'react';
import { ScreenSize } from 'glints-aries';
import { debounce } from 'lodash-es';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { useLocation } from 'react-router';

import authenticate from '../../common/authenticate';
import { loadable } from '../../common/loadable';
import { getSessionCompany } from '../../selectors/session';
import { getUserRole } from '../../selectors/user';
import { getMenuItems, getPermissions, getPlatformFeatures } from '../Platform';
import { NavbarWrapper } from './Navbar.sc';
import NavbarDesktop from './NavbarDesktop';
import NavbarMobile from './NavbarMobile';

const BookAConsultModal = loadable(
  () =>
    import('../../components/Modal/BookConsultModal').then(
      module => module.default
    ),
  {
    fallback: undefined,
  }
);

const Navbar: React.FC<NavbarProps> = ({
  authenticated,
  setHeaderHeight,
  top,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const platformFeatures = useSelector(getPlatformFeatures);

  const role = useSelector(getUserRole);
  const company = useSelector(getSessionCompany);
  const menuItems = useSelector(getMenuItems);
  const permissions = useSelector(getPermissions);

  const hideSignUp = platformFeatures?.menu?.hideSignUp ?? false;

  const { pathname } = useLocation();

  React.useLayoutEffect(() => {
    if (ref.current) {
      setHeaderHeight(ref.current.clientHeight);
    }
  }, [setHeaderHeight]);

  React.useLayoutEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setHeaderHeight(ref.current.clientHeight);
      }
    };
    const debouncedHandleResize = debounce(handleResize, 500);
    window.addEventListener('resize', debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, [setHeaderHeight]);

  return (
    <NavbarWrapper ref={ref} top={top}>
      <MediaQuery maxWidth={ScreenSize.tablet - 1}>
        <NavbarMobile authenticated={authenticated} />
      </MediaQuery>
      <MediaQuery minWidth={ScreenSize.tablet}>
        <NavbarDesktop
          authenticated={authenticated}
          menuItems={menuItems}
          hideSignUp={hideSignUp}
          role={role}
          company={company}
          permissions={permissions}
          hasNewUnreadMessages={false}
          fromLandingPage={location.pathname === '/'}
          fromJobEditPage={pathname.startsWith('/job')}
          fromOnboardingPage={pathname === '/onboarding'}
        />
      </MediaQuery>
      <Suspense fallback={null}>
        <BookAConsultModal />
      </Suspense>
    </NavbarWrapper>
  );
};

type NavbarProps = {
  authenticated: boolean;
  setHeaderHeight(height: number): void;
  top: number;
};

export default authenticate(Navbar, true);
