import { useMemo } from 'react';
import { useLocation } from 'react-router';

type Location = 'chat_widget' | 'chat_page';

export const useGetChannelLocation = () => {
  const location = useLocation();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const channelLocation: Location = useMemo(() => {
    const isChatWidgetOpened = Boolean(searchParams.get('pop_cid'));

    const isChatPage = new RegExp('^(/messages).*', 'i').test(
      location.pathname
    );

    if (isChatWidgetOpened) {
      return 'chat_widget';
    }

    if (isChatPage) {
      return 'chat_page';
    }

    throw new Error('Untracked location');
  }, [location.pathname, searchParams]);

  return {
    channelLocation,
  } as const;
};
