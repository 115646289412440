import React from 'react';
import { useModal } from 'glints-aries/es/@next';
import { defineMessage, useIntl } from 'react-intl';

import { useGlintsVIPFreeTrialOrPurchaseModal } from '../../modules/CreditSystem/PurchaseModals/GlintsVIPPurchaseModal/hooks/useGlintsVIPFreeTrialOrPurchaseModal';
import { useGlintsVIPMembershipInfo } from '../GlintsVIPMembershipInfo/hooks/useGlintsVIPMembershipInfo';
import {
  FullProfileAccessAwarenessModalContent,
  FullProfileAccessAwarenessModalFooter,
} from './FullProfileAccessAwarenessModal';

const titleMessage = defineMessage({
  id: 'text-full-profile-access',
  defaultMessage: 'Full Profile Access',
});

export default function useFullProfileAccessAwarenessModal() {
  const { formatMessage } = useIntl();
  const { open: openModal, close: closeModal } = useModal();

  const { openGlintsVIPPurchaseModal, isModalChunkLoadPending } =
    useGlintsVIPFreeTrialOrPurchaseModal();

  const { vipMembershipInfo } = useGlintsVIPMembershipInfo();

  const openFullProfileAccessAwarenessModal = () => {
    openModal({
      header: formatMessage(titleMessage),
      children: <FullProfileAccessAwarenessModalContent />,
      customActions: (
        <FullProfileAccessAwarenessModalFooter
          onClose={closeModal}
          openGlintsVIPPurchaseModal={openGlintsVIPPurchaseModal}
          isGlintsVIPPurchaseModalChunkLoading={isModalChunkLoadPending}
          isVIPMembershipActive={vipMembershipInfo?.isActive}
        />
      ),
      closeOnClickOutside: true,
    });
  };

  return {
    openFullProfileAccessAwarenessModal,
  } as const;
}
