import React from 'react';
import { PrimaryButton, Typography } from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { getCurrentCompanyLoading } from '../../selectors/company';
import { getSessionCompany } from '../../selectors/session';
import { StyledLoading } from './CTAButtonLoggedIn.sc';
import { NavbarItem } from './NavbarItem';

type CTAButtonLoggedInProps = {
  fromJobEditPage?: boolean;
  fromOnboardingPage?: boolean;
  closeSidebar?(): void;
};

type NavbarButtonProps = {
  to: string;
  closeSidebar(): void;
  label: React.ReactNode;
};

const NavbarButton: React.FC<NavbarButtonProps> = ({
  to,
  closeSidebar,
  label,
  ...menuItemProps
}) => (
  <NavbarItem
    to={to}
    onClick={closeSidebar}
    style={{
      padding: 0,
    }}
    {...menuItemProps}
  >
    <PrimaryButton
      style={{
        textTransform: 'uppercase',
      }}
    >
      <Typography variant="button">{label}</Typography>
    </PrimaryButton>
  </NavbarItem>
);

const CTAButtons = {
  postJob: {
    label: (
      <FormattedMessage
        id="interactive-post-a-job"
        defaultMessage="Post A Job"
      />
    ),
    to: '/job/create',
  },
  createCompany: {
    label: (
      <FormattedMessage
        id="modules.Navigation.CTAButtonLoggedIn.create.a.company"
        defaultMessage="Create A Company"
      />
    ),
    to: '/onboarding',
  },
};

const getJobCreatePath = (pathname: string) => {
  const pathNameListToHidePostJobButton = [
    '/job/draft',
    '/job/create',
    '/job/copy',
  ];
  return pathNameListToHidePostJobButton.some(pathNameToHidePostJobButton =>
    pathname.startsWith(pathNameToHidePostJobButton)
  );
};

const CTAButtonLoggedIn: React.FC<CTAButtonLoggedInProps> = ({
  closeSidebar = () => {
    void 0;
  },
  fromOnboardingPage,
  ...menuItemProps
}) => {
  const hasCompany = Boolean(useSelector(getSessionCompany));
  const companyLoading = useSelector(getCurrentCompanyLoading);
  const { pathname } = useLocation();

  if (fromOnboardingPage) {
    return null;
  }

  if (hasCompany) {
    if (!getJobCreatePath(pathname)) {
      return (
        <NavbarButton
          to={CTAButtons.postJob.to}
          label={CTAButtons.postJob.label}
          closeSidebar={closeSidebar}
          {...menuItemProps}
        />
      );
    } else {
      return <></>;
    }
  } else if (companyLoading) {
    return <StyledLoading />;
  } else {
    return (
      <NavbarButton
        to={CTAButtons.createCompany.to}
        label={CTAButtons.createCompany.label}
        closeSidebar={closeSidebar}
        {...menuItemProps}
      />
    );
  }
};

export default CTAButtonLoggedIn;
