/* eslint-disable camelcase */
import { ApplicationStatus } from '../../common/constants';
import { TrackingService } from '../../common/tracking/trackingService';

export const WhatsAppContactPopoverSources = {
  ATS: 'ats_table',
  APPLICATION_POPUP: 'application_popup',
  APPLICATION_DETAIL: 'application_detail',
  CHAT: 'chat',
} as const;

export type WhatsAppContactPopoverSources =
  (typeof WhatsAppContactPopoverSources)[keyof typeof WhatsAppContactPopoverSources];

type EventProperties = {
  applicationId: string;
  jobId: string;
  source: WhatsAppContactPopoverSources;
};

type EventPropertiesWithAtsStage = EventProperties & {
  atsStage: ApplicationStatus;
};

export const trackWAPopoverInCommunicationClickedEvent = (
  properties: EventProperties
) => {
  const transformedProperties = {
    application_id: properties.applicationId,
    job_id: properties.jobId,
    source: properties.source,
  };
  TrackingService.logEvent(
    'WA Popover In Communication Clicked',
    transformedProperties
  );
};

export const trackWAPopoverGlintsChatClickedEvent = (
  properties: EventPropertiesWithAtsStage
) => {
  const transformedProperties = {
    application_id: properties.applicationId,
    job_id: properties.jobId,
    source: properties.source,
    ats_stage: properties.atsStage,
  };

  TrackingService.logEvent(
    'WA Popover Glints Chat Clicked',
    transformedProperties
  );
};

export const trackWAChatIconClickedEvent = (
  properties: EventPropertiesWithAtsStage
) => {
  const transformedProperties = {
    application_id: properties.applicationId,
    job_id: properties.jobId,
    source: properties.source,
    ats_stage: properties.atsStage,
  };

  TrackingService.logEvent('WA Chat Icon Clicked', transformedProperties);
};
