import React, { ReactNode } from 'react';
import { Typography, useModal } from 'glints-aries/es/@next';
import { space24 } from 'glints-aries/es/@next/utilities/spacing';
import { ComponentAction } from 'glints-aries/es/types/componentAction';
import { useIntl } from 'react-intl';

import { CompanyStatus } from '../../constants';
import { useGetSessionCompany } from '../useGetSessionCompany';
import { unverifiedCompanyRestrictionMessages } from './unverifiedCompanyRestrictionMessages';
import { Container } from './unverifiedCompanyRestrictionModal.sc';

export const useUnverifiedCompanyRestrictionModal = (
  firstLineFormattedMessage = unverifiedCompanyRestrictionMessages.needVerification
) => {
  const { open, close } = useModal();
  const { formatMessage } = useIntl();
  const { sessionCompany } = useGetSessionCompany();
  const companyStatus = sessionCompany?.status;

  const getOpenRestrictionModalFunc = () => {
    const header = formatMessage(unverifiedCompanyRestrictionMessages.header);
    const Content = () => (
      <Container>
        <Typography variant="body1" style={{ marginBottom: space24 }}>
          {formatMessage(firstLineFormattedMessage)}
        </Typography>
        <Typography variant="body1" as="span">
          {formatMessage(unverifiedCompanyRestrictionMessages.uploadDocuments, {
            a: (chunks: ReactNode[]) => (
              <a href={`mailto:${chunks}`}>{chunks}</a>
            ),
          })}
        </Typography>
      </Container>
    );
    const primaryAction: ComponentAction = {
      label: formatMessage(unverifiedCompanyRestrictionMessages.refresh),
      onClick: () => window.location.reload(),
    };
    const secondaryAction: ComponentAction = {
      label: formatMessage(unverifiedCompanyRestrictionMessages.close),
      onClick: close,
    };

    if (companyStatus === CompanyStatus.VERIFIED) {
      return null;
    }

    return () =>
      open({
        header,
        children: <Content />,
        primaryAction,
        secondaryAction,
      });
  };

  return { getOpenRestrictionModalFunc };
};
