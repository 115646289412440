import React from 'react';
import PropTypes from 'prop-types';

import GlintsPicture from './GlintsPicture';

const CompanyPhoto = ({ photo, ...otherProps }) => (
  <GlintsPicture name={photo} assetType="company-photos" {...otherProps} />
);

CompanyPhoto.propTypes = {
  photo: PropTypes.string,
};

export default CompanyPhoto;
