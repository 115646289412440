import {
  COMPANY_MENU_ITEMS,
  RECRUITER_COMPANY_MENU_ITEMS,
  RECRUITER_COMPANY_TALENTHUNT_MENU_ITEMS,
  TALENTHUNT_MENU_ITEMS,
  USER_WITHOUT_COMPANY_MENU_ITEMS,
} from './Constants';

export const FEATURES = {
  glints: {
    logo: {
      style: 'navbar-logo',
    },
    showTalentHuntFeatures: true,
    menuItems: USER_WITHOUT_COMPANY_MENU_ITEMS,
    onboarding: {
      requiredFields: ['tagline', 'countryCode'],
    },
    settings: {
      hideHelpAndSupport: false,
    },
  },
  company: {
    menuItems: COMPANY_MENU_ITEMS,
  },
  talenthunt: {
    menuItems: TALENTHUNT_MENU_ITEMS,
  },
  recruiterCompany: {
    menuItems: RECRUITER_COMPANY_MENU_ITEMS,
  },
  recruiterCompanyTalentHunt: {
    menuItems: RECRUITER_COMPANY_TALENTHUNT_MENU_ITEMS,
  },
};
