import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { SWRConfig as BaseSWRConfig } from 'swr';

import { getAuthHeader } from './common/api';
import { createAcceptLanguageHeader } from './common/helpers/createAcceptLanguageHeader';
import { getConfig } from './config';

export const buildFetcher =
  store =>
  (...args) => {
    const endpoint = args[0];
    if (endpoint.indexOf('/') !== 0) {
      throw new Error(
        `Invalid endpoint "${endpoint}". Must start with / (forward slash).`
      );
    }
    const config = getConfig();
    const headers = {
      'accept-language': createAcceptLanguageHeader(config.LANG),
      ...getAuthHeader(store.dispatch, store.getState),
    };
    const options = { headers };
    const fullUrl = `${config.API_BASE}${endpoint}`;
    return axios(fullUrl, options)
      .then(result => result.data)
      .catch(error => {
        throw error;
      });
  };

export const buildConfig = store => ({
  fetcher: buildFetcher(store),
  shouldRetryOnError: false,
  revalidateOnFocus: false,
});

export const SWRConfig = ({ store, ...props }) => (
  <BaseSWRConfig {...props} value={buildConfig(store)} />
);

SWRConfig.propTypes = {
  store: PropTypes.object.isRequired,
};
