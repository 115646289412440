import React from 'react';
import { Flex, Greyscale, SecondaryColor } from 'glints-aries';
import { Typography } from 'glints-aries/es/@next';
import { FileAttachmentProps } from 'stream-chat-react';
import styled from 'styled-components';

import FileUploadSpinner from './FileUploadSpinner';
import { ClippedTypography } from './Styled';

const Styled = {
  FileBubble: styled(Flex)`
    cursor: pointer;
    padding: 8px;
    gap: 16px;
    align-items: center;
    background-color: ${Greyscale.white};
  `,
  OverflowHiddenFlex: styled(Flex)`
    overflow: hidden;
  `,
};

const humanReadableFileSize = (bytes: number) => {
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const sizes = ['B', 'KB', 'MB'];
  return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
};

const FileAttachment = ({ attachment }: FileAttachmentProps) => (
  <Styled.FileBubble
    onClick={() => window.open(attachment.asset_url, '_blank')}
  >
    <img src="/images/pdf-icon.png" />
    <Styled.OverflowHiddenFlex
      justifyContent="space-between"
      style={{ gap: 16 }}
    >
      <Styled.OverflowHiddenFlex flexDirection="column">
        <ClippedTypography variant="body1">
          {attachment.title}
        </ClippedTypography>
        {attachment.file_size && (
          <Typography variant="subtitle2" color={Greyscale.devilsgrey}>
            {humanReadableFileSize(attachment.file_size)}
          </Typography>
        )}
      </Styled.OverflowHiddenFlex>
      <If condition={attachment.loading}>
        <FileUploadSpinner fill={SecondaryColor.actionblue} />
      </If>
    </Styled.OverflowHiddenFlex>
  </Styled.FileBubble>
);

export default FileAttachment;
