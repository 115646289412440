import styled from 'styled-components';

import Container from '../../../components/Container';

export const NavbarHeaderWithLogoContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 20px;
  width: 100%;
`;

export const NavbarLogo = styled.img.attrs({
  src: '/images/glints_for_employers.svg',
})`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  margin-right: 10px;

  width: auto;
  height: 35px;
`;
