import { borderRadius8 } from 'glints-aries/es/@next/utilities/borderRadius';
import {
  space8,
  space12,
  space16,
} from 'glints-aries/es/@next/utilities/spacing';
import styled from 'styled-components';

export const VipBackground = styled.div`
  background: linear-gradient(
    291deg,
    rgba(255, 237, 177, 0.4) 32.49%,
    #fff8e0 78.31%
  );
  padding: ${space16};
  border-radius: ${borderRadius8};
  width: 100%;
  display: flex;
  gap: ${space16};
`;

export const DefaultAccessLimitStyle = {
  VipBackground: styled(VipBackground)`
    flex-direction: column;
  `,
  LockedContentList: styled.div`
    display: grid;
    grid-template-columns: repeat(5, max-content);
    gap: ${space16};
    row-gap: ${space8};
  `,
};
export const LockedProfileDetailsImage = styled.img.attrs({
  src: '/images/full-profile-access/profile-details-lock.svg',
})`
  width: 48px;
  height: 48px;
`;

export const PopoverAccessLimitStyle = {
  VipBackground: styled(VipBackground)`
    flex-direction: column;
    gap: ${space12};
    max-width: 432px;
  `,
  Image: styled(LockedProfileDetailsImage)`
    width: 80px;
    height: 80px;
  `,
};

export const ChatAccessLimitStyle = {
  VipBackground: styled(PopoverAccessLimitStyle.VipBackground)`
    border-radius: 0;
    z-index: 100;
    max-width: 100%;
  `,
  Image: styled(PopoverAccessLimitStyle.Image)``,
};
