import { merge, uniq } from 'lodash-es';
import { handleActions } from 'redux-actions';

import { Actions } from './Actions';
import { DEFAULT_PERMISSIONS } from './Permissions';

export const initialState = {
  features: {
    names: [],
    value: {},
  },
  permissions: {
    value: DEFAULT_PERMISSIONS,
    loading: false,
    success: false,
    error: null,
  },
};

export default handleActions(
  {
    [Actions.MERGE_FEATURES]: (state, action) => {
      const merged = merge({}, state.features.value, action.payload);
      // Replace menuItems if action.payload.menuItems exist
      merged.menuItems =
        action.payload?.menuItems ?? state.features.value.menuItems;
      return {
        ...state,
        features: {
          ...state.features,
          value: merged,
        },
      };
    },
    [Actions.ADD_FEATURE_NAME]: (state, action) => ({
      ...state,
      features: {
        ...state.features,
        names: uniq([...state.features.names, action.payload]),
      },
    }),
    [Actions.RESET_FEATURES_STATE]: state => ({
      ...state,
      features: {
        ...state.features,
        names: ['glints'],
        value: {},
      },
    }),
    [Actions.MERGE_PERMISSION]: (state, action) => ({
      ...state,
      permissions: {
        ...state.permissions,
        value: merge({}, state.permissions.value, action.payload),
      },
    }),
    [Actions.MERGE_PERMISSIONS_REQUEST]: state => ({
      ...state,
      permissions: {
        ...state.permissions,
        loading: true,
        success: false,
        error: null,
      },
    }),
    [Actions.MERGE_PERMISSIONS_SUCCESS]: state => ({
      ...state,
      permissions: {
        ...state.permissions,
        loading: false,
        success: true,
        error: null,
      },
    }),
    [Actions.MERGE_PERMISSIONS_FAILURE]: (state, action) => ({
      ...state,
      permissions: {
        ...state.permissions,
        loading: false,
        success: false,
        error: action.payload,
      },
    }),
  },
  initialState
);
