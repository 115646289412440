import React from 'react';
import { apm } from '@elastic/apm-rum';
import { Icon } from 'glints-aries/es/@next';
import { Link } from 'react-router-dom';

import { useUnverifiedCompanyRestrictionModal } from '../../common/hooks/useUnverifiedCompanyRestrictionModal/useUnverifiedCompanyRestrictionModal';
import { useStreamCurrentUserTotalUnreadCount } from '../../common/stream';
import ChatButton from '../../components/ChatButton';
import MobileChatButton from '../../components/ChatButton/MobileChatButton';
import ErrorBoundaryWithCrashReporting from '../../components/Error/ErrorBoundaryWithCrashReporting';
import { restrictUnverifiedCompanyMessage } from '../EmployerATSV2/components/ApplicationTable/applicationTableMessages';
import {
  ChatClickedEventLocation,
  trackGlobalChatClickedEvent,
} from '../EmployerATSV2/tracking';
import { MessagingUIMode } from '../MessagingV2/constants';
import { useIsMobileVersion } from '../MessagingV2/hooks';
import { trackChatPageViewedEvent } from '../MessagingV2/tracking';

const CTAChatComponent = () => {
  const totalUnreadCount = useStreamCurrentUserTotalUnreadCount();
  const isMobileVersion = useIsMobileVersion('TopNavCTA');
  const { getOpenRestrictionModalFunc } = useUnverifiedCompanyRestrictionModal(
    restrictUnverifiedCompanyMessage
  );
  const openRestrictionModalFunc = getOpenRestrictionModalFunc();
  return (
    <Link
      data-cy="chat-cta-menu"
      to="/messages"
      onClick={e => {
        if (openRestrictionModalFunc) {
          e.preventDefault();
          openRestrictionModalFunc();
          return;
        }
        trackGlobalChatClickedEvent(ChatClickedEventLocation.HEADER);
        trackChatPageViewedEvent({
          source: 'navbar',
          uiType: MessagingUIMode.FULL,
        });
      }}
    >
      <Choose>
        <When condition={isMobileVersion}>
          <MobileChatButton unreadCount={totalUnreadCount} />
        </When>
        <Otherwise>
          <ChatButton
            unreadCount={totalUnreadCount}
            icon={<Icon name="ri-message-line" />}
            iconPosition="left"
            style={{
              textTransform: 'uppercase',
            }}
          />
        </Otherwise>
      </Choose>
    </Link>
  );
};

const CTAChat = () => (
  <ErrorBoundaryWithCrashReporting
    onError={e => {
      apm.setCustomContext({ ctaChatNavbar: 'error' });
      apm.captureError(e);
      apm.setCustomContext({ ctaChatNavbar: '' });
    }}
    fallback={null}
  >
    <CTAChatComponent />
  </ErrorBoundaryWithCrashReporting>
);

export default CTAChat;
