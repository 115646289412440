import React, { ComponentProps } from 'react';
import { Avatar } from 'stream-chat-react';

import useIsOSSEnabled from '../../../common/hooks/useIsOSSEnabled';
import { convertS3ToOSSURL } from '../helper';

type Props = ComponentProps<typeof Avatar>;

export default function AvatarWithOSS(props: Props) {
  const isOSSEnabled = useIsOSSEnabled();
  const processedImageUrl = isOSSEnabled
    ? convertS3ToOSSURL(props.image)
    : props.image;

  return <Avatar {...props} image={processedImageUrl} />;
}
