import { Menu } from 'antd';
import { Box, Button } from 'glints-aries';
import { Badge } from 'glints-aries/es/@next';
import styled from 'styled-components';

import { dropdownMenuItemStyles } from './NavbarCommon.sc';
import { NavbarItem } from './NavbarItem';

export const DropdownLink = styled.a.attrs({
  className: 'ant-dropdown-link',
})`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const DropdownLinkLabel = styled.span`
  margin-right: 5px;
`;

export const DropdownMenu = styled(Menu)`
  ${dropdownMenuItemStyles}
`;

export const Messages = styled(Box)`
  a {
    color: #656565;
  }

  svg {
    width: 21px;
    height: 21px;
  }
`;

export const StyledNavbarItem = styled(NavbarItem)`
  a {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export const StyledButton = styled(Button)`
  button {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export const StyledBadge = styled(Badge).attrs({
  status: 'enticing',
})`
  text-transform: uppercase;
  span {
    font-size: 10px;
    letter-spacing: 0.2px;
  }
`;
