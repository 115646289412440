import { handleActions } from 'redux-actions';

import { TrackingService } from '../../common/tracking/trackingService';
import { Actions } from './Actions';

const initialState = {
  sendToken: {
    loading: false,
    error: null,
    success: false,
  },

  verifyEmail: {
    loading: false,
    error: null,
    success: false,
  },
};

export default handleActions(
  {
    [Actions.SEND_TOKEN_REQUEST]: state => ({
      ...state,
      sendToken: {
        ...initialState.sendToken,
        loading: true,
      },
    }),
    [Actions.SEND_TOKEN_SUCCESS]: state => ({
      ...state,
      sendToken: {
        ...initialState.sendToken,
        success: true,
      },
    }),
    [Actions.SEND_TOKEN_FAILURE]: (state, action) => ({
      ...state,
      sendToken: {
        ...initialState.sendToken,
        error: action.payload.response,
      },
    }),
    [Actions.VERIFY_EMAIL_REQUEST]: state => ({
      ...state,
      verifyEmail: {
        ...initialState.verifyEmail,
        loading: true,
      },
    }),
    [Actions.VERIFY_EMAIL_SUCCESS]: state => {
      TrackingService.logEvent('Employer Email Verification');
      return {
        ...state,
        verifyEmail: {
          ...initialState.verifyEmail,
          success: true,
        },
      };
    },
    [Actions.VERIFY_EMAIL_FAILURE]: (state, action) => ({
      ...state,
      verifyEmail: {
        ...initialState.verifyEmail,
        error: action.error.response,
      },
    }),
  },
  initialState
);
