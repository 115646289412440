import React from 'react';
import { Flex } from 'glints-aries';
import { Banner } from 'glints-aries/es/@next';

import { useRebuildComponentOnChannelEvent } from '../../hooks';
import { CardContainer } from '../Styled';
import CandidateCV from './CandidateCV';

const AutoShareCVMessageContent = () => {
  useRebuildComponentOnChannelEvent('channel.updated');

  return (
    <CardContainer>
      <Banner dismissable={false} status="info" iconName="ri-information-line">
        <Flex flexDirection="column" style={{ gap: 8 }}>
          <CandidateCV />
        </Flex>
      </Banner>
    </CardContainer>
  );
};

export default AutoShareCVMessageContent;
