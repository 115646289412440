import React from 'react';
import { Button, ButtonProps, Typography } from 'glints-aries/es/@next';
import styled from 'styled-components';

import { useIsMobileVersion } from '../../hooks';

const Styled = {
  MobileButton: styled(Button)`
    flex-direction: column;
    width: 100%;
    height: auto;
    border: 0;
    box-shadow: none;
  `,
};

type ChannelHeaderMenuButtonProps = ButtonProps & {
  disableTypography?: boolean;
};

const ChannelHeaderMenuButton = ({
  children,
  disableTypography,
  ...props
}: ChannelHeaderMenuButtonProps) => {
  const isMobileVersion = useIsMobileVersion('ChannelHeaderMenus');
  const ResponsiveButton = isMobileVersion ? Styled.MobileButton : Button;
  const btnTypographyVariant = isMobileVersion ? 'subtitle2' : 'button';

  return (
    <ResponsiveButton {...props}>
      <Choose>
        <When condition={disableTypography}>{children}</When>
        <Otherwise>
          <Typography variant={btnTypographyVariant}>{children}</Typography>
        </Otherwise>
      </Choose>
    </ResponsiveButton>
  );
};

export default ChannelHeaderMenuButton;
