import { uniq } from 'lodash-es';

const DEFAULT_LANGUAGE = 'en';
export const createAcceptLanguageHeader = (
  configuredLanguage: string | undefined
): string => {
  const languages = uniq(
    [
      configuredLanguage || DEFAULT_LANGUAGE,
      ...window.navigator.languages,
      window.navigator.language,
    ].filter(Boolean)
  );

  // See what q-values are here: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept-Language
  const addQvalue = (lang: string, index: number) =>
    index === 0 ? lang : `${lang};q=0.${9 - index}`;

  return languages.map(addQvalue).join(',');
};
