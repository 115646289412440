import { handleActions } from 'redux-actions';

import { Actions } from './Actions';

export const initialState = {
  error: null,
  loading: false,
  success: false,

  facebook: {
    isOpen: false,
    loading: false,
    success: false,
    error: null,
  },

  linkedIn: {
    csrfToken: null,
    isOpen: false,
    loading: false,
    success: false,
    error: null,
  },
};

export default handleActions(
  {
    [Actions.CREATE_USER_REQUEST]: state => ({
      ...state,
      error: null,
      loading: true,
      success: false,
    }),
    [Actions.CREATE_USER_SUCCESS]: state => ({
      ...state,
      error: null,
      loading: false,
      success: true,
    }),
    [Actions.CREATE_USER_FAILURE]: (state, action) => ({
      ...state,
      error: action.payload.response,
      loading: false,
      success: false,
    }),
    [Actions.RESET_SIGN_UP_STATE]: () => initialState,
    [Actions.OPEN_FACEBOOK_DIALOG]: state => ({
      ...state,
      facebook: {
        ...state.facebook,
        isOpen: true,
      },
    }),
    [Actions.CLOSE_FACEBOOK_DIALOG]: state => ({
      ...state,
      facebook: {
        ...state.facebook,
        isOpen: false,
      },
    }),
    [Actions.OPEN_LINKEDIN_DIALOG]: (state, action) => ({
      ...state,
      linkedIn: {
        ...state.linkedIn,
        isOpen: true,
        csrfToken: action.payload.csrfToken,
      },
    }),
    [Actions.CLOSE_LINKEDIN_DIALOG]: state => ({
      ...state,
      linkedIn: {
        ...state.linkedIn,
        isOpen: false,
      },
    }),
    [Actions.SIGN_UP_WITH_FACEBOOK_REQUEST]: state => ({
      ...state,
      facebook: {
        ...state.facebook,
        loading: true,
        success: false,
        error: null,
      },
    }),
    [Actions.SIGN_UP_WITH_FACEBOOK_SUCCESS]: state => ({
      ...state,
      facebook: {
        ...state.facebook,
        loading: false,
        success: true,
        error: null,
      },
    }),
    [Actions.SIGN_UP_WITH_FACEBOOK_FAILURE]: (state, action) => ({
      ...state,
      facebook: {
        ...state.facebook,
        loading: false,
        success: false,
        error: action.payload,
      },
    }),
    [Actions.SIGN_UP_WITH_LINKEDIN_REQUEST]: state => ({
      ...state,
      linkedIn: {
        ...state.linkedIn,
        loading: true,
        success: false,
        error: null,
      },
    }),
    [Actions.SIGN_UP_WITH_LINKEDIN_SUCCESS]: state => ({
      ...state,
      linkedIn: {
        ...state.linkedIn,
        loading: false,
        success: true,
        error: null,
      },
    }),
    [Actions.SIGN_UP_WITH_LINKEDIN_FAILURE]: (state, action) => ({
      ...state,
      linkedIn: {
        ...state.linkedIn,
        loading: false,
        success: false,
        error: action.payload,
      },
    }),
  },
  initialState
);
