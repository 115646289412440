import { useSelector } from 'react-redux';

import { useGlintsVIPMembershipInfo } from '../../components/GlintsVIPMembershipInfo/hooks/useGlintsVIPMembershipInfo';
import { UnleashFeatureNames } from '../../modules/Unleash/featureNames';
import { getFeatureVariant } from '../../selectors/features';
import { countryCodeIsIndonesia } from '../helpers/countryCodeHelper';
import { useGetSessionCompany } from './useGetSessionCompany';

type Params = {
  skipVIPMembershipCheck?: boolean;
};

type FeatureVariant = 'enabled' | 'disabled' | 'ignored';

export default function useIsProfileLimitAccessEnabled(
  params?: Params
): boolean {
  const { sessionCompany } = useGetSessionCompany();
  const { vipMembershipInfo } = useGlintsVIPMembershipInfo();
  const variant: FeatureVariant | undefined = useSelector(state =>
    getFeatureVariant(state, UnleashFeatureNames.allFullProfileAccess)
  );

  const vipMembershipCheck = params?.skipVIPMembershipCheck
    ? true
    : !vipMembershipInfo.isActive;

  return Boolean(
    variant === 'enabled' &&
      sessionCompany &&
      countryCodeIsIndonesia(sessionCompany.CountryCode) &&
      vipMembershipCheck
  );
}
