import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  moveToInCommunication: {
    id: 'interactive-move-to-in-communication',
    defaultMessage: 'Move to “In Communication”',
  },
  noWhatsAppNumber: {
    id: 'text-no-whatsapp-number-found',
    defaultMessage: 'No WhatsApp Number Found',
  },
  toContactMoveOrChat: {
    id: 'text-to-contact-candidate-whatsapp',
    defaultMessage:
      'To contact this candidate on WhatsApp, either move or chat with them',
  },
  whatsAppNotIncluded: {
    id: 'text-candidate-not-included-whatsapp',
    defaultMessage:
      'Candidate has not included a WhatsApp number. Kindly request this information from them',
  },
  whatsApp: {
    id: 'text-channel-whatsapp',
    defaultMessage: 'WhatsApp',
  },
  whatsAppCandidate: {
    id: 'interactive-whatsapp-candidate',
    defaultMessage: 'WhatsApp Candidate',
  },
});
