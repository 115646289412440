import { handleActions } from 'redux-actions';

import { Actions } from './Actions';

const initialState = {
  update: {
    loading: false,
    error: null,
    success: false,
  },
};

export default handleActions(
  {
    [Actions.UPDATE_APPLICATION_STATUS_REQUEST]: state => ({
      ...state,
      update: {
        ...initialState.update,
        loading: true,
      },
    }),
    [Actions.UPDATE_APPLICATION_STATUS_SUCCESS]: state => ({
      ...state,
      update: {
        ...initialState.update,
        success: true,
      },
    }),
    [Actions.UPDATE_APPLICATION_STATUS_FAILURE]: (state, action) => ({
      ...state,
      update: {
        ...initialState.update,
        error: action.error,
      },
    }),
  },
  initialState
);
