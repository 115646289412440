import { useEffect } from 'react';
import { includes } from 'lodash-es';
import { useHistory, useLocation } from 'react-router';

import { useGetSessionCompany } from '../../common/hooks/useGetSessionCompany';
import { ROUTES } from '../../common/routes';
import { reportRedirectStatuses } from './constants';

const reportRouteRegex = new RegExp(`'^/${ROUTES.companyReportStatus}/?$'`);

export const CompanyReportStatusRedirect = () => {
  const { sessionCompany } = useGetSessionCompany();
  const { pathname } = useLocation();
  const history = useHistory();

  const companyStatus = sessionCompany?.status;

  useEffect(() => {
    if (
      !includes(reportRedirectStatuses, companyStatus) ||
      pathname.match(reportRouteRegex)
    ) {
      return;
    }
    history.replace(`/${ROUTES.companyReportStatus}`);
  }, [companyStatus, history, pathname]);

  return null;
};
