import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';

import config from './config';

type Props = {
  title: string;
  description?: string;
  siteName?: string;
  skipTwitterSite?: boolean;
  prerenderStatusCode?: string;
  image?: string;
  noIndex?: boolean;
  location?: string;
  canonical?: string;
};

type Metadata = {
  title: string;
  description?: string;
  charSet: string;
  name: Record<string, string | undefined>;
  property: Record<string, string | undefined>;
  canonicalLink?: string | null;
  hrefLang: Record<string, string>;
};

const MetaComponent: React.FC<Props> = (props: Props) => {
  const {
    title,
    siteName,
    skipTwitterSite,
    description,
    image,
    prerenderStatusCode,
    location,
    noIndex,
    canonical,
  } = props;

  const constructMetadata = (): Metadata => {
    const { origin, pathname, hostname } = window.location;

    const canonicalPath = canonical
      ? canonical !== '/'
        ? origin + canonical
        : origin
      : null;

    // Removing the region from the url. Example: .sg / .id / .vn
    const baseUrl =
      hostname === 'localhost'
        ? origin
        : origin.split('.').slice(0, -1).join('.');

    const currentPath = pathname !== '/' ? pathname : '';

    const metadata = {
      title,
      description,
      charSet: 'utf-8',
      name: {
        viewport: 'width=device-width, initial-scale=1',
        'prerender-status-code': prerenderStatusCode,
        keywords: 'glints, employer, internship, jobs',
      },
      property: {
        'fb:app_id': config.FACEBOOK_APPID,
        'og:title': title,
        'og:site_name': siteName ?? title,
        'og:description': description,
        'og:type': 'website',
        'og:image': image,
        'og:image:width': '1200',
        'og:image:height': '630',
        'og:url': location,
        'twitter:title': title,
        'twitter:description': description,
        'twitter:image': image,
        'twitter:url': location,
        'twitter:card': 'summary',
      },
      canonicalLink: canonicalPath,
      hrefLang: {
        'id-ID': `${baseUrl}.id${currentPath}`,
        'zh-TW': `${baseUrl}.tw${currentPath}`,
        vi: `${baseUrl}.vn${currentPath}`,
        'en-SG': `${baseUrl}.sg${currentPath}`,
        'en-MY': `${baseUrl}.my${currentPath}`,
      },
    };

    if (!skipTwitterSite) {
      (metadata.property as { [key: string]: string })['twitter:site'] =
        '@glintsintern';
    }

    if (noIndex) {
      return { ...metadata, name: { ...metadata.name, robots: 'noindex' } };
    }

    return metadata;
  };

  const {
    title: metaTitle,
    charSet,
    name,
    property,
    canonicalLink,
    hrefLang,
  } = constructMetadata();

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta charSet={charSet} />
      {Object.entries(name).map(([key, value]) => (
        <meta name={key} content={value} key={key} />
      ))}
      {Object.entries(property).map(([key, value]) => (
        <meta property={key} content={value} key={key} />
      ))}
      {canonicalLink && <link rel="canonical" href={canonicalLink} />}
      {Object.entries(hrefLang).map(([key, value]) => (
        <link rel="alternate" hrefLang={key} href={value} key={key} />
      ))}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin={''}
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
};

const GlintsMeta = connect(
  (state: any) => ({
    title: state.meta.title,
    description: state.meta.description,
    image: state.meta.image,
    prerenderStatusCode: state.meta.prerenderStatusCode,
    noIndex: state.meta.noIndex,
    location: state.meta.location,
    canonical: state.meta.canonical,
    siteName: state.meta.siteName,
    skipTwitterSite: state.meta.skipTwitterSite,
  }),
  null
)(MetaComponent);

export default GlintsMeta;
