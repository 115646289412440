import React, { CSSProperties } from 'react';
import { Flex, Greyscale } from 'glints-aries';
import { Icon } from 'glints-aries/es/@next';
import { Blue } from 'glints-aries/es/@next/utilities/colors';

import { ClippedTypography } from './Styled';

type Props = {
  title: string;
  style?: CSSProperties;
};

const MessagingApplicationJobTitle = ({ title, style }: Props) => (
  <Flex
    p={4}
    alignItems="center"
    style={{
      gap: 4,
      borderRadius: 4,
      backgroundColor: Blue.S08,
      ...style,
    }}
  >
    <Icon
      name="ri-briefcase-fill"
      width={16}
      height={16}
      fill={Greyscale.devilsgrey}
    />
    <ClippedTypography variant="subtitle2">{title}</ClippedTypography>
  </Flex>
);

export default MessagingApplicationJobTitle;
