import React from 'react';
import { Greyscale, PrimaryColor } from 'glints-aries';
import { Badge, Icon, Typography } from 'glints-aries/es/@next';
import styled from 'styled-components';

import clampedNum from '../../common/clamped';

const Styled = {
  Container: styled.div`
    position: relative;
    line-height: 0;
    padding: 12px 0;
    width: 28px;
    height: auto;
  `,
  Badge: styled(Badge)`
    background-color: ${PrimaryColor.glintsred};
    border: 2px solid ${Greyscale.white};
    position: absolute;
    top: 5%;
    right: -50%;
    padding: 2px 7px;
  `,
};

type MobileChatButtonProps = {
  unreadCount?: number;
};

const MobileChatButton = ({ unreadCount }: MobileChatButtonProps) => {
  const clampedUnreadCount = clampedNum({
    count: unreadCount,
    max: 99,
  });

  return (
    <Styled.Container>
      <Icon name="ri-message-line" fill={Greyscale.devilsgrey} />
      <If condition={clampedUnreadCount}>
        <Styled.Badge>
          <Typography variant="overline" color={Greyscale.white}>
            {clampedUnreadCount}
          </Typography>
        </Styled.Badge>
      </If>
    </Styled.Container>
  );
};

export default MobileChatButton;
