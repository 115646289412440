import { useCallback } from 'react';

import { useAxiosWithAPI } from './useAxiosWithAPI';

interface S3DownloadResponse {
  url: string;
}

const useGetS3FileUrl = () => {
  const axios = useAxiosWithAPI();
  const getS3Url = useCallback(
    async (key: string, label: string) => {
      try {
        const data = await axios.get<S3DownloadResponse>('/s3/download', {
          params: {
            key,
            label,
            redirect: 'false',
          },
        });

        return data.data.url as string;
      } catch (error) {
        return null;
      }
    },
    [axios]
  );

  return getS3Url;
};

export default useGetS3FileUrl;
