import React, { useState } from 'react';
import { Tag, Typography } from 'glints-aries/es/@next';
import { Blue, Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space12 } from 'glints-aries/es/@next/utilities/spacing';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import TextAreaField from '../../../../Fields/TextAreaField';
import {
  ApplicationRejectionMessageType,
  RejectionReasonPreferenceFormState,
  selectableApplicationRejectionMessageTypes,
} from '../../types';
import {
  applicationRejectionMessageTypeMessages,
  MESSAGE_INPUT_MAX_LENGTH,
} from '../consts';

const TagsWrapper = styled.div`
  display: flex;
  gap: ${space12};
  flex-wrap: wrap;
  margin-bottom: ${space12};
`;

type MessageTypeTagProps = {
  type: ApplicationRejectionMessageType;
  selectedMessageType: ApplicationRejectionMessageType;
  setSelectedMessageType: (type: ApplicationRejectionMessageType) => void;
};

const MessageTypeTag = ({
  type,
  selectedMessageType,
  setSelectedMessageType,
}: MessageTypeTagProps) => {
  const message = applicationRejectionMessageTypeMessages[type];
  if (!message) return null;
  const selected = type === selectedMessageType;

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setSelectedMessageType(type);
  };

  return (
    <Tag
      key={type}
      onClick={handleClick}
      style={{
        backgroundColor: selected ? Blue.S100 : Blue.S08,
      }}
    >
      <Typography
        variant="subtitle2"
        color={selected ? Neutral.B100 : Blue.S99}
      >
        <FormattedMessage {...applicationRejectionMessageTypeMessages[type]} />
      </Typography>
    </Tag>
  );
};

export default function CustomMessageTagsAndInput() {
  const { formatMessage } = useIntl();
  const [selectedMessageType, setSelectedMessageType] =
    useState<ApplicationRejectionMessageType>(
      ApplicationRejectionMessageType.NO_SKILL_SET_FIT
    );

  const { control } = useFormContext<RejectionReasonPreferenceFormState>();

  const rejectionMessages = useWatch({
    control,
    name: 'rejectionMessages',
  });

  const selectedMessageTypeIndex = rejectionMessages.findIndex(
    message => message.messageType === selectedMessageType
  );

  const messageInputName =
    `rejectionMessages.${selectedMessageTypeIndex}.message` as const;

  return (
    <div style={{ paddingBottom: space12 }}>
      <TagsWrapper>
        {selectableApplicationRejectionMessageTypes.map(type => (
          <MessageTypeTag
            key={type}
            type={type}
            selectedMessageType={selectedMessageType}
            setSelectedMessageType={setSelectedMessageType}
          />
        ))}
      </TagsWrapper>
      <Controller
        key={messageInputName}
        name={messageInputName}
        control={control}
        render={({ field }) => (
          <TextAreaField
            maxLength={MESSAGE_INPUT_MAX_LENGTH}
            canExceedMaxLength={false}
            {...field}
            subLabel={formatMessage({
              id: 'text-message',
              defaultMessage: 'Message',
            })}
          />
        )}
      />
    </div>
  );
}
