import { kebabCase } from 'lodash-es';

export const SwrCacheKeys = {
  applicationDetailsShortKey: 'get-application-details',
  applicationDetails: (jobId: string, applicationId: string) =>
    `${SwrCacheKeys.applicationDetailsShortKey}-${jobId}-${applicationId}`,
  jobApplicationsShortKey: 'get-job-applications',
  jobApplications: (jobId: string) =>
    `${SwrCacheKeys.jobApplicationsShortKey}-${jobId}`,
  getTalents: 'get-talents',
  talentDetails: (talentId: string) => `get-talent-details-${talentId}`,
  companyJobStatusBreakdown: 'get-company-job-status-breakdown',
  getRecommendedHierarchicalJobCategory: (suffix?: string) =>
    suffix
      ? `get-recommended-hierarchical-job-category-${kebabCase(suffix)}`
      : 'get-recommended-hierarchical-job-category',
};
