import React from 'react';
import { FormattedMessage } from 'react-intl';

import { COUNTRY_DOMAINS } from '../../common/constants';

export const TABS = {
  basic: {
    label: (
      <FormattedMessage
        id="interactive-basic-information"
        defaultMessage="Basic Information"
      />
    ),
    key: 'basic',
    to: '/settings/basic',
  },
  security: {
    label: (
      <FormattedMessage
        id="interactive-sign-in-security"
        defaultMessage="Sign In & Security"
      />
    ),
    key: 'security',
    to: '/settings/security',
  },
  linked: {
    label: (
      <FormattedMessage
        id="interactive-linked-accounts"
        defaultMessage="Linked Accounts"
      />
    ),
    key: 'linked',
    to: '/settings/linked',
  },
  notifications: {
    label: (
      <FormattedMessage
        id="interactive-notification-preferences"
        defaultMessage="Notification Preferences"
      />
    ),
    key: 'notifications',
    to: '/settings/notifications',
  },
  company: {
    label: (
      <FormattedMessage
        id="interactive-company-profile"
        defaultMessage="Company Profile"
      />
    ),
    key: 'company',
    to: '/settings/company',
  },
  rejection: {
    label: (
      <FormattedMessage
        id="text-rejection-method"
        defaultMessage="Rejection Method"
      />
    ),
    key: 'rejection',
    to: '/settings/rejection',
  },
  help: {
    label: (
      <FormattedMessage
        id="interactive-help-and-support"
        defaultMessage="Help & Support"
      />
    ),
    key: 'help',
    to: '/settings/help',
  },
};

export const LANG_OPTIONS = {
  en: {
    value: 'en',
    label: (
      <FormattedMessage
        id="modules.Settings.Constants.english"
        defaultMessage="English"
      />
    ),
  },
  id: {
    value: 'id',
    label: (
      <FormattedMessage
        id="modules.Settings.Constants.bahasa.indonesia"
        defaultMessage="Bahasa Indonesia"
      />
    ),
  },
  zh: {
    value: 'zh',
    label: (
      <FormattedMessage
        id="modules.Settings.Constants.traditional.chinese"
        defaultMessage="Traditional Chinese"
      />
    ),
  },
  vi: {
    value: 'vi',
    label: (
      <FormattedMessage
        id="modules.Settings.Constants.vietnamese"
        defaultMessage="Vietnamese"
      />
    ),
  },
};

export const LANG_OPTIONS_BY_COUNTRY = {
  [COUNTRY_DOMAINS.id]: [LANG_OPTIONS.id, LANG_OPTIONS.en],
  [COUNTRY_DOMAINS.hk]: [LANG_OPTIONS.zh, LANG_OPTIONS.en],
  [COUNTRY_DOMAINS.tw]: [LANG_OPTIONS.zh, LANG_OPTIONS.en],
  [COUNTRY_DOMAINS.vn]: [LANG_OPTIONS.vi, LANG_OPTIONS.en],
  default: [LANG_OPTIONS.en, LANG_OPTIONS.id, LANG_OPTIONS.vi, LANG_OPTIONS.zh],
};

export const DEFAULT_LANG_OPTIONS = {
  [COUNTRY_DOMAINS.id]: 'id',
  [COUNTRY_DOMAINS.sg]: 'en',
  [COUNTRY_DOMAINS.hk]: 'zh',
  [COUNTRY_DOMAINS.tw]: 'zh',
  [COUNTRY_DOMAINS.vn]: 'vi',
  default: 'en',
};

export const IDENTITY_TYPES = {
  FACEBOOK: 'FACEBOOK',
  LINKEDIN: 'LINKEDIN',
};

export const ERRORS = {
  status409: {
    IdentityExists: 'The identity already exists.',
  },
  status400: {
    EmailExists: 'email must be unique',
    InvalidProviderParams: 'Authentication with external provider failed',
    InvalidEmailVerificationToken:
      'The specified email verification token is invalid or has expired.',
    InvalidIdOrEmail: 'No user with the specified id or email address exists.',
    MissingIdOrEmail: 'Must specified either user id or email.',
  },
};

export const NOTIFICATION_CHANNELS = {
  email: 'email',
  whatsapp: 'whatsapp',
};

export const NOTIFICATION_CATEGORIES = {
  recommendations: {
    label: (
      <FormattedMessage
        id="modules.Settings.Constants.recommendations"
        defaultMessage="Recommendations"
      />
    ),
    talentHuntOnly: true,
    channels: {
      email: true,
    },
  },
  applications: {
    label: (
      <FormattedMessage
        id="modules.Settings.Constants.applications"
        defaultMessage="Applications"
      />
    ),
    talentHuntOnly: false,
    channels: {
      email: true,
    },
  },
};
