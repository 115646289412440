import { createSelector } from 'reselect';

import { getCompanies } from './entities';

export const getCurrentCompanyId = state => state.company.value;
export const getCurrentCompanyLoading = state => state.company.loading;
export const getCompanyUsers = state => state.company.users;

export const getCurrentCompany = createSelector(
  [getCurrentCompanyId, getCompanies],
  (id, companies) => companies[id]
);
