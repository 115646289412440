import React, { useState } from 'react';
import { Box, Flex, Greyscale, ScreenSize } from 'glints-aries';
import {
  Banner,
  Button,
  Icon,
  Modal,
  PrimaryButton,
  Select,
  SelectProps,
  Typography,
} from 'glints-aries/es/@next';
import { difference, uniq } from 'lodash-es';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { useChannelStateContext } from 'stream-chat-react';
import styled, { createGlobalStyle } from 'styled-components';

import useCompanyUsers from '../../../../common/hooks/useCompanyUsers';
import { getStreamUserName } from '../../../../common/stream';
import { ProfilePicture } from '../../../../components/GlintsPicture';
import {
  useDeleteJobNotificationReceiverById,
  useGetJobNotificationReceivers,
} from '../../graphql';
import { getChannelApplicationMetadataV2 } from '../../helper';
import { useIsMobileVersion } from '../../hooks';
import { useSyncStreamUser } from '../../syncStreamUser';
import ResponsiveButtonGroup from '../ResponsiveButtonGroup';
import {
  memberJoinedChatMessage,
  memberLeftChatMessage,
  messages,
  RemoveChannelMemberAction,
} from './constants';
import RemoveMemberDropdown from './RemoveMemberDropdown';

type ChannelMembersModalProps = {
  onClose: () => void;
};

const Styled = {
  ModalZIndexOverride: createGlobalStyle`
    div[data-testid="modal-wrapper"] {
      z-index: 399;
    }
  `,
  Modal: styled(Modal)`
    max-width: 620px;
    width: 100%;

    .modal-content {
      width: 100%;
    }

    @media (max-width: ${ScreenSize.desktopM - 1}px) {
      max-width: none;
    }
  `,
  ProfilePicture: styled(ProfilePicture)`
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 50%;
  `,
  RemoveButton: styled(Button)`
    padding: 8px 12px;
  `,
};

const ChannelMembersModal = ({ onClose }: ChannelMembersModalProps) => {
  const { formatMessage } = useIntl();
  const isMobileVersion = useIsMobileVersion();
  const location = useLocation();
  const {
    channel,
    channelCapabilities,
    members = {},
  } = useChannelStateContext();
  const [deleteJobNotificationReceiverById] =
    useDeleteJobNotificationReceiverById();
  const metadata = getChannelApplicationMetadataV2(channel);
  const memberIds = Object.keys(members).filter(
    memberId => memberId !== metadata.glints_application_applicant_id
  );
  const [syncInProgress, setSyncInProgress] = useState(false);
  const [stagedMemberIds, setStagedMemberIds] = useState<string[]>(memberIds);
  const [removeMemberAction, setRemoveMemberAction] = useState<
    Record<string, RemoveChannelMemberAction>
  >({});
  const syncStreamUser = useSyncStreamUser();
  const companyUsersResult = useCompanyUsers(
    metadata.glints_company_id,
    {
      where: JSON.stringify({
        '$User.id$': uniq([...memberIds, ...stagedMemberIds]),
      }),
    },
    {
      keepPreviousData: true,
    }
  );
  const companyUsers = companyUsersResult.data?.data ?? [];
  const getJobNotificationReceiversResult = useGetJobNotificationReceivers({
    fetchPolicy: 'cache-and-network',
    variables: {
      jobId: metadata.glints_job_id,
    },
  });
  const jobNotificationReceivers =
    getJobNotificationReceiversResult.data?.getJobNotificationReceivers ?? [];
  const options = jobNotificationReceivers.map(({ receiver }) => ({
    value: receiver.id,
    label: getStreamUserName(receiver),
  }));
  const filteredOptions = options.filter(
    option => !stagedMemberIds.includes(option.value)
  );
  const canManageChannelMembers = channelCapabilities['update-channel-members'];
  const sortedStagedMemberIds = stagedMemberIds.reduce(
    (memberIds, memberId) => {
      if (memberId === metadata.glints_job_creator_id) {
        return [memberId, ...memberIds];
      }
      return [...memberIds, memberId];
    },
    [] as string[]
  );

  const handleSelectMember: SelectProps['onSelect'] = async ({
    value: userId,
  }) => {
    setSyncInProgress(true);
    await syncStreamUser({
      variables: {
        data: {
          id: userId,
        },
      },
    });
    setStagedMemberIds(ids => uniq([...ids, userId]));
    setSyncInProgress(false);
  };

  const makeRemoveStagedMemberHandler = (userId: string) => () =>
    setStagedMemberIds(ids => ids.filter(id => id !== userId));

  const makeRemoveMemberDropdownHandler =
    (userId: string) => (action: RemoveChannelMemberAction) => {
      setStagedMemberIds(ids => ids.filter(id => id !== userId));
      setRemoveMemberAction(value => ({
        ...value,
        [userId]: action,
      }));
    };

  const getSystemMessage = (
    userIds: string[],
    systemMessage: Record<number, MessageDescriptor | undefined>,
    fallbackMessage: MessageDescriptor
  ) => {
    const [firstMember, secondMember] = [
      companyUsers.find(user => user.UserId === userIds[0]),
      companyUsers.find(user => user.UserId === userIds[1]),
    ];

    const [firstMemberSalutation, secondMemberSalutation] = [
      firstMember ? getStreamUserName(firstMember.links.user) : undefined,
      secondMember ? getStreamUserName(secondMember.links.user) : undefined,
    ];

    const [message, messageParams] = [
      systemMessage[userIds.length] ?? fallbackMessage,
      {
        memberName: firstMemberSalutation,
        firstMemberName: firstMemberSalutation,
        secondMemberName: secondMemberSalutation,
        otherCount: userIds.length > 2 ? userIds.length - 2 : undefined,
      },
    ];

    return {
      text: formatMessage(message, messageParams),
      glints_i18n_message_props: {
        id: message.id,
        default_message: message.defaultMessage,
        values: messageParams,
      },
    };
  };

  const handleSave = async () => {
    setSyncInProgress(true);

    const [membersToAdd, membersToRemove] = [
      difference(stagedMemberIds, memberIds),
      difference(memberIds, stagedMemberIds),
    ];

    if (membersToAdd.length) {
      const systemMessage = getSystemMessage(
        membersToAdd,
        memberJoinedChatMessage,
        messages.multiMemberJoinedChatPlural
      );

      await channel.addMembers(membersToAdd, systemMessage);
    }

    if (membersToRemove.length) {
      const systemMessage = getSystemMessage(
        membersToRemove,
        memberLeftChatMessage,
        messages.multiMemberLeftChatPlural
      );

      const membersToRemoveFromPTN = membersToRemove.filter(userId => {
        const action = removeMemberAction[userId];
        return action === RemoveChannelMemberAction.FROM_CHAT_AND_PTN;
      });

      await Promise.all(
        membersToRemoveFromPTN.map(userId => {
          const jobNotificationReceiver = jobNotificationReceivers.find(
            ({ receiver }) => receiver.id === userId
          );

          if (!jobNotificationReceiver) return;

          return deleteJobNotificationReceiverById({
            variables: {
              id: jobNotificationReceiver.id,
            },
          });
        })
      );

      await channel.removeMembers(membersToRemove, systemMessage);
    }

    setSyncInProgress(false);
    onClose();
  };

  const disableSelection = !options.filter(
    option => option.value !== metadata.glints_job_creator_id
  ).length;

  return (
    <Styled.Modal
      isOpen={true}
      showCloseButton={true}
      header={formatMessage(messages.title)}
      customActions={
        <If condition={canManageChannelMembers}>
          <ResponsiveButtonGroup>
            <Button onClick={onClose}>
              <Typography variant="button">
                <FormattedMessage
                  id="button-text_cancel"
                  defaultMessage="Cancel"
                />
              </Typography>
            </Button>
            <PrimaryButton onClick={handleSave} disabled={syncInProgress}>
              <Typography variant="button">
                <FormattedMessage id="interactive-save" defaultMessage="Save" />
              </Typography>
            </PrimaryButton>
          </ResponsiveButtonGroup>
        </If>
      }
      onClose={onClose}
    >
      <If condition={canManageChannelMembers}>
        <Banner
          status="info"
          dismissable={false}
          style={{ minHeight: 'fit-content' }}
        >
          <Typography variant="body1" as="span">
            <FormattedMessage
              id="text-only-ptn-member-added-chat"
              defaultMessage="Only members from People to Notify can be added."
            />
          </Typography>
          &nbsp;
          <Link
            to={`/job/edit/${metadata.glints_job_id}?nextPath=${location.pathname}${location.search}&tab=1`}
          >
            <Typography variant="body2" as="span">
              <FormattedMessage
                id="interactive-manage"
                defaultMessage="Manage"
              />
            </Typography>
          </Link>
        </Banner>
        <Box my={24}>
          <Select
            width={isMobileVersion ? '100%' : '580px'}
            label={formatMessage(messages.addTeamMembers)}
            placeholder={formatMessage(messages.searchByName)}
            searchable={true}
            selectedValues={[]}
            options={filteredOptions}
            onSelect={handleSelectMember}
            disabled={disableSelection}
            helpText={
              <If condition={disableSelection}>
                <Typography variant="subtitle2" color={Greyscale.devilsgrey}>
                  <FormattedMessage
                    id="text-add-member-disabled-helper-text"
                    defaultMessage='No members in "People to Notify & Chat" currently. You can add them by editing the job, then click "Enhancement"'
                  />
                </Typography>
              </If>
            }
          />
        </Box>
      </If>
      <Flex flexDirection="column" style={{ gap: 24 }}>
        {sortedStagedMemberIds.map(memberId => {
          const companyUser = companyUsers.find(
            ({ UserId }) => UserId === memberId
          );
          if (!companyUser) {
            return null;
          }

          const { user } = companyUser.links;
          const userSalutation = getStreamUserName(user);

          return (
            <Flex
              key={user.id}
              justifyContent="space-between"
              alignItems="center"
              style={{
                gap: 8,
              }}
            >
              <Flex style={{ gap: 12, overflow: 'hidden' }}>
                <Styled.ProfilePicture
                  userId={user.id}
                  profilePic={user.profilePic}
                  alt={userSalutation}
                />
                <Flex flexDirection="column" style={{ overflow: 'hidden' }}>
                  <Typography variant="body2">{userSalutation}</Typography>
                  <Typography variant="body1" color={Greyscale.devilsgrey}>
                    {user.email}
                  </Typography>
                </Flex>
              </Flex>
              <If
                condition={
                  canManageChannelMembers &&
                  user.id !== metadata.glints_job_creator_id
                }
              >
                <Choose>
                  <When condition={members[memberId]}>
                    <RemoveMemberDropdown
                      onRemove={makeRemoveMemberDropdownHandler(user.id)}
                    />
                  </When>
                  <Otherwise>
                    <Styled.RemoveButton
                      icon={<Icon name="ri-delete-bin-line" />}
                      iconPosition="left"
                      onClick={makeRemoveStagedMemberHandler(user.id)}
                    >
                      <Typography variant="button">
                        <FormattedMessage
                          id="interactive-remove"
                          defaultMessage="Remove"
                        />
                      </Typography>
                    </Styled.RemoveButton>
                  </Otherwise>
                </Choose>
              </If>
            </Flex>
          );
        })}
      </Flex>
      <Styled.ModalZIndexOverride />
    </Styled.Modal>
  );
};

export default ChannelMembersModal;
