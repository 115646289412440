import 'stream-chat-react/dist/css/index.css';

import React from 'react';
import { useAlert } from 'glints-aries/es/@next';
import { borderRadius12 } from 'glints-aries/es/@next/utilities/borderRadius';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space16 } from 'glints-aries/es/@next/utilities/spacing';
import { defineMessages, useIntl } from 'react-intl';
import { useKeyPressEvent, useMount } from 'react-use';
import { Channel, Chat, Window } from 'stream-chat-react';
import styled from 'styled-components';
import { StringParam, useQueryParam } from 'use-query-params';

import useIsChatV2Enabled from '../../../../common/hooks/useIsChatV2Enabled';
import {
  DefaultStreamChannelTypes,
  useStreamClient,
} from '../../../../common/stream';
import ErrorBoundaryWithCrashReporting from '../../../../components/Error/ErrorBoundaryWithCrashReporting';
import {
  trackESCtoClose,
  TrackESCtoCloseSource,
} from '../../../EmployerATSV2/components/ApplicationTable/ApplicationDetailsModal/tracking';
import {
  ChannelQueryParam,
  customClasses,
  MessagingUIMode,
} from '../../constants';
import { useIsChatWidgetEnabled, useStreamI18nInstance } from '../../hooks';
import MessagingChannel from '../Channel';
import MessagingChannelContainer from '../ChannelContainer';
import ChannelEmptyPlaceholder from '../ChannelEmptyPlaceholder';
import MessagingDateSeparator from '../DateSeparator';
import HeaderComponent from '../HeaderComponent';
import MessageSystem from '../MessageSystem';
import { CardContainer, StreamUIThemeOverride } from '../Styled';
import useCloseChannelPopupWidget from './useCloseChannelPopupWidget';

const Styled = {
  Container: styled.div`
    position: fixed;
    background-color: ${Neutral.B99};
    bottom: 0;
    right: 40px;
    width: 460px;
    z-index: 101;
    overflow: hidden;
    border: 1px solid ${Neutral.B85};
    border-radius: ${borderRadius12} ${borderRadius12} 0px 0px;
    box-shadow:
      0px 3px 6px -3px rgba(71, 71, 71, 0.08),
      0px 8px 20px 0px rgba(71, 71, 71, 0.2);

    .messaging-chat-message-list {
      max-height: 400px;
    }

    .str-chat__date-separator {
      padding: ${space16} 0;
    }

    ${CardContainer} {
      max-width: 360px;
    }
  `,
};

const ChannelPopupWidgetComponent = () => {
  const client = useStreamClient();
  const i18nInstance = useStreamI18nInstance();
  const isChatWidgetEnabled = useIsChatWidgetEnabled();
  const isChatV2Enabled = useIsChatV2Enabled();
  const [popupChannelId] = useQueryParam(
    ChannelQueryParam.POPUP_CHANNEL_ID,
    StringParam
  );
  const closeChannelPopupWidget = useCloseChannelPopupWidget();

  useKeyPressEvent('Escape', () => {
    if (popupChannelId) {
      trackESCtoClose({
        applicationId: popupChannelId,
        source: TrackESCtoCloseSource.CHAT_WIDGET,
      });
      closeChannelPopupWidget();
    }
  });

  // TODO: [V2-Chat] Handle chat widget
  if (!client || !isChatWidgetEnabled || isChatV2Enabled) return null;

  const channel = popupChannelId
    ? client.channel(DefaultStreamChannelTypes.MESSAGING, popupChannelId)
    : undefined;

  return (
    <Chat
      client={client}
      customClasses={customClasses}
      i18nInstance={i18nInstance}
    >
      <If condition={channel}>
        <Styled.Container data-cy="channel-popup-widget">
          <Channel
            channel={channel}
            DateSeparator={MessagingDateSeparator}
            HeaderComponent={HeaderComponent}
            EmptyPlaceholder={<ChannelEmptyPlaceholder />}
            MessageSystem={MessageSystem}
          >
            <Window>
              <MessagingChannelContainer uiMode={MessagingUIMode.POPUP}>
                <MessagingChannel />
              </MessagingChannelContainer>
            </Window>
          </Channel>
        </Styled.Container>
      </If>
      <StreamUIThemeOverride />
    </Chat>
  );
};

export default function ChannelPopupWidget() {
  return (
    <ErrorBoundaryWithCrashReporting fallback={<OpenErrorAlert />}>
      <ChannelPopupWidgetComponent />
    </ErrorBoundaryWithCrashReporting>
  );
}

const errorMessages = defineMessages({
  title: {
    id: 'text-error',
    defaultMessage: 'Error',
  },
  message: {
    id: 'text-something-went-wrong',
    defaultMessage: 'Something went wrong. Please try again later.',
  },
});

const OpenErrorAlert = () => {
  const { open } = useAlert();
  const { formatMessage } = useIntl();

  useMount(() => {
    open({
      title: formatMessage(errorMessages.title),
      content: formatMessage(errorMessages.message),
      status: 'error',
    });
  });
  return null;
};
