import React from 'react';
import { ApolloError } from '@apollo/client';
import { useMount } from 'react-use';

import useRemoveStateFromLocation from '../../../../common/hooks/useRemoveStateFromLocation';
import useGetErrorFromVIPPurchaseFailedState from './hooks/useGetErrorFromVIPPurchaseFailedState';
import { useGlintsVIPFreeTrialOrPurchaseModal } from './hooks/useGlintsVIPFreeTrialOrPurchaseModal';

type GlintsVIPPurchaseModalContentProps = {
  apolloError: ApolloError;
};

const GlintsVIPPurchaseModalContent = ({
  apolloError,
}: GlintsVIPPurchaseModalContentProps) => {
  const { removeStateFromLocation } = useRemoveStateFromLocation();
  const { openGlintsVIPPurchaseModal } = useGlintsVIPFreeTrialOrPurchaseModal();

  useMount(() => {
    openGlintsVIPPurchaseModal({
      errorBannerProviderInitialValue: apolloError,
      onClose: () => {
        removeStateFromLocation('GlintsVIPPurchaseFailed');
      },
    });
  });

  return null;
};

export default function GlintsVIPPurchaseModalForAfterPayment() {
  const apolloError = useGetErrorFromVIPPurchaseFailedState();
  if (!apolloError) return null;

  return <GlintsVIPPurchaseModalContent apolloError={apolloError} />;
}
