import { Divider } from 'glints-aries/es/@next';
import { space4 } from 'glints-aries/es/@next/utilities/spacing';
import styled, { createGlobalStyle } from 'styled-components';

import { Avatar } from '../NavbarCommon.sc';

export const CustomPopoverStyles = createGlobalStyle`
  .Polaris-Popover__Content {
    max-width: 500px;
  }
  .Polaris-Scrollable--vertical {
    overflow-y: hidden;
  }
`;

export const UserProfilePicture = styled(Avatar)<{
  size?: number;
}>`
  width: ${props => props.size || 48}px;
  height: ${props => props.size || 48}px;
  margin-right: 0;
`;

export const StyledDivider = styled(Divider)`
  margin: ${space4} 0;
`;
