import { handleActions } from 'redux-actions';

import { Actions } from '../actions/schools';

const initialState = {
  loading: false,
  error: null,
  value: [],
};

export default handleActions(
  {
    [Actions.SCHOOLS_REQUEST]: state => ({
      ...state,
      loading: true,
      value: state.value.length ? state.value : [],
      error: null,
    }),
    [Actions.SCHOOLS_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      value: action.response.result,
      error: null,
    }),
    [Actions.SCHOOLS_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      value: [],
      error: action.error,
    }),
  },
  initialState
);
