import React from 'react';
import { Greyscale } from 'glints-aries';
import { Typography } from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';
import { MessageTimestamp, useChatContext } from 'stream-chat-react';

import {
  getContactsExchangeActingUserByStatus,
  getContactsExchangeRequestMessages,
  getGroupContactsExchangeRequestMessages,
} from '../../helper';
import { ContactsRequestAction } from '../../types';

type Props = {
  metadata: ContactsRequestAction;
};

const ContactsExchangeStatusSystemMessage = ({ metadata }: Props) => {
  const { client } = useChatContext();
  const actingUser = getContactsExchangeActingUserByStatus(metadata);
  const isActingUser = client.userID === actingUser?.id;
  const messages =
    actingUser && !isActingUser
      ? getGroupContactsExchangeRequestMessages(metadata)
      : getContactsExchangeRequestMessages(metadata);

  return (
    <Typography
      variant="subtitle2"
      color={Greyscale.devilsgrey}
      style={{ textAlign: 'center' }}
    >
      <MessageTimestamp format="HH:mm" />
      &#65073;
      <FormattedMessage
        {...messages[metadata.action_payload.status]}
        values={{
          memberName: actingUser?.name,
        }}
      />
    </Typography>
  );
};

export default ContactsExchangeStatusSystemMessage;
