import { LocationHelper } from './common/LocationHelper';

const COOKIE_PREFIX = 'glints_config_debug_';

/** Returns a read-only copy of the config */
function getConfig() {
  return window.GlintsEmployersAppConfig;
}

/** Overwrites the config */
function setConfig(config) {
  window.GlintsEmployersAppConfig = config;
}

function serializeKey(key) {
  if (key.match(/[\W]+/)) {
    throw new Error(`Key '${key}' should not contain special characters`);
  }
  return `${COOKIE_PREFIX}${key}`;
}

function deserializeKey(serializedKey) {
  return serializedKey.replace(COOKIE_PREFIX, '');
}

function parseCookie(row) {
  const [cookieKey, cookieValue] = row.split('=');
  const key = deserializeKey(cookieKey);
  const value = JSON.parse(cookieValue);
  return { [key]: value };
}

/** Reads all values that have been persisted to the cookies and applies them
 * to the config */
function readPersistedValues() {
  const overrides = document.cookie
    .split('; ')
    .filter(row => row.startsWith(COOKIE_PREFIX))
    .map(parseCookie)
    .reduce((overrides, keyValue) => ({ ...overrides, ...keyValue }), {});
  const config = getConfig();
  const newConfig = { ...config, ...overrides };
  setConfig(newConfig);
}

/** Sets a config value. If persist is given, the value will be persisted in a
 * session cookie. */
function setConfigValue(key, value, persist) {
  if (persist) {
    const cookieKey = serializeKey(key);
    const cookieValue = JSON.stringify(value);
    document.cookie = `${cookieKey}=${cookieValue}`;

    readPersistedValues();
  } else {
    const config = getConfig();
    const newConfig = { ...config, [key]: value };
    setConfig(newConfig);
  }
}

function getConfigValue(key) {
  const config = getConfig();
  return config[key];
}

const registerDebugUtils = () => {
  readPersistedValues();

  // @ts-ignore
  if (window.Cypress) {
    window.LocationHelper = LocationHelper;
  }

  window.glints = {};
  window.glints.config = {};
  window.glints.config.list = getConfig;
  window.glints.config.set = setConfigValue;
  window.glints.config.get = getConfigValue;
};

registerDebugUtils();
