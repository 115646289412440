import { combineReducers } from '@reduxjs/toolkit';

import { Reducer as formSelectJobCategory } from './SelectJobCategory';
import { Reducer as formSelectSchool } from './SelectSchool';

const reducer = combineReducers({
  formSelectJobCategory,
  formSelectSchool,
});

export default reducer;
