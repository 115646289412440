import React from 'react';
import { Flex, Greyscale, Loading } from 'glints-aries';
import { Icon, Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ApplicationSource } from '../../../common/constants';
import { RenderlessSetApplicationFirstReadAt } from '../../ApplicationDetails/components/CandidateDetails/RenderlessSetApplicationFirstReadAt';
import { useGetApplicationWithChannelAndMetadata } from '../graphql';
import { useIsPopupMode } from '../hooks';
import ApplicantInfo from './ApplicantInfo';
import ApplicationJobInfo from './ApplicationJobInfo';
import { CardContainer } from './Styled';

const HeaderComponent = () => {
  const history = useHistory();
  const isPopupMode = useIsPopupMode();
  const { data, loading, metadata } = useGetApplicationWithChannelAndMetadata();

  const isFromTalentSearch =
    metadata?.application?.source === ApplicationSource.TALENT_SEARCH_WEB;

  if (loading) {
    return (
      <Flex p={16} justifyContent="center">
        <Loading />
      </Flex>
    );
  }

  const application = data?.getApplicationById;

  if (application) {
    const { applicant, job } = application;

    return (
      <Flex flexDirection="column" alignItems="center" style={{ gap: 16 }}>
        <RenderlessSetApplicationFirstReadAt
          applicationId={application.id}
          jobId={job.id}
        />
        <CardContainer>
          <ApplicantInfo applicant={applicant} />
        </CardContainer>
        <Flex
          flexDirection="column"
          alignItems="center"
          data-cy="applied-for-label"
        >
          <Choose>
            <When condition={isFromTalentSearch}>
              <Typography variant="subtitle2" color={Greyscale.devilsgrey}>
                <FormattedMessage
                  id="text-found-talent-search-for"
                  defaultMessage="Found via Talent Search for"
                />
              </Typography>
            </When>
            <Otherwise>
              <Typography variant="subtitle2" color={Greyscale.devilsgrey}>
                <FormattedMessage
                  id="text_applied-for-label"
                  defaultMessage="Applied for"
                />
              </Typography>
            </Otherwise>
          </Choose>
          <Icon
            name="ri-arrow-m-down-fill"
            fill={Neutral.B85}
            color={Neutral.B85}
            width={24}
            height={24}
          />
        </Flex>
        <CardContainer
          {...(!isPopupMode
            ? {
                onClick: () => history.push(`/manage-candidates?jid=${job.id}`),
                style: { cursor: 'pointer' },
              }
            : undefined)}
        >
          <ApplicationJobInfo job={job} />
        </CardContainer>
      </Flex>
    );
  }

  return null;
};

export default HeaderComponent;
