import { handleActions } from 'redux-actions';

import {
  Actions,
  JOIN_COMPANY_FAILURE,
  JOIN_COMPANY_REQUEST,
  JOIN_COMPANY_SUCCESS,
  RECEIVE_COMPANY_USERS,
  REQUEST_COMPANY_USERS,
  TOGGLE_COMPANY_ADMIN_REQUEST_MODAL,
  UPDATE_COMPANY_TAB,
} from '../actions/company';

const initialState = {
  loading: false,
  error: null,
  value: null,
  opportunities: [],

  users: {
    loading: false,
    error: null,
    value: [],
  },

  view: {
    tab: 'INFO',
  },

  pendingUsers: {
    isOpen: false,
    loading: false,
    success: false,
    error: null,
    value: [],
  },
};

export default handleActions(
  {
    [Actions.COMPANY_REQUEST]: state => ({
      ...state,
      loading: true,
      error: null,
      value: null,
      opportunities: [],
    }),
    [Actions.COMPANY_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      value: action.response.result,
      opportunities:
        action.response.entities.company[action.response.result].links.jobs,
    }),
    [Actions.COMPANY_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error
        ? action.response
          ? action.response
          : action.error
        : null,
      value: null,
      opportunities: [],
    }),
    [REQUEST_COMPANY_USERS]: state => ({
      ...state,
      users: {
        ...state.users,
        loading: true,
        error: null,
        value: [],
        count: 0,
      },
    }),
    [RECEIVE_COMPANY_USERS]: (state, action) => ({
      ...state,
      users: {
        ...state.users,
        loading: false,
        error: action.error ? action.payload : null,
        value: action.error ? state.users.value : action.payload.data,
        count: action.error ? state.users.count : action.payload.count,
      },
    }),
    [UPDATE_COMPANY_TAB]: (state, action) => ({
      ...state,
      view: {
        ...state.view,
        tab: action.payload,
      },
    }),
    [TOGGLE_COMPANY_ADMIN_REQUEST_MODAL]: (state, action) => ({
      ...state,
      pendingUsers: {
        ...state.pendingUsers,
        isOpen: action.payload,
      },
    }),
    [JOIN_COMPANY_REQUEST]: state => ({
      ...state,
      pendingUsers: {
        ...state.pendingUsers,
        loading: true,
        success: false,
        error: null,
      },
    }),
    [JOIN_COMPANY_SUCCESS]: state => ({
      ...state,
      pendingUsers: {
        ...state.pendingUsers,
        loading: false,
        success: true,
        error: null,
      },
    }),
    [JOIN_COMPANY_FAILURE]: (state, action) => ({
      ...state,
      pendingUsers: {
        ...state.pendingUsers,
        loading: false,
        success: false,
        error: action.payload,
      },
    }),
  },
  initialState
);
