import React from 'react';

import { getRandomFallbackProfilePicture } from '../../common/asset-manager';
import { USER_TYPES } from '../../common/constants';
import GlintsPicture from './GlintsPicture';

const ProfilePicture: React.FC<ProfilePictureProps> = ({
  userType = USER_TYPES.USER,
  ...otherProps
}) => {
  switch (userType) {
    case USER_TYPES.USER:
      return renderUserProfilePicture(otherProps);
    case USER_TYPES.SYSTEM:
      return renderSystemProfilePicture(otherProps);
    case USER_TYPES.TALENTHUNT_PLUS:
      return renderTalentHuntPlusProfilePicture(otherProps);
    default:
      return null;
  }
};

type ProfilePictureProps = {
  userId: string;
  profilePic?: string | null;
  className?: string;
  sizes?: string;

  /**
   * A profile picture can be shown not only for a regular Glints user, but in
   * special cases where we need to show something done by a system actor or an
   * intelligent agent. This is not to be confused with user role.
   *
   * If SYSTEM or TALENTHUNT_PLUS is specified, a pre-defined URL pointing to an
   * avatar representing the actor is used. In this case, userId and profilePic
   * are ignored.
   */
  userType?: keyof typeof USER_TYPES;

  // TODO: Extend props from GlintsPicture once it has been converted to TS.
  alt?: string;
};

// eslint-disable-next-line react/prop-types
const renderUserProfilePicture: React.FC<
  Omit<ProfilePictureProps, 'userType'>
> = ({ profilePic, userId, ...otherProps }) => {
  if (profilePic) {
    return (
      <GlintsPicture
        name={profilePic}
        assetType="profile-picture"
        fallback={getRandomFallbackProfilePicture(userId)}
        fallbackAssetType="profile-picture-default"
        {...otherProps}
      />
    );
  } else {
    return (
      <GlintsPicture
        name={getRandomFallbackProfilePicture(userId)}
        assetType="profile-picture-default"
        {...otherProps}
      />
    );
  }
};

const renderSystemProfilePicture: React.FC<
  Omit<ProfilePictureProps, 'userType'>
> = otherProps => (
  <GlintsPicture
    name="system.png"
    assetType="profile-picture-default"
    {...otherProps}
  />
);

const renderTalentHuntPlusProfilePicture: React.FC<
  Omit<ProfilePictureProps, 'userType'>
> = otherProps => (
  <GlintsPicture
    name="talenthunt-plus.png"
    assetType="profile-picture-default"
    {...otherProps}
  />
);

export default ProfilePicture;
