import React from 'react';
import { Flex } from 'glints-aries/es';
import { Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space8 } from 'glints-aries/es/@next/utilities/spacing';
import { useSelector } from 'react-redux';

import { userName } from '../../../common/filters';
import RenderBasedOnCompanyCountry from '../../../components/RenderBasedOnCompanyCountry';
import { User } from '../../../models/User';
import { getUser } from '../../../selectors/user';
import VIPMembershipStatusBadge from '../../CreditSystem/NavbarUserMenu/VIPMembershipStatusBadge';
import { UserProfilePicture } from './UserMenu.sc';

export default function MenuProfileOverview() {
  const me: User = useSelector(getUser);
  const location = [me.links?.city?.name, me.links?.country?.name]
    .filter(Boolean)
    .join(', ');

  return (
    <Flex
      style={{
        gap: space8,
        padding: `${space8}`,
      }}
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Flex
        style={{
          gap: space8,
        }}
        alignItems="center"
      >
        <UserProfilePicture
          userId={me.id}
          profilePic={me.profilePic}
          alt="Profile Picture"
        />
        <Flex flexDirection="column">
          <Typography variant="body2" aria-label="user-name">
            {userName(me)}
          </Typography>
          <Typography
            variant="body1"
            color={Neutral.B40}
            aria-label="user-location"
          >
            {location}
          </Typography>
        </Flex>
      </Flex>
      <RenderBasedOnCompanyCountry.IDOnly>
        <VIPMembershipStatusBadge />
      </RenderBasedOnCompanyCountry.IDOnly>
    </Flex>
  );
}
