import { useEffect } from 'react';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

type ApplicationsPageRedirectProps = {
  redirect: (path: string) => void;
};

const ApplicationsPageRedirect = ({
  redirect,
}: ApplicationsPageRedirectProps) => {
  const { jid } = useParams<{ jid: string }>();

  useEffect(() => {
    if (jid) {
      const redirectSearchParams = new URLSearchParams({
        jid,
      });
      redirect(`/manage-candidates?${redirectSearchParams.toString()}`);
    }
  }, [jid, redirect]);

  return null;
};

export default connect(null, dispatch => ({
  redirect: (args: string) => dispatch(replace(args)),
}))(ApplicationsPageRedirect);
