export const unverifiedCompanyRestrictionMessages = {
  header: {
    id: 'text-company-verification-required',
    defaultMessage: 'Company Verification Required',
  },
  needVerification: {
    id: 'text-company-needs-verification-talent',
    defaultMessage: `Seems like your company is not yet verified. Your company needs to be verified before you can view the resume and chat with the talent.`,
  },
  uploadDocuments: {
    id: 'text-upload-necessary-documents',
    defaultMessage: `If your company is verified, try refreshing or contact our support team via <a>hello@glints.com</a>.`,
  },
  refresh: {
    id: 'interactive-refresh',
    defaultMessage: 'Refresh',
  },
  close: {
    id: 'interactive-close',
    defaultMessage: 'Close',
  },
};
