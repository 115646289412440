import { useEffect } from 'react';

import { useGlintsVIPMembershipInfo } from '../../components/GlintsVIPMembershipInfo/hooks/useGlintsVIPMembershipInfo';
import { useVIPMembershipExpiredLocalStorage } from './useVIPMembershipExpiredLocalStorage';

export const useResetVIPMembershipExpiredFirstSeen = () => {
  const { vipMembershipInfo } = useGlintsVIPMembershipInfo();

  const { isFirstSeenSet, unsetVIPMembershipExpiredFirstSeen } =
    useVIPMembershipExpiredLocalStorage();

  useEffect(() => {
    if (isFirstSeenSet && vipMembershipInfo.isActive) {
      unsetVIPMembershipExpiredFirstSeen();
    }
  }, [
    isFirstSeenSet,
    unsetVIPMembershipExpiredFirstSeen,
    vipMembershipInfo.isActive,
  ]);
};
