import { useCallback } from 'react';
import dayjs from 'dayjs';
import { useLocalStorage } from 'react-use';

import { Company } from '../interfaces/entities';
import { useGetSessionCompany } from './useGetSessionCompany';

const NOTIFICATION_CUT_OFF_THRESHOLD_DAYS = 3;

const canShowNotificationAndBanner = (date?: string): boolean => {
  if (!date) {
    return true;
  }

  return (
    dayjs().diff(date, 'days', true) <= NOTIFICATION_CUT_OFF_THRESHOLD_DAYS
  );
};

type VIPMembershipExpiredFirstSeen = Record<Company['id'], string | undefined>;

export const useVIPMembershipExpiredLocalStorage = () => {
  const { sessionCompany } = useGetSessionCompany();

  const [
    cachedVIPMembershipExpiredFirstSeen,
    setVIPMembershipExpiredFirstSeenCache,
  ] = useLocalStorage<VIPMembershipExpiredFirstSeen>(
    `vipMembershipExpiredFirstSeen`,
    {}
  );

  const setVIPMembershipExpiredFirstSeen = useCallback(() => {
    if (!sessionCompany?.id) {
      return;
    }

    setVIPMembershipExpiredFirstSeenCache(state => {
      if (state && state[sessionCompany.id]) {
        // return if already set
        return { ...state };
      }

      return {
        ...state,
        [sessionCompany.id]: dayjs().toJSON(),
      };
    });
  }, [sessionCompany?.id, setVIPMembershipExpiredFirstSeenCache]);

  const unsetVIPMembershipExpiredFirstSeen = useCallback(() => {
    if (!sessionCompany?.id) {
      return;
    }

    setVIPMembershipExpiredFirstSeenCache(state => {
      if (state && !state[sessionCompany.id]) {
        // return if already undefined
        return { ...state };
      }

      return {
        ...state,
        [sessionCompany.id]: undefined,
      };
    });
  }, [sessionCompany?.id, setVIPMembershipExpiredFirstSeenCache]);

  return {
    canShowNotificationAndBanner: sessionCompany?.id
      ? canShowNotificationAndBanner(
          cachedVIPMembershipExpiredFirstSeen?.[sessionCompany.id]
        )
      : false,
    isFirstSeenSet: sessionCompany?.id
      ? Boolean(cachedVIPMembershipExpiredFirstSeen?.[sessionCompany.id])
      : false,
    setVIPMembershipExpiredFirstSeen,
    unsetVIPMembershipExpiredFirstSeen,
  } as const;
};
