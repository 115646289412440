import React from 'react';
import { Menu } from 'antd';
import type { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { Greyscale } from 'glints-aries';
import { Badge, Typography } from 'glints-aries/es/@next';
import omit from 'lodash-es/omit';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import useGetRefCodeParam from '../../common/hooks/useGetRefCodeParam';
import { HashLink as Link } from '../../components/HashLink';
import { MenuBadge } from '../Platform/Constants';
import { MenuItemWithBadge } from './NavbarItem.sc';
import { isAbsoluteURL } from './navigationUtils';

const NavbarItem: React.FC<NavbarItemProps> = ({
  activeClassName,
  children,
  clickAction,
  label,
  to,
  badge,
  ...others
}) => {
  const referralCode = useGetRefCodeParam();
  const isExternal = isAbsoluteURL(to);
  return (
    <Menu.Item {...omit(others, ['logoStyle', 'fromJobEditPage'])}>
      <Choose>
        <When condition={Boolean(clickAction)}>
          <a onClick={clickAction}>
            {label}
            {children}
          </a>
        </When>
        <When condition={isExternal}>
          <a href={to} target="_blank" rel="noreferrer noopener">
            {label}
            {children}
          </a>
        </When>
        <When condition={Boolean(to) && to.indexOf('#') === 0}>
          <Link to={to}>
            {label}
            {children}
          </Link>
        </When>
        <Otherwise>
          <MenuItemWithBadge>
            <NavLink
              to={{
                pathname: to,
                ...(referralCode && { search: `refCode=${referralCode}` }),
              }}
              activeClassName={activeClassName}
            >
              {label}
              {children}
            </NavLink>
            <If condition={typeof badge !== 'undefined'}>
              <Badge status={badge?.status}>
                <Typography variant="overline" color={Greyscale.white}>
                  <FormattedMessage {...badge?.label} />
                </Typography>
              </Badge>
            </If>
          </MenuItemWithBadge>
        </Otherwise>
      </Choose>
    </Menu.Item>
  );
};

type NavbarItemProps = {
  to: string;
  label?: React.ReactNode;
  activeClassName?: string;
  children?: React.ReactNode;
  clickAction?: React.MouseEventHandler<HTMLAnchorElement>;
  badge?: MenuBadge;
} & MenuItemProps;

export { NavbarItem };
