import React, { Component } from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import FormSelect from '../Select';
import {
  destroyState,
  fetchJobCategories,
  fetchSelectedJobCategory,
  initState,
  updateSelectedJobCategory,
} from './Actions';
import {
  isSelectOptionsLoading,
  makeGetSelectedJobCategory,
  makeGetSelectOptions,
} from './Selectors';

class FormSelectJobCategory extends Component {
  static propTypes = {
    fetchJobCategories: PropTypes.func.isRequired,
    jobCategories: PropTypes.array,
    fetching: PropTypes.bool,
    input: PropTypes.object,

    initState: PropTypes.func.isRequired,
    destroyState: PropTypes.func.isRequired,

    fetchSelectedJobCategory: PropTypes.func.isRequired,
    updateSelectedJobCategory: PropTypes.func.isRequired,
    selectedJobCategory: PropTypes.object,
  };

  static defaultProps = {
    jobCategories: [],
    fetching: false,
  };

  componentDidMount() {
    this.props.initState();

    // If a school was selected, fetch it so we know its name.
    if (this.props.input.value) {
      this.props.fetchSelectedJobCategory(this.props.input.value);
    }

    // Pre-fetch entries.
    this.props.fetchJobCategories();
  }

  handleOnChange = value => {
    const { input, updateSelectedJobCategory } = this.props;
    input.onChange(value.key);
    updateSelectedJobCategory(value.key);
  };

  render() {
    const {
      jobCategories,
      fetchJobCategories,
      fetching,
      selectedJobCategory,
      input,
      ...props
    } = this.props;

    const value = input.value
      ? {
          key: input.value,
          label: selectedJobCategory ? selectedJobCategory.name : 'Loading...',
        }
      : undefined;

    return (
      <FormSelect
        options={jobCategories}
        showSearch={true}
        onSearch={fetchJobCategories}
        optionLabelProp="children"
        filterOption={false}
        labelInValue={true}
        notFoundContent={
          <React.Fragment>
            <If condition={fetching}>
              <Spin size="small" />
            </If>
            <If condition={!fetching}>
              <FormattedMessage
                id="components.Form.SelectJobCategory.SelectJobCategory.not.found"
                defaultMessage="Not Found"
              />
            </If>
          </React.Fragment>
        }
        {...props}
        value={value}
        onChange={this.handleOnChange}
      />
    );
  }
}

export default connect(
  () => {
    const getSelectOptions = makeGetSelectOptions();
    const getSelectedJobCategory = makeGetSelectedJobCategory();
    return (state, props) => ({
      jobCategories: getSelectOptions(state, props),
      fetching: isSelectOptionsLoading(state, props),
      selectedJobCategory: getSelectedJobCategory(state, props),
    });
  },
  (dispatch, ownProps) => ({
    initState: () => dispatch(initState(ownProps.meta.form)),
    destroyState: () => dispatch(destroyState(ownProps.meta.form)),
    fetchJobCategories: searchTerm =>
      dispatch(fetchJobCategories(ownProps.meta.form, searchTerm)),
    fetchSelectedJobCategory: id =>
      dispatch(fetchSelectedJobCategory(ownProps.meta.form, id)),
    updateSelectedJobCategory: id =>
      dispatch(updateSelectedJobCategory(ownProps.meta.form, id)),
  })
)(FormSelectJobCategory);
