import { Dispatch } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';

import reducer, { TrackingAction } from './trackingReducer';

export default (store: ToolkitStore) =>
  (next: Dispatch) =>
  (action: TrackingAction) => {
    reducer(store.getState(), action);
    return next(action);
  };
