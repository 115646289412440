import { pushDataLayer } from '../common/google-tag-manager';
import gtmReducer from '../gtm-reducer';

// Integrates Google Tag Manager with a Redux store.
// https://medium.com/@j3y/analytics-with-react-redux-spas-and-google-tag-manager-4d3a55833619
export default store => next => action => {
  const stateFromReducer = gtmReducer(store.getState(), action);

  const nextDataLayer = {
    event: action.type,
    payload: action.payload,
    response: action.response,
    ...stateFromReducer,
  };

  pushDataLayer(nextDataLayer);

  return next(action);
};
