import React from 'react';
import { Greyscale, PrimaryColor } from 'glints-aries';
import {
  Badge,
  Button,
  ButtonProps,
  PrimaryButton,
  Typography,
} from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import clampedNum from '../../common/clamped';

type ButtonVariant = 'default' | 'primary';

type ChatButtonProps = ButtonProps & {
  variant?: ButtonVariant;
  unreadCount?: number;
  content?: React.ReactNode;
};

const btnVariantMap = {
  default: Button,
  primary: PrimaryButton,
};

const StyledBadge = styled(Badge)`
  background-color: ${PrimaryColor.glintsred};
  border: 2px solid ${Greyscale.white};
  position: absolute;
  top: -36%;
  right: -14%;
  padding: 2px 7px;
`;

const ChatButton = ({
  variant = 'default',
  unreadCount,
  style,
  content,
  ...rest
}: ChatButtonProps) => {
  const ButtonComponent = btnVariantMap[variant];
  const clampedUnreadCount = clampedNum({
    count: unreadCount,
    max: 99,
  });

  return (
    <ButtonComponent {...rest} style={{ ...style, position: 'relative' }}>
      <Typography variant="button">
        {content ?? (
          <FormattedMessage id="button-text_chat" defaultMessage="Chat" />
        )}
      </Typography>
      <If condition={clampedUnreadCount}>
        <StyledBadge>
          <Typography variant="overline" color={Greyscale.white}>
            {clampedUnreadCount}
          </Typography>
        </StyledBadge>
      </If>
    </ButtonComponent>
  );
};
export default ChatButton;
