import React from 'react';
import { Flex } from 'glints-aries';
import { useChannelStateContext } from 'stream-chat-react';

import { useMessagingChannelContext } from '../../constants';
import { useCreateShareContactsRequest } from '../../graphql';
import ContactsRequestInputPhoneNumber from '../ContactsRequestMessage/ContactsRequestInputPhoneNumber';

const PhoneNoInputMessage = () => {
  const { channel } = useChannelStateContext();
  const { setShowInputPhoneNumber } = useMessagingChannelContext();
  const [createShareContactsRequest] = useCreateShareContactsRequest();

  const handleSubmit = () => {
    if (channel.id) {
      createShareContactsRequest({
        variables: {
          channelId: channel.id,
        },
      });
    }
  };

  return (
    <Flex justifyContent="center">
      <ContactsRequestInputPhoneNumber
        onClose={() => setShowInputPhoneNumber(false)}
        afterSubmit={handleSubmit}
      />
    </Flex>
  );
};

export default PhoneNoInputMessage;
