import { handleActions } from 'redux-actions';

import { Actions } from './Actions';

export const initialState = {
  name: null,
  data: null,
};

export default handleActions(
  {
    [Actions.OPEN_MODAL]: (state, action) => ({
      ...state,
      name: action.payload.name,
      data: action.payload.data,
    }),
    [Actions.CLOSE_MODAL]: () => ({
      ...initialState,
    }),
  },
  initialState
);
