import { createAction } from 'redux-actions';

import { USER_ROLES } from '../../common/constants';
import { GROUPS } from '../../common/filters';
import { getSessionCompany } from '../../selectors/session';
import { getUserRole } from '../../selectors/user';
import { updateNavbarLogo } from '../Navigation/Actions';
import {
  getCustomPermissionsSet,
  getFeatureSet,
  getPlatformFeatureNames,
} from './Selectors';

export const Actions = {
  MERGE_FEATURES: 'glints/platform/MERGE_FEATURES',
  ADD_FEATURE_NAME: 'glints/platform/ADD_FEATURE_NAME',
  RESET_FEATURES_STATE: 'glints/platform/RESET_FEATURES_STATE',

  MERGE_PERMISSION: 'glints/platform/MERGE_PERMISSION',
  MERGE_PERMISSIONS_REQUEST: 'glints/platform/MERGE_PERMISSIONS_REQUEST',
  MERGE_PERMISSIONS_SUCCESS: 'glints/platform/MERGE_PERMISSIONS_SUCCESS',
  MERGE_PERMISSIONS_FAILURE: 'glints/platform/MERGE_PERMISSIONS_FAILURE',
};

export const mergeFeatures = createAction(Actions.MERGE_FEATURES);
export const addFeatureName = createAction(Actions.ADD_FEATURE_NAME);
export const resetFeaturesState = createAction(Actions.RESET_FEATURES_STATE);

export const mergePermission = createAction(Actions.MERGE_PERMISSION);
export const mergePermissionsRequest = createAction(
  Actions.MERGE_PERMISSIONS_REQUEST
);
export const mergePermissionsSuccess = createAction(
  Actions.MERGE_PERMISSIONS_SUCCESS
);
export const mergePermissionsFailure = createAction(
  Actions.MERGE_PERMISSIONS_FAILURE
);

export function mergePermissions(permissions, keys) {
  return dispatch => {
    try {
      dispatch(mergePermissionsRequest());
      keys.forEach(
        async x => await dispatch(mergePermission({ [x]: permissions[x] }))
      );
      dispatch(mergePermissionsSuccess());
    } catch (err) {
      dispatch(mergePermissionsFailure(err));
    }
  };
}

export function applyFeatures() {
  return (dispatch, getState) => {
    dispatch(resetFeaturesState());

    const features = ['glints'];
    const sessionCompany = getSessionCompany(getState());
    const userRole = getUserRole(getState());

    // Check if session company is a TalentHunt Approved company
    if (sessionCompany) {
      features.push('company');
      const groups = sessionCompany?.links?.groupMembers ?? [];
      const isTalentHuntApproved = Boolean(
        groups.filter(
          g =>
            g.GroupId === GROUPS.talentSearchCompany.id &&
            g.status === 'APPROVED'
        ).length
      );
      if (isTalentHuntApproved) {
        features.push('talenthunt');
      }
    }

    if (userRole) {
      if (
        userRole === USER_ROLES.RECRUITER ||
        userRole === USER_ROLES.SUPER_ADMIN
      ) {
        if (sessionCompany) {
          const hasTalentHuntFeature = features.includes('talenthunt');

          features.push(
            hasTalentHuntFeature
              ? 'recruiterCompanyTalentHunt'
              : 'recruiterCompany'
          );
        }
      }
    }

    // Set the feature names in the store.
    for (const feature of features) {
      dispatch(addFeatureName(feature));
    }

    // Apply permissions and compute the features.
    let permissions = {};
    let permissionKeys = [];
    const featureNames = getPlatformFeatureNames(getState());

    if (featureNames.length) {
      // Merge in custom features
      featureNames.forEach(s => {
        const featureSet = getFeatureSet(s);
        permissions = getCustomPermissionsSet(s);
        permissionKeys = permissions ? Object.keys(permissions) : [];

        // Should not override logo if on whitelabel site
        if (featureSet && featureSet.logo) {
          dispatch(updateNavbarLogo(featureSet.logo));
        }
        dispatch(mergeFeatures(featureSet));
      });
      dispatch(mergePermissions(permissions, permissionKeys));
    }
  };
}
