import { Button } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import styled from 'styled-components';

const StyledVIPGradientButton = styled(Button)`
  background: linear-gradient(0deg, #ffa81c 0%, #ffcf2c 100%);
  border: none;
  box-shadow: 0px -1px 0px 0px #00000033 inset;
  box-shadow: 0px 1px 0px 0px #00000014;

  &&&:hover,
  &&&:active {
    border: none;
    background: linear-gradient(0deg, #f29e1b 0%, #f2c72b 100%);
  }

  &&&[data-loading='true'] {
    background: linear-gradient(0deg, #ffa81c 0%, #ffcf2c 100%);
    & p {
      opacity: 0;
    }
    & svg {
      fill: ${Neutral.B18};
    }
  }
`;

export default StyledVIPGradientButton;
