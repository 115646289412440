type Options = {
  retries?: number;
  interval?: number;
  exponentialBackoff?: boolean;
};

export async function retry<R>(
  fn: () => Promise<R>,
  { retries = 3, interval = 500, exponentialBackoff = true }: Options = {}
): Promise<R> {
  try {
    const result = await fn();
    return result;
  } catch (error) {
    await new Promise(resolve => setTimeout(resolve, interval));
    if (retries === 1) {
      throw error;
    } else {
      return await retry(fn, {
        retries: retries - 1,
        interval: exponentialBackoff ? interval * 2 : interval,
      });
    }
  }
}
