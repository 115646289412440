import {
  gql,
  QueryHookOptions,
  SuspenseQueryHookOptions,
  useQuery,
  useSuspenseQuery,
} from '@apollo/client';

import {
  ApplicationRejectionMessage,
  ApplicationRejectionMessagePreference,
  ApplicationRejectionMessageType,
} from '../types';

const query = gql`
  query getApplicationRejectionMessagePreferences {
    getApplicationRejectionMessagePreferences {
      preferredType
      rejectionMessages {
        message
        messageType
      }
    }
  }
`;

type QueryOutput = {
  getApplicationRejectionMessagePreferences: {
    preferredType: ApplicationRejectionMessagePreference;
    rejectionMessages: ApplicationRejectionMessage[];
  };
};

const transformResponseByFilteringMessages = (
  response: QueryOutput['getApplicationRejectionMessagePreferences']
): QueryOutput['getApplicationRejectionMessagePreferences'] => ({
  ...response,
  rejectionMessages: response.rejectionMessages.filter(message =>
    Object.values(ApplicationRejectionMessageType).includes(message.messageType)
  ),
});

export const useGetApplicationRejectionMessagePreferencesNonSuspense = (
  option?: QueryHookOptions<QueryOutput>
) => {
  const { data, loading } = useQuery<QueryOutput>(query, option);

  return {
    rejectionMessagePreferences: data?.getApplicationRejectionMessagePreferences
      ? transformResponseByFilteringMessages(
          data.getApplicationRejectionMessagePreferences
        )
      : undefined,
    loading,
  } as const;
};

export default function useGetApplicationRejectionMessagePreferences(
  option?: SuspenseQueryHookOptions<QueryOutput>
) {
  const { data } = useSuspenseQuery<QueryOutput>(query, option);

  return {
    rejectionMessagePreferences: transformResponseByFilteringMessages(
      data.getApplicationRejectionMessagePreferences
    ),
  } as const;
}
