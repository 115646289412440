import React from 'react';
import { useMessageContext } from 'stream-chat-react';

import {
  ApplicationChatContactsRequestActionType,
  ApplicationChatCVRequestActionType,
  ApplicationChatEphemeralActionType,
} from '../constants';
import { getMessageApplicationMetadata } from '../helper';
import PhoneNoInputMessage from './ContactsInputMessage';
import ContactsRequestMessage from './ContactsRequestMessage';
import CVRequestMessage from './CVRequestMessage';
import TextMessage from './TextMessage';

const MessageVariantController = () => {
  const { message } = useMessageContext();
  const metadata = getMessageApplicationMetadata(message);

  switch (metadata?.action_type) {
    case ApplicationChatContactsRequestActionType.CONTACTS_REQUEST_CDD:
    case ApplicationChatContactsRequestActionType.CONTACTS_REQUEST_EMP:
      return <ContactsRequestMessage />;
    case ApplicationChatCVRequestActionType.CV_REQUEST_CDD:
    case ApplicationChatCVRequestActionType.CV_REQUEST_CDD_AUTO:
    case ApplicationChatCVRequestActionType.CV_REQUEST_EMP:
    case ApplicationChatCVRequestActionType.CV_AUTO_SHARED:
      return <CVRequestMessage />;
    case ApplicationChatEphemeralActionType.INPUT_PHONE:
      return <PhoneNoInputMessage />;
  }

  switch (message.type) {
    case 'regular':
    case 'deleted':
      return <TextMessage />;
    default:
      return null;
  }
};

export default MessageVariantController;
