import React from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';

import { getEmailVerificationStatus } from '../selectors/user';

export const verify = WrappedComponent => {
  const Verified = props => {
    const dispatch = useDispatch();
    const isVerified = useSelector(getEmailVerificationStatus);
    if (isVerified) {
      return <WrappedComponent {...props} />;
    }
    if (isVerified === false) {
      dispatch(push('/email-verification'));
      return;
    }
    dispatch(push('/500'));
    return null;
  };

  return Verified;
};
