import React from 'react';
import { Flex, Greyscale } from 'glints-aries';
import { Icon, Typography } from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';

const ChannelEmptyPlaceholder = () => (
  <Flex
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    style={{ gap: 8, background: Greyscale.white }}
  >
    <Icon name="ri-message-line" width={66} fill={Greyscale.devilsgrey} />
    <Typography variant="subtitle1">
      <FormattedMessage
        id="text_chat-empty-state-title"
        defaultMessage="No conversation selected"
      />
    </Typography>
    <Typography variant="body1" color={Greyscale.devilsgrey}>
      <FormattedMessage
        id="text_chat-empty-state-desc"
        defaultMessage="Select the candidate to start chatting and hiring"
      />
    </Typography>
  </Flex>
);

export default ChannelEmptyPlaceholder;
