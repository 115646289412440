import { useContext } from 'react';

import { GlintsVIPPurchaseModalContext } from '../Providers/GlintsVIPPurchaseModalContext';

export const useGlintsVIPFreeTrialOrPurchaseModal = () => {
  const context = useContext(GlintsVIPPurchaseModalContext);
  if (!context) {
    throw new Error(
      'useGlintsVIPFreeTrialOrPurchaseModal must be used within a GlintsVIPPurchaseModalProvider'
    );
  }

  return context;
};
