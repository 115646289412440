import React, { useEffect, useState } from 'react';
import { Icon, InlineError, Tooltip, Typography } from 'glints-aries/es/@next';
import { Blue, Neutral, Red } from 'glints-aries/es/@next/utilities/colors';
import { omit } from 'lodash-es';
import { useIntl } from 'react-intl';
import Select, { components, GroupBase, OptionsOrGroups } from 'react-select';

import { StyledLabelWrapper, StyledSelectField } from './SelectField.sc';
import {
  checkKeepSearchValueAction,
  DropdownIndicator,
  dropdownStyle,
  getNoOptionMessage,
} from './selectFieldHelper';
import { SelectFieldProps } from './SelectFieldProps';

const SelectField = React.forwardRef<Select, SelectFieldProps>(
  (
    {
      label,
      subLabel,
      tooltipText,
      errorText,
      hasPlaceholderAsOption,
      width,
      fullWidth,
      menuPlacement,
      menuZIndex,
      helperText,
      styles,
      keepSearchValue,
      hasAsterisk,
      ...props
    }: SelectFieldProps,
    ref
  ) => {
    const { formatMessage } = useIntl();

    const [options, setOptions] = useState<
      OptionsOrGroups<unknown, GroupBase<unknown>> | undefined
    >([]);

    useEffect(() => {
      if (props.options) {
        if (hasPlaceholderAsOption) {
          setOptions([
            {
              value: null,
              label: props.placeholder,
              disabled: true,
            },
            ...props.options,
          ]);
        } else {
          setOptions([...props.options]);
        }
      } else {
        setOptions([]);
      }
    }, [hasPlaceholderAsOption, props.options, props.placeholder]);

    return (
      <StyledSelectField data-fullwidth={fullWidth}>
        <If condition={Boolean(label)}>
          <StyledLabelWrapper>
            <Typography variant="body2">{label}</Typography>
            <If condition={Boolean(tooltipText)}>
              <Tooltip content={tooltipText} preferredPosition="right-middle">
                <Icon
                  name="ri-information-line"
                  height={20}
                  width={20}
                  fill={Blue.S99}
                  data-cy={'tooltip'}
                />
              </Tooltip>
            </If>
          </StyledLabelWrapper>
        </If>
        <If condition={subLabel}>
          <Typography variant="subtitle2">
            {subLabel}
            <Typography variant="subtitle2" color={Red.B93} as="span">
              {hasAsterisk ? '*' : ''}
            </Typography>
          </Typography>
        </If>
        <Select
          {...props}
          ref={ref as any}
          options={options}
          {...(menuZIndex ? { menuPortalTarget: document.body } : {})}
          styles={{
            ...dropdownStyle({
              error: Boolean(errorText),
              isSearchable: props.isSearchable,
              width,
              removeBorder: props.removeBorder,
              removeFocusBoxShadow: props.removeFocusBoxShadow,
            }),
            menuPortal: base => ({
              ...base,
              zIndex: menuZIndex ? menuZIndex : base.zIndex,
            }),
            ...styles,
          }}
          components={{
            ...(props.isSearchable ? { DropdownIndicator } : {}),
            ...(props.isLoading ? { DropdownIndicator: undefined } : {}),
            IndicatorSeparator: () => null,
            MenuList: menuListProps => (
              <components.MenuList
                {...menuListProps}
                data-cy={`${props.id}-list`}
              >
                {menuListProps.children}
              </components.MenuList>
            ),
            NoOptionsMessage: getNoOptionMessage(formatMessage, props),
            ...omit(props.components, 'NoOptionsMessage'),
            ...(props.isMulti ? { ClearIndicator: () => null } : {}),
          }}
          isOptionDisabled={(option: any) => option.disabled}
          menuPlacement={menuPlacement || 'auto'}
          onInputChange={
            props.onInputChange
              ? (value, meta) => {
                  if (keepSearchValue && checkKeepSearchValueAction(meta)) {
                    return;
                  }
                  props.onInputChange?.(value, meta);
                }
              : undefined
          }
        />
        <If condition={Boolean(errorText)}>
          <InlineError text={String(errorText)} data-cy={'error-message'} />
        </If>
        <If condition={Boolean(helperText)}>
          <Typography variant="subtitle2" color={Neutral.B40}>
            {helperText}
          </Typography>
        </If>
      </StyledSelectField>
    );
  }
);

SelectField.displayName = 'SelectField';

export default SelectField;
