import React from 'react';
import { Typography } from 'glints-aries/es/@next';
import { DateSeparator, DateSeparatorProps } from 'stream-chat-react';

const MessagingDateSeparator = (props: DateSeparatorProps) => (
  <Typography variant="overline">
    <DateSeparator {...props} position="center" />
  </Typography>
);

export default MessagingDateSeparator;
