import { useMemo } from 'react';
import { gql, useSuspenseQuery } from '@apollo/client';
import { cloneDeep, reduce } from 'lodash-es';

const query = gql`
  query getVIPJobFieldsDefaultValue {
    getVIPJobFieldsDefaultValue {
      fieldName
      defaultValue
    }
  }
`;

type QueryOutput = {
  getVIPJobFieldsDefaultValue: {
    fieldName: string;
    defaultValue: boolean;
  }[];
};

/**
 * Build nested object from array of keys
 * @param obj - object to build nested object from
 * @param path - array of keys
 * @param value - value to set
 * @example buildNestedObject({}, ['a', 'b', 'c'], 1) => { a: { b: { c: 1 } } }
 */
const buildNestedObject = (
  obj: Record<string, any>,
  path: string[],
  value: any
) => {
  const newObj = cloneDeep(obj);
  let current = newObj;

  path.forEach((key, index) => {
    if (index === path.length - 1) {
      current[key] = value;
    } else {
      current[key] = current[key] || {};
      current = current[key];
    }
  });

  return newObj;
};

const convertDefaultValueArrayToObject = (
  defaultValueArray: QueryOutput['getVIPJobFieldsDefaultValue']
) =>
  reduce<
    QueryOutput['getVIPJobFieldsDefaultValue'][number],
    Record<string, any>
  >(
    defaultValueArray,
    (processedObject, { fieldName, defaultValue }) => {
      const keys = fieldName.split('.');
      return buildNestedObject(processedObject, keys, defaultValue);
    },
    {}
  );

export default function useGetVIPJobFieldsDefaultValue() {
  const { data } = useSuspenseQuery<QueryOutput>(query, {
    fetchPolicy: 'cache-first',
  });

  const vipJobFieldsDefaultValue = useMemo(
    () => convertDefaultValueArrayToObject(data.getVIPJobFieldsDefaultValue),
    [data.getVIPJobFieldsDefaultValue]
  );

  return {
    vipJobFieldsDefaultValue,
  } as const;
}
