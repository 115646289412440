import { useEffect, useState } from 'react';

import { getBuilderInstance } from '../../asyncBuilderInstance';
import { BUILDER_NAVIGATION_DATA_MODEL } from './Constants';

type NavItem = {
  label: string;
  url: string;
  subNav?: Array<NavItem>;
};

type NavItems = {
  items: NavItem[];
  button?: NavItem;
};

type BuilderResponse = {
  data: NavItems;
};

export const useGetMenuItemsBuilder = () => {
  const [navItems, setNavItems] = useState<NavItems>({ items: [] });

  useEffect(() => {
    const fetchData = async () => {
      const builder = await getBuilderInstance();
      builder
        .get(BUILDER_NAVIGATION_DATA_MODEL)
        .promise()
        .then((res: BuilderResponse) => {
          setNavItems(res.data);
        });
    };
    fetchData();
  }, []);

  return navItems;
};
