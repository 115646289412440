import React from 'react';
import { Menu } from 'antd';
import { Greyscale, SecondaryColor } from 'glints-aries';
import styled, { css } from 'styled-components';

import Container from '../../components/Container';
import { ProfilePicture } from '../../components/GlintsPicture';
import { NavbarItem } from './NavbarItem';

export const NavbarContainer = styled(Container)`
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;
  width: 100%;
`;

const menuStyles = css`
  display: flex;
  align-items: center;
  line-height: initial;
  border-bottom: none;
`;

const menuItemStyles = css`
  > .ant-menu-item-divider {
    height: calc(100% - 20px);
    border-left: solid 2px ${Greyscale.softgrey};
    margin: 0 5px;
  }

  > .ant-menu-item,
  > .ant-menu-submenu {
    text-transform: uppercase;
    color: black;
    font-weight: bold;
    letter-spacing: 0.5px;
    padding: 5px 15px !important;
    display: inline-flex;
    align-items: center;
    border-bottom: 5px solid transparent;
    height: calc(100% - 5px);
    margin-bottom: -2.5px;
  }

  &:not(.ant-menu-dark) {
    > .ant-menu-item:hover:after,
    > .ant-menu-submenu:hover:after {
      border-bottom: 5px solid transparent !important;
    }
  }

  > .ant-menu-item:hover,
  > .ant-menu-submenu:hover {
    border-bottom: 5px solid ${SecondaryColor.actionblue};
  }

  > .ant-menu-overflowed-submenu {
    align-items: stretch;
  }

  > .ant-menu-overflowed-submenu > .ant-menu-submenu-title {
    display: flex;
    align-items: center;
  }
`;

export const dropdownMenuItemStyles = css`
  .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
    background-color: ${Greyscale.white};
  }
  .ant-dropdown-menu-item:hover {
    background-color: #f5f5f5;
  }
`;

export const NavbarLogo = styled(NavbarItem).attrs(() => ({
  children: <img src="/images/glints_for_employers.svg" />,
}))`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  margin-right: 10px;

  img {
    width: auto;
    height: 35px;
  }
`;

export const LeftMenu = styled(Menu)`
  flex: 1 1 auto;
  overflow: hidden;

  ${menuStyles}
  ${menuItemStyles}
`;

export const RightMenu = styled(Menu)`
  flex: 0 0 auto;

  ${menuStyles}
  ${menuItemStyles}
`;

const userLogoHeight = '32px';

const userLogo = css`
  object-fit: cover;
  border-radius: 100%;
  width: ${userLogoHeight};
  height: ${userLogoHeight};
`;

export const Avatar = styled(ProfilePicture)`
  ${userLogo}
  margin-right: 10px;
`;
