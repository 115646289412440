import { handleActions } from 'redux-actions';

import { Actions } from './Actions';

export const initialState = {
  approveUser: {
    loading: false,
    success: false,
    error: null,
  },

  rejectUser: {
    loading: false,
    success: false,
    error: null,
  },

  jobExpiryExtend: {
    jobExtending: {
      loading: false,
      error: null,
      success: false,
    },
    selectedJobForExtension: null,
    jobExtendDay: {
      loading: false,
      value: null,
      error: null,
    },
    modalOpen: false,
  },
};

export default handleActions(
  {
    [Actions.APPROVE_COMPANY_USER_REQUEST]: state => ({
      ...state,
      approveUser: {
        ...state.approveUser,
        loading: true,
        success: false,
        error: null,
      },
    }),
    [Actions.APPROVE_COMPANY_USER_SUCCESS]: state => ({
      ...state,
      approveUser: {
        ...state.approveUser,
        loading: false,
        success: true,
        error: null,
      },
    }),
    [Actions.APPROVE_COMPANY_USER_FAILURE]: (state, action) => ({
      ...state,
      approveUser: {
        ...state.approveUser,
        loading: false,
        success: false,
        error: action.payload.response
          ? action.payload.response
          : action.payload,
      },
    }),
    [Actions.REJECT_COMPANY_USER_REQUEST]: state => ({
      ...state,
      rejectUser: {
        ...state.rejectUser,
        loading: true,
        success: false,
        error: null,
      },
    }),
    [Actions.REJECT_COMPANY_USER_SUCCESS]: state => ({
      ...state,
      rejectUser: {
        ...state.rejectUser,
        loading: false,
        success: true,
        error: null,
      },
    }),
    [Actions.REJECT_COMPANY_USER_FAILURE]: (state, action) => ({
      ...state,
      rejectUser: {
        ...state.rejectUser,
        loading: false,
        success: false,
        error: action.payload.response
          ? action.payload.response
          : action.payload,
      },
    }),
    [Actions.RESET_COMPANY_USER_STATE]: state => ({
      ...state,
      approveUser: initialState.approveUser,
      rejectUser: initialState.rejectUser,
    }),
    [Actions.EXTEND_JOB_EXPIRY_REQUEST]: state => ({
      ...state,
      jobExpiryExtend: {
        ...state.jobExpiryExtend,
        jobExtending: {
          loading: true,
          error: null,
          success: false,
        },
      },
    }),
    [Actions.EXTEND_JOB_EXPIRY_SUCCESS]: state => ({
      ...state,
      jobExpiryExtend: {
        ...state.jobExpiryExtend,
        jobExtending: {
          loading: false,
          error: null,
          success: true,
        },
      },
    }),
    [Actions.EXTEND_JOB_EXPIRY_FAILURE]: (state, action) => ({
      ...state,
      jobExpiryExtend: {
        ...state.jobExpiryExtend,
        jobExtending: {
          loading: false,
          error: action.error,
          success: false,
        },
      },
    }),
  },
  initialState
);
